import { Subject, Observable, BehaviorSubject, Subscription, fromEvent } from 'rxjs';
import { Router, NavigationStart, Event as NavigationEvent, ActivatedRoute } from "@angular/router";
import { Custompipe } from '../pipes/pipes.module';
import { Injectable, Component, Output, EventEmitter } from '@angular/core';
import { formatDate, Location } from '@angular/common';

import pdfMake from "../../assets/js/bundles/export-tables/pdfmake.min.js";
import pdfFonts from '../../assets/js/bundles/export-tables/vfs_fonts.js';
import { CommunicatorService, MyServiceEvent } from './communication.service.js';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { HttpService } from './http.service.js';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { getArialUnicodeURL, getArialUnicodeBoldURL } from 'src/assets/fonts/importJapaneseFonts.js'
import { truncate } from 'fs';
import { additionalText, CountingMethod, defaultTestSettings, PopupData, SelectedTest, Test, LoggedError, SystemSettings } from '../globals/globals';
import { e } from 'mathjs';
import { environment } from 'src/environments/environment';
import { SAMPLETYPE } from './algorithm.service';
import { AuthService } from '../core/auth.service';
// import { RightSidebarComponent } from '../layout/right-sidebar/right-sidebar.component.js';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
declare const $: any;
declare var navigator;


@Injectable({
  providedIn: 'root'
})
// @Component({
//   template:'<div><canvas id="pie-chartcanvas-2"></canvas></div>'
// })
export class GlobalService {
  testCredit = 0;
  selectedRowEditData = {};
  selftestStatus = false;
  connectedStatus: boolean = false;
  Fresh_Test_Data: Test | any = {};
  ART_PREP_Results: Test[] | any[] = []
  postPrepOverwrite; // Property to handle re-test in flow of overwriting Post-Prep tests of existing sample
  reTest = false;
  fromtestresults = false;
  fromDataentry = false;
  Pdfobj = null;
  qcPdfobj = null;
  criteria;
  age;
  Patient_Info: any = {};
  testprocess = false;
  facilityHeader: any = {};
  patientGrp: any

  globalErrors: LoggedError[] = [];
  // Calibration global data
  // =======================
  activeCalibrationid = "";
  selectedCalibration = 0;
  selectedPortindex = 0;
  isSettingSaveEnabled: boolean = false;
  isSettingSaveEnabledAlert: boolean = false;

  // Pre Calibration global data
  // ===========================
  NumberofSamplesPoints = 100;
  allPatientAdd: boolean = false;
  allPatientEdit: boolean = false;
  docName;
  navToTestPatient = {};
  isFromPatient: boolean = false;
  testID: any;
  navToFacility: boolean = false;
  navToFacilitySet: boolean = false;
  navToFacilityStay: boolean = false;
  appearanceOther: boolean = false;
  a = 10;
  hasUnsavedChanges = false;
  @Output() connected: EventEmitter<any> = new EventEmitter();
  @Output() logoColor: EventEmitter<any> = new EventEmitter();
  @Output() Usb: EventEmitter<any> = new EventEmitter();
  dateandAge: string;
  concArrow: string;
  motPRNPArrow: string;
  progMSCbArrow: string;
  progMSCaArrow: string;
  progMSCArrow: string;
  normFrmsArrow: string;
  tscArrow: string;
  mscArrow: string;
  motilespermArrow: string;
  smiArrow: string;
  progressiveArrow: string;
  nonprogressiveArrow: string;
  immotileArrow: string;
  leftData: any = [];
  rightData: any = [];
  languageType: any;
  ConcvalUpdated: boolean = false;
  heightUnit: any = "";
  weightUnit: any = "";
  appearance: any;
  viscosity: any;
  liquefaction: any;
  prepTypeTranslationKeys: string[] = ['test_process.pre_prep', 'test_process.post_prep']
  options: any = { year: "numeric", month: "2-digit", day: "2-digit" };
  paramArray: any = [];
  chartURI: any;
  pcDateFormat: string = 'dd-MMM-yyyy';
  pcTimeFormat: string = 'HH:mm'
  level_3_count: number;
  clickedStartSelfTest: boolean = false;
  digitSeparator: string;
  credit_10_flag: boolean = true;
  credit_5_flag: boolean = true;
  credit_0_flag: boolean = true;
  driverUpdatePopup: boolean = true;
  isManual: boolean = false;
  isInternet = new Subject<any>();
  networkStatus: boolean = true;
  connectionStatus: any;
  networkSpeed: any;
  navigator: any;
  PageCount: any;
  rapidProgArrow: string;
  slowprogArrow: string;
  pmscaArrow: string;
  pmscbArrow: string;
  fscArrow: string;
  velocity: string;
  totalFscArrow: string;
  totalPmscArrow: string;
  totalMorphArrow: string;
  playPrVideo = new Subject<{ isPlay: boolean, source?: string, startsAt?: number }>();
  exitPopupData$ = new Subject<PopupData>();
  exitPopupBtnClicked$ = new Subject<string>();
  headerLogoutClicked$ = new Subject<string>();
  exitSaveLoading$ = new Subject<boolean>();
  triggerAutoCalSound$ = new Subject<boolean>();
  isViewSample$: Subject<boolean> = new Subject<boolean>()
  isReconnect$: Subject<boolean> = new Subject<boolean>()
  videoSettingNavigation = new Subject<boolean[]>();
  //Detect Visualization device observable

  cameraLabel = 'See3CAM_CU30 (2560:c130)';

  chartURL: string;
  debrisTrainingVideoURL: string = '';
  isdisplaychart: boolean;
  unTranslate: any = [];
  selfTest_track: any = [];
  reconnectTrack: any = [];
  dontShowInternetpopup: boolean = false;
  isHavingInternet: boolean = true
  backButtonPressed: boolean = false;
  showLiquefactionAlert: boolean = true;
  isDriverUpdated$: Subject<boolean> = new BehaviorSubject<boolean>(false);
  //home
  defaults: any
  //Visualization
  isAnyUploadingImagesVideos$: Subject<boolean> = new BehaviorSubject<boolean>(false);
  loader$: Subject<boolean> = new Subject<boolean>();
  fullScreenClicked$: Subject<boolean> = new Subject<boolean>()
  globalSelfTest$: Subject<boolean> = new BehaviorSubject<boolean>(false);


  // Archive Table
  PageNumLastVisit: number = -1;
  lastPageSize: number = 10;
  archiveLastTestView: any;

  //Video stream for VU component
  mstream: MediaStream | null;
  // alternateUnsavedForRefValues: boolean;
  // alternateUnsavedForRefValues1:boolean;
  // reportImageGrid: boolean = false;

  // PageNumLastVlsit: any = {
  //   pageNo: 0,
  //   color: ""
  // }



  constructor(
    private http: HttpService, private customPipe: Custompipe, private _authService: AuthService,
    private Comm: CommunicatorService, private translate: TranslateService, private router: Router) {

    this.checkNetworkconnection()
    this.getConnectionStatus();

    getArialUnicodeURL().then(fontURL => {
      pdfFonts.pdfMake.vfs['Arial_unicode'] = fontURL
    }).catch(err => {
      console.error('Error loading report font: ', err)
    });

    getArialUnicodeBoldURL().then(fontBoldURL => {
      pdfFonts.pdfMake.vfs['Arial_unicode_Bold'] = fontBoldURL;
    }).catch(err => {
      console.error('Error loading report font: ', err)
    });

    pdfMake.fonts = {
      Roboto: {
        normal: 'Roboto-Regular.ttf',
        bold: 'Roboto-Medium.ttf',
        italics: 'Roboto-Italic.ttf',
        bolditalics: 'Roboto-MediumItalic.ttf'
      },
      japneseFont: {
        normal: 'Arial_unicode',
        bold: 'Arial_unicode_Bold',
        italics: 'Arial_unicode',
        bolditalics: 'Arial_unicode'
      },
    }

    // Object.assign(this.Fresh_Test_Data, {reportImageGrid: false})

    /************  An observer to track browser's back button click and toggle a flag accordingly.
    
    this.router.events.pipe(
      filter((event:NavigationEvent) => {
        return (event instanceof NavigationStart);
      })
    ).subscribe(
      (event: NavigationStart) => {
        this.backButtonPressed = false;
        if (event.navigationTrigger === 'popstate') {
          this.backButtonPressed = true;
        }
      }
    )

    ************************************************************************************************/

    this.Comm.onChange.subscribe({
      next: (event: MyServiceEvent) => {
        switch (event.command) {

          case 'DeviceList':
            this.toggleconnect();
            break;
          case 'isConnect':
            this.toggleconnect();
            break;
          case 'dateFormat':
            this.pcDateFormat = event.value.localdateformat;

            if (event.value.localtimeformat.includes('tt')) {
              this.pcTimeFormat = event.value.localtimeformat.replace('tt', 'a');
            } else {
              this.pcTimeFormat = event.value.localtimeformat;
            }
            // console.log('new date format driver gloal', event.value);

            break;
        }
      }
    });

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      // console.log('language change from glob', event);
      this.languageType = event.lang;
    });

    this.Comm.getDateFormatByAPI().subscribe((data: any) => {
      this.pcDateFormat = data.data.localdateformat;

      if (data.data.localtimeformat.includes('tt')) {
        this.pcTimeFormat = data.data.localtimeformat.replace('tt', 'a');
      } else {
        this.pcTimeFormat = data.data.localtimeformat;
      }
      // console.log('format from api ', data.data);
    });

  }


  // public convertDateToFirebaseTimestamp(date: Date) {
  //   let timestamp = firebase.firestore.Timestamp.fromDate(date);
  //   return timestamp;
  // }

  // public converseFirebaseTimestampToDate(timestamp: firebase.firestore.Timestamp) {
  //   return timestamp.toDate();
  // }


  switchArrow(Fresh_Test_Data) {
    var downArrow = 'assets/images/test/downArrow.png'
    var upArrow = 'assets/images/test/upArrow.png'
    this.concArrow = undefined;
    this.motPRNPArrow = undefined;
    this.progMSCbArrow = undefined;
    this.progMSCaArrow = undefined;
    this.progMSCArrow = undefined;
    this.normFrmsArrow = undefined;
    this.tscArrow = undefined;
    this.mscArrow = undefined;
    this.smiArrow = undefined;
    this.motilespermArrow = undefined;
    this.progressiveArrow = undefined;
    this.nonprogressiveArrow = undefined;
    this.immotileArrow = undefined;
    this.pmscaArrow = undefined;
    this.pmscbArrow = undefined;
    this.fscArrow = undefined;
    this.velocity = undefined;
    this.totalFscArrow = undefined;
    this.totalPmscArrow = undefined;
    this.totalMorphArrow = undefined;
    this.slowprogArrow = undefined;
    this.rapidProgArrow = undefined;
    var references: any = Fresh_Test_Data.testSettings.referenceSettings
    // console.log("freshData", Fresh_Test_Data);

    for (const key in references) {

      if (key != "use_WHO_RefValue_def" && references[key] !== null && references[key] !== undefined) {
        switch (references[key][0]) {
          case '<=':
            if (key == 'concentration') {

              if (!(Number(Fresh_Test_Data.concentration) <= Number(references[key][1])) && Number(Fresh_Test_Data.concentration) != -500) {
                this.concArrow = upArrow;
              }

            } else if (key == 'total_motile_pr_np') {
              if (!(Number(Fresh_Test_Data.totalMotilePRNP.toFixed()) <= Number(references[key][1]))) {

                this.motPRNPArrow = upArrow;
              }
            } else if (key == 'progressive_pr') {
              if (!(Number(Fresh_Test_Data.progressivePR.toFixed()) <= Number(references[key][1]))) {

                this.progressiveArrow = upArrow;
              }
            } else if (key == 'rapid_progressive_a') {
              if (!(Number(this.Fresh_Test_Data.rapidProgressiveA) <= Number(references[key][1]))) {

                this.rapidProgArrow = upArrow;
              }
            } else if (key == 'slow_progressive_b') {
              if (!(Number(this.Fresh_Test_Data.slowProgressiveB) <= Number(references[key][1]))) {

                this.slowprogArrow = upArrow;
              }
            } else if (key == 'non_progressive_np') {
              if (!(Number(Fresh_Test_Data.non_progressive_np.toFixed()) <= Number(references[key][1])) && (!Fresh_Test_Data.testSettings.referenceSettings.use_WHO_RefValue_def || Fresh_Test_Data.testSettings.who == 4)) {

                this.nonprogressiveArrow = upArrow;
              }
            } else if (key == 'immotile') {
              if (!(Number(Fresh_Test_Data.immotile.toFixed()) <= Number(references[key][1])) && (!Fresh_Test_Data.testSettings.referenceSettings.use_WHO_RefValue_def || Fresh_Test_Data.testSettings.who == 4)) {

                this.immotileArrow = upArrow;
              }
            } else if (key == 'prog_motile_sperm_conc') {
              if (!(Number(Fresh_Test_Data.progMotileSpermConc) <= Number(references[key][1])) && !Fresh_Test_Data.testSettings.referenceSettings.use_WHO_RefValue_def) {

                this.progMSCArrow = upArrow;
              }
            } else if (key == 'normal_forms') {
              if (!(Number(Fresh_Test_Data.normalForms) <= Number(references[key][1]))) {

                this.normFrmsArrow = upArrow;
              }
            } else if (key == 'sperm_motility_index') {
              if (Fresh_Test_Data.spermMotilityIndex >= 0) {
                if (!(Number(Fresh_Test_Data.spermMotilityIndex) <= Number(references[key][1])) && !Fresh_Test_Data.testSettings.referenceSettings.use_WHO_RefValue_def) {

                  this.smiArrow = upArrow;
                }
              } else {
                this.smiArrow = undefined
              }
            } else if (key == 'total_sperm_count') {

              if (!(Number(Fresh_Test_Data.spermCount) <= Number(references[key][1]))) {

                this.tscArrow = upArrow;
              }
            } else if (key == 'motile_sperm_conc') {
              if (!(Number(Fresh_Test_Data.motileSpermConc) <= Number(references[key][1])) && !Fresh_Test_Data.testSettings.referenceSettings.use_WHO_RefValue_def) {

                this.mscArrow = upArrow;
              }
            } else if (key == 'motile_sperm') {
              if (!(Number(Fresh_Test_Data.motileSperm) <= Number(references[key][1])) && !Fresh_Test_Data.testSettings.referenceSettings.use_WHO_RefValue_def) {

                this.motilespermArrow = upArrow;
              }
            } else if (key == 'prog_motile_sperm_conc_a') {
              if (!(Number(Fresh_Test_Data.progMotileSpermConcA) <= Number(references[key][1]))) {
                this.pmscaArrow = upArrow;
              }
            } else if (key == 'prog_motile_sperm_conc_b') {
              if (!(Number(Fresh_Test_Data.progMotileSpermConcB) <= Number(references[key][1]))) {
                this.pmscbArrow = upArrow;
              }
            } else if (key == 'fsc') {
              if (!(Number(Fresh_Test_Data.fsc) <= Number(references[key][1]))) {
                this.fscArrow = upArrow;
              }
            } else if (key == 'velocity') {
              if (!(Number(Fresh_Test_Data.velocity) <= Number(references[key][1]))) {
                this.velocity = upArrow;
              }
            } else if (key == 'totalFSC') {
              if (!(Number(Fresh_Test_Data.totalFsc) <= Number(references[key][1]))) {
                this.totalFscArrow = upArrow;
              }
            } else if (key == 'totalPMSC') {
              if (!(Number(Fresh_Test_Data.totalPmsc) <= Number(references[key][1]))) {
                this.totalPmscArrow = upArrow;
              }
            } else if (key == 'totalMorph') {
              if (!(Number(Fresh_Test_Data.totalMorph) <= Number(references[key][1]))) {
                this.totalMorphArrow = upArrow;
              }
            }
            break;
          case '>=':
            if (key == 'concentration') {

              if (!(Number(Fresh_Test_Data.concentration) >= Number(references[key][1])) && Number(Fresh_Test_Data.concentration) != -500) {

                this.concArrow = downArrow;
              }
            } else if (key == 'total_motile_pr_np') {

              if (!(Number(Fresh_Test_Data.totalMotilePRNP.toFixed()) >= Number(references[key][1]))) {
                this.motPRNPArrow = downArrow;
              }
            } else if (key == 'progressive_pr') {
              if (!(Number(Fresh_Test_Data.progressivePR.toFixed()) >= Number(references[key][1]))) {

                this.progressiveArrow = downArrow;
              }
            } else if (key == 'non_progressive_np') {
              if (!(Number(Fresh_Test_Data.non_progressive_np.toFixed()) >= Number(references[key][1])) && (!Fresh_Test_Data.testSettings.referenceSettings.use_WHO_RefValue_def)) {

                this.nonprogressiveArrow = downArrow;
              }
            } else if (key == 'immotile') {
              if (!(Number(Fresh_Test_Data.immotile.toFixed()) >= Number(references[key][1])) && !Fresh_Test_Data.testSettings.referenceSettings.use_WHO_RefValue_def) {

                this.immotileArrow = downArrow;
              }
            } else if (key == 'prog_motile_sperm_conc') {
              if (!(Number(Fresh_Test_Data.progMotileSpermConc) >= Number(references[key][1])) && !Fresh_Test_Data.testSettings.referenceSettings.use_WHO_RefValue_def) {

                this.progMSCArrow = downArrow;
                // console.log(this.progMSCArrow, "PMSC arrow");

              }
            } else if (key == 'normal_forms') {
              if (!(Number(Fresh_Test_Data.normalForms) >= Number(references[key][1]))) {

                this.normFrmsArrow = downArrow;
              }
            } else if (key == 'sperm_motility_index') {
              if (Fresh_Test_Data.spermMotilityIndex >= 0) {
                if (!(Number(Fresh_Test_Data.spermMotilityIndex) >= Number(references[key][1])) && !Fresh_Test_Data.testSettings.referenceSettings.use_WHO_RefValue_def) {

                  this.smiArrow = downArrow;
                }
              } else {
                this.smiArrow = undefined
              }
            } else if (key == 'total_sperm_count') {

              if (!(Number(Fresh_Test_Data.spermCount) >= Number(references[key][1]))) {

                this.tscArrow = downArrow;
              }

            } else if (key == 'motile_sperm_conc') {
              if (!(Number(Fresh_Test_Data.motileSpermConc) >= Number(references[key][1])) && !Fresh_Test_Data.testSettings.referenceSettings.use_WHO_RefValue_def) {

                this.mscArrow = downArrow;
              }
            } else if (key == 'motile_sperm') {
              if (!(Number(Fresh_Test_Data.motileSperm) >= Number(references[key][1])) && !Fresh_Test_Data.testSettings.referenceSettings.use_WHO_RefValue_def) {

                this.motilespermArrow = downArrow;
              }
            } else if (key == 'rapid_progressive_a') {
              if (!(Number(Fresh_Test_Data.rapidProgressiveA) >= Number(references[key][1]))) {

                this.rapidProgArrow = downArrow
              }
            } else if (key == 'slow_progressive_b') {
              if (!(Number(Fresh_Test_Data.slowProgressiveB) >= Number(references[key][1]))) {

                this.slowprogArrow = downArrow
              }
            } else if (key == 'prog_motile_sperm_conc_a') {
              if (!(Number(Fresh_Test_Data.progMotileSpermConcA) >= Number(references[key][1]))) {
                this.pmscaArrow = downArrow
              }
            } else if (key == 'prog_motile_sperm_conc_b') {
              if (!(Number(Fresh_Test_Data.progMotileSpermConcB) >= Number(references[key][1]))) {
                this.pmscbArrow = downArrow
              }
            } else if (key == 'fsc') {
              if (!(Number(Fresh_Test_Data.fsc) >= Number(references[key][1]))) {
                this.fscArrow = downArrow
              }
            } else if (key == 'velocity') {
              if (!(Number(Fresh_Test_Data.velocity) >= Number(references[key][1]))) {
                this.velocity = downArrow
              }
            } else if (key == 'totalFSC') {
              if (!(Number(Fresh_Test_Data.totalFsc) >= Number(references[key][1]))) {
                this.totalFscArrow = downArrow
              }
            } else if (key == 'totalPMSC') {
              if (!(Number(Fresh_Test_Data.totalPmsc) >= Number(references[key][1]))) {
                this.totalPmscArrow = downArrow
              }
            } else if (key == 'totalMorph') {
              if (!(Number(Fresh_Test_Data.totalMorph) >= Number(references[key][1]))) {
                this.totalMorphArrow = downArrow
              }
            }
            break;
          case '<':
            if (key == 'concentration') {

              if (!(Number(Fresh_Test_Data.concentration) < Number(references[key][1])) && Number(Fresh_Test_Data.concentration) != -500) {
                this.concArrow = upArrow;
              }
            } else if (key == 'total_motile_pr_np') {
              if (!(Number(Fresh_Test_Data.totalMotilePRNP.toFixed()) < Number(references[key][1]))) {

                this.motPRNPArrow = upArrow;
              }
            } else if (key == 'progressive_pr') {
              if (!(Number(Fresh_Test_Data.progressivePR.toFixed()) < Number(references[key][1]))) {
                this.progressiveArrow = upArrow;
              }
            } else if (key == 'non_progressive_np') {
              if (!(Number(Fresh_Test_Data.non_progressive_np.toFixed()) < Number(references[key][1])) && !Fresh_Test_Data.testSettings.referenceSettings.use_WHO_RefValue_def) {
                this.nonprogressiveArrow = upArrow;
              }
            } else if (key == 'immotile') {
              if (!(Number(Fresh_Test_Data.immotile.toFixed()) < Number(references[key][1])) && !Fresh_Test_Data.testSettings.referenceSettings.use_WHO_RefValue_def) {
                this.immotileArrow = upArrow;
              }
            } else if (key == 'prog_motile_sperm_conc') {
              if (!(Number(Fresh_Test_Data.progMotileSpermConc) < Number(references[key][1])) && !Fresh_Test_Data.testSettings.referenceSettings.use_WHO_RefValue_def) {

                this.progMSCArrow = upArrow;
              }
            } else if (key == 'normal_forms') {
              if (!(Number(Fresh_Test_Data.normalForms) < Number(references[key][1]))) {
                this.normFrmsArrow = upArrow;
              }
            } else if (key == 'sperm_motility_index') {
              if (Fresh_Test_Data.spermMotilityIndex >= 0) {
                if (!(Number(Fresh_Test_Data.spermMotilityIndex) < Number(references[key][1])) && !Fresh_Test_Data.testSettings.referenceSettings.use_WHO_RefValue_def) {
                  this.smiArrow = upArrow;
                }
              } else {
                this.smiArrow = undefined
              }
            } else if (key == 'total_sperm_count') {

              if (!(Number(Fresh_Test_Data.spermCount) < Number(references[key][1]))) {

                this.tscArrow = upArrow;
              }

            } else if (key == 'motile_sperm_conc') {
              if (!(Number(Fresh_Test_Data.motileSpermConc) < Number(references[key][1])) && !Fresh_Test_Data.testSettings.referenceSettings.use_WHO_RefValue_def) {

                this.mscArrow = upArrow;
              }
            } else if (key == 'motile_sperm') {
              if (!(Number(Fresh_Test_Data.motileSperm) < Number(references[key][1])) && !Fresh_Test_Data.testSettings.referenceSettings.use_WHO_RefValue_def) {

                this.motilespermArrow = upArrow;
              }
            } else if (key == 'rapid_progressive_a') {
              if (!(Number(Fresh_Test_Data.rapidProgressiveA) < Number(references[key][1]))) {
                this.rapidProgArrow = upArrow
              }
            } else if (key == 'slow_progressive_b') {
              if (!(Number(Fresh_Test_Data.slowProgressiveB) < Number(references[key][1]))) {
                this.slowprogArrow = upArrow
              }
            } else if (key == 'prog_motile_sperm_conc_a') {
              if (!(Number(Fresh_Test_Data.progMotileSpermConcA) < Number(references[key][1]))) {
                this.pmscaArrow = upArrow
              }
            } else if (key == 'prog_motile_sperm_conc_b') {
              if (!(Number(Fresh_Test_Data.progMotileSpermConcB) < Number(references[key][1]))) {
                this.pmscbArrow = upArrow
              }
            } else if (key == 'fsc') {
              if (!(Number(Fresh_Test_Data.fsc) < Number(references[key][1]))) {
                this.fscArrow = upArrow
              }
            } else if (key == 'velocity') {
              if (!(Number(Fresh_Test_Data.velocity) < Number(references[key][1]))) {
                this.velocity = upArrow
              }
            } else if (key == 'totalFSC') {
              !environment.production && console.log(this.totalFscArrow, Fresh_Test_Data, references[key][1]);

              if (!(Number(Fresh_Test_Data.totalFsc) < Number(references[key][1]))) {
                this.totalFscArrow = upArrow
              }
            } else if (key == 'totalPMSC') {
              if (!(Number(Fresh_Test_Data.totalPmsc) < Number(references[key][1]))) {
                this.totalPmscArrow = upArrow
              }
            } else if (key == 'totalMorph') {
              if (!(Number(Fresh_Test_Data.totalMorph) < Number(references[key][1]))) {
                this.totalMorphArrow = upArrow
              }
            }
            break;
          case '>':
            if (key == 'concentration') {

              if (!(Number(Fresh_Test_Data.concentration) > Number(references[key][1])) && Number(Fresh_Test_Data.concentration) != -500 && !(Fresh_Test_Data.concentration > 2 && this.handlingnegSigns(Fresh_Test_Data.motileSpermConc) == "< 0.2")) {
                this.concArrow = downArrow;
              }
            } else if (key == 'total_motile_pr_np') {

              if (!(Number(Fresh_Test_Data.totalMotilePRNP.toFixed()) > Number(references[key][1]))) {
                this.motPRNPArrow = downArrow;
              }
            } else if (key == 'progressive_pr') {
              if (!(Number(Fresh_Test_Data.progressivePR.toFixed()) > Number(references[key][1]))) {
                this.progressiveArrow = downArrow;
              }
            } else if (key == 'non_progressive_np') {
              if (!(Number(Fresh_Test_Data.non_progressive_np.toFixed()) > Number(references[key][1])) && !Fresh_Test_Data.testSettings.referenceSettings.use_WHO_RefValue_def) {
                this.nonprogressiveArrow = downArrow;
              }
            } else if (key == 'immotile') {
              if (!(Number(Fresh_Test_Data.immotile.toFixed()) > Number(references[key][1])) && !Fresh_Test_Data.testSettings.referenceSettings.use_WHO_RefValue_def) {
                this.immotileArrow = downArrow;
              }
            } else if (key == 'prog_motile_sperm_conc') {
              if (!(Number(Fresh_Test_Data.progMotileSpermConc) > Number(references[key][1])) && !Fresh_Test_Data.testSettings.referenceSettings.use_WHO_RefValue_def) {

                this.progMSCArrow = downArrow;
              }
            } else if (key == 'normal_forms') {
              if (!(Number(Fresh_Test_Data.normalForms) > Number(references[key][1]))) {

                this.normFrmsArrow = downArrow;
              }
            } else if (key == 'sperm_motility_index') {
              if (Fresh_Test_Data.spermMotilityIndex >= 0) {
                if (!(Number(Fresh_Test_Data.spermMotilityIndex) > Number(references[key][1])) && !Fresh_Test_Data.testSettings.referenceSettings.use_WHO_RefValue_def) {

                  this.smiArrow = downArrow;
                }
              } else {
                this.smiArrow = undefined
              }
            } else if (key == 'total_sperm_count') {

              if (!(Number(Fresh_Test_Data.spermCount) > Number(references[key][1]))) {

                this.tscArrow = downArrow;
              }

            } else if (key == 'motile_sperm_conc') {
              if (!(Number(Fresh_Test_Data.motileSpermConc) > Number(references[key][1])) && !Fresh_Test_Data.testSettings.referenceSettings.use_WHO_RefValue_def) {

                this.mscArrow = downArrow;
              }
            } else if (key == 'motile_sperm') {
              if (!(Number(Fresh_Test_Data.motileSperm) > Number(references[key][1])) && !Fresh_Test_Data.testSettings.referenceSettings.use_WHO_RefValue_def) {

                this.motilespermArrow = downArrow;
              }
            } else if (key == 'rapid_progressive_a') {
              if (!(Number(Fresh_Test_Data.rapidProgressiveA) > Number(references[key][1]))) {

                this.rapidProgArrow = downArrow
              }
            } else if (key == 'slow_progressive_b') {
              if (!(Number(Fresh_Test_Data.slowProgressiveB) > Number(references[key][1]))) {

                this.slowprogArrow = downArrow
              }
            } else if (key == 'prog_motile_sperm_conc_a') {

              if (!(Number(Fresh_Test_Data.progMotileSpermConcA) > Number(references[key][1]))) {
                this.pmscaArrow = downArrow

              }
            } else if (key == 'prog_motile_sperm_conc_b') {
              if (!(Number(Fresh_Test_Data.progMotileSpermConcB) > Number(references[key][1]))) {
                this.pmscbArrow = downArrow
              }
            } else if (key == 'fsc') {
              if (!(Number(Fresh_Test_Data.fsc) > Number(references[key][1]))) {
                this.fscArrow = downArrow
              }
            } else if (key == 'velocity') {
              if (!(Number(Fresh_Test_Data.velocity) > Number(references[key][1]))) {
                this.velocity = downArrow
              }
            } else if (key == 'totalFSC') {
              if (!(Number(Fresh_Test_Data.totalFsc) > Number(references[key][1]))) {
                this.totalFscArrow = downArrow
              }
            } else if (key == 'totalPMSC') {
              if (!(Number(Fresh_Test_Data.totalPmsc) > Number(references[key][1]))) {
                this.totalPmscArrow = downArrow
              }
            } else if (key == 'totalMorph') {
              if (!(Number(Fresh_Test_Data.totalMorph) > Number(references[key][1]))) {
                this.totalMorphArrow = downArrow
              }
            }

            break;
          case '=':
            if (key == 'concentration') {

              if (Fresh_Test_Data.concentration != references[key][1] && !(Fresh_Test_Data.concentration > 2 && this.handlingnegSigns(Fresh_Test_Data.motileSpermConc) == "< 0.2")) {
                if (Number(Fresh_Test_Data.concentration) > Number(references[key][1])) {
                  if (Fresh_Test_Data.concentration != -500) {
                    this.concArrow = upArrow;
                  }
                } else {
                  if (Fresh_Test_Data.concentration != -500) {
                    this.concArrow = downArrow;
                  }
                }
              }

            } else if (key == 'total_motile_pr_np') {
              if (Fresh_Test_Data.totalMotilePRNP.toFixed() != references[key][1]) {
                if (Number(Fresh_Test_Data.totalMotilePRNP.toFixed()) > Number(references[key][1])) {
                  this.motPRNPArrow = upArrow;
                } else {
                  this.motPRNPArrow = downArrow;
                }
              }
            } else if (key == 'progressive_pr') {
              if (Fresh_Test_Data.progressivePR.toFixed() != references[key][1]) {
                if (Number(Fresh_Test_Data.progressivePR.toFixed()) > Number(references[key][1])) {
                  this.progressiveArrow = upArrow;
                } else {
                  this.progressiveArrow = downArrow;
                }
              }
            } else if (key == 'non_progressive_np') {
              if (Fresh_Test_Data.non_progressive_np.toFixed() != references[key][1] && !Fresh_Test_Data.testSettings.referenceSettings.use_WHO_RefValue_def) {
                if (Number(Fresh_Test_Data.non_progressive_np.toFixed()) > Number(references[key][1])) {
                  this.nonprogressiveArrow = upArrow;
                } else {
                  this.nonprogressiveArrow = downArrow;
                }
              }
            } else if (key == 'immotile') {
              if (Fresh_Test_Data.immotile.toFixed() != references[key][1] && !Fresh_Test_Data.testSettings.referenceSettings.use_WHO_RefValue_def) {
                if (Number(Fresh_Test_Data.immotile.toFixed()) > Number(references[key][1])) {
                  this.immotileArrow = upArrow;
                } else {
                  this.immotileArrow = downArrow;
                }
              }
            } else if (key == 'prog_motile_sperm_conc') {
              if (!Fresh_Test_Data.testSettings.referenceSettings.use_WHO_RefValue_def) {
                if (Fresh_Test_Data.progMotileSpermConc != references[key][1]) {
                  if (Number(Fresh_Test_Data.progMotileSpermConc) > Number(references[key][1])) {
                    this.progMSCArrow = upArrow;
                  } else {
                    this.progMSCArrow = downArrow;
                  }
                }
              }
            } else if (key == 'normal_forms') {
              if (Fresh_Test_Data.normalForms != references[key][1]) {
                if (Number(Fresh_Test_Data.normalForms) > Number(references[key][1])) {
                  this.normFrmsArrow = upArrow;
                } else {
                  this.normFrmsArrow = downArrow;
                }
              }
            } else if (key == 'sperm_motility_index') {
              if (Fresh_Test_Data.spermMotilityIndex != references[key][1] && !Fresh_Test_Data.testSettings.referenceSettings.use_WHO_RefValue_def) {
                // console.log("SMIIIIIIIII", Fresh_Test_Data.spermMotilityIndex);
                if (Fresh_Test_Data.spermMotilityIndex >= 0 && this.handlingnegSigns(Fresh_Test_Data.spermMotilityIndex, "SMI") != "N/A") {
                  if (Number(Fresh_Test_Data.spermMotilityIndex) > Number(references[key][1])) {
                    this.smiArrow = upArrow;
                  } else {
                    this.smiArrow = downArrow;
                  }
                } else {
                  this.smiArrow = undefined
                }
              }
            } else if (key == 'total_sperm_count') {

              if (Fresh_Test_Data.spermCount != references[key][1] && !(Fresh_Test_Data.concentration > 2 && this.handlingnegSigns(Fresh_Test_Data.motileSpermConc) == "< 0.2")) {
                if (Number(Fresh_Test_Data.spermCount) > Number(references[key][1])) {
                  this.tscArrow = upArrow;
                } else {
                  this.tscArrow = downArrow;
                }
              }

            } else if (key == 'motile_sperm_conc') {

              if (!Fresh_Test_Data.testSettings.referenceSettings.use_WHO_RefValue_def) {
                if (Fresh_Test_Data.motileSpermConc != references[key][1]) {
                  if (Number(Fresh_Test_Data.motileSpermConc) > Number(references[key][1])) {
                    this.mscArrow = upArrow;
                  } else {
                    this.mscArrow = downArrow;
                  }
                }
              }
            } else if (key == 'motile_sperm') {
              if (!Fresh_Test_Data.testSettings.referenceSettings.use_WHO_RefValue_def) {
                if (Fresh_Test_Data.motileSperm != references[key][1]) {
                  if (Number(Fresh_Test_Data.motileSperm) > Number(references[key][1])) {
                    this.motilespermArrow = upArrow;
                  } else {
                    this.motilespermArrow = downArrow;
                  }
                }
              }
            } else if (key == 'rapid_progressive_a') {
              if (Fresh_Test_Data.rapidProgressiveA != references[key][1]) {
                if (Number(Fresh_Test_Data.rapidProgressiveA) > Number(references[key][1])) {
                  this.rapidProgArrow = upArrow
                } else {
                  this.rapidProgArrow = downArrow
                }
              }
            } else if (key == 'slow_progressive_b') {
              if (Fresh_Test_Data.slowProgressiveB != references[key][1]) {
                if (Number(Fresh_Test_Data.slowProgressiveB) > Number(references[key][1])) {
                  this.slowprogArrow = upArrow
                } else {
                  this.slowprogArrow = downArrow
                }
              }
            } else if (key == 'prog_motile_sperm_conc_a') {
              if (Fresh_Test_Data.progMotileSpermConcA != references[key][1]) {
                if (Number(Fresh_Test_Data.progMotileSpermConcA) > Number(references[key][1])) {
                  this.pmscaArrow = upArrow
                } else {
                  this.pmscaArrow = downArrow
                }
              }
            } else if (key == 'prog_motile_sperm_conc_b') {
              if (Fresh_Test_Data.progMotileSpermConcB != references[key][1]) {
                if (Number(Fresh_Test_Data.progMotileSpermConcB) > Number(references[key][1])) {
                  this.pmscbArrow = upArrow
                } else {
                  this.pmscbArrow = downArrow
                }
              }
            } else if (key == 'fsc') {
              if (Fresh_Test_Data.fsc != references[key][1]) {
                if (Number(Fresh_Test_Data.fsc) > Number(references[key][1])) {
                  this.fscArrow = upArrow
                } else {
                  this.fscArrow = downArrow
                }
              }
            } else if (key == 'velocity') {
              if (Fresh_Test_Data.velocity != references[key][1]) {
                if (Number(Fresh_Test_Data.velocity) > Number(references[key][1])) {
                  this.velocity = upArrow
                } else {
                  this.pmscbArrow = downArrow
                }
              }
            } else if (key == 'totalFSC') {
              if (Fresh_Test_Data.totalFsc != references[key][1]) {
                if (Number(Fresh_Test_Data.totalFsc) > Number(references[key][1])) {
                  this.totalFscArrow = upArrow
                } else {
                  this.totalFscArrow = downArrow
                }
              }
            } else if (key == 'totalPMSC') {
              if (Fresh_Test_Data.totalPmsc != references[key][1]) {

                if (Number(Fresh_Test_Data.totalPmsc) > Number(references[key][1])) {
                  this.totalPmscArrow = upArrow
                } else {
                  this.totalPmscArrow = downArrow
                }
              }
            } else if (key == 'totalMorph') {
              if (Fresh_Test_Data.totalMorph != references[key][1]) {
                if (Number(Fresh_Test_Data.totalMorph) > Number(references[key][1])) {
                  this.totalMorphArrow = upArrow
                } else {
                  this.totalMorphArrow = downArrow
                }
              }
            }
            break;
          default:
            break;
        }
      }
    }
  }
  callFactilitySettings() {
    // console.log('facility settings')
    if (this.navToFacilitySet == true) {
      $(function () {
        $('#myTab a[href="#facility_settings"]').tab('show');
      })
      this.navToFacilitySet = false;
      this.navToFacilityStay = true;

    }
    else {
      // console.log("no navigation");
    }
  }
  callFactilityUserSettings() {
    // console.log('user settings')

    if (this.navToFacilitySet == true) {
      $(function () {
        $('#myTab a[href="#user_profile"]').tab('show');
      })
      this.navToFacilitySet = false;
      this.navToFacilityStay = true;

    }
    else {
      // console.log("no navigation");
    }
  }
  translateUnit() {
    // console.log("translateUnit");

    this.http.getSettings().then((data: any) => {
      // console.log("global data", data);
      this.heightUnit = data.data.testSettings.heightUnit;
      this.weightUnit = data.data.testSettings.weightUnit;
      // console.log("weightUnit", this.weightUnit);

      if (this.heightUnit == 'inches') {
        this.heightUnit = 'ft';
      }
      if (this.weightUnit == 'pounds') {
        this.weightUnit = 'Lbs';
      }
      if (this.heightUnit == 'ft') {
        this.heightUnit = this.translate.instant('units.ft');
      }
      if (this.heightUnit == 'cm') {
        this.heightUnit = this.translate.instant('units.cm');
      }
      if (this.weightUnit == 'kg') {
        this.weightUnit = this.translate.instant('units.kg');
      }
      if (this.weightUnit == 'lb') {
        this.weightUnit = this.translate.instant('units.lb');
      }
      // console.log("height glob", this.heightUnit, this.weightUnit);
    });


  }
  calculateAge() {
    console.log(this.Patient_Info.birthDate);

    if ((this.facilityHeader.testSettings.systemSettings as SystemSettings).isPrivacyMode == true) {
      this.dateandAge = ''
      return
    }
    if (this.Fresh_Test_Data.testSettings.patientAge == "BirthDate") {
      if (this.Patient_Info.birthDate && this.Patient_Info.birthDate !== "" && this.Patient_Info.birthDate !== undefined) {
        // try {
        var birthDate: any = 'N/A'
        // console.log(this.Patient_Info.birthDate, "check");
        try {
          var formattedBday: any = formatDate(this.Patient_Info.birthDate.toDate(), 'dd/MM/yyyy', 'en')
          birthDate = this.customPipe.transform(this.Patient_Info.birthDate.toDate(), this.pcDateFormat, this.translate.currentLang, true)
        } catch (error) {
          var formattedBday: any = formatDate(this.Patient_Info.birthDate, 'dd/MM/yyyy', 'en')
          birthDate = this.customPipe.transform(this.Patient_Info.birthDate, this.pcDateFormat, this.translate.currentLang, true)

        }
        !environment.production && console.log(formattedBday);

        // } catch (error) {
        //   var formattedBday: any = formatDate(new Date(this.Patient_Info.birthDate), 'dd/MM/yyyy', 'en')
        //   console.log(this.Patient_Info.birthDate,formattedBday,"check2");
        // }
        // if (this.fromDataentry) {
        //   var formattedBday: any = formatDate(new Date(this.Patient_Info.birthDate), 'dd/MM/yyyy', 'en')
        //   birthDate = this.customPipe.transform(this.Patient_Info.birthDate, this.pcDateFormat, this.translate.currentLang)

        // }
        var bday = new Date(formattedBday.substr(formattedBday.length - 4), formattedBday.substr(3, 2) - 1, formattedBday.substr(0, 2));
        var timeDiff = Math.abs(Date.now() - bday.getTime());
        this.age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
        this.dateandAge = birthDate + " | " + this.age.toString();
      } else {
        this.dateandAge = 'N/A'
      }

    }
    else {

      // this.Patient_Info=this.Patient_Info.sampleInformation.patientAge;
      if (this.Fresh_Test_Data.sampleInformation.patientAge)
        this.dateandAge = this.Fresh_Test_Data.sampleInformation.patientAge.toString()
      else
        this.dateandAge = 'N/A'
      // console.log("?????", this.Fresh_Test_Data.sampleInformation.patientAge.toString());

    }

  }


  async parametersTable(dataURL, Fresh_Test_Data, who, testDt) {
    const resultRestrictionArr = this._authService.iOwMode == true ? ['spermMotilityIndex', 'velocity', 'fsc',
      'totalFsc', 'totalMorph', 'spermCount', 'motileSperm', 'totalPmsc'] : this.facilityHeader.resultsRestriction
    const ART_PREP_LimitedParams: string[] = [this.translateNreturn('test.totals_per_ejac', true), this.translateNreturn("test_process.time", true), this.translateNreturn('settings.motile_conc', true), `${who == 4 ? `\u200B\t` : ``} ${this.translateNreturn('settings.prog_mot', true)}`, `\u200B\t\u200B\t ${this.translateNreturn('test.rapid_msc', true)}`, `\u200B\t\u200B\t ${this.translateNreturn('test.slow_msc', true)}`, this.translateNreturn('test.velocity', true), this.translateNreturn('settings.sperm_motility_index', true), this.translateNreturn('settings.mot_spr_conc', true), this.translateNreturn('test.tota_progmotilesperm', true)]
    var dataArray = [];
    let tablearray1 = [];
    let tablearray2 = [];
    var redArrow = 'assets/images/test/downArrow.png'
    var greenArrow = 'assets/images/test/upArrow.png'
    var manualIcon = 'assets/images/test/manualicon.jpg'
    var concVal;
    var spermCountVal;
    var concValStyle = 'tabledata';
    var ismanualEntryAvailable: boolean = false;
    var displayNa = false
    let pagebreak = 'after'
    const getRef = (refArray, checkDefault?: boolean): string => {
      return refArray != undefined && refArray[1] != null ? refArray[0] + refArray[1] : ' '
    }
    const getArrow = async (arrowCheck, valueCheck) => {
      !environment.production && console.log(arrowCheck, valueCheck);

      return arrowCheck && this.handlingnegSigns(valueCheck) != 'N/A' ? { image: await this.getBase64ImageFromURL(arrowCheck), fit: [30, 30], style: 'tabledata', margin: [0, 1, 0, 0] } : { text: ' ', style: 'anotherStyle' }
    }
    concVal = Fresh_Test_Data.concentration
    spermCountVal = Fresh_Test_Data.spermCount
    var rowspan = 11
    //display graph incase of motility estimation is erased after entering some value returs true to show graph
    const checkToDisplayGraph = () => {
      if (this.displayAllParams) {
        if (Fresh_Test_Data.motilityEstimationData != undefined) {
          return Fresh_Test_Data.motilityEstimationData?.totalMotility != 0
        } else {
          return true
        }
      } else {
        return false
      }
    }
    let isdisplayChart = (Fresh_Test_Data.testingMode == 2 && this.isdisplaychart == true) ? checkToDisplayGraph() : this.isdisplaychart
    !environment.production && console.log(Fresh_Test_Data, isdisplayChart, "----------------");
    if (who == 3) {
      rowspan = 11
      pagebreak = ''
      if (isdisplayChart == undefined) {
        if (Fresh_Test_Data.progressivePR >= 0 && Fresh_Test_Data.non_progressive_np >= 0 && Fresh_Test_Data.immotile >= 0 && !Fresh_Test_Data.mAllZeroValue) {
          isdisplayChart = true
        } else {
          isdisplayChart = false
        }
      }
    } else if (who == 4) {
      rowspan = 14
      pagebreak = 'before'
      if (isdisplayChart == undefined) {
        if (Fresh_Test_Data.non_progressive_np >= 0 && Fresh_Test_Data.rapidProgressiveA >= 0 && Fresh_Test_Data.slowProgressiveB >= 0 && Fresh_Test_Data.immotile >= 0 && !Fresh_Test_Data.mAllZeroValue) {
          isdisplayChart = true
        } else {
          isdisplayChart = false
        }
      }
    }
    if (this.Fresh_Test_Data.noSpermSeen === true) {
      isdisplayChart = false;
    }

    tablearray1 = [
      // //heading
      [{ text: this.translateNreturn('test.params', true), fillColor: '#B0E0E6', style: 'paraheader', alignment: 'left', bold: 'Helvetica-Bold' }, { text: this.translateNreturn('test.result', true), fillColor: '#B0E0E6', style: 'paraheader' }, { text: this.translateNreturn('units.units', true), fillColor: '#B0E0E6', style: 'paraheader' }, { text: this.translateNreturn('settings.ref_value', true), fillColor: '#B0E0E6', colSpan: 2, style: 'paraheader' }, { text: ' ', fillColor: '#B0E0E6', style: 'paraheader' }, { text: this.translateNreturn('test.motility_graph', true), fillColor: '#B0E0E6', style: 'paraheader' }],
      // //concentration
      [{ text: this.translateNreturn("settings.concentration", true), style: 'resultsParam' }, { text: this.handlingnegSigns(concVal), style: concValStyle, margin: [0, 3, 0, 0] }, { text: this.translateNreturn('units.M_ml', true, true).substring(1, this.translateNreturn('units.M_ml', true, true).length - 1), style: 'tabledata' }, { text: getRef(Fresh_Test_Data.testSettings.referenceSettings.concentration), style: 'tabledata' }, await getArrow(this.concArrow, concVal), isdisplayChart ? { image: await this.getBase64ImageFromURL(dataURL, true), fit: who == 4 && this._authService.iOwMode != true ? [220, 220] : [166, 166], rowSpan: rowspan - (this.facilityHeader.contentLimitation.hideParameters === true ? this.getParamsLengthInTables(resultRestrictionArr, 1) : 0), margin: [0, who == 4 ? 10 : 5, 0, 0], alignment: 'center' } : { text: this.translate.instant('test.no_graph_report'), rowSpan: rowspan - (this.facilityHeader.contentLimitation.hideParameters === true ? this.getParamsLengthInTables(resultRestrictionArr, 1) : 0), style: 'tabledata', margin: [0, 5, 0, 0], alignment: 'center' }],
      // // total motility
      [{ text: this.translateNreturn(who == 3 ? "settings.tot_pr" : "settings.motility", true).substring(0, this.translateNreturn(who == 3 ? "settings.tot_pr" : "settings.motility", true).length - 3), style: 'resultsParam' }, { text: this.handlingnegSigns(Fresh_Test_Data.totalMotilePRNP, "motility"), style: 'tabledata' }, { text: this.translateNreturn('units.percent', true, true).substring(1, this.translateNreturn('units.percent', true, true).length - 1), style: 'tabledata' }, { text: getRef(Fresh_Test_Data.testSettings.referenceSettings.total_motile_pr_np), style: 'tabledata' }, await getArrow(this.motPRNPArrow, Fresh_Test_Data.totalMotilePRNP)],
      // // progressive pr
      [{ text: `${who == 4 ? `\u200B\t` : ``} ${this.translateNreturn(who == 4 ? 'test.progressive' : 'test.prog_motility_pr', true).substring(0, this.translateNreturn(who == 4 ? 'test.progressive' : 'test.prog_motility_pr', true).length - 3)}`, style: 'resultsParam' }, { text: this.handlingnegSigns(Fresh_Test_Data.progressivePR, "motility"), style: 'tabledata' }, { text: this.translate.instant('units.percent').substring(1, this.translate.instant('units.percent').length - 1), style: 'tabledata' }, { text: getRef(Fresh_Test_Data.testSettings.referenceSettings.progressive_pr), style: 'tabledata' }, await getArrow(this.progressiveArrow, Fresh_Test_Data.progressivePR)],
      // //rapid and slow progressive
      ...(who == 4 ? [[{ text: `\u200B\t\u200B\t ${this.translateNreturn('test.rapid_prog', true).substring(0, this.translateNreturn('test.rapid_prog', true).length - 3)}`, style: 'resultsParam' }, { text: this.handlingnegSigns(Fresh_Test_Data.rapidProgressiveA, "motility"), style: 'tabledata' }, { text: this.translate.instant('units.percent').substring(1, this.translate.instant('units.percent').length - 1), style: 'tabledata' }, { text: getRef(Fresh_Test_Data.testSettings.referenceSettings.rapid_progressive_a), style: 'tabledata' }, await getArrow(this.rapidProgArrow, Fresh_Test_Data.rapidProgressiveA)],
      [{ text: `\u200B\t\u200B\t ${this.translateNreturn('test.slow_prog', true).substring(0, this.translateNreturn('test.slow_prog', true).length - 3)}`, style: 'resultsParam' }, { text: this.handlingnegSigns(Fresh_Test_Data.slowProgressiveB, "motility"), style: 'tabledata' }, { text: this.translate.instant('units.percent').substring(1, this.translate.instant('units.percent').length - 1), style: 'tabledata' }, { text: getRef(Fresh_Test_Data.testSettings.referenceSettings.slow_progressive_b), style: 'tabledata' }, await getArrow(this.slowprogArrow, Fresh_Test_Data.slowProgressiveB)]] : []),
      // //non-progressive
      [{ text: `${who == 4 ? `\u200B\t` : ``} ${this.translateNreturn(who == 3 ? 'test.non_prog_motility_np' : 'settings.non_progressive_pr', true).substring(0, this.translateNreturn(who == 3 ? 'test.non_prog_motility_np' : 'settings.non_progressive_pr', true).length - 3)}`, style: 'resultsParam' }, { text: this.handlingnegSigns(Fresh_Test_Data.non_progressive_np, "motility"), style: 'tabledata' }, { text: this.translate.instant('units.percent').substring(1, this.translate.instant('units.percent').length - 1), style: 'tabledata' }, { text: getRef(Fresh_Test_Data.testSettings.referenceSettings.non_progressive_np), style: 'tabledata' }, await getArrow(this.nonprogressiveArrow, Fresh_Test_Data.non_progressive_np)],
      // //Immotile
      [{ text: `${this.translateNreturn(who == 3 ? 'test.immotility' : 'settings.immotile_who6', true).substring(0, this.translateNreturn(who == 3 ? 'test.immotility' : 'settings.immotile_who6', true).length - 3)}`, style: 'resultsParam' }, { text: this.handlingnegSigns(Fresh_Test_Data.immotile, "motility"), style: 'tabledata' }, { text: this.translate.instant('units.percent').substring(1, this.translate.instant('units.percent').length - 1), style: 'tabledata' }, { text: getRef(Fresh_Test_Data.testSettings.referenceSettings.immotile), style: 'tabledata' }, await getArrow(this.immotileArrow, Fresh_Test_Data.immotile)],
      // //Normal forms
      [{ text: this.translateNreturn('settings.normal', true).substring(0, this.translateNreturn('settings.normal', true).length - 3), style: 'resultsParam' }, { text: this.handlingnegSigns(Fresh_Test_Data.normalForms, "normal_frms"), style: 'tabledata' }, { text: this.translate.instant('units.percent').substring(1, this.translate.instant('units.percent').length - 1), style: 'tabledata' }, { text: getRef(Fresh_Test_Data.testSettings.referenceSettings.normal_forms), style: 'tabledata' }, await getArrow(this.normFrmsArrow, Fresh_Test_Data.normalForms)],
      // //MSC
      [{ text: this.translateNreturn('settings.motile_conc', true), style: 'resultsParam' }, { text: this.handlingnegSigns(Fresh_Test_Data.motileSpermConc), style: 'tabledata' }, { text: this.translate.instant('units.M_ml').substring(1, this.translate.instant('units.M_ml').length - 1), style: 'tabledata' }, { text: getRef(Fresh_Test_Data.testSettings.referenceSettings.motile_sperm_conc), style: 'tabledata' }, await getArrow(this.mscArrow, Fresh_Test_Data.motileSpermConc)],
      // //PMSC
      [{ text: `${who == 4 ? `\u200B\t` : ``} ${this.translateNreturn('settings.prog_mot', true)}`, style: 'resultsParam' }, { text: this.handlingnegSigns(Fresh_Test_Data.progMotileSpermConc), style: 'tabledata' }, { text: this.translate.instant('units.M_ml').substring(1, this.translate.instant('units.M_ml').length - 1), style: 'tabledata' }, { text: getRef(Fresh_Test_Data.testSettings.referenceSettings.prog_motile_sperm_conc), style: 'tabledata' }, await getArrow(this.progMSCArrow, Fresh_Test_Data.progMotileSpermConc)],
      // //RPMSC & SPMSC
      ...(who == 4 ? [
        [{ text: `\u200B\t\u200B\t ${this.translateNreturn('test.rapid_msc', true)}`, style: 'resultsParam' }, { text: this.handlingnegSigns(Fresh_Test_Data.progMotileSpermConcA), style: 'tabledata' }, { text: this.translate.instant('units.M_ml').substring(1, this.translate.instant('units.M_ml').length - 1), style: 'tabledata' }, { text: getRef(Fresh_Test_Data.testSettings.referenceSettings.prog_motile_sperm_conc_a), style: 'tabledata' }, await getArrow(this.pmscaArrow, Fresh_Test_Data.progMotileSpermConcA)],
        [{ text: `\u200B\t\u200B\t ${this.translateNreturn('test.slow_msc', true)}`, style: 'resultsParam' }, { text: this.handlingnegSigns(Fresh_Test_Data.progMotileSpermConcB), style: 'tabledata' }, { text: this.translate.instant('units.M_ml').substring(1, this.translate.instant('units.M_ml').length - 1), style: 'tabledata' }, { text: getRef(Fresh_Test_Data.testSettings.referenceSettings.prog_motile_sperm_conc_b), style: 'tabledata' }, await getArrow(this.pmscbArrow, Fresh_Test_Data.progMotileSpermConcB)],
        // FSC
        [{ text: this.translateNreturn('test.fsc', true), style: 'resultsParam', label: 'fsc' }, { text: this.handlingnegSigns(Fresh_Test_Data.fsc), style: 'tabledata' }, { text: this.translate.instant('units.M_ml').substring(1, this.translate.instant('units.M_ml').length - 1), style: 'tabledata' }, { text: getRef(Fresh_Test_Data.testSettings.referenceSettings.fsc), style: 'tabledata' }, await getArrow(this.fscArrow, Fresh_Test_Data.fsc)],
        //  Velocity
        [{ text: this.translateNreturn('test.velocity', true), label: "velocity", style: 'resultsParam' }, { text: this.handlingnegSigns(Fresh_Test_Data.velocity, 'normal_frms'), style: 'tabledata' }, { text: this.translate.instant('units.mic_sec').substring(1, this.translate.instant('units.mic_sec').length - 1), style: 'tabledata' }, { text: getRef(Fresh_Test_Data.testSettings.referenceSettings.velocity), style: 'tabledata' }, await getArrow(this.velocity, Fresh_Test_Data.velocity)],
        //  SMI
        [{ text: this.translateNreturn('settings.sperm_motility_index', true), label: "spermMotilityIndex", style: 'resultsParam' }, { text: this.handlingnegSigns(Fresh_Test_Data.spermMotilityIndex, 'SMI', this.handlingnegSigns(Fresh_Test_Data.motileSpermConc)), style: 'tabledata' }, { text: '---', style: 'tabledata' }, { text: getRef(Fresh_Test_Data.testSettings.referenceSettings.sperm_motility_index), style: 'tabledata' }, await getArrow(this.smiArrow, Fresh_Test_Data.spermMotilityIndex)],
      ] : [
        // WHO3 SMI
        [{ text: this.translateNreturn('settings.sperm_motility_index', true), label: "spermMotilityIndex", style: 'resultsParam' }, { text: this.handlingnegSigns(Fresh_Test_Data.spermMotilityIndex, 'SMI', this.handlingnegSigns(Fresh_Test_Data.motileSpermConc)), style: 'tabledata' }, { text: '---', style: 'tabledata' }, { text: getRef(Fresh_Test_Data.testSettings.referenceSettings.sperm_motility_index), style: 'tabledata' }, await getArrow(this.smiArrow, Fresh_Test_Data.spermMotilityIndex)],
        // WHO3 totals header
        [{ text: this.translateNreturn('test.totals_per_ejac', true), fillColor: '#B0E0E6', colSpan: 5, style: 'paraheader', alignment: 'left', bold: 'Helvetica-Bold' }],
        // WHO3 total sperm
        [{ text: this.translateNreturn('settings.sperm', true), style: 'resultsParam', label: "spermCount" }, { text: this.handlingnegSigns(Fresh_Test_Data.spermCount), style: 'tabledata' }, { text: this.translate.instant('units.m_ejac').substring(1, this.translate.instant('units.m_ejac').length - 1), style: 'tabledata' }, { text: getRef(Fresh_Test_Data.testSettings.referenceSettings.total_sperm_count), style: 'tabledata' }, await getArrow(this.tscArrow, Fresh_Test_Data.spermCount)],
        // WHO3 totals mot sperm
        [{ text: this.translateNreturn('settings.mot_spr_conc', true), style: 'resultsParam', label: "motileSperm" }, { text: this.handlingnegSigns(Fresh_Test_Data.motileSperm), style: 'tabledata' }, { text: this.translate.instant('units.m_ejac').substring(1, this.translate.instant('units.m_ejac').length - 1), style: 'tabledata' }, { text: getRef(Fresh_Test_Data.testSettings.referenceSettings.motile_sperm), style: 'tabledata' }, await getArrow(this.motilespermArrow, Fresh_Test_Data.motileSperm)],
      ]),
    ].map((ele: any[], index) => {    // adding borders and fillcolors to all the cells
      return ele.map((innerEle, innerInd) => {
        if (who == 3 && index == 10 || index == 0) {  //skip totals header in who5
          return { ...innerEle, fillColor: '#B0E0E6', }
        }
        !environment.production && console.log(innerEle, index, who, innerInd, ele.length, index == 15 || (index == 11 && who == 3));
        return typeof innerEle == "object" ? {
          ...innerEle,
          //border for all the parameters 
          ...{ border: (((who == 4 && (index == 1 || index == 15)) || (who == 3 && (index == 1 || index == 12))) && innerInd == ele.length - 1) ? [true, ((index == 12) || index == 15) ? false : true, true, index == 1 ? false : true] : [true, true, true, true] },
          fillColor: index % 2 != 0 ? 'white' : '#eee',
        } : innerEle
      })
    }).filter(el => {
      if (this._authService.iOwMode == true) {
        return !resultRestrictionArr.includes(el[0].label)
      } else {
        if (el[0].label && this.facilityHeader.contentLimitation.hideParameters === true)
          return !this.facilityHeader.resultsRestriction.includes(el[0].label)
        else
          return true
      }
    })
    tablearray1.slice(-1)[0].push({ text: this.translateNreturn('test.mes_parameters_msg', true, true), style: 'tabledata', border: [true, false, true, true] } as any)

    let ART_PREP_tablearray = []
    let ART_PREP_tablearray2 = []
    !environment.production && console.log("FFFFFFFFFFFFFFFF", this.Fresh_Test_Data);

    if (this.Fresh_Test_Data.testingMode === 2) {
      const isFullArt = this.ART_PREP_Results[0] != undefined && this.ART_PREP_Results[1] != undefined
      const getARTPREPRowSpan = (isGraph: boolean) => {
        if (who == 4) {
          if (this.displayAllParams) {
            return (rowspan + 1) - this.getParamsLengthInTables(resultRestrictionArr, 1)
          } else {
            return (isGraph ? 10 : 9) - this.getParamsLengthInTables(resultRestrictionArr, 1)
          }
        } else {
          if (this.displayAllParams) {
            //+2 for sample evaluation and time
            return (rowspan + 2) - this.getParamsLengthInTables(resultRestrictionArr, 1)
          } else {
            return (isGraph ? 5 : 5) - this.getParamsLengthInTables(resultRestrictionArr, 1)
          }
        }
      }
      const filteredData = [
        // //heading
        [{ text: this.translateNreturn('test.params', true), fillColor: '#B0E0E6', style: 'paraheader', alignment: 'left', bold: 'Helvetica-Bold' }, { ...this.ART_PREP_Results[0] != undefined && { text: this.translateNreturn('test_process.pre_prep', true), fillColor: '#B0E0E6', style: 'paraheader' } }, { ...this.ART_PREP_Results[1] && { text: this.translateNreturn('test_process.post_prep', true), fillColor: '#B0E0E6', style: 'paraheader' } }, { text: this.translateNreturn('units.units', true), fillColor: '#B0E0E6', style: 'paraheader' }, { text: `${this.translateNreturn('test.motility_graph', true)} ${Fresh_Test_Data.testingMode == 2 ? `| ${this.translateNreturn(this.prepTypeTranslationKeys[Fresh_Test_Data.prepType], true)}` : ""}`, fillColor: '#B0E0E6', style: 'paraheader' }],

        [{ text: this.translateNreturn("test_process.time", true), style: 'resultsParam' }, { ...this.ART_PREP_Results[0] != undefined && { text: this.getDate(this.ART_PREP_Results[0].dateTime, true), style: concValStyle, margin: [0, 3, 0, 0] } }, { ...this.ART_PREP_Results[1] != undefined && { text: this.getDate(this.ART_PREP_Results[1].dateTime, true), style: concValStyle, margin: [0, 3, 0, 0] } }, { text: `---`, style: 'tabledata' }, isdisplayChart ? { image: await this.getBase64ImageFromURL(dataURL, true), fit: who == 4 ? [220, 220] : [166, 166], rowSpan: getARTPREPRowSpan(true), margin: [0, who == 4 ? 10 : 5, 0, 0], alignment: 'center' } : { text: this.translate.instant('test.no_graph_report'), rowSpan: getARTPREPRowSpan(false), style: 'tabledata', margin: [0, 5, 0, 0], alignment: 'center' }],
        //   // //concentration
        [{ text: this.translateNreturn("settings.concentration", true), lable: "concentration", style: 'resultsParam' }, { ...this.ART_PREP_Results[0] != undefined && { text: this.handlingnegSigns(this.ART_PREP_Results[0].concentration), style: concValStyle, margin: [0, 3, 0, 0] } }, { ...this.ART_PREP_Results[1] != undefined && { text: this.handlingnegSigns(this.ART_PREP_Results[1].concentration), style: concValStyle, margin: [0, 3, 0, 0] } }, { text: this.translateNreturn('units.M_ml', true, true).substring(1, this.translateNreturn('units.M_ml', true, true).length - 1), style: 'tabledata' },],
        //   // // total motility
        [{ text: this.translateNreturn(who == 3 ? "settings.tot_pr" : "settings.motility", true).substring(0, this.translateNreturn(who == 3 ? "settings.tot_pr" : "settings.motility", true).length - 3), lable: "totalMotilePRNP", style: 'resultsParam' }, { ...this.ART_PREP_Results[0] != undefined && { text: this.handlingnegSigns(this.ART_PREP_Results[0].totalMotilePRNP, "motility"), style: 'tabledata' } }, { ...this.ART_PREP_Results[1] != undefined && { text: this.handlingnegSigns(this.ART_PREP_Results[1].totalMotilePRNP, "motility"), style: concValStyle, margin: [0, 3, 0, 0] } }, { text: this.translateNreturn('units.percent', true, true).substring(1, this.translateNreturn('units.percent', true, true).length - 1), style: 'tabledata' }],
        //   // // progressive pr
        [{ text: `${who == 4 ? `\u200B\t` : ``} ${this.translateNreturn(who == 4 ? 'test.progressive' : 'test.prog_motility_pr', true).substring(0, this.translateNreturn(who == 4 ? 'test.progressive' : 'test.prog_motility_pr', true).length - 3)}`, lable: "progressivePR", style: 'resultsParam' }, { ...this.ART_PREP_Results[0] != undefined && { text: this.handlingnegSigns(this.ART_PREP_Results[0].progressivePR, "motility"), style: 'tabledata' } }, { ...this.ART_PREP_Results[1] != undefined && { text: this.handlingnegSigns(this.ART_PREP_Results[1].progressivePR, "motility"), style: concValStyle, margin: [0, 3, 0, 0] } }, { text: this.translate.instant('units.percent').substring(1, this.translate.instant('units.percent').length - 1), style: 'tabledata' }],
        //   // //rapid and slow progressive
        ...(who == 4 ? [[{ text: `\u200B\t\u200B\t ${this.translateNreturn('test.rapid_prog', true).substring(0, this.translateNreturn('test.rapid_prog', true).length - 3)}`, style: 'resultsParam' }, { ...this.ART_PREP_Results[0] != undefined && { text: this.handlingnegSigns(this.ART_PREP_Results[0].rapidProgressiveA, "motility"), style: 'tabledata' } }, { ...this.ART_PREP_Results[1] != undefined && { text: this.handlingnegSigns(this.ART_PREP_Results[1].rapidProgressiveA, "motility"), style: concValStyle, margin: [0, 3, 0, 0] } }, { text: this.translate.instant('units.percent').substring(1, this.translate.instant('units.percent').length - 1), style: 'tabledata' }],
        [{ text: `\u200B\t\u200B\t ${this.translateNreturn('test.slow_prog', true).substring(0, this.translateNreturn('test.slow_prog', true).length - 3)}`, style: 'resultsParam' }, { ...this.ART_PREP_Results[0] != undefined && { text: this.handlingnegSigns(this.ART_PREP_Results[0].slowProgressiveB, "motility"), style: 'tabledata' } }, { ...this.ART_PREP_Results[1] != undefined && { text: this.handlingnegSigns(this.ART_PREP_Results[1].slowProgressiveB, "motility"), style: concValStyle, margin: [0, 3, 0, 0] } }, { text: this.translate.instant('units.percent').substring(1, this.translate.instant('units.percent').length - 1), style: 'tabledata' },]] : []),
        //   // //non-progressive
        [{ text: `${who == 4 ? `\u200B\t` : ``} ${this.translateNreturn(who == 3 ? 'test.non_prog_motility_np' : 'settings.non_progressive_pr', true).substring(0, this.translateNreturn(who == 3 ? 'test.non_prog_motility_np' : 'settings.non_progressive_pr', true).length - 3)}`, style: 'resultsParam' }, { ...this.ART_PREP_Results[0] != undefined && { text: this.handlingnegSigns(this.ART_PREP_Results[0].non_progressive_np, "motility"), style: 'tabledata' } }, { ...this.ART_PREP_Results[1] != undefined && { text: this.handlingnegSigns(this.ART_PREP_Results[1].non_progressive_np, "motility"), style: concValStyle, margin: [0, 3, 0, 0] } }, { text: this.translate.instant('units.percent').substring(1, this.translate.instant('units.percent').length - 1), style: 'tabledata' },],
        //   // //Immotile
        [{ text: `${this.translateNreturn(who == 3 ? 'test.immotility' : 'settings.immotile_who6', true).substring(0, this.translateNreturn(who == 3 ? 'test.immotility' : 'settings.immotile_who6', true).length - 3)}`, style: 'resultsParam' }, { ...this.ART_PREP_Results[0] != undefined && { text: this.handlingnegSigns(this.ART_PREP_Results[0].immotile, "motility"), style: 'tabledata' } }, { ...this.ART_PREP_Results[1] != undefined && { text: this.handlingnegSigns(this.ART_PREP_Results[1].immotile, "motility"), style: concValStyle, margin: [0, 3, 0, 0] } }, { text: this.translate.instant('units.percent').substring(1, this.translate.instant('units.percent').length - 1), style: 'tabledata' }],
        //   // //Normal forms
        [{ text: this.translateNreturn('settings.normal', true).substring(0, this.translateNreturn('settings.normal', true).length - 3), style: 'resultsParam' }, { ...this.ART_PREP_Results[0] != undefined && { text: this.handlingnegSigns(this.ART_PREP_Results[0].normalForms, "normal_frms"), style: 'tabledata' } }, { ...this.ART_PREP_Results[1] != undefined && { text: this.handlingnegSigns(this.ART_PREP_Results[1].normalForms, "normal_frms"), style: concValStyle, margin: [0, 3, 0, 0] } }, { text: this.translate.instant('units.percent').substring(1, this.translate.instant('units.percent').length - 1), style: 'tabledata' }],
        //   // //MSC
        [{ text: this.translateNreturn('settings.motile_conc', true), style: 'resultsParam' }, { ...this.ART_PREP_Results[0] != undefined && { text: this.handlingnegSigns(this.ART_PREP_Results[0].motileSpermConc), style: 'tabledata' } }, { ...this.ART_PREP_Results[1] != undefined && { text: this.handlingnegSigns(this.ART_PREP_Results[1].motileSpermConc), style: concValStyle, margin: [0, 3, 0, 0] } }, { text: this.translate.instant('units.M_ml').substring(1, this.translate.instant('units.M_ml').length - 1), style: 'tabledata' }],
        //   // //PMSC
        [{ text: `${who == 4 ? `\u200B\t` : ``} ${this.translateNreturn('settings.prog_mot', true)}`, style: 'resultsParam' }, { ...this.ART_PREP_Results[0] != undefined && { text: this.handlingnegSigns(this.ART_PREP_Results[0].progMotileSpermConc), style: 'tabledata' } }, { ...this.ART_PREP_Results[1] != undefined && { text: this.handlingnegSigns(this.ART_PREP_Results[1].progMotileSpermConc), style: concValStyle, margin: [0, 3, 0, 0] } }, { text: this.translate.instant('units.M_ml').substring(1, this.translate.instant('units.M_ml').length - 1), style: 'tabledata' }],
        //   // //RPMSC & SPMSC
        ...(who == 4 ? [
          [{ text: `\u200B\t\u200B\t ${this.translateNreturn('test.rapid_msc', true)}`, style: 'resultsParam' }, { ...this.ART_PREP_Results[0] != undefined && { text: this.handlingnegSigns(this.ART_PREP_Results[0].progMotileSpermConcA), style: 'tabledata' } }, { ...this.ART_PREP_Results[1] != undefined && { text: this.handlingnegSigns(this.ART_PREP_Results[1].progMotileSpermConcA), style: concValStyle, margin: [0, 3, 0, 0] } }, { text: this.translate.instant('units.M_ml').substring(1, this.translate.instant('units.M_ml').length - 1), style: 'tabledata' }],
          [{ text: `\u200B\t\u200B\t ${this.translateNreturn('test.slow_msc', true)}`, style: 'resultsParam' }, { ...this.ART_PREP_Results[0] != undefined && { text: this.handlingnegSigns(this.ART_PREP_Results[0].progMotileSpermConcB), style: 'tabledata' } }, { ...this.ART_PREP_Results[1] != undefined && { text: this.handlingnegSigns(this.ART_PREP_Results[1].progMotileSpermConcB), style: concValStyle, margin: [0, 3, 0, 0] } }, { text: this.translate.instant('units.M_ml').substring(1, this.translate.instant('units.M_ml').length - 1), style: 'tabledata' }],
          //     // FSC
          [{ text: this.translateNreturn('test.fsc', true), style: 'resultsParam' }, { ...this.ART_PREP_Results[0] != undefined && { text: this.handlingnegSigns(this.ART_PREP_Results[0].fsc), style: 'tabledata' } }, { ...this.ART_PREP_Results[1] != undefined && { text: this.handlingnegSigns(this.ART_PREP_Results[1].fsc), style: concValStyle, margin: [0, 3, 0, 0] } }, { text: this.translate.instant('units.M_ml').substring(1, this.translate.instant('units.M_ml').length - 1), style: 'tabledata' }],
          //     //  Velocity
          [{ text: this.translateNreturn('test.velocity', true), style: 'resultsParam', label: "velocity" }, { ...this.ART_PREP_Results[0] != undefined && { text: this.handlingnegSigns(this.ART_PREP_Results[0].velocity, 'normal_frms'), style: 'tabledata' } }, { ...this.ART_PREP_Results[1] != undefined && { text: this.handlingnegSigns(this.ART_PREP_Results[1].velocity, 'normal_frms'), style: concValStyle, margin: [0, 3, 0, 0] } }, { text: this.translate.instant('units.mic_sec').substring(1, this.translate.instant('units.mic_sec').length - 1), style: 'tabledata' }],
          //     //  SMI
          [{ text: this.translateNreturn('settings.sperm_motility_index', true), style: 'resultsParam', label: "spermMotilityIndex" }, { ...this.ART_PREP_Results[0] != undefined && { text: this.handlingnegSigns(this.ART_PREP_Results[0].spermMotilityIndex, 'SMI', this.handlingnegSigns(this.ART_PREP_Results[0].motileSpermConc)), style: 'tabledata' } }, { ...this.ART_PREP_Results[1] != undefined && { text: this.handlingnegSigns(this.ART_PREP_Results[1].spermMotilityIndex, 'SMI', this.handlingnegSigns(this.ART_PREP_Results[1].motileSpermConc)), style: concValStyle, margin: [0, 3, 0, 0] } }, { text: '---', style: 'tabledata' }],
          ...(!this.displayAllParams ? [
            [{ text: this.translateNreturn('test.totals_per_ejac', true), colSpan: (this.ART_PREP_Results[0] != undefined && this.ART_PREP_Results[1] != undefined ? 4 : 3), style: 'paraheader', alignment: 'left', bold: 'Helvetica-Bold' }],
            // //total MSC
            [{ text: this.translateNreturn('settings.mot_spr_conc', true), style: 'resultsParam' }, { ...this.ART_PREP_Results[0] != undefined && { text: this.handlingnegSigns(this.ART_PREP_Results[0].motileSperm), style: 'tabledata' } }, { ...this.ART_PREP_Results[1] != undefined && { text: this.handlingnegSigns(this.ART_PREP_Results[1].motileSperm), style: 'tabledata' } }, { text: this.translate.instant('units.m_ejac').substring(1, this.translate.instant('units.m_ejac').length - 1), style: 'tabledata' }],
            // //total PMSC
            [{ text: this.translateNreturn('test.tota_progmotilesperm', true), style: 'resultsParam' }, { ...this.ART_PREP_Results[0] != undefined && { text: this.handlingnegSigns(this.ART_PREP_Results[0].totalPmsc), style: 'tabledata' } }, { ...this.ART_PREP_Results[1] != undefined && { text: this.handlingnegSigns(this.ART_PREP_Results[1].totalPmsc), style: 'tabledata' } }, { text: this.translate.instant('units.m_ejac').substring(1, this.translate.instant('units.m_ejac').length - 1), style: 'tabledata' }],


          ] : [])
        ] : [
          //     // WHO3 SMI
          [{ text: this.translateNreturn('settings.sperm_motility_index', true), style: 'resultsParam', label: "spermMotilityIndex" }, { ...this.ART_PREP_Results[0] != undefined && { text: this.handlingnegSigns(this.ART_PREP_Results[0].spermMotilityIndex, 'SMI', this.handlingnegSigns(this.ART_PREP_Results[0].motileSpermConc)), style: 'tabledata' } }, { ...this.ART_PREP_Results[1] != undefined && { text: this.handlingnegSigns(this.ART_PREP_Results[1].spermMotilityIndex, 'SMI', this.handlingnegSigns(this.ART_PREP_Results[1].motileSpermConc)), style: concValStyle, margin: [0, 3, 0, 0] } }, { text: '---', style: 'tabledata' }],
          //     // WHO3 totals header
          [{ text: this.translateNreturn('test.totals_per_ejac', true), colSpan: (this.ART_PREP_Results[0] != undefined && this.ART_PREP_Results[1] != undefined ? 4 : 3), style: 'paraheader', alignment: 'left', bold: 'Helvetica-Bold' }],
          //     // WHO3 total sperm
          ...this.displayAllParams ? [
            [{ text: this.translateNreturn('settings.sperm', true), style: 'resultsParam' }, { ...this.ART_PREP_Results[0] != undefined && { text: this.handlingnegSigns(this.ART_PREP_Results[0].spermCount), style: 'tabledata' } }, { ...this.ART_PREP_Results[1] != undefined && { text: this.handlingnegSigns(this.ART_PREP_Results[1].spermCount), style: concValStyle, margin: [0, 3, 0, 0] } }, { text: this.translate.instant('units.m_ejac').substring(1, this.translate.instant('units.m_ejac').length - 1), style: 'tabledata' }],
          ] : [],
          //     // WHO3 totals mot sperm
          [{ text: this.translateNreturn('settings.mot_spr_conc', true), style: 'resultsParam' }, { ...this.ART_PREP_Results[0] != undefined && { text: this.handlingnegSigns(this.ART_PREP_Results[0].motileSperm), style: 'tabledata' } }, { ...this.ART_PREP_Results[1] != undefined && { text: this.handlingnegSigns(this.ART_PREP_Results[1].motileSperm), style: concValStyle, margin: [0, 3, 0, 0] } }, { text: this.translate.instant('units.m_ejac').substring(1, this.translate.instant('units.m_ejac').length - 1), style: 'tabledata' }],
          //sample evaluation who3
          [{ text: this.translateNreturn('motility_estimation.sample_evaluation', true), style: 'resultsParam', label: 'sampleEvaluation' }, { ...this.ART_PREP_Results[0] != undefined && { text: this.getSampleEvalution(this.ART_PREP_Results[0].motilityEstimationData?.sampleEvaluation), style: 'tabledata' } }, { ...this.ART_PREP_Results[1] != undefined && { text: this.handlingnegSigns(this.ART_PREP_Results[1].totalPmsc), style: 'tabledata' } }, { text: '---', style: 'tabledata' }],
        ]),
      ].filter((para, index1) => (index1 > 1 && !this.displayAllParams) ? ART_PREP_LimitedParams.includes(para[0].text) : true)

      ART_PREP_tablearray = filteredData.map((ele: any[], index) => {    // adding borders and fillcolors to all the cells
        const innerFiilteredData = ele.filter((item) => Object.keys(item).length != 0)
        return innerFiilteredData.map((innerEle, innerInd) => {
          !environment.production && console.log("Inner ele.........", innerEle);

          //headings          
          if (ele[0].text == this.translateNreturn('test.totals_per_ejac', true) || ele[0].text == this.translateNreturn('test.params', true)) {
            return { ...innerEle, fillColor: '#B0E0E6', }
          }


          // if ((who == 3 && index == (Fresh_Test_Data.testingMode != 2 ? 10 : 11)) || index == 0 || who == 4 && (!this.displayAllParams && index == 8)) {  //skip totals header in who5
          //   return { ...innerEle, fillColor: '#B0E0E6', }
          // }
          //hint text
          // if (index == (filteredData.length - 1) && innerInd == (ele.length - (this.ART_PREP_Results[0] != undefined && this.ART_PREP_Results[1] != undefined ? 1 : 2))) {
          //   return { ...innerEle, fillColor: '#fff', border: [true, false, true, true] }
          // }
          if (index == 1 && innerInd == (ele.length - (this.ART_PREP_Results[0] != undefined && this.ART_PREP_Results[1] != undefined ? 1 : 2))) {
            return { ...innerEle, fillColor: '#fff', border: [true, false, true, false] }
          }
          // console.log(innerEle, index, who, innerInd, ele.length, index == 15 || (index == 11 && who == 3));

          return typeof innerEle == "object" ? {
            ...innerEle,
            //border for all the parameters 
            ...{ border: (((who == 4 && (index == 1 || index == (filteredData.length - 1))) || (who == 3 && (index == 1 || index == 12))) && innerInd == filteredData.length - 1) ? [true, ((index == 12) || index == (filteredData.length - 1)) ? false : true, true, innerInd == 1 ? false : true] : [true, true, true, true] },
            fillColor: index % 2 != 0 || (who == 3 && (index == 12) && innerInd == ele.length - 1) ? 'white' : '#eee',
          } : innerEle
        })
      }).filter(el => {
        // if (el[0].label && this.facilityHeader.contentLimitation.hideParameters === true)
        //   return !this.facilityHeader.resultsRestriction.includes(el[0].label)
        // else
        //   return true
        if (this._authService.iOwMode == true) {
          return !resultRestrictionArr.includes(el[0].label)
        } else {
          if (el[0].label && this.facilityHeader.contentLimitation.hideParameters === true)
            return !this.facilityHeader.resultsRestriction.includes(el[0].label)
          else
            return true
        }
      })
      //pushing hind text to last cell in the table after last column spanning
      ART_PREP_tablearray.slice(-1)[0].push({ text: this.translateNreturn('test.mes_parameters_msg', true, true), style: 'tabledata', border: [true, false, true, true] } as any)

      console.log("+++++++++++++", filteredData, ART_PREP_tablearray);

      ART_PREP_tablearray2 = [
        // //Report continued
        [{ text: `${this.checkandTranslate(this.translate.instant("test.report_continued"))} | ${this.checkandTranslate(this.translate.instant("patients.patient_id"))}: ${this.Patient_Info.patientid ?? this.Fresh_Test_Data.patientid} | ${this.checkandTranslate(this.translate.instant("test.test_date")).replace("|", "/")}: ${testDt}`, style: "reportContinueText", colSpan: isFullArt ? 5 : 4, border: [false, false, false, false], fillColor: '#FFFFFF', margin: [-5, 0, 0, 10] }, ...isFullArt ? ['', '', '', ''] : ['', '', '']],
        // //totals Heading
        [{ text: this.translateNreturn('test.totals_per_ejac', true), fillColor: '#B0E0E6', style: 'paraheader', alignment: 'left', bold: 'Helvetica-Bold' }, { ...this.ART_PREP_Results[0] != undefined && { text: this.translateNreturn('test_process.pre_prep', true), fillColor: '#B0E0E6', style: 'paraheader' } }, { ...this.ART_PREP_Results[1] != undefined && { text: this.translateNreturn('test_process.post_prep', true), fillColor: '#B0E0E6', style: 'paraheader' } }, { text: this.translateNreturn('units.units', true), fillColor: '#B0E0E6', style: 'paraheader' }, { text: '', rowSpan: this.displayAllParams ? 7 - this.getParamsLengthInTables(resultRestrictionArr, 2) : 3, color: "white", border: [false, false, false, false] }],
        // //total sperm
        [{ text: this.translateNreturn('settings.sperm', true), style: 'resultsParam' }, { ...this.ART_PREP_Results[0] != undefined && { text: this.handlingnegSigns(this.ART_PREP_Results[0].spermCount), style: 'tabledata' } }, { ...this.ART_PREP_Results[1] != undefined && { text: this.handlingnegSigns(this.ART_PREP_Results[1].spermCount), style: 'tabledata' } }, { text: this.translate.instant('units.m_ejac').substring(1, this.translate.instant('units.m_ejac').length - 1), style: 'tabledata' }, ''],
        // //total MSC
        [{ text: this.translateNreturn('settings.mot_spr_conc', true), style: 'resultsParam' }, { ...this.ART_PREP_Results[0] != undefined && { text: this.handlingnegSigns(this.ART_PREP_Results[0].motileSperm), style: 'tabledata' } }, { ...this.ART_PREP_Results[1] != undefined && { text: this.handlingnegSigns(this.ART_PREP_Results[1].motileSperm), style: 'tabledata' } }, { text: this.translate.instant('units.m_ejac').substring(1, this.translate.instant('units.m_ejac').length - 1), style: 'tabledata' }, ''],
        // //total PMSC
        [{ text: this.translateNreturn('test.tota_progmotilesperm', true), style: 'resultsParam' }, { ...this.ART_PREP_Results[0] != undefined && { text: this.handlingnegSigns(this.ART_PREP_Results[0].totalPmsc), style: 'tabledata' } }, { ...this.ART_PREP_Results[1] != undefined && { text: this.handlingnegSigns(this.ART_PREP_Results[1].totalPmsc), style: 'tabledata' } }, { text: this.translate.instant('units.m_ejac').substring(1, this.translate.instant('units.m_ejac').length - 1), style: 'tabledata' }, ''],
        // //total FSC
        [{ text: this.translateNreturn('test.tota_fsc', true), style: 'resultsParam' }, { ...this.ART_PREP_Results[0] != undefined && { text: this.handlingnegSigns(this.ART_PREP_Results[0].totalFsc), style: 'tabledata' } }, { ...this.ART_PREP_Results[1] != undefined && { text: this.handlingnegSigns(this.ART_PREP_Results[1].totalFsc), style: 'tabledata' } }, { text: this.translate.instant('units.m_ejac').substring(1, this.translate.instant('units.m_ejac').length - 1), style: 'tabledata' }, ''],
        // //total Morph
        [{ text: this.translateNreturn('test.tota_morph', true), style: 'resultsParam', label: 'totalMorph' }, { ...this.ART_PREP_Results[0] != undefined && { text: this.handlingnegSigns(this.ART_PREP_Results[0].totalMorph), style: 'tabledata' } }, { ...this.ART_PREP_Results[1] != undefined && { text: this.handlingnegSigns(this.ART_PREP_Results[1].totalMorph), style: 'tabledata' } }, { text: this.translate.instant('units.m_ejac').substring(1, this.translate.instant('units.m_ejac').length - 1), style: 'tabledata' }, ''],
        // sample evalution
        [{ text: this.translateNreturn('motility_estimation.sample_evaluation', true), style: 'resultsParam', label: 'sampleEvaluation' }, { ...this.ART_PREP_Results[0] != undefined && { text: this.getSampleEvalution(this.ART_PREP_Results[0].motilityEstimationData?.sampleEvaluation), style: 'tabledata' } }, { ...this.ART_PREP_Results[1] != undefined && { text: this.getSampleEvalution(this.ART_PREP_Results[1].motilityEstimationData?.sampleEvaluation), style: 'tabledata' } }, { text: '---', style: 'tabledata' }, ''],
      ].filter((para1: any, index) => (index > 1 && !this.displayAllParams) ? ART_PREP_LimitedParams.includes(para1[0].text) : true).map((ele: any[], index) => {
        if (index == 1) {
          return ele.filter(innerItem => Object.keys(innerItem).length != 0)
        }
        if (index > 1) {
          return ele.filter((item) => Object.keys(item).length != 0).map(innerEle => {
            return typeof innerEle == "object" ? {
              ...innerEle,
              border: [true, true, true, true],
              ...index != 0 && { fillColor: (index - 2) % 2 != 0 ? '#eee' : 'white' }
            } : innerEle
          })
        } else {
          return ele
        }
      }).filter(el => {
        // if (el[0].label && this.facilityHeader.contentLimitation.hideParameters === true)
        // return !this.facilityHeader.resultsRestriction.includes(el[0].label)
        // else
        //   return true
        if (this._authService.iOwMode == true) {
          return !resultRestrictionArr.includes(el[0].label)
        } else {
          if (el[0].label && this.facilityHeader.contentLimitation.hideParameters === true)
            return !this.facilityHeader.resultsRestriction.includes(el[0].label)
          else
            return true
        }
      })
    }

    if (who == 4) {
      tablearray2 = [
        //Report continued
        [{ text: `${this.checkandTranslate(this.translate.instant("test.report_continued"))} | ${this.checkandTranslate(this.translate.instant("patients.patient_id"))}: ${this.Patient_Info.patientid ?? this.Fresh_Test_Data.patientid} | ${this.checkandTranslate(this.translate.instant("test.test_date")).replace("|", "/")}: ${testDt}`, style: "reportContinueText", colSpan: 6, border: [false, false, false, false], fillColor: '#FFFFFF', margin: [-5, 0, 0, 10] }, '', '', '', '', ''],
        //totals Heading
        [{ text: this.translateNreturn('test.totals_per_ejac', true), fillColor: '#B0E0E6', style: 'paraheader', alignment: 'left', bold: 'Helvetica-Bold' }, { text: this.translateNreturn('test.result', true), fillColor: '#B0E0E6', style: 'paraheader' }, { text: this.translateNreturn('units.units', true), fillColor: '#B0E0E6', style: 'paraheader' }, { text: this.translateNreturn('settings.ref_value', true), fillColor: '#B0E0E6', colSpan: 2, style: 'paraheader' }, { text: ' ', fillColor: '#B0E0E6', style: 'paraheader' }, { text: '', color: "white", border: [false, false, false, false], rowSpan: 6 - this.getParamsLengthInTables(resultRestrictionArr, 2) }],
        //total sperm
        [{ text: this.translateNreturn('settings.sperm', true), style: 'resultsParam', label: 'spermCount' }, { text: this.handlingnegSigns(spermCountVal), style: 'tabledata' }, { text: this.translate.instant('units.m_ejac').substring(1, this.translate.instant('units.m_ejac').length - 1), style: 'tabledata' }, { text: getRef(Fresh_Test_Data.testSettings.referenceSettings.total_sperm_count), style: 'tabledata' }, await getArrow(this.tscArrow, spermCountVal), ''],
        //total MSC
        [{ text: this.translateNreturn('settings.mot_spr_conc', true), style: 'resultsParam', label: 'motileSperm' }, { text: this.handlingnegSigns(Fresh_Test_Data.motileSperm), style: 'tabledata' }, { text: this.translate.instant('units.m_ejac').substring(1, this.translate.instant('units.m_ejac').length - 1), style: 'tabledata' }, { text: getRef(Fresh_Test_Data.testSettings.referenceSettings.motile_sperm), style: 'tabledata' }, await getArrow(this.motilespermArrow, Fresh_Test_Data.motileSperm), ''],
        //total PMSC
        [{ text: this.translateNreturn('test.tota_progmotilesperm', true), style: 'resultsParam', label: 'totalPmsc' }, { text: this.handlingnegSigns(Fresh_Test_Data.totalPmsc), style: 'tabledata' }, { text: this.translate.instant('units.m_ejac').substring(1, this.translate.instant('units.m_ejac').length - 1), style: 'tabledata' }, { text: getRef(Fresh_Test_Data.testSettings.referenceSettings.totalPMSC), style: 'tabledata' }, await getArrow(this.totalPmscArrow, Fresh_Test_Data.totalPmsc), ''],
        //total FSC
        [{ text: this.translateNreturn('test.tota_fsc', true), style: 'resultsParam', label: 'fsc' }, { text: this.handlingnegSigns(Fresh_Test_Data.totalFsc), style: 'tabledata' }, { text: this.translate.instant('units.m_ejac').substring(1, this.translate.instant('units.m_ejac').length - 1), style: 'tabledata' }, { text: getRef(Fresh_Test_Data.testSettings.referenceSettings.totalFSC), style: 'tabledata' }, await getArrow(this.totalFscArrow, Fresh_Test_Data.totalFsc), ''],
        //total Morph
        [{ text: this.translateNreturn('test.tota_morph', true), style: 'resultsParam', label: 'totalMorph' }, { text: this.handlingnegSigns(Fresh_Test_Data.totalMorph), style: 'tabledata' }, { text: this.translate.instant('units.m_ejac').substring(1, this.translate.instant('units.m_ejac').length - 1), style: 'tabledata' }, { text: getRef(Fresh_Test_Data.testSettings.referenceSettings.totalMorph), style: 'tabledata' }, await getArrow(this.totalMorphArrow, Fresh_Test_Data.totalMorph), ''],
      ].map((ele: any[], index) => {
        if (index > 1) {
          return ele.map(innerEle => {
            return typeof innerEle == "object" ? {
              ...innerEle,
              border: [true, true, true, true],
              ...index != 0 && { fillColor: (index - 2) % 2 != 0 ? '#eee' : 'white' }
            } : innerEle
          })
        } else {
          return ele
        }
      }).filter(el => {
        // if (el[0].label && this.facilityHeader.contentLimitation.hideParameters === true)
        // return !this.facilityHeader.resultsRestriction.includes(el[0].label)
        // else
        //   return true
        if (this._authService.iOwMode == true) {
          return !resultRestrictionArr.includes(el[0].label)
        } else {
          if (el[0].label && this.facilityHeader.contentLimitation.hideParameters === true)
            return !this.facilityHeader.resultsRestriction.includes(el[0].label)
          else
            return true
        }
      })
    }

    dataArray[0] = Fresh_Test_Data.testingMode === 2 ? ART_PREP_tablearray : tablearray1;
    dataArray[1] = Fresh_Test_Data.testingMode === 2 ? ART_PREP_tablearray2 : tablearray2;

    !environment.production && console.log("00000000", dataArray);

    return dataArray;
  }

  public get displayAllParams(): boolean {
    if (this.ART_PREP_Results.length === 0) return true;
    if (this.ART_PREP_Results[0] != undefined && this.ART_PREP_Results[1] != undefined) {                     // when both tests exists
      if ((this.ART_PREP_Results[0].countingMethod != undefined) && (this.ART_PREP_Results[0].countingMethod == 4) || (this.ART_PREP_Results[1].countingMethod != undefined) && (this.ART_PREP_Results[1].countingMethod == 4)) { //  current test is Motility estimation
        if (this.ART_PREP_Results[0].countedMorphologyFields != undefined && this.ART_PREP_Results[0].countedMorphologyFields.length > 0) { // check for Morphology fields if exists show all params
          return true
        } else if ((this.ART_PREP_Results[0].motilityEstimationData != undefined && this.ART_PREP_Results[0].motilityEstimationData?.totalMotility != 0) || (this.ART_PREP_Results[1].motilityEstimationData != undefined && this.ART_PREP_Results[1].motilityEstimationData?.totalMotility != 0)) {
          return true;                                                                                        // Either of the test is having Motility estimation then show all
        }
        else {
          return false;
        }
      }
      return this.ART_PREP_Results[0].mconcentration != undefined || this.ART_PREP_Results[1].mconcentration != undefined
    } else {
      if (this.ART_PREP_Results[0] != undefined || this.ART_PREP_Results[1] != undefined) {
        if (this.Fresh_Test_Data.countingMethod != undefined && this.Fresh_Test_Data.countingMethod == CountingMethod.Motility_Estimation) {
          if (this.Fresh_Test_Data.countedMorphologyFields != undefined && this.Fresh_Test_Data.countedMorphologyFields.length > 0) {
            return true
          } else if (this.Fresh_Test_Data.motilityEstimationData != undefined && this.Fresh_Test_Data.motilityEstimationData.totalMotility != 0) {
            return true
          } else {
            return false
            // if (this.ART_PREP_Results[0] != undefined) {
            //   return this.ART_PREP_Results[0].mconcentration != undefined
            // } else if (this.ART_PREP_Results[1] != undefined) {
            //   return this.ART_PREP_Results[1].mconcentration != undefined
            // }
          }
        }
        return this.Fresh_Test_Data.mconcentration != undefined
      }
      return this.Fresh_Test_Data.mconcentration != undefined
    }
  }

  switchdisplayValues(testData: Test) {
    const Fresh_Test_Data: any = { ...testData }

    if (Fresh_Test_Data.mtotalMotilePRNP != undefined || (Fresh_Test_Data.testingMode == 2 && this.displayAllParams)) {

      this.isManual = true
    } else {
      this.isManual = false
    }

    if (Fresh_Test_Data.testingMode == 2 && Fresh_Test_Data.motilityEstimationData?.totalMotility == 0 && Fresh_Test_Data.countingMethod == CountingMethod.Motility_Estimation) {
      this.isManual = false
    }

    !environment.production && console.log(Fresh_Test_Data, "000000000000000", this.isManual, "=====================");

    if (Fresh_Test_Data.noSpermSeen == true || Fresh_Test_Data.mconcentration === 0) {

      this.isManual = true
      Fresh_Test_Data.concentration = 0.0
      Fresh_Test_Data.spermCount = 0.0
      Fresh_Test_Data.motileSpermConc = -1
      Fresh_Test_Data.progMotileSpermConc = -1
      Fresh_Test_Data.normalForms = -1
      Fresh_Test_Data.motileSperm = -1
      Fresh_Test_Data.progressivePR = -1
      Fresh_Test_Data.non_progressive_np = -1
      Fresh_Test_Data.immotile = -1
      Fresh_Test_Data.totalMotilePRNP = -1
      Fresh_Test_Data.progMotileSpermConcA = -1
      Fresh_Test_Data.progMotileSpermConcB = -1
      Fresh_Test_Data.rapidProgressiveA = -1
      Fresh_Test_Data.slowProgressiveB = -1
      Fresh_Test_Data.fsc = -1
      Fresh_Test_Data.velocity = -1
      // Fresh_Test_Data.spermMotilityIndex = 0
      Fresh_Test_Data.totalFsc = -1
      Fresh_Test_Data.totalPmsc = -1
      Fresh_Test_Data.totalMorph = -1
      Fresh_Test_Data.spermMotilityIndex = 0
    } else {
      if (this.isManual && Fresh_Test_Data.mconcentration != undefined) {

        if (Fresh_Test_Data.testSettings.who == 3) {
          Fresh_Test_Data.progressivePR = Fresh_Test_Data.mprogressivePR
          Fresh_Test_Data.progMotileSpermConc = Fresh_Test_Data.mprogMotileSpermConc
          // Fresh_Test_Data.progMotileSpermConcA = Fresh_Test_Data.progMotileSpermConcA
          // Fresh_Test_Data.progMotileSpermConcB = Fresh_Test_Data.progMotileSpermConcB
        } else {
          Fresh_Test_Data.progressivePR = Fresh_Test_Data.progressivePR
          Fresh_Test_Data.rapidProgressiveA = Fresh_Test_Data.mrapidProgressiveA
          Fresh_Test_Data.slowProgressiveB = Fresh_Test_Data.mslowProgressiveB
          if (Fresh_Test_Data.mrapidProgressiveA >= 0 && Fresh_Test_Data.mslowProgressiveB >= 0) {
            if (Fresh_Test_Data.mprogressivePR != undefined) {
              Fresh_Test_Data.progressivePR = Fresh_Test_Data.mprogressivePR
            } else {
              Fresh_Test_Data.progressivePR = Fresh_Test_Data.mrapidProgressiveA + Fresh_Test_Data.mslowProgressiveB
            }
          } else {
            Fresh_Test_Data.progressivePR = -1
          }
          Fresh_Test_Data.progMotileSpermConcA = Fresh_Test_Data.mprogMotileSpermConcA
          Fresh_Test_Data.progMotileSpermConcB = Fresh_Test_Data.mprogMotileSpermConcB
          if (Fresh_Test_Data.mprogMotileSpermConcA >= 0 && Fresh_Test_Data.mprogMotileSpermConcB >= 0)
            Fresh_Test_Data.progMotileSpermConc = Number(Fresh_Test_Data.mprogMotileSpermConcA.toFixed(1)) + Number(Fresh_Test_Data.mprogMotileSpermConcB.toFixed(1))
          else
            Fresh_Test_Data.progMotileSpermConc = -1
        }
        Fresh_Test_Data.concentration = Fresh_Test_Data.mconcentration
        Fresh_Test_Data.spermCount = Number(Fresh_Test_Data.mspermCount)
        Fresh_Test_Data.motileSpermConc = Fresh_Test_Data.mmotileSpermConc
        Fresh_Test_Data.normalForms = Fresh_Test_Data.mnormalForms
        Fresh_Test_Data.motileSperm = Fresh_Test_Data.mmotileSperm
        Fresh_Test_Data.non_progressive_np = Fresh_Test_Data.mnon_progressive_np
        Fresh_Test_Data.immotile = Fresh_Test_Data.mimmotile
        Fresh_Test_Data.totalMotilePRNP = Fresh_Test_Data.mtotalMotilePRNP
        Fresh_Test_Data.fsc = Fresh_Test_Data.mFSC ?? -1
        Fresh_Test_Data.velocity = Fresh_Test_Data.mvelocity ?? -1
        Fresh_Test_Data.totalFsc = Fresh_Test_Data.mtotalFSC ?? -1
        Fresh_Test_Data.totalPmsc = Fresh_Test_Data.mtotalPmsc ?? -1
        Fresh_Test_Data.totalMorph = Fresh_Test_Data.mtotNormalForms ?? -1

      } else {

        if (Fresh_Test_Data.mconcentration != undefined && Fresh_Test_Data.mtotalMotilePRNP == undefined) {
          Fresh_Test_Data.concentration = Fresh_Test_Data.mconcentration
          Fresh_Test_Data.spermCount = Fresh_Test_Data.mspermCount
        }
        Fresh_Test_Data.normalForms = Fresh_Test_Data.mnormalForms ? Fresh_Test_Data.mnormalForms : Fresh_Test_Data.normalForms
      }
    }

    return Fresh_Test_Data
  }





  public getTestsByWeek(tests) {

    let listOfWeeks = [];
    let listOfTestsPerWeek = [];

    let listOfAllDays = [];
    // let testList = [
    //   new Date('2020-06-04T07:37:00.000Z'),
    //   new Date('2020-06-04T07:37:00.000Z'),
    //   new Date('2020-06-02T07:37:00.000Z'),
    //   new Date('2020-06-02T07:37:00.000Z'),
    //   new Date('2020-06-01T07:37:00.000Z'),
    //   new Date('2020-06-01T07:37:00.000Z'),
    //   new Date('2020-04-01T07:37:00.000Z'),
    //   new Date('2020-05-29T09:37:00.000Z'),
    //   new Date('2020-05-27T07:37:00.000Z'),
    //   new Date('2020-05-28T09:37:00.000Z'),
    //   new Date('2020-05-28T07:37:00.000Z'),
    //   new Date('2020-05-29T09:37:00.000Z'),
    //   new Date('2020-05-21T07:37:00.000Z'),
    //   new Date('2020-05-21T09:37:00.000Z'),
    //   new Date('2020-05-21T07:37:00.000Z'),
    //   new Date('2020-05-22T07:37:00.000Z'),
    //   new Date('2020-05-26T07:37:00.000Z'),
    //   new Date('2020-05-29T09:37:00.000Z'),
    //   new Date('2020-04-29T07:37:00.000Z'),
    //   new Date('2020-04-29T09:37:00.000Z'),
    //   new Date('2020-04-28T07:37:00.000Z'),
    //   new Date('2020-04-29T09:37:00.000Z'),
    //   new Date('2020-04-30T07:37:00.000Z'),
    //   new Date('2020-04-30T09:37:00.000Z'),
    //   new Date('2020-05-01T07:37:00.000Z'),
    //   new Date('2020-05-01T07:37:00.000Z'),
    //   new Date('2020-05-02T07:37:00.000Z'),
    //   new Date('2020-05-21T07:37:00.000Z'),
    //   new Date('2020-05-25T07:39:00.000Z'),
    //   new Date('2020-05-25T07:37:00.000Z'),
    //   new Date('2020-05-21T07:37:00.000Z'),
    //   new Date('2020-05-01T07:37:00.000Z'),
    //   new Date('2020-04-02T07:37:00.000Z'),
    //   new Date('2020-04-21T07:37:00.000Z'),
    //   new Date('2020-04-20T07:37:00.000Z'),
    //   new Date('2020-04-20T07:37:00.000Z'),
    //   new Date('2020-04-21T07:37:00.000Z'),
    //   new Date('2020-04-25T07:39:00.000Z'),
    //   new Date('2020-04-25T07:37:00.000Z'),
    //   new Date('2020-05-26T07:37:00.000Z'),
    //   new Date('2020-04-13T07:37:00.000Z'),
    //   new Date('2020-04-14T07:37:00.000Z'),
    //   new Date('2020-04-11T07:37:00.000Z'),
    //   new Date('2020-04-12T07:37:00.000Z'),
    //   new Date('2020-04-11T07:37:00.000Z'),
    //   new Date('2020-04-12T07:37:00.000Z'),
    //   new Date('2020-04-11T07:37:00.000Z'),
    //   new Date('2020-04-12T07:37:00.000Z'),
    //   new Date('2020-04-11T07:37:00.000Z'),
    //   new Date('2020-04-20T07:37:00.000Z'),
    //   new Date('2020-04-06T07:37:00.000Z'),
    //   new Date('2020-04-06T07:37:00.000Z'),
    //   new Date('2020-04-04T07:37:00.000Z'),
    //   new Date('2020-04-03T07:37:00.000Z'),
    //   new Date('2020-04-03T07:37:00.000Z'),
    //   new Date('2020-04-03T07:37:00.000Z'),
    //   new Date('2020-04-02T07:37:00.000Z'),
    //   new Date('2020-03-31T07:37:00.000Z'),
    //   new Date('2020-03-29T07:37:00.000Z'),
    //   new Date('2020-03-27T07:37:00.000Z'),
    //   new Date('2020-03-26T07:37:00.000Z'),
    //   new Date('2020-03-26T07:37:00.000Z'),
    //   new Date('2020-03-26T07:37:00.000Z'),
    //   new Date('2020-03-25T07:37:00.000Z'),
    //   new Date('2020-03-24T07:37:00.000Z'),
    //   new Date('2020-03-24T07:37:00.000Z'),
    //   new Date('2020-03-22T07:37:00.000Z'),
    //   new Date('2020-03-21T07:37:00.000Z'),
    //   new Date('2020-03-21T07:37:00.000Z'),
    //   new Date('2020-03-15T07:37:00.000Z'),
    //   new Date('2020-03-15T07:37:00.000Z'),
    //   new Date('2020-03-15T07:37:00.000Z'),
    //   new Date('2020-03-17T07:37:00.000Z'),
    //   new Date('2020-03-18T07:37:00.000Z'),
    //   new Date('2020-03-18T07:37:00.000Z'),
    //   new Date('2020-03-18T07:37:00.000Z'),
    //   new Date('2020-03-18T09:30:00.000Z'),
    //   new Date('2020-03-30T07:37:00.000Z'),
    //   new Date('2020-03-04T09:30:00.000Z'),
    //   new Date('2020-03-04T09:30:00.000Z'),
    //   new Date('2020-03-05T09:30:00.000Z'),
    //   new Date('2020-03-06T09:30:00.000Z'),
    //   new Date('2020-03-07T09:30:00.000Z'),
    //   new Date('2020-03-07T09:30:00.000Z'),
    //   new Date('2020-03-07T09:30:00.000Z'),
    //   new Date('2020-03-14T09:30:00.000Z'),
    //   new Date('2020-03-14T09:30:00.000Z'),
    //   new Date('2020-03-13T09:30:00.000Z'),
    //   new Date('2020-03-10T09:30:00.000Z'),
    //   new Date('2020-03-09T09:30:00.000Z'),
    //   new Date('2020-03-09T09:30:00.000Z'),
    //   new Date('2020-03-09T09:30:00.000Z'),
    //   new Date('2020-05-04T09:30:00.000Z'),
    //   new Date('2020-05-04T09:30:00.000Z'),
    //   new Date('2020-05-05T09:30:00.000Z'),
    //   new Date('2020-05-06T09:30:00.000Z'),
    //   new Date('2020-05-07T09:30:00.000Z'),
    //   new Date('2020-05-07T09:30:00.000Z'),
    //   new Date('2020-05-07T09:30:00.000Z'),
    //   new Date('2020-05-04T09:30:00.000Z'),
    //   new Date('2020-05-14T09:30:00.000Z'),
    //   new Date('2020-05-15T09:30:00.000Z'),
    //   new Date('2020-05-16T09:30:00.000Z'),
    //   new Date('2020-05-11T09:30:00.000Z'),
    //   new Date('2020-05-11T09:30:00.000Z'),
    //   new Date('2020-05-12T09:30:00.000Z'),
    //   new Date('2020-05-12T09:30:00.000Z'),
    //      new Date('2020-05-30T09:30:00.000Z'),
    //      new Date('2020-05-30T09:30:00.000Z'),
    // ];
    !environment.production && console.log(tests, "testssss");

    tests.forEach(test => {
      let initialDate = test.testdata.dateTime.toDate();
      let finalDate;
      // try {
      //   finalDate = this.dateStringToTimestamp(initialDate.split(' ')[0]);
      // } catch {}
      // try {
      //   finalDate = initialDate.toDate();
      // } catch {}
      try {
        finalDate = new Date(initialDate);
      } catch { }


      listOfAllDays.push(finalDate);
    });

    // listOfAllDays = testList;
    // console.log(listOfAllDays);

    let earliestDate;
    let latestDate;

    let distinctDates: any = [];
    let distinctStringDates = [];
    let allDaysSting = [];

    const distinct = (value: any, index: any, self: any) => {
      return self.indexOf(value) === index;
    };

    listOfAllDays.forEach(day => {
      let strday = this.timestampToDateString(day, false);
      allDaysSting.push(strday.split(' ')[0]);
    });

    // console.log('all days calendar format ', allDaysSting);
    distinctStringDates = allDaysSting.filter(distinct);

    distinctStringDates.forEach(distDay => {
      let day = new Date(distDay);
      distinctDates.push(day);
    });

    distinctStringDates.sort();

    // console.log(distinctStringDates);

    earliestDate = distinctStringDates[0];
    latestDate = distinctStringDates[distinctDates.length - 1];
    // console.log(earliestDate, latestDate);

    let firstDay = new Date(earliestDate);
    let startDay = earliestDate;
    let counterDay = firstDay.getTime();
    let endDate; // = new Date(latestDate).getTime();
    if (latestDate === earliestDate) {
      endDate = new Date(latestDate);
      if (endDate.getDay() !== 6) {
        latestDate = this.addDay(latestDate, 1);
      }
    }
    // console.log('latest date', latestDate);
    endDate = new Date(latestDate).getTime();

    if (firstDay.getDay() !== 1) { // if not Monday
      startDay = this.reduceDay(startDay, (firstDay.getDay() - 1));
      // console.log('list start day ', startDay);
    }

    do {
      let weekEndDay = this.addDay(startDay, 6);
      let weekPair = [
        startDay,
        weekEndDay
      ];
      listOfWeeks.push(weekPair);
      startDay = this.addDay(weekEndDay, 1);
      counterDay = new Date(weekEndDay).getTime();
    } while (endDate > counterDay)
    // console.log(listOfWeeks);

    listOfWeeks.forEach(week => {
      let weeklyTestCount = 0;
      allDaysSting.forEach(day => {
        let weekstartTime = new Date(week[0]).getTime();
        let weekEndTime = new Date(week[1]).getTime();
        let dayTime = new Date(day).getTime();

        if (weekstartTime <= dayTime && dayTime <= weekEndTime) {
          weeklyTestCount++;
        }

      });
      listOfTestsPerWeek.push({ range: week, graphLabel: this.convertWeekRangeToDateLable(week), testCount: weeklyTestCount });
    });
    // console.log('list of tests per week ', listOfTestsPerWeek);
    return listOfTestsPerWeek;
  }

  private convertWeekRangeToDateLable(range) {

    // let day1 = range[0].split('/');
    // let day2 = range[1].split('/');
    // let label = range[0] + ' - ' + range[1];

    // if (day1[0] === day2[0]) { //same year
    //   // if (day1[1] === day2[1]) { //same month
    //   //   label = day1[2] + '-' + day2[2] + '/' + day2[1] + '/' + day2[0];
    //   // } else {
    //   label = day1[2] + '/' + day1[1] + '-' + day2[2] + '/' + day2[1] + '/' + day2[0];
    //   // }
    // } else {
    //   label = day1[2] + '/' + day1[1] + '/' + day1[0] + '-' + day2[2] + '/' + day2[1] + '/' + day2[0];
    // }

    let label = this.customPipe.transform(range[0], this.pcDateFormat, this.translate.currentLang);
    return label;
  }


  private reduceDay(date, reduceBy) {

    let time = new Date(date).getTime();
    let millisecondsReduce = reduceBy * 24 * 60 * 60 * 1000;
    let newTime = time - millisecondsReduce;
    let newDate = new Date(newTime);
    let newDateCalendarString = this.timestampToDateString(newDate, false);
    return newDateCalendarString.split(' ')[0];

  }

  private addDay(date, addTo) {
    let time = new Date(date).getTime();
    let millisecondsReduce = addTo * 25 * 60 * 60 * 1000;
    let newTime = time + millisecondsReduce;
    let newDate = new Date(newTime);
    let newDateCalendarString = this.timestampToDateString(newDate, false);
    return newDateCalendarString.split(' ')[0];
  }

  handlingnegSigns(tmpNum, praName?: string, msc?) {

    if (tmpNum == undefined) {
      return "N/A"
    }
    if (tmpNum == -1) {

      return "N/A";

    }

    else if (tmpNum == -2) {

      return "< 0" + "." + "2";

    }

    else if (tmpNum == -3) {

      return "< 2" + "." + "0";

    }

    else if (tmpNum == -4) {

      return "N/A";

    }

    else if ((tmpNum == undefined) && praName == 'SMI') {
      // if (this.isManual == true) {
      //   return tmpNum.toFixed()
      // } else {
      return "N/A";

      // }

    }

    else if (tmpNum == -10) {

      return "< 1";

    }

    else if (tmpNum == -500) {

      return "> 500";

    } else {
      if (praName == "motility" || praName == "normal_frms" || praName == "SMI") {
        !environment.production && console.log(tmpNum, praName);

        return tmpNum.toFixed()

      } else {
        try {
          return tmpNum.toFixed(1)
        } catch (error) {
          return tmpNum
        }
      }

    }
  }
  onLowQuality() {
    if ((this.Fresh_Test_Data.concentration < 2 && this.Fresh_Test_Data.motileSpermConc > 0.2) || (this.Fresh_Test_Data.concentration >= 2 && this.Fresh_Test_Data.motileSpermConc < 0.2) || (this.Fresh_Test_Data.concentration < 2 && this.Fresh_Test_Data.motileSpermConc < 0.2)) {
      this.Fresh_Test_Data.progressivePR = -1;
      this.Fresh_Test_Data.progressivePR = -1;
      this.Fresh_Test_Data.immotile = -1;
      this.Fresh_Test_Data.totalMotilePRNP = -1
    }
  }
  async reportHeader(isQC?: boolean) {
    var returningHeader: any = {}
    var headerArray: any = [];
    var whiteURI = 'assets/images/mes_man_transparent.png'
    var imageObj: any = {}
    var topMargin = -12
    // console.log("LLLLLLLLLL", this.facilityHeader);
    var header: boolean = isQC == true ? false : this.facilityHeader.testSettings.reportSettings.firstPage.reportHeader;

    if (this.facilityHeader.logoURL && this.facilityHeader.logoURL !== "" && this.facilityHeader.logoURL !== undefined) {
      try {
        var logoUrl1 = await this.getBase64ImageFromURL(this.facilityHeader.logoURL);
      } catch {
        logoUrl1 = await this.getBase64ImageFromURL(whiteURI);
      }
    } else {
      logoUrl1 = await this.getBase64ImageFromURL(whiteURI);
    }
    var width: any = {}
    await this.limitImageDims(logoUrl1, 55).then((data: any) => {
      width = data
      // console.log("checking limit", data);
    })
    var font;
    if (this.translate.currentLang == "ja") {
      font = "japneseFont"
    } else {
      font = "Roboto"
    }

    if (!header) {
      imageObj = {
        image: logoUrl1,
        width: width.width,
        height: width.height,
        // style: 'address'
      }
      if (this.facilityHeader.phone) {
        headerArray.push([{ text: this.unTranslate.includes(this.translate.currentLang) ? this.translate.instant('general.phone') + ":" : this.translate.instant('general.phone').toUpperCase() + ":", style: 'anotherStyle' }, { text: this.facilityHeader.phone == "" ? 'N/A' : this.facilityHeader.phone, font: 'japneseFont', style: 'anotherStyle' }])
      } else {
        headerArray.push([{ text: this.unTranslate.includes(this.translate.currentLang) ? this.translate.instant('general.phone') + ":" : this.translate.instant('general.phone').toUpperCase() + ":", style: 'anotherStyle' }, { text: 'N/A', style: 'anotherStyle' }])
      }

      if (this.facilityHeader.testSettings.reportSettings.firstPage.editEmail != "remove" || this.facilityHeader.testSettings.reportSettings.firstPage.isEditEmail == false) {
        headerArray.push([{ text: this.unTranslate.includes(this.translate.currentLang) ? this.translate.instant('general.email') + ":" : this.translate.instant('general.email').toUpperCase() + ":", style: 'anotherStyle', font: 'japneseFont' }, { text: this.facilityHeader.testSettings.reportSettings.firstPage.reportEmail == undefined || this.facilityHeader.testSettings.reportSettings.firstPage.reportEmail == "" ? 'N/A' : this.facilityHeader.testSettings.reportSettings.firstPage.reportEmail.toLowerCase(), style: 'anotherStyle', font: 'japneseFont' }])
      }
      //  else {
      //   headerArray.push([{ text: this.unTranslate.includes(this.translate.currentLang) ? this.translate.instant('general.email') + ":" : this.translate.instant('general.email').toUpperCase() + ":", style: 'anotherStyle', font: 'japneseFont' }, { text: '', style: 'anotherStyle' }])
      // }

      if (this.facilityHeader.website) {
        headerArray.push([{ text: this.unTranslate.includes(this.translate.currentLang) ? this.translate.instant('settings.website') + ":" : this.translate.instant('settings.website').toUpperCase() + ":", style: 'anotherStyle', font: 'japneseFont' }, { text: this.facilityHeader.website == "" ? 'N/A' : this.facilityHeader.website.toLowerCase(), style: 'anotherStyle', font: 'japneseFont' }])
      } else {
        headerArray.push([{ text: this.unTranslate.includes(this.translate.currentLang) ? this.translate.instant('settings.website') + ":" : this.translate.instant('settings.website').toUpperCase() + ":", style: 'anotherStyle', font: 'japneseFont' }, { text: 'N/A', style: 'anotherStyle' }])
      }
    } else {
      topMargin = this.facilityHeader.testSettings.reportSettings.firstPage.headerSpace - 15
      headerArray = []
      headerArray.push([{ text: " " }, { text: '' }])
      headerArray.push([{ text: " " }, { text: '' }])
      headerArray.push([{ text: " " }, { text: '' }])
      imageObj = {
        text: " ",
        height: 85,
        style: 'address'
      }
    }

    returningHeader = {
      rightHeader: headerArray,
      leftHeader: imageObj,
      HeaderSpace: topMargin
    }



    return returningHeader;
  }
  async Report(isDownload?: boolean) {
    console.log(this.facilityHeader, "sign2");
    const isFullART = () => {
      return this.ART_PREP_Results[0] != undefined && this.ART_PREP_Results[1] != undefined
    }
    if (this.ART_PREP_Results != undefined && this.Fresh_Test_Data.testingMode == 2) {
      if (isFullART()) {
        this.Fresh_Test_Data = this.ART_PREP_Results[1]
        this.Fresh_Test_Data.sampleInformation = this.ART_PREP_Results[0].sampleInformation
        this.Fresh_Test_Data.comments = this.ART_PREP_Results[0].comments
        // this.Fresh_Test_Data.dateTime = this.ART_PREP_Results[0].dateTime
      }
      if (this.ART_PREP_Results[0] != undefined) {
        this.ART_PREP_Results[0] = { ...this.switchdisplayValues(this.ART_PREP_Results[0]), ...this.ART_PREP_Results[0].sampleInformation }
        if (!isFullART())
          this.Fresh_Test_Data = this.ART_PREP_Results[0]
      }
      if (this.ART_PREP_Results[1] != undefined) {
        this.ART_PREP_Results[1] = { ...this.switchdisplayValues(this.ART_PREP_Results[1]), ...this.ART_PREP_Results[1].sampleInformation }
        if (!isFullART())
          this.Fresh_Test_Data = this.ART_PREP_Results[1]
        if (this.ART_PREP_Results[0] != undefined)
          this.Fresh_Test_Data.visualizationImages = this.ART_PREP_Results[0].visualizationImages
      }
    }

    let gotUrls = false
    let attachImgurl: any = []
    let imagestoAttach: any = []
    if (this.Fresh_Test_Data.visualizationImages != undefined && this.Fresh_Test_Data.visualizationImages.length != 0) {
      this.idtoImageURL(this.Fresh_Test_Data.visualizationImages).then((data: any[]) => {
        attachImgurl = data.filter(el => el != undefined)
        !environment.production && console.log(attachImgurl);

        gotUrls = true
      })
    } else {
      gotUrls = true
    }

    var footerData: any = await this.returnFooter(false)
    var header: boolean = this.facilityHeader.testSettings.reportSettings.firstPage.reportHeader;
    var footer: boolean = this.facilityHeader.testSettings.reportSettings.firstPage.reportFooter;
    var footerval = 40
    var imageObj: any = {};
    var dataArray: any = [];
    var style: string;
    var style2: string;
    var isdebris: boolean = false;
    // var canvas = document.getElementById('pie-chartcanvas-1') as HTMLCanvasElement;
    // this.chartURL = canvas.toDataURL();
    var logoUrl;
    var topMrgn = 45;
    var jap_tab2_pos = 0
    var jap_tab1_pos = 0
    var headerArray: any = [];
    let headerInfo: any = {}
    headerArray = this.reportHeader()
    var pctimeFormat = this.pcTimeFormat + " ";
    let pagebreak = ''
    var now = this.customPipe.transform(new Date(), this.pcDateFormat + ' | ' + pctimeFormat.split(" ")[0] + ':ss ' + pctimeFormat.split(" ")[1], this.translate.currentLang);
    var AVG = this.Fresh_Test_Data.calRawData.data.average.toFixed(2);
    var CNT = this.Fresh_Test_Data.calRawData.data.count.toFixed(1);
    var OD = this.Fresh_Test_Data.calRawData.data.od.toFixed(4);
    var AW = this.Fresh_Test_Data.calRawData.data.aw;
    var Sno = this.Fresh_Test_Data.rawData.data.serialNumber;
    var conc = this.Fresh_Test_Data.testSettings.concentrationStd;
    var sampletested;
    var signpos;
    let isnextPage: boolean = true
    //this.collectedDt = this.Fresh_Test_Data.sampleInformation.collectedTime.substr(0, this.Fresh_Test_Data.sampleInformation.collectedTime.length - 5) + "| " + this.Fresh_Test_Data.sampleInformation.collectedTime.substr(this.Fresh_Test_Data.sampleInformation.collectedTime.length - 5);
    !environment.production && console.log(this.Fresh_Test_Data, "freshtest");
    if (this.defaults == undefined) {
      const res = await this.http.getCollectionData("System", "defaultSettings")
      this.defaults = res.data
    }
    imageObj = (await headerArray).leftHeader
    const getDate = (date, trimmedKey) => {
      //there is no field or the value of the field is epty string
      const mainCondition = trimmedKey ? ((this.Fresh_Test_Data.sampleInformation[`${trimmedKey}Date`] == undefined || this.Fresh_Test_Data.sampleInformation[`${trimmedKey}Date`] != undefined) && this.Fresh_Test_Data.sampleInformation[`${trimmedKey}Date`] == "") : false
      try {
        if (date.toDate().toLocaleTimeString('it-IT') != '00:00:00')
          return this.customPipe.transform(date.toDate(), mainCondition ? `N/A | ${this.pcTimeFormat}` : this.pcDateFormat + ' | ' + this.pcTimeFormat, this.translate.currentLang)
        else
          return mainCondition ? "N/A" : `${this.customPipe.transform(date.toDate(), this.pcDateFormat, this.translate.currentLang)} | N/A`
      } catch (error) {
        try {
          if (date.toLocaleTimeString('it-IT') != '00:00:00')
            return this.customPipe.transform(date, mainCondition ? `N/A | ${this.pcTimeFormat}` : this.pcDateFormat + ' | ' + this.pcTimeFormat, this.translate.currentLang)
          else
            return mainCondition ? "N/A" : `${this.customPipe.transform(date, this.pcDateFormat, this.translate.currentLang)} | N/A`
        } catch (error) {
          return 'NA'
        }
      }
    }
    try {
      var testDt: any = this.Fresh_Test_Data.dateTime
      var testDtdownload: any
      testDt = getDate(this.Fresh_Test_Data.dateTime, "")
      testDtdownload = this.customPipe.transform(this.Fresh_Test_Data.dateTime.toDate(), this.pcDateFormat + ' | ' + this.pcTimeFormat, this.translate.currentLang);
      if (this.Fresh_Test_Data.sampleInformation.collectedTime != undefined) {
        var collectedDt: any = this.Fresh_Test_Data.sampleInformation.collectedTime
        collectedDt = getDate(this.Fresh_Test_Data.sampleInformation.collectedTime, "collected")
      }
      if (this.Fresh_Test_Data.sampleInformation.receivedTime != undefined) {
        var receivedDt: any = this.Fresh_Test_Data.sampleInformation.receivedTime
        receivedDt = getDate(this.Fresh_Test_Data.sampleInformation.receivedTime, "received")
      }

    } catch (error) {
      // console.log("IIIIIIIII", error);
      if (this.Fresh_Test_Data.sampleInformation.collectedTime != undefined)
        //  collectedDt = this.customPipe.transform(this.Fresh_Test_Data.sampleInformation.collectedTime, this.pcDateFormat + ' | ' + this.pcTimeFormat, this.translate.currentLang)
        collectedDt = getDate(this.Fresh_Test_Data.sampleInformation.collectedTime, "collected")

      if (this.Fresh_Test_Data.sampleInformation.receivedTime != undefined)
        //  receivedDt = this.customPipe.transform(this.Fresh_Test_Data.sampleInformation.receivedTime, this.pcDateFormat + ' | ' + this.pcTimeFormat, this.translate.currentLang);
        receivedDt = getDate(this.Fresh_Test_Data.sampleInformation.receivedTime, "received")


      testDt = this.customPipe.transform(this.Fresh_Test_Data.dateTime, this.pcDateFormat + ' | ' + this.pcTimeFormat, this.translate.currentLang);
      testDtdownload = this.customPipe.transform(this.Fresh_Test_Data.dateTime, this.pcDateFormat + ' | ' + this.pcTimeFormat, this.translate.currentLang);
    }
    this.criteria = this.Fresh_Test_Data.testSettings.who + 2 + "th";

    var debrisValue = this.translate.instant('test_process.none_few_debris');
    var wbcvalue;
    if (this.Fresh_Test_Data.testSettings.debrisAssessment) {
      if (this.Fresh_Test_Data.sampleInformation.debris == 1) {
        debrisValue = this.translate.instant('test_process.none_few_debris');
      } else if (this.Fresh_Test_Data.sampleInformation.debris == 2) {
        debrisValue = this.translate.instant('test_process.moderate_debris');
      } else if (this.Fresh_Test_Data.sampleInformation.debris == 3) {
        debrisValue = this.translate.instant('test_process.many_debris');
      } else if (this.Fresh_Test_Data.sampleInformation.debris == 4) {
        debrisValue = this.translate.instant('test_process.gross_debris');
      }
    }
    if (this.Fresh_Test_Data.sampleInformation.wbcConcentration == 1) {
      wbcvalue = "<1"
    } else if (this.Fresh_Test_Data.sampleInformation.wbcConcentration == 2) {
      wbcvalue = ">=1"
    } else {
      wbcvalue = "N/A"
    }
    var who = "WHO " + this.criteria;
    var optional1 = 'settings.opt_1'
    var optional2 = 'settings.opt_2'
    var rightHeaderWidth = 32;
    var testernameWidth = 197;
    var testermarginLength = 137
    var testermarginpos = -13;
    var switchFont = 'Roboto';
    var jap_header = 0;
    this.calculateAge();


    if (this.Fresh_Test_Data.sampleInformation.optional1 != undefined && this.Fresh_Test_Data.sampleInformation.optional1 != "") {
      var opt1 = this.Fresh_Test_Data.sampleInformation.optional1;
    } else {
      opt1 = "N/A";
    }
    if (this.Fresh_Test_Data.sampleInformation.optional2 != undefined && this.Fresh_Test_Data.sampleInformation.optional2 != "") {
      var opt2 = this.Fresh_Test_Data.sampleInformation.optional2;
    } else {
      opt2 = "N/A";
    }
    if (this.Fresh_Test_Data.sampleInformation.sampleId || this.Fresh_Test_Data.sampleInformation.sampleId == 0) {
      var sampleID = this.Fresh_Test_Data.sampleInformation.sampleId;
    } else {
      sampleID = "";
    }
    var volume: any
    if (this.Fresh_Test_Data.sampleInformation.volume != undefined && this.Fresh_Test_Data.sampleInformation.volume != "") {
      volume = this.Fresh_Test_Data.sampleInformation.volume;
    } else {
      volume = "N/A";
    }
    var ph: any
    if (this.Fresh_Test_Data.sampleInformation.pH != undefined) {
      ph = this.Fresh_Test_Data.sampleInformation.pH;
    } else {
      ph = "N/A";
    }
    if (this.Fresh_Test_Data.comments) {
      var comments = this.Fresh_Test_Data.comments;
    } else {
      comments = "";
    }

    // console.log("this.Fresh_Test_Data.testSettings", this.Fresh_Test_Data.testSettings);

    var paddingTop = -5
    if (this.Fresh_Test_Data.testSettings.debrisAssessment && this.Fresh_Test_Data.testSettings.debrisAssessment == true && this.Fresh_Test_Data.testSettings.debrisAssessment !== undefined && this.Fresh_Test_Data.testSettings.debrisAssessment !== null) {
      isdebris = true;
      style = 'paraTable'
      style2 = 'sampleinforight'
      jap_tab2_pos = 12
    } else {
      style = 'paraTable2'
      style2 = 'sampleinforight2'
      jap_tab2_pos = 12
      paddingTop = 8
    }
    var setTextpos = ''
    const modifiedData = await this.switchdisplayValues(this.Fresh_Test_Data)
    console.log("no sperm seen", this.Fresh_Test_Data, this.ART_PREP_Results);

    if (this.Fresh_Test_Data.noSpermSeen != true) {
      if (this.Fresh_Test_Data.testingMode == 2 && (this.ART_PREP_Results[0] != undefined && this.ART_PREP_Results[1] != undefined)) {
        this.makeChartfromValues(this.ART_PREP_Results[1])
      } else {
        this.makeChartfromValues(this.Fresh_Test_Data)
      }
    }
    // if (this.Fresh_Test_Data.testSettings.who == 3) {
    if (this.Fresh_Test_Data.testingMode != 2) {
      this.switchArrow(modifiedData);
    }
    signpos = 'signaturesection'
    setTextpos = 'notifitextstyle'
    // } else {
    //   await this.switchArrow(modifiedData);
    //   dataArray = await this.who3_4_Parameters(dataURL);
    //   signpos = 'testerName'
    //   setTextpos = 'notifitextstyle3_4'
    // }
    var notifyText = ''
    if (this.Fresh_Test_Data.concentration == -3 || this.Fresh_Test_Data.motileSpermConc == -3) {
      notifyText = '';
    } else {
      notifyText = '';
    }
    if (this.Fresh_Test_Data.testSettings.testOptional1 != undefined && this.Fresh_Test_Data.testSettings.testOptional1 != " " && this.Fresh_Test_Data.testSettings.testOptional1 != "") {
      optional1 = this.Fresh_Test_Data.testSettings.testOptional1
    } else {
      optional1 = 'settings.opt_1';
    }
    if (this.Fresh_Test_Data.testSettings.testOptional2 != undefined && this.Fresh_Test_Data.testSettings.testOptional2 != " " && this.Fresh_Test_Data.testSettings.testOptional2 != "") {
      optional2 = this.Fresh_Test_Data.testSettings.testOptional2
    } else {
      optional2 = 'settings.opt_2';
    }

    if (this.facilityHeader.signatureURL && this.facilityHeader.signatureURL != "") {
      console.log("sign0", this.facilityHeader.signatureURL);
      var signimg = this.facilityHeader.signatureURL
      var signImg = await this.getBase64ImageFromURL(signimg)
    }

    var sampInfo_left1 = 100
    var sampInfo_left2 = 151
    if (this.languageType == 'pt' || this.languageType == 'de') {
      sampInfo_left1 += 10;
      sampInfo_left2 -= 10;
    }
    var signimgYpos = 760
    var signimgXpos = 60
    if (footer) {
      // footerval = this.facilityHeader.testSettings.reportSettings.firstPage.footerSpace * 2.8346456692913 //setting footerspace
    } else {
      signimgYpos = 760
    }

    var headermargin = 0
    if (this.facilityHeader.testSettings.headerSpace != undefined && this.facilityHeader.testSettings.reportHeader) {
      headermargin = 2.4 * this.facilityHeader.testSettings.reportSettings.firstPage.headerSpace
      // topMrgn = 5
    } else {
      if (this.languageType == 'ja') {
        headermargin = 45
      } else {
        headermargin = 30
      }
    }
    if (footerval > 10 || (this.facilityHeader.testSettings.reportHeader && this.facilityHeader.testSettings.headerSpace > 10)) {
      isnextPage = true
    } else {
      isnextPage = false
    }
    dataArray = await this.parametersTable(this.chartURL, modifiedData, this.Fresh_Test_Data.testSettings.who, testDt);
    var jap_patInfo_left = 0
    var jap_patInfo_right = 0
    var jap_sampleInfo_left = 0
    var jap_sampleInfo_right = 0
    if (this.languageType == 'ja') {
      switchFont = 'japneseFont';
      jap_tab1_pos = 1
      jap_tab2_pos = 1
      jap_header = 0
      headermargin -= 14

    }
    else {
      // switchFont = 'Roboto';
      jap_tab1_pos = 0
      jap_tab2_pos = 0
      jap_header = 0
    }
    if (this.languageType == 'en' || this.languageType == 'fr' || this.languageType == 'vi' || this.languageType == 'de' || this.languageType == 'ja') {
      jap_patInfo_left = 0
      jap_patInfo_right = 0
    } else {
      // if (this.languageType=='pt') {
      //   jap_patInfo_left = 30
      //   jap_patInfo_right = -30
      // } else {
      jap_patInfo_left = 20
      jap_patInfo_right = -20
      // }
    }
    if (this.languageType == 'it' || this.languageType == 'es' || this.languageType == 'pt') {
      jap_sampleInfo_left = 20
      jap_sampleInfo_right = -20
    } else {
      jap_sampleInfo_left = 0
      jap_sampleInfo_right = 0
    }


    this.leftData = [];
    this.rightData = [];
    if (this.Fresh_Test_Data.sampleInformation.testType == 1) {
      sampletested = this.unTranslate.includes(this.translate.currentLang) ? this.translate.instant('test_process.normal_volume') : this.translate.instant('test_process.normal_volume').toUpperCase()
    } else if (this.Fresh_Test_Data.sampleInformation.testType == 2) {
      sampletested = this.translate.instant('test.dilution')
    } else if (this.Fresh_Test_Data.sampleInformation.testType == 3) {
      sampletested = this.translate.instant('test_process.10_micro')
    }
    await this.loadsampleInfo(testDt, receivedDt, collectedDt, who, volume, ph, opt1, opt2, optional1, optional2, isdebris, debrisValue, wbcvalue, sampletested);

    var margin = 25;

    if (this.facilityHeader.website) {
      if (this.facilityHeader.testSettings.reportSettings.firstPage.reportEmail.length >= this.facilityHeader.website.length) {
        if (this.facilityHeader.testSettings.reportSettings.firstPage.reportEmail.length >= 30) {
          if (this.facilityHeader.testSettings.reportSettings.firstPage.reportEmail.length >= 40) {
            margin = this.facilityHeader.testSettings.reportSettings.firstPage.reportEmail.length * 2 + 32
            console.log("came here............", margin);

          } else {
            margin = this.facilityHeader.testSettings.reportSettings.firstPage.reportEmail.length * 2 + 20
          }
        } else {
          if (this.facilityHeader.testSettings.reportSettings.firstPage.reportEmail.length > 15) {
            margin = this.facilityHeader.testSettings.reportSettings.firstPage.reportEmail.length + 15
          }
        }
      }
      else {
        if (this.facilityHeader.website.length >= 30) {
          if (this.facilityHeader.website.length >= 40) {
            margin = this.facilityHeader.website.length * 2
            // console.log(margin, "expected");

          } else {
            margin = this.facilityHeader.website.length * 2 + 20

          }
        } else {
          if (this.facilityHeader.website.length > 15) {
            margin = this.facilityHeader.website.length + 15
            // console.log(margin, "Margin");

          }
        }
      }
    } else {
      if (this.facilityHeader.testSettings.reportSettings.firstPage.reportEmail.length >= 30) {
        if (this.facilityHeader.testSettings.reportSettings.firstPage.reportEmail.length >= 40) {
          margin = this.facilityHeader.testSettings.reportSettings.firstPage.reportEmail.length * 2 + 32
          console.log("came here......", margin);

        } else {
          margin = this.facilityHeader.testSettings.reportSettings.firstPage.reportEmail.length * 2 + 20

        }

      } else {

        if (this.facilityHeader.testSettings.reportSettings.firstPage.reportEmail.length > 15) {
          margin = this.facilityHeader.testSettings.reportSettings.firstPage.reportEmail.length + 15
        }
      }
    }

    var desg_line_length = 120
    var phoneFont = 7


    // console.log("Facility Header", this.facilityHeader, dataArray);
    if (this.languageType == 'es') {
      margin += 40
    }
    console.log("Margin..........", margin);

    if (!header) {
      headerInfo = {
        // alignment: 'justify',
        columns: [
          {
            width: 430 - margin,
            table: {
              widths: [160],
              headerRows: 1,
              body: [
                [imageObj],
                [{ text: this.facilityHeader.facilityName, style: 'facilityHeader' }],
                [{ text: this.getAddress(), style: 'anotherStyle', margin: [0, 0, 0, 0] }]
              ]
            },
            layout: {
              defaultBorder: false,
            }
          },
          {
            width: '*',
            margin: [0, 10, 0, 10],
            table: {
              widths: [(await footerData).topright, '*'],
              headerRows: 1,
              body: (await headerArray).rightHeader
            },
            layout: {
              defaultBorder: false,
            }
          },
        ]
      }
    } else {

      // topMrgn =255
      topMrgn = this.facilityHeader.testSettings.reportSettings.firstPage.headerSpace * 2.8346456692913
      headerInfo = {}
    }
    // console.log("margin Prof", margin, (await footerData).topright);

    // pdfFonts.pdfMake.vfs['Arial_unicode'] = arial_unicodeURL
    // pdfFonts.pdfMake.vfs['Arial_unicode_bold'] = arial_unicodeBoldURL
    // pdfMake.fonts = {
    //   Roboto: {
    //     normal: 'Roboto-Regular.ttf',
    //     bold: 'Roboto-Medium.ttf',
    //     italics: 'Roboto-Italic.ttf',
    //     bolditalics: 'Roboto-MediumItalic.ttf'
    //   },
    //   japneseFont: {
    //     normal: 'Arial_unicode',
    //     bold: 'Arial_unicode_bold',
    //     italics: 'Arial_unicode',
    //     bolditalics: 'Arial_unicode'
    //   },
    // }
    let afterOD = ""
    if (this.Fresh_Test_Data.visualizationImages != undefined && this.Fresh_Test_Data.visualizationImages.length > 0 && this.Fresh_Test_Data.visualizationVideos != undefined && this.Fresh_Test_Data.visualizationVideos.length > 0) {
      afterOD = ` | IMG: ${this.Fresh_Test_Data.visualizationImages.length}, VID: ${this.Fresh_Test_Data.visualizationVideos.length}`
    }
    const getMIndication = () => {
      if (!this.isManual)
        return ``
      if (this.Fresh_Test_Data.countingMethod == 0) {
        if (this.Fresh_Test_Data.countedMotilityFields !== undefined && this.Fresh_Test_Data.countedMotilityFields.length > 0) {
          return `[MC] | `
        } else if (this.Fresh_Test_Data.countedMorphologyFields !== undefined && this.Fresh_Test_Data.countedMorphologyFields.length > 0) {
          return `[MM] | `
        }
      } else if (this.Fresh_Test_Data.countingMethod == 1)
        return `[MA] | `
      else if (this.Fresh_Test_Data.countingMethod == 4)
        return `[ME] | `
      else
        return ``
    }

    const getPrivacyMode = () => {
      const systemSettings: SystemSettings = this.facilityHeader.testSettings.systemSettings
      if (systemSettings) {
        if (systemSettings.isPrivacyMode == true) {
          return `[PM] | `
        } else {
          return ``
        }
      } else {
        return ``
      }
    }
    var footerText = 'FAC ID#: ' + this.facilityHeader.uid.substring(0, 6) + ' | SN# : ' + Sno + ' | ' + getPrivacyMode() + getMIndication() + this.translate.instant('settings.conc_head') + ' ' + conc + ' | ' + now + ' | AVG ' + AVG + ' | AW ' + AW + ' | CNT ' + CNT + ' | OD ' + OD
    //  + afterOD

    // console.log("KIJIJIJIJ", margin);
    switchFont = 'japneseFont';
    // let isFullART = () => {
    //   return this.ART_PREP_Results[0] != undefined && this.ART_PREP_Results[1] != undefined
    // }
    var widths: any = this.Fresh_Test_Data.testingMode == 2 ? isFullART() ? ['*', 50, 55, 33, '*'] : ['*', 55, 50, '*'] : ['*', 45, 35, 29, 18, '*']
    if (this.Fresh_Test_Data.testingMode == 2) {
      if (this.languageType == "tr") {
        widths = isFullART() ? [225, 40, 45, 40, '*'] : [240, 40, 40, '*']
      } else if (this.languageType == "vi") {
        widths = isFullART() ? [210, 50, 55, 30, '*'] : ['*', 55, 30, '*']
      } else if (this.languageType == "es") {
        widths = isFullART() ? [215, 40, 45, 33, '*'] : [187, 55, 50, '*']
      } else if (this.languageType == "pt") {
        widths = isFullART() ? ['*', 58, 55, 38, '*'] : ['*', 55, 50, '*']
      } else if (this.languageType == "it") {
        widths = isFullART() ? [215, 40, 45, 28, '*'] : [225, 55, 50, '*']
      }
    } else {
      if (this.languageType == "tr") {
        widths = [220, 30, 42, 21, 16, 179]
      } else if (this.languageType == "vi") {
        widths = [196, 44, 38, 28, 20, 185]
      } else if (this.languageType == "es") {
        widths = [187, 50, 35, 33, 23, '*']
      } else if (this.languageType == "pt") {
        widths = ['*', 52, 37, 31, 23, '*']
      } else if (this.languageType == "it") {
        widths = [215, 45, 30, 29, 18, '*']
      }
    }


    const getFont = (lang: string): Number => {
      if (lang == 'es') {
        return 6.1
      } else if (lang == 'it') {
        return 7.2
      } else if (lang == 'pt') {
        return 7.2
      } else if (lang == 'tr') {
        return 7.5
      } else if (lang == 'vi') {
        return 7.5
      } else {
        return 7.7
      }
    }
    const interval = setInterval(async () => {

      if (gotUrls == true) {
        gotUrls = false
        clearInterval(interval)
        let docBody: any[] = await this.returnPdfBody(headerInfo, sampInfo_left1, jap_patInfo_left, sampInfo_left2, jap_patInfo_right, widths, dataArray, footerData, sampleID, comments)
        if (this.Fresh_Test_Data.visualizationImages != undefined && this.Fresh_Test_Data.visualizationImages.length != 0 && attachImgurl.length != 0) {
          await this.imagesPdfStructure(attachImgurl, testDt) ? docBody.push(await this.imagesPdfStructure(attachImgurl, testDt)) : null
        }
        const reportSettings = this.facilityHeader.testSettings.reportSettings

        var docDefinition = {
          header: (currentPage, pageCount, pageSize) => {
            this.PageCount = pageCount;
            var pageNumber: any = [{ text: ` `, style: "headerText", },];
            const criteria = this.Fresh_Test_Data.testSettings.who
            if ((criteria == 4 && currentPage == 1 && reportSettings.firstPage.displayPageNumber) || (criteria == 3 && reportSettings.firstPage.displayPageNumber))
              pageNumber = [{ text: `${this.translate.instant("test.page")}  ${currentPage}  ${this.translate.instant("test.of")}  ${pageCount} `, style: "headerText", },]
            else if (criteria == 4 && currentPage != 1 && reportSettings.secondPage.displayPageNumber)
              pageNumber = [{ text: `${this.translate.instant("test.page")}  ${currentPage}  ${this.translate.instant("test.of")}  ${pageCount} `, style: "headerText", },]

            return pageNumber
            // }
          },
          footer: (currentPage, pageCount, pageSize) => {
            this.PageCount = pageCount;
            let currentPageSettings: any;
            const colWidth = ((210 * 2.835) - 27) / 3
            if (currentPage == 1) {
              currentPageSettings = reportSettings.firstPage
            } else {
              if (this.Fresh_Test_Data.testSettings.who === 3) {
                currentPageSettings = reportSettings.firstPage
              } else if (this.Fresh_Test_Data.testSettings.who === 4) {
                currentPageSettings = reportSettings.secondPage
              }
            }
            let pgMargin = -5;
            var footer: any = [{ text: ``, style: "footerstyle", margin: [0, 0, 0, -15] }];
            // if (currentPage == pageCount) {
            if (!currentPageSettings.reportFooter) {
              footer = [{ text: footerText, style: "footerstyle", margin: [0, 5, 0, -15] }];
              pgMargin = -30
              // if (currentPage !=1 && reportSettings.firstPage.reportFooter==true) {
              //   pgMargin = footerval-50
              //   // footer[0].margin = [0,pgMargin,0,-15]
              // }
            } else {

              // if (currentPage !=1 ) {
              pgMargin = -Number(currentPageSettings.footerSpace * 2.8346456692913)
              // }else {
              //   pgMargin = -Number(currentPageSettings.footerSpace * 2.8346456692913)
              // } 
            }

            const footerObj = {
              margin: [12, pgMargin, 10, !currentPageSettings.reportFooter ? 10 : 0],
              columns: [
                !currentPageSettings.removeSignature && {
                  width: colWidth,
                  margin: [0, -11, 0, 0],
                  table: {
                    widths: [(footerData).firstCellWidth('sign'), '*'],
                    body: [
                      (footerData).signature,
                    ],
                    layout: {
                      defaultBorder: true,
                    }
                  }
                },
                !currentPageSettings.removeTester && {
                  // style: 'sign',
                  width: colWidth,
                  table: {
                    // this.translate.currentLang != 'en' ? 75 : 54
                    widths: [(footerData).firstCellWidth('tester'), '*'],
                    body: [
                      (footerData).tester
                    ]
                  },
                  layout: {
                    defaultBorder: true,
                  }
                },
                !currentPageSettings.removeTester && {
                  // style: 'sign',
                  width: colWidth,
                  table: {
                    widths: [(footerData).firstCellWidth('desg'), '*'],
                    body: [
                      (footerData).desg
                    ]
                  },
                  layout: {
                    defaultBorder: true,
                  }

                },

              ].filter(el => el)
            }
            if (!currentPageSettings.removeSignature || !currentPageSettings.removeTester)
              footer.unshift(footerObj)

            console.log(footerData);

            return footer
          },
          info: {
            title: 'Semen Analysis Test Report',
            author: 'MES',
          },
          pageMargins: [17, topMrgn - (topMrgn > 50 ? 22 : 5), 17, footerval - 10],
          content: docBody,
          // unbreakable: true,

          styles: {
            header: {
              fontSize: 14,
              bold: 'Helvetica-Bold',
              alignment: 'center',
              normal: 'Helvetica'
            },
            subhead: {
              fontSize: 9.5,
              margin: [0, 5, 0, 5],
              alignment: 'center',
              normal: 'Helvetica'
            },
            tabledata: {
              normal: 'Helvetica',
              fontSize: this.smallFont ? 7.7 : 9.0,
              color: 'black',
              alignment: 'center',
              margin: [0, 2, 0, 0],
            },
            footerstyle: {
              normal: 'Helvetica',
              fontSize: 7.7,
              color: 'grey',
              alignment: 'center'
            },

            tableHeader: {
              normal: 'Helvetica',
              bold: 'Helvetica-Bold',
              fontSize: this.smallFont ? 7.7 : 7.7,
              color: 'black',
              uppercase: true
            },
            facilityHeader: {
              normal: 'Helvetica',
              bold: 'Helvetica-Bold',
              fontSize: 9.7,
              color: 'black',
              uppercase: true

            },

            anotherStyle: {
              alignment: 'left',
              // fontSize: this.smallFont ? 7.5 : 8.8,
              fontSize: 7.5,
              margin: [0, 2, 0, 0],
              normal: 'Helvetica',
            },
            resultsParam: {
              alignment: 'left',
              fontSize: this.smallFont ? getFont(this.translate.currentLang) : 8.8,
              margin: [0, 2, 0, 0],
              normal: 'Helvetica',
            },

            footerTable: {
              alignment: 'left',
              fontSize: this.smallFont ? 7.5 : 8.8,
              normal: 'Helvetica',
            },

            paraheader: {
              alignment: 'center',
              normal: 'Helvetica',
              bold: 'Helvetica-Bold',
              fontSize: this.smallFont ? 7.7 : 9.0,
              color: 'black',
              margin: [0, 1, 0, 0]
            },
            comments: {
              margin: [-20, 0, 0, 0],
            },
            sign: {
              margin: [-20, 0, 35, 0],
              alignment: 'left',
            },
            columnStyle: {
              margin: [0, 10, 0, 0]
            },
            headerText: {
              fontSize: 8.5,
              alignment: "right",
              margin: [10, 10, 14, 10],
            },
            reportContinueText: {
              fontSize: 10,
              alignment: "left",
            },
            smi_Bullets: {
              margin: [6, 5, 0, 0],
              lineHeight: false ? 1.4 : 1.1,
              style: 'anotherStyle',
              fontSize: 10.5
            }



          },
          defaultStyle: {
            font: switchFont,

          }

        };

        pdfMake.tableLayouts = {
          exampleLayout: {

            fillColor: function (row, col, node) {

              if (row % 2 == 0 && node != 5) {
                return '#eee'

              } else {
                return 'white'

              }

            }
          }
        };


        if (isDownload) {
          if (this.Fresh_Test_Data.dateTime.seconds != undefined) {
            this.Pdfobj = pdfMake.createPdf(docDefinition).download('Semen_Analysis_Test_Report_PID' + this.Patient_Info.patientid + '_' + (testDtdownload.replace('/', '_')).replace(' ', '_') + '.pdf');
          } else {
            this.Pdfobj = pdfMake.createPdf(docDefinition).download('Semen_Analysis_Test_Report_PID' + this.Patient_Info.patientid + '_' + (testDtdownload.replace('/', '_')).replace(' ', '_') + '.pdf');
          }
        } else {
          this.Pdfobj = pdfMake.createPdf(docDefinition).open();
        }
      }
    }, 100)
  }

  getAddress() {
    var address = ''
    if (this.facilityHeader.streetNo) {
      if ((this.facilityHeader.country?.toLowerCase().includes("united states") && this.facilityHeader.state != undefined && this.facilityHeader.state != "") || this.translate.currentLang == 'ja') {
        if (this.translate.currentLang == 'ja') {
          if (this.facilityHeader.state != undefined && this.facilityHeader.state != '') {
            address += this.facilityHeader.zipCode + this.facilityHeader.state + this.facilityHeader.city
          } else {
            address += this.facilityHeader.zipCode + this.facilityHeader.city
          }
          if (this.facilityHeader.streetNo.split(";")[1] != undefined && this.facilityHeader.streetNo.split(";")[1] != "null") {
            address += this.facilityHeader.streetNo.split(";")[0] + this.facilityHeader.streetNo.split(";")[1]
          } else {
            address += this.facilityHeader.streetNo.split(";")[0]
          }
        } else {
          if (this.facilityHeader.streetNo.split(";")[1] != undefined && this.facilityHeader.streetNo.split(";")[1] != "null") {
            address = this.facilityHeader.streetNo.split(";")[0] + ' ' + this.facilityHeader.streetNo.split(";")[1]
          } else {
            address = this.facilityHeader.streetNo.split(";")[0]
          }
          if (this.facilityHeader.state != undefined && this.facilityHeader.state != '') {
            address += ', ' + this.facilityHeader.city + ' ' + this.facilityHeader.state + '' + this.facilityHeader.zipCode + ', ' + this.facilityHeader.country
          } else {
            address += ', ' + this.facilityHeader.city + '\n ' + this.facilityHeader.zipCode + ', ' + this.facilityHeader.country
          }
        }

      } else {
        if (this.facilityHeader.streetNo.split(";")[1] != undefined && this.facilityHeader.streetNo.split(";")[1] != "null") {
          address = this.facilityHeader.streetNo.split(";")[0] + ' ' + this.facilityHeader.streetNo.split(";")[1]
        } else {
          address = this.facilityHeader.streetNo.split(";")[0]
        }
        if (this.facilityHeader.state != undefined && this.facilityHeader.state != '') {
          address += ', ' + this.facilityHeader.city + ' ' + this.facilityHeader.state + '\n' + this.facilityHeader.zipCode + ', ' + this.facilityHeader.country
        } else {
          address += ', ' + this.facilityHeader.city + '\n ' + this.facilityHeader.zipCode + ', ' + this.facilityHeader.country
        }
      }
    }
    console.log("Address?????????????????????", address);

    return address
  }

  async returnPdfBody(headerInfo, sampInfo_left1, jap_patInfo_left, sampInfo_left2, jap_patInfo_right, widths, dataArray, footerData, sampleID, comments) {
    console.log(this.Patient_Info, this.Fresh_Test_Data);

    const handleRefDoc = () => {
      if ((this.facilityHeader.testSettings.systemSettings as SystemSettings).isPrivacyMode == true) {
        return ""
      } else {
        if (this.Fresh_Test_Data.referringDoc?.trim() && typeof this.Fresh_Test_Data.referringDoc != "object") {
          return this.Fresh_Test_Data.referringDoc
        } else {
          return 'N/A'
        }
      }
    }

    const makeTable = (Tbody: any[], Twidths?) => {
      const tableBody = Tbody.reduce((pre, cur) => {
        const txt = cur.unit ? `${this.translateNreturn(cur.leftText, true)} ${this.translateNreturn(cur.unit, undefined, true)}` : this.translateNreturn(cur.leftText, undefined, undefined, cur.isLowerCase)
        return [...pre, [{ text: txt, ...cur.type == "title" ? { colSpan: 2, style: 'tableHeader' } : { style: 'anotherStyle' } }, { text: cur.rightText, ...cur.type == "title" ? { colSpan: 2, style: 'tableHeader' } : { style: 'anotherStyle' } }]]
      }, [])

      return {
        table: {
          widths: Twidths,
          body: tableBody
        },
        layout: {
          hLineWidth: function (i, node) {
            if (i != 0)
              return 1;
            else
              return 0;
          },
          vLineWidth: function (i, node) {
            return 0;
          },
        }
      }
    }
    const getPatientName = (isFirstName: boolean) => {
      const lang = this.translate.currentLang

      return lang != 'ja' ? { leftText: isFirstName ? 'patients.first_name' : 'patients.last_name', type: 'data', rightText: this.handlePatientName(this.Patient_Info[isFirstName ? 'firstName' : 'lastName']) } : { leftText: isFirstName ? 'patients.last_name' : 'patients.first_name', type: 'data', rightText: this.handlePatientName(this.Patient_Info[isFirstName ? 'lastName' : 'firstName']) }

    }
    console.log("Langggggggggggggggggggggg", this.unTranslate, this.translate.currentLang);

    let tablebodayArray = [
      headerInfo,
      { text: this.unTranslate.includes(this.translate.currentLang) ? this.translate.instant('test.report_head') : this.translate.instant('test.report_head').toUpperCase().replace("SQA-IO", "SQA-iO"), style: 'header' },
      { text: this.translate.instant('test.report_subhead'), style: 'subhead' },                                          //Header of the report starts here this.Patient_Info.firstName + " " + this.Patient_Info.lastName

      //Renders the Patient information
      {
        columns: [
          makeTable([{ leftText: 'patients.patient_information', colSpan: 2, type: 'title', rightText: "" }, getPatientName(true), { leftText: 'patients.patient_id', type: 'data', rightText: this.Patient_Info.patientid }, { leftText: 'test.ref_doc', type: 'data', rightText: handleRefDoc()}], [sampInfo_left1 + jap_patInfo_left, sampInfo_left2 + jap_patInfo_right]),
          makeTable([{ leftText: ' ', colSpan: 2, type: 'title', rightText: "" }, getPatientName(false), { leftText: this.Fresh_Test_Data.testSettings.patientAge == "BirthDate" ? 'test.birth_day_age' : 'test.age', type: 'data', rightText: this.dateandAge }, { leftText: !this.removePhonenumber ? 'general.phone_num' : ' ', type: 'data', rightText: !this.removePhonenumber ? (this.Patient_Info.mobile_phone && typeof this.Patient_Info.mobile_phone != "object") ? this.Patient_Info.mobile_phone : 'N/A' : ' ' }], [115 + jap_patInfo_left, '*']),
        ]
      },
      //Renders the Sample information
      {
        margin: [0, 7, 0, 0],
        columns: [
          makeTable(this.leftData, [sampInfo_left1 + jap_patInfo_left, sampInfo_left2 + jap_patInfo_right]),
          makeTable(this.rightData, [115 + jap_patInfo_left, '*']),
        ]
      },

      {
        margin: [0, 10, 0, 0],
        table: {
          // dontBreakRows: true,
          widths: widths,
          // headerRows: 1,
          body: dataArray[0],
          id: 'paramTable',
        },
        // layout: 'exampleLayout'
      },
    ]
    if (this.Fresh_Test_Data.testSettings.who == 4 && this._authService.iOwMode == false) {
      if (!(this.Fresh_Test_Data.testingMode == 2 && !this.displayAllParams)) {
        // return tablebodayArray
        tablebodayArray.push({
          margin: [0, this.getSecondPageMargin("headerSpace"), 0, 0],
          table: {
            // dontBreakRows: true,
            widths: widths,
            // headerRows: 1,
            body: dataArray[1],
            id: 'paramTable',
          },
          pageBreak: 'before',
          // layout: 'exampleLayout'
        })
      }
    }


    tablebodayArray.push({
      style: "columnStyle",
      margin: [0, 10, 0, (this.facilityHeader.testSettings.who == 4 && comments.length == 0) ? 25 : 8],
      italics: false,
      // pageBreak: this._authService.iOwMode == true ? 'after' : '',
      text: [
        { text: this.unTranslate.includes(this.translate.currentLang) ? this.translate.instant('test.comments') + ':' + ' ' : this.translate.instant('test.comments').toUpperCase() + ':' + ' ', fontSize: 9.5, bold: 'Helvetica-Bold', }, { text: comments, lineHeight: 1.4, style: 'anotherStyle', fontSize: 9.5 }
      ],
    })

    if ((this.Fresh_Test_Data.testSettings.who == 4 && this.Fresh_Test_Data.testingMode == 2 && !this.displayAllParams && this.hasAddtionalText)) {
      tablebodayArray.push({
        margin: [0, this.getSecondPageMargin("headerSpace"), 0, 0],
        table: {
          // dontBreakRows: true,
          widths: widths,
          // headerRows: 1,
          body: [dataArray[1][0]],
          id: 'paramTable',
        },
        pageBreak: 'before',
        // layout: 'exampleLayout'
      })
      // tablebodayArray.push({
      //   margin: [0, 5, 0, 0],
      //   text: [
      //     { text: this.translate.instant('test.mes_parameters_msg'), style: 'anotherStyle', fontSize: 9.5 }
      //   ]
      // })
    }



    // const data = this.defaults.WHO6ReportText_SMI
    if (this.hasAddtionalText) {
      const data = this.facilityHeader.testSettings.reportSettings.secondPage.additionalText == additionalText.semenAnalysis ? this.defaults.WHO6ReportText_ANALYSIS : this.defaults.WHO6ReportText_SMI
      const makeArray = (data) => {
        console.log(data[this.translate.currentLang]);
        let arr = []
        for (const key in data) {
          const element = data[key];
          console.log(element);
          if (key == "mainTitle") {
            arr.unshift({ text: element, margin: [0, 0, 0, 6], fontSize: 10, bold: 'Helvetica-Bold' })
          } else if (key == "paragraphs") {
            element.forEach((para: any) => {
              arr.push({ text: para.title, decoration: "underline", fontSize: 10 })
              arr.push({
                style: 'smi_Bullets',
                ul: para.bullets
              })
            })

          }
        }
        return arr
      }
      if (this.Fresh_Test_Data.testSettings.who == 4) {
        tablebodayArray.push(
          {
            pageBreak: this._authService.iOwMode ? 'before' : '',
            stack: makeArray(Object.keys(data[this.translate.currentLang]).length != 0 ? data[this.translate.currentLang] : data['en'])
          }
        )
      }

    }
    console.log(tablebodayArray);



    return tablebodayArray
  }


  async returnFooter(isQc: boolean) {
    var footer: boolean = this.facilityHeader.testSettings.reportSettings.firstPage.reportFooter;
    var footerval = 0
    var margin = 10;
    var designationxpos = 0;
    var tester_name_xpos = 167;
    var desg_line_length = 120
    var phoneFont = 7
    var desg_line_xpos = 0
    var signatureLinePos = 0
    var rightHeaderWidth = 29;
    var testernameWidth = 217;
    var testermarginLength = 137
    var signimgYpos = 760
    var signimgXpos = 60
    var testermarginpos = 7;
    var desgTextpos = 453
    let sign = 41
    let tester = 54
    let desg = 80
    var signimg: any = {}
    if (footer) {
      footerval = this.facilityHeader.testSettings.reportSettings.firstPage.footerSpace //setting footerspace
      if (isQc) {
        signimgYpos -= footerval

      }
    } else {
      signimgYpos = 760
    }
    if (this.languageType == 'fr') {
      rightHeaderWidth = 60
      testernameWidth = 235;
      testermarginLength = 117;
      testermarginpos = 23;
      desg_line_length = 136
      designationxpos = 19;
      phoneFont = 8
      sign = 35
      tester = 68
      desg = 50
    } else if (this.languageType == 'de') {
      rightHeaderWidth = 45;
      testernameWidth = 229;
      testermarginLength = 117;
      testermarginpos = 18;
      phoneFont = 8
      tester_name_xpos = 160
      designationxpos = 25
      sign = 33
      tester = 66
      desg = 65
    } else if (this.languageType == 'es') {
      rightHeaderWidth = 93;
      margin += 40
      tester_name_xpos = 140
      signatureLinePos = -15
      designationxpos = -5
      signimgXpos = 45
      testernameWidth = 187
      testermarginpos = -25
      sign = 28
      tester = 45
      desg = 70
    } else if (this.languageType == 'it') {
      rightHeaderWidth = 70;
      testernameWidth = 205;
      testermarginLength = 117;
      testermarginpos = -5;
      desg_line_length = 136
      designationxpos = 12;
      signimgXpos = 47
      signatureLinePos = -13
      phoneFont = 8
      tester_name_xpos = 143
      sign = 28
      tester = 62
      desg = 55
    } else if (this.languageType == 'ja') {
      signatureLinePos = -15
      testernameWidth = 190
      testermarginpos = -21
      designationxpos = 15;
      tester_name_xpos = 152
      signimgXpos = 45
      rightHeaderWidth = 43
      sign = 23
      tester = 36
      desg = 50
    } else if (this.languageType == 'vi') {
      rightHeaderWidth = 55
      tester_name_xpos = 150
      signatureLinePos = -10
      designationxpos = 39
      signimgXpos = 51
      testernameWidth = 220
      testermarginpos = 10
      desg_line_xpos = 10
      desgTextpos = 463
      sign = 35
      tester = 68
      desg = 50
    } else if (this.languageType == 'pt') {
      rightHeaderWidth = 50;
      tester_name_xpos = 160
      testernameWidth = 227
      testermarginpos = 15
      sign = 41
      tester = 64
      desg = 70
    } else if (this.languageType == 'el') {
      rightHeaderWidth = 70
      tester_name_xpos = 152
      designationxpos = -17
      testermarginpos = 0
      testernameWidth = 212
      sign = 41
      tester = 54
      desg = 85
    } else if (this.languageType == 'tr') {
      rightHeaderWidth = 70
      designationxpos = 0
      testermarginpos = 5
      testernameWidth = 215
      signatureLinePos = -8
      signimgXpos = 47
      tester_name_xpos = 152;
      desg = 70
      sign = 21
      tester = 60
    }
    //since font size is increased the positions should be changed
    testernameWidth += 5
    desgTextpos += 3
    signimgXpos += 5
    rightHeaderWidth += 10
    // signature URL logic

    const returnSignURL = async (URL) => {
      if (URL != "") {
        return {
          image: await this.getBase64ImageFromURL(URL),
          width: 80,
          height: 25,
          // absolutePosition: { x: signimgXpos, y: signimgYpos }
        }
      } else {
        return { text: "" }
      }
    }

    if (this.Fresh_Test_Data.signatureURL) {
      // if URL exists in tests
      if (await this.urlExists(this.Fresh_Test_Data.signatureURL)) {
        signimg = await returnSignURL(this.Fresh_Test_Data.signatureURL)
      } else {
        //if not take facility url
        if (await this.urlExists(this.facilityHeader.signatureURL)) {
          signimg = await returnSignURL(this.facilityHeader.signatureURL)
        } else {
          signimg = { text: "" }
        }
      }
    } else {
      if (await this.urlExists(this.facilityHeader.signatureURL)) {
        signimg = await returnSignURL(this.facilityHeader.signatureURL)
      } else {
        signimg = { text: "" }
      }
    }


    const signImgURL = this.Fresh_Test_Data.signatureURL ?? this.facilityHeader.signatureURL
    console.log("::::::::::::::::::::::::::::::::", signImgURL);

    // if (await this.urlExists(signImgURL) && signImgURL != "") {
    //   var signimg = signImgURL
    //   var signImg = await this.getBase64ImageFromURL(signimg)
    //   signimg = {
    //     image: signImg,
    //     width: 80,
    //     height: 25,
    //     // absolutePosition: { x: signimgXpos, y: signimgYpos }
    //   }
    // } else {
    //   signimg = { text: "" }

    // }

    let y = 782 + 5
    let autoY = -15
    if (!isQc) {
      y = -3
      signimgYpos = -32
    } else {
      y = 782 - footerval + 5
    }
    // if (signImgURL && signImgURL != "") {
    //   signimg = {
    //     image: signImg,
    //     width: 80,
    //     height: 25,
    //     // absolutePosition: { x: signimgXpos, y: signimgYpos }
    //   }
    // } else {
    //   signimg = { text: "" }
    // }

    var canvasLines: any = [
      {
        canvas: [
          {
            type: 'rect',
            x: 423 + desg_line_xpos,
            y: -1,
            w: 123,
            h: 0,
            r: 0,
            // solid: { length: 1 },
            lineWidth: 0.1,
            lineColor: 'black',

          },
        ]
      },
      {
        canvas: [
          {
            type: 'rect',
            x: testermarginpos + 181,
            y: -1,
            w: 145,
            h: 0,
            r: 0,
            // solid: { length: 5 },
            lineWidth: 0.1,
            lineColor: 'black',
          },

        ]
      },
      {
        canvas: [
          {
            type: 'rect',
            x: 25,
            y: -3,
            w: 80,
            h: 0,
            r: 0,
            lineWidth: 0.1,
            lineColor: 'black',
          },
        ]
      }
    ]
    const firstCellWidth = (command: string) => {
      switch (command) {
        case 'sign':
          return sign
          break;
        case 'tester':
          return tester
          break;
        case 'desg':
          return desg
          break;

        default:
          break;
      }
    }
    // if (isQc) {
    //   var signaturefield: any = [{ text: this.translate.instant('test.sign') + ':', border: [false, false, false, false], style: 'anotherStyle', font: 'japneseFont', absolutePosition: { x: 22, y: 770 - footerval + 5 } }, { text: ' ', border: [false, false, false, false] }]
    //   var testerField: any = [{ text: this.translate.instant('test.tester_name') + ':', border: [false, false, false, false], font: 'japneseFont', style: 'anotherStyle', absolutePosition: { x: tester_name_xpos, y: 770 - footerval + 5 } }, { text: this.returnFirstnamNLastname(this.facilityHeader.doctorName, " "), font: 'japneseFont', border: [false, false, false, false], style: 'anotherStyle', absolutePosition: { x: testernameWidth, y: 770 - footerval + 5 } }]
    //   var designationField: any = [{ text: this.translate.instant('general.tester_desg') + ':', border: [false, false, false, false], font: 'japneseFont', style: 'anotherStyle', absolutePosition: { x: 380 + designationxpos, y: 770 - footerval + 5 } }, { text: this.returnFirstnamNLastname(this.facilityHeader.designation, " "), font: 'japneseFont', border: [false, false, false, false], style: 'anotherStyle', absolutePosition: { x: desgTextpos, y: 770 - footerval + 5 } }]

    // } else {
    var signaturefield: any = [{ text: this.translate.instant('test.sign') + ':', border: [false, false, false, false], style: "footerTable", font: 'japneseFont', margin: [0, 13, 0, 0] }, { ...signimg, border: [false, false, false, true] }]
    var testerField: any = [{ text: this.translate.instant('test.tester_name') + ':', border: [false, false, false, false], style: 'footerTable', font: 'japneseFont', margin: [0, 2, 0, 0] }, { text: this.returnFirstnamNLastname(isQc ? this.facilityHeader.doctorName : this.Fresh_Test_Data.tester, ""), font: 'japneseFont', style: 'anotherStyle', fontSize: 8.5, border: [false, false, false, true], margin: [0, 3, 0, 0] }]
    var designationField: any = [{ text: this.translate.instant('general.tester_desg') + ':', border: [false, false, false, false], style: 'footerTable', font: 'japneseFont', margin: [0, 2, 0, 0] }, { text: this.returnFirstnamNLastname(isQc ? this.facilityHeader.designation : this.Fresh_Test_Data.designation, ""), font: 'japneseFont', style: 'anotherStyle', fontSize: 8.5, border: [false, false, false, true], margin: [0, 3, 0, 0] }]
    // }
    // if (this.facilityHeader.testSettings.signatureLine) {
    //   signaturefield = [{ text: '', border: [false, false, false, false], style: 'anotherStyle', absolutePosition: { x: 22, y: 770 - footerval + 5 } }, { text: ' ', border: [false, false, false, false] }]
    //   testerField = [{ text: '', border: [false, false, false, false], style: 'anotherStyle', absolutePosition: { x: 202, y: 770 - footerval + 5 } }, { text: '', border: [false, false, false, false], style: 'anotherStyle', absolutePosition: { x: testernameWidth, y: 770 - footerval + 5 } }]
    //   designationField = [{ text: '', border: [false, false, false, false], style: 'anotherStyle', absolutePosition: { x: 390, y: 770 - footerval + 5 } }, { text: '', border: [false, false, false, false], style: 'anotherStyle', absolutePosition: { x: 461, y: 770 - footerval + 5 } }]
    //   canvasLines = []
    // }
    return {
      Lines: canvasLines,
      signature: signaturefield,
      tester: testerField,
      desg: designationField,
      signImage: signimg,
      margin: margin,
      topright: rightHeaderWidth,
      firstCellWidth: firstCellWidth
    }
  }
  returncorrectiveaction(text) {

    if (text != undefined) {
      if (typeof text == "number") {
        if (text == 0) {
          return this.checkandTranslate(this.translate.instant('qc-controls.system_clean_action'))
        } else if (text == 1) {
          return this.checkandTranslate(this.translate.instant('qc-controls.material_expired_action'))
        } else if (text == 2) {
          return this.checkandTranslate(this.translate.instant('qc-controls.sample_handling_action'))
        } else if (text == 3) {
          return this.checkandTranslate(this.translate.instant('qc-controls.improperly_stored_action'))
        } else if (text == 4) {
          return this.checkandTranslate(this.translate.instant('qc-controls.wrong_level_action'))
        } else if (text == 5) {
          return this.checkandTranslate(this.translate.instant('qc-controls.assayed_control_action'))
        }
      } else {
        return this.checkandTranslate(text)
      }

    } else {
      return " "
    }
  }
  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  async qcParamstable(data, isFromArchive?: boolean) {
    var downArrow = await this.getBase64ImageFromURL('assets/images/test/downArrow.png')
    var upArrow = await this.getBase64ImageFromURL('assets/images/test/upArrow.png')
    var pass = await this.getBase64ImageFromURL('assets/images/test/pass.png')
    var fail = await this.getBase64ImageFromURL('assets/images/test/Fail.png')
    this.paramArray = []
    let isFirstPage: boolean = true
    var correctiveAction: string
    var level3_unit = " "
    var level = " "
    var batch = data
    var target
    var range
    var results
    var dateTime
    this.level_3_count = 0
    batch.sort(function (a, b) {
      // convert date object into number to resolve issue in typescript
      return Number(a.dateTime) - Number(b.dateTime);
    });
    console.log("SOOOO", this.translate.currentLang);
    this.paramArray.push([{ text: this.checkandTranslate(this.translate.instant('qc-controls.date/time')), fillColor: '#B0E0E6', style: 'paraheader', alignment: 'center', bold: 'Helvetica-Bold' }, { text: this.checkandTranslate(this.translate.instant('qc-controls.level')), fillColor: '#B0E0E6', style: 'paraheader' }, { text: this.checkandTranslate(this.translate.instant('qc-controls.lot')), fillColor: '#B0E0E6', style: 'paraheader' }, { text: this.checkandTranslate(this.translate.instant('qc-controls.exp_date')), fillColor: '#B0E0E6', style: 'paraheader' }, { text: this.checkandTranslate(this.translate.instant('qc-controls.target')) + "\n" + this.translate.instant('units.M_ml'), fillColor: '#B0E0E6', style: 'paraheader' }, { text: this.checkandTranslate(this.translate.instant('qc-controls.pass_range')), fillColor: '#B0E0E6', style: 'paraheader' }, { text: this.checkandTranslate(this.translate.instant('test.results')) + "\n" + this.translate.instant('units.M_ml'), fillColor: '#B0E0E6', style: 'paraheader' }, { text: this.checkandTranslate(this.translate.instant('test.status')), fillColor: '#B0E0E6', style: 'paraheader' }, { text: this.checkandTranslate(this.translate.instant('qc-controls.corrective_action')), fillColor: '#B0E0E6', style: 'paraheader' }])
    batch.forEach(async (element, index) => {
      // console.log("type of", typeof (element.dateTime));

      if (typeof (element.dateTime) == 'string') {
        dateTime = element.dateTime
      } else {
        try {
          dateTime = element.dateTime.toDate()
        } catch (error) {
          // console.log("ERROR", error, dateTime);

          dateTime = element.dateTime
        }
      }

      if (element.isNegativeControl || element.lev == "NEG. CONTROL") {
        this.level_3_count++;
        level = this.checkandTranslate(this.translate.instant('qc-controls.Neg_control'))
        target = "0.0 " + this.checkandTranslate(this.translate.instant('units.conc_msc'))
        range = "0.0-0.0 " + this.checkandTranslate(this.translate.instant('units.conc_msc'))

        if ((element.conc != undefined && (element.msc != undefined && element.msc != -1))) {
          results = Number(element.conc).toFixed(1) + ' / ' + Number(element.msc).toFixed(1)
        } else if (element.conc == undefined) {
          // console.log("PPPPPPLLLLLLLLLLLLL", element.conc, element.msc);
          results = 'N/A' + ' / ' + Number(element.msc).toFixed(1)
        } else if (element.msc == undefined || element.msc == -1) {
          results = Number(element.conc).toFixed(1) + ' / ' + 'N/A'
        }
      } else {
        level = element.level
        target = element.target
        range = (Number(element.target) - Number(element.value)).toFixed(1).toString() + " - " + (Number(element.target) + Number(element.value)).toFixed(1).toString()
        results = Number(element.conc).toFixed(1)
      }
      var seperation = ' | '
      // console.log("this.pcTimeFormat", this.pcTimeFormat.length, this.pcDateFormat.length);

      if (this.pcDateFormat.length > 12 || this.pcTimeFormat.length > 5) {
        seperation = '\n|'
      } else {
        seperation = ' | '
      }
      var isExpired: boolean = false;
      // var lev_expiry = new Date(element.expirationDate)
      // if (new Date() > lev_expiry) {
      //   isExpired = true;
      // } else {
      //   isExpired = false;
      // }
      if (isExpired) {
        if (element.status == true || element.status == this.translate.instant('general.pass')) {
          this.paramArray.push([{ text: this.customPipe.transform(dateTime, this.pcDateFormat + seperation + this.pcTimeFormat, this.translate.currentLang), style: 'paradata', alignment: 'center' }, { text: level, style: 'paradata', }, { text: element.lotID, style: 'paradata', }, { text: this.customPipe.transform(element.expirationDate, 'MMM / yyyy', this.translate.currentLang), style: 'paradata', color: "red" }, { text: target, style: 'paradata', }, { text: range, style: 'paradata', }, { text: results, style: 'paradata', }, { image: pass, fit: [20, 20], style: 'paradata', margin: [0, 1, 0, 0] }, { text: this.returncorrectiveaction(element.correctiveAction), style: 'paradata', font: 'japneseFont' },])
        } else {
          this.paramArray.push([{ text: this.customPipe.transform(dateTime, this.pcDateFormat + seperation + this.pcTimeFormat, this.translate.currentLang), style: 'paradata', alignment: 'center' }, { text: level, style: 'paradata', }, { text: element.lotID, style: 'paradata', }, { text: this.customPipe.transform(element.expirationDate, 'MMM / yyyy', this.translate.currentLang), style: 'paradata', color: "red" }, { text: target, style: 'paradata', }, { text: range, style: 'paradata', }, { text: results, style: 'paradata', }, { image: fail, fit: [10, 10], style: 'paradata', margin: [0, 1, 0, 0] }, { text: this.returncorrectiveaction(element.correctiveAction), style: 'paradata', font: 'japneseFont' },])
        }
      } else {
        if (element.status == true || element.status == this.translate.instant('general.pass')) {
          this.paramArray.push([{ text: this.customPipe.transform(dateTime, this.pcDateFormat + seperation + this.pcTimeFormat, this.translate.currentLang), style: 'paradata', alignment: 'center' }, { text: level, style: 'paradata', }, { text: element.lotID, style: 'paradata', }, { text: this.customPipe.transform(element.expirationDate, 'MMM / yyyy', this.translate.currentLang), style: 'paradata', }, { text: target, style: 'paradata', }, { text: range, style: 'paradata', }, { text: results, style: 'paradata', }, { image: pass, fit: [20, 20], style: 'paradata', margin: [0, 1, 0, 0] }, { text: this.returncorrectiveaction(element.correctiveAction), style: 'paradata', font: 'japneseFont' },])
        } else {
          this.paramArray.push([{ text: this.customPipe.transform(dateTime, this.pcDateFormat + seperation + this.pcTimeFormat, this.translate.currentLang), style: 'paradata', alignment: 'center' }, { text: level, style: 'paradata', }, { text: element.lotID, style: 'paradata', }, { text: this.customPipe.transform(element.expirationDate, 'MMM / yyyy', this.translate.currentLang), style: 'paradata', }, { text: target, style: 'paradata', }, { text: range, style: 'paradata', }, { text: results, style: 'paradata', }, { image: fail, fit: [10, 10], style: 'paradata', margin: [0, 1, 0, 0] }, { text: this.returncorrectiveaction(element.correctiveAction), style: 'paradata', font: 'japneseFont' },])
        }
      }
    });

    this.paramArray.forEach((element, index) => {
      if (index != 0) {
        if (isFirstPage == true && index == 15) {
          isFirstPage = false
          this.paramArray[index][0].pageBreak = 'before'
          console.log(index, this.paramArray.length, "zzzzzzzzzzzzzzzzzzzzzz");
        } else if ((index - 15) % 20 == 0 && isFirstPage == false) {
          this.paramArray[index][0].pageBreak = 'before'
          console.log(index, this.paramArray.length, "zzzzzzzzzzzzzzzzzzzzzz");

        }
      }
    });
    console.log(this.paramArray);



  }

  async QCreport(batch: any, lastRun, fromArchive?: boolean) {
    // this.facilityHeader.testSettings = defaultTestSettings
    await this.qcParamstable(batch, fromArchive)
    // console.log("OKOOOKOOKOo", batch, lastRun);
    var widths = [80, 45, 45, 45, 42, 45, 63, 35, 80]
    var pctimeFormat = this.pcTimeFormat + " ";
    var now = this.customPipe.transform(new Date(), this.pcDateFormat + ' ' + pctimeFormat.split(" ")[0] + ':ss ' + pctimeFormat.split(" ")[1], this.translate.currentLang);
    var margin = 10;
    var rightHeaderWidth = 32;
    var imageObj: any;
    var switchFont = 'Roboto';
    var headerArray: any = await this.reportHeader(true)
    var rptFooter = await this.returnFooter(true)
    var footerData: any = await this.returnFooter(false)
    var footerval: boolean = this.facilityHeader.testSettings.reportSettings.firstPage.reportFooter;
    var footermargin = 10;
    imageObj = headerArray.leftHeader
    // console.log("batch", batch);
    var address = ''
    var footerText
    if (!footerval || true) {
      footerText = this.translate.instant('qc-controls.printed_from') + ' SN: ' + batch[0].calRawData.serialNumber + ' | ' + now;
    } else {
      footerText = ""
    }
    // if (this.facilityHeader.streetNo) {
    //   // console.log("this.facilityHeader.state", this.facilityHeader);
    //   if ((this.facilityHeader.country?.toLowerCase().includes("united states") && this.facilityHeader.state != undefined && this.facilityHeader.state != "") || this.translate.currentLang == 'ja') {
    //     if (this.translate.currentLang == 'ja') {
    //       if (this.facilityHeader.state != undefined && this.facilityHeader.state != '') {
    //         address += this.facilityHeader.zipCode + this.facilityHeader.state + this.facilityHeader.city
    //       } else {
    //         address += this.facilityHeader.zipCode + this.facilityHeader.city
    //       }
    //       if (this.facilityHeader.streetNo.split(";")[1] != undefined && this.facilityHeader.streetNo.split(";")[1] != "null") {
    //         address += this.facilityHeader.streetNo.split(";")[0] + this.facilityHeader.streetNo.split(";")[1]
    //       } else {
    //         address += this.facilityHeader.streetNo.split(";")[0]
    //       }
    //     } else {
    //       if (this.facilityHeader.streetNo.split(";")[1] != undefined && this.facilityHeader.streetNo.split(";")[1] != "null") {
    //         address = this.facilityHeader.streetNo.split(";")[0] + ' ' + this.facilityHeader.streetNo.split(";")[1]
    //       } else {
    //         address = this.facilityHeader.streetNo.split(";")[0]
    //       }
    //       if (this.facilityHeader.state != undefined && this.facilityHeader.state != '') {
    //         address += ', ' + this.facilityHeader.city + ' ' + this.facilityHeader.zipCode + ', ' + this.facilityHeader.state
    //       } else {
    //         address += ', ' + this.facilityHeader.city + ' ' + this.facilityHeader.zipCode
    //       }
    //     }

    //   } else {
    //     if (this.facilityHeader.streetNo.split(";")[1] != undefined && this.facilityHeader.streetNo.split(";")[1] != "null") {
    //       address = this.facilityHeader.streetNo.split(";")[0] + ' ' + this.facilityHeader.streetNo.split(";")[1]
    //     } else {
    //       address = this.facilityHeader.streetNo.split(";")[0]
    //     }
    //     if (this.facilityHeader.state != undefined && this.facilityHeader.state != '') {
    //       address += ', ' + this.facilityHeader.city + ' ' + this.facilityHeader.zipCode + ', ' + this.facilityHeader.state + ', ' + this.facilityHeader.country
    //     } else {
    //       address += ', ' + this.facilityHeader.city + ' ' + this.facilityHeader.zipCode + ', ' + this.facilityHeader.country

    //     }
    //   }
    // }
    if (this.facilityHeader.streetNo) {
      if ((this.facilityHeader.country.toLowerCase().includes("united states") && this.facilityHeader.state != undefined && this.facilityHeader.state != "") || this.translate.currentLang == 'ja') {
        if (this.translate.currentLang == 'ja') {
          if (this.facilityHeader.state != undefined && this.facilityHeader.state != '') {
            address += this.facilityHeader.zipCode + this.facilityHeader.state + this.facilityHeader.city
          } else {
            address += this.facilityHeader.zipCode + this.facilityHeader.city
          }
          if (this.facilityHeader.streetNo.split(";")[1] != undefined && this.facilityHeader.streetNo.split(";")[1] != "null") {
            address += this.facilityHeader.streetNo.split(";")[0] + this.facilityHeader.streetNo.split(";")[1]
          } else {
            address += this.facilityHeader.streetNo.split(";")[0]
          }
        } else {
          console.log("////////////////////////////////////////////?????????????????????");

          if (this.facilityHeader.streetNo.split(";")[1] != undefined && this.facilityHeader.streetNo.split(";")[1] != "null") {
            address = this.facilityHeader.streetNo.split(";")[0] + ' ' + this.facilityHeader.streetNo.split(";")[1]
          } else {
            address = this.facilityHeader.streetNo.split(";")[0]
          }
          if (this.facilityHeader.state != undefined && this.facilityHeader.state != '') {
            address += ', ' + this.facilityHeader.city + '\n' + this.facilityHeader.state + ' ' + this.facilityHeader.zipCode + ', ' + this.facilityHeader.country
          } else {
            address += ', ' + this.facilityHeader.city + '\n ' + this.facilityHeader.zipCode + ', ' + this.facilityHeader.country
          }
        }

      } else {
        console.log("////////////////////////////////////////////?????????????????????");

        if (this.facilityHeader.streetNo.split(";")[1] != undefined && this.facilityHeader.streetNo.split(";")[1] != "null") {
          address = this.facilityHeader.streetNo.split(";")[0] + ' ' + this.facilityHeader.streetNo.split(";")[1]
        } else {
          address = this.facilityHeader.streetNo.split(";")[0]
        }
        if (this.facilityHeader.state != undefined && this.facilityHeader.state != '') {
          address += ', ' + this.facilityHeader.city + '\n' + this.facilityHeader.state + ' ' + this.facilityHeader.zipCode + ', ' + this.facilityHeader.country
        } else {
          address += ', ' + this.facilityHeader.city + '\n ' + this.facilityHeader.zipCode + ', ' + this.facilityHeader.country
        }
      }
    }

    var facilityName = this.facilityHeader.facilityName
    if (this.facilityHeader.testSettings.reportSettings.firstPage.reportHeader == true) {
      address = " "
      facilityName = ""
    }

    var margin = 20;

    if (this.facilityHeader.website) {
      if (this.facilityHeader.email.length >= this.facilityHeader.website.length) {
        if (this.facilityHeader.email.length >= 30) {
          if (this.facilityHeader.email.length >= 40) {
            margin = this.facilityHeader.email.length * 2 + 25
          } else {
            margin = this.facilityHeader.email.length * 2 + 20
          }
        } else {
          if (this.facilityHeader.email.length > 15) {
            margin = this.facilityHeader.email.length + 15
          }
        }
      }
      else {
        if (this.facilityHeader.website.length >= 30) {
          if (this.facilityHeader.website.length >= 40) {
            margin = this.facilityHeader.website.length * 2
            // console.log(margin, "expected");

          } else {
            margin = this.facilityHeader.website.length * 2 + 20

          }
        } else {
          if (this.facilityHeader.website.length > 15) {
            margin = this.facilityHeader.website.length + 15
            // console.log(margin, "Margin");

          }
        }
      }
    } else {
      if (this.facilityHeader.email.length >= 30) {
        if (this.facilityHeader.email.length >= 40) {
          margin = this.facilityHeader.email.length * 2 + 25

        } else {
          margin = this.facilityHeader.email.length * 2 + 20

        }

      } else {

        if (this.facilityHeader.email.length > 15) {
          margin = this.facilityHeader.email.length + 15
        }
      }
    }


    // console.log("Facility Header", this.facilityHeader, dataArray);
    if (this.languageType == 'es') {
      margin += 45
    }

    // pdfFonts.pdfMake.vfs['Arial_unicode'] = arial_unicodeURL
    // pdfFonts.pdfMake.vfs['Arial_unicode_Bold'] = arial_unicodeBoldURL
    // pdfMake.fonts = {
    //   Roboto: {
    //     normal: 'Roboto-Regular.ttf',
    //     bold: 'Roboto-Medium.ttf',
    //     italics: 'Roboto-Italic.ttf',
    //     bolditalics: 'Roboto-MediumItalic.ttf'
    //   },
    //   japneseFont: {
    //     normal: 'Arial_unicode',
    //     bold: 'Arial_unicode_Bold',
    //     italics: 'Arial_unicode',
    //     bolditalics: 'Arial_unicode'
    //   },
    // }
    var headerSpace
    var right_tab = 0

    var headermargin = 0
    // if (this.facilityHeader.testSettings.reportSettings.firstPage.reportHeader) {
    //   if (this.facilityHeader.testSettings.reportSettings.firstPage.headerSpace >= 40) {
    //     headermargin = 2.5 * this.facilityHeader.testSettings.reportSettings.firstPage.headerSpace
    //   } else if (this.facilityHeader.testSettings.reportSettings.firstPage.headerSpace >= 30) {
    //     headermargin = 2.6 * this.facilityHeader.testSettings.reportSettings.firstPage.headerSpace
    //   } else {
    //     headermargin = 2.92 * this.facilityHeader.testSettings.reportSettings.firstPage.headerSpace
    //   }
    // } else {
    //   headermargin = 0
    // }

    //making always enter inorder to not affect the report header
    // if (headermargin == 0 || true) {
    if (this.languageType == 'ja') {
      headermargin = 45
    } else {
      headermargin = 30
    }
    // }
    if (this.languageType == 'ja') {
      switchFont = 'japneseFont';
      headerSpace = headerArray.HeaderSpace
      right_tab = -70
      headermargin -= 10
    } else {
      switchFont = 'Roboto';
      headerSpace = headerArray.HeaderSpace
      right_tab = -65
    }
    if (this.facilityHeader.testSettings.reportSettings.firstPage.footerSpace != undefined && this.facilityHeader.testSettings.reportSettings.firstPage.reportFooter) {
      footermargin = 3 * (this.facilityHeader.testSettings.reportSettings.firstPage.footerSpace) + 10
    } else {
      footermargin = 10
    }
    if (this.languageType == 'vi') {
      widths = [80, 45, 45, 45, 50, 55, 50, 30, 80]
    } else if (this.languageType == 'de') {
      widths = [80, 45, 45, 45, 42, 55, 48, 35, 80]
    } else if (this.languageType == 'it') {
      widths = [80, 45, 45, 45, 42, 60, 43, 35, 80]
    } else if (this.languageType == 'ja') {
      widths = [80, 45, 45, 45, 53, 53, 39, 40, 80]
    }
    var breakPG: string = ' '
    var ChartHeading: any
    // console.log("RRRRRRRRRRRRR", footermargin, headermargin, this.facilityHeader.testSettings);
    if ((this.facilityHeader.testSettings.reportSettings.firstPage.headerSpace > 20 && this.facilityHeader.testSettings.reportSettings.firstPage.reportHeader && this.paramArray.length > 4) || this.level_3_count > 2 || (this.paramArray.length >= 4 && this.level_3_count > 2) || this.paramArray.length > 5) {
      // console.log("Entered Break", this.paramArray, this.paramArray.length % 11);

      ChartHeading = { text: this.checkandTranslate(this.translate.instant('qc-controls.qc_graph')), style: "chartHeading", ...(this.paramArray.length < 20 || !this.facilityHeader.testSettings.reportSettings.firstPage.reportFooter) && { pageBreak: 'before' }, margin: [0, 20, 0, 10] }
    } else {
      ChartHeading = { text: this.checkandTranslate(this.translate.instant('qc-controls.qc_graph')), style: "chartHeading", margin: [0, 10, 0, 10] }
    }
    const reportSettings = this.facilityHeader.testSettings.reportSettings

    var docDefinition = {
      header: (currentPage, pageCount, pageSize) => {
        this.PageCount = pageCount;
        var pageNumber: any = [{ text: ` `, style: "headerText", },];
        // added true to make settings not applicable
        if (reportSettings.firstPage.displayPageNumber || true)
          pageNumber = [{ text: `${this.translate.instant("test.page")}  ${currentPage}  ${this.translate.instant("test.of")}  ${pageCount} `, style: "headerText", },]

        return pageNumber
        // }
      },
      footer: (currentPage, pageCount, pageSize) => {
        this.PageCount = pageCount;
        let currentPageSettings: any;
        const colWidth = ((210 * 2.835) - 27) / 3
        currentPageSettings = reportSettings.firstPage
        let pgMargin = -5;
        var footer: any = [{ text: ``, style: "footerstyle", margin: [0, 0, 0, -15] }];
        // if (currentPage == pageCount) {
        if (!currentPageSettings.reportFooter || true) {
          footer = [{ text: footerText, style: "footerstyle", margin: [0, 5, 0, -15] }];
          pgMargin = -55
          // if (currentPage !=1 && reportSettings.firstPage.reportFooter==true) {
          //   pgMargin = footerval-50
          //   // footer[0].margin = [0,pgMargin,0,-15]
          // }
        } else {

          // if (currentPage !=1 ) {
          pgMargin = -Number(currentPageSettings.footerSpace * 2.046456692913)
          // }else {
          //   pgMargin = -Number(currentPageSettings.footerSpace * 2.8346456692913)
          // } 
        }

        const footerObj = {
          margin: [12, pgMargin - 20, 10, 10],
          columns: [
            {
              width: colWidth,
              margin: [0, -11, 0, 0],
              table: {
                widths: [(footerData).firstCellWidth('sign'), '*'],
                body: [
                  (footerData).signature,
                ],
                layout: {
                  defaultBorder: true,
                }
              }
            },
            {
              // style: 'sign',
              width: colWidth,
              table: {
                // this.translate.currentLang != 'en' ? 75 : 54
                widths: [(footerData).firstCellWidth('tester'), '*'],
                body: [
                  (rptFooter).tester
                ]
              },
              layout: {
                defaultBorder: true,
              }
            },
            {
              // style: 'sign',
              width: colWidth,
              table: {
                widths: [(footerData).firstCellWidth('desg'), '*'],
                body: [
                  (rptFooter).desg
                ]
              },
              layout: {
                defaultBorder: true,
              }

            },

          ].filter(el => el)
        }
        // if (!currentPageSettings.removeSignature || !currentPageSettings.removeTester)
        footer.unshift(footerObj)
        // if (currentPageSettings.removeSignature && currentPageSettings.removeTester)
        //   footer[0].margin = [0, -10, 0, -15]


        console.log(footer);

        return footer
      },
      info: {
        title: this.checkandTranslate(this.translate.instant('qc-controls.qc_report')),
        author: 'MES',
      },
      pageMargins: [17, headermargin, 10, 0],
      content: [
        {
          columns: [
            {
              width: 430 - margin,
              table: {
                widths: [165],
                headerRows: 1,
                body: [
                  [imageObj],
                  [{ text: this.facilityHeader.facilityName, style: 'facilityHeader', }],
                  [{ text: this.getAddress(), style: 'anotherStyle', margin: [0, 0, 0, 0] }]
                ]
              },
              layout: {
                defaultBorder: false,
              }
            },
            {
              width: '*',
              margin: [0, 10, 0, 10],
              table: {
                widths: [(await footerData).topright, '*'],
                headerRows: 1,
                body: (await headerArray).rightHeader
              },
              layout: {
                defaultBorder: false,
              }
            },
          ],
        },

        // imageObj,
        // {
        //   style: 'address',
        //   table: {
        //     widths: [130],
        //     // heights:10,
        //     headerRows: 1,
        //     body: [
        //       [{ text: facilityName, style: 'facilityHeader', margin: [-5, 0, 0, 0], font: 'japneseFont' }],
        //       [{ text: address, style: 'anotherStyle', margin: [-5, 0, 0, 0], font: 'japneseFont' }]
        //     ]
        //   },
        //   layout: {
        //     defaultBorder: false,
        //   }
        // },

        // {
        //   style: 'FacilityHeader',
        //   margin: [400 - (margin), -80, 0, 0],
        //   table: {
        //     widths: [(await rptFooter).topright, 'auto'],
        //     headerRows: 1,
        //     body: (await headerArray).rightHeader
        //   },
        //   layout: {
        //     defaultBorder: false,
        //   }
        // },
        { text: this.checkandTranslate(this.translate.instant('qc-controls.qc_report')) + ' - ' + this.translate.instant('qc-controls.latex_beads'), style: 'header' },
        { text: this.translate.instant('test.report_subhead'), style: 'subhead' },
        {
          style: 'tableExample',
          table: {
            widths: [120, 135],
            headerRows: 1,
            body: [
              [{ text: this.checkandTranslate(this.translate.instant('qc-controls.qc_info')), colSpan: 2, border: [false, false, false, true], style: 'tableHeader' }, { text: "", border: [false, false, false, true], style: 'anotherStyle' }],
              [{ text: this.checkandTranslate(this.translate.instant('qc-controls.qc_type')) + ':', border: [false, false, false, true], style: 'anotherStyle' }, { text: this.translate.instant('qc-controls.latex_beads'), border: [false, false, false, true], style: 'anotherStyle' }],
              [{ text: this.checkandTranslate(this.translate.instant('qc-controls.run_date')) + ':', border: [false, false, false, true], style: 'anotherStyle' }, { text: this.customPipe.transform(lastRun, this.pcDateFormat, this.translate.currentLang), border: [false, false, false, true], style: 'anotherStyle' }],
              //latex_beads [{ text: this.translate.instant('test.ref_doc').toUpperCase() + ':', border: [false, false, false, true], style: 'anotherStyle' }, { text: this.Fresh_Test_Data.referringDoc, border: [false, false, false, true], style: 'anotherStyle' }],
            ]
          },
          layout: {
            defaultBorder: false,
          }
        },
        {
          style: 'tableExample2',
          table: {
            widths: [125, 125],
            // heights:10,
            headerRows: 1,
            body: [
              [{ text: ' ', border: [false, false, false, true] }, { text: ' ', border: [false, false, false, true] }],
              [{ text: this.checkandTranslate(this.translate.instant('qc-controls.report_datetime')) + ':', border: [false, false, false, true], style: 'anotherStyle' }, { text: this.customPipe.transform(new Date(), this.pcDateFormat + " | " + this.pcTimeFormat, this.translate.currentLang), border: [false, false, false, true], style: 'anotherStyle' }],
              [{ text: ' ', border: [false, false, false, true], style: 'anotherStyle' }, { text: '', border: [false, false, false, true], style: 'anotherStyle' }],
            ]
          },
          layout: {
            defaultBorder: false,
          }
        },
        {
          style: "paraTable2",
          table: {
            widths: widths,
            // headerRows: 1,
            heights: 15,
            dontBreakRows: true,
            body: this.paramArray,

          },
          layout: {
            defaultBorder: true,
          }
        },
        ChartHeading,
        { image: this.chartURI, height: 315, width: 715 * 0.7487922705314, margin: [0, 1, 0, 0] },
        // {
        //   // style: signpos,
        //   table: {
        //     widths: [36, 110],
        //     body: [
        //       // [{ text: 'Tester Name:', border: [false, false, false, false], style: 'anotherStyle' },{ text: this.Fresh_Test_Data.tester, border: [false, false, false, true], style: 'tableHeader' }],
        //       footer.signature
        //     ],
        //     margin: [0, 5, 0, 0],
        //   },
        //   layout: {
        //     defaultBorder: false,
        //   }

        // },

        // {
        //   style: 'sign',
        //   table: {
        //     widths: [48, '*'],
        //     body: [
        //       footer.tester
        //     ],

        //   },
        //   layout: {
        //     defaultBorder: false,
        //   }

        // },

        // {
        //   style: 'sign',
        //   table: {
        //     widths: ['*', 250],
        //     body: [
        //       footer.desg
        //     ]
        //   },
        //   layout: {
        //     defaultBorder: false,

        //   }
        // },

        // footer.signImage,
        // { text: footerText, style: 'footerstyle', absolutePosition: { x: 10, y: 810 } },

        // footer.Lines
      ],

      styles: {
        FacilityHeader: {
          normal: 'Helvetica',
          fontSize: 8,
          color: 'black',
        },
        sign: {
          margin: [-20, 0, 35, 0],
          alignment: 'left',
        },
        address: {
          margin: [-20, -3, 0, 0]
        },
        anotherStyle: {
          alignment: 'left',
          fontSize: 7.5,
          margin: [0, 3, 0, 0],
          normal: 'Helvetica',
        },
        paraheader: {
          alignment: 'center',
          normal: 'Helvetica',
          bold: 'Helvetica-Bold',
          fontSize: 8,
          color: 'black',
          margin: [0, 2, 0, 0]
        },
        headerText: {
          fontSize: 8.5,
          alignment: "right",
          margin: [10, 10, 14, 10],
        },
        footerTable: {
          alignment: 'left',
          fontSize: 7.5,
          normal: 'Helvetica',
        },
        footerstyle: {
          normal: 'Helvetica',
          fontSize: 8,
          color: 'grey',
          alignment: 'center'
        },
        paradata: {
          alignment: 'center',
          normal: 'Helvetica',
          fontSize: 8,
          color: 'black',
          margin: [0, 5, 0, 0]
        },

        facilityHeader: {
          normal: 'Helvetica',
          bold: 'Helvetica-Bold',
          fontSize: 9.7,
          color: 'black',
          uppercase: true
        },
        tableExample: {
          margin: [0, -10, 0, 10]
        },
        tableExample2: {
          margin: [290, right_tab, 0, 15],
          alignment: 'right'
        },
        header: {
          fontSize: 15,
          bold: 'Helvetica-Bold',
          margin: [0, 20, 0, 0],
          alignment: 'center',
          normal: 'Helvetica'
        },
        subhead: {
          fontSize: 9,
          margin: [0, 5, 0, 20],
          alignment: 'center',
          normal: 'Helvetica'
        },
        tableHeader: {
          normal: 'Helvetica',
          bold: 'Helvetica-Bold',
          fontSize: 8,
          color: 'black',
          uppercase: true
        },
        paraTable2: {
          margin: [0, 3, 0, 10]
        },
        chartHeading: {
          uppercase: true,
          fontSize: 13,
          bold: 'Helvetica-Bold',
          decoration: 'underline',
          decorationStyle: 'solid',
          decorationColor: 'black'
        }

      },
      defaultStyle: {
        font: switchFont,

      }
    }
    this.qcPdfobj = pdfMake.createPdf(docDefinition).open();
  }

  checkandTranslate(text: string) {
    return this.unTranslate.includes(this.translate.currentLang) ? text : text.toUpperCase()
  }


  returnFirstnamNLastname(firstname, lastname, isPatname?) {
    // console.log("CHECK fN LN", firstname, lastname, this.translate.currentLang);
    var string = "";
    if (firstname == undefined) {
      firstname = ""
    }
    var count = (string.match(/w/g) || []).length + (string.match(/m/g) || []).length;
    // console.log("Wcount", count);
    if (count > 23) {
      if (this.translate.currentLang == 'ja' && isPatname == true) {
        return this.capitalize_string(lastname) + " " + this.capitalize_string(firstname)
      } else {
        return this.capitalize_string(firstname) + " " + this.capitalize_string(lastname)
      }
    } else {
      if (this.translate.currentLang == 'ja' && isPatname == true) {
        return lastname + " " + firstname
      } else {
        return firstname + " " + lastname
      }
    }
  }
  capitalize_string(stringto) {

    var splitStr = stringto.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');

    // var returnFinalstring = stringto.toLowerCase()
    // return stringto.charAt(0).toUpperCase() + returnFinalstring.slice(1)
  }
  checkTranslateforText() {

    if (this.Fresh_Test_Data.sampleInformation.appearance == "Normal") {
      this.appearance = this.translate.instant('test_process.normal');
    } else if (this.Fresh_Test_Data.sampleInformation.appearance == "Abnormal") {
      this.appearance = this.translate.instant('test_process.abnormal');
    } else if (this.Fresh_Test_Data.sampleInformation.appearance == undefined) {
      this.appearance = 'N/A'
    }
    else if (this.defaults.appearance[this.translate.currentLang].filter(el => el.value == this.Fresh_Test_Data.sampleInformation.appearance).length != 0) {
      this.appearance = this.defaults.appearance[this.translate.currentLang].filter(el => el.value == this.Fresh_Test_Data.sampleInformation.appearance)[0].title
    }
    else {
      this.appearance = this.Fresh_Test_Data.sampleInformation.appearance ?? "N/A"
    }

    if (this.Fresh_Test_Data.sampleInformation.viscosity == "Normal") {
      this.viscosity = this.translate.instant('test_process.normal');
    } else if (this.Fresh_Test_Data.sampleInformation.viscosity == "Abnormal") {
      this.viscosity = this.translate.instant('test_process.abnormal');
    } else if (this.Fresh_Test_Data.sampleInformation.viscosity == "Decreased") {
      this.viscosity = this.translate.instant('test_process.decreased');
    }

    if (this.Fresh_Test_Data.sampleInformation.liquefaction == "Normal") {
      this.liquefaction = this.translate.instant('test_process.liquefaction_option_1');
    } else if (this.Fresh_Test_Data.sampleInformation.liquefaction == "Abnormal") {
      this.liquefaction = this.translate.instant('test_process.liquefaction_option_2');
    } else if (this.Fresh_Test_Data.sampleInformation.liquefaction == "Extreme") {
      this.liquefaction = this.translate.instant('test_process.liquefaction_option_3');
    }

  }
  getSampleEvalution(value) {
    if (value != undefined) {
      if (value == "pass")
        return this.translateNreturn("general.pass", true)
      else if (value == "fail")
        return this.translateNreturn("general.fail", true)
      else
        return 'N/A'
    } else {
      return 'N/A'
    }
  }
  loadsampleInfo(testDt, receivedDt, collectedDt, who, volume, ph, opt1, opt2, optional1, optional2, isDebris, debrisvalue, wbcvalue, testedSamp) {
    const getSampleType = (value) => {
      let testType: string;
      if (value == SAMPLETYPE.FRESH)
        testType = this.checkandTranslate(this.translate.instant("visualization.fresh"))
      else if (value == SAMPLETYPE.WASHED)
        testType = this.checkandTranslate(this.translate.instant("visualization.washed"))
      else if (value == SAMPLETYPE.SWIM_UP)
        testType = `${this.translate.instant("test.swimup")} / ${this.translate.instant("test.gradient")}`
      else
        return this.checkandTranslate(this.translate.instant("test_process.art_prep"))
      if (this.Fresh_Test_Data.testingMode == 2 && this.ART_PREP_Results[0] != undefined && this.ART_PREP_Results[1] != undefined)
        return testType + ' | ' + `${this.checkandTranslate(this.translate.instant("test.swimup"))} / ${this.checkandTranslate(this.translate.instant("test.gradient"))}`
      return testType.toUpperCase()
    }
    const checkForNA = (value: any) => {
      if ((typeof value != "number" && value == "") || value == undefined || typeof value == "object")
        return 'N/A'
      else
        return value
    }

    this.checkTranslateforText()
    console.log("JJJJJJJJJJJJJJJJJJJJJJJJ", (this.Fresh_Test_Data.sampleInformation.sampleType != 2 || this.Fresh_Test_Data.sampleInformation.sampleType != 6));

    this.leftData = [
      { leftText: 'test.sample_info', colSpan: 2, type: 'title', rightText: "" },
      { leftText: 'test.sample_id', type: 'data', rightText: checkForNA(this.Fresh_Test_Data.sampleInformation.sampleId) },
      this.Fresh_Test_Data.testingMode == 2 ? { leftText: 'test.test_type', type: 'data', rightText: `${getSampleType(undefined)}` } : { leftText: 'test.test_type', type: 'data', rightText: getSampleType(this.Fresh_Test_Data.sampleInformation.sampleType) },
      { leftText: 'test.colected_date', type: 'data', rightText: collectedDt ?? 'N/A' },
      { leftText: 'test.received_date', type: 'data', rightText: receivedDt ?? 'N/A' },
      { leftText: 'test.test_date', type: 'data', rightText: testDt },
      ...(this.Fresh_Test_Data.sampleInformation.sampleType != 2 && this.Fresh_Test_Data.sampleInformation.sampleType != 6) ? [{ leftText: 'test.criteria', type: 'data', rightText: who }] : [],
      { leftText: 'test.volume', unit: 'units.ml', type: 'data', rightText: checkForNA(volume) },
      ...(this.Fresh_Test_Data.sampleInformation.sampleType != 2 && this.Fresh_Test_Data.sampleInformation.sampleType != 6) ? [{ leftText: 'test.wbc', unit: 'units.M_ml', type: 'data', rightText: wbcvalue }] : [],
      ...this.Fresh_Test_Data.testingMode == 2 ? [{ leftText: 'test.step_test_type', type: 'data', rightText: getSampleType(this.Fresh_Test_Data.sampleInformation.sampleType) }] : [],
      ...isDebris && this.Fresh_Test_Data.sampleInformation.sampleType !== 2 ? [{ leftText: 'test.ph', type: 'data', rightText: checkForNA(ph), isLowerCase: true }] : []
    ]

    console.log(this.leftData, "lllllllllllllllllll");


    this.rightData = [

      { leftText: ' ', colSpan: 2, type: 'title', rightText: "" },
      ...this.Fresh_Test_Data.sampleInformation.sampleType == 2 || this.Fresh_Test_Data.sampleInformation.sampleType == 6 ? [{ leftText: 'test.wbc', unit: 'units.M_ml', type: 'data', rightText: wbcvalue }, { leftText: 'test.criteria', type: 'data', rightText: who }] : [],

      ...(isDebris ? [{ leftText: 'test.debris', type: 'data', rightText: debrisvalue ?? 'N/A' }] : [{ leftText: 'test.ph', type: 'data', rightText: checkForNA(ph), isLowerCase: true }]),
      { leftText: 'test.appearance', type: 'data', rightText: this.appearance ?? 'N/A' },
      { leftText: 'test.viscosity', type: 'data', rightText: this.viscosity ?? 'N/A' },
      { leftText: 'test.liquefaction', type: 'data', rightText: this.liquefaction ?? 'N/A' },
      { leftText: 'test.abstinence', unit: 'units.days', type: 'data', rightText: checkForNA(this.Fresh_Test_Data.sampleInformation.abstinence) },
      { leftText: 'test.sample_tested', type: 'data', rightText: testedSamp ?? 'N/A' },
      // ...this.Fresh_Test_Data.testingMode == 2 ? [{ leftText: 'motility_estimation.sample_evaluation', type: 'data', rightText: this.getSampleEvalution(this.Fresh_Test_Data.motilityEstimationData?.sampleEvaluation) }] : [],
      { leftText: optional1, type: 'data', rightText: opt1 },
      { leftText: optional2, type: 'data', rightText: opt2 },
      ...((isDebris && this.Fresh_Test_Data.sampleInformation.sampleType !== 2) || (!isDebris && (this.Fresh_Test_Data.sampleInformation.sampleType == 2 || this.Fresh_Test_Data.sampleInformation.sampleType == 6))) ? [{ leftText: ' ', colSpan: 2, type: 'data', rightText: " " }] : [],


    ].filter(el => {
      if (this.Fresh_Test_Data.sampleInformation.sampleType == 2 || this.Fresh_Test_Data.sampleInformation.sampleType == 6) {
        return !['test.abstinence', 'test.ph', 'test.appearance', 'test.liquefaction', 'test.viscosity'].includes(el.leftText)
      } else return true
    })

    // if (isDebris) {
    //   this.rightData.push([{ text: this.unTranslate.includes(this.translate.currentLang) ? this.translate.instant('test.sample_tested') + ':' : this.translate.instant('test.sample_tested').toUpperCase() + ':', border: [false, false, false, true], style: 'anotherStyle' }, { text: testedSamp, border: [false, false, false, true], style: 'anotherStyle' }],)
    //   this.rightData.push([{ text: this.unTranslate.includes(this.translate.currentLang) ? this.translate.instant('test.debris') + ':' : this.translate.instant('test.debris').toUpperCase() + ':', border: [false, false, false, true], style: 'anotherStyle' }, { text: debrisvalue ?? 'N/A', border: [false, false, false, true], style: 'anotherStyle' }],)

    // }
    // if (!isDebris) {
    //   this.rightData.push([{ text: this.unTranslate.includes(this.translate.currentLang) ? this.translate.instant('test.sample_tested') + ':' : this.translate.instant('test.sample_tested').toUpperCase() + ':', border: [false, false, false, true], style: 'anotherStyle' }, { text: testedSamp, border: [false, false, false, true], style: 'anotherStyle' }],)
    // }
    // this.rightData.push([{ text: this.unTranslate.includes(this.translate.currentLang) ? this.translate.instant('test.appearance') + ':' : this.translate.instant('test.appearance').toUpperCase() + ':', border: [false, false, false, true], style: 'anotherStyle' }, { text: this.appearance ?? 'N/A', border: [false, false, false, true], style: 'anotherStyle' }])
    // this.rightData.push([{ text: this.unTranslate.includes(this.translate.currentLang) ? this.translate.instant('test.viscosity') + ':' : this.translate.instant('test.viscosity').toUpperCase() + ':', border: [false, false, false, true], style: 'anotherStyle' }, { text: this.viscosity ?? 'N/A', border: [false, false, false, true], style: 'anotherStyle' }],)
    // this.rightData.push([{ text: this.unTranslate.includes(this.translate.currentLang) ? this.translate.instant('test.liquefaction') + ':' : this.translate.instant('test.liquefaction').toUpperCase() + ':', border: [false, false, false, true], style: 'anotherStyle' }, { text: this.liquefaction ?? 'N/A', border: [false, false, false, true], style: 'anotherStyle' }],)
    // this.rightData.push([{ text: this.unTranslate.includes(this.translate.currentLang) ? this.translate.instant('test.abstinence') + " " + this.translate.instant('units.days') + ':' : this.translate.instant('test.abstinence').toUpperCase() + " " + this.translate.instant('units.days') + ':', border: [false, false, false, true], style: 'anotherStyle' }, { text: this.Fresh_Test_Data.sampleInformation.abstinence ?? 'N/A', border: [false, false, false, true], style: 'anotherStyle' }])
    // this.rightData.push([{ text: optional1 + ':', border: [false, false, false, true], style: 'anotherStyle' }, { text: opt1 ?? 'N/A', border: [false, false, false, true], style: 'anotherStyle' }])
    // this.rightData.push([{ text: optional2 + ':', border: [false, false, false, true], style: 'anotherStyle' }, { text: opt2 ?? 'N/A', border: [false, false, false, true], style: 'anotherStyle' }])

  }

  async urlExists(url) {
    if (!url)
      return false
    try {
      const res = await this.http.verifySignatureURL(url)
      console.log("result", res);

      return true
    } catch (error) {
      console.log("error", error);

      return false
    }
  }
  getBase64ImageFromURL(url, isChart?: boolean): Promise<any> | string {

    var whiteURI = 'assets/images/SQA-iO-Logo.png';
    if (isChart) {
      if (url !== null && url !== undefined && url !== '') {
        return new Promise((resolve, reject) => {
          var img = new Image();
          img.setAttribute("crossOrigin", "anonymous");

          img.onload = () => {
            var canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;

            var ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, -20);

            var dataURL = canvas.toDataURL("image/png");

            resolve(dataURL);
          };

          img.onerror = error => {
            reject(error);
          };

          img.src = url;
        });
      } else {
        return this.getBase64ImageFromURL(whiteURI);
      }
    } else {
      if (url !== null && url !== undefined && url !== '') {
        return new Promise((resolve, reject) => {
          var img = new Image();
          img.setAttribute("crossOrigin", "anonymous");

          img.onload = () => {
            var canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;

            var ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);

            var dataURL = canvas.toDataURL("image/png");

            resolve(dataURL);
          };

          img.onerror = error => {
            console.log(error);

            reject(error);
          };

          img.src = url;
        });
      } else {
        // var canvas:HTMLCanvasElement = document.createElement("canvas1");
        // canvas.width = 150;
        //     canvas.height = 100;
        //     var ctx = canvas.getContext("2d");
        return this.getBase64ImageFromURL(whiteURI);
      }
    }
  }

  getBase64ImageFromURLRotated(url: string): Promise<any> | string {
    if (url !== null && url !== undefined && url !== '') {
      return new Promise((resolve, reject) => {
        var img = new Image();
        img.setAttribute("crossOrigin", "anonymous");

        img.onload = () => {

          var canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;

          var ctx = canvas.getContext("2d");
          ctx.translate(canvas.width, 0);
          ctx.rotate(Math.PI / 2);
          ctx.drawImage(img, 0, 0);

          var dataURL = canvas.toDataURL("image/png");

          resolve(dataURL);
        };

        img.onerror = error => {
          reject(error);
        };

        img.src = url;
      });
    }
  }

  toggleconnect() {
    if (this.Comm.IsDriverConnected()) {
      setTimeout(() => {
        if (this.Comm.SocketArray.length !== 0) {
          this.connected.emit(true);
          return true;
        } else {
          this.connected.emit(false);
          return false;
        }
      }, 200);

    } else {
      this.connected.emit(false);
      return false;
    }
  }

  toggleUsb() {
    if (this.Comm.IsDriverConnected() && this.isDevicesnValid()) {
      setTimeout(() => {
        if (this.Comm.SocketArray.length !== 0) {
          this.Usb.emit(true);
          return true;
        } else {
          this.Usb.emit(false);
          return false;
        }
      }, 200);

    } else {
      this.Usb.emit(false);
      return false;
    }
  }

  isDevicesnValid() {

    try {
      // console.log("check sn", this.Comm.SocketArray[0]);
      // if (this.Comm.SocketArray[0].SN == '0000' || this.Comm.SocketArray[0].SN.length > 5 || this.Comm.SocketArray[0].SN.length < 4) {
      //   return false;
      // } else {
      //   return true;
      // }
      console.log(this.Comm.SocketArray[0].SNValid, "Serial number.....");

      return this.Comm.SocketArray[0].SNValid
    } catch (err) {
      return false;
    }
  }
  public toggleSideBarColor(color: string) {
    // console.log('toggle color', color);
    if (color === "white") {
      this.logoColor.emit("white");
    } else {
      this.logoColor.emit("black");
    }
  }


  toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  dateStringToTimestamp(calenderstring: string) {
    // takes "dd/mm/yyyy" or "dd/mm/yyyy hh:mm" and converts to js Date object
    // console.log('date from calendar ', calenderstring);
    let datesection;
    let timesection;
    let date;
    let time;
    let formatedDate;
    try {
      datesection = calenderstring.split(' ')[0];
      timesection = calenderstring.split(' ')[1];
      date = datesection.split('/');
      time = timesection.split(':');
      formatedDate = new Date(Number(date[2]), Number(date[1]) - 1, Number(date[0]), Number(time[0]), Number(time[1]));
    } catch (TypeError) {
      date = calenderstring.split('/');
      formatedDate = new Date(Number(date[2]), Number(date[1]) - 1, Number(date[0]));
    }

    return formatedDate;
  }

  convertQCExpDate(dateString: string, expiryDate: any = new Date()) {
    let month = Number(dateString.split('/')[0]);
    let year = Number(dateString.split('/')[1]);
    let day = 1;
    if (month === 12) {
      month = 0;
      year = year + 1;
    }
    let time = [23, 59]
    let timestamp = new Date(year, month, day, time[0], time[1]);

    if (expiryDate > timestamp) {
      // console.log('time expired!');
      return true;
    } else {
      return false;
    }
    // return timestamp;
  }

  convertProficiencyExpDate(dateString: string, expiryDate: any = new Date()) {
    let day = Number(dateString.split('/')[0]);
    let month = Number(dateString.split('/')[1]);
    let year = Number(dateString.split('/')[2]);
    // console.log("%%%%%%%%%%%%%%",dateString,expiryDate);

    month = month - 1;
    // if (month === 12) {
    //   month = 0;
    //   year = year + 1;
    // }
    let time = [23, 59]
    let timestamp = new Date(year, month, day, time[0], time[1]);
    if (expiryDate > timestamp) {
      // console.log('time expired!');
      return true;
    } else {
      // console.log('time not expired!');
      return false;
    }
    // return timestamp;
  }

  timestampToDateString(timestamp: Date, dateTime: boolean) {
    // take js Date object and returns "yyyy/mm/dd hh:mm"
    // console.log('timestamp ', timestamp);
    // let day = timestamp.getDate().toString();
    // let month = (timestamp.getMonth() + 1).toString();
    // let year = timestamp.getFullYear().toString();
    // let hours = timestamp.getHours().toString()
    // let minutes = timestamp.getMinutes().toString();

    // if (day.length < 2) { day = '0' + day };
    // if (month.length < 2) { month = '0' + month };
    // if (hours.length < 2) { hours = '0' + hours };
    // if (minutes.length < 2) { minutes = '0' + minutes };

    // let datestring = year + '/' + month + '/' + day + ' ' + hours + ':' + minutes;
    if (!timestamp) {
      return;
    }
    let datestring;
    if (dateTime) {
      datestring = formatDate(new Date(timestamp), 'yyyy/MM/dd HH:mm', 'en')
    } else {
      datestring = formatDate(new Date(timestamp), 'yyyy/MM/dd', 'en')

    }
    // console.log('datestring ', datestring);
    return datestring;

  }

  public getLocation() {
    return new Promise<any>((res, rej) => {
      if (navigator.geolocation) {
        // console.log('in location if');
        navigator.geolocation.getCurrentPosition(resp => {
          let location = {
            lat: resp.coords.latitude,
            lng: resp.coords.longitude
          }
          res(location);
        }, err => {
          // console.log('pos err ', err);
          rej("N/A");
        });
      }

    });
  }


  public getDriverDownloadUrl() {
    let hostname = window.location.hostname;
    let downloadUrl;
    if (hostname.toString().toLowerCase().includes('validation')) {
      downloadUrl = 'Installation/Service/InValidation/SQA-iO_Setup.exe';
    } else {
      downloadUrl = 'Installation/Service/SQA-iO_Setup.exe';
    }
    return downloadUrl;
  }

  public async getDriverUpdateVersion() {
    let hostname = window.location.hostname;
    let updateVersion;
    await this.http.getSystemVersions().then((data: any) => {
      if (hostname.toString().toLowerCase().includes('validation')) {
        updateVersion = data.data.allowDriverValidationVersions;
      } else {
        updateVersion = data.data.allowDriverVersions;
      }
      console.log('update driver version ', updateVersion, data);
    }).catch(err => {
      console.log(err);
      return err;
    });
    return await updateVersion;
  }

  getImageDims(imgUrl) {

    let img = new Image();
    return new Promise<any>((res, rej) => {
      img.src = imgUrl;
      img.onload = () => {
        let imgWidth = img.width;
        let imgHeight = img.height;
        // console.log('height: ' + imgHeight);
        // console.log('width: ' + imgWidth);
        // return [imgHeight, imgWidth];
        res({ width: imgWidth, height: imgHeight });
      };
      img.onerror = (err) => {
        rej(err);
      }
    });
  }

  public limitImageDims(imgUrl, setHeight) {
    return new Promise<any>((res, rej) => {
      this.getImageDims(imgUrl).then(dimensions => {
        // console.log('returned img dims', dimensions);
        let setWidth = Math.round(setHeight * dimensions.width / dimensions.height);
        // console.log(`new dimenstions: ${setWidth} x ${setHeight}`);
        res({ width: setWidth, height: setHeight });
      }).catch(err => {
        // console.log('img dims error', err);
        rej(err);
      });
    });


  }

  public setSeflTestStatus(failed: boolean) {
    this.selftestStatus = failed;
  }
  public getSelfTestStatus() {
    return this.selftestStatus;
  }

  public getUserManualUrl(contentLimitation) {

    let path: string;
    return new Promise<any>((res, rej) => {
      if (contentLimitation.englishOnly === true) {
        path = `Manuals/EU/SQA-iO_User_Guide.pdf`;
      } else {
        path = this._authService.iOwMode === true ? `Manuals/SQA-iOw/SQA-iOw_User_Guide.pdf` : `Manuals/${this.translate.currentLang}/SQA-iO_User_Guide.pdf`;
      }
      this.http.getFileUrl(path).then(oUrl => {
        res(oUrl);
      })
        .catch(err => {
          this.http.getFileUrl('Manuals/EU/SQA-iO_User_Guide.pdf').then(oEnUrl => {

            res(oEnUrl);
          }).catch(e => {
            rej(e);
          });
        });
    });
  }

  public getCleaningAppendixUrl() {
    let currLang = this.translate.currentLang;
    // console.log('curr lang ', currLang);
    let page: string;
    if (currLang === 'en' || currLang === 'pt' || currLang === 'it') {
      page = "#page=8";
    } else {
      page = "#page=8";
    }
    return new Promise<any>((res, rej) => {
      const path = `Manuals/${currLang}/SQA-iO_Service_Manual.pdf`;
      this.http.getFileUrl(path).then(oUrl => {
        let url = oUrl + page;
        res(url);
      })
        .catch(err => {
          this.http.getFileUrl(`Manuals/en/SQA-iO_Service_Manual.pdf`).then(oEnUrl => {
            let enurl = oEnUrl + "#page=8";
            res(enurl);
          }).catch(e => {
            rej(e);
          });

        });
    });

  }
  public getServiceManualUrl() {
    const path = this._authService.iOwMode === true ? `Manuals/SQA-iOw/SQA-iOw_Service_Manual.pdf` : `Manuals/${this.translate.currentLang}/SQA-iO_Service_Manual.pdf`
    return new Promise<any>((res, rej) => {
      this.http.getFileUrl(path).then(oUrl => {
        res(oUrl);
      })
        .catch(err => {
          this.http.getFileUrl(`Manuals/en/SQA-iO_Service_Manual.pdf`).then(oEnUrl => {
            res(oEnUrl);
          }).catch(e => {
            rej(e);
          });

        });
    });

  }

  public getCapillaryPrepAppendixUrl() {
    let currLang = this.translate.currentLang;
    let page: string;
    if (currLang === 'en' || currLang === 'pt' || currLang === 'it') {
      page = "#page=11";
    } else {
      page = "#page=11";
    }
    return new Promise<any>((res, rej) => {
      const path = `Manuals/${currLang}/SQA-iO_Service_Manual.pdf`;
      this.http.getFileUrl(path).then(oUrl => {
        let url = oUrl + page;
        res(url);
      })
        .catch(err => {
          this.http.getFileUrl(`Manuals/en/SQA-iO_Service_Manual.pdf`).then(oEnUrl => {
            let enurl = oEnUrl + "#page=11";
            res(enurl);
          }).catch(e => {
            rej(e);
          });

        });
    });

  }

  public getQuickStartGuideUrl() {
    return new Promise<any>((res, rej) => {
      const path = `Manuals/${this.translate.currentLang}/SQA-iO_Quick_Start_Guide.pdf`;
      this.http.getFileUrl(path).then(oUrl => {
        res(oUrl);
      })
        .catch(err => {
          this.http.getFileUrl(`Manuals/en/SQA-iO_Quick_Start_Guide.pdf`).then(oEnUrl => {
            res(oEnUrl);
          }).catch(e => {
            rej(e);
          });

        });
    });
  }

  public getHelpDocumentUrl(documentFileName: string) {
    return new Promise<any>((res, rej) => {
      const path = this._authService.iOwMode ? `Manuals/SQA-iOw/${documentFileName}` : `Manuals/${this.translate.currentLang}/${documentFileName}`;
      this.http.getFileUrl(path).then(oUrl => {
        res(oUrl);
      })
        .catch(err => {
          this.http.getFileUrl(`Manuals/en/${documentFileName}`).then(oEnUrl => {
            res(oEnUrl);
          }).catch(e => {
            rej(e);
          });
        });
    });
  }

  public getTroubleKeyParamsSectionUrl() {
    let currLang = this.translate.currentLang;
    let page: string;
    // if (currLang === 'en' || currLang === 'pt' || currLang === 'it') {
    //   page = "#page=10";
    // } else {
    //   page = "#page=10";
    // }
    return new Promise<any>((res, rej) => {
      const path = this._authService.iOwMode ? `Manuals/SQA-iOw/SQA-iOw_troubleshooting.pdf` : `Manuals/${currLang}/SQA-iO_troubleshooting.pdf`;
      this.http.getFileUrl(path).then(url => {
        res(url);
      })
        .catch(err => {
          this.http.getFileUrl(`Manuals/en/SQA-iO_troubleshooting.pdf`).then(enurl => {
            res(enurl);
          }).catch(e => {
            rej(e);
          });

        });
    });
  }

  public getTroubleShootSectionUrl() {
    let currLang = this.translate.currentLang;
    // let page: string;
    // if (currLang === 'en' || currLang === 'pt' || currLang === 'it') {
    //   page = "#page=9";
    // } else {
    //   page = "#page=9";
    // }
    return new Promise<any>((res, rej) => {
      const path = `Manuals/${currLang}/SQA-iO_troubleshooting.pdf`;
      this.http.getFileUrl(path).then(url => {
        res(url);
      })
        .catch(err => {
          this.http.getFileUrl(`Manuals/en/SQA-iO_troubleshooting.pdf`).then(enurl => {
            res(enurl);
          }).catch(e => {
            rej(e);
          });

        });
    });
  }


  // Report for NEQAS and QuaDeGa
  public async neqasQedegaReport(batch, distributorNum, scheme, Submissiondeadline?: any) {
    console.log("details", batch, distributorNum, scheme, Submissiondeadline);
    // this.facilityHeader.testSettings = defaultTestSettings

    var footerData = await this.returnFooter(true)
    var _footerData = await this.returnFooter(false)
    var headerArray = this.reportHeader(true)
    var imageObj: any = {};
    var topMrgn = -12;
    var jap_tab2_pos = 0
    var jap_tab1_pos = 0
    var preText: any = ""
    var note = "";
    var deadline = ""
    var latestDate: any
    var testDatetimeArray: any = []
    var mainTableWidths = [100, 80, 85, 80, 'auto']
    var footerText = "";
    var pctimeFormat = this.pcTimeFormat + " ";
    var footerval: boolean = this.facilityHeader.testSettings.reportSettings.firstPage.reportFooter;
    var now = this.customPipe.transform(new Date(), this.pcDateFormat + ' ' + pctimeFormat.split(" ")[0] + ':ss ' + pctimeFormat.split(" ")[1], this.translate.currentLang);
    let displayscheme = scheme
    batch.sort(function (a, b) {
      // convert date object into number to resolve issue in typescript
      // if (a.testDateTime.includes('/')) {
      //  return Number(new Date(a.testDateTime.substr(6),a.testDateTime.substr(3,2)-1,a.testDateTime.substr(0,2))) + Number(new Date(b.testDateTime.substr(6),b.testDateTime.substr(3,2)-1,b.testDateTime.substr(0,2)))
      // }else{
      if (a.data == undefined) {
        return Number(a.testDateTime) - Number(b.testDateTime);
      } else {
        return Number(a.data.testDateTime.toDate()) - Number(b.data.testDateTime.toDate());
      }
      // }
    });
    // console.log("TTTTTTTTTTTT", batch);

    // if (!footerval||tru) {
    footerText = this.translate.instant('qc-controls.printed_from') + ' SN: ' + batch[0].calRawData.serialNumber + ' | ' + now;
    // } else {
    //   footerText = ""
    // }
    imageObj = (await headerArray).leftHeader
    var dataArray: any = []
    if (scheme == "QuaDeGa") {
      preText = this.checkandTranslate(this.translate.instant('proficiency.probe')) + " "
    } else {
      preText = ""
    }
    if (scheme != "CAP/API" && scheme != "ipro") {
      dataArray.push([{ text: this.checkandTranslate(this.translate.instant('qc-controls.date/time')), fillColor: '#B0E0E6', style: 'paraheader', bold: 'Helvetica-Bold', alignment: 'center' }, { text: this.checkandTranslate(this.translate.instant('proficiency.distibution_num')), fillColor: '#B0E0E6', style: 'paraheader' }, { text: this.checkandTranslate(this.translate.instant('proficiency.sample')), fillColor: '#B0E0E6', style: 'paraheader' }, { text: this.checkandTranslate(this.translate.instant('test.results')) + "\n" + this.translate.instant('units.M_ml'), fillColor: '#B0E0E6', style: 'paraheader' }, { text: this.checkandTranslate(this.translate.instant('proficiency.submission_deadLine')), fillColor: '#B0E0E6', style: 'paraheader' }, { text: this.checkandTranslate(this.translate.instant('proficiency.note')), fillColor: '#B0E0E6', style: 'paraheader' }])
      if (this.languageType == 'it') {
        mainTableWidths = [80, 80, 45, 63, 67, 168]
      } else if (this.languageType == 'pt') {
        mainTableWidths = [80, 70, 45, 65, 75, 168]
      } else if (this.languageType == 'vi') {
        mainTableWidths = [80, 60, 65, 60, 70, 168]
      } else if (this.languageType == 'tr') {
        mainTableWidths = [80, 53, 72, 50, 80, 168]
      } else if (this.languageType == 'fr') {
        mainTableWidths = [80, 53, 72, 50, 80, 168]
      } else {
        mainTableWidths = [80, 80, 45, 70, 60, 168]
      }
    } else if (scheme == "ipro") {
      displayscheme = "iPRO"
      dataArray.push([{ text: this.checkandTranslate(this.translate.instant('qc-controls.date/time')), fillColor: '#B0E0E6', style: 'paraheader', bold: 'Helvetica-Bold', alignment: 'center' }, { text: this.checkandTranslate(this.translate.instant('proficiency.batch_number')), fillColor: '#B0E0E6', style: 'paraheader' }, { text: this.checkandTranslate(this.translate.instant('proficiency.sample')) + " #", fillColor: '#B0E0E6', style: 'paraheader' }, { text: this.checkandTranslate(this.translate.instant('settings.concentration')) + "\n" + this.translate.instant('units.M_ml'), fillColor: '#B0E0E6', style: 'paraheader' }, { text: this.checkandTranslate(this.translate.instant('proficiency.note')), fillColor: '#B0E0E6', style: 'paraheader' }])
      mainTableWidths = [100, 80, 85, 80, 168]
    } else if (scheme == "CAP/API") {
      dataArray.push([{ text: this.checkandTranslate(this.translate.instant('qc-controls.date/time')), fillColor: '#B0E0E6', style: 'paraheader', bold: 'Helvetica-Bold', alignment: 'center' }, { text: this.checkandTranslate(this.translate.instant('proficiency.issue_date')), fillColor: '#B0E0E6', style: 'paraheader' }, { text: this.checkandTranslate(this.translate.instant('proficiency.sample')) + " #", fillColor: '#B0E0E6', style: 'paraheader' }, { text: this.checkandTranslate(this.translate.instant('test.results')) + "\n" + this.translate.instant('units.M_ml'), fillColor: '#B0E0E6', style: 'paraheader' }, { text: this.checkandTranslate(this.translate.instant('proficiency.note')), fillColor: '#B0E0E6', style: 'paraheader' }])
      mainTableWidths = [100, 80, '*', 80, 168]
    }

    if (batch.length >= 1) {
      batch.forEach(element => {
        // console.log("distttttttttttttt", element);
        if (Submissiondeadline != undefined) {
          deadline = Submissiondeadline
        } else {
          if (element.submissionDeadline != undefined) {
            deadline = this.customPipe.transform(new Date(element.submissionDeadline.substr(6), element.submissionDeadline.substr(3, 2) - 1, element.submissionDeadline.substr(0, 2)), this.pcDateFormat, this.translate.currentLang);
          } else {
            deadline = ""
          }
        }

        testDatetimeArray.push(element.testDateTime)
        if (element.conc != undefined) {
          if (element.note != undefined) {
            note = element.note
          } else {
            note = ""
          }
          console.log("NOte", this.customPipe.transform(element.testDateTime, this.pcDateFormat + ' ' + this.pcTimeFormat, this.translate.currentLang));

          if (scheme != "CAP/API" && scheme != "ipro") {
            dataArray.push([{ text: this.customPipe.transform(element.testDateTime, this.pcDateFormat + ' | ' + this.pcTimeFormat, this.translate.currentLang), style: 'paradata', alignment: 'center' }, { text: element.distributionNo, style: 'paradata' }, { text: preText + element.sampleId, style: 'paradata' }, { text: element.conc, style: 'paradata' }, { text: deadline, style: 'paradata' }, { lineHeight: 0.8, text: note, style: 'paradata' }])
          } else if (scheme == "ipro") {
            dataArray.push([{ text: this.customPipe.transform(element.testDateTime, this.pcDateFormat + ' | ' + this.pcTimeFormat, this.translate.currentLang), style: 'paradata', alignment: 'center' }, { text: element.batchNumber != undefined ? element.batchNumber : element.data.batchNumber, style: 'paradata' }, { text: preText + element.sampleId, style: 'paradata' }, { text: element.conc, style: 'paradata' }, { lineHeight: 0.8, text: note, style: 'paradata' }])

          } else if (scheme == "CAP/API") {
            dataArray.push([{ text: this.customPipe.transform(element.testDateTime, this.pcDateFormat + ' | ' + this.pcTimeFormat, this.translate.currentLang), style: 'paradata', alignment: 'center' }, { text: element.issueDate, style: 'paradata' }, { text: preText + element.sampleId, style: 'paradata' }, { text: element.conc, style: 'paradata' }, { lineHeight: 0.8, text: note, style: 'paradata' }])
          }
        }
      });
      latestDate = new Date(Math.max(...testDatetimeArray.map(e => new Date(e))))
    }
    let isFirstPage = false
    dataArray.forEach((element, index) => {
      if (index != 0) {
        if (index % 20 == 0 && isFirstPage == true && index != 10) {
          isFirstPage = false
          dataArray[index][0].pageBreak = 'before'
          console.log(index, dataArray.length, "zzzzzzzzzzzzzzzzzzzzzz1");

        } else if ((dataArray.length - index) % 30 == 0 && isFirstPage == false && index != 10) {
          dataArray[index][0].pageBreak = 'before'
          console.log(index, dataArray.length, "zzzzzzzzzzzzzzzzzzzzzz2");

        }
      }
    });
    console.log("EEEEEEEEEEE", dataArray, latestDate)
    var address = ''
    if (this.facilityHeader.streetNo) {
      if ((this.facilityHeader.country.toLowerCase().includes("united states") && this.facilityHeader.state != undefined && this.facilityHeader.state != "") || this.translate.currentLang == 'ja') {
        if (this.translate.currentLang == 'ja') {
          if (this.facilityHeader.state != undefined && this.facilityHeader.state != '') {
            address += this.facilityHeader.zipCode + this.facilityHeader.state + this.facilityHeader.city
          } else {
            address += this.facilityHeader.zipCode + this.facilityHeader.city
          }
          if (this.facilityHeader.streetNo.split(";")[1] != undefined && this.facilityHeader.streetNo.split(";")[1] != "null") {
            address += this.facilityHeader.streetNo.split(";")[0] + this.facilityHeader.streetNo.split(";")[1]
          } else {
            address += this.facilityHeader.streetNo.split(";")[0]
          }
        } else {
          console.log("////////////////////////////////////////////?????????????????????");

          if (this.facilityHeader.streetNo.split(";")[1] != undefined && this.facilityHeader.streetNo.split(";")[1] != "null") {
            address = this.facilityHeader.streetNo.split(";")[0] + ' ' + this.facilityHeader.streetNo.split(";")[1]
          } else {
            address = this.facilityHeader.streetNo.split(";")[0]
          }
          if (this.facilityHeader.state != undefined && this.facilityHeader.state != '') {
            address += ', ' + this.facilityHeader.city + '\n' + this.facilityHeader.state + ' ' + this.facilityHeader.zipCode + ', ' + this.facilityHeader.country
          } else {
            address += ', ' + this.facilityHeader.city + '\n ' + this.facilityHeader.zipCode + ', ' + this.facilityHeader.country
          }
        }

      } else {
        console.log("////////////////////////////////////////////?????????????????????");

        if (this.facilityHeader.streetNo.split(";")[1] != undefined && this.facilityHeader.streetNo.split(";")[1] != "null") {
          address = this.facilityHeader.streetNo.split(";")[0] + ' ' + this.facilityHeader.streetNo.split(";")[1]
        } else {
          address = this.facilityHeader.streetNo.split(";")[0]
        }
        if (this.facilityHeader.state != undefined && this.facilityHeader.state != '') {
          address += ', ' + this.facilityHeader.city + '\n' + this.facilityHeader.state + ' ' + this.facilityHeader.zipCode + ', ' + this.facilityHeader.country
        } else {
          address += ', ' + this.facilityHeader.city + '\n ' + this.facilityHeader.zipCode + ', ' + this.facilityHeader.country
        }
      }
    }

    var margin = 20;

    if (this.facilityHeader.website) {
      if (this.facilityHeader.email.length >= this.facilityHeader.website.length) {
        if (this.facilityHeader.email.length >= 30) {
          if (this.facilityHeader.email.length >= 40) {
            margin = this.facilityHeader.email.length * 2 + 25
          } else {
            margin = this.facilityHeader.email.length * 2 + 20
          }
        } else {
          if (this.facilityHeader.email.length > 15) {
            margin = this.facilityHeader.email.length + 15
          }
        }
      }
      else {
        if (this.facilityHeader.website.length >= 30) {
          if (this.facilityHeader.website.length >= 40) {
            margin = this.facilityHeader.website.length * 2
            // console.log(margin, "expected");

          } else {
            margin = this.facilityHeader.website.length * 2 + 20

          }
        } else {
          if (this.facilityHeader.website.length > 15) {
            margin = this.facilityHeader.website.length + 15
            // console.log(margin, "Margin");

          }
        }
      }
    } else {
      if (this.facilityHeader.email.length >= 30) {
        if (this.facilityHeader.email.length >= 40) {
          margin = this.facilityHeader.email.length * 2 + 25

        } else {
          margin = this.facilityHeader.email.length * 2 + 20

        }

      } else {

        if (this.facilityHeader.email.length > 15) {
          margin = this.facilityHeader.email.length + 15
        }
      }
    }

    var desg_line_length = 120
    var phoneFont = 7
    if (this.languageType == 'ja') {
      jap_tab1_pos = 1
      jap_tab2_pos = 1

    }
    else {
      // switchFont = 'Roboto';
      jap_tab1_pos = 0
      jap_tab2_pos = 0
    }

    if (this.languageType == 'es') {
      margin += 40
    }
    var facilityName = this.facilityHeader.facilityName
    if (this.facilityHeader.testSettings.reportSettings.firstPage.reportHeader == true) {
      address = " "
      facilityName = ""
    }

    // pdfFonts.pdfMake.vfs['Arial_unicode'] = arial_unicodeURL
    // pdfFonts.pdfMake.vfs['Arial_unicode_bold'] = arial_unicodeBoldURL
    // pdfMake.fonts = {
    //   Roboto: {
    //     normal: 'Roboto-Regular.ttf',
    //     bold: 'Roboto-Medium.ttf',
    //     italics: 'Roboto-Italic.ttf',
    //     bolditalics: 'Roboto-MediumItalic.ttf'
    //   },
    //   japneseFont: {
    //     normal: 'Arial_unicode',
    //     bold: 'Arial_unicode_bold',
    //     italics: 'Arial_unicode',
    //     bolditalics: 'Arial_unicode'
    //   },
    // }
    var headerSpace
    var headermargin = 0
    // if (this.facilityHeader.testSettings.reportSettings.firstPage.headerSpace != undefined && this.facilityHeader.testSettings.reportSettings.firstPage.reportHeader) {
    //   if (this.facilityHeader.testSettings.reportSettings.firstPage.headerSpace >= 40) {
    //     headermargin = 2.5 * this.facilityHeader.testSettings.reportSettings.firstPage.headerSpace
    //   } else if (this.facilityHeader.testSettings.reportSettings.firstPage.headerSpace >= 30) {
    //     headermargin = 2.6 * this.facilityHeader.testSettings.reportSettings.firstPage.headerSpace
    //   } else {
    //     headermargin = 2.92 * this.facilityHeader.testSettings.reportSettings.firstPage.headerSpace
    //   }
    // } else {
    //   headermargin = 0
    // }
    // to make settings not applicable for proficiency report
    // if (headermargin == 0 || true) {
    if (this.languageType == 'ja') {
      headermargin = 45
    } else {
      headermargin = 30
    }
    // }
    var footermargin = 10;
    if (this.facilityHeader.testSettings.reportSettings.firstPage.footerSpace != undefined && this.facilityHeader.testSettings.reportSettings.firstPage.reportFooter) {
      footermargin = 3 * (this.facilityHeader.testSettings.reportSettings.firstPage.footerSpace) + 10
    } else {
      footermargin = 10
    }
    const reportSettings = this.facilityHeader.testSettings.reportSettings

    var docDefinition = {
      header: (currentPage, pageCount, pageSize) => {
        this.PageCount = pageCount;
        var pageNumber: any = [{ text: ` `, style: "headerText", },];
        // added true to make settings not applicable
        // if (reportSettings.firstPage.displayPageNumber || true)
        pageNumber = [{ text: `${this.translate.instant("test.page")}  ${currentPage}  ${this.translate.instant("test.of")}  ${pageCount} `, style: "headerText", },]

        return pageNumber
        // }
      },
      footer: (currentPage, pageCount, pageSize) => {
        this.PageCount = pageCount;
        let currentPageSettings: any;
        const colWidth = ((210 * 2.835) - 27) / 3
        currentPageSettings = reportSettings.firstPage
        let pgMargin = -5;
        var footer: any = [{ text: ``, style: "footerstyle", margin: [0, 0, 0, -15] }];
        // if (currentPage == pageCount) {
        // if (!currentPageSettings.reportFooter || true) {
        footer = [{ text: footerText, style: "footerstyle", margin: [0, 5, 0, -15] }];
        pgMargin = -40
        // if (currentPage !=1 && reportSettings.firstPage.reportFooter==true) {
        //   pgMargin = footerval-50
        //   // footer[0].margin = [0,pgMargin,0,-15]
        // }
        // } else {

        // if (currentPage !=1 ) {
        // pgMargin = -Number(currentPageSettings.footerSpace * 2.8346456692913)
        // }else {
        //   pgMargin = -Number(currentPageSettings.footerSpace * 2.8346456692913)
        // } 
        // }
        console.log("++++++++++++++++++++++++", pgMargin);

        const footerObj = {
          margin: [12, pgMargin - 20, 10, 10],
          columns: [
            {
              width: colWidth,
              margin: [0, -11, 0, 0],
              table: {
                widths: [(_footerData).firstCellWidth('sign'), '*'],
                body: [
                  (_footerData).signature,
                ],
                layout: {
                  defaultBorder: true,
                }
              }
            },
            {
              // style: 'sign',
              width: colWidth,
              table: {
                // this.translate.currentLang != 'en' ? 75 : 54
                widths: [(_footerData).firstCellWidth('tester'), '*'],
                body: [
                  (footerData).tester
                ]
              },
              layout: {
                defaultBorder: true,
              }
            },
            {
              // style: 'sign',
              width: colWidth,
              table: {
                widths: [(_footerData).firstCellWidth('desg'), '*'],
                body: [
                  (footerData).desg
                ]
              },
              layout: {
                defaultBorder: true,
              }

            },

          ].filter(el => el)
        }
        // if (!currentPageSettings.removeSignature || !currentPageSettings.removeTester)
        footer.unshift(footerObj)

        console.log(footer);

        return footer
      },
      info: {
        title: this.translate.instant('proficiency.report-title'),
        author: 'MES',
      },
      pageMargins: [17, headermargin, 10, 10],
      content: [
        {
          columns: [
            {
              width: 430 - margin,
              table: {
                widths: [160],
                headerRows: 1,
                body: [
                  [imageObj],
                  [{ text: this.facilityHeader.facilityName, style: 'facilityHeader' }],
                  [{ text: this.getAddress(), style: 'anotherStyle', margin: [0, 0, 0, 0] }]
                ]
              },
              layout: {
                defaultBorder: false,
              }
            },
            {
              width: '*',
              margin: [0, 10, 0, 10],
              table: {
                widths: [(await footerData).topright, '*'],
                headerRows: 1,
                body: (await headerArray).rightHeader
              },
              layout: {
                defaultBorder: false,
              }
            },
          ],
        },
        // here it should add image to pdf
        // imageObj,
        // {
        //   style: 'address',
        //   table: {
        //     widths: [130],
        //     // heights:10,
        //     headerRows: 1,
        //     body: [
        //       [{ text: facilityName, style: 'facilityHeader', margin: [-5, 7, 0, 0] }],
        //       [{ text: address, style: 'anotherStyle', margin: [-5, 3, 0, 0] }]
        //     ]
        //   },
        //   layout: {
        //     defaultBorder: false,
        //   }
        // },
        // // (await footerData).signImage,
        // {
        //   style: 'FacilityHeader',
        //   margin: [400 - (margin), -80, 0, 0],
        //   table: {
        //     widths: [(await footerData).topright, 'auto'],
        //     // heights:10,
        //     headerRows: 1,
        //     body: (await headerArray).rightHeader
        //   },
        //   layout: {
        //     defaultBorder: false,
        //   }
        // },
        { text: this.checkandTranslate(this.translate.instant('proficiency.report-title')), style: 'header' },
        { text: this.translate.instant('test.report_subhead'), style: 'subhead' },
        {
          style: 'tableExample',
          table: {
            widths: [125, 125],
            headerRows: 1,
            body: [
              [{ text: this.checkandTranslate(this.translate.instant('proficiency.heading')), colSpan: 2, border: [false, false, false, true], style: 'tableHeader' }, { text: "", border: [false, false, false, true], style: 'anotherStyle' }],
              [{ text: this.checkandTranslate(this.translate.instant('proficiency.scheme')) + ':', border: [false, false, false, true], style: 'anotherStyle' }, { text: displayscheme, border: [false, false, false, true], style: 'anotherStyle' }],
              [{ text: this.checkandTranslate(this.translate.instant('qc-controls.run_date')) + ':', border: [false, false, false, true], style: 'anotherStyle' }, { text: this.customPipe.transform(latestDate, this.pcDateFormat + ' | ' + this.pcTimeFormat, this.translate.currentLang), border: [false, false, false, true], style: 'anotherStyle' }],
            ]
          },
          layout: {
            defaultBorder: false,
          }
        },
        {
          style: 'tableExample2',
          table: {
            widths: [125, 125],
            headerRows: 1,
            body: [
              [{ text: "", colSpan: 2, border: [false, false, false, true], style: 'tableHeader' }, { text: "", border: [false, false, false, true], style: 'anotherStyle' }],
              [{ text: this.checkandTranslate(this.translate.instant('qc-controls.report_datetime')) + ':', border: [false, false, false, true], style: 'anotherStyle' }, { text: this.customPipe.transform(new Date(), this.pcDateFormat + ' | ' + this.pcTimeFormat, this.translate.currentLang), border: [false, false, false, true], style: 'anotherStyle' }],
              [{ text: " ", border: [false, false, false, true], style: 'anotherStyle' }, { text: "", border: [false, false, false, true], style: 'anotherStyle' }],
            ]
          },
          layout: {
            // paddingTop: function (i, node) { 
            //   console.log("::::::::::::",i,node);

            //   return 5; 
            // }
          }
        },
        {
          style: 'paraTable2',
          table: {
            widths: mainTableWidths,
            dontBreakRows: true,
            body: dataArray,
            heights: 'auto'
          },

          layout: {
            defaultBorder: true,
            paddingTop: function (i, node) {
              // console.log("::::::::::::", i, node);

              return 5;
            }
          }

        },
        // {
        //   table: {
        //     widths: [36, 110],
        //     body: [
        //       (await footerData).signature
        //     ]
        //   },
        //   layout: {
        //     defaultBorder: false,
        //   }

        // },

        // {
        //   style: 'sign',
        //   table: {
        //     widths: [48, '*'],
        //     body: [
        //       (await footerData).tester
        //     ]
        //   },
        //   layout: {
        //     defaultBorder: false,
        //   }

        // },

        // {
        //   style: 'sign',
        //   table: {
        //     widths: ['*', 250],
        //     body: [
        //       (await footerData).desg
        //     ]
        //   },
        //   layout: {
        //     defaultBorder: false,
        //   }

        // },

        // { text: footerText, style: 'footerstyle', absolutePosition: { x: 10, y: 810 } },

        // (await footerData).Lines


      ],
      unbreakable: true,
      styles: {
        header: {
          fontSize: 14,
          bold: 'Helvetica-Bold',
          margin: [0, 20, 0, 0],
          alignment: 'center',
          normal: 'Helvetica'
        },
        subhead: {
          fontSize: 8.7,
          margin: [0, 5, 0, 20],
          alignment: 'center',
          normal: 'Helvetica'
        },
        tabledata: {
          normal: 'Helvetica',
          fontSize: 7.7,
          color: 'black',
          alignment: 'center',
          // margin: [0, 3, 0, 0],
        },
        headerText: {
          fontSize: 8.5,
          alignment: "right",
          margin: [10, 10, 14, 10],
        },
        footerTable: {
          alignment: 'left',
          fontSize: 7.5,
          normal: 'Helvetica',
        },
        footerstyle: {
          normal: 'Helvetica',
          fontSize: 7.7,
          color: 'grey',
          alignment: 'center'
        },

        tableExample: {
          margin: [0, -10, 0, 10]
        },
        paraTable: {
          margin: [0, -1, 0, 10]
        },
        paraTable2: {
          margin: [0, 3, 0, 10]
        },
        sampleinfoLeft: {
          margin: [0, -5, 0, 10]
        },
        address: {
          margin: [0, 0, 0, 0]
        },
        tableExample2: {
          margin: [290, -52, 0, 15],
          alignment: 'right'
        },
        sampleinforight: {
          margin: [290, -174 - jap_tab2_pos, 0, 15],
          alignment: 'right'
        },
        sampleinforight2: {
          margin: [290, -174 - jap_tab2_pos, 0, 15],
          alignment: 'right'
        },
        tableHeader: {
          normal: 'Helvetica',
          bold: 'Helvetica-Bold',
          fontSize: 7.7,
          color: 'black',
        },
        facilityHeader: {
          normal: 'Helvetica',
          bold: 'Helvetica-Bold',
          fontSize: 9.7,
          color: 'black',
        },
        FacilityHeader: {
          normal: 'Helvetica',
          fontSize: 7.7,
          color: 'black',
        },
        anotherStyle: {
          alignment: 'left',
          fontSize: 7.5,
          margin: [0, 3, 0, 0],
          normal: 'Helvetica',
        },
        conValcolor: {
          normal: 'Helvetica',
          fontSize: 7.7,
          alignment: 'center',
          color: 'red'
        },
        paraheader: {
          alignment: 'center',
          normal: 'Helvetica',
          bold: 'Helvetica-Bold',
          fontSize: 7.7,
          color: 'black',
          margin: [0, 2, 0, 0]
        },
        paradata: {
          alignment: 'center',
          normal: 'Helvetica',
          fontSize: 7.7,
          color: 'black',
          margin: [0, 2, 0, 0]
        },
        comments: {
          margin: [0, 0, 0, 0],
        },
        sign: {
          margin: [0, 0, 35, 0],
          alignment: 'left',
        },
        testerName: {
          margin: [0, 65, 35, 0],
          alignment: 'left',
        },
        signaturesection: {
          margin: [0, 80, 35, 0],
          alignment: 'left',
        },
        nocomments_signature: {
          margin: [0, 80, 35, 0],
          alignment: 'left',
        },


      },

      defaultStyle: {
        font: 'japneseFont',

      }

    };
    this.Pdfobj = pdfMake.createPdf(docDefinition).open();

  }

  decimalPointHandler(value) {
    var modifiedvalue
    if (value.includes(',')) {
      modifiedvalue = value.replace(',', '.')
    } else {
      modifiedvalue = value
    }
    // console.log("Logging values", modifiedvalue);

    return Number(modifiedvalue)
  }

  public logoutSetFlags() {
    this.credit_0_flag = true;
    this.credit_5_flag = true;
    this.credit_10_flag = true;
  }


  // Manual Calculations

  proceedManualCalculation(manualResults, volume) {
    console.log("nnnnnnn", manualResults);


    var totMotile, prog_pr, concen, normalForms
    totMotile = manualResults.mTotal_Motile
    prog_pr = manualResults.mProgrssive_Pr
    concen = manualResults.mConcentration
    if (manualResults.mNormalForms != undefined) {
      normalForms = Number(manualResults.mNormalForms)
    } else {
      normalForms = manualResults.mNormalForms
    }

    var nonProgressive, immotile, msc, pmsc;
    // Total Params
    var totSperm, totMot_sperm, totProg_motile_sperm, totNormal_forms;
    nonProgressive = totMotile - prog_pr;
    immotile = 100 - totMotile;
    msc = (totMotile * concen) / 100
    pmsc = (prog_pr * concen) / 100

    // Total
    totSperm = concen * volume;
    totMot_sperm = msc * volume;
    totProg_motile_sperm = pmsc * volume;
    if (normalForms != undefined) {
      totNormal_forms = Number((totSperm * normalForms) / 100)
    } else {
      totNormal_forms = undefined
    }
    this.Fresh_Test_Data.mmotileSpermConc = Number(msc)
    this.Fresh_Test_Data.mtotalMotilePRNP = Number(totMotile),
      this.Fresh_Test_Data.mprogressivePR = Number(prog_pr),
      this.Fresh_Test_Data.mnon_progressive_np = Number(nonProgressive)
    this.Fresh_Test_Data.mimmotile = Number(immotile)
    this.Fresh_Test_Data.mconcentration = Number(concen),
      this.Fresh_Test_Data.mnormalForms = normalForms,
      // mNoSpermSeen: ,
      this.Fresh_Test_Data.mspermCount = Number(totSperm),
      this.Fresh_Test_Data.mmotileSperm = Number(totMot_sperm),
      this.Fresh_Test_Data.mprogMotileSpermConc = Number(totProg_motile_sperm),
      this.Fresh_Test_Data.mtotNormalForms = totNormal_forms

    console.log("FFFF", this.Fresh_Test_Data);

  }
  async returnSMIarrow() {
    if (this.handlingnegSigns(this.Fresh_Test_Data.spermMotilityIndex, "SMI") != "N/A") {
      return { image: await this.getBase64ImageFromURL(this.smiArrow), fit: [30, 30], style: 'tabledata', margin: [0, 1, 0, 0] }
    } else {
      return { text: '', fit: [30, 30], style: 'tabledata', margin: [0, 1, 0, 0] }
    }
  }

  checkNetworkconnection() {
    // Access to XMLHttpRequest at 'https://us-central1-messqasystem.cloudfunctions.net/checkInternetConnection' from origin 'http://localhost:4200' has been blocked by CORS policy: No 'Access-Control-Allow-Origin' header is present on the requested resource.
    setInterval(() => {

      this.http.checkconn().then((res: any) => {
        if (!this.networkStatus) {
          this.isInternet.next(true)
          this.networkStatus = true
        }
      }).catch((err) => {
        console.error('Network disconnected :', err);
        if (typeof (err) === 'string' && err.includes('CORS')) return;
        if (this.networkStatus) {
          this.isInternet.next(false)
          this.networkStatus = false
        }

      })
    }, 1000);
  }

  networkMonitor(): Observable<any> {
    return this.isInternet.asObservable()
  }

  getConnectionStatus() {
    this.connectionStatus = (navigator['connection']);
    return this.connectionStatus;
  }

  //Report supporting functions

  pagenumberForreport(isPagenumber, currentPage, pageCount) {

    return [
      {
        text: `${this.translate.instant("test.page")}  ${currentPage}  ${this.translate.instant("test.of")}  ${pageCount} `,
        style: "headerText",
      },
    ];

  }

  verticallyRotatedText(text: string) {
    const _sampleId: string = this.Fresh_Test_Data.sampleInformation.sampleId ?? "";
    let height = 1130
    if (_sampleId.length > 16)
      height = 1330
    const canvas = document.createElement("canvas");
    canvas.width = 47;
    canvas.height = height;
    const ctx = canvas.getContext("2d");
    ctx.font = "normal normal 900 35px arial";
    ctx.save();
    ctx.translate(37, height);
    ctx.rotate((-90 * Math.PI) / 180);
    ctx.fillStyle = "black";
    ctx.fillText(text, 0, 0);
    ctx.restore();

    return canvas.toDataURL();
  }

  drawImage(url, datetime, sampletype, addGridToImages?: boolean) {
    const isShowGrid = this.Fresh_Test_Data.reportImageGrid ?? addGridToImages;
    return new Promise((res, rej) => {
      const img = new Image();
      console.log(url);

      const drawGrid = (canvas, ctx) => {

        const hori = (canvas.height) / 5 //horizontal divides height
        const vert = (canvas.width) / 4  //vertical divides width
        console.log(canvas.height, canvas.width);

        ctx.lineWidth = 2;

        //horizontal lines
        for (let i = 0; i <= 5; i++) {
          const x = -(canvas.height / 2) + (i * hori)
          ctx.moveTo(x, 0);
          ctx.lineTo(x, -canvas.width);
          ctx.lineTo(x, canvas.width);
          ctx.stroke();
        }
        //vertical lines
        for (let i = 0; i <= 4; i++) {
          const y = -(canvas.width / 2) + (i * vert)
          ctx.moveTo(0, y);
          ctx.lineTo(-canvas.height, y);
          ctx.lineTo(canvas.height, y);
          ctx.stroke();
        }
      }
      img.setAttribute('crossOrigin', 'anonymous');
      img.onload = () => {

        const canvas = document.createElement("canvas");
        canvas.width = img.height / 2;
        canvas.height = img.width / 2;
        var ctx = canvas.getContext("2d");
        ctx.translate(img.height / 4, img.width / 4);
        ctx.rotate((-90 * Math.PI) / 180);

        ctx.drawImage(
          img,
          0,
          0,
          img.width,
          img.height,
          -img.width / 4,
          -img.height / 4,
          img.width / 2,
          img.height / 2
        );
        if (isShowGrid)
          drawGrid(canvas, ctx)

        const dataURL = canvas.toDataURL("image/png");

        res({ url: dataURL, dateTime: datetime, sampleType: sampletype })

      }
      // img.width = url.width
      // img.height = url.height

      img.src = url

      img.onerror = () => {
        console.log("error with image");

        res(undefined)

      }
    })

  }


  async imagesPdfStructure(Imagedata, testDt) {
    let verticallyRotatedImages: any[] = await this.getimageURL(Imagedata)
    const criteria = this.Fresh_Test_Data.testSettings.who
    if (verticallyRotatedImages.length == 0)
      return

    const secondPageSettings = this.facilityHeader.testSettings.reportSettings[criteria == 3 ? 'firstPage' : 'secondPage']
    let imageTxtFit = [['*', 235], [4, 25, 0, 0]]
    let imageFit = [[360, 270], [0, 0, 0, 0]]
    let emptySpace = 0
    emptySpace += secondPageSettings.reportHeader ? secondPageSettings.headerSpace : 0
    emptySpace += secondPageSettings.reportFooter ? secondPageSettings.footerSpace : 0
    console.warn(emptySpace)
    if (emptySpace <= 50) {
      imageFit[0] = [360, 300]
      imageTxtFit = [['*', 300], [4, 5, 0, 0]]
    } else if (emptySpace > 50 && emptySpace < 70) {
      imageFit[0] = [360, 285]
      imageTxtFit = [['*', 250], [4, 30, 0, 0]]
    } else if (secondPageSettings.removeSignature == true && secondPageSettings.removeTester == true && emptySpace >= 70) {
      imageFit[0] = [360, 285]
      imageTxtFit = [['*', 250], [4, 25, 0, 0]]
    } else if (emptySpace >= 70) {
      imageFit[0] = [360, 270]
      imageTxtFit = [['*', 235], [4, 25, 0, 0]]
    }



    let allData: any = []
    let capturedVid = 0
    let pagebreak = ""
    let PAGE_COUNT = 0;
    let MAX_ITEM_PER_PAGE = 4;
    const upperMargin = this.getSecondPageMargin("headerSpace")
    const groupedByPairImages: any = [];
    if (this.Fresh_Test_Data.visualizationVideos != undefined) {
      capturedVid = this.Fresh_Test_Data.visualizationVideos.length
    }
    if (this.Fresh_Test_Data.testSettings.who == 3 || this.hasAddtionalText || this.Fresh_Test_Data.testingMode == 2 || this._authService.iOwMode == true) {
      pagebreak = "before"
      groupedByPairImages.push({
        text: { text: `${this.checkandTranslate(this.translate.instant("test.report_continued"))} | ${this.checkandTranslate(this.translate.instant("patients.patient_id"))}: ${this.Patient_Info.patientid ?? this.Fresh_Test_Data.patientid} | ${this.checkandTranslate(this.translate.instant("test.test_date")).replace("|", "/")}: ${testDt}`, style: "reportContinueText" },
        pageBreak: pagebreak,
        margin: [0, upperMargin, 0, 15]
      })
    }

    const _sampleId = this.Fresh_Test_Data.sampleInformation.sampleId;
    const pdfImageColumnStructure = (index) => ({

      table: {
        headerRows: 0,
        widths: ["3%", "44.5%", "3%", "44.5%"],
        heights: [190],
        body: [
          [
            {
              image: this.verticallyRotatedText(
                `${_sampleId !== undefined ? _sampleId + '_' : ''}${verticallyRotatedImages[index].sampleType != undefined ? this.translate.instant(`visualization.${verticallyRotatedImages[index].sampleType}`).toUpperCase() : this.translate.instant("test.fresh").toUpperCase()}_IMG_${verticallyRotatedImages[index].dateTime.getUTCDate()}_${verticallyRotatedImages[index].dateTime.toLocaleString('default', { month: 'short' })}_${verticallyRotatedImages[index].dateTime.getUTCFullYear()}_${verticallyRotatedImages[index].dateTime.getHours()}:${verticallyRotatedImages[index].dateTime.getMinutes()}:${verticallyRotatedImages[index].dateTime.getSeconds()}`
              ),
              fit: imageTxtFit[0],
              margin: imageTxtFit[1],
            },
            {
              alignment: 'center',
              image: verticallyRotatedImages[index].url,
              fit: imageFit[0],
              margin: imageFit[1]
            },
            {
              image: this.verticallyRotatedText(
                `${_sampleId !== undefined ? _sampleId + '_' : ''}${verticallyRotatedImages[index + 1].sampleType != undefined ? this.translate.instant(`visualization.${verticallyRotatedImages[index + 1].sampleType}`).toUpperCase() : this.translate.instant("test.fresh").toUpperCase()}_IMG_${verticallyRotatedImages[index + 1].dateTime.getUTCDate()}_${verticallyRotatedImages[index + 1].dateTime.toLocaleString('default', { month: 'short' })}_${verticallyRotatedImages[index + 1].dateTime.getUTCFullYear()}_${verticallyRotatedImages[index + 1].dateTime.getHours()}:${verticallyRotatedImages[index + 1].dateTime.getMinutes()}:${verticallyRotatedImages[index + 1].dateTime.getSeconds()}`
              ),
              fit: imageTxtFit[0],
              margin: imageTxtFit[1],
            },
            {
              alignment: 'center',
              image: verticallyRotatedImages[index + 1].url,
              fit: imageFit[0],
              margin: imageFit[1]
            },
          ],
        ],
      },
      margin: [0, 0, 0, 1],
    });

    // Default image boxes structure for the pdf...
    let subval = 0
    if (this.Fresh_Test_Data.testSettings.who == 3 || this.hasAddtionalText || this.Fresh_Test_Data.testingMode == 2) {
      PAGE_COUNT = 1
    }

    const defaultPdfImageStrategy = (index: number) => {
      console.log(index, PAGE_COUNT * MAX_ITEM_PER_PAGE);

      if (((index - subval) < PAGE_COUNT * MAX_ITEM_PER_PAGE) || (index == 0 && this.Fresh_Test_Data.testSettings.who == 4)) {
        groupedByPairImages.push(pdfImageColumnStructure(index));
      } else {
        PAGE_COUNT += 1
        if (this.Fresh_Test_Data.testSettings.who == 4 && !this.hasAddtionalText) {
          subval = 2
        }

        groupedByPairImages.push(
          {
            text: { text: `${this.checkandTranslate(this.translate.instant("test.report_continued"))} | ${this.checkandTranslate(this.translate.instant("patients.patient_id"))}: ${this.Patient_Info.patientid ?? this.Fresh_Test_Data.patientid} | ${this.checkandTranslate(this.translate.instant("test.test_date")).replace("|", "/")}: ${testDt}`, style: "reportContinueText" },
            pageBreak: "before",
            margin: [0, upperMargin, 0, 15]
          },
          pdfImageColumnStructure(index)
        );
      }
    };

    if (verticallyRotatedImages.length % 2 === 0) {
      for (let i = 0; i < verticallyRotatedImages.length; i += 2) {
        defaultPdfImageStrategy(i);
      }
    } else {
      for (let i = 0; i < verticallyRotatedImages.length; i += 2) {
        if (i === verticallyRotatedImages.length - 1) {
          let checkCondition: Boolean
          if (this.Fresh_Test_Data.testSettings.who == 4 && !this.hasAddtionalText) {
            checkCondition = await this.getSequence(verticallyRotatedImages.length).includes(verticallyRotatedImages.length)
          } else {
            checkCondition = verticallyRotatedImages.length % 4 === 1 ? true : false
          }
          if (checkCondition && verticallyRotatedImages.length > 1) {
            groupedByPairImages.push(
              {
                text: { text: `${this.checkandTranslate(this.translate.instant("test.report_continued"))} | ${this.checkandTranslate(this.translate.instant("patients.patient_id"))}: ${this.Patient_Info.patientid ?? this.Fresh_Test_Data.patientid} | ${this.checkandTranslate(this.translate.instant("test.test_date")).replace("|", "/")}: ${testDt}`, style: "reportContinueText" },
                pageBreak: "before",
                margin: [0, upperMargin, 0, 15]
              },
              {
                table: {
                  headerRows: 0,
                  widths: ["2.8%", "43.1%"],
                  heights: [150],
                  body: [
                    [
                      {
                        image: this.verticallyRotatedText(
                          `${_sampleId !== undefined ? _sampleId + '_' : ''}${verticallyRotatedImages[i].sampleType != undefined ? this.translate.instant(`visualization.${verticallyRotatedImages[i].sampleType}`).toUpperCase() : this.translate.instant("test.fresh").toUpperCase()}_IMG_${verticallyRotatedImages[i].dateTime.getUTCDate()}_${verticallyRotatedImages[i].dateTime.toLocaleString('default', { month: 'short' })}_${verticallyRotatedImages[i].dateTime.getUTCFullYear()}_${verticallyRotatedImages[i].dateTime.getHours()}:${verticallyRotatedImages[i].dateTime.getMinutes()}:${verticallyRotatedImages[i].dateTime.getSeconds()}`),
                        fit: imageTxtFit[0],
                        margin: imageTxtFit[1],
                      },
                      {
                        alignment: 'center',
                        image: verticallyRotatedImages[i].url,
                        fit: imageFit[0],
                        margin: imageFit[1]

                      },
                    ],
                  ],
                },
                margin: [0, 0, 0, 1],
              }
            );
          } else {
            groupedByPairImages.push({
              table: {
                headerRows: 0,
                widths: ["2.8%", "43.1%"],
                heights: [150],
                body: [
                  [
                    {
                      image: this.verticallyRotatedText(
                        `${_sampleId !== undefined ? _sampleId + '_' : ''}${verticallyRotatedImages[i].sampleType != undefined ? this.translate.instant(`visualization.${verticallyRotatedImages[i].sampleType}`).toUpperCase() : this.translate.instant("test.fresh").toUpperCase()}_IMG_${verticallyRotatedImages[i].dateTime.getUTCDate()}_${verticallyRotatedImages[i].dateTime.toLocaleString('default', { month: 'short' })}_${verticallyRotatedImages[i].dateTime.getUTCFullYear()}_${verticallyRotatedImages[i].dateTime.getHours()}:${verticallyRotatedImages[i].dateTime.getMinutes()}:${verticallyRotatedImages[i].dateTime.getSeconds()}`)
                      ,
                      fit: imageTxtFit[0],
                      margin: imageTxtFit[1],
                    },
                    {
                      alignment: 'center',
                      image: verticallyRotatedImages[i].url,
                      fit: imageFit[0],
                      margin: imageFit[1]
                    },
                  ],
                ],
              },
              margin: [0, 0, 0, 1],
            });
          }
        } else {
          defaultPdfImageStrategy(i);
        }
      }
    }
    console.log("UUUUUUUUUUUUUUUUUUUU", groupedByPairImages);
    return groupedByPairImages;
  }

  idtoImageURL(arrayImg) {
    return new Promise((res, rej) => {
      let allImages = arrayImg
      let allPromises: any = []
      allImages.forEach(element => {
        if (element.isattached == true) {
          allPromises.push(this.getImage(element.fileName, element.capturedDate, element.sampleType))
        }
      });
      Promise.all(allPromises).then((response: any) => {
        res(response)
      })
    })

  }

  getImage(filename, capturedDate, sampletype) {
    let datetime;
    try {
      datetime = capturedDate.toDate()
    } catch (error) {
      datetime = capturedDate
    }
    return new Promise((res, rej) => {
      this.http.getFileUrl(`Visualization/Images/${this.Fresh_Test_Data.facilityId}/${filename}`).then((url: any) => {
        res({ image: url, dateTime: datetime, sampleType: sampletype })
      }).catch(err => {
        console.log(err);
        res(undefined);
      })
    })
  }

  getimageURL(imageurls): Promise<any[]> {
    return new Promise((res, rej) => {
      let allImages = imageurls
      let allPromises: any = []
      allImages.forEach(element => {
        allPromises.push(this.drawImage(element.image, element.dateTime, element.sampleType))
      });
      Promise.all(allPromises).then((response: any[]) => {
        console.log(response);
        res(response.filter(el => el != undefined))
      }).catch(err => {
        console.log(err);

      })
    })
  }

  makeChartfromValues(freshTestData) {
    const test_Data = { ...freshTestData }

    const canvas = document.createElement("canvas");
    canvas.height = 685;
    canvas.width = 640;
    var echart1 = echarts.init(canvas);
    var data: any = [];
    var fSize = 24.5;
    var colors: any = [];
    let chartValues: any = {}
    if ((this.isManual && this.Fresh_Test_Data.testingMode != 2) || (this.Fresh_Test_Data.testingMode == 2 && this.displayAllParams)) {
      if (test_Data.testSettings.who == 3) {
        chartValues.progressivePR = test_Data.mprogressivePR
        chartValues.nonprogressiveNP = test_Data.mnon_progressive_np
        chartValues.immotility = test_Data.mimmotile
      } else {
        chartValues.rapidProgressiveA = test_Data.mrapidProgressiveA
        chartValues.slowProgressiveB = test_Data.mslowProgressiveB
        chartValues.immotility = test_Data.mimmotile
        chartValues.nonprogressiveNP = test_Data.mnon_progressive_np
        chartValues.progressivePR = test_Data.progressivePR

      }
    } else {
      if (test_Data.testSettings.who == 3) {
        chartValues.progressivePR = test_Data.progressivePR
        chartValues.nonprogressiveNP = test_Data.non_progressive_np
        chartValues.immotility = test_Data.immotile
      } else {
        chartValues.rapidProgressiveA = test_Data.rapidProgressiveA
        chartValues.slowProgressiveB = test_Data.slowProgressiveB
        chartValues.immotility = test_Data.immotile
        chartValues.nonprogressiveNP = test_Data.non_progressive_np
        chartValues.progressivePR = test_Data.progressivePR
      }
    }
    for (const key in chartValues) {
      const element = chartValues[key];
      if (element == undefined) {
        chartValues[key] = 0
        console.log("JJJJJJJJJJJj", chartValues[key], key);

      }
    }
    console.log("charttttttttttttttttttt", chartValues, this.isManual);

    if (test_Data.testSettings.who == 3) {
      if (Number(chartValues.progressivePR) >= 0 && Number(chartValues.nonprogressiveNP) >= 0 && Number(chartValues.immotility) >= 0) {
        this.isdisplaychart = true;
      } else {
        this.isdisplaychart = false;
      }

      data = [{
        name: this.translate.instant('test.prog_motility_pr'),
        value: chartValues.progressivePR.toFixed()
      }, {
        name: this.translate.instant('test.non_prog_motility_np'),
        value: chartValues.nonprogressiveNP.toFixed()
      }, {
        name: this.translate.instant('test.immotility'),
        value: chartValues.immotility.toFixed(),
      },];
      colors = ["#575B7A", "#DFC126", "#DE725C"];
    } else if (test_Data.testSettings.who == 4) {
      if (chartValues.rapidProgressiveA >= 0 && chartValues.slowProgressiveB >= 0 && chartValues.nonprogressiveNP >= 0 && chartValues.immotility >= 0) {
        this.isdisplaychart = true;
      } else {
        this.isdisplaychart = false;
      }
      console.log("chart values", this.isdisplaychart, chartValues.immotility, chartValues.rapidProgressiveA, chartValues.slowProgressiveB, chartValues.nonprogressiveNP);
      data = [{
        name: this.translate.instant('test.rapid_prog'),
        value: chartValues.rapidProgressiveA.toFixed()
      }, {
        name: this.translate.instant('test.slow_prog'),
        value: chartValues.slowProgressiveB.toFixed()
      }, {
        name: this.translate.instant('settings.non_progressive_pr'),
        value: chartValues.nonprogressiveNP.toFixed()
      }, {
        name: this.translate.instant('settings.immotile_who6'),
        value: chartValues.immotility.toFixed(),
      }];
      colors = ["#91cc75", "#575b7a", "#dfc126", "#de725c"];
    }
    var option = {

      chart: {
        size: {
          width: 500,
          height: 680
        }
      },
      color: colors,

      legend: {
        right: 'center',
        textStyle: {
          fontSize: fSize,
          // fontWeight:"bold",
          color: 'black'
        },

        top: '80%',

        // data: [
        //   "Immotility",
        //   "Progressive Motility",
        //   "Non Progressive Motility",
        // ]
      },
      series: [{

        type: 'pie',
        radius: '65%',
        center: ['50%', '40%'],
        data: data,
        name: data,
        animation: false,
        label: {
          position: 'outer',
          fontSize: 22,
          fontWeight: 'bold',
          alignTo: 'none',
          formatter: "({d}%)",
          color: 'black'
        }, labelLine: {
          show: true,
          length: 5,
          color: 'black'
        },
      }]
    };
    echart1.setOption(option);
    var canvas1 = canvas as HTMLCanvasElement
    this.chartURL = canvas1.toDataURL();
    echart1.dispose();
    console.log(this.chartURL, "JJJJ");
  }

  langNotToTranslate() {
    this.http.getCollectionData("System", "Languages").then((res) => {
      this.unTranslate = res.data.Exceptional
    })
  }

  getSequence(length) {
    let seq: any = []
    for (let index = 1; index < length + 1; index++) {
      seq.push(3 + (index - 1) * 4)
    }
    return seq
  }

  doIntensivecleaningCheck(allSelftests) {
    let selfTestArray = allSelftests
    interface checkingKeys {
      ref1: Number;
      ledCurrent1: Number;
      ref2: Number;
      ledCurrent2: Number;
      zeroLevel: Number;
      amplitude: Number;
    }

    if (selfTestArray.length >= 3) {
      const reversedArr = Array.from(selfTestArray).reverse();
      const mappedArray = reversedArr.map(({ ...element }: checkingKeys) => {
        return {
          ref1: element.ref1,
          ref2: element.ref2,
          ledCurrent1: element.ledCurrent1,
          ledCurrent2: element.ledCurrent2,
          zeroLevel: element.zeroLevel,
          amplitude: element.amplitude,
        }
      })
      const arr = []
      for (const key in mappedArray[0]) {
        for (let index = 0; index <= 2; index++) {
          arr.push({
            value: mappedArray[index][key],
            key: key
          });
          // const element2 = mappedArray[1][key];
          // const element3 = mappedArray[2][key];
        }
      }
      // arr.forEach(element => {
      this.passLevelCheck(arr).then(counter => {
        $("#intensive_cleaning").modal("show");
        this.selfTest_track = []
      })      // });

    }
  }

  passLevelCheck(array) {
    let counter = 0
    return new Promise((res, err) => {
      console.log(")))))))))))", array);

      array.forEach(element => {
        let key = element.key
        let value = element.value
        switch (key) {
          case "ref1":
            if (!(value >= 150 && value <= 350)) {
              counter++
              if (counter >= 3) {
                res(counter)
              }
            } else {
              counter = 0
            }
            break;

          case "ledCurrent1":
            if (!(value >= 5 && value <= 20)) {
              counter++
              if (counter >= 3) {
                res(counter)
              }
            } else {
              counter = 0
            }
            break;

          case "ref2":
            if (!(value >= 2500 && value <= 3500)) {
              counter++
              if (counter >= 3) {
                res(counter)
              }
            } else {
              counter = 0
            }
            break;

          case "ledCurrent2":
            if (!(value >= 10 && value <= 32)) {
              counter++
              if (counter >= 3) {
                res(counter)
              }
            } else {
              counter = 0
            }
            break;

          case "zeroLevel":
            if (!(value >= 500 && value <= 525)) {
              counter++
              if (counter >= 3) {
                res(counter)
              }
            } else {
              counter = 0
            }
            break;

          case "amplitude":
            if (!(value >= 50 && value <= 100)) {
              counter++
              if (counter >= 3) {
                res(counter)
              }
            } else {
              counter = 0
            }
            break;
        }
      });
      console.log(counter, "=================================");

    })

  }



  public get smallFont(): boolean {
    return (this.Fresh_Test_Data.testSettings.who == 3 || this.translate.currentLang != 'en') ? true : false
  }

  showNotification(colorName, text, placementFrom, placementAlign, animateEnter, animateExit) {
    if (colorName === null || colorName === '') { colorName = 'bg-black'; }
    if (text === null || text === '') { text = 'Turning standard Bootstrap alerts'; }
    if (animateEnter === null || animateEnter === '') { animateEnter = 'animated fadeInDown'; }
    if (animateExit === null || animateExit === '') { animateExit = 'animated fadeOutUp'; }
    var allowDismiss = true;
    $.notify({
      message: text
    },
      {
        type: colorName,
        allow_dismiss: allowDismiss,
        newest_on_top: true,
        timer: 1000,
        placement: {
          from: placementFrom,
          align: placementAlign
        },
        animate: {
          enter: animateEnter,
          exit: animateExit
        },
        template: '<div data-notify="container" class="bootstrap-notify-container alert alert-dismissible {0} ' + (allowDismiss ? "p-r-35" : "") + '" role="alert">' +
          '<span data-notify="icon"></span> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          '</div>' +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          '</div>'
      });
  }

  translateNreturn(text: string, hasUnit?: boolean, isUnit?: boolean, isLower?: boolean): string {
    let colon = ':'
    if (hasUnit)
      colon = ''
    if (text == ' ' || text == '')
      return text
    console.log(text);
    if (typeof this.translate.instant(text) == "string" && text.trim() != '')
      return this.unTranslate.includes(this.translate.currentLang) || isUnit ? `${this.translate.instant(text)}${colon}` : `${isLower ? this.translate.instant(text) : this.translate.instant(text).toUpperCase()}${colon}`
    else
      return text
  }

  /********************* Visualization Date format ********************/

  //Date Format
  // options = { year: '2-digit', month: 'short', day: '2-digit', hour:'2-digit' , minute:'2-digit'  };
  dateFormat(date: any) {
    // console.log(date);
    // console.log(date.toDate().toLocaleDateString("en-US", this.options).split(',').join('_'));
    // return date.toDate().toLocaleDateString("en-US", this.options).split(',').join('_')

    if (date != null) {
      const months = [
        "JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"
      ];

      const d: Date = date.toDate();

      if (Object.prototype.toString.call(d) === "[object Date]") {
        let month = months[d.getMonth()];
        let date1: any = d.getDate();
        let year = d.getFullYear().toString().substring(2, 4);
        let hour: any = d.getHours();
        let min: any = d.getMinutes();
        let sec: any = d.getSeconds();

        if (date1 < 10) date1 = "0" + date1;
        if (hour < 10) hour = "0" + hour;
        if (min < 10) min = "0" + min;
        if (sec < 10) sec = "0" + sec;

        return `${date1}_${month}_${year}_${hour}:${min}:${sec}`;

      }

      return "";

    }

    return "";

  }


  public get hasAddtionalText(): boolean {
    const addtionalText: additionalText = this.facilityHeader.testSettings.reportSettings.secondPage.additionalText

    return (this.facilityHeader.testSettings.who == 4 && (addtionalText != additionalText.none && addtionalText != undefined)) || this.facilityHeader.testSettings.reportSettings.reportType === 1
  }

  getSecondPageMargin(field: string) {
    const criteria = this.Fresh_Test_Data.testSettings.who
    let spacing;
    if (!this.facilityHeader.testSettings.reportSettings[criteria == 3 ? 'firstPage' : "secondPage"][field == 'headerSpace' ? 'reportHeader' : 'reportFooter']) {
      spacing = 5 * 2.83465
    } else {
      const secondPageMargin = Number(this.facilityHeader.testSettings.reportSettings[criteria == 3 ? 'firstPage' : "secondPage"][field])
      spacing = (secondPageMargin - Number(this.facilityHeader.testSettings.reportSettings.firstPage[field])) * 2.83465
      console.log("HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH", spacing);

    }
    //Header space in firstpage but not in second page
    if (!this.facilityHeader.testSettings.reportSettings[criteria == 3 ? 'firstPage' : "secondPage"][field == 'headerSpace' ? 'reportHeader' : 'reportFooter'] && this.facilityHeader.testSettings.reportSettings.firstPage[field == 'headerSpace' ? 'reportHeader' : 'reportFooter']) {
      spacing = -Number(this.facilityHeader.testSettings.reportSettings.firstPage[field] - 20) * 2.83465
    }

    return field == 'headerSpace' ? spacing : -spacing; //sending the negative marging because footer is handling by the negative header margin
  }

  public isLowQuality(testResults): boolean {
    const lowQuality = this.http.getLowQuality ?? { MSC: 2, TSC: 2, LV_MSC: 0.2 }
    !environment.production && console.warn(`%c LC cr: ${JSON.stringify(lowQuality)}`, "color: red; background-color: yellow");
    if (lowQuality.TSC === undefined || lowQuality.MSC === undefined) {
      lowQuality.TSC = this.http.lowQualityCriteria.TSC;
      lowQuality.MSC = this.http.lowQualityCriteria.MSC;
    }
    if (testResults.sampleInformation.testType != 3)
      return testResults.concentration < lowQuality.TSC || testResults.motileSpermConc < lowQuality.MSC
    else
      return testResults.motileSpermConc < lowQuality[testResults.testingMode == SelectedTest.ART_PREP ? "LV_MSC" : "MSC"]
  }

  handlePatientName(name: string) {
    if ((this.facilityHeader.testSettings.systemSettings as SystemSettings).isPrivacyMode == true)
      return ``
    if (name === "" || name === undefined || name === null)
      return "N/A"
    else
      return name
  }

  handleSampleandOtherInfo(name: string) {
    if (name === "" || name === undefined || name === null)
      return "N/A"
    else
      return name
  }

  getDate(date, onlyTime: boolean) {
    try {
      return formatDate(date.toDate(), `${onlyTime ? '' : this.pcDateFormat + ' | '}${this.pcTimeFormat}`, this.translate.currentLang)
    } catch (error) {
      return formatDate(date, `${onlyTime ? '' : this.pcDateFormat + ' | '}${this.pcTimeFormat}`, this.translate.currentLang)
    }
  }

  getParamsLengthInTables(array: String[], table: Number) {

    const isART_PREP_displayAll = () => {
      return this.Fresh_Test_Data.testSettings.testingMode == 2 && this.displayAllParams == true
    }
    console.log("OOOOOOOOOOOOOO", this.facilityHeader.contentLimitation.hideParameters);

    if (!this.facilityHeader.contentLimitation.hideParameters) return 0
    // if (this.Fresh_Test_Data.testSettings.who == 3) return 1
    const table1 = this.Fresh_Test_Data.testSettings.who == 3 ? [...!isART_PREP_displayAll() ? ["concentration", "totalMotilePRNP", "progressivePR", "non_progressive_np", "immotile", "normalForms", "motileSpermConc", "progMotileSpermConc", "motileSperm", "spermMotilityIndex", "spermCount"] : ["motileSpermConc", "progMotileSpermConc", "motileSperm", "spermMotilityIndex"]] : ["concentration", "totalMotilePRNP", "progressivePR", "rapidProgressiveA", "slowProgressiveB", "non_progressive_np",
      "immotile", "normalForms", "motileSpermConc", "progMotileSpermConc", "progMotileSpermConcA", "progMotileSpermConcB", "fsc", "spermMotilityIndex",
      "velocity"]
    const table2 = ["spermCount", "motileSperm", "totalPmsc", "totalFsc", "totalMorph"]
    const compare = (a1, a2) => a1.filter(v => a2.includes(v)).length
    if (table == 1) return compare(array, table1)
    else if (table == 2) return compare(array, table2)
  }


  public get removePhonenumber(): boolean {
    return this.facilityHeader.testSettings.systemSettings.removePatientPhone == true || this.facilityHeader.testSettings.systemSettings.isPrivacyMode == true

  }

}



