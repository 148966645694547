import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { resolve } from 'path';
import { BehaviorSubject, ReplaySubject, Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DefaultSettingsInterface } from '../shared/interface/defaultSettings.interface';
import { FacilitySettingsInterface } from '../shared/interface/facilitySettings.interface';
import { MediaDevice } from '../shared/interface/mediaDevice.interface';
import { getUSBDevicesByAPIInterface } from '../shared/interface/usbDevice.interface';
import { CommunicatorService, MyServiceEvent } from './communication.service';
import { GlobalService } from './global.service';
import { HttpService } from './http.service';
import { VideoRecordingService } from './video-recording.service';

@Injectable({
  providedIn: 'root'
})
export class MediaDeviceService {

  _serviceSubscription: Subscription;

  constructor(
    private _http: HttpService,
    private videoRecordingService: VideoRecordingService,
    private _glbalService: GlobalService,
    private _router: Router,
    private _comm: CommunicatorService,
  ) {     
    this.detectDeviceFn();
    
    // this.getFacilitySettings();


    this._comm.getDriverVersion().toPromise().then((data: any) => {

      let driverVersion = data.data;

      if (driverVersion == "201.01.02.02") {
        navigator.mediaDevices.ondevicechange = async event => {
          console.log("-------------ondevicechange------------");
          this.detectDevice$.next();
          const res = await this.getConnectedDevices('onchange');
          this.isVisualizationaDeviceConnected$.next(res);
        };
      }else{
        this.comEvents();
      }

    });
  }

  videoDevices$ = new Subject<MediaDevice[]>();
  selectedDevice$ = new Subject<MediaDevice | null>();

  videoDevices: MediaDevice[];
  selectedDevice: MediaDevice | null;

  defaultSettings: DefaultSettingsInterface;
  facilitySettings: FacilitySettingsInterface;

  previewData$ = new Subject<any>();

  detectDevice$ = new Subject<any>();
  isVisualizationaDeviceConnected$ = new ReplaySubject<any>();

  private _isVUConnected: boolean = false;
  isVUConnected$ = new BehaviorSubject<boolean>(false);

  get isVUConnected(){
    return this._isVUConnected;
  }

  emitIsVUConnected(){
    this.isVUConnected$.next(this._isVUConnected);
  }

  async detectDeviceFn() {

    // console.log("===================detectDeviceFn=====================");
    
    if (!this.defaultSettings || !this.facilitySettings) {

      Promise.all([this.getDefaultSettings(), this.getFacilitySettings()])
        .then(_ => this.getConnectedDevices('oninit'))
        .then(res => {
          console.warn('settings loaded', res);
          this.isVisualizationaDeviceConnected$.next(res);
        }).catch(err => {
          console.error(err);
          throw err;
        });
        
      } else {
        try {
          const res = await this.getConnectedDevices('oninit');
          console.warn('settings pendind...', res);
          this.isVisualizationaDeviceConnected$.next(res);
        } catch (error) {
          console.error('VIDEO STREAM ERROR', error);
          this.isVisualizationaDeviceConnected$.next(false);
          throw error;
        }
    }

  }


  comEvents(): void{
    this._serviceSubscription = this._comm.onChange.pipe(
      filter((event: MyServiceEvent) =>  this.isAllowedDevice(event))
    ).subscribe({
      next: async (event: MyServiceEvent) => {
        let res;        
        switch (event.command) {
          case "usbadd":
            res =  await this.getIsVUConnected();
            console.log("===================comEvents add ==============", res);
            this.deviceOberve(res);
            break;
          case "usbremove":
            res = await this.getIsVUConnected();
            console.log("===================comEvents remove ==============", res);
            this.deviceOberve(res);
            break;
        }
      }
    })
  }

  isAllowedDevice(event: MyServiceEvent){
    const allowedDevice = this.defaultSettings.data.CameraSettings.allowedDevices.filter(val => 
      val.vendorId == event.value.vendorId && val.productId == event.value.productId
    );

    return allowedDevice.length > 0  ? event.value : null;
  }

  deviceOberve(res: boolean){
    console.log(`%c MEDIA-S SQA-VU connection: ${res}`, "color: red; background-color: cyan");
    this.detectDevice$.next();
    this.isVisualizationaDeviceConnected$.next(res);
    this.getConnectedDevices('onchange');
  }

  getIsVUConnected(): Promise<boolean> {
    return new Promise((res, rej) => {
      this._comm.getusbDevicesByAPI().then(async (data: getUSBDevicesByAPIInterface) => {
       
        if(!this.defaultSettings){
          await this.getDefaultSettings();
        }

        // const { vendorId, productId } = this.defaultSettings.data.CameraSettings.allowedDevices[0];
        // const vuDevice = data.connected.find((val: any) => val.vendorId == vendorId && val.productId == productId );

        const vuDevices = this.defaultSettings.data.CameraSettings.allowedDevices
          .filter(
            val => data.connected
              .find(val1 => {
                return val.vendorId == val1.vendorId.toString() && val.productId == val1.productId.toString();
              }
            )
          );

        console.log("============= vuDevices ===============", vuDevices);

        if(vuDevices.length > 0){
          return res(true);
        }
        return res(false);
        
        
      }).catch(err => {
       
        rej(err);
      })
    })
  }


  getDefaultSettings(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http.getCollectionData("System", "defaultSettings").then(res => {
        this.defaultSettings = res;
        resolve(res);
      }).catch((err) => {
        reject(err);
      });
    })
  }


  getFacilitySettings(): Promise<void>{
    return new Promise((resolve, reject) => {
      this._http.facilityUpdates.subscribe(res =>{
        this.facilitySettings = res;
        resolve();
      });
    });
  }


  emitVideoDevices(videoDevices: MediaDevice[]){
    this.videoDevices = videoDevices;
    this.videoDevices$.next(videoDevices);
  }

  emitSelectedDevice(selectedDevice: MediaDevice){
    this.selectedDevice = selectedDevice;
    this.selectedDevice$.next(selectedDevice);
  }

  emitPreviewData(data){
    this.previewData$.next(data);
  }

  getDevicesOnChange(): Promise<MediaDevice[]>{
    return new Promise((resolve, reject)=> {

      navigator.mediaDevices.ondevicechange = event => {

        navigator.mediaDevices.enumerateDevices().then(async _devs => {

          // Filter Devices
          let devices = _devs.filter( device => device.kind == "videoinput" && device.deviceId != '');

          // Assign Device name
          devices = devices.map((device, index) => {
            device['name'] = device.label;
            return device;
          });
          
          resolve(devices);

        })
      }

    });
  }


  async getConnectedDevices(type: string): Promise<boolean>{

    return new Promise<boolean>(async (resolve, reject) => {
      
      // Get connected Devices
      let devices = await this.enumerateDevices();

      if(type === "onchange"){
        devices = await this.getDevicesOnChange();
      }
      
      // If no device, try to get video permission
      if(devices.length == 0) {
        if(type == "oninit"){
          try {
            await navigator.mediaDevices.getUserMedia({video: true, audio: false});
            devices = await this.enumerateDevices();
          } catch (error) {
            reject(error);
          }
        }
      }

      this.videoDevices = devices;
      console.log(this.videoDevices);


      if(this.videoDevices.length > 0) {

        const facilityDeviceLabel: string[] = this.facilitySettings.CameraSettings.allowedDevices.reduce((x: string[], y: any) => {
          x.push(y.label.toLowerCase());
          return x;
        },[]);

        const filteredVideoDevice = this.videoDevices.filter((val: MediaDevice)=> {
          return facilityDeviceLabel.includes(val.label.toLowerCase());
        });
        console.log('SQA-VU ', filteredVideoDevice);
        
        if(filteredVideoDevice[0]){
          filteredVideoDevice[0].name = "SQA-VU";
          this.selectedDevice = filteredVideoDevice[0];
          this._isVUConnected = true;
        }else{
          this._isVUConnected = false;
        }

        this.emitIsVUConnected();

        if(!this.facilitySettings.contentLimitation.allowMultipleDevices){
          // Emit video devices -> Device settings component
          this.emitVideoDevices(filteredVideoDevice);
        }else{
          // Emit video devices -> Device settings component
          this.emitVideoDevices(this.videoDevices);
        }

        
        if(this.selectedDevice){
          this.emitSelectedDevice(this.selectedDevice);
          return resolve(true);
        }else{
          
          // If multiple devices not allowed
          if(!this.facilitySettings.contentLimitation.allowMultipleDevices){
            return resolve(false);
          }
          
          // If multiple devices allowed
          if(this.videoDevices[0]){
            this.emitSelectedDevice(this.videoDevices[0]);
            return resolve(true);
          }

          return resolve(false);
        }

      }else{
        return resolve(false);
      }

    });

  }



  enumerateDevices(): Promise<MediaDevice[] | []>{

    return new Promise(async (resolve, reject) => {

      // Get all devices
      let devices: MediaDeviceInfo[] = await navigator.mediaDevices.enumerateDevices();
      
      // Filter Devices
      devices = devices.filter( device => device.kind == "videoinput" && device.deviceId != '');

      // Assign Device name
      devices = devices.map((device,index) => {
        device['name'] = device.label;
        return device;
      });
      
      resolve(devices);

    });
    
  }
  

  startStreaming(): Promise<boolean>{  
    
    return new Promise((resolve, reject) => {

      if(this.selectedDevice){

        let videoConf;

        const deviceId = this.selectedDevice.deviceId; 

        // Getting video properties
        const videoConstraints = this.facilitySettings.CameraSettings.allowedDevices.filter( allowedDevice => allowedDevice.label == this.selectedDevice.label);

        // Adding video properties
        if(videoConstraints[0]){

          videoConf = {
            audio: false,
            video: {
              width: videoConstraints[0].videoSettings.width,
              height: videoConstraints[0].videoSettings.height,
              frameRate: videoConstraints[0].videoSettings.frameRate,
              deviceId: {
                exact: deviceId
              }
            }
          }

        }else {

          videoConf = {
            audio: false,
            video: {
              deviceId: {
                exact: deviceId
              }
            }
          }

        }
        
        if( videoConf.video.deviceId ){

          // Saving Camera label in global service
          // let label = this.videoDevices.filter(device => device.deviceId == deviceId).map( val=> val.label);
          // if(label[0]){
          //   this._glbalService.cameraLabel = label[0];
          // }

          // Saving Camera label in global service
          this._glbalService.cameraLabel = this.selectedDevice.label;

          this.videoRecordingService.startStreaming(videoConf)
          .then(async _ => {
            const field = this._router.url.includes('morphology')?`morphologySettings`:`motilitySettings`
            // if(this.facilitySettings.cameraSettings){
              try{
                await this.videoRecordingService.processTracks(this.facilitySettings.cameraSettings?this.facilitySettings.cameraSettings[field]:undefined);
                resolve(true);
              }catch(err){
                resolve(false);
              }
            // }
          })
          .catch( err =>{
            console.log(err.name + ": " + err.message);
            resolve(false);
          });

        }

      }else{
        resolve(false);
      }

    });  

  }

  async stopStreaming(): Promise<boolean> {
    return await this.videoRecordingService.stopStreaming();
  }

}
