import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { CommunicatorService } from '../services/communication.service';
import { GlobalService } from '../services/global.service';
import { HttpService } from '../services/http.service';
import { ReportsService } from '../services/reports/reports.service';
import { AuthService } from '../core/auth.service';
declare const $: any;

@Injectable({
  providedIn: 'root'
})
export class ResultsHeaderService {
  isDownload: boolean;
  _testCreditSubscription: Subscription;
  userdata: any = {};
  enableSave: boolean;
  testID: any
  deviceconnect = 0;
  creditcount: number
  isRetest: boolean = false;
  enteringVisualization: boolean = false
  isRetestClicked: boolean = false
  testCount = new Subject();
  anyChanges = new Subject();  
                           

  constructor(private _glob: GlobalService, private _reports: ReportsService,private authService: AuthService, private _http: HttpService, private Comm: CommunicatorService) {
    // this._testCreditSubscription = this._http.facilityUpdates.subscribe((fUpdate) => {
      
    // })
    this._testCreditSubscription = authService.user.subscribe((user) => {
      this.userdata = user;
      
      
    })
  }

  async printReport(isDownload) {
    this._http.getFacility(this.userdata).then(f => {
      // this._glob.facilityHeader = f.data
      this._glob.criteria = f.data.testSettings.who;
      // this._glob.Report(isDownload)
      console.log(f.data.testSettings,isDownload,"=========================================================");
      
      if (f.data.testSettings.reportSettings.reportType == 1) {
        
        this._reports.onePageReport({ ...this._glob.Fresh_Test_Data, ...this._glob.Patient_Info }, this._glob.facilityHeader, isDownload,this._glob.Fresh_Test_Data.testingMode==2?this._glob.ART_PREP_Results:undefined)
      }else if(f.data.testSettings.reportSettings.reportType == 2){
        
        this._glob.Report(isDownload)
      }
      else if (f.data.testSettings.reportSettings.reportType == 3) {
        const reportFileName = f.data.testSettings.reportSettings.selectedFecitlityHtmlFile;
        const reportData = this._glob.switchdisplayValues(this._glob.Fresh_Test_Data);
        this._reports.getFlexibleReport(reportData, reportFileName, isDownload);
      }
    })


  }

  updateconnectionstatus() {
    if (this.Comm.SocketArray.length == 0 || !this.Comm.IsDriverConnected()) {
      this.deviceconnect = 0;
    } else {
      this.deviceconnect = 1;
    }
    return this.deviceconnect
  }

  Retest() {
    console.log("Came here..............111111111111111111111");
    
    // this._glob.hasUnsavedChanges = true;
    this._glob.reTest = true;
    this._glob.fromtestresults = true;
    // this.router.navigate(['../test-process'], { relativeTo: this.route });
    this._glob.fromDataentry = false;
  }
  onCompDestroy() {
    if (this.isRetestClicked == false) {
      this._glob.reTest = false;
      this._glob.fromtestresults = false;
    }
  }

}
