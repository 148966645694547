import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { PopupData } from 'src/app/globals/globals';
import { CommunicatorService } from 'src/app/services/communication.service';
import { GlobalService } from 'src/app/services/global.service';

declare const $: any;

@Component({
  selector: 'app-exit-popup',
  templateUrl: './exit-popup.component.html',
  styleUrls: ['./exit-popup.component.sass'],
})
export class ExitPopupComponent implements OnInit, OnDestroy {
  popupData: PopupData;
  exitPopupDataSubscription: Subscription;
  saveBtnLoading = false;
  saveBtnSubscription: Subscription;
  // isDeviceDisconnected:boolean=false;

  constructor(
    private globalService: GlobalService,
    private cdr: ChangeDetectorRef,
    private Comm: CommunicatorService
  ) {}

  ngOnInit(): void {
    this.exitPopupDataSubscription =
      this.globalService.exitPopupData$.subscribe((result) => {
        if (result) {
          console.log(result);
          this.popupData = result;
          if ((this.Comm.SocketArray.length == 0 || !this.Comm.IsDriverConnected()) && this.popupData.showPopup) {
            // console.log('outttttttttttttttttttttttt')
            // this.popupData.buttons[0].disableBtn = true;
          }
          this.cdr.detectChanges();
          if (result.showPopup) {
            $('#unsaved-guard-modal').modal('show');
          }
          if (!result.showPopup) {
            $('#unsaved-guard-modal').modal('hide');
          }
        }
      });

    // Save btn loading state...
    this.saveBtnSubscription = this.globalService.exitSaveLoading$.subscribe(
      (result) => {
        this.saveBtnLoading = result;
      }
    );
  }

  onClickBtn(eventName: string) {
    if (this.saveBtnLoading) return;
    if (this.popupData && this.popupData?.accessedFromHeader) {
      this.globalService.exitPopupBtnClicked$.next(`${eventName}-logout`);
    } else {
      this.globalService.exitPopupBtnClicked$.next(eventName);
    }
  }

  ngOnDestroy(): void {
    this.exitPopupDataSubscription.unsubscribe();
    this.saveBtnSubscription.unsubscribe();
  }
  j;
}
