import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule,FormsModule } from "@angular/forms";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TranslateModule } from "@ngx-translate/core";
import { PipesModule } from "../pipes/pipes.module";

import { ExitPopupComponent } from "./components/exit-popup/exit-popup.component";
import { HeaderButtonsComponent } from "./components/header-buttons/header-buttons.component";
import { ResultsHeaderComponent } from "./components/results-header/results-header.component";
import { BredCrumComponent } from './components/bred-crum/bred-crum.component';
import { RouterModule } from "@angular/router";
import { VuVideoComponent } from "./components/vuVideo/vuVideo.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatChipsModule } from "@angular/material/chips";
import { TestProcessMaskDirective } from "../directives/test-process-mask.directive";
import { VideoSettingsComponent } from './components/video-settings/video-settings.component';
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from '@angular/material/tabs'

import { MatSliderModule } from '@angular/material/slider';
import { SelectVideoDeviceComponent } from './components/select-video-device/select-video-device.component'
import { VideoRecordingService } from "../services/video-recording.service";
import { MediaDeviceService } from "../services/media-device.service";
import { CustomPaginatorComponent } from './components/custom-paginator/custom-paginator.component';
import { MatButtonModule } from "@angular/material/button";
import { FlexibleReportPopupComponent } from './components/flexible-report-popup/flexible-report-popup.component';
import { NoSpermSeenCheckboxComponent } from './components/no-sperm-seen-checkbox/no-sperm-seen-checkbox.component';
import { OtpVerificationComponent } from './components/otp-verification/otp-verification.component';


@NgModule({
    
    declarations: [
        HeaderButtonsComponent, 
        ResultsHeaderComponent, 
        ExitPopupComponent, 
        VuVideoComponent, 
        BredCrumComponent, 
        VideoSettingsComponent, 
        SelectVideoDeviceComponent, 
        CustomPaginatorComponent, 
        FlexibleReportPopupComponent, 
        NoSpermSeenCheckboxComponent, 
        OtpVerificationComponent,
    ],
    imports: [
        CommonModule, 
        TranslateModule, 
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatButtonModule,
        FormsModule,
        ReactiveFormsModule,
        PipesModule,
        RouterModule,
        MatFormFieldModule,
        MatChipsModule,
        MatSelectModule,
        MatTabsModule,
        MatSliderModule
    ],
    exports: [
        HeaderButtonsComponent, 
        ResultsHeaderComponent, 
        VuVideoComponent, 
        ExitPopupComponent, 
        BredCrumComponent,
        VideoSettingsComponent, 
        CustomPaginatorComponent,
        FlexibleReportPopupComponent,
        NoSpermSeenCheckboxComponent,
        OtpVerificationComponent
    ],
    providers:[
        MediaDeviceService, 
        VideoRecordingService
    ]
})
export class SharedModule {}