import { AbstractControl } from '@angular/forms';

export function passwordStrengthValidator(control: AbstractControl): { [key: string]: boolean } | null {

    const passwordString = control.value;
    const charCheckPattern = /^(?=.*[!@#$%^&*()_\-+=\[\]{}<>`:;,'".~?|]).+$/;
    const letterCheckPattern = /^(?=(.*[a-zA-Z])).+$/;
    const digitCheckPattern = /^(?=.*\d).+$/;
    let _errorCount: number = 0;

    let results: any = {
        noCharacters: !charCheckPattern.test(passwordString),
        noLetters: !letterCheckPattern.test(passwordString),
        noDigits: !digitCheckPattern.test(passwordString),
    }

    if (results.noCharacters === true) _errorCount++;
    if (results.noLetters === true) _errorCount++;
    if (results.noDigits === true) _errorCount++;

    results = {...results, errorCount: _errorCount};

    if ((!results.noCharacters && !results.noDigits && !results.noLetters) || (passwordString == null || undefined) || passwordString.length == 0) {
        return null;
    } else {
        return results;
    }
}


