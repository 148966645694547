import { GlobalService } from 'src/app/services/global.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'custompipe' })
export class Custompipe implements PipeTransform {
  constructor(private translate?: TranslateService,) { }
  // adding a default value in case you don't want to pass the format then 'yyyy-MM-dd' will be used
  transform(date: Date | string, format: string = 'yyyy-MMM-dd', Language: string, isBirthDate?: boolean): string {
    // console.log("===Custompipe===", date, Language);

    try {
      // check data is pending translate the word 
      if (date == "pending") this.translate.instant("qc-controls.pending");

      var datetoTranslate = new Date(date);  // if orginal type was a string 

      // date.setDate(date.getDate() - day);
      if (isBirthDate === true) {
        return new DatePipe(Language).transform(datetoTranslate, format, 'UTC')
      } else {
        return new DatePipe(Language).transform(datetoTranslate, format);
      }


    } catch (error) {
      if (date !== undefined) {
        if (date == "pending") {
          return this.translate.instant("qc-controls.pending")
        } else {
          return date.toString();
        }
      }
    }

  }
}
@Pipe({ name: 'parameters' })
export class Parameterspipe implements PipeTransform {
  transform(parametetWithUnit: string, Language: string, langList: string[]): string {
    let parameter;
    try {
      if (langList.includes(Language)) {
        return parametetWithUnit
      } else {
        if (parametetWithUnit.includes("(")) {
          if (parametetWithUnit.split("(").length == 2) {
            parameter = parametetWithUnit.substring(0, parametetWithUnit.indexOf('(')).toUpperCase() + parametetWithUnit.substring(parametetWithUnit.indexOf('('), parametetWithUnit.length)
          } else {
            let pos = parametetWithUnit.split("(").length - 1
            parameter = parametetWithUnit.substring(0, this.getPosition(parametetWithUnit, "(", pos)).toUpperCase() + parametetWithUnit.substring(this.getPosition(parametetWithUnit, "(", pos), parametetWithUnit.length)
          }
        } else {
          parameter = parametetWithUnit.toUpperCase()
        }
        return parameter;
      }
    } catch (error) {
      return error.toString();
    }

  }
  getPosition(string, subString, index) {
    return string.split(subString, index).join(subString).length;
  }
}

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) { }
  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}

@Pipe({ name: 'customDecimal' })
export class CustomDecimalPipe implements PipeTransform {
  constructor() { }
  transform(value: any, decimalSeparator?: any): any {
    //let separator = this.global.digitSeparator;
    let userLang = navigator.language;
    if (decimalSeparator) {
      if (decimalSeparator === '.') userLang = 'en-US';
      else if (decimalSeparator === ',') userLang = 'de-DE';
    }
    // else if(separator !== '' && separator !== undefined) {
    //   if(separator === 'dot') userLang = 'en-US';
    //   else if (separator === 'comma') userLang = 'de-DE';
    // }
    return new Intl.NumberFormat(userLang, { maximumFractionDigits: 4 }).format(value);
  }
}


@NgModule({
  declarations: [Custompipe, SafeHtmlPipe, CustomDecimalPipe, Parameterspipe],
  imports: [
    CommonModule
  ],
  exports: [Custompipe, SafeHtmlPipe, CustomDecimalPipe, Parameterspipe]
})
export class PipesModule { }
