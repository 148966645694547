import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/auth.guard';

const routes: Routes = [
    {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard]
    },
   
    {
        path: 'sementest',
        loadChildren: () => import('./sementest/sementest.module').then(m => m.SemenTestModule),
        canActivate: [AuthGuard]
    },
       
    {
        path: 'patient',
        loadChildren: () => import('./patient/patient.module').then(m => m.PatientModule),
        canActivate: [AuthGuard]
    },

    {
        path: 'support',
        loadChildren: () => import('./support/support.module').then(m => m.SupportModule),
        canActivate: [AuthGuard]
    },

    {
        path: 'installation',
        loadChildren: () => import('./installation/installation.module').then(m => m.InstallationModule),
        canActivate: [AuthGuard]
    },

    {
        path: 'notifications',
        loadChildren: () => import('./notifications/notifications.module').then(m => m.NotificationsModule),
        canActivate: [AuthGuard]
    },

    {
        path: 'authentication',
        loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
    },
    {
        path: '',
        redirectTo: 'authentication',
        pathMatch: 'full'
    },
    {
        path: '**',
        redirectTo: 'authentication/page404',
        pathMatch: 'full'
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
