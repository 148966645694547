import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, Renderer2, SimpleChange, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DynamicScriptLoaderService } from 'src/app/services/dynamic-script-loader.service';
import { GlobalService } from 'src/app/services/global.service';


import ResizeObserver from 'resize-observer-polyfill';
import { HttpService } from 'src/app/services/http.service';
import { VideoRecordingService } from 'src/app/services/video-recording.service';
import { Subscription } from 'rxjs';
// import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FacilitySettingsInterface } from '../../interface/facilitySettings.interface';
import { MediaDevice } from '../../interface/mediaDevice.interface';
import { MediaDeviceService } from 'src/app/services/media-device.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';


declare const $: any;


interface Args {
  hideControls?: boolean;
  showFullScreenBtn: boolean;
  showZoomBtn: boolean;
  showRealTimeBtn?: boolean;
  showStreamPlayFreezeBtn?: boolean;
  showPreviewPlayFreezeBtn?: boolean;
  showBackBtn: boolean;
  isFullScreen: boolean;
  isGrid: boolean;
  isFreezed: boolean;
  isZoom: boolean;
  showRealtimeText?: boolean;
  height?: string;
  isStreaming: boolean;
  mainvideodivHeight?: string;
  mainVideoFooterHeight?: string;
  rightSideHeight?: string;
  showCameraSettings: boolean;
  showVideoBottomText: boolean;
}


@Component({
  selector: 'app-vuVideo',
  templateUrl: './vuVideo.component.html',
  styleUrls: ['./vuVideo.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VuVideoComponent implements OnInit, AfterViewInit, OnDestroy {

  gridlines: any = "";
  isStreaming: boolean = false;
  isVideoRecording: boolean = false;
  videoDevices: MediaDevice[] = [];
  currentTime;

  isStreamPaused: boolean = false;

  selectedDevice: MediaDevice | null;

  facilitySettings: FacilitySettingsInterface;
  videoHeight: string;

  previewData: any;
  previewVideoStatus: 'playing' | 'paused' = null;

  @Input() displaynote: boolean
  @Output() backtoDataentry = new EventEmitter<string>();
  @Input() args: Args;
  @Output() argsStatus = new EventEmitter<Args>();


  @ViewChild('mainvideodiv', { static: false }) mainvideodiv: ElementRef;
  @ViewChild('mainvideoFooter', { static: false }) mainvideoFooter: ElementRef;
  @ViewChild('leftsideElement', { static: false }) leftsideElement: ElementRef;
  @ViewChild('streamingVideoElement', { static: false }) streamingVideoElement: ElementRef;
  @ViewChild('canvas', { static: true }) canvas: any;

  //Freeze Image
  @ViewChild('freezeElement', { static: false }) freezeElement: ElementRef;

  //Magnifier
  @ViewChild('zoomElement', { static: false }) zoomElement: ElementRef;

  @ViewChild('previewImageElement', { static: false }) previewImageElement: ElementRef;

  @ViewChild('previewVideoElement', { static: false }) previewVideoElement: ElementRef;

  instructionItems: string[] = [
    'visualization.vu_instructions_1',
    'visualization.vu_instructions_2',
    'visualization.vu_instructions_3',
  ];

  detectDeviceObservable: Subscription;

  getStreamingTimeSubscription: Subscription;
  getStreamSubscription: Subscription;

  _selectedDeviceSubsciption: Subscription;

  _previewDataSubscription: Subscription;

  _VideoSettingNavigation: Subscription;

  obs: ResizeObserver;

  isStreamingPlaying: boolean = false;

  constructor(
    private videoRecordingService: VideoRecordingService,
    private changeDetector: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private dynamicScriptLoader: DynamicScriptLoaderService,
    public _glbalService: GlobalService,
    private _http: HttpService,
    private _mediaDeviceService: MediaDeviceService,
    public translate: TranslateService,
    private _renderer: Renderer2,
    private router: Router
  ) {
    this.subscriptions();

    if (this.router.url == '/sementest/motility-estimation') {
      this.instructionItems.push(null, 'visualization.vu_instructions_art');
    }

    this.videoSettingNavigate();


  }

  //videoSettingNavigate for control backtoDataentry
  videoSettingNavigate() {
    this._VideoSettingNavigation = this._glbalService.videoSettingNavigation.subscribe((res: any) => {

      //incase user click close button the res[0] will be false that time we have to stop this process
      if (res[0]) {
        if (document.fullscreenElement !== null) {
          document.exitFullscreen().then(() => {
            this._glbalService.fullScreenClicked$.next(false);
          });
        }
        this.backtoDataentry.emit()
      }
    });
  }


  subscriptions() {
    // Detect Device
    this.detectDeviceObservable = this._mediaDeviceService.detectDevice$.subscribe(devices => {
      this._mediaDeviceService.emitSelectedDevice(null);
      this.onConnectedDeviceChange();
    });

    // Stream Time
    this.getStreamingTimeSubscription = this.videoRecordingService.getStreamingTime().subscribe((time) => {
      this.changeDetector.detectChanges();
    });

    // Video Stream
    this.getStreamSubscription = this.videoRecordingService.getStream().subscribe((stream) => {

      this.isStreaming = true;
      this.changeDetector.detectChanges();

      setTimeout(() => {
        const video: HTMLVideoElement = this.streamingVideoElement?.nativeElement;
        video.srcObject = stream;
        video.onloadedmetadata = () => video.play();
      }, 10);

    });

    // Selected device
    this._selectedDeviceSubsciption = this._mediaDeviceService.selectedDevice$.subscribe(res => {
      // console.log(res);
      this.selectedDevice = res;
      this.restartStreaming();
    })

    this._previewDataSubscription = this._mediaDeviceService.previewData$.subscribe(res => {

      this.previewData = res;
      if (this.previewData) {
        // For capture screen -> switching buttons
        this.args.showStreamPlayFreezeBtn = false;
        this.args.showPreviewPlayFreezeBtn = true;
        this.emitArgs();

        if (this.previewData.data.extension == 'mp4') {
          this.previewVideoListen();
        }
      } else {
        this.args.showStreamPlayFreezeBtn = true;
        this.args.showPreviewPlayFreezeBtn = false;
      }
    })

  }



  ngOnInit(): void {
    // this.resize$
    // .pipe(
    //   debounceTime(200),
    //   distinctUntilChanged(),
    // )
    // .subscribe(x => {
    //   this.videoorimageHeight();
    // });

  }


  ngOnChanges(changes: SimpleChanges): void {

    this.args = changes.args.currentValue;

    if (changes.args.previousValue) {
      if (changes.args.currentValue.isFreezed != changes.args.previousValue.isFreezed) {
        if (changes.args.currentValue.isFreezed) {
          this.freezeStreamingVideo();
        } else if (!changes.args.currentValue.isFreezed) {
          this.playStreamingVideo();
        }
      }
    }

    if (changes.args.currentValue.isZoom) {
      this.zoomOn();
    } else if (!changes.args.currentValue.isZoom) {
      this.zoomOff();
    }

    if (changes.args.currentValue.isGrid) {
      this.generategridLines();
    }

  }

  get mainVideoDivHeight(): string {
    if (this.mainvideodiv) {
      return (this.mainvideodiv.nativeElement.offsetWidth / 1.33) + 'px';
    }
    return 'auto';
  }



  async ngAfterViewInit() {

    this.startScript();

    //Responsive Observer for Grid Lines
    this.obs = new ResizeObserver(entries => {
      if (this.args.isGrid) this.generategridLines();
      this.changeDetector.detectChanges();

      //Measure the video height
      if (this.isStreaming) {
        this.videoHeight = `calc((${this.mainvideodiv.nativeElement.offsetWidth}px / 4) * ${3})`;
        this.args.mainvideodivHeight = `${this.mainvideodiv.nativeElement.offsetHeight}px`;
        this.args.mainVideoFooterHeight = `calc((${this.mainvideoFooter.nativeElement.offsetHeight}px + 6px))`;
      } else {
        this.args.mainvideodivHeight = this.mainvideodiv.nativeElement.offsetHeight + 'px';
        this.args.mainVideoFooterHeight = this.mainvideoFooter.nativeElement.offsetHeight + 'px';
      }

      this.args.rightSideHeight = this.leftsideElement.nativeElement.offsetHeight + 'px';

      this.emitArgs();
      // this.resize$.next(entries);
    });

    this.obs.observe(this.leftsideElement.nativeElement);
    // this.obs.observe(this.mainvideodiv.nativeElement);

    this.getFacilitySettings()
      .then(_ => this._mediaDeviceService.getConnectedDevices('oninit'))
      .then(_ => this._mediaDeviceService.startStreaming())
      .then(status => {
        this.setStreamingStatus = status; this.isStreamPaused = false;
        if (status) {
          // For capture screen -> switching buttons
          this.args.showStreamPlayFreezeBtn = true;
          this.args.showPreviewPlayFreezeBtn = false;
          this.emitArgs();
        }
      })
      .catch(err => {
        console.log(err);
      })

  }


  async startScript() {
    await this.dynamicScriptLoader.load('form.min', 'bootstrap-colorpicker').then(data => {
      $('select').formSelect();
    }).catch(error => console.log(error));
  }


  // Get the streaming video height
  get getVideoHeight(): string {
    return this.videoHeight;
  }


  getFacilitySettings(): Promise<void> {
    return new Promise((resolve, reject) => {
      this._http.facilityUpdates.subscribe(res => {
        this.facilitySettings = res;
        resolve();
      });
    });
  }

  // Stop streaming
  stopStreaming(): void {
    if (this.isStreaming) {
      this.setStreamingStatus = false;
      this.videoRecordingService.stopStreaming();
    }
    this.args.isFreezed = false;
    this.args.isZoom = false;
    this.isStreamPaused = false;
    this.emitArgs();
  }

  // Change streaming device
  async restartStreaming(): Promise<void> {
    this.stopStreaming();
    this.setStreamingStatus = await this._mediaDeviceService.startStreaming();
    this.isStreamPaused = false;
  }

  //On Reconnecting Device
  async onConnectedDeviceChange(): Promise<void> {
    await this._mediaDeviceService.getConnectedDevices('onchange');
    this.restartStreaming();
  }


  // Change streaming status
  set setStreamingStatus(status: boolean) {
    this.isStreaming = status;
    if (this.args.isStreaming !== status) {
      this.args.isStreaming = status;
      this.emitArgs();
    }
  }

  //Pass video/image height
  // videoorimageHeight(){
  // this.args.height = (this.mainvideodiv.nativeElement.offsetHeight)+'px';
  // this.argsStatus.emit(this.args);
  // }


  fullScreen(): void {
    this.args.isFullScreen = !this.args.isFullScreen;
    this.browserFullScreenFn();
  }

  // Realtime text bottom of the video
  get realTimeTextVisibility(): 'block' | 'none' {
    if (this.isStreaming && !this.isStreamPaused && this.args?.showRealtimeText && !this.previewData) {
      return 'block';
    } else {
      return 'none';
    }
  }


  showCameraSettings(): void {
    this.args.showCameraSettings = true;
    this.emitArgs();
  }


  getSampleType(data): string {
    if (data.data.sampleType) {
      return this.translate.instant(`visualization.${data.data.sampleType}`).toUpperCase();
    } else {
      return this.translate.instant(`visualization.fresh`).toUpperCase();
    }
  }

  get prepText(): string {
    if (this._glbalService.Fresh_Test_Data.testingMode == 2) {
      if (this._glbalService.Fresh_Test_Data.prepType == 0) {
        return this.translate.instant('test_process.pre_prep');
      } else if (this._glbalService.Fresh_Test_Data.prepType == 1) {
        return this.translate.instant('test_process.post_prep');
      }
      return ''
    }
    return '';
  }

  get previewDataFileName(): string {

    const isSampleId = this._glbalService.Fresh_Test_Data.sampleInformation.sampleId;

    const sampleId = isSampleId ? isSampleId + '_' : '';

    const prepText = this.prepText != '' ? this.prepText + '_' : '';

    return `${sampleId}${prepText}${this.getSampleType(this.previewData)}`;
  }

  ngOnDestroy(): void {

    if (this.streamingVideoElement) {
      this.videoRecordingService.stopRecording();
      this.stopStreaming();
    }
    this.detectDeviceObservable.unsubscribe();

    if (this.getStreamingTimeSubscription) this.getStreamingTimeSubscription.unsubscribe();
    if (this.getStreamSubscription) this.getStreamSubscription.unsubscribe();

    if (this._selectedDeviceSubsciption) this._selectedDeviceSubsciption.unsubscribe();

    if (this._previewDataSubscription) this._previewDataSubscription.unsubscribe();

    if (this.obs) {
      this.obs.disconnect();
    }
    this._VideoSettingNavigation.unsubscribe();
  }


  //************************* Grid ********************************
  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    if (this.args.isGrid) this.generategridLines();
  }

  gridToggle(): void {
    this.args.isGrid = !this.args.isGrid;
    this.emitArgs();
    if (this.args.isGrid) this.generategridLines();
  }

  generategridLines(): void {

    this.changeDetector.detectChanges();

    this.gridlines = "";
    let gridline = "";

    //Check Element Available or Not
    let element = this.streamingVideoElement;

    if (this.streamingVideoElement) {
      element = this.streamingVideoElement;
    } else if (this.freezeElement) {
      element = this.freezeElement;
    } else if (this.previewImageElement) {
      element = this.previewImageElement
    } else if (this.previewVideoElement) {
      element = this.previewVideoElement;
    }

    const verticalLineWidth = element.nativeElement.offsetWidth;
    const verticalLineHeight = element.nativeElement.offsetHeight;

    for (let i = 1; i <= 3; i++) {
      let widthMeasure = element.nativeElement.offsetWidth / 5;
      let heightMeasure = (element.nativeElement.offsetHeight) / 4;

      // ${verticalLineWidth}px

      gridline += `<div style="top:${(heightMeasure * i) + 5}px; width: ${verticalLineWidth}px" class="hr-gridline"></div>`;
      gridline += `<div style="height:${verticalLineHeight}px; left:${(widthMeasure * i)}px" class="vr-gridline"></div>`;
      if (i === 3) {
        gridline += `<div style="height:${verticalLineHeight}px; left:${(widthMeasure * ++i)}px" class="vr-gridline"></div>`;
      }
    }

    this.gridlines = this.sanitizer.bypassSecurityTrustHtml(gridline);

  }


  freezeVideo(): void {
    if (this.previewVideoElement) {
      const videoElem = this.previewVideoElement.nativeElement as HTMLVideoElement;
      videoElem.pause();
    }
  }


  playVideo(): void {
    if (this.previewVideoElement) {
      const videoElem = this.previewVideoElement.nativeElement as HTMLVideoElement;
      videoElem.play();
    }
  }


  async previewVideoListen() {

    this.previewVideoStatus = null;

    this.changeDetector.detectChanges();

    //Delay
    if (!this.previewVideoElement) await this.delay();

    this._renderer.listen(this.previewVideoElement.nativeElement, 'playing', () => {
      this.previewVideoStatus = 'playing';
    });

    this._renderer.listen(this.previewVideoElement.nativeElement, 'pause', () => {
      this.previewVideoStatus = 'paused';
    });

  }


  delay(): Promise<void> {
    return new Promise(resolve => {
      var interval = setInterval(() => {
        if (this.previewVideoElement != undefined) {
          clearInterval(interval);
          resolve();
        }
      }, 10);

    });
  }

  // true -> disabled button
  get previewPlayFreezeButtonStatus(): boolean {
    if (this.previewData && this.previewData.data) {
      if (this.previewData.data.extension == 'mp4') {
        return false;
      }
    }
    return true;
  }

  freezeStreamingVideo(): void {

    if (!this.streamingVideoElement) return;

    const videoEl = this.streamingVideoElement.nativeElement as HTMLVideoElement;
    const width = videoEl.offsetWidth; //videoEl.videoWidth;
    const height = videoEl.offsetHeight; //videoEl.videoHeight;

    // console.log(width);
    // console.log(height);

    var canvasElement = this.canvas.nativeElement;
    var context = canvasElement.getContext('2d');

    canvasElement.width = width;
    canvasElement.height = height;

    context.drawImage(videoEl, 0, 0, width, height);
    const data = canvasElement.toDataURL('image/png');

    this.isStreamPaused = true;

    if (!this.args.isFreezed) {
      this.args.isFreezed = true;
      this.emitArgs();
    }

    this.changeDetector.detectChanges();

    if (document.getElementById("freezeElement")) {
      this.freezeElement.nativeElement.src = data
    } else {
      setTimeout(() => {
        this.freezeElement.nativeElement.src = data
      }, 1);
    }

    this.videoRecordingService.freezeStreamingVideo();

  }



  async playStreamingVideo(): Promise<void> {

    this.isStreamPaused = false;

    this._mediaDeviceService.emitPreviewData(null);

    if (this.args.isFreezed) {
      this.args.isFreezed = false;
      this.emitArgs();
    }

    if (this.args.isZoom) {
      this.zoomOff();
    }

    this.changeDetector.detectChanges();

    this.setStreamingStatus = await this._mediaDeviceService.startStreaming();

    // For capture screen -> switching buttons
    this.args.showStreamPlayFreezeBtn = true;
    this.args.showPreviewPlayFreezeBtn = false;
    this.emitArgs();
  }

  // glob.hasUnsavedChanges value changer
  set sethasUnsavedChanges(status: boolean) {
    this._glbalService.hasUnsavedChanges = status;
  }

  // glob.hasUnsavedChanges value getter
  get gethasUnsavedChanges(): boolean {
    return this._glbalService.hasUnsavedChanges;
  }


  backtodataentry(): void {
    if (this.gethasUnsavedChanges == true) {
      // $('#unsaved-guard-modal').modal('show');

      //exitPopup
      this._glbalService.exitPopupData$.next({
        accessedFromHeader: false,
        showPopup: true,
        buttons: [
          {
            eventName: "save",
            displayName: this.translate.instant("general.save"),
            disableBtn: false,
          },
          {
            eventName: "dontSave",
            displayName: this.translate.instant("general.dont_save"),
            disableBtn: false

          },
          {
            eventName: "close",
            displayName: this.translate.instant("general.cancel"),
            disableBtn: false

          }
        ]
      });
    } else {
      if (document.fullscreenElement !== null) {
        document.exitFullscreen().then(() => {
          this._glbalService.fullScreenClicked$.next(false);
        });
      }
      this.backtoDataentry.emit()
    }

  }


  emitArgs = () => this.argsStatus.next(this.args);


  // Zoom on
  zoomOn(): void {

    if (!this.args.isZoom) {
      this.args.isZoom = true;
      this.emitArgs();
    }
    this.changeDetector.detectChanges();

    setTimeout(() => this.magnify(3), 10);

  }

  // Zoom off
  zoomOff(): void {
    if (this.args.isZoom) {
      this.args.isZoom = false;
      this.emitArgs();
    }

    const elements = document.getElementsByClassName("img-magnifier-glass");
    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0]);
    }
  }


  getVideoRunningTime(event): void {
    let time = Math.round(event.target.currentTime);
    if (time < 10) this.currentTime = "00:0" + Math.round(event.target.currentTime);
    else this.currentTime = "00:" + Math.round(event.target.currentTime);
  }


  dateFormat(date: any) {
    return this._glbalService.dateFormat(date);
  }

  // Magnify
  magnify(zoom): void {

    let w: number, h: number;

    let img: HTMLImageElement = this.freezeElement.nativeElement;

    /*create magnifier glass:*/
    let zoomElement: HTMLDivElement = this.zoomElement.nativeElement;
    zoomElement.style.backgroundImage = "url('" + img.src + "')";
    zoomElement.style.backgroundRepeat = "no-repeat";
    zoomElement.style.backgroundSize = (img.width * zoom) + "px " + (img.height * zoom) + "px";

    w = zoomElement.offsetWidth / 2;
    h = zoomElement.offsetHeight / 2;
    /*execute a function when someone moves the magnifier glass over the image:*/
    zoomElement.addEventListener("mousemove", (evt) => this.moveMagnifier(evt, img, zoomElement, zoom, w, h));
    img.addEventListener("mousemove", (evt) => this.moveMagnifier(evt, img, zoomElement, zoom, w, h));
    /*and also for touch screens:*/
    zoomElement.addEventListener("touchmove", (evt) => this.moveMagnifier(evt, img, zoomElement, zoom, w, h));
    img.addEventListener("touchmove", (evt) => this.moveMagnifier(evt, img, zoomElement, zoom, w, h));

  }




  moveMagnifier(e: Event, img: HTMLImageElement, zoomElement: HTMLDivElement, zoom: number, w: number, h: number): void {

    let bw: number = 3;
    let pos: any, x: number, y: number, x1: number, y1: number;

    /*prevent any other actions that may occur when moving over the image*/
    e.preventDefault();

    /*get the cursor's x and y positions:*/
    pos = this.getCursorPos(e, img);
    x = pos.x;
    y = pos.y;

    x1 = pos.x;
    y1 = pos.y;

    /*prevent the magnifier glass from being positioned outside the image:*/

    if (x > (img.width) - (w / zoom) - 50) { x = img.width - (w / zoom) - 50; }
    if (x < (w / zoom) + 50) { x = (w / zoom) + 50; }
    if (y > (img.height) - (h / zoom) - 50) { y = img.height - (h / zoom) - 50; }
    if (y < (h / zoom) + 50) { y = (h / zoom) + 50; }

    /*set the position of the magnifier glass:*/
    // if(x1 < img.width){

    if (x1 <= x + 50) {
      zoomElement.style.left = (x - w) + "px";
    }

    if (y1 <= y + 50) {
      zoomElement.style.top = (y - h) + "px";
    }

    if (x1 <= x + 50 && y1 <= y + 50) {
      zoomElement.style.backgroundPosition = "-" + ((x1 * zoom) - w + bw) + "px -" + ((y1 * zoom) - h + bw) + "px";
    }

  }



  getCursorPos(e: any, img: HTMLImageElement): { x: number, y: number } {
    let x = 0, y = 0;
    e = e || window.event;
    /*get the x and y positions of the image:*/
    let a = img.getBoundingClientRect();
    /*calculate the cursor's x and y coordinates, relative to the image:*/
    x = e.pageX - a.left;
    y = e.pageY - a.top;
    /*consider any page scrolling:*/
    x = x - window.pageXOffset;
    y = y - window.pageYOffset;
    return { x: x, y: y };
  }




  @HostListener('document:fullscreenchange', ['$event']) browserFullScreen(event): void {
    if (document.fullscreenElement) {
      this.args.isFullScreen = true;
    } else {
      this.args.isFullScreen = false;
      this.exitFullscreen();
    }

    if (this.args.isFullScreen) {
      $('.section-content').addClass('full-screen-section');
    } else {
      $('.section-content').removeClass('full-screen-section');
    }

    // console.log("===================this.args.isFullScreen=====================",this.args.isFullScreen);

    this.emitArgs();

    //emit event to global service
    this._glbalService.fullScreenClicked$.next(this.args.isFullScreen);

  }

  browserFullScreenFn(): void {
    if (this.args.isFullScreen) {
      let element = document.querySelector('body');
      this.enterFullScreen(element);
    } else {
      this.exitFullscreen();
    }
  }

  enterFullScreen(element): void {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen(); // Firefox
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen(); // Safari
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen(); // IE/Edge
    }
  }

  exitFullscreen(): void {
    if (document.fullscreenElement !== null) {
      document.exitFullscreen();
    }
    // else if (document?.mozCancelFullScreen) {
    //   document.mozCancelFullScreen();
    // } else if (document.webkitExitFullscreen) {
    //   document.webkitExitFullscreen();
    // }
  }
}