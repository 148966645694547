import {
  Component,
  OnInit,
  OnChanges,
  SimpleChanges,
  Input,
  ViewChild,
  ElementRef,
  Renderer2,
  Output,
  EventEmitter,
  OnDestroy,
  ChangeDetectionStrategy,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { SQACustomer } from 'src/app/globals/globals';
import { HttpService } from 'src/app/services/http.service';
import { GlobalService } from 'src/app/services/global.service';
import { AuthService } from 'src/app/core/auth.service';
import { countryOptions as countries } from 'src/app/globals/countries';

interface FormInput {
  controlName: string;
  translationPath: string;
  validators: any[];
  maxLength: number;
  disablePaste?: boolean;
  isNumber?: boolean;
  required?: boolean;
}

interface ImageError {
  correctFormat: boolean;
  correctSize: boolean;
}

declare const $: any;

@Component({
  selector: 'app-facility-profile',
  templateUrl: './facility-profile.component.html',
  styleUrls: ['./facility-profile.component.sass'],
})
export class FacilityProfileComponent implements OnInit, OnChanges, OnDestroy {
  @Input() facilityData: SQACustomer;
  @Output() fireEvent = new EventEmitter();

  // prettier-ignore
  formInputs: FormInput[] = [
    { controlName: 'facilityName', translationPath: 'settings.facility_name', validators: [Validators.required], maxLength: 30, disablePaste: true, required: true },
    { controlName: 'phone', translationPath: 'settings.office_phone', validators: [Validators.minLength(6)], maxLength: 20, disablePaste: true, isNumber: true },
    { controlName: 'email', translationPath: 'settings.admin_email', validators: [], maxLength: 45, disablePaste: true },
    { controlName: 'website', translationPath: 'settings.website', validators: [], maxLength: 35 },
    { controlName: 'streetName', translationPath: 'settings.street', validators: [Validators.required], maxLength: 30, required: true },
    { controlName: 'streetNumber', translationPath: 'settings.streetNumber', validators: [], maxLength: 5, isNumber: true },
    { controlName: 'city', translationPath: 'settings.city', validators: [Validators.required], maxLength: 30, required: true },
    { controlName: 'state', translationPath: 'settings.state', validators: [], maxLength: 35 },
    { controlName: 'zipCode', translationPath: 'settings.zipcode', validators: [Validators.required], maxLength: 10, isNumber: true, required: true },
    { controlName: 'country', translationPath: 'settings.country', validators: [Validators.required], maxLength: 100, required: true },
    { controlName: 'optional1', translationPath: '', validators: [], maxLength: 30 },
    { controlName: 'optional2', translationPath: '', validators: [], maxLength: 30 },
  ];
  countryOptions = countries;
  form: FormGroup;
  userEmail: string;
  facilityLogoFile: File | undefined;
  defaultLogoUrl = 'assets/images/settings/no_image_upload.png';
  formInputChanged = false;
  imageError: ImageError = { correctFormat: true, correctSize: true };
  labelInputActive = false;
  optionalFields: {
    optional1: { value: string; edited: boolean };
    optional2: { value: string; edited: boolean };
  } | null = null;
  optional1DefaultText: string;
  optional2DefaultText: string;
  userSubscription: Subscription;
  submitBtnLoading = false;
  isTouched: boolean[] = [];

  // Modal related variables...
  modalTitle: string;
  modalBody: string;
  modalButtons: {
    name: string;
    color: string;
    clickFn: any;
    loading?: boolean;
  }[];

  @ViewChild('facilityLogo') facilityLogoInput: ElementRef;

  get disableSaveBtn() {
    // console.log('===disableSaveBtn===', this.form.valid, this.isTouched);

    if (this.form.valid == false && this.isTouched.includes(true)) this.globalService.hasUnsavedChanges = true;


    return !(
      this.imageError.correctFormat &&
      this.imageError.correctSize &&
      this.form.valid
      // && this.globalService.alternateUnsavedForRefValues1
      && this.globalService.hasUnsavedChanges
    );
  }



  get facilityLogoUrl() {
    if (this.facilityData) {
      if (this.facilityData.logoURL && this.facilityData.logoURL !== '')
        return this.facilityData.logoURL;
      return this.defaultLogoUrl;
    }
    return this.defaultLogoUrl;
  }

  get optionalLabelChanged() {
    if (
      this.facilityData &&
      this.facilityData.testSettings &&
      this.optionalFields
    ) {
      // Doing loose comparation because value can be '', null or undefined...
      return (
        this.optionalFields.optional1.value !=
        this.facilityData.testSettings.facilityOptional1 ||
        this.optionalFields.optional2.value !=
        this.facilityData.testSettings.facilityOptional2
      );
    }
    return false;
  }

  constructor(
    private renderer: Renderer2,
    private httpService: HttpService,
    private _translate: TranslateService,
    private authService: AuthService,
    private globalService: GlobalService
  ) {
    this.optional1DefaultText = this._translate.instant('settings.opt_1');
    this.optional2DefaultText = this._translate.instant('settings.opt_2');
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Update formGroup values on facilityData value changes...
    if (changes['facilityData'] && changes['facilityData'].currentValue) {
      if (!this.form) this.createForm();
      this.setFormValues(this.facilityData);
    }
  }

  ngOnInit(): void {

    // this.globalService.alternateUnsavedForRefValues1 = this.globalService.hasUnsavedChanges;
    this.createForm();

    // Retrieve current user email...
    this.userSubscription = this.authService.user.subscribe((user) => {
      if (user) {
        this.userEmail = user.email;
        // Stop emitting value change event, which can change the global hasUnsavedChange
        // variable and create problem in some other tabs...
        this.form.get('email').setValue(this.userEmail, { emitEvent: false });
      }
    });

    this._translate.onLangChange.subscribe((lang) => {
      this.optional1DefaultText = this._translate.instant('settings.opt_1');
      this.optional2DefaultText = this._translate.instant('settings.opt_2');
    });
  }

  createForm() {
    const formControls = {};
    for (const item of this.formInputs) {
      if (!formControls.hasOwnProperty(item.controlName)) {
        formControls[item.controlName] = new FormControl('', item.validators);
      }
    }

    this.form = new FormGroup(formControls);

    // Disable the Email control...
    this.form.get('email').disable();

    // Adding value changes listener...
    this.form.valueChanges.subscribe((result) => {
      console.warn("Value changes.......", result, this.facilityData);

      this.formInputChanged = false;
      if (this.facilityData) {
        for (const key in this.form.controls) {
          if (!this.formInputChanged) {
            // Since street name and number will be stored together in database...
            if (key === 'streetName' || key === 'streetNumber') {
              if (this.facilityData.hasOwnProperty('streetNo')) {
                let compareValue: string;
                if (key === 'streetName')
                  compareValue = this.facilityData.streetNo.split(';')[0];
                if (key === 'streetNumber')
                  compareValue = this.facilityData.streetNo.split(';')[1];

                if (result[key] !== compareValue) {
                  this.formInputChanged = true;
                }
              } else {
                /* Property doesn't exist in database(that's why enable saving if value becomes true) */
                if (result[key]) this.formInputChanged = true;
              }
            } else if (this.facilityData.hasOwnProperty(key)) {
              if (result[key] !== this.facilityData[key] && key !== 'email') {
                /* Email will be undefiened, since it's disabled field */
                this.formInputChanged = true;
              }
            } else {
              /* Property doesn't exist in database(that's why enable saving if value becomes true) */
              if (result[key]) this.formInputChanged = true;
            }
          }
        }
        this.setHasUnsavedChanges();
      }
    });
  }

  setFormValues(data: SQACustomer) {
    for (const key in this.form.controls) {
      // In case of email, use the authentication email instead of the saved email...
      if (data.hasOwnProperty(key) && key !== 'email') {
        this.form.get(key).setValue(data[key]);
      }
    }

    // Since streetName and streetNumber will be stored together as streetNo in database...
    if (data.hasOwnProperty('streetNo')) {
      const splitData = data.streetNo.split(';');
      this.form.get('streetName').setValue(splitData[0]);
      this.form.get('streetNumber').setValue(splitData[1]);
    }

    // Set optional fields title's as well...
    if (data.testSettings) {
      this.optionalFields = {
        optional1: {
          value: data.testSettings.facilityOptional1,
          edited: false,
        },
        optional2: {
          value: data.testSettings.facilityOptional2,
          edited: false,
        },
      };
    }

    // After setting the form values check for value changes again, because setForm value
    // will update the optional filed as well, which are not part of form...
    this.setHasUnsavedChanges();
  }

  // Keep track of the input field, image picker field and changable label(optional1, optional2) field...
  setHasUnsavedChanges() {
    if (
      this.formInputChanged ||
      this.facilityLogoFile ||
      this.optionalLabelChanged
    ) {
      this.globalService.hasUnsavedChanges = true;
      // this.globalService.alternateUnsavedForRefValues1 = true;
    } else {
      this.globalService.hasUnsavedChanges = false;
      // this.globalService.alternateUnsavedForRefValues1 = false;
    }

  }
  resetValues() {
    //we sould reset the this.isTouched variable.
    //this avoids this.globalService.hasUnsavedChanges assigning as true
    this.isTouched = [];
    // First reset the form and only put email(Related to new user's,
    // because they will have most of the fields as blank, so setFormValues
    // can't overwrite those value's since those value's don't exist on database)...
    this.form.reset();
    this.form.get('email').setValue(this.userEmail);
    this.form.get('email').disable();
    // Reset the form values to it's original...
    this.setFormValues(this.facilityData);

    // Reset the image picker field...
    this.resetImagePicker();

    // Reset the optional field labels...
    this.optionalFields = {
      optional1: {
        value: this.facilityData.testSettings.facilityOptional1,
        edited: false,
      },
      optional2: {
        value: this.facilityData.testSettings.facilityOptional2,
        edited: false,
      },
    };

    this.setHasUnsavedChanges();
  }

  shouldPasteValue(event: Event, condition?: boolean) {
    if (condition) {
      event.preventDefault();
    }
  }

  onlyNumber(event: KeyboardEvent, condition?: boolean) {
    console.log("====form", this.form.valid, this.globalService.hasUnsavedChanges);

    if (condition) {
      const key = event.key;
      if (
        key === 'Backspace' ||
        key === 'ArrowLeft' ||
        key === 'ArrowRight' ||
        key === 'Tab'
      )
        return true;
      if (key === 'Space' || key === 'Enter') return false;
      return /[0-9]/.test(key);
    }
    return true;
  }

  getLabelColor(controlName: string) {
    if (this.form) {
      if (
        !this.form.get(controlName).valid &&
        (this.form.get(controlName).touched || this.form.get(controlName).dirty)
      ) {
        return { color: 'red' };
      }
      if (this.form.get(controlName).status === 'DISABLED')
        return { color: '#c6c6c6' };
    }
  }

  getInputLabelsValue(controlName: string): string {
    // If value becomes '' and still in edit mode than don't set it back to the default texts,
    // only set it back to default text when it's not in edit mode(ex. onFocusOut)...
    if (this.optionalFields) {
      if (
        this.optionalFields[controlName].value === '' &&
        !this.optionalFields[controlName].edited
      ) {
        if (controlName === 'optional1') return this.optional1DefaultText;
        if (controlName === 'optional2') return this.optional2DefaultText;
      }
      return this.optionalFields[controlName].value;
    }
  }

  activateLabelInput(element: Element, controlName: string) {
    if (!this.labelInputActive) {
      this.renderer.removeAttribute(element, 'disabled');
      // Move the input field cursor to the end of the text...
      const end = this.getInputLabelsValue(controlName).length;
      (element as HTMLInputElement).setSelectionRange(end, end);
      (element as HTMLInputElement).focus();
      this.labelInputActive = true;
    }
  }

  changeOptionalTitle(event: Event, controlName: string) {
    const value = (event.target as HTMLInputElement).value;
    if (!this.optionalFields[controlName].edited) {
      this.optionalFields[controlName].edited = true;
    }
    this.optionalFields[controlName].value = value;

    this.setHasUnsavedChanges();
  }

  onOptionFocusOut(controlName: string, element: Element) {
    this.optionalFields[controlName].edited = false;
    this.renderer.setAttribute(element, 'disabled', 'true');
    this.labelInputActive = false;
  }

  resetImagePicker() {
    if (!this.facilityLogoFile) return;

    this.facilityLogoFile = undefined;
    this.imageError = { correctFormat: true, correctSize: true };
    this.renderer.removeClass(
      this.facilityLogoInput.nativeElement,
      'valid-image'
    );
    this.renderer.removeClass(
      this.facilityLogoInput.nativeElement,
      'invalid-image'
    );

    this.setHasUnsavedChanges();
  }

  onSelectFile(event: Event) {
    const imageFile = (event.target as HTMLInputElement).files[0];

    if (imageFile) {
      // Resetting the fields...
      this.resetImagePicker();

      // Store the image...
      this.facilityLogoFile = imageFile;

      // Check for correct image format...
      if (
        this.facilityLogoFile.type !== 'image/png' &&
        this.facilityLogoFile.type !== 'image/jpg' &&
        this.facilityLogoFile.type !== 'image/jpeg'
      )
        this.imageError.correctFormat = false;
      // Check for correct image size...
      if (this.facilityLogoFile.size / (1024 * 1024) > 5)
        this.imageError.correctSize = false;

      // Adding green or red border in the bottom...
      if (this.imageError.correctFormat && this.imageError.correctSize) {
        this.renderer.addClass(
          this.facilityLogoInput.nativeElement,
          'valid-image'
        );
      } else {
        this.renderer.addClass(
          this.facilityLogoInput.nativeElement,
          'invalid-image'
        );
      }

      this.setHasUnsavedChanges();
    }
  }

  deleteFacilityLogo() {
    this.httpService
      .DeleteLogo()
      .then((_) => {
        this.facilityData.logoURL = '';
        this.fireEvent.emit('success:settings.remove_succ_logo');
        this.closeModal();
      })
      .catch((error) => {
        console.log(error);
        this.fireEvent.emit('error:settings.remove_fail_img');
      });
  }

  onClickDeleteLogo() {
    if (!this.facilityData.logoURL) return;

    // Show delete logo popup...
    this.modalTitle = this._translate.instant('settings.remove_image');
    // prettier-ignore
    this.modalBody = `<i class="material-icons" style="color: red; font-size: 36px; height: 36px; margin-bottom: 15px">
                        error_outline</i><h6><strong>${this._translate.instant('settings.remove_img_logo_warn')}
                        </strong></h6>`;
    this.modalButtons = [
      {
        name: this._translate.instant('general.confirm'),
        color: 'btn-primary',
        clickFn: this.deleteFacilityLogo.bind(this),
        loading: false,
      },
      {
        name: this._translate.instant('general.cancel'),
        color: 'btn-primary',
        clickFn: this.closeModal,
        loading: false,
      },
    ];
    $('#facilityCommonModal').modal('show');
    return;
  }

  closeModal() {
    $('#facilityCommonModal').modal('hide');
  }

  async onSubmit() {
    if (this.disableSaveBtn || this.submitBtnLoading) return;

    this.submitBtnLoading = true;
    const value = this.form.value;
    if (value['streetName'] !== '' || value['streetNumber'] !== '') {
      const streetNo = `${value['streetName']};${value['streetNumber']}`;
      value.streetNo = streetNo;
    }

    // Delete unnecessary fields...
    delete value.email;
    delete value.streetName;
    delete value.streetNumber;

    const dataToBeSaved = {
      ...value,
      testSettings: {
        facilityOptional1: this.optionalFields.optional1.value,
        facilityOptional2: this.optionalFields.optional2.value,
      },
    };

    // If Image is selected, than save the image first...
    if (this.facilityLogoFile) {
      const logoUrl = await this.httpService.UploadFacilityLogo(
        this.facilityLogoFile
      );
      dataToBeSaved['logoURL'] = logoUrl;
    }

    // Save the data in the database...
    try {
      await this.httpService.UpdateFacilitySettings(dataToBeSaved);

      // Reset the value changed related variables...
      this.formInputChanged = false;
      this.resetImagePicker();

      this.fireEvent.emit('fetch data');
      this.fireEvent.emit('success:settings.save_success');
    } catch (error) {
      console.log(error);
      this.fireEvent.emit('error:settings.save_fail');
    }
    this.submitBtnLoading = false;
  }

  // Function to check the condition
  checkCondition(controlName: string): boolean {

    const control = this.form.get(controlName);
    const checkTouched = control.dirty || control.touched;
    const controlIndexMap = {
      facilityName: 0,
      streetName: 1,
      city: 2,
      zipCode: 3,
      country: 4
    };
    const controlIndex = controlIndexMap[controlName];

    if (controlIndex !== undefined) this.isTouched[controlIndex] = checkTouched;

    return control.hasError('required') && checkTouched;
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }

}
