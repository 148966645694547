import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnDestroy,
  QueryList,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/auth.service';

import {
  SQACustomer,
  SystemSettings,
  VUSettings,
  TestSettings,
  // defaultTestSettings,
} from 'src/app/globals/globals';
import { GlobalService } from 'src/app/services/global.service';
import { HttpService } from 'src/app/services/http.service';

interface SettingsItems {
  controlName: string;
  translationPath: string;
  validators?: ValidatorFn[];
  groupValue?: { title: string, value: string }[];
  isTrue?: boolean;
}

interface UpdatedSQACustomer extends SQACustomer {
  contentLimitation: {
    visualizationAccess: boolean;
  };
}

type FormType = 'SystemSettings' | 'VUSettings';

declare const $: any;

@Component({
  selector: 'app-system-settings',
  templateUrl: './system-settings.component.html',
  styleUrls: ['./system-settings.component.sass'],
})
export class SystemSettingsComponent implements OnInit, OnDestroy {
  @Input() facilityData: UpdatedSQACustomer;
  @Input() defaultTestSettings: TestSettings;
  @Output() fireEvent = new EventEmitter();
  // prettier-ignore
  systemSettings: SettingsItems[] = [
    { controlName: 'playSound', translationPath: 'settings.play_sound_info', validators: [Validators.required] },
    // { controlName: 'disableSelfTest', translationPath: 'settings.disable_self_test_info', validators: [Validators.required] },
    { controlName: 'archivePageHistory', translationPath: 'settings.archive_page_history_info', validators: [Validators.required] },
    { controlName: 'removePatientPhone', translationPath: 'settings.remove_phone_number', validators: [Validators.required] },
    { controlName: 'multiFactorAuthActive', translationPath: 'settings.add_multi_factor', validators: [Validators.required] },
    // { controlName: 'addLIS', translationPath: 'settings.add_lis_info', validators: [Validators.required] },
    // { controlName: 'autoOpenLQCounter', translationPath: 'settings.auto_open_lq_counter', validators: [Validators.required] },
    { controlName: 'autoLogOut', translationPath: 'settings.logout_info', validators: [Validators.required] },

    /*****Privacy Mode controls*****/
    // { controlName: 'disableFirstLastName', translationPath: 'settings.privacy_disable_name', validators: [Validators.required] },
    // { controlName: 'disableBirthDateAge', translationPath: 'settings.privacy_disable_age', validators: [Validators.required] },
    { controlName: 'isPrivacyMode', translationPath: 'settings.privacy_disable_protected_health', validators: [Validators.required] },
  ];

  i: number = 0;

  // check iOwMode if the iOMode is true 
  //openLQAuto is enable
  //otherwise openLQAuto is disable
  vuSettings: SettingsItems[] = !this.authService.iOwMode ? [
    { controlName: 'downloadJPEGImg', translationPath: 'settings.download_img_info', groupValue: [{ title: "settings.download_img_png", value: "png" }, { title: "settings.download_img_jpeg", value: "jpeg" }], validators: [Validators.required] },
    // { controlName: 'downloadXXVid', translationPath: 'settings.download_vid_info', groupValue: [{ title: "settings.download_vid_pc", value: "mp4_pc" }, { title: "settings.download_vid_mobile", value: "mp4_mobile" }], validators: [Validators.required] },
    { controlName: 'openLQAuto', translationPath: 'settings.open_low_counter_info', validators: [Validators.required] },
  ] :
    [
      { controlName: 'downloadJPEGImg', translationPath: 'settings.download_img_info', groupValue: [{ title: "settings.download_img_png", value: "png" }, { title: "settings.download_img_jpeg", value: "jpeg" }], validators: [Validators.required] },
    ];



  // defaultTestSettingsValues: TestSettings = this.defaultTestSettings;
  systemForm: FormGroup;
  vuForm: FormGroup;
  systemFormValueChanged = false;
  vuFormValueChanged = false;
  submitBtnLoading = false;
  disableTimerInput = false;
  currentLang: string;
  languageSubscription$: Subscription;
  logoutTimeChange: boolean = false;
  isMinimumLogout: boolean = false;
  isHideMFAcontrol: boolean = false;

  // Modal related variables...
  modalTitle: string;
  modalBody: string;
  modalButtons: {
    name: string;
    color: string;
    clickFn: any;
    loading?: boolean;
  }[];

  @ViewChild('hourInput') hourInput: ElementRef;
  @ViewChild('minuteInput') minuteInput: ElementRef;

  @ViewChildren('systemCheckBox', { read: ElementRef })
  systemCheckBoxes: QueryList<ElementRef>;

  get hasValueChanged() {
    return this.systemFormValueChanged || this.vuFormValueChanged;
  }

  get disableSaveBtn() {
    if (
      (this.systemForm && !this.systemForm.valid) ||
      (this.vuForm && !this.vuForm.valid) || this.isMinimumLogout
    )
      return true;
    return !this.hasValueChanged;
  }

  get disableCloseBtn() {
    if (this.modalTitle === this._translate.instant('settings.disable_logout_header')) {
      return false
    }
    return true;
  }

  get enableDefaultBtn() {
    // Check if value changed than the default value in system settings form...
    if (this.systemForm) {
      for (const key in this.systemForm.controls) {
        if (this.defaultTestSettings.systemSettings.hasOwnProperty(key)) {
          const compareValue =
            key === 'autoLogOutValue'
              ? +this.systemForm.get(key).value
              : this.systemForm.get(key).value;
          if (compareValue !== this.defaultTestSettings.systemSettings[key])
            return true;
        }
      }
    }

    // Check if value changed than the default value in VU settings form...
    if (this.vuForm) {
      for (const key in this.vuForm.controls) {
        if (this.defaultTestSettings.VUSettings.hasOwnProperty(key)) {
          if (
            this.vuForm.get(key).value !== this.defaultTestSettings.VUSettings[key]
          )
            return true;
        }
      }
    }

    return false;
  }

  constructor(
    private globalService: GlobalService,
    private httpService: HttpService,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private _translate: TranslateService,
    private authService: AuthService,
  ) {
    this.currentLang = this._translate.currentLang;

    //check hideMFAcontrol
    httpService.facilityUpdates.subscribe((fac) => {
      this.isHideMFAcontrol =
        fac.contentLimitation?.hideMFAcontrol != undefined
          ? fac.contentLimitation?.hideMFAcontrol
          : false;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Update formGroup values on testSettings value changes...
    if (changes['facilityData'] && changes['facilityData'].currentValue) {
      this.createForm();

      // Populate form values for the systemForm...
      if (this.facilityData.testSettings.systemSettings) {
        this.setFormValues('SystemSettings',
          this.facilityData.testSettings.systemSettings);
      }

      // Populate form values for the VU form...
      if (this.vuForm && this.facilityData.testSettings.VUSettings) {
        this.setFormValues(
          'VUSettings',
          this.facilityData.testSettings.VUSettings
        );
      }

    }
  }

  ngOnInit(): void {
    this.languageSubscription$ = this._translate.onLangChange.subscribe(
      (e: LangChangeEvent) => {
        this.currentLang = e.lang;
      });
  }

  createForm() {
    // Create the sytem settings form ...
    const systemFormControls = {};
    for (const item of this.systemSettings) {
      systemFormControls[item.controlName] = new FormControl(
        this.defaultTestSettings.systemSettings[item.controlName] ?? false,
        item.validators
      );
    }
    // Adding logout time and LIS input controls as well...
    systemFormControls['autoLogOutValue'] = new FormControl(
      this.defaultTestSettings.systemSettings['autoLogOutValue'],
      Validators.required
    );
    systemFormControls['LISValue'] = new FormControl('');
    this.systemForm = new FormGroup(systemFormControls);
    // Attaching the listener for value changes...
    this.checkValueChanges('SystemSettings');

    // Create the vu settings form only for the allowed users...
    if (this.facilityData.contentLimitation.visualizationAccess) {
      const vuFormControls = {};
      for (const item of this.vuSettings) {
        vuFormControls[item.controlName] = new FormControl(
          this.defaultTestSettings.VUSettings[item.controlName] ?? false,
          item.validators
        );
      }
      this.vuForm = new FormGroup(vuFormControls);
      this.checkValueChanges('VUSettings');
    }
    this.isMinimumLogout = false;
  }

  setFormValues(formType: FormType, value: SystemSettings | VUSettings) {
    // Populate the system settings fields...

    if (formType === 'SystemSettings') {
      for (const key in this.systemForm.controls) {
        if (value.hasOwnProperty(key)) {
          this.systemForm.get(key).setValue(value[key]);
        }
      }
    }

    // Disable the LIS input field if the value is false...
    // if (this.systemForm.get('addLIS').value === false) {
    //   this.systemForm.get('LISValue').disable();
    // }

    this.cdr.detectChanges();
    // Update the timer field...
    this.updateTime(+this.systemForm.get('autoLogOutValue').value);
    // Disable the logout timer inputs if autoLogOut value is 0...
    if (this.systemForm.get('autoLogOutValue').value === 0 || this.systemForm.get('autoLogOut').value == false)
      this.disableTimerInput = true;
    else this.disableTimerInput = false;

    // Populate the vu device settings fields if vu settings form exists...
    if (formType === 'VUSettings') {
      console.log(value, this.vuForm.controls);

      for (const key in this.vuForm.controls) {
        if (value.hasOwnProperty(key)) {
          this.vuForm.get(key).setValue(value[key]);
        }
      }
    }

  }

  checkValueChanges(formType: FormType) {
    // Choosing the form and it's corresponding data's that we will check for value changes...
    const form = formType === 'SystemSettings' ? this.systemForm : this.vuForm;
    const savedValue =
      formType === 'SystemSettings'
        ? this.facilityData.testSettings.systemSettings
        : this.facilityData.testSettings.VUSettings;
    const defaultValues =
      formType === 'SystemSettings'
        ? this.defaultTestSettings.systemSettings
        : this.defaultTestSettings.VUSettings;

    // Check for value changes...
    form.valueChanges.subscribe((result) => {
      let valueChanged = false;
      for (const key in result) {
        const compareValue =
          key === 'autoLogOutValue' ? +result[key] : result[key];

        // First compare with database saved values, if not exist than compare with
        // default values, if neither exist than check if the form value is truish...
        if (!valueChanged) {
          if (savedValue && savedValue.hasOwnProperty(key)) {
            if (compareValue !== savedValue[key]) valueChanged = true;
          } else {
            if (defaultValues.hasOwnProperty(key)) {
              if (compareValue !== defaultValues[key]) valueChanged = true;
            } else {
              if (compareValue) valueChanged = true;
            }
          }
        }
      }

      if (formType === 'SystemSettings') {
        this.systemFormValueChanged = valueChanged;
      }
      if (formType === 'VUSettings') {
        this.vuFormValueChanged = valueChanged;
      }

      // Finally update the global service as well...
      this.globalService.hasUnsavedChanges = this.hasValueChanged;
    });
  }

  activeCheckMark(controlName: string, formType: FormType, value?: string) {
    if (formType === 'SystemSettings') {
      return this.systemForm.get(controlName).value;
    }
    if (formType === 'VUSettings') {
      if (value)
        return this.vuForm.get(controlName).value === value
      else
        return this.vuForm.get(controlName).value;
    }
    return false;
  }

  changeCheckMark(controlName: string, formType: FormType, value: boolean) {
    if (formType === 'SystemSettings') {
      // For autoLogout checkbox show a popup / reset to the default auto logout values...
      if (controlName === 'autoLogOut') {
        if (value === false) {
          this.showMinimumAutoLogoutMessage();
          return;
        } else {
          // Reset the auto logout values to the default value...
          const defaultLogOut =
            this.defaultTestSettings.systemSettings.autoLogOutValue;
          this.systemForm.get('autoLogOutValue').setValue(defaultLogOut);
          this.updateTime(defaultLogOut);
          this.disableTimerInput = false;
        }
      }

      this.systemForm.get(controlName).setValue(value);

      // Disable or enable the LIS value input field...
      if (controlName === 'addLIS') {
        if (this.systemForm.get('addLIS').value) {
          this.systemForm.get('LISValue').enable();
        } else {
          this.systemForm
            .get('LISValue')
            .setValue(this.defaultTestSettings.systemSettings.LISValue);
          this.systemForm.get('LISValue').disable();
        }
      }

    }
    if (formType === 'VUSettings') {
      console.log(this.vuForm.get(controlName));
      this.vuForm.get(controlName).setValue(value);
      if (controlName == "downloadJPEGImg") {
        return
      } else if (controlName == "downloadJPEGImg") {

      }
    }
  }

  logOutModalApprove() {
    this.systemForm.get('autoLogOut').setValue(false);
    const defaultLogOut = this.defaultTestSettings.systemSettings.autoLogOutValue;
    this.systemForm.get('autoLogOutValue').setValue(defaultLogOut);
    this.updateTime(defaultLogOut);
    // this.systemForm.get('autoLogOutValue').setValue(0);
    // this.updateTime(0);
    this.disableTimerInput = true;
    this.closeCommonModal();
  }

  logOutModalCancel() {
    this.systemForm.get('autoLogOut').setValue(true);
    // Enable the checkbox again...
    this.renderer.setProperty(
      this.systemCheckBoxes.toArray()[1].nativeElement,
      'checked',
      true
    );
    // Reset the auto logout values to the default value...
    const defaultLogOut = this.defaultTestSettings.systemSettings.autoLogOutValue;
    this.systemForm.get('autoLogOutValue').setValue(defaultLogOut);
    this.updateTime(defaultLogOut);

    this.closeCommonModal();
  }

  disableAutoLogOut() {
    this.modalTitle = this._translate.instant('settings.disable_logout_header');
    // prettier-ignore
    this.modalBody = `<i class="material-icons" style="color: red; font-size: 36px; height: 36px; margin-bottom: 15px">error_outline</i>
                      <h6 style="text-align: center"><strong>${this._translate.instant('settings.disable_logout_title_1')}</strong></h6>
                      <h6 style="text-align: center"><strong>${this._translate.instant('settings.disable_logout_title_2')}</strong></h6>
                      <h6 style="text-align: center; margin-top: 20px; text-transform: initial"><strong>${this._translate.instant('settings.disable_logout_text')}</strong></h6>`;
    this.modalButtons = [
      {
        name: this._translate.instant('general.approve'),
        color: 'btn-primary',
        clickFn: this.logOutModalApprove.bind(this),
        loading: false,
      },
      {
        name: this._translate.instant('general.cancel'),
        color: 'btn-primary',
        clickFn: this.logOutModalCancel.bind(this),
        loading: false,
      },
    ];
    $('#systemSettingsCommonModal').modal('show');
  }

  closeCommonModal() {
    $('#systemSettingsCommonModal').modal('hide');
  }

  // <----------------------------- Timer Input Related Methods ------------------------------>

  onTimeChange() {
    this.logoutTimeChange = true;
    if (this.isMinimumLogout) this.isMinimumLogout = false;
    const value =
      +(this.hourInput.nativeElement as HTMLInputElement).value * 60 +
      +(this.minuteInput.nativeElement as HTMLInputElement).value;

    if (value === 0) {
      this.showMinimumAutoLogoutMessage();
    }

    this.systemForm.get('autoLogOutValue').setValue(value);
  }

  onFocusOut(elem: HTMLInputElement) {
    elem.value = elem.value.padStart(2, '0');

    // If value becomes 0 than show the auto logout warning popup...
    if (+this.systemForm.get('autoLogOutValue').value === 0) this.showMinimumAutoLogoutMessage();
  }

  updateTime(value: number) {
    const hour = Math.floor(value / 60)
      .toString()
      .padStart(2, '0');
    const minute = Math.floor(value % 60)
      .toString()
      .padStart(2, '0');
    this.renderer.setProperty(this.hourInput.nativeElement, 'value', hour);
    this.renderer.setProperty(this.minuteInput.nativeElement, 'value', minute);
  }

  onTimeControlClick(event: 'up' | 'down') {
    this.logoutTimeChange = true;
    // Do nothing if the icons are disabled...
    if (this.disableTimerInput) return;
    if (this.isMinimumLogout) this.isMinimumLogout = false

    const value = +this.systemForm.get('autoLogOutValue').value;

    let updatedValue: number;

    if (event === 'up' && value < 720) updatedValue = value + 1;
    else if (event === 'down' && value > 0) updatedValue = value - 1;
    else updatedValue = value;

    this.systemForm.get('autoLogOutValue').setValue(updatedValue);

    this.updateTime(updatedValue);

    // Show minimum 1 minute warning caption if value becomes 0...
    if (updatedValue === 0) {
      this.showMinimumAutoLogoutMessage();
    }
  }

  showMinimumAutoLogoutMessage() {
    this.isMinimumLogout = true;
  }

  onTimeKeyDown(event: KeyboardEvent, type: 'hour' | 'minute') {
    const key = event.key;
    const value = (event.target as HTMLInputElement).value;

    // Cursor position to determine where the pressed key value would be entered in the
    // previous value's array...
    const _cursorStartPosition = (event.target as HTMLInputElement)
      .selectionStart;
    const _cursorEndPosition = (event.target as HTMLInputElement).selectionEnd;
    const valueArr = value.split('');

    if (key === 'ArrowRight' || key === 'ArrowLeft' || key === 'Backspace')
      return true;
    if (key === 'Space') return false;

    if (/[0-9]/.test(key)) {
      if (_cursorStartPosition === _cursorEndPosition) {
        // If no text selected through the cursor...
        valueArr.splice(_cursorStartPosition, 0, key);
      } else {
        // If text is selected through cursor...
        valueArr.splice(
          _cursorStartPosition,
          _cursorEndPosition - _cursorStartPosition,
          key
        );
      }

      const updatedValue = +valueArr.join('');
      let totalValue: number;

      //minute check 
      if (updatedValue >= 60) {
        return false;
      }

      // Total updated value if the input event is for hour...
      if (type === 'hour') {
        totalValue =
          updatedValue * 60 +
          +(this.minuteInput.nativeElement as HTMLInputElement).value;
      }
      // Total updated value if the input event is for minute...
      if (type === 'minute') {
        totalValue =
          +(this.hourInput.nativeElement as HTMLInputElement).value * 60 +
          updatedValue;

      }

      if (totalValue <= 720) return true;
      return false;
    }

    return false;
  }

  // <----------------------------------------------------------------------------------------->
  onResetToDefault() {
    this.modalTitle = this._translate.instant('settings.default_header');
    // prettier-ignore
    this.modalBody = `<i class="material-icons" style="color: red; font-size: 36px; height: 36px; margin-bottom: 15px">error_outline</i>
                      <h6 style="text-align: center"><strong>${this._translate.instant('settings.default_header_body')}</strong></h6>`;
    this.modalButtons = [
      {
        name: this._translate.instant('general.restore'),
        color: 'btn-primary',
        clickFn: () => this.resetValueToDefault(),
        loading: false,
      },
      {
        name: this._translate.instant('general.cancel'),
        color: 'btn-primary',
        clickFn: this.closeCommonModal,
        loading: false,
      },
    ];
    $('#systemSettingsCommonModal').modal('show');
  }

  // closeCommonModal() {
  //   $('#testSettingsCommonModal').modal('hide');
  // }

  async resetValueToDefault() {
    if (this.modalButtons[0].loading) return;

    // remove 1 minute timeout warning caption
    this.isMinimumLogout = false;

    // Check if the saved data in database is same as default or not, if the saved data
    // in database is same as default than no need to save it again(just update the local form)...
    let isDatabaseValueChanged = false;

    // Compare the default data and database data for system settings...
    for (const key in this.defaultTestSettings.systemSettings) {
      if (this.facilityData.testSettings.systemSettings.hasOwnProperty(key)) {
        if (
          this.facilityData.testSettings.systemSettings[key] !==
          this.defaultTestSettings.systemSettings[key]
        )
          isDatabaseValueChanged = true;
      }
    }

    // Compare the default data and database data for VU settings...
    if (this.vuForm) {
      for (const key in this.defaultTestSettings.VUSettings) {
        if (this.facilityData.testSettings.VUSettings.hasOwnProperty(key)) {
          if (
            this.facilityData.testSettings.VUSettings[key] !==
            this.defaultTestSettings.VUSettings[key]
          )
            isDatabaseValueChanged = true;
        }
      }
    }

    // If it's necessary to update in the databse than update the data in database...
    if (isDatabaseValueChanged) {
      //modelButton enable
      this.modalButtons[0].loading = true;

      const updatedTestSettings: TestSettings = {
        ...this.facilityData.testSettings,
        systemSettings: { ...this.defaultTestSettings.systemSettings },
      };


      // Attach the default VU settings as well if user have VU device...
      if (this.vuForm) {
        updatedTestSettings.VUSettings = { ...this.defaultTestSettings.VUSettings };
      }


      try {
        await this.httpService.UpdateFacilitySettings({
          testSettings: updatedTestSettings,
        });
        //modelButton disable
        this.modalButtons[0].loading = false;
        this.globalService.hasUnsavedChanges = false;
        this.fireEvent.emit('fetch data');
        this.fireEvent.emit('success:settings.save_success');
        this.closeCommonModal();
      } catch (error) {
        console.log(error);
      }
    } else {

      //close Model
      this.closeCommonModal();
      // Update the local form's to it's corresponding default values...
      this.setFormValues('SystemSettings', this.defaultTestSettings.systemSettings);
      if (this.vuForm) {
        this.setFormValues('VUSettings', this.defaultTestSettings.VUSettings);
      }
    }
  }

  resetValues() {
    this.createForm();

    // Populate form values for the system form...
    if (this.facilityData.testSettings.systemSettings) {
      this.setFormValues('SystemSettings', this.facilityData.testSettings.systemSettings);
    }
    // Populate form values for the VU form...
    if (this.vuForm && this.facilityData.testSettings.VUSettings) {
      this.setFormValues(
        'VUSettings',
        this.facilityData.testSettings.VUSettings
      );
    }
  }

  async onSubmit() {
    const systemSettingsValue = this.systemForm.getRawValue();

    const updatedTestSettings: TestSettings = {
      ...this.facilityData.testSettings,
      systemSettings: systemSettingsValue,
    };

    // Attach vu form values also if it's exist...
    if (this.vuForm) {
      updatedTestSettings.VUSettings = this.vuForm.value;
      // console.log(this.vuForm.value);
    }

    this.submitBtnLoading = true;
    try {
      const result: { id: string; data: { testSettings: TestSettings } } =
        await this.httpService.UpdateFacilitySettings({
          testSettings: updatedTestSettings,
        });
      console.log(result);
      this.globalService.hasUnsavedChanges = false;
      this.fireEvent.emit('fetch data');
      this.fireEvent.emit('success:settings.save_success');
    } catch (error) {
      console.log(error);
      this.fireEvent.emit('error:settings.save_fail');
    }
    this.submitBtnLoading = false;
  }

  ngOnDestroy(): void {
    this.languageSubscription$?.unsubscribe();
  }
}
