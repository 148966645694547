import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { German } from 'flatpickr/dist/l10n/de';
import { French } from 'flatpickr/dist/l10n/fr';
import { Spanish } from 'flatpickr/dist/l10n/es';
import { Italian } from 'flatpickr/dist/l10n/it';
import { Japanese } from 'flatpickr/dist/l10n/ja';
import { Portuguese } from 'flatpickr/dist/l10n/pt';
import { Vietnamese } from 'flatpickr/dist/l10n/vn';
import { Greek } from 'flatpickr/dist/l10n/gr';
import { Turkish } from 'flatpickr/dist/l10n/tr';


@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  langArr = ['en', 'de', 'fr', 'it', 'es', 'ja', 'vi', 'pt', 'el', 'tr'];
  languages = [
    { id: 'en', name: 'English', flag: 'assets/images/flags/en.png' },
    { id: 'fr', name: 'French', flag: 'assets/images/flags/fr.png' },
    { id: 'de', name: 'German', flag: 'assets/images/flags/de.png' },
    { id: 'el', name: 'Greek', flag: 'assets/images/flags/el.png' },
    { id: 'it', name: 'Italian', flag: 'assets/images/flags/it.png' },
    { id: 'ja', name: 'Japanese', flag: 'assets/images/flags/ja.png' },
    { id: 'pt', name: 'Portuguese', flag: 'assets/images/flags/pt.png' },
    { id: 'es', name: 'Spanish', flag: 'assets/images/flags/es.png' },
    { id: 'tr', name: 'Turkish', flag: 'assets/images/flags/tr.png' },
    { id: 'vi', name: 'Vietnamese', flag: 'assets/images/flags/vi.png' }
  ];
  currentLanguage: any;

  constructor(public translate: TranslateService) {

    translate.addLangs(this.langArr);
    translate.setDefaultLang('en');

  }

  selectLang(langId) {
    let langInd = this.languages.findIndex(i => i.id === langId);
    this.currentLanguage = this.languages[langInd];
    this.saveSelectedLanguage(langId);
    // this.translate.use(langId);
    return this.currentLanguage;
  }

  getAllLanguages() {
    return this.languages;
  }

  getDefaultLang() {
    return this.translate.getDefaultLang();
  }

  getSavedLang() {
    const lang = localStorage.getItem('language');
      if (lang != null && lang !== '' && lang !== undefined && this.langArr.includes(lang)) {
        this.translate.use(lang);
        console.log(`recovered language from db ${lang}`);
        return lang;
      } else {
        this.translate.use('en');
        localStorage.setItem('language', 'en');
        console.log('set default language en');
        return 'en';
       }

  }

  saveSelectedLanguage(lang: string) {
    localStorage.setItem('language', lang);
    console.log('saved language ', lang);
    this.translate.use(lang);
  }

  getLangString() {
    if (this.translate.currentLang.includes('de')) {
      return German;
    } else if (this.translate.currentLang.includes('fr')) {
      return French;
    } else if (this.translate.currentLang.includes('es')) {
      return Spanish;
    } else if (this.translate.currentLang.includes('it')) {
      return Italian;
    } else if (this.translate.currentLang.includes('ja')) {
      return Japanese;
    } else if (this.translate.currentLang.includes('pt')) {
      return Portuguese;
    } else if (this.translate.currentLang.includes('vi')) {
      return Vietnamese;
    } else if (this.translate.currentLang.includes('tr')) {
      return Turkish;
    } else if (this.translate.currentLang.includes('el')) {
      return Greek;
    }
    else if (this.translate.currentLang.includes('en')) {
      return null;
    }
  }
}
