import { Component, OnInit, OnDestroy, ElementRef, AfterViewInit, Renderer2, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { User } from 'src/app/globals/user';
import { AuthService } from 'src/app/core/auth.service';
import { HttpService } from 'src/app/services/http.service';
import { GlobalService } from 'src/app/services/global.service';
import { Router, NavigationEnd, } from '@angular/router';
import { CommunicatorService, ActiveClient, TestState } from 'src/app/services/communication.service';
import { MyServiceEvent } from '../../services/communication.service';
import { ServiceData, Device } from 'src/app/globals/globals';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

declare const $: any;
declare const Waves: any;
declare const jQuery: any;
const document: any = window.document;

export interface btns {
  name: string,
  fun: Function,
  className?: string,
}
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.sass']
})
export class SidebarComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('sidebar') sideBar: ElementRef
  photo: string;
  photodefined: boolean;
  doctorName: string;
  userID: string;
  facilityName: string;
  facilityID: string;
  subscription: Subscription;
  noRecords = false;
  noCredits = false;
  isDisconnected: boolean;
  isDriverInstalled: boolean;
  deviceBusy = false;
  selfTestFailed = true;
  _serviceSubscription: Subscription;
  ProgressValue = 0;
  sProgressValue = "";
  SubProgressValue = 0;
  sSubProgressValue = "";
  // maxvalue = 4000;
  currentUser: any;
  isSNvalid = false;
  isFacilityAdmin = false;
  _langSubscription: Subscription;
  _testCreditSubscription: Subscription;
  InternetSubscription: Subscription;
  routerSubscription$: Subscription;
  driverUpdateSubscription: Subscription;
  isInternet: boolean = true
  internetButtons: Array<btns> = []
  globalService: GlobalService
  showServiceButton: boolean = true;
  translate: any
  selectedPortindex = 0;
  MESLogoSrc: string = 'assets/images/mes_man_transparent.png';
  fullScreen: boolean = false;
  smallIcon: boolean = false;
  isDriverAfterUpdate: boolean = false;
  activeMenu: string = 'home';
  currentLang: string;
  reducedFontLanguages: string[] = ['vi', 'es'];
  initDevice: boolean = false;
  isFinishedSelfTest: boolean = false;
  isReconnectDevice: boolean = false;
  reconnectFromSidebar: boolean = false;
  getQCSignal$: Subject<any> = new Subject<any>()
  QCdata:any
  logoSrc: string;
  constructor(private elRef: ElementRef, private _cdr: ChangeDetectorRef, public authService: AuthService, private http: HttpService, public _glob: GlobalService, public router: Router, public Comm: CommunicatorService, private _translate: TranslateService, private _renderer: Renderer2) {
    this.globalService = _glob
    this.translate = _translate
    this.getQCSignal$.subscribe((data) => {
      this.QCdata = data
    })
    _glob.isReconnect$.subscribe(res => {
      // if (res == true) {
      //   this.isReconnectDevice = res
      // }
      this.initDevice = res
    })
    this.InternetSubscription = _glob.networkMonitor().subscribe((status: any) => {
      this.internetButtons = []
      this.isInternet = status
      this.MESLogoSrc = 'assets/images/mes_man_transparent.png'
      if (!this.isInternet) {
        console.log(_glob.dontShowInternetpopup);
        if (!_glob.dontShowInternetpopup) {
          if ($(".modal").hasClass("show")) {
            $(".modal").modal("hide")
          }
        }
        if (this.router.url.includes('main') || this.router.url.includes('manual-results') || this.router.url.includes('testresults')) {
          this.internetButtons.push({
            fun: this.connectionTryagain,
            name: "general.try_again"
          }, {
            fun: this.back,
            name: "general.close"
          })
        } else if (this.router.url.includes('qc-controls') || this.router.url.includes('test-process')) {
          this.internetButtons.push({
            fun: this.connectionTryagain,
            name: "general.try_again"
          }, {
            fun: this.back,
            name: !_glob.dontShowInternetpopup ? "general.back" : "general.exit_test",
            className: !_glob.dontShowInternetpopup ? "btn btn-primary waves-effect" : "btn btn-danger waves-effect"
          })
          if (!_glob.dontShowInternetpopup)
            $("#Auto_Calibration_Alert").modal("hide");

          // $('#internet-modal').modal('show');

        } else {

          this.internetButtons.push({
            fun: this.connectionTryagain,
            name: "general.try_again"
          }, {
            // fun: this.backtoHome.bind(this),
            fun: () => {
              _glob.hasUnsavedChanges = false
              if (this.isDriverInstalled) {
                this.router.navigate(['/dashboard/main']);
              }
              $('#internet-modal').modal('hide');
            },
            name: this.isDriverInstalled ? "general.back_to_home" : "general.close"
          })
        }
        if (!_glob.dontShowInternetpopup && !$(".connectionlost").hasClass("show"))
          $('#internet-modal').modal('show');

      } else {
        $('#internet-modal').modal('hide');
      }
    })
    this.subscription = authService.user.subscribe((user) => {
      // console.log('current user ', user);
      if (user !== undefined && user !== null) {

        if (user.doctorName !== null) {
          this.doctorName = user.doctorName;
        }
        if (user.userID !== null) {
          this.userID = user.userID;
        }
        if (user.isFacilityAdmin) {
          this.isFacilityAdmin = true;
        } else {
          this.isFacilityAdmin = false;
        }

      }
    });

    this.driverUpdateSubscription = this._glob.isDriverUpdated$.subscribe(res => {
      this.isDriverAfterUpdate = res;
    });

    this.isDisconnected = !this._glob.toggleconnect();

    this._glob.connected.subscribe((e) => {
      // console.log('connected event', e);
      this.isDisconnected = !e;
      if (this.isDisconnected) {
        this._glob.clickedStartSelfTest = false;
        this.selfTestFailed = true;
        this._glob.setSeflTestStatus(false);
        // $('.modal').hide();
        if ($(".modal").hasClass("show")) {
          $('.modal-backdrop').remove();
        }
        $('#gen-pass-selfTest').modal('hide');
        $('#gen-fail-selfTest').modal('hide');
        $('#QC-fail').modal('hide');
        this.globalService.isReconnect$.next(false)
        // if (this.deviceBusy) {
        //   $('#Devicenotconnected').modal('show');
          
        // }
      }
    });



    this._langSubscription = this._translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.currentLang = event.lang;
      this.bindClickLinks();
    });

  }

  bindClickLinks() {
    setTimeout(() => {
      const serviceLink = this.elRef.nativeElement.querySelectorAll('.link-to-service-screen');
      for (let i = 0; i < serviceLink.length; i++) {
        serviceLink[i].addEventListener('click', this.gotoService.bind(this));
      }
      const videoLink = this.elRef.nativeElement.querySelectorAll('.link-to-videos');
      for (let i = 0; i < videoLink.length; i++) {
        videoLink[i].addEventListener('click', this.gotoVideos.bind(this));
      }
      const guideLinks = this.elRef.nativeElement.querySelectorAll('.link-to-service-9');
      for (let i = 0; i < guideLinks.length; i++) {
        guideLinks[i].addEventListener('click', this.gotoTroubleshoot.bind(this));
      }
    }, 100);
  }

  gotoTroubleshoot() {
    this._glob.getTroubleShootSectionUrl().then(url => {
      window.open(url, '_blank');
    });
  }
  ngAfterViewInit() {

    this.activeMenu = 'home';
    this.routerSubscription$ = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (val.url.includes('installation')) {
          this.isDriverInstalled = false;
        } else {
          if (this.Comm.IsDriverConnected()) {
            this.isDriverInstalled = true;
          } else {
            this.isDriverInstalled = false;
          }

        }
        // console.log('in installation page', this.isDriverInstalled);
        // console.log('this url', this.router.url);
        if (val.url === '/dashboard/main') {
          this.activeMenu = 'home';
        };
        if (val.url === '/sementest/test-process') {
          this.activeMenu = 'test-process';
        };
        if (val.url.includes('qc-controls')) {
          this.activeMenu = 'qc-controls';
        };
        if (val.url.includes('archive')) {
          this.activeMenu = 'archive';
        };
        if (val.url.includes('all-patient')) {
          this.activeMenu = 'all-patient';
        };
        if (val.url.includes('service')) {
          this.activeMenu = 'service';
        };
        if (val.url.includes('settings')) {
          this.activeMenu = 'settings';
        };
        if (val.url.includes('support')) {
          this.activeMenu = '';
        };
      }
    });
    if (this._glob.isDevicesnValid()) {
      this.isSNvalid = true;
    }
    // this.http.loadTestDataOnLogin(this.facilityID);
    
    
    this._serviceSubscription = this.Comm.onChange.subscribe({
      next: (event: MyServiceEvent) => {
        if (
          this.Comm.getCurrentClient() == ActiveClient.TEST_PROCESS_CLIENT
        ) {
          if (event.command === "ReadParameters") {
            if (event.value.SelfTest === 1) {
              this.selfTestFailed = false;
              this._glob.setSeflTestStatus(false);
            } else {
              this.selfTestFailed = true;
              this._glob.setSeflTestStatus(true);
            }
          }
          return;
        }
        
        switch (event.command) {  
          case 'DeviceList':
            console.log(event)
            if (event.value.length === 0) {
              $('#reconnect-start').modal('hide');
            } else if (event.value.length > 0) {
              $('#QC-fail').modal('hide');
              $('#reconnect-start').modal('show');
            }
            break;

          case 'reConnectDevices':
            $('#gen-fail-selfTest').modal('hide');
            if (!this.reconnectFromSidebar) {
              $('#QC-fail').modal('hide');
              if (this.Comm.SocketArray.length > 0) {
                $('#reconnect-start').modal('show');
              }
            } 
            break;
          case 'areParsValid':
              this.getQCSignal$.next(event.value)
            break;
          case 'Startoperations':
            //this.OperationState =  event.value.value.operation[event.value.value.operationIndex].STATE;
            console.log("Start operation----------------------------");
            this.isFinishedSelfTest = false
            this.deviceBusy = true;
            this.setProgress(0);
            this.setSubProgress(0);
            if ($(".modal").hasClass("show")) {
              $(".modal:not(#start-selfTest)").modal("hide")
            }
            $('#start-selfTest').modal('show');
            $('#serial-no-error').modal('hide');
            break;

          case 'OperationStatus':
            // console.log("operation in progress", event.value);
            this.setProgress((event.value.value.operationIndex - 1) * 10);

            break;

          case "Finishoperations":
            console.log("finish operation in progress from sidebar");
            this.reconnectFromSidebar = false;
            this.deviceBusy = false;
            this.setProgress(100);
            setTimeout(() => {
              $('#start-selfTest').modal('hide');
              this._glob.clickedStartSelfTest = false;
            }, 1000);
            // console.log('op name ', event.value.value['name']);
            if (event.value.value["name"] === 'selftest') {
              this.isFinishedSelfTest = true
              let selftest = this.Comm.getParameters();
              this.authService.driverVersion = selftest.OBServiceVersion;

              if (selftest.SelfTest == 1) {
                this._glob.selfTest_track = [];
              } else {
                this._glob.selfTest_track.push(event.value)
                this.isReconnectDevice = false
              }
              // this.Comm.areParametersValid()
              this.checkSelftestStatus(selftest);
              if (this.currentUser !== undefined && this.currentUser !== null && this._glob.isDevicesnValid()) {
                let fullData: ServiceData = {
                  serviceDataDate: new Date(),
                  operatorId: this.currentUser.uid,
                  operatorName: this.currentUser.facilityName,
                  userId: this.userID,
                  userName: this.doctorName,
                  serviceDataParameters: selftest,
                  serviceDataType: 'Interface Self-Test',
                  platform: 'interface'
                }

                this.http.addDeviceServiceData(selftest.serialNumber, fullData);
                const deviceData: Device = {};
                deviceData.usingFacility = {
                  facilityId: this.currentUser.uid,
                  facilityName: this.currentUser.facilityName,
                  facilityAddress: {
                    streetNo: '',
                    zipCode: '',
                    city: '',
                    country: ''
                  }
                }
                if (this.currentUser.streetNo !== undefined) {
                  deviceData.usingFacility.facilityAddress.streetNo = this.currentUser.streetNo;
                }
                if (this.currentUser.zipCode !== undefined) {
                  deviceData.usingFacility.facilityAddress.zipCode = this.currentUser.zipCode;
                }
                if (this.currentUser.city !== undefined) {
                  deviceData.usingFacility.facilityAddress.city = this.currentUser.city;
                }
                if (this.currentUser.country !== undefined) {
                  deviceData.usingFacility.facilityAddress.country = this.currentUser.country;
                }
                this._glob.getLocation().then(pos => {
                  // console.log('the getLocation retruned ', pos);
                  deviceData.usingFacility.facilityAddress.geo = {
                    lat: pos.lat,
                    lng: pos.lng
                  }

                  this.http.updateDevicedata(this.currentUser.deviceSN, deviceData, false).then((data: any) => {
                    // console.log("updateddevice", data);
                  });
                }).catch(err => {
                  this.http.updateDevicedata(this.currentUser.deviceSN, deviceData, false).then((data: any) => {
                    // console.log("updateddevice", data);
                  });
                });

              }
            }
            break;
          case "ReadParameters":
            console.log("read parameters sidebar ", event.value);
            if (event.value.SelfTest === 1) {
              this.selfTestFailed = false;
              this._glob.setSeflTestStatus(false);
            } else {
              this.selfTestFailed = true;
              this._glob.setSeflTestStatus(true);
            }
            this.updateDeviceSN();
            break;

          case "Progress":
            $('#reconnect-start').modal('hide');
            if (this.Comm.getCurrentClient() !== ActiveClient.TEST_PROCESS_CLIENT && !$('#start-selfTest').hasClass('show')) {
              this.setProgress(0);
              this.setSubProgress(0);
              // $('#start-selfTest').modal('show');

            }
            //this.myvalue = event.value;
            // console.log('operation progress ', event.value);
            let npoints = event.data.npoints;
            this.setSubProgress(Math.round(100 * Number(event.value) / (npoints)));
            break;

          case "isConnect":
            this.globalService.isReconnect$.next(true)
            if (event.value === true) {
              this.isDriverInstalled = true;
            } else {
              this.isDriverInstalled = false;
            }
            break;

        }
      }
    });
    this.visualizationFullScreenCheck()

    this.bindClickLinks();
    // this._testCreditSubscription = this.http.testCreditNumber.subscribe((tcn) => {
    //   console.log('new test credit number ', tcn);
    //   if (tcn !== undefined && tcn !== null) {
    //   if (tcn >= 1) {
    //     this.noCredits = false;
    //   } else {
    //     this.noCredits = true;
    //   }
    // }
    // });
    this._testCreditSubscription = this.http.facilityUpdates.subscribe((fUpdate) => {
      // console.log("f value changes", fUpdate);

      if (fUpdate !== null && fUpdate !== undefined) {
        this.currentUser = fUpdate;
        this.facilityName = fUpdate.facilityName;
        this.facilityID = fUpdate.uid;

        if (fUpdate.logoURL === null || fUpdate.logoURL === '' || fUpdate.logoURL === undefined) {
          // this.photo = '/assets/images/user/userdefault.png';
          this.photodefined = false;
        } else {
          this.photo = fUpdate.logoURL;
          this.photodefined = true;
        }

        if ((fUpdate.testCount !== undefined && fUpdate.testCount !== 0) || (fUpdate.qcTestCount !== undefined && fUpdate.qcTestCount !== 0) || (fUpdate.proficiencyTestCount !== undefined && fUpdate.proficiencyTestCount !== 0)) {
          this.noRecords = false;
        } else {
          this.noRecords = true;
        }

        // console.log('new test credit number ', fUpdate.testCredits);
        if (fUpdate.testCredits !== undefined && fUpdate.testCredits !== null) {
          if (fUpdate.testCredits >= 1) {
            this.noCredits = false;
          } else {
            this.noCredits = true;
          }
        }
      }

    })

  }
  
  ngOnInit() {
    // this.Comm.setCurrentClient(ActiveClient.ALL);
    // this.visualizationFullScreenCheck()
    this.logoSrc = !this.authService.iOwMode ? 'assets/images/SQA-iO_Logo_BW.png' : 'assets/images/SQA-iOw/SQA-iOw_Logo_White.png';
    this.sidemenuCollapse();
    this.initLeftSidebar();
    this.startUp()
    // console.log('this url', this.router.url);
    if (this.router.url.includes('installation') || !this.Comm.IsDriverConnected()) {
      this.isDriverInstalled = false;
    } else {
      this.isDriverInstalled = true;
      this.Comm.ReadParameters();
    }

    if (this._glob.isDevicesnValid()) {
      this.isSNvalid = true;
    }

    $('#gen-pass-selfTest').on('hidden.bs.modal',() =>{
      this.globalService.isReconnect$.next(false)
    });


  }

  startUp() {
    if (localStorage.getItem("sidebar_collapsed")) {
      if (localStorage.getItem("sidebar_collapsed") === 'true') {
        this.smallIcon = true;
      } else {
        this.smallIcon = false;
      }
      // this.isLogoSmall = localStorage.getItem("sidebar_collapsed");
    } else {
      this.smallIcon = false;
    }

  }

  ngOnDestroy() {
    $('#gen-pass-selfTest').off('hidden.bs.modal');
    this.subscription.unsubscribe();
    this._serviceSubscription.unsubscribe();
    this.routerSubscription$.unsubscribe();
    this._langSubscription.unsubscribe();
    this._testCreditSubscription.unsubscribe();
    this.InternetSubscription.unsubscribe();
    this.driverUpdateSubscription?.unsubscribe();
  }

  setProgress(Progressvalue) {
    if (Progressvalue > 100) return;
    this.ProgressValue = Progressvalue;
    this.sProgressValue = Progressvalue.toString() + "%";
    // console.log(' progress ', this.sProgressValue);
  }

  setSubProgress(Progressvalue) {
    this.SubProgressValue = Progressvalue;
    this.sSubProgressValue = Progressvalue.toString() + "%";
    // console.log(' progress ', this.sProgressValue);
  }

  onPassModalDismiss() {
    this.globalService.isReconnect$.next(false);
    this.globalService.isDriverUpdated$.next(false);
  }

  initLeftSidebar() {

    var _this = this;
    var $body = $("body");
    var $overlay = $(".overlay");

    //Close sidebar
    $(window).on("click", function (e) {
      var $target = $(e.target);
      if (e.target.nodeName.toLowerCase() === "i") {
        $target = $(e.target).parent();
      }

      if (
        !$target.hasClass("bars") &&
        _this.isOpen() &&
        $target.parents("#leftsidebar").length === 0
      ) {
        if (!$target.hasClass("js-right-sidebar")) $overlay.fadeOut();
        $body.removeClass("overlay-open");
      }
    });

    $.each($(".menu-toggle.toggled"), function (i, val) {
      $(val)
        .next()
        .slideToggle(0);
    });
    //Collapse or Expand Menu
    $(".menu-toggle").on("click", function (e) {
      var $this = $(this);
      var $content = $this.next();

      if ($($this.parents("ul")[0]).hasClass("list")) {
        var $not = $(e.target).hasClass("menu-toggle")
          ? e.target
          : $(e.target).parents(".menu-toggle");

        $.each(
          $(".menu-toggle.toggled")
            .not($not)
            .next(),
          function (i, val) {
            if ($(val).is(":visible")) {
              $(val)
                .prev()
                .toggleClass("toggled");
              $(val).slideUp();
            }
          }
        );
      }

      $this.toggleClass("toggled");
      $content.slideToggle(320);
    });

    //Set menu height
    _this.setMenuHeight(true);
    _this.checkStatuForResize(true);
    $(window).resize(function () {
      _this.setMenuHeight(false);
      _this.checkStatuForResize(false);
    });

    //Set Waves
    Waves.attach(".menu .list a", ["waves-block"]);
    Waves.init();

  }
  setMenuHeight(isFirstTime) {
    if (typeof $.fn.slimScroll != "undefined") {
      var height = $(window).height() - $(".navbar").innerHeight();
      var $el = $(".list");

      $el.slimscroll({
        height: height + "px",
        color: 'rgba(0,0,0,0.5)',
        size: '4px',
        alwaysVisible: false,
        borderRadius: '0',
        railBorderRadius: '0'
      });

    }
  }

  isOpen() {
    return $('body').hasClass('overlay-open');
  }
  closeFac(checkBlocking: boolean) {
    if (checkBlocking) {
      // console.log("checkBlocking", checkBlocking);

      this._glob.hasUnsavedChanges = false;
      this._glob.navToFacilitySet = true;
      this._glob.callFactilitySettings();
    }
    $('#unsaved-data-modal-fac').modal('hide');
  }
  checkStatuForResize(firstTime) {
    var $body = $('body');
    var $openCloseBar = $('.navbar .navbar-header .bars');
    var width = $body.width();

    if (firstTime) {
      $body.find('.content, .sidebar').addClass('no-animate').delay(1000).queue(function () {
        $(this).removeClass('no-animate').dequeue();
      });
    }

    if (width < 1465) {
      document.body.classList.remove("side-closed-hover")
      // document.body.classList.add("side-closed", "submenu-closed");
      // this.collapseSideBar()
      this.smallIcon = true
    }
    else {
      if (this.fullScreen) return;
      this.smallIcon = false
      this.collapseSideBar()
      document.body.classList.remove("side-closed", "submenu-closed")
    }

  }

  sidemenuCollapse() {
    const self = this
    $(".sidemenu-collapse").on("click", function () {
      if (document.body.classList.contains("side-closed")) {
        document.body.classList.remove("side-closed", "submenu-closed")
        self.smallIcon = false
      } else {
        document.body.classList.add("side-closed", "submenu-closed");
        self.smallIcon = true
      }
    });
    $(".content, .navbar").mouseenter(function () {
      var $body = $("body");
      $body.removeClass("side-closed-hover");
      $body.addClass("submenu-closed");
      if ($("body").hasClass("side-closed")) {
        $(".sidebar-user-panel").css({ display: "none" });
      }
    });
    $(".sidebar").mouseenter(function () {

      if (!self.fullScreen) {
        var $body = $("body");
        if ($body.width() <= 1028 && $("body").hasClass("side-closed")) return;
        if ($body.width() > 1028) {
          $body.addClass("side-closed-hover");
        }
        $body.removeClass("submenu-closed");
        $(".sidebar-user-panel").css({ display: "block" });
      }
    });
    if (localStorage.getItem("sidebar_option")) {
      jQuery("body").addClass(localStorage.getItem("sidebar_option"));
    }
    if ($("body").hasClass("side-closed")) {
      $(".sidebar-user-panel").css({ display: "none" });
    } else {
      $(".sidebar-user-panel").css({ display: "block" });
    }
    jQuery(document).on("click", ".sidemenu-collapse", function () {
      var sidebar_option = "";
      if ($("body").hasClass("side-closed")) {
        var sidebar_option = "side-closed submenu-closed";
        $(".sidebar-user-panel").css({ display: "none" });
      } else {
        $(".sidebar-user-panel").css({ display: "block" });
      }
      jQuery("body").addClass(sidebar_option);
      localStorage.setItem("sidebar_option", sidebar_option);
    });

  }

  goToFacilityProfile() {
    if (this.isFacilityAdmin) {
      this.router.navigate(['/sementest/settings'], { fragment: 'facility_settings' })
    }
  }

  updateDeviceSN() {
    let devices = this.Comm.SocketArray;
    let serialNo = devices[0].SN;
    this.http.updateFacilityDevice({ deviceSN: serialNo }).then((snAdded) => {
    }).catch(err => {
      console.log(err);
    })
  }


  async checkSelftestStatus(selftestPara) {
    this._glob.toggleUsb();
    $('#Deviceconnected').modal('hide');

    //check if device SN is valid
    if (this._glob.isDevicesnValid()) {
      this.isSNvalid = true;
      $('#serial-no-error').modal('hide');
        if (selftestPara.length != 0) {
          let isselftestPassed = Boolean(this.Comm.Isselftest())
          console.log("%c Self test status -----> " ,"color: red; font-weight: bold" , isselftestPassed);
          console.log("%c QC Data ------> ","color: red; font-weight: bold", this.QCdata)

          //@@@@@@ Self test passed block
          if (isselftestPassed) {
            // if Self test passed, and Signal QC also passed or ignored, proceed with self test passed/cleaning flow
            if (this.QCdata?.status === '0' || this.QCdata?.status === '-1' || this.QCdata?.status === undefined ) {
              this.http.getRef2Cleaning().then(async ref2ref => {
                if (selftestPara.ref2 >= ref2ref.data.ref2) {
                  $('#gen-pass-selfTest').modal('show');
                } else {
                  this.showServiceButton = this.router.url.includes('service') ? false : true;
                  $('#ref2_cleaning').modal('show');
                }
              })
              // If self test passed and signal QC failed - very rare condition. 
              // Condtiion added here for possible updates. Meanwhile, proceed with self test passed/cleaning flow.
            } else if (this.QCdata?.status === '1') {
              this.http.getRef2Cleaning().then(async ref2ref => {
                if (selftestPara.ref2 >= ref2ref.data.ref2) {
                  $('#gen-pass-selfTest').modal('show');
                } else {
                  this.showServiceButton = this.router.url.includes('service') ? false : true;
                  $('#ref2_cleaning').modal('show');
                }
              })
            }

          //@@@@@@ Self test failed block
          } else {
            // if Signal QC passed or disabled, proceed with self-test failed/intensive clean flow
            if (this.QCdata?.status === '0' || this.QCdata?.status === '-1' || this.QCdata?.status === undefined) {
             if (this._glob.selfTest_track.length >= 3) {
                this._glob.doIntensivecleaningCheck(this._glob.selfTest_track)
              } else {
                this.showServiceButton = this.router.url.includes('service') ? false : true;
                // if (!$('#intensive_cleaning').is(':visible')) {
                  $('#gen-fail-selfTest').modal('show');
                // }
              }
            // If Signal QC failed where self test failed, it triggers the reconnect failure flow
            // Display reconnect failed pop-up. If failed for more than 3 times, display repeating reconnect failure pop-up
            } else if (this.QCdata?.status === '1') {
              this._glob.reconnectTrack.push(selftestPara);
              if (this._glob.reconnectTrack.length >= 3) {
                $('#reconnect_failures').modal('show');
                this._glob.reconnectTrack = [];
              } else {
                $('#gen-fail-reconnect').modal('show');
                this._glob.reconnectTrack.push(selftestPara);
              }
            }
          }
        }
      } else {
        this.isSNvalid = false;
        this._glob.reconnectTrack.push(selftestPara);
        if (this._glob.reconnectTrack.length >= 3) {
          $('#reconnect_failures').modal('show');
          this._glob.reconnectTrack = [];
        } else {
          $('#QC-fail').modal('show');
        }
    }
  }

  // startSelfTest() {
  //   // this.Comm.MonitortestPoints(0);
  //   this.setProgress(0);
  //   this.Comm.StartSelftest(4000);
  //   // this.Comm.setCurrentClient(ActiveClient.TEST_PROCESS_CLIENT);
  //   // this.Comm.StartSelftest(this.NumberofSamplesPoints);
  //   // console.log("number of samplepoints", this.NumberofSamplesPoints);
  // }

  gotoService() {
    $('#gen-pass-selfTest').modal('hide');
    $('.modal-backdrop').remove();
    this.router.navigate(['//sementest/service']);
  }
  gotoVideos() {
    $('#gen-pass-selfTest').modal('hide');
    $('.modal-backdrop').remove();
    this.router.navigate(['//support/help'], { queryParams: { tabParam: 'Cleaningvideo' } });
  }
  gotoFAQ() {
    $('.modal-backdrop').remove();
    this.router.navigate(['/support/help']);
  }

  connectionTryagain() {
    this.http.checkconn().then((res: any) => {
      $("#internet-modal").modal("hide");
    }).catch((err) => {
      $("#internet-modal").modal("show");
    })
  }

  backtoHome() {
    this.router.navigate(['/dashboard/main']);
    $('#internet-modal').modal('hide');
  }

  back() {
    $("#internet-modal").modal("hide");
  }
  readSensor() {
    this.Comm.ReadCapSensor(this.selectedPortindex)
  }

  visualizationFullScreenCheck() {
    this.globalService.fullScreenClicked$.subscribe(isFullScreen => {
      var $body = $("body");
      this.fullScreen = isFullScreen
      this.smallIcon = isFullScreen
      if (isFullScreen) {
        console.log(document.body.classList);
        document.body.classList.remove("side-closed-hover")
        document.body.classList.add("side-closed", "submenu-closed");
        document.querySelector('app-header').classList.add('displayNone');
        document.querySelector('section.content').classList.add('noMargin');
        // this._renderer.addClass(this.sideBar.nativeElement, "no-pointers")
        // this._renderer.setStyle(this.reorder.nativeElement, "pointer-events", "none")
      }
      else {
        document.body.classList.remove("side-closed", "submenu-closed")
        document.querySelector('app-header')?.classList?.remove('displayNone');
        document.querySelector('section.content')?.classList?.remove('noMargin');
        // this._renderer.removeClass(this.sideBar.nativeElement, "no-pointers")
        // this._renderer.removeStyle(this.reorder.nativeElement, "pointer-events")
      }
      // this.collapseSideBar()
    })
  }

  collapseSideBar() {
    // this.smallIcon = !this.smallIcon;
    localStorage.setItem("sidebar_collapsed", String(this.smallIcon));
  }

  onMenuClick(menuItem: string) {
    // this.activeMenu = menuItem;
  }

  iconClicked() {
    if (this.fullScreen) {
      this.globalService.fullScreenClicked$.next(false);
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }

  reconnectDriver() {
    console.log('Reconnect triggered from sidebar.............')
    this.reconnectFromSidebar = true;
    this.Comm.reconnectDevices();
    this._glob.isReconnect$.next(true)
    // this.Comm.TestCapStatus(this.selectedPortindex)
  }



}
