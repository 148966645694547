import { Component, OnInit, NgZone, ChangeDetectorRef, HostListener } from "@angular/core";
// import { CommunicatorService, MyServiceEvent, ActiveClient } from './Services/communication.service';
import { Title } from "@angular/platform-browser"

import {
  Event,
  Router,
  NavigationStart,
  NavigationEnd,
  RouterEvent
} from "@angular/router";

import { AuthService } from './core/auth.service';
import { LanguageService } from './services/language.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { BnNgIdleService } from 'bn-ng-idle';
import { interval, Observable, fromEvent, merge, Subscription } from 'rxjs';
import { GlobalService } from "./services/global.service";
import { autoLogOutData } from './globals/globals';
import { first } from "rxjs/operators";

declare const $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  currentUrl: string;
  showLoadingIndicatior = false;

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  title = 'angular-idle-timeout';
  modalTitle = 'SESSION EXPIRE WARNING';
  warningTitle: string;
  expiredTitle: string;
  countdown: any;
  interval: any;
  compareTime: any;
  sleepinterval: any;
  stoppedTimer = false;

  isConnected = true;
  noInternetConnection: boolean;
  isUserAuthenticated: boolean = false;

  cntdownPopupShown = false;
  sessionLogoutTime: number;
  autoLogOutActive: boolean = true;
  logoutCountdown: number;
  timeLapsedSinceInactivity: number = 0;
  isInactivityTimeOut = false;

  timeoutSubscription$: Subscription;
  countersubscription$: Subscription;
  isUserAuthenticated$: Subscription;
  observeable$: Observable<any>;
  mergedObservable$: Observable<any>;
  public inactivityTimerEvent: Array<any>[] = [[document, 'click'],
                                      [document, 'wheel'],
                                      [document, 'scroll'],
                                      [document, 'mousemove'],
                                      [document, 'keyup'],
                                      [window, 'resize'],
                                      [window, 'scroll'],
                                      [window, 'mousemove']
                                     ];

  constructor(private bnIdle: BnNgIdleService,
    public translate: TranslateService,
    private language: LanguageService,
    public _router: Router,
    private authService: AuthService,
    public _ngZone: NgZone,
    public _cd: ChangeDetectorRef,
    public global: GlobalService,
    private titleService: Title) {

  }

  ngOnInit() {
    // console.clear();

    console.log(navigator.language);

    this.global.loader$.subscribe(res=>{
      this.showLoadingIndicatior = res
    })

    
    // Modifications for SQA-ioW
    // Match page title anf favicon to SQA-iO/iOw mode
    if (this.authService.iOwMode === true) {
      this.titleService.setTitle('MES SQA-iOw System')
      this.changeFavicon('assets/images/SQA-iOw/SQA-iOw_favicon.png');

      // load alternate tranlation json file for SQA-iOw captions
      this.translate.getTranslation('enw').pipe(first()).subscribe(lng => {
        this.translate.setTranslation('en', lng);
        this.translate.use('en');
      });

    } else if (this.authService.iOwMode === false){
      this.changeFavicon('assets/images/SQA-iO-ICON.ico');
      this.titleService.setTitle('MES SQA-iO System');
    }

    // Subscription to authantication state, to toggle visibility state of SIdebar & header
    this.isUserAuthenticated$ = this.authService.user.subscribe(_user => {
      if (_user === null) {
        this.isUserAuthenticated = false;
        this.global.selfTest_track = [];
        this._router.navigate(['/']);
      } else {
        this.isUserAuthenticated = true;
      }
    });

    // let counter = window.localStorage.getItem('opentabs');
    // if(counter !== null) {
    //   let c = Number(counter) + 1;
    //   counter = c.toString();
    // } else {
    //   counter = "1";
    // }
    // window.localStorage.setItem('opentabs', counter);
    // if(Number(counter) > 1) {
    //   alert('The SQA-iO app is open in another window and cannot be duplicated. Please use it there.');
    //   window.location.href="https://mes-global.com/";

    // }

    this.language.getDefaultLang();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.updateTranslations();
      // this.bindClickLinks();
    });
    this.updateTranslations();

    this.mergedObservable$ = new Observable<any>();
    let observableArray$: Observable<any>[] = [];
    this.inactivityTimerEvent.forEach(x => {
      observableArray$.push(fromEvent(x[0], x[1]))
    })
    this.mergedObservable$ = merge(...observableArray$);

    this.startTimer();

    this.timeoutSubscription$ = this.authService.getDuration().subscribe(_autoLogOutData => {
      this.autoLogOutActive = _autoLogOutData.isLogOutActive;
      this.sessionLogoutTime = _autoLogOutData.timeoutDuration;
      if (this.autoLogOutActive) {
        this.logoutCountdown = 5*60-1+this.sessionLogoutTime/1000;
        this.modalTitle = this.warningTitle;
        this.cntdownPopupShown = false;
        console.log('session user log in');
        this.timedOut = false;
        let inactivityTimeCount = new Date();
        window.sessionStorage.setItem('timeout', inactivityTimeCount.toString());
        this.setCountdown();
        // this.setWaitCountdown();
      } else {
        if(this.compareTime) {
          clearInterval(this.compareTime);
        }
        console.log('Session timeout inactive');
      }


    })

    this.countersubscription$ = this.authService.getUserLoggedIn().subscribe(isloggedin => {
      if (!isloggedin) {
        clearInterval(this.compareTime);
        this.modalTitle = this.expiredTitle;
        this.timedOut = true;
        console.log('session user log out');
        this._router.navigate(['/']);
      } else if (isloggedin) {
      };
    })

    
    window.onbeforeunload = () =>{
      window.sessionStorage.setItem('sessionEnded','true');
      this.global.logoutSetFlags();
      this.authService.signOut();
      // window.localStorage.setItem('opentabs', '0');

    }
  }
  
  createObserable(): void {
    // console.log('create observable');
    this._ngZone.runOutsideAngular(() => {

      this.mergedObservable$.subscribe(val => {
        if(!this.cntdownPopupShown) {
          // console.log('in subscribe and set new time count');
          let inactivityTimeCount = new Date();
          window.sessionStorage.setItem('timeout', inactivityTimeCount.toString());
        }
      });

    })

  }

  setCountdown() {
    this.compareTime = setInterval(() => {
      let timeout = new Date(window.sessionStorage.getItem('timeout'));
      let currenttime = new Date().getTime();
      let secCounter = Math.floor((timeout.getTime() - currenttime + this.sessionLogoutTime * 60000) / 1000); // Convert sessionLogoutTime to milliseconds
  
      // console.log('sec till timeout ', secCounter);
  
      if (currenttime - this.sessionLogoutTime * 60000 > timeout.getTime()) {
        this.modalTitle = this.expiredTitle;
        this.timedOut = true;
        this.global.driverUpdatePopup = true;
        clearInterval(this.compareTime);
        if (localStorage.getItem('loggedIn') === 'true')$('#idle').modal('show');
        this.signout();
      } else {
        if (this.sessionLogoutTime > 5) {
          if (timeout.getTime() - currenttime + this.sessionLogoutTime * 60000 <= 300000) {
            this.modalTitle = this.warningTitle;
            $('#idle').modal('show');
            this.cntdownPopupShown = true;
    
            let minutes = Math.floor(secCounter / 60);
            let seconds = secCounter % 60;
    
            this.countdown = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    
            if (secCounter > 0) {
              secCounter--; // Reduce secCounter by 1 second
            }
          }
        } 
      }
    }, 1000);
  }

  setWaitCountdown() {
    // console.log('in set wait cnt ', new Date());
    let cntdwn = this.logoutCountdown;
    this.countdown = '5' + ':' + '00';
    this.interval = setInterval(() => {
      if (this.isInactivityTimeOut) {
        this.modalTitle = this.warningTitle;
        $('#idle').modal('show');
        this.stoppedTimer = true;
      }
      // console.log('session cntdwn ', cntdwn);
      if(cntdwn > 0) {
        let s = (cntdwn%60).toString();
        while (s.length <  2) {s = "0" + s;}
        this.countdown = Math.floor(cntdwn/60) + ':' + s;
        cntdwn--;
      } else {
        clearInterval(this.interval);
        this.modalTitle = this.expiredTitle;
        this.timedOut = true;
        this.signout();
      }
    },1000)

    // this.bnIdle.resetTimer();
  }



  startTimer() {
    this.createObserable();
    // console.log('subscription started');
  }


  signout() {
    // clearInterval(this.compareTime);
    this.isInactivityTimeOut = false;
    this.global.logoutSetFlags();
    this.global.selfTest_track = [];
    this.authService.signOut();
  }

  stay() {
    $('#idle').modal('hide');
    this.cntdownPopupShown = false;
    // clearInterval(this.interval);
    // this.setCountdown();
    // this.setWaitCountdown();
    let inactivityTimeCount = new Date();
    window.sessionStorage.setItem('timeout', inactivityTimeCount.toString());
    this.stoppedTimer = false;
    this.timedOut = false;
    this.isInactivityTimeOut = false;
  }

  updateTranslations() {
    this.translate.get('auth.session_title').subscribe(res => {
      this.warningTitle = res;
    });
    this.translate.get('auth.timeout_title').subscribe(res => {
      this.expiredTitle = res;
    });
  }

  changeFavicon(faviconURL: string) {
    const link: HTMLLinkElement = document.querySelector('link[rel="icon"]');
    if (link) {
      link.href = faviconURL;
    } else {
      // If link element is not found, create a new one
      const newLink: HTMLLinkElement = document.createElement('link');
      newLink.rel = 'icon';
      newLink.href = faviconURL;
      document.head.appendChild(newLink);
    }
  }

}
