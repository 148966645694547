import { PrepType } from './../globals/globals';
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from "@angular/fire/firestore";
import { database, analytics } from "firebase";
//import { read } from 'fs';
//import { Observable } from 'rxjs';
//import { map } from 'rxjs/operators';
import * as firebase from "firebase/app";
import "firebase/storage";
import { AuthService } from "../core/auth.service";
//import { AppError } from '../_helpers/app-error';
import { FileTypeError } from "../_helpers/file-type-error";
import {
  Patient,
  Test,
  Device,
  SQACustomer,
  ControlsLot,
  LatexBeadsData,
  TestSettings,
  SQAUser,
  ProficiencySample,
  VUSettings,
} from "../globals/globals";
import { BehaviorSubject, Observable, Subject, of, throwError } from "rxjs";
import { EmailNotAvailableError } from "../_helpers/email-not-available-error";
import { environment } from "src/environments/environment";
import { catchError, first, shareReplay, take } from "rxjs/operators";
import { EncryptionService } from './encryption.service';

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers":
      "*,Origin, Content-Type, X-Auth-Token,AUTHORIZATION",
    "Access-Control-Allow-Methods": "GET, POST, PATCH, DELETE, PUT, OPTIONS",
  }),
};


export const signaltype = {

  AUTO_CALIBRATION: 0,
  TEST: 1,
  SELF_TEST: 2,
  TEST_SN: 3,
  TEST_PARAMETERS:4,
}


@Injectable({
  providedIn: "root",
})



export class HttpService {
  db = firebase.firestore();
  currentFacilityId: string;
  currentFacilityName: string;
  currentFacilityTotalTests: number;
  testsSnapshot: any;
  patientsSnapshot: any;
  currentFacilityEmail: string;
  currentFacilityType: string;
  realtimeNotifications: Observable<any[]>;
  isFacilityAdmin: boolean;
  currentUserId: string;
  currentUserName: string;
  currentUserEmail: string;
  currentWHOSetting: number;
  systemSettings: any;
  facilityUpdates = new Observable<any>();
  viewLastArchivePage: boolean = false;
  isConnected = true;
  public cptcCheck_k: string;
  creditError: string = "Error: an error has occured in credit loading";
  lowQualityCriteria: { MSC: Number, TSC: Number, LV_MSC: Number } = { MSC: 2, TSC: 2, LV_MSC: 0.2 };
  VUSettings: VUSettings;
  apiEP = "api";
  private _sKey: string;

  public allFacilityTestsData$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public isPatientDataUpdating$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  //Videoanalysis file upload - observable
  uploadProgress$ = new Subject<any>();

  constructor(
    private _http: HttpClient,
    private afs: AngularFirestore,
    private authService: AuthService,
    private encryptionService: EncryptionService
  ) {
    const hostname = window.location.hostname.toString().toLowerCase();
    if (hostname.includes("localhost")) {
      this.apiEP = "api";
    } else {
      this.apiEP = "https://us-central1-messqasystem.cloudfunctions.net";
    }

    this.getSKey();

    this.authService.user.subscribe((user) => {
      if (user !== null && user !== undefined) {
        this.isFacilityAdmin = user.isFacilityAdmin;
        this.currentUserId = user.userID;
        this.currentUserName = user.doctorName;
        this.currentUserEmail = user.email;
        this.currentFacilityId = user.facilityID;
        this.getVauleChanges();

        this.getFacility(user).then((f) => {
          // this.currentFacilityId = f.id;
          this.currentFacilityName = f.data.facilityName;
          this.currentFacilityEmail = f.data.email;
          this.currentFacilityType = f.data.customerType;
          this.currentFacilityTotalTests = f.data.totalTestCount;

          
          // this.lowQualityCriteria = f.data.lowQualityCriteria;
          // this.currentWHOSetting = f.data.testSettings.who;
          // this.VUSettings = f.data.testSettings.VUSettings;
          // this.systemSettings = f.data.testSettings.systemSettings;
          // this.viewLastArchivePage = f.data.testSettings.systemSettings.archivePageHistory;
        });

        // this.getVauleChanges();
        this.facilityUpdates.subscribe((f) => {
          !environment.production && console.log('current fac in http susbscr ', f);
          this.lowQualityCriteria = f.lowQualityCriteria;
          this.currentFacilityName = f.facilityName;
          this.currentFacilityEmail = f.email;
          this.currentFacilityType = f.customerType;
          this.currentWHOSetting = f.testSettings?.who;
          this.VUSettings = f.testSettings?.VUSettings;
          this.systemSettings = f.testSettings?.systemSettings;
          this.viewLastArchivePage = f.testSettings?.systemSettings.archivePageHistory;
        });

        // this.getSnapshots();
      }

    });
  }

  public getFacility(loggedInUser) {
    let fid = loggedInUser?.facilityID;
    // console.log('ret fac id', fid)
    return this.getCollectionData("SQACustomers", fid);
  }

  public checkconn() {
    let url =
      // "https://us-central1-messqasystem.cloudfunctions.net/checkInternetConnection";
      "https://us-central1-messqasystem.cloudfunctions.net/checkNetworkConnection";
    return this._http.get(url).toPromise();
  }


  // public signalQC(data, signalType): Observable<any> {
  //   //const url = "http://localhost:8080/signalQC"
  //   const url = "https://signalprocessing-ldoxyjbqhq-uc.a.run.app/signalQC"
  //   const headers = { 'content-type': 'application/json' }
  //   const buf = { 'data': data, 'signaltype': signalType };
  //   const body = JSON.stringify(buf);
  //   return this._http.post(url, body, { 'headers': headers })
  // }

  public signalQC(data, signalType, parameters?): Observable<any> {
    //const url = "http://localhost:8080/signalQC"
    const url = "https://signalqc.mes-data.app/signalQC"
    const headers = { 'content-type': 'application/json' }

    //console.log("signalQC Parameters from http service ",parameters)

    let buf = {}
    if (parameters == undefined) {
      buf = { 'data': data, 'signaltype': signalType };
    } else {
      buf = { 'data': data, 'signaltype': signalType, 'Parameters': parameters };
    }
    const body = JSON.stringify(buf);
    return this._http.post(url, body, { 'headers': headers }).pipe(
      take(1),
      catchError(error => {
        console.error('Signal QC failed ' ,error);
        return throwError(error);
      })
    );
  }

  public getFacilityTestsFromAPI(facilityId: string) {
    const url = `https://iogeneralservices-ldoxyjbqhq-uc.a.run.app/getFacilityTests`;
    return new Promise<any>((res, rej) => {
      this._http.post(url, {facilityId}).pipe(take(1)).subscribe(tests => {
        res(tests);
      }, 
      error => {
        rej(error)
      });
    })

  }


  // ====================================================================================================
  // Global and Generic functions
  // ====================================================================================================

  private getVauleChanges() {
    // this.afs.collection<any>('SQACustomers').valueChanges().subscribe(rt => {
    //   console.log('real time facilities ', rt);
    // });
    // console.log('in value changes');
    this.facilityUpdates = this.afs
      .collection("SQACustomers")
      .doc(this.currentFacilityId)
      .valueChanges();
    this.realtimeNotifications = this.afs
      .collection<any>("Notifications")
      .valueChanges();
  }
  // private getSnapshots() {
  //   return new Promise<any>((res, rej) => {
  //     this.db
  //       .collection("SQACustomers")
  //       .doc(this.currentFacilityId)
  //       .collection("patients")
  //       .onSnapshot(
  //         async (patientsSnapshot) => {
  //           let tests: any = [];
  //           let patients: any = [];
  //           for (const patient of patientsSnapshot.docs) {
  //             if (patient.id !== "stats") {
  //               patients.push({ id: patient.id, data: patient.data() });
  //               this.db
  //                 .collection("SQACustomers")
  //                 .doc(this.currentFacilityId)
  //                 .collection("patients")
  //                 .doc(patient.id)
  //                 .collection("tests")
  //                 .onSnapshot(
  //                   async (testSnapshot) => {
  //                     for (const test of testSnapshot.docs) {
  //                       if (test.id !== "stats") {
  //                         await tests.push({
  //                           id: patient.id,
  //                           data: patient.data(),
  //                           testid: test.id,
  //                           testdata: test.data(),
  //                         });
  //                       }
  //                     }
  //                   },
  //                   (error) => {
  //                     console.log("Error getting snapshot: ", error);
  //                   }
  //                 );
  //             }
  //           }
  //           // return (await markers);
  //           this.patientsSnapshot = await patients;
  //           this.testsSnapshot = await tests;
  //           res({ allTests: this.testsSnapshot, allPatients: this.patientsSnapshot });
  //         },
  //         (error) => {
  //           console.log("Error getting snapshot: ", error);
  //           rej(error);
  //         }
  //       );

  //   })
  // }

  public loadArchiveTestData(facilityID: string) {
    const testsArray: any[] = [];
    this.allFacilityTestsData$.next(['fetching....']);
    this.db.collectionGroup('tests')
      .where('facilityId', '==', facilityID)
      .get()
      .then(async (allTests) => {
        !environment.production && console.log('FETCHING TESTS FOR ARCHIVE: ---------->', testsArray);
        if (allTests.empty) {
          this.allFacilityTestsData$.next(['empty']);
        } else {
          for (const test of allTests.docs) {
            // @TODO - uncomment to restore condition whan ART mode is active again.
            // // check if the test is ART mode. if true, then push to the array only post-prep tests with no linked test (no pre-prep)
            // if (test.data().testingMode === 2)  { 
            //   if (test.data().prepType === 1) {
            //     if(!test.data().linkedTest) {
            //       testsArray.push({ testid: test.id, testdata: test.data() });
            //     }
            //   } else { // test is pre-prep, so push it to the array 
            //     testsArray.push({ testid: test.id, testdata: test.data() });
            //   }
            // } else { // any other test type - push to the array normally
            //   testsArray.push({ testid: test.id, testdata: test.data() });
            // }
            testsArray.push({ testid: test.id, testdata: test.data() });
          }
          
          !environment.production && console.log('ARCHIVE AFTER PROCESSING: ---------->', testsArray);
          this.allFacilityTestsData$.next(testsArray);
        }
      })
      .catch(error => {
        console.error('Failed fetching tests for facility:', error);
      });
      
  }

  public getCollectionList(collectionpath) {
    const markers: any = [];

    return new Promise<any>((res, rej) => {
      this.afs
        .collection(collectionpath)
        .get()
        .subscribe((snapshot) => {
          // console.log('collection list ', snapshot.docs);

          snapshot.forEach((doc) => {
            if (doc.id !== "stats") {
              markers.push({ id: doc.id, data: doc.data() });
            }
          });
          //return markers;
          res(markers);
        });
    });
  }

  public getCollectionData(collectionpath, docid) {
    return new Promise<any>((res, rej) => {
      this.afs
        .collection(collectionpath)
        .doc(docid)
        .get()
        .subscribe((doc) => {
          if (doc.exists) {
            // console.log('collection data ', doc.data());
            res({ id: doc.id, data: doc.data() });
          } else {
            // console.log("No such document!");
            rej("No such document!");
          }
        });
    });
  }

  public addCollectionItam(collectionpath, data) {
    return new Promise<any>((res, rej) => {
      this.afs
        .collection(collectionpath)
        .add(data)
        .then((doc) => {
          // console.log({ "id": doc.id, "data": data });
          res(doc);
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
          rej(error);
        });
    });
  }

  public updateCollectionData(collectionpath, Itemid, itemData) {
    return new Promise<any>((res, rej) => {
      this.afs
        .collection(collectionpath)
        .doc(Itemid)
        .set(itemData, { merge: true })
        .then((docRef) => {
          // console.log({ "collectionPath": collectionpath, "id": Itemid, "data": itemData });
          res({ id: Itemid, data: itemData });
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
          rej(error);
        });
    });
  }

  public deleteCollectionItem(collectionpath, Itemid) {
    return new Promise<any>((res, rej) => {
      this.afs
        .collection(collectionpath)
        .doc(Itemid)
        .delete()
        .then((docRef) => {
          // console.log({ "collectionPath": collectionpath, "id": Itemid });
          res(docRef);
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
          rej(error);
        });
    });
  }

  public getTotalNumber(collection) {
    return new Promise<any>((res, rej) => {
      this.afs
        .collection(collection)
        .get()
        .subscribe(async function (querySnapshot) {
          let totalRequests = querySnapshot.docs.length;
          res({ number: totalRequests });
        });
    });
  }

  private async calculateAverageTestsperDay(totalTestCount: Number): Promise<Number> {
    const res = await this.getFirstTestForFacility()
    if (res > 0) {
      const timeDiff = +new Date() - res.testdata.dateTime.toDate() // tme difference
      const days = Math.ceil(timeDiff / (1000 * 60 * 60 * 24)) //divide by per day milli seconds
      return Math.round(Number(totalTestCount) / days)
    } else {
      return 0;
    }
  };

  // ====================================================================================================
  // End of Global and Generic functions
  // ====================================================================================================

  public getSystemVersions() {
    return this.getCollectionData("System", "versions");
  }

  public async setFacilityLastLogin() {
    let data = {};

    await this.db
      .collection("SQAUsers")
      .doc(this.currentUserId)
      .get()
      .then(async (f) => {
        data = {
          lastSignInTime: await f.data().lastSignInTime,
          clientIP: this.authService.currentUserIP,
          lastLoginToHost: this.authService.currentHost
        };
      });
    return this.updateCollectionData(
      "SQACustomers",
      this.currentFacilityId,
      await data
    );
  }

  public async UpdateFacilitySettings(data) {
    // hasNameChanged:boolean=false

    // if(hasNameChanged) {
    //   await this.updateFacilityNameInTests(data.facilityName).then(update => {
    //     console.log('tests updated with facility name', update);
    //   }).catch(e => {
    //     console.log('error with updating facility name');
    //   });
    // }
    return this.updateCollectionData(
      "SQACustomers",
      this.currentFacilityId,
      data
    );
  }
  public async UpdateFacilitySettings2(facilityData, userData) {
    // hasNameChanged:boolean=false

    // if(hasNameChanged) {
    //   await this.updateFacilityNameInTests(data.facilityName).then(update => {
    //     console.log('tests updated with facility name', update);
    //   }).catch(e => {
    //     console.log('error with updating facility name');
    //   });
    // }

    let fData;
    let data: any;
    if (facilityData !== null && userData !== null) {
      data = {
        ...userData,
        ...facilityData,
      };
    } else if (facilityData === null) {
      data = userData;
    } else {
      data = facilityData;
    }

    if (this.isFacilityAdmin) {
      await this.updateCollectionData(
        "SQACustomers",
        this.currentFacilityId,
        data
      );
    } else if (facilityData !== null) {
      fData = await this.updateCollectionData(
        "SQACustomers",
        this.currentFacilityId,
        facilityData
      );
    }

    if (userData !== null) {
      // console.log('the user data ', userData);
      return this.updateCollectionData(
        "SQAUsers",
        this.currentUserId,
        userData
      );
    } else if (facilityData !== null) {
      // console.log('the facility data ', facilityData);
      return await fData;
    } else {
      // console.log('all was null');
      return null;
    }
  }

  public addPatient(data, patientid: string) {
    let patientData: Patient;
    patientData = data;
    patientData.addedDate = new Date();

    return new Promise<any>((res, rej) => {
      const statsRef = this.db
        .collection("SQACustomers")
        .doc(this.currentFacilityId);
      let increment = firebase.firestore.FieldValue.increment(1);
      const batch = this.db.batch();
      const reqRef = this.db
        .collection("SQACustomers")
        .doc(this.currentFacilityId)
        .collection("patients")
        .doc(patientid.toString());

      reqRef
        .get()
        .then((snapshot) => {
          if (snapshot.exists) {
            increment = firebase.firestore.FieldValue.increment(0);
            // console.log('patient alread exist', snapshot.id);
          } else {
            // console.log('this is a new patient', snapshot.id);
          }
          batch.set(reqRef, patientData);
          batch.set(statsRef, { patientCount: increment }, { merge: true });
          batch
            .commit()
            .then((val) => {
              // console.log('batch res ', val);
              res(val);
            })
            .catch((err) => {
              rej(err);
            });
        })
        .catch((e) => {
          rej(e);
        });
    });
  }

  public updateArtLinkedtests(patientId: string, prePrepTestId: string, postPrepTestId: string): Promise<any> {
    return new Promise<any>(async (res, rej)=>{

      const prePrepTestRef = this.db.collection("SQACustomers")
                              .doc(this.currentFacilityId)
                              .collection("patients")
                              .doc(patientId)
                              .collection("tests")
                              .doc(prePrepTestId);

      const postPrepTestRef = this.db.collection("SQACustomers")
                              .doc(this.currentFacilityId)
                              .collection("patients")
                              .doc(patientId)
                              .collection("tests")
                              .doc(postPrepTestId);
      
      this.db.runTransaction(async (transaction)=>{
        return await transaction.get(postPrepTestRef).then(docData => {
          console.log('TRANSACTION DOC DATA', docData)
          transaction.set(prePrepTestRef, { linkedTest: { linkedTestID: postPrepTestRef.id, linkedPatientID: patientId } }, { merge: true });
          transaction.set(postPrepTestRef, { linkedTest: { linkedTestID: prePrepTestRef.id, linkedPatientID: patientId } }, { merge: true });
          setTimeout(() => {
            this.loadArchiveTestData(this.currentFacilityId);
            res(true);
          }, 600);
        }).catch(err=> {
          console.error('Could not update test',err);
          rej(err);
        });
      })
    });
  }

  public handleSavedARTModeTests(patientId: string, currentTestId: string, ARTtestType: string): Promise<any> {
    const targetCollection = ARTtestType === 'pre_prep' ? 'prePrepTests' : 'postPrepTests';

    
    return new Promise<any>(async (resolve, reject) => {
      try {
        console.warn('PROCESSING TEST REPLACE:', patientId, currentTestId, ARTtestType)
          const sourceTestRef = this.db.collection("SQACustomers")
          .doc(this.currentFacilityId)
          .collection("patients")
          .doc(patientId.toString())
          .collection("tests")
          .doc(currentTestId);
        
        const destinationRef = this.db.collection("SQACustomers")
          .doc(this.currentFacilityId)
          .collection("patients")
          .doc(patientId.toString())
          .collection(targetCollection)
          .doc(currentTestId)
        
        const sourceTestRefData = await sourceTestRef.get();       
       
        const _testsBatch = this.db.batch()
        _testsBatch.set(destinationRef, sourceTestRefData.data());
        _testsBatch.delete(sourceTestRef);

        _testsBatch.commit().then(() => {
          this.loadArchiveTestData(this.currentFacilityId);
          resolve(true);
        })
        
      } catch (error) {
          reject(error);
      }
    });
  }

  public async saveTestData(patientid: string, data, reduceCredit: boolean = true) {
    let testData: Test;
    testData = data;
    testData.hostname = window.location.hostname;
    testData.userId = this.currentUserId;
    testData.userName = this.currentUserName;
    let thisMonth = new Date().getMonth() + 1;
    let testIncValue = this.currentFacilityTotalTests++;
    let averageTests = Math.round((testIncValue) / thisMonth);
    let averageTestsPerDay = await this.calculateAverageTestsperDay(testIncValue);

    return new Promise<any>((res, rej) => {
      const statsRef = this.db
        .collection("SQACustomers")
        .doc(this.currentFacilityId)
        .collection("patients")
        .doc(patientid.toString());
      const statsRef2 = this.db
        .collection("SQACustomers")
        .doc(this.currentFacilityId);
      // this.updateCollectionData('SQACustomers', this.currentFacilityId, data);
      const increment = firebase.firestore.FieldValue.increment(1);
      const decrement = firebase.firestore.FieldValue.increment(-1);
      // const batch = this.db.batch();
      const reqRef = this.db
        .collection("SQACustomers")
        .doc(this.currentFacilityId)
        .collection("patients")
        .doc(patientid.toString())
        .collection("tests")
        .doc();

      let facilityDataToUpdate: any = {
        testCount: increment,
        totalTestCount: increment,
        testCredits: decrement,
        driverVersion: this.authService.driverVersion || null,
        averageTests,
        averageTestsPerDay,
      };

      
      this.db.runTransaction((transaction)=>{
        return transaction.get(reqRef).then(docData => {
          transaction.set(reqRef, testData);
          transaction.set(statsRef, { testCount: increment, customerType: this.currentFacilityType }, { merge: true });
          transaction.set(statsRef2, facilityDataToUpdate, { merge: true });
          res(reqRef.id);
        }).catch(err=> {
          console.error('Could not update test',err);
          rej(err);
        });
      })
      // if(reduceCredit === true) facilityDataToUpdate.testCredits = decrement,
      
      // batch.set(reqRef, testData);
      // batch.set(statsRef, { testCount: increment }, { merge: true });
      // batch.set(statsRef2, facilityDataToUpdate, { merge: true });
      // batch
      //   .commit()
      //   .then((val) => {
      //     // console.log('batch res ', val);
      //     // console.log('Test ID from ref ', reqRef.id);
      //     res(reqRef.id);
      //   })
      //   .catch((err) => {
      //     rej(err);
      //   });
    });
  }

  public updateTestData(patientid, testid, data) {
    return this.updateCollectionData(
      "SQACustomers/" +
      this.currentFacilityId +
      "/patients/" +
      patientid +
      "/tests/",
      testid,
      data
    );
  }


  public deleteManualConc(patientid, testid) {
    return new Promise<any>((res, rej) => {
      const fieldValue = firebase.firestore.FieldValue;
      this.db
        .collection("SQACustomers")
        .doc(this.currentFacilityId)
        .collection("patients")
        .doc(patientid)
        .collection("tests")
        .doc(testid)
        .update({
          mspermCount: fieldValue.delete(),
          mconcentration: fieldValue.delete(),
        })
        .then((u) => {
          res(u);
        })
        .catch((err) => {
          rej(err);
        });
    });
  }

  // public gettestsForFacility() {
  //   // let url = "https://us-central1-messqasystem.cloudfunctions.net/getNestedCollection?id=" + this.currentFacilityId + "&maincollection=patients&subcollection=tests";
  //   // return this._http.get(url);

  //   return new Promise<any>((res, rej) => {

  //     this.db.collection('SQACustomers').doc(this.currentFacilityId).collection('patients').get()
  //       .then(async patientsSnapshot => {
  //         let markers: any = [];
  //         for (const patient of patientsSnapshot.docs) {
  //           await this.db.collection('SQACustomers').doc(this.currentFacilityId).collection('patients').doc(patient.id).collection('tests').get()
  //             .then(async testSnapshot => {
  //               for (const test of testSnapshot.docs) {
  //                 if (test.id !== 'stats') {
  //                   await markers.push({ id: patient.id, data: patient.data(), testid: test.id, testdata: test.data() });
  //                 }
  //               }
  //             }).catch(error => {
  //               console.log("Error getting sub-collection documents", error);
  //               rej(error);
  //             })
  //         }
  //         res(await markers);
  //         console.log(await 'success')
  //       })
  //       .catch(error => {
  //         console.log("Error getting documents: ", error);
  //         rej(error);
  //       });
  //   });
  // }

  public gettestsForFacility(toArchive: boolean = false) {
    return new Promise<any>((res, rej) => {
      let markers: any = [];
      this.db
        .collectionGroup("tests")
        .where("facilityId", "==", this.currentFacilityId)
        .get()
        .then(async (tests) => {
          if (tests.empty) {
            // console.log('No matching documents.');
            // rej('No tests found.');
          }
          for (const test of tests.docs) {
            // if the function is called from Archive component, then check if the test is ART mode
            // if true, the push to the array only post-prep tests with no linked test (no pre-prep)
            if (toArchive && test.data().testingMode === 2)  { 
              if (test.data().prepType === 1) {
                if(!test.data().linkedTest) {
                  await markers.push({ testid: test.id, testdata: test.data() });
                }
              } else { // test is pre-prep, so push it to the array 
                await markers.push({ testid: test.id, testdata: test.data() });
              }
            } else { // any other test type - push to the array normally
              await markers.push({ testid: test.id, testdata: test.data() });
            }
          }
          res(await markers);
        })
        .catch((err) => {
          rej(err);
        });
    });
  }

  public getSingleTest(patientId: string, testId: string) {
    return new Promise<any>((res, rej) => {
      this.afs.collection('SQACustomers')
          .doc(this.currentFacilityId)
          .collection('patients')
          .doc(patientId)
          .collection('tests')
          .doc(testId)
          .get()
          .pipe(
            first(),
          ).subscribe(
            test => {
              if (test.exists) {
                res({...test.data(),id:testId});
              } else {
                throw new Error('No Test Found');
              }
            },
            error => rej(error)
          );
    });
  }

  public getFirstTestForFacility() {
    return new Promise<any>((res, rej) => {
      this.db
        .collectionGroup("tests")
        .where("facilityId", "==", this.currentFacilityId)
        .orderBy("dateTime", "asc")
        .limit(1)
        .get()
        .then((tests) => {
          let firstTest = tests.docs[0];
          if (tests.empty) {
            console.log('No matching documents.');
            res(0);
            return;
          }
          res({ testid: firstTest.id, testdata: firstTest.data() })
        })
        .catch((err) => {
          rej(err);
        });
    });
  }

  public gettestsForPatient(patientid) {
    return this.getCollectionList(
      "SQACustomers/" +
      this.currentFacilityId +
      "/patients/" +
      patientid +
      "/tests"
    );
  }


  public getArtPrepTestsPerPatient(patientID: string) {
    return new Promise<any>((res, rej) => {
      this.db.collection('SQACustomers')
          .doc(this.currentFacilityId)
          .collection('patients')
          .doc(patientID)
          .collection('tests')
          .where('testingMode', '==', 2)
          .orderBy("dateTime", "desc")
          .get() // Get all artPrep tests for patient
          .then(tests=> {
            const artPrepTests: Array<any> = [];
            tests.forEach(testData => {
              artPrepTests.push({ 
                                 testId: testData.id, 
                                 testPatientId: testData.data().patientId, 
                                 testPrepType: testData.data().prepType, 
                                 linkedTestId: testData.data().linkedTest?.linkedTestID ?? null,
                                 testSampleInfo: testData.data().sampleInformation,
                                 testreferringDoc: testData.data().referringDoc
                                });
            });
            // Filter out post-prep tests that are already linked to pre-prep tests
            let filteredArtPrepTests = artPrepTests.filter(test => !test.linkedTestId || (test.linkedTestId && test.testPrepType === 0));
            res(filteredArtPrepTests);
          })
          .catch(err => {console.warn(err);rej(err)});
    });
  }

  public deleteCaptureDataFromTest(deleteBy: string, docId: string): Promise<any> {
    let storageRef = firebase.storage().ref();
    let colQuery: string;
    if (deleteBy === 'test') {
      colQuery = "testID"
    } else if (deleteBy === 'patient') {
      colQuery = "patientID"
    }

    return new Promise(async (res, rej) => {
      let imageCount: number = 0;
      let videoCount: number = 0;
      this.db
        .collection("SQACustomers")
        .doc(this.currentFacilityId)
        .collection("Images")
        .where(colQuery, "==", docId)
        .get().then(async images => {
          const imgBatch = this.db.batch();
          if (images) {
            images.docs.forEach(async image => {
              let fileName = image.data().fileName;
              imgBatch.delete(image.ref);
              imageCount++;
              await storageRef.child(`Visualization/Images/${this.currentFacilityId}/${fileName}`).delete();
            })
            await imgBatch.commit().then(() => {
              console.log('cpature data deleted');
              // res('complete');
            }).catch(err => {
              rej(err);
            });
          }
        });

      this.db
        .collection("SQACustomers")
        .doc(this.currentFacilityId)
        .collection("Videos")
        .where(colQuery, "==", docId)
        .get().then(async videos => {
          const vidBatch = this.db.batch();
          if (videos) {
            videos.docs.forEach(async video => {
              let fileName = video.data().fileName;
              vidBatch.delete(video.ref);
              videoCount++;
              await storageRef.child(`Visualization/Videos/${this.currentFacilityId}/${fileName}`).delete();
            })
            await vidBatch.commit().then(() => {
              console.log('cpature data deleted');
              res({ _images: imageCount, _videos: videoCount });
            }).catch(err => {
              rej(err);
            });
          }
        });
    });
  }

  public getallPatientsForFacility() {
    return this.getCollectionList(
      "SQACustomers/" + this.currentFacilityId + "/patients"
    );
  }

  public getSettings() {
    return this.getCollectionData("SQACustomers", this.currentFacilityId);
  }

  public getDefaultSettings() {
    return this.getCollectionData('System', 'defaultSettings');
  }

  public getUserData() {
    return this.getCollectionData("SQAUsers", this.currentUserId);
  }

  public async UpdatePatientData(patientid, data) {
    // hasPatientNameChanged:boolean=false

    // console.log('update patient data ', data);
    // if(hasPatientNameChanged && data.lastName !== undefined && data.firstName !== undefined) {
    //   await this.updatePatientNameInTests(patientid, data.firstName, data.lastName).then(updates => {
    //     console.log('updated patient name in tests');
    //   }).catch(e => {
    //     console.log('error updating patient name in tests ', e);
    //   })
    // } else {
    //   console.log('patient name undef or not changed');
    // }
    let path = "SQACustomers/" + this.currentFacilityId + "/patients/";
    return this.updateCollectionData(path, patientid.toString(), data);
  }

  public getPatient(patientid) {
    return this.getCollectionData(
      `SQACustomers/${this.currentFacilityId}/patients`,
      patientid.toString()
    );
  }

  public DeletePatient(patientid) {
    return new Promise<any>((res, rej) => {
      const statsRef = this.db
        .collection("SQACustomers")
        .doc(this.currentFacilityId);
      const increment = firebase.firestore.FieldValue.increment(-1);
      const batch = this.db.batch();
      const reqRef = this.db
        .collection("SQACustomers")
        .doc(this.currentFacilityId)
        .collection("patients")
        .doc(patientid.toString());
      this.getCollectionData(
        `SQACustomers/${this.currentFacilityId}/patients`,
        patientid.toString()
      )
        .then(async (doc) => {
          let patientTestCnt = 0;
          if (doc.data.testCount !== undefined) {
            patientTestCnt = doc.data.testCount;
          }
          // console.log('tests of patient: ', patientTestCnt);
          const testCntUpdtae = firebase.firestore.FieldValue.increment(
            -patientTestCnt
          ); //testCount of patient who is deleted
          await this.deleteCaptureDataFromTest('patient', patientid);
          this.db
            .collection("SQACustomers")
            .doc(this.currentFacilityId)
            .collection("patients")
            .doc(patientid.toString())
            .collection("tests")
            .get()
            .then(async (testcollection) => {
              for (const test of testcollection.docs) {
                await batch.delete(test.ref);
              }

              await batch.delete(reqRef);
              await batch.set(
                statsRef,
                { patientCount: increment, testCount: testCntUpdtae },
                { merge: true }
              );
              await batch
                .commit()
                .then((val) => {
                  res(val);
                })
                .catch((err) => {
                  console.log("delete patient err", err);
                  rej(err);
                });
            })
            .catch((err) => {
              console.log("delete patient err", err);
              rej(err);
            });
        })
        .catch((err) => {
          console.log("get patient on delete err", err);
          rej(err);
        });
    });
  }

  public DeleteTest(patientid, testid) {
    return new Promise<any>(async (res, rej) => {
      const statsRef = this.db
        .collection("SQACustomers")
        .doc(this.currentFacilityId);
      const statsRef2 = this.db
        .collection("SQACustomers")
        .doc(this.currentFacilityId)
        .collection("patients")
        .doc(patientid.toString());
      const decrement = firebase.firestore.FieldValue.increment(-1);
      const increment = firebase.firestore.FieldValue.increment(1);
      const batch = this.db.batch();
      const reqRef = this.db
        .collection("SQACustomers")
        .doc(this.currentFacilityId)
        .collection("patients")
        .doc(patientid.toString())
        .collection("tests")
        .doc(testid.toString());
      let captureCount = await this.deleteCaptureDataFromTest('test', testid);
      const imageDecrement = firebase.firestore.FieldValue.increment(-captureCount?._images);
      const videoDecrement = firebase.firestore.FieldValue.increment(-captureCount?._videos);
      this.getCollectionData(
        `SQACustomers/${this.currentFacilityId}/patients/${patientid}/tests`,
        testid.toString()
      )
        .then((doc) => {
          batch.delete(reqRef);
          batch.set(statsRef, { deletedTests: increment, testCount: decrement, totalImages: imageDecrement, totalVideos: videoDecrement }, { merge: true });
          batch.set(statsRef2, { testCount: decrement }, { merge: true });

          batch
            .commit()
            .then((val) => {
              // console.log('batch res ', val);
              res(val);
            })
            .catch((err) => {
              rej(err);
            });
        })
        .catch((err) => {
          console.log("get patient on delete err", err);
          rej(err);
        });
    });
  }

  UploadProfilePicture(file: File) {
    var storageRef = firebase.storage().ref();
    var ref = storageRef.child(
      `${this.currentFacilityId}/${this.currentUserId}/profileimg.jpg`
    );

    return this.uploadFile(file, ref);
  }

  UploadFacilityLogo(file: File) {
    var storageRef = firebase.storage().ref();
    var ref = storageRef.child(`${this.currentFacilityId}/logo.jpg`);

    return this.uploadFile(file, ref);
  }

  UploadSignature(file: File) {
    var storageRef = firebase.storage().ref();
    var ref = storageRef.child(
      `${this.currentFacilityId}/${this.currentUserId}/signature.jpg`
    );

    return this.uploadFile(file, ref);
  }

  private uploadFile(file, ref) {
    return new Promise<any>((res, rej) => {
      if (file.type.includes("image")) {
        ref.put(file).then((snapshot) => {
          snapshot.ref
            .getDownloadURL()
            .then((url) => {
              res(url);
            })
            .catch((err) => {
              rej(err);
            });
        });
      } else {
        rej(new FileTypeError());
      }
    });
  }

  public verifySignatureURL(url: string) {
    return new Promise<any>((resolve, rej) => {
      this._http.get(url, {responseType :'blob'}).pipe(first()).subscribe(res=> {
        resolve(res);
      }, 
      error => {
        rej(error);
      });
    })
  }

  public UploadSignal(path, signalData) {
    var storageRef = firebase.storage().ref();
    var ref = storageRef.child(path);

    return new Promise<any>((res, rej) => {
      ref.putString(signalData).then((snapshot) => {
        snapshot.ref
          .getDownloadURL()
          .then((url) => {
            res(url);
          })
          .catch((err) => {
            rej(err);
          });
      });
    });
  }

  public uploadBase64(base64Data, ref, fileName) {
    // console.log(fileName);
    const base64 = base64Data.split(",")[1];

    return new Promise<any>((res, rej) => {
      const uploadTask = ref.putString(base64, "base64");

      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");

          this.uploadProgress$.next(
            JSON.stringify({
              fileName: fileName,
              percentage: progress,
              status: "uploading",
            })
          );
        },
        (error) => {
          // upload failed
          console.log(error);
        },
        () => {
          this.uploadProgress$.next(
            JSON.stringify({
              fileName: fileName,
              percentage: 100,
              status: "done",
            })
          );
        }
      );
    });
  }

  public uploadHTMLFile(htmlFileData, fileName: string): Promise<boolean> {

    let storageRef = firebase.storage().ref(`customreport/${this.currentFacilityId}/${fileName}`);

    return new Promise<any>((resolve, reject) => {
      const htmlUpload = storageRef.put(htmlFileData);
      htmlUpload.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        snapshot => {
          console.log(snapshot);
          if (snapshot.bytesTransferred === snapshot.totalBytes) {
            resolve(true);
          }
        }
        , error => {
          console.error(error);
          reject(false)
        }
      )
    });
  }

  public getHTMLFilesList(facilityID: string): Promise<void> {
    const filesList: Array<string> = [];
    let storageRef = firebase.storage().ref(`customreport/${this.currentFacilityId}`);

    return new Promise<any>((resolve, reject) => {
      storageRef.list().then((files) => {
        files.items.forEach(fileRef => {
          filesList.push(fileRef.name);
        });
        resolve(filesList);
      }).catch(error => {
        console.error(error);
        reject(error);
      });
    });
  }

  public downloadConvertedVideo(facilityId: string, fileData: any): Promise<any> {
    let downloadUrl: string = '';

    /* Handle video download via API call 
    *
    *
    let apiUrl:string = '';
    const hostname = window.location.hostname.toString().toLowerCase();
    if (hostname.includes("localhost")) {
      apiUrl = "api_video_services";
    } else {
      apiUrl = "https://videoservices-ldoxyjbqhq-uc.a.run.app";
    }
    
    if (this.VUSettings.downloadXXVid === 'mp4_pc') {
      downloadUrl = fileData.src;
    } else if (this.VUSettings.downloadXXVid === 'mp4_mobile') {
      downloadUrl = `${apiUrl}/convertVideoForDownload?facilityId=${facilityId}&filename=${fileData.fileName}`
    } */
    downloadUrl = fileData.src;

    return new Promise<any>((res, rej) => {
      this._http.get(downloadUrl, {responseType: 'blob'}).subscribe(_blob => {
        // const videoBlob = new Blob([_blob], {type: `video/${this.VUSettings.downloadXXVid}`});
          const videoBlob = new Blob([_blob], {type: `video/mp4}`});
          let videoObj = {
            blob: videoBlob,
            fileType: this.VUSettings.downloadXXVid
          }
          res(videoObj);
      }, error => {
        rej(error);
      });
    });
  }

  public deleteFileFromStorage(fileName: string) {
    return new Promise<any>(async (resolve, reject) => {
      var ref = await firebase
        .storage()
        .ref()
        .child(fileName)
        .delete()
        .then((res) => {
          resolve(ref);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public getFileUrl(fileName) {
    return new Promise<any>((res, rej) => {
      var ref = firebase.storage().ref().child(`${fileName}`);
      ref
        .getDownloadURL()
        .then((url) => {
          res(url);
        })
        .catch((err) => {
          rej(err);
        });
    });
  }

  public async UploadBase64Video(currentFacilityId, file: File, data) {
    var storageRef = firebase.storage().ref();
    var ref = storageRef.child(
      `Visualization/Videos/${currentFacilityId}/` + data.fileName
    );

    const res = await this.addCollectionItam(
      "SQACustomers/" + currentFacilityId + "/Videos",
      data
    );

    const videos = await this.getCollectionList(`SQACustomers/${currentFacilityId}/Videos`);

    this.uploadBase64(file, ref, data.fileName);

    if (videos.length) {
      await this.updateCollectionData('SQACustomers', currentFacilityId, { totalVideos: videos.length });
    }

    return res;
  }

  public async UploadBase64Image(currentFacilityId, file: File, data) {
    var storageRef = firebase.storage().ref();
    var ref = storageRef.child(
      `Visualization/Images/${currentFacilityId}/` + data.fileName
    );

    const res = await this.addCollectionItam(
      "SQACustomers/" + currentFacilityId + "/Images",
      data
    );

    const images = await this.getCollectionList(`SQACustomers/${currentFacilityId}/Images`);

    this.uploadBase64(file, ref, data.fileName);

    if (images.length) {
      await this.updateCollectionData('SQACustomers', currentFacilityId, { totalImages: images.length });
    }

    return res;
  }

  public async handleCaptureDataDelete(facilityId: string, subCollection: string) {
    let decrement = firebase.firestore.FieldValue.increment(-1);
    if (subCollection === '/Images') {
      await this.updateCollectionData('SQACustomers', facilityId, { totalImages: decrement });
    } else if (subCollection === '/Videos') {
      await this.updateCollectionData('SQACustomers', facilityId, { totalVideos: decrement });
    }
  }

  public DeleteProfilePicture() {
    // var storageRef = firebase.storage().ref();
    // var ref = storageRef.child(`${this.currentFacilityId}/profileimg.jpg`);
    this.UpdateFacilitySettings2(null, { photoURL: "" })
      .then(() => { })
      .catch((err) => {
        console.log(err);
        return err;
      });

    return this.deleteFile(`/${this.currentUserId}/profileimg.jpg`);
    // this.deleteFile(ref);
  }

  public DeleteLogo() {
    // var storageRef = firebase.storage().ref();
    // var ref = storageRef.child(`${this.currentFacilityId}/logo.jpg`);
    this.UpdateFacilitySettings2({ logoURL: "" }, null)
      .then(() => { })
      .catch((err) => {
        console.log(err);
        return err;
      });
    return this.deleteFile("/logo.jpg");
    // this.deleteFile(ref);
  }

  public DeleteSignature() {
    // var storageRef = firebase.storage().ref();
    // var ref = storageRef.child(`${this.currentFacilityId}/logo.jpg`);
    this.UpdateFacilitySettings2(null, { signatureURL: "" })
      .then(() => { })
      .catch((err) => {
        console.log(err);
        return err;
      });
    return this.deleteFile(`/${this.currentUserId}/signature.jpg`);
    // this.deleteFile(ref);
  }

  private deleteFile(fileName) {
    var storageRef = firebase.storage().ref();
    var ref = storageRef.child(`${this.currentFacilityId}${fileName}`);

    return ref.delete();
  }

  public getDevicedata(deviceID) {
    return this.getCollectionData("Devices", deviceID);
  }

  public updateDevicedata(deviceID, data: Device, addTest = false) {
    let increment;
    if (addTest) {
      increment = firebase.firestore.FieldValue.increment(1);
      data.testCount = increment;
    }

    return this.updateCollectionData("Devices", deviceID, data);
  }

  public addDeviceMaintenance(deviceID, data) {
    return new Promise<any>((res, rej) => {
      let facilityPath =
        "SQACustomers/" + this.currentFacilityId + "/deviceMaintenance";
      this.addCollectionItam(facilityPath, data)
        .then((maintenance) => {
          // console.log('added M ', maintenance);
          let lastM = {
            lastMaintenance: data,
          };
          this.updateCollectionData(
            "SQACustomers",
            this.currentFacilityId,
            lastM
          )
            .then((facilityM) => {
              lastM.lastMaintenance.facilityId = this.currentFacilityId;
              lastM.lastMaintenance.facilityName = this.currentFacilityName;
              this.updateCollectionData("Devices", deviceID, lastM)
                .then((deviceM) => {
                  res(deviceM);
                })
                .catch((err) => {
                  rej(err);
                });
            })
            .catch((err) => {
              rej(err);
            });
        })
        .catch((err) => {
          rej(err);
        });
    });
    // return this.addCollectionItam(path, data);
  }

  public addDeviceServiceData(deviceID, data) {
    // let path = 'Devices/' + deviceID.toString() + '/ServiceData';
    // return this.addCollectionItam(path, data);

    return new Promise<any>((res, rej) => {
      const statsRef = this.db.collection("Devices").doc(deviceID);

      const increment = firebase.firestore.FieldValue.increment(1);
      const batch = this.db.batch();
      const reqRef = this.db
        .collection("Devices")
        .doc(deviceID)
        .collection("servicedata")
        .doc();
      batch.set(reqRef, data);
      batch.set(statsRef, { selfTestCount: increment }, { merge: true });
      batch
        .commit()
        .then((val) => {
          // console.log('batch res ', val);
          res(val);
        })
        .catch((err) => {
          rej(err);
        });
    });
  }

  public sendNewContactRequest(data) {
    return new Promise<any>((res, rej) => {
      const statsRef = this.db.collection("Requests").doc("stats");
      const customerRef = this.db
        .collection("SQACustomers")
        .doc(this.currentFacilityId);
      const increment = firebase.firestore.FieldValue.increment(1);
      const batch = this.db.batch();
      const reqRef = this.db.collection("Requests").doc();
      batch.set(reqRef, data);
      batch.set(statsRef, { requestsCount: increment }, { merge: true });
      batch.set(customerRef, { requestCount: increment }, { merge: true });
      batch
        .commit()
        .then((val) => {
          // console.log('batch res ', val);
          res(val);
        })
        .catch((err) => {
          rej(err);
        });
    });
  }

  // not in use
  // public getRequestsForFacility() {
  //   // let url = "https://us-central1-messqasystem.cloudfunctions.net/getRequestsforSelectedFacility?id=" + this.currentFacilityId;
  //   // return this._http.get(url);

  //   return new Promise<any>((res, rej) => {
  //     const markers: any = [];
  //     this.db.collection('Requests').where("facilityId", "==", this.currentFacilityId)
  //     .get()
  //     .then(querySnapshot => {
  //         querySnapshot.forEach(doc => {
  //             // if (doc.data().facilityId === id) {
  //                 markers.push({id: doc.id, data: doc.data() });
  //             // }
  //         });

  //         res(markers);
  //     });
  //   });
  // }


  public redeemTestCredits(creditId) {
    return new Promise<any>((res, rej) => {
      let fullCreditId: string;
      // get credit code status from API
      this.checkCreditRestriction(creditId)
        .then((creditItemStatus) => {
          if (creditItemStatus.id) {
            if (creditItemStatus.status == 1) {
              fullCreditId = creditItemStatus.id;

              let currentFacilityCredits: number;
              let creditsToAdd = 0;
              let newCreditNumber: number;
              let type: string[];
              switch (this.currentFacilityType) {
                case "facility":
                  type = ["Production"];
                  break;
                case "distributor":
                  type = ["Marketing", "Production"];
                  break;
                case "alpha":
                  type = ["Production"];
                  break;
                case "validation":
                  type = ["Validation", "Development"];
                  break;
                case "clinical trial":
                  type = ["Clinical Trail"];
                  break;
                case "development":
                  type = ["Development"];
                  break;
                case "demo":
                  type = ["Marketing", "Production"];
                  break;
                case "marketing":
                  type = ["Marketing", "Production"];
                  break;
                default:
                  type = ["Development"];
                  break;
              }
              // Get full credit document data
              this.getCreditItemReduced(fullCreditId, type)
                .then((creditItem) => {
                  // fullCreditId = creditItem.id;
                  if (creditItem.toString().includes("Error")) {
                    rej(creditItem);
                  } else {
                    creditsToAdd = creditItem["data"]["credit"];
                    // console.log(creditsToAdd);
                    return this.getFacilityCurrentCredits();
                  }
                })
                .then((facilityCredits) => {
                  // Add credits to facility
                  if (facilityCredits.toString().includes("Error")) {
                    rej(facilityCredits);
                  } else {
                    currentFacilityCredits = Number(
                      facilityCredits["testCredits"]
                    );
                    newCreditNumber = currentFacilityCredits + creditsToAdd;
                    const increment =
                      firebase.firestore.FieldValue.increment(creditsToAdd);
                    let data: SQACustomer = {
                      testCredits: newCreditNumber,
                      totalCredits: increment,
                    };
                    return this.updateFacilityCredits(data);
                  }
                })
                .then((update) => {
                  // Set & update credit document with 'used' status and facility details.
                  let facility = {
                    facilityId: this.currentFacilityId,
                    facilityName: this.currentFacilityName,
                  };
                  let self = {
                    email: this.currentFacilityEmail,
                    id: this.currentFacilityId,
                    userType: this.currentFacilityType,
                  };
                  let data = {
                    status: "used",
                    usingFacility: facility,
                    redeemingDate: new Date(),
                    redeemedBy: self,
                  };
                  return this.updateTestCreditItem(fullCreditId, data);
                  // return this.updateTestCreditItem(creditId, {status: 'used', facility: this.currentFacilityId});
                })
                .then((status) => {
                  // console.log(status);
                  // console.log('credits to add ', creditsToAdd);
                  res(creditsToAdd);
                })
                .catch((err) => {
                  // console.log('redeem credits err', err);
                  firebase.analytics().logEvent('creditCodeFailed', { creditError: err });
                  rej(err);
                });
            } else {
              firebase.analytics().logEvent('creditCodeFailed', { creditError: 'Credit Load Error' });
              rej(this.creditError);
              return;
            }
          } else {
            firebase.analytics().logEvent('creditCodeFailed', { creditError: 'Credit Load Error' });
            rej(this.creditError);
            return;
          }
        })
        .catch((err) => {
          console.log("CREDIT RESTRICTION ERROR", err);
          rej(err);
        });
    });
  }

  private getTestCreditItem(creditId) {
    return new Promise<any>((res, rej) => {
      this.afs
        .collection("Credits")
        .doc(creditId)
        .get()
        .subscribe((docRef) => {
          if (docRef.exists) {
            let credit = docRef.data();
            if (credit !== undefined) {
              let status = credit["status"];
              if (status === "unused") {
                res({ id: creditId, data: docRef.data() });
              } else {
                rej("Error: Credit has already been used!");
              }
            }
          } else {
            rej("Error: No such document!");
            console.log("No such document!");
          }
        });
    });
  }

  public checkCreditRestriction(creditId: string) {
    let creditApi: string;
    let uid: string;
    const hostname = window.location.hostname.toString().toLowerCase();
    if (hostname.includes("localhost")) {
      creditApi = "api_registration";
    } else {
      // creditApi = "https://smsapi-ldoxyjbqhq-uc.a.run.app";
      creditApi = "https://credit.mes-data.app";
    }

    let url = creditApi + "/gettokenstatus";
    let creditStatus = {};
    return new Promise<any>((res, rej) => {
      firebase
        .auth()
        .currentUser.getIdToken()
        .then(async (token) => {
          const httpOptions = { Authorization: "Bearer " + token };
          this.authService.user.subscribe((user) => {
            uid = user.userID;
            let data = {
              headers: httpOptions,
              uid: uid,
              code: creditId,
            };
            this._http
              .post(url, data, { headers: httpOptions })
              .toPromise()
              .then((response) => {
                creditStatus = response;
                res(creditStatus);
              })
              .catch((err) => {
                // Analytic Event - log credit restriction failure
                firebase.analytics().logEvent('creditRestrictionFailed', { errorData: err });

                console.log("CREDIT DID NOT VALIDATE", err);
                rej(err);
              });
          });
        });
    });
  }

  public async getCreditItemReduced(creditId, creditType: string[]) {
    return new Promise<any>(async (res, rej) => {
      try {
        let creditDoc = await this.getCollectionData('Credits', creditId);
        if (creditType.includes(creditDoc.data.type) || 
            creditDoc.data.type === "Customer Support") {
              res(creditDoc)
        } else {
          rej(this.creditError);
        }
      } catch (error) {
        rej(this.creditError);
      }
    });
    // let credits = await this.getCollectionList("Credits");
    // return new Promise<any>((res, rej) => {
    //   let extractedArr = [];
    //   let extractedCredit;
    //   credits.forEach((element) => {
    //     if (
    //       element.id.toLowerCase().substring(0, 9) ===
    //       creditId.toLowerCase().substring(0, 9)
    //     ) {
    //       if (
    //         element.data.type === creditType ||
    //         element.data.type === "Customer Support"
    //       ) {
    //         extractedArr.push(element);
    //       }
    //     }
    //   });
    //   // console.log('extracted credit array ', extractedArr);
    //   if (extractedArr.length > 1) {
    //     let unusedArr = [];
    //     extractedArr.forEach((element) => {
    //       if (element.data.status === "unused") {
    //         unusedArr.push(element);
    //       }
    //     });
    //     if (unusedArr.length >= 1) {
    //       res(unusedArr[0]);
    //     } else {
    //       console.log("credit was already used!");
    //       rej(this.creditError);
    //     }
    //   } else if (extractedArr.length === 1) {
    //     if (extractedArr[0].data.status === "unused") {
    //       res(extractedArr[0]);
    //     } else {
    //       rej(this.creditError);
    //     }
    //   } else {
    //     console.log("no matching credit found!");
    //     rej(this.creditError);
    //   }
    // });


  }

  private getFacilityCurrentCredits() {
    return new Promise<any>((res, rej) => {
      this.afs
        .collection("SQACustomers")
        .doc(this.currentFacilityId)
        .get()
        .subscribe((doc) => {
          if (doc.exists) {
            let data = doc.data();
            if (data !== undefined) {
              res({
                id: this.currentFacilityId,
                testCredits: data["testCredits"],
              });
            } else {
              res("Error: Empty document!");
            }
          } else {
            res("Error: No such document!");
            // console.log("No such document!");
          }
        });
    });
  }

  private updateFacilityCredits(data) {
    return this.updateCollectionData(
      "SQACustomers",
      this.currentFacilityId,
      data
    );
  }
  private updateTestCreditItem(creditId, creditData) {
    return new Promise<any>((res, rej) => {
      let requStatus = creditData["status"];

      if (requStatus === "used" || requStatus === undefined) {
        this.afs
          .collection("Credits")
          .doc(creditId)
          .set(creditData, { merge: true })
          .then((docRef) => {
            res({ id: creditId, data: creditData });
          })
          .catch((error) => {
            // console.log("Error getting documents: ", error);
            rej(error);
          });
      } else {
        rej("Cannot reset status!");
      }
    });
  }

  public updateFacilityDevice(data) {
    return this.updateCollectionData(
      "SQACustomers",
      this.currentFacilityId,
      data
    );
  }

  public getRef2Cleaning() {
    return this.getCollectionData("System", "deviceCleaning");
  }

  public getLed1Device(deviceSN) {
    return new Promise<any>((res, rej) => {
      this.db
        .collection("Devices")
        .doc(deviceSN)
        .get()
        .then((doc) => {
          let sdataId;
          try {
            sdataId = doc.data().factoryDefaultServiceData;
          } catch (err) {
            // console.log('service data default err ', err);
            rej(err);
          }
          // console.log('service data id ', sdataId);
          if (sdataId !== undefined) {
            this.db
              .collection("Devices")
              .doc(deviceSN)
              .collection("servicedata")
              .doc(sdataId)
              .get()
              .then((lastsdata) => {
                // console.log('last service data doc ', lastsdata.id);
                const led1 = lastsdata.data().serviceDataParameters.ledCurrent1;
                // console.log('last service data led 1 ', led1);
                res(led1);
              })
              .catch((err) => {
                rej(err);
              });
          } else {
            rej("error: no reference available");
          }
        })
        .catch((err) => {
          rej(err);
        });
    });
  }

  // public addDatatotestsForFacility() {
  //   // let url = "https://us-central1-messqasystem.cloudfunctions.net/getNestedCollection?id=" + this.currentFacilityId + "&maincollection=patients&subcollection=tests";
  //   // return this._http.get(url);

  //   return new Promise<any>((res, rej) => {

  //     this.db.collection('SQACustomers').doc(this.currentFacilityId).collection('patients').get()
  //       .then(async patientsSnapshot => {
  //         let markers: any = [];
  //         for (const patient of patientsSnapshot.docs) {
  //           await this.db.collection('SQACustomers').doc(this.currentFacilityId).collection('patients').doc(patient.id).collection('tests').get()
  //             .then(async testSnapshot => {
  //               for (const test of testSnapshot.docs) {
  //                 if (test.id !== 'stats') {
  //                   let itemData = {
  //                     facilityId: this.currentFacilityId,
  //                     facilityName: this.currentFacilityName,
  //                     patientId: patient.id,
  //                     patientFirstName: patient.data().firstName,
  //                     patientLastName: patient.data().lastName
  //                   }
  //                   await this.db.collection('SQACustomers').doc(this.currentFacilityId).collection('patients').doc(patient.id).collection('tests').doc(test.id)
  //                   .set(itemData, { merge: true }).then(snap => {
  //                     console.log('updated data ', snap);
  //                   })
  //                 }
  //               }
  //             }).catch(error => {
  //               console.log("Error getting sub-collection documents", error);
  //               rej(error);
  //             })
  //         }
  //         res(await markers);
  //         console.log(await 'success')
  //       })
  //       .catch(error => {
  //         console.log("Error getting documents: ", error);
  //         rej(error);
  //       });
  //   });
  // }

  public updatePatientNameInTests(
    patientid,
    newName: any
  ) {
    return new Promise<any>((res, rej) => {
      let markers: any = [];

      this.db
        .collection("SQACustomers")
        .doc(this.currentFacilityId)
        .collection("patients")
        .doc(patientid)
        .collection("tests")
        .get()
        .then(async (testSnapshot) => {
          for (const test of testSnapshot.docs) {
            if (test.id !== "stats") {
              await this.db
                .collection("SQACustomers")
                .doc(this.currentFacilityId)
                .collection("patients")
                .doc(patientid)
                .collection("tests")
                .doc(test.id)
                .set(newName, { merge: true })
                .then(async (snap) => {
                  markers.push(snap);
                });
            }
          }
          res(await markers);
          console.log(await "success");
        })
        .catch((error) => {
          console.log("Error getting test-collection documents", error);
          rej(error);
        });
    });
  }

  // public updateFacilityNameInTests(newFacilityName) {

  //   console.log('in updateFacilityNameInTests');
  //   return new Promise<any>((res, rej) => {

  //     let markers: any = [];
  //     this.db.collectionGroup('tests').where('facilityId', '==', this.currentFacilityId).get()
  //       .then(async testSnapshot => {
  //         for (const test of testSnapshot.docs) {
  //           if (test.id !== 'stats') {
  //             let itemData = {
  //               facilityName: newFacilityName
  //             }
  //             await this.db.collection('SQACustomers').doc(this.currentFacilityId).collection('patients').doc(test.data().patientId).collection('tests').doc(test.id)
  //               .set(itemData, { merge: true }).then(async snap => {
  //                 markers.push(snap);
  //               })
  //           }
  //         }
  //         res(await markers);
  //         console.log(await 'success')
  //       }).catch(error => {
  //         console.log("Error getting test-collection documents", error);
  //         rej(error);
  //       })
  //   });
  // }

  public getActiveNotifications(_notifications: []) {
    let allowNotifications: string[] = _notifications;
    console.warn(_notifications, allowNotifications)
    return new Promise<any>((res, rej) => {
      let markers: any = [];
      this.db
        .collection("Notifications")
        .where("active", "==", true)
        .get()
        .then(async (aNotifications) => {

          if (aNotifications.empty) {
            // console.log('No active notifications.');
            // rej('No tests found.');
          }

          for (const notific of aNotifications.docs) {
            if (allowNotifications) {
              if ( allowNotifications.includes(notific.id) ) {
                await markers.push({ id: notific.id, data: notific.data() });
              }
            }
          }
          res(await markers);
        })
        .catch((err) => {
          rej(err);
        });
    });
  }

  public updateFacilityNotificationTime(time: Date) {
    let data: SQACustomer = {
      lastNotification: time,
    };
    return this.updateCollectionData(
      "SQACustomers",
      this.currentFacilityId,
      data
    );
  }

  public getLastNotificationTime() {
    return new Promise<any>((res, rej) => {
      this.db
        .collection("SQACustomers")
        .doc(this.currentFacilityId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            let data: SQACustomer = doc.data();
            if (data.lastNotification !== undefined) {
              res({
                id: this.currentFacilityId,
                lastNotification: data.lastNotification,
              });
            } else {
              res(null);
            }
          } else {
            rej("Error: No such document!");
            // console.log('No such document!');
          }
        })
        .catch((e) => {
          rej(e);
        });
    });
  }

  public getAllQCControls() {
    return this.getCollectionList("Controls");
  }

  public getQCBatch(batchID) {
    return this.getCollectionData("Controls", batchID);
  }

  // public getAllTestsPerFacility() {
  //   return this.getSnapshots();
  // }

  public addFacilityQCTest(data: ControlsLot, reduceCredit: boolean) {
    // add reduceTestCredit
    return new Promise<any>((res, rej) => {
      let decrementCredit = 0;
      if (reduceCredit) {
        decrementCredit = -1;
      }
      let controlsPath = this.db
        .collection("SQACustomers")
        .doc(this.currentFacilityId)
        .collection("controlTests")
        .doc();
      let facilityPath = this.db
        .collection("SQACustomers")
        .doc(this.currentFacilityId);
      let dataPlus = data;
      dataPlus.facilityId = this.currentFacilityId;
      dataPlus.facilityName = this.currentFacilityName;
      dataPlus.userId = this.currentUserId;
      dataPlus.userName = this.currentUserName;

      let lastQC: LatexBeadsData = {
        batchID: data.batchID,
        lastRun: data.dateTime,
      };

      let increment = firebase.firestore.FieldValue.increment(1);
      let decrement = firebase.firestore.FieldValue.increment(decrementCredit);
      const batch = this.db.batch();
      batch.set(controlsPath, dataPlus);
      batch.set(
        facilityPath,
        { lastQC: lastQC, qcTestCount: increment, testCredits: decrement },
        { merge: true }
      );

      batch
        .commit()
        .then((facilityQC) => {
          res({ id: controlsPath.id, data: dataPlus });
        })
        .catch((err) => {
          rej(err);
        });
    });
    // return this.addCollectionItam(path, data);
  }

  public checkVUserialNumber(devicesArray: any[], allowedVUdevices: any[]) {
    let vendorIdList: number[] = [];
    let productIdList: number[] = [];
    allowedVUdevices.forEach(vuDetails => {
      vendorIdList.push(vuDetails['vendorId']);
      productIdList.push(vuDetails['productId'])
    });
    const filteredVUDeviceSerial = devicesArray
                                    .filter(device =>  vendorIdList.includes(device['vendorId']) && productIdList.includes(device['productId']))
                                    .map(device => device['serialNumber']);
    console.log(filteredVUDeviceSerial[0])
    return new Promise<any>(async (res, rej) => {
      try {
        const serialNumData = await this.db.collection('VUDevices').where("serialNumber", '==', filteredVUDeviceSerial[0]).get();
        if (!serialNumData.empty) {
          serialNumData.forEach(serialID => {
            res(serialID);
          });
        } else {
          rej('No VU Device match!');
        }
      } catch (error) {
        rej({message: 'VU serial number detection error', error});
      }
    })
  }

  public updateFacilityQCTest(qcTestId, data) {
    let facilityPath =
      "SQACustomers/" + this.currentFacilityId + "/controlTests";
    return this.updateCollectionData(facilityPath, qcTestId, data);
  }

  public deleteFacilityQCTest(qcTestId) {
    let facilityPath =
      "SQACustomers/" + this.currentFacilityId + "/controlTests";
    return this.deleteCollectionItem(facilityPath, qcTestId);
  }

  public deleteMultipleQCTests(ids: string[]) {
    const batch = this.db.batch();
    ids.forEach((id) => {
      const path = this.db
        .collection("SQACustomers")
        .doc(this.currentFacilityId)
        .collection("controlTests")
        .doc(id);
      batch.delete(path);
    });
    const statsRef = this.db
      .collection("SQACustomers")
      .doc(this.currentFacilityId);
    const reduce = 0 - ids.length;
    // console.log('arr len reduce ', reduce);
    const decrement = firebase.firestore.FieldValue.increment(reduce);
    batch.set(statsRef, { qcTestCount: decrement }, { merge: true });
    return batch.commit();
  }

  public getAllFacilityQCTests() {
    let facilityPath =
      "SQACustomers/" + this.currentFacilityId + "/controlTests";
    return this.getCollectionList(facilityPath);
  }

  // public getLastQCOld() {
  //   return new Promise<any>((res, rej)=> {

  //     let facilityPath = 'SQACustomers/' + this.currentFacilityId + '/controlTests';
  //     let lastQCID:string;
  //     this.getCollectionData('SQACustomers', this.currentFacilityId).then(facilityData => {
  //       console.log('last QC ', facilityData.data.lastQC);
  //       if(facilityData.data.lastQC) {
  //         lastQCID = facilityData.data.lastQC.batchID;
  //         this.db.collection('SQACustomers').doc(this.currentFacilityId).collection('controlTests').where('batchID', '==', lastQCID).get()
  //       .then(async levels => {
  //         if (levels.empty) {
  //           console.log('No matching qc levels.');
  //           // rej('No tests found.');
  //           res('pending');
  //         } else {
  //         let allTestspreLevel = [];
  //         let latestTests = [];
  //         let levelsarr = [];
  //         let negLevel;

  //         for (const level of levels.docs) {
  //           if(levelsarr.includes(level.data().level)) {
  //           } else {
  //             await levelsarr.push(level.data().level);
  //             if (level.data().isNegativeControl) {
  //               negLevel = level.data().level;
  //             }
  //           }
  //         }
  //         console.log('all levels ', await levelsarr);
  //         console.log('neg level ', await negLevel);

  //         for (const sLevel of levelsarr) {
  //           let testcollection = [];
  //           for (const test of levels.docs) {
  //             if (test.data().level === sLevel) {
  //               await testcollection.push({'testid': test.id, 'data': test.data()})
  //             }
  //           }
  //           await allTestspreLevel.push({level: sLevel, tests: testcollection});
  //         }
  //         console.log('sorted tests per level ', await allTestspreLevel);

  //         for (const coll of allTestspreLevel) {
  //             let latestTest = await coll.tests.reduce((a, b) => a.data.dateTime > b.data.dateTime ? a : b);
  //             await latestTests.push(latestTest);
  //         }
  //         console.log('latest tests arr ', await latestTests);
  //         res(await latestTests);
  //       }
  //       }).catch(err => {
  //         rej(err);
  //       })

  //       } else {
  //         res('pending');
  //       }
  //     }).catch(err => {
  //       rej(err);
  //     })

  //   });
  // }

  public getLastQC() {
    return new Promise<any>((res, rej) => {
      this.db
        .collection("SQACustomers")
        .doc(this.currentFacilityId)
        .collection("controlTests")
        .get()
        .then(async (tests) => {
          if (tests.empty) {
            // console.log('No matching qc levels.');
            // rej('No tests found.');
            res("pending");
          } else {
            let lastQCbatch = tests.docs[0].data().batchID;
            let lastQCdate = tests.docs[0].data().dateTime;
            let lastQCtestId = tests.docs[0].id;
            let latestTests = [];
            let allTestspreLevel = [];
            let levelsarr = [];
            let testsarr = [];
            let negLevel;

            for (const test of tests.docs) {
              if (test.data().dateTime > lastQCdate) {
                lastQCbatch = test.data().batchID;
                lastQCdate = test.data().dateTime;
                lastQCtestId = test.id;
              }
            }

            for (const test of tests.docs) {
              if (test.data().batchID === lastQCbatch) {
                if (levelsarr.includes(test.data().level)) {
                } else {
                  await levelsarr.push(test.data().level);
                  if (test.data().isNegativeControl) {
                    negLevel = test.data().level;
                  }
                }
                testsarr.push({ testid: test.id, data: test.data() });
              }
            }
            // console.log('testarr ', testsarr);

            for (const sLevel of levelsarr) {
              let testcollection = [];
              for (const test of testsarr) {
                if (test.data.level === sLevel) {
                  await testcollection.push(test);
                }
              }
              await allTestspreLevel.push({
                level: sLevel,
                tests: testcollection,
              });
            }
            // console.log('sorted tests per level ', await allTestspreLevel);

            let frequency = await this.getQCFrequency(); // await this.getCollectionData('System', 'qcFrequency');
            let hours = frequency; //.data.hours;
            let dateoflasttest = lastQCdate;
            // console.log('date of last test ', dateoflasttest);
            let timediff =
              new Date().getTime() - dateoflasttest.toDate().getTime();
            // console.log('timediff as hours', timediff/3600000);

            for (const coll of allTestspreLevel) {
              let latestTest = await coll.tests.reduce((a, b) =>
                a.data.dateTime > b.data.dateTime ? a : b
              );
              let timediffbyTest =
                lastQCdate.toDate().getTime() -
                latestTest.data.dateTime.toDate().getTime();
              if (latestTest.testid === lastQCtestId) {
                await latestTests.push(latestTest);
              } else if (timediffbyTest <= hours * 3600 * 1000) {
                await latestTests.push(latestTest);
              }
            }
            // console.log('latest tests arr ', await latestTests);
            // let fac = await this.getCollectionData('SQACustomers', this.currentFacilityId);

            if (timediff > hours * 3600 * 1000) {
              await latestTests.push({ isNewRound: true });
            } else {
              await latestTests.push({ isNewRound: false });
            }
            res(await latestTests);
          }
        })
        .catch((err) => {
          rej(err);
        });
    });
  }

  public getLastQCTestsOfBatch(batch_id: string) {
    // batchID can be distribution number or issue date
    return new Promise<any>((res, rej) => {
      this.db
        .collection("SQACustomers")
        .doc(this.currentFacilityId)
        .collection("controlTests")
        .where("batchID", "==", batch_id)
        .get()
        .then(async (tests) => {
          if (tests.empty) {
            // console.log('No matching qc levels.');
            // rej('No tests found.');
            res("pending");
          } else {
            let lastQCdate = tests.docs[0].data().dateTime;
            let lastQCtestId = tests.docs[0].id;
            let latestTests = [];
            let allTestspreLevel = [];
            let levelsarr = [];
            let testsarr = [];
            let negLevel;

            for (const test of tests.docs) {
              if (test.data().dateTime > lastQCdate) {
                lastQCdate = test.data().dateTime;
                lastQCtestId = test.id;
              }
            }
            for (const test of tests.docs) {
              if (levelsarr.includes(test.data().level)) {
              } else {
                await levelsarr.push(test.data().level);
                if (test.data().isNegativeControl) {
                  negLevel = test.data().level;
                }
              }
              testsarr.push({ testid: test.id, data: test.data() });
            }
            // console.log('testarr ', testsarr);

            for (const sLevel of levelsarr) {
              let testcollection = [];
              for (const test of testsarr) {
                if (test.data.level === sLevel) {
                  await testcollection.push(test);
                }
              }
              await allTestspreLevel.push({
                level: sLevel,
                tests: testcollection,
              });
            }
            // console.log('sorted tests per level ', await allTestspreLevel);

            let frequency = await this.getQCFrequency(); // await this.getCollectionData('System', 'qcFrequency');
            let hours = frequency; //.data.hours;
            let dateoflasttest = lastQCdate;
            // console.log('date of last test ', dateoflasttest);
            let timediff =
              new Date().getTime() - dateoflasttest.toDate().getTime();
            // console.log('timediff as hours', timediff/3600000);

            for (const coll of allTestspreLevel) {
              let latestTest = await coll.tests.reduce((a, b) =>
                a.data.dateTime > b.data.dateTime ? a : b
              );
              let timediffbyTest =
                lastQCdate.toDate().getTime() -
                latestTest.data.dateTime.toDate().getTime();
              if (latestTest.testid === lastQCtestId) {
                await latestTests.push(latestTest);
              } else if (timediffbyTest <= hours * 3600 * 1000) {
                await latestTests.push(latestTest);
              }
            }
            // console.log('latest tests arr ', await latestTests);
            // let fac = await this.getCollectionData('SQACustomers', this.currentFacilityId);

            if (timediff > hours * 3600 * 1000) {
              await latestTests.push({ isNewRound: true });
            } else {
              await latestTests.push({ isNewRound: false });
            }
            res(await latestTests);
          }
        })
        .catch((err) => {
          rej(err);
        });
    });
  }

  public addNewSQAUser(userData: SQAUser, password: string) {
    const url = this.apiEP + "/addNewSQAUserWithAuth2";
    const data: any = userData;
    data.password = password;
    data.isFacilityAdmin = false;
    data.facilityID = this.currentFacilityId;

    return new Promise<any>((ress, rejj) => {
      firebase
        .auth()
        .currentUser.getIdToken()
        .then((token) => {
          const headers = new HttpHeaders({ Authorization: "Bearer " + token });

          this._http
            .post(url, JSON.stringify(data), { headers: headers })
            .toPromise()
            .then((res) => {
              ress(res);
            })
            .catch((err) => {
              console.log("err adding admin  http ", err);
              if (err.error.code === "auth-error") {
                rejj(new EmailNotAvailableError(err));
              } else {
                rejj(err);
              }
            });
        });
    });
  }

  public deleteSQAUser(id: string) {
    const url = this.apiEP + "/deleteSQAUserWithAuth?id=" + id;
    return new Promise<any>((ress, rejj) => {
      firebase
        .auth()
        .currentUser.getIdToken()
        .then((token) => {
          const headers = new HttpHeaders({ Authorization: "Bearer " + token });

          this._http
            .get(url, { headers: headers })
            .toPromise()
            .then((res) => {
              ress(res);
            })
            .catch((err) => {
              rejj(err);
            });
        });
    });
  }

  public getUsersForFacility() {
    return new Promise<any>((res, rej) => {
      let markers: any = [];
      this.db
        .collection("SQAUsers")
        .where("facilityID", "==", this.currentFacilityId)
        .get()
        .then(async (users) => {
          if (users.empty) {
            // console.log('No matching documents.');
            // rej('No tests found.');
          }
          for (const user of users.docs) {
            await markers.push({ id: user.id, data: user.data() });
          }
          res(await markers);
        })
        .catch((e) => {
          rej(e);
        });
    });
  }

  public changeUserPassword(userId: string, newPassword) {
    const data = JSON.stringify({ password: newPassword });
    const url = this.apiEP + "/changeFacilityPasswordWithAuth?id=" + userId;
    return new Promise<any>((ress, rejj) => {
      firebase
        .auth()
        .currentUser.getIdToken()
        .then((token) => {
          const headers = new HttpHeaders({ Authorization: "Bearer " + token });
          this._http
            .post(url, data, { headers: headers })
            .toPromise()
            .then((res) => {
              // console.log('pw reset ', res);
              ress(res);
            })
            .catch((err) => {
              console.log("pw reset err", err);

              rejj(err);
            });
        });
    });
  }

  public async updateUserDetails(userId, data: SQAUser, password?: string) {
    if (password) {
      // console.log('includes pw change');
      await this.changeUserPassword(userId, password).then((pwchange) => {
        console.log("change pw ", pwchange);
      });
    }
    return this.updateCollectionData("SQAUsers", userId, data);
  }

  public getAllProficiencyBatches(scheme: string) {
    return new Promise<any>((res, rej) => {
      let markers: any = [];
      this.db
        .collection("Proficiency")
        .where("scheme", "==", scheme)
        .orderBy("adminEditor.dateTime", "desc")
        .get()
        .then(async (proficiencyBatches) => {
          if (proficiencyBatches.empty) {
            // console.log('No active notifications.');
            rej("No data found.");
          }
          for (const batch of proficiencyBatches.docs) {
            await markers.push({ id: batch.id, data: batch.data() });
          }
          res(await markers);
        })
        .catch((err) => {
          rej(err);
        });
    });
    // return this.getCollectionList('Proficiency');
  }

  public getProficiencyBatch(id) {
    return this.getCollectionData("Proficiency", id);
  }

  public addFacilityProficiencyTest(
    data: ProficiencySample,
    reduceCredit: boolean
  ) {
    return new Promise<any>((res, rej) => {
      let decrementCredit = 0;
      if (reduceCredit) {
        decrementCredit = -1;
      }

      const statsRef2 = this.db
        .collection("SQACustomers")
        .doc(this.currentFacilityId);
      const increment = firebase.firestore.FieldValue.increment(1);
      const decrement =
        firebase.firestore.FieldValue.increment(decrementCredit);
      const batch = this.db.batch();
      const reqRef = this.db
        .collection("SQACustomers")
        .doc(this.currentFacilityId)
        .collection("proficiencyTests")
        .doc();
      let dataPlus = data;
      dataPlus.facilityId = this.currentFacilityId;
      dataPlus.facilityName = this.currentFacilityName;
      dataPlus.userId = this.currentUserId;
      dataPlus.userName = this.currentUserName;
      batch.set(reqRef, dataPlus);
      let lastProficiency = {
        scheme: data.scheme,
        lastRun: data.testDateTime,
      };
      batch.set(
        statsRef2,
        {
          lastProficiency: lastProficiency,
          proficiencyTestCount: increment,
          testCredits: decrement,
        },
        { merge: true }
      );
      batch
        .commit()
        .then((val) => {
          // console.log('batch res ', val);
          // console.log('Test ID from ref ', reqRef.id);
          res(reqRef.id);
        })
        .catch((err) => {
          rej(err);
        });

      // let facilityPath = 'SQACustomers/' + this.currentFacilityId + '/proficiencyTests';
      // let dataPlus = data;
      // dataPlus.facilityId = this.currentFacilityId;
      // dataPlus.facilityName = this.currentFacilityName;
      // dataPlus.userId = this.currentUserId;
      // dataPlus.userName = this.currentUserName;
      // this.addCollectionItam(facilityPath, dataPlus).then(proficiencyTest => {
      // let lastProficiency = {
      //   scheme: data.scheme,
      //   lastRun: data.testDateTime
      // };
      // let increment = firebase.firestore.FieldValue.increment(1);
      // this.updateCollectionData('SQACustomers',this.currentFacilityId, {'lastProficiency': lastProficiency, proficiencyTestCount: increment}).then(() => {
      //   res({ "id": proficiencyTest.id, "data": dataPlus });
      // }).catch(err => {
      //   rej(err);
      // });
      // }).catch(err => {
      //   rej(err);
      // })
    });
    // return this.addCollectionItam(path, data);
  }

  public updateFacilityProficiencyTest(testId, data) {
    let facilityPath =
      "SQACustomers/" + this.currentFacilityId + "/proficiencyTests";
    return this.updateCollectionData(facilityPath, testId, data);
  }

  public deleteFacilityProficiencyTest(testId) {
    let facilityPath =
      "SQACustomers/" + this.currentFacilityId + "/proficiencyTests";
    return this.deleteCollectionItem(facilityPath, testId);
  }

  public deleteMultipleProficiencyTests(ids: string[]) {
    const batch = this.db.batch();
    ids.forEach((id) => {
      const path = this.db
        .collection("SQACustomers")
        .doc(this.currentFacilityId)
        .collection("proficiencyTests")
        .doc(id);
      batch.delete(path);
    });
    const statsRef = this.db
      .collection("SQACustomers")
      .doc(this.currentFacilityId);
    const reduce = 0 - ids.length;
    // console.log('arr len reduce ', reduce);
    const decrement = firebase.firestore.FieldValue.increment(reduce);
    batch.set(statsRef, { proficiencyTestCount: decrement }, { merge: true });
    return batch.commit();
  }

  public getAllFacilityProficiencyTests(scheme: string) {
    return new Promise<any>((res, rej) => {
      let markers: any = [];
      this.db
        .collection("SQACustomers")
        .doc(this.currentFacilityId)
        .collection("proficiencyTests")
        .where("scheme", "==", scheme)
        .orderBy("testDateTime", "desc")
        .get()
        .then(async (proficiencyTests) => {
          if (proficiencyTests.empty) {
            // console.log('No active notifications.');
            rej("No tests found.");
          }
          for (const test of proficiencyTests.docs) {
            await markers.push({ id: test.id, data: test.data() });
          }
          res(await markers);
        })
        .catch((err) => {
          rej(err);
        });
    });
  }

  public getPreviousIssueDates(scheme: string = "CAP/API") {
    return new Promise<any>((res, rej) => {
      this.db
        .collection("SQACustomers")
        .doc(this.currentFacilityId)
        .collection("proficiencyTests")
        .where("scheme", "==", scheme)
        .get()
        .then(async (tests) => {
          if (tests.empty) {
            // console.log('No matching qc levels.');
            // rej('No tests found.');
            res([]);
          } else {
            let issueDates;
            let idates = tests.docs.map((doc) => {
              if (scheme == "ipro") {
                return doc.data().batchNumber;
              } else {
                return doc.data().issueDate;
              }
            });
            const distinct = (value: any, index: any, self: any) => {
              return self.indexOf(value) === index;
            };
            const undef = (value) => {
              return value !== undefined;
            };
            issueDates = idates.filter(distinct).filter(undef);
            res(issueDates);
          }
        })
        .catch((err) => {
          rej(err);
        });
    });
  }

  public getLastProficiencyTestsOfBatch(scheme: string, batchID: string) {
    // batchID can be distribution number or issue date
    return new Promise<any>((res, rej) => {
      let batch_id;
      if (scheme == "CAP/API") {
        batch_id = "issueDate";
      } else if (scheme == "ipro") {
        batch_id = "batchNumber";
      } else {
        batch_id = "distributionNo";
      }
      this.db
        .collection("SQACustomers")
        .doc(this.currentFacilityId)
        .collection("proficiencyTests")
        .where("scheme", "in", [scheme])
        .where(batch_id, "==", batchID)
        .get()
        .then(async (tests) => {
          if (tests.empty) {
            // console.log('No matching qc levels.');
            // rej('No tests found.');
            res("pending");
          } else {
            let lastProficiencydate = tests.docs[0].data().testDateTime;
            let lastProficiencytestId = tests.docs[0].id;
            let latestTests = [];
            let samplesArr = [];
            let allTestsPerSampleID = [];
            let testsarr = [];

            for (const test of tests.docs) {
              if (test.data().testDateTime > lastProficiencydate) {
                lastProficiencydate = test.data().testDateTime;
                lastProficiencytestId = test.id;
              }
            }
            // console.log('lastDate ', lastProficiencydate);
            for (const test of tests.docs) {
              // console.log('test ', test.data());

              if (samplesArr.includes(test.data().sampleId)) {
              } else {
                await samplesArr.push(test.data().sampleId);
              }
              testsarr.push({ testid: test.id, data: test.data() });
            }
            // console.log('testarr ', testsarr);
            // console.log('sample arr ', samplesArr);
            for (let i = 0; i < samplesArr.length; i++) {
              // console.log(samplesArr[i]);
              let testcollection = [];
              for (const test of testsarr) {
                if (test.data.sampleId === samplesArr[i]) {
                  await testcollection.push(test);
                  // console.log('collection ', testcollection);
                }
              }
              await allTestsPerSampleID.push({
                sampleID: samplesArr[i],
                tests: testcollection,
              });
            }
            // console.log('sorted tests per sample id ', allTestsPerSampleID);

            for (const coll of allTestsPerSampleID) {
              let latestTest = await coll.tests.reduce((a, b) =>
                a.data.testDateTime > b.data.testDateTime ? a : b
              );

              await latestTests.push(latestTest);
            }

            // console.log('latest tests arr ', latestTests);

            res(await latestTests);
          }
        })
        .catch((err) => {
          rej(err);
        });
    });
  }

  public getLastProficiencyScheme(
    schemes: string[] = ["NEQAS", "QuaDeGa", "CAP/API", "ipro"]
  ) {
    // returns the last tests of the specified scheme or of the last test's scheme if not specified
    return new Promise<any>((res, rej) => {
      // console.log('in get last prof', schemes);
      this.db
        .collection("SQACustomers")
        .doc(this.currentFacilityId)
        .collection("proficiencyTests")
        .where("scheme", "in", schemes)
        .get()
        .then(async (tests) => {
          if (tests.empty) {
            // console.log('No matching qc levels.');
            // rej('No tests found.');
            res("pending");
          } else {
            let lastProficiencyScheme = tests.docs[0].data().scheme;
            let lastProficiencydate = tests.docs[0].data().testDateTime;
            let lastProficiencytestId = tests.docs[0].id;
            let lastProficiencyDistributionId =
              tests.docs[0].data().distributionNo;
            let lastProficiencyIssueDate = tests.docs[0].data().issueDate;
            let lastProficiencyBatchNum = tests.docs[0].data().batchNumber;
            let latestTests = [];
            let samplesArr = [];
            let allTestsPerSampleID = [];
            let testsarr = [];

            for (const test of tests.docs) {
              if (test.data().testDateTime > lastProficiencydate) {
                lastProficiencyScheme = test.data().scheme;
                lastProficiencydate = test.data().testDateTime;
                lastProficiencytestId = test.id;
                lastProficiencyDistributionId = test.data().distributionNo;
                lastProficiencyIssueDate = test.data().issueDate;
                lastProficiencyBatchNum = test.data().batchNumber;
              }
            }
            // console.log('lastDate ', lastProficiencydate);
            for (const test of tests.docs) {
              // console.log('test ', test.data());
              if (lastProficiencyScheme === "CAP/API") {
                if (
                  test.data().scheme === lastProficiencyScheme &&
                  test.data().issueDate === lastProficiencyIssueDate
                ) {
                  // && test.data().distributionNo === lastProficiencyDistributionId
                  if (samplesArr.includes(test.data().sampleId)) {
                  } else {
                    await samplesArr.push(test.data().sampleId);
                  }
                  testsarr.push({ testid: test.id, data: test.data() });
                }
              } else if (lastProficiencyScheme === "ipro") {
                if (
                  test.data().scheme === lastProficiencyScheme &&
                  test.data().batchNumber === lastProficiencyBatchNum
                ) {
                  // && test.data().distributionNo === lastProficiencyDistributionId
                  if (samplesArr.includes(test.data().sampleId)) {
                  } else {
                    await samplesArr.push(test.data().sampleId);
                  }
                  testsarr.push({ testid: test.id, data: test.data() });
                }
              } else {
                if (
                  test.data().scheme === lastProficiencyScheme &&
                  test.data().distributionNo === lastProficiencyDistributionId
                ) {
                  // && test.data().distributionNo === lastProficiencyDistributionId
                  if (samplesArr.includes(test.data().sampleId)) {
                  } else {
                    await samplesArr.push(test.data().sampleId);
                  }
                  testsarr.push({ testid: test.id, data: test.data() });
                }
              }
            }
            // console.log('testarr ', testsarr);
            // console.log('sample arr ', samplesArr);
            for (let i = 0; i < samplesArr.length; i++) {
              // console.log(samplesArr[i]);
              let testcollection = [];
              for (const test of testsarr) {
                if (test.data.sampleId === samplesArr[i]) {
                  await testcollection.push(test);
                  // console.log('collection ', testcollection);
                }
              }
              await allTestsPerSampleID.push({
                sampleID: samplesArr[i],
                tests: testcollection,
              });
            }
            // console.log('sorted tests per sample id ', allTestsPerSampleID);

            let frequency = await this.getQCFrequency(); //this.getCollectionData('System', 'qcFrequency');
            let hours = frequency; //.data.hours;
            let dateoflasttest = lastProficiencydate;
            // console.log('date of last test ', dateoflasttest);
            let timediff =
              new Date().getTime() - dateoflasttest.toDate().getTime();
            // // console.log('timediff as hours', timediff/3600000);

            for (const coll of allTestsPerSampleID) {
              let latestTest = await coll.tests.reduce((a, b) =>
                a.data.testDateTime > b.data.testDateTime ? a : b
              );

              let timediffbyTest =
                lastProficiencydate.toDate().getTime() -
                latestTest.data.testDateTime.toDate().getTime();
              await latestTests.push(latestTest);
            }
            // let fac = await this.getCollectionData('SQACustomers', this.currentFacilityId);

            // if (timediff > hours*3600*1000) {
            //   await latestTests.push({isNewRound: true});
            // } else {
            await latestTests.push({ isNewRound: false });
            // }
            // console.log('latest tests arr ', latestTests);

            res(await latestTests);

            // latestTest = {
            //   scheme: lastProficiencyScheme,
            //   dateTime: lastProficiencydate,
            //   testId: lastProficiencytestId
            // };
            // res(await latestTest.scheme);
          }
        })
        .catch((err) => {
          rej(err);
        });
    });
  }

  public getLastRunProficiencyPerScheme() {
    return new Promise<any>((res, rej) => {
      // console.log('in get last prof');
      let schemes = {
        neqas: "pending",
        quadega: "pending",
        capapi: "pending",
      };
      this.db
        .collection("SQACustomers")
        .doc(this.currentFacilityId)
        .collection("proficiencyTests")
        .get()
        .then(async (tests) => {
          if (tests.empty) {
            // console.log('No matching qc levels.');
            // rej('No tests found.');
            res(schemes);
            return;
          } else {
            let lastDateN;
            let lastDateQ;
            let lastDateC;

            for (const test of tests.docs) {
              // console.log(test.data().testDateTime);
              switch (test.data().scheme) {
                case "NEQAS":
                  if (test.data().testDateTime > lastDateN || !lastDateN) {
                    lastDateN = test.data().testDateTime;
                    schemes.neqas = lastDateN.toDate();
                  }
                  break;
                case "QuaDeGa":
                  if (test.data().testDateTime > lastDateQ || !lastDateQ) {
                    lastDateQ = test.data().testDateTime;
                    schemes.quadega = lastDateQ.toDate();
                  }
                  break;
                case "CAP/API":
                  if (test.data().testDateTime > lastDateC || !lastDateC) {
                    lastDateC = test.data().testDateTime;
                    schemes.capapi = lastDateC.toDate();
                  }
                  break;
                default:
                  break;
              }
            }
            // console.log('lastDates ', schemes);
            res(schemes);
          }
        })
        .catch((err) => {
          rej(err);
        });
    });
  }

  public getDistributerById(id: string) {
    if (this.isConnected) {
      return this.getCollectionData("Distributers", id);
    } else {
      return new Promise((res, rej) => {
        rej("no internet connection!");
      });
    }
  }

  public linkDistributor(distributorId) {
    let data = {
      servicePersons: [
        {
          id: distributorId,
          order: true,
          support: true,
        },
      ],
    };
    if (this.isConnected) {
      return this.updateCollectionData(
        "SQACustomers",
        this.currentFacilityId,
        data
      );
    } else {
      return new Promise((res, rej) => {
        rej("no internet connection!");
      });
    }
  }

  private async getQCFrequency() {
    let hostname = window.location.hostname;
    let frequency;
    let data = await this.getCollectionData("System", "qcFrequency");
    if (
      hostname.toString().toLowerCase().includes("validation") ||
      hostname.toString().toLowerCase().includes("localhost")
    ) {
      // console.log('fff ', data.data.hoursInValidation)
      return (frequency = data.data.hoursInValidation);
    } else {
      // console.log('fffff ', data.data.hours)
      return (frequency = data.data.hours);
    }
  }
 
  public async sendVerificationEmail(email, siteMode: boolean, userName: string):Promise<string> {
    let url = this.apiEP + "/sendVerificationEmailWithOTP2";
    let data = {
      email: email,
      iOwMode: siteMode,
      userName: userName
    };

    try {
      const _response = await this._http.post(url, JSON.stringify(data)).toPromise();

      let encryptedOTP = _response['OTP'];
      let _k = _response['IPorigin'];
      let OTP = this.encryptionService.decrypt(encryptedOTP, _k);
      
      return OTP;
      
    } catch (error) {
      throw error;
    }

  }
  
  public sendMFALoginVerificationMail(email: string, siteMode: boolean) {
    let url = this.apiEP + "/sendLoginVerificationEmail2";
    let data = {
      email: email,
      siteMode: siteMode
    };
    return this._http.post(url, JSON.stringify(data));
  }

  public processRecaptchaValidation(RCPTtoken: string) {
    let url = this.apiEP + '/confirmCP';
    let data = {
      token: RCPTtoken,
      control: new Date()
    }

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-hdr-checksum': this.encryptionService.createChecksum(data.control)
    });
    
    this.cptcCheck_k = this.encryptionService.createChecksum(data.control);
    
    return new Promise<any>((res, rej) => {
      this._http.post(url, JSON.stringify(data), { headers }).subscribe(tokenCheckResponse=> {
        res(tokenCheckResponse)

      },
      error => {
        rej(error)  
      } 
    );

    })
  }

  private async getSKey() {
    this._http.get('https://additional.mes-data.app/retrieve').pipe(first()).subscribe(res => {
      this._sKey = res['content'];
    });
  }

  public checkValidRegistrationNumberENC(regNumber: string, _iOwMode: boolean, _host: string) {
    let regApi: string;

    const hostname = window.location.hostname.toString().toLowerCase();
    if (hostname.includes("localhost")) {
      regApi = "api_registration";
    } else {
      // regApi = "https://smsapi-ldoxyjbqhq-uc.a.run.app";
      regApi = "https://credit.mes-data.app";
    }

    let url = regApi + "/gettokenregnumstatus";
    let regNumStatus = {};
    let data = {
      regnum: regNumber,
      iOwMode: _iOwMode,
      clientHost: _host
    };
    
    const _sKey = this._sKey + regNumber;
    return new Promise<any>((res, rej) => {
      this._http
        .post(url, JSON.stringify(data), httpOptions)
        .toPromise()
        .then((response: string) => {
          try {
            // let _decrypted = this.encryptionService.decodeJWTasSHA256(response,'CJuaWNrbmFtZSI6Ikplc3MifQ.EDkUUxaM439gWLsQ8a8mJWIvQtgZe0et3O3z4Fd_J8oeyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI0MjQyIiwibmFtZSI6Ikplc3NpY2EgVGVtcG9yYWwiL');
            let _decrypted = this.encryptionService.decodeJWTasSHA256(response, _sKey);
            let _payload = JSON.parse(_decrypted.decodedPayload);
            regNumStatus = _payload;
            res(regNumStatus);
          } catch (error) {
            res({id: null , status: 0});
          }
        })
        .catch((err) => {
          rej(err);
        });
    });
  }

  public MarkRegistrationNumberAsUsed(
    regNumber: string,
    facilityId: string,
    facilityName: string,
    email: string
  ) {
    let regApi: string;
    let regDate = firebase.firestore.Timestamp.fromDate(new Date());
    const hostname = window.location.hostname.toString().toLowerCase();
    if (hostname.includes("localhost")) {
      regApi = "api_registration";
    } else {
      // regApi = "https://smsapi-ldoxyjbqhq-uc.a.run.app";
      regApi = "https://credit.mes-data.app";
    }

    let url = regApi + "/updateregnumstatus";
    const regHttpOptions = { ...httpOptions, responseType: "text" as "json" };
    let regNumStatus = {};
    let data = {
      regnum: regNumber,
    };

    this.afs
      .collection("RegistrationNumbers")
      .doc(regNumber)
      .set(
        {
          status: "used",
          usingFacility: { facilityId, facilityName, email },
          registrationDate: regDate,
        },
        { merge: true }
      )
      .then((res) => {
        return true;
      });
    return new Promise<any>((res, rej) => {
      this._http
        .post(url, JSON.stringify(data), regHttpOptions)
        .toPromise()
        .then((response) => {
          regNumStatus = response;
          res(regNumStatus);
        })
        .catch((err) => {
          rej(err);
        });
    });
  }


  public get getLowQuality(): { MSC: Number, TSC: Number, LV_MSC: Number } {
    return this.lowQualityCriteria
  }

}
