import { formatDate } from '@angular/common';
import { Component, OnInit, Renderer2, ViewChildren, ElementRef, ViewChild, Input, ChangeDetectorRef, ChangeDetectionStrategy, AfterViewInit, QueryList } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import * as firebase from 'firebase';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/auth.service';
import { Patient, SystemSettings, Test } from 'src/app/globals/globals';
import { Custompipe } from 'src/app/pipes/pipes.module';
import { GlobalService } from 'src/app/services/global.service';
import { HttpService } from 'src/app/services/http.service';
declare const $: any;

interface formData {
  firstName?: string,
  lastName?: string,
  referringDoc?: string,
  sampleInformation?: sampleInfo
}
interface sampleInfo {
  patientAge?: number
}

@Component({
  selector: 'app-results-header',
  templateUrl: './results-header.component.html',
  styleUrls: ['./results-header.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResultsHeaderComponent implements OnInit, AfterViewInit {
  @ViewChild("patientName") name: ElementRef
  @ViewChild("ageTxt", { static: true }) ageTxt: ElementRef
  @ViewChild("backBtn") backBtn: ElementRef
  @ViewChild("mainDiv", { static: true }) mainDiv: ElementRef
  @ViewChildren('underLineTxt',  {read: ElementRef}) updateTextList:QueryList<ElementRef>;
  @Input() isCounter: boolean = false
  @Input() facilityUser: any;
  Patient_Data: any = {}
  Result_Data: any = {};
  refbyDoc: string;
  showSpinner: boolean = false
  patientNameForm: FormGroup
  maxlength: number = 25;
  InternetSubscription: Subscription;
  global
  dateformat: string;
  whoTxt: string
  dateTime: string;
  momentRef: any = moment
  initialFormValue: formData
  enableSubmit: boolean = false
  systemSettings: SystemSettings
  privacyModeActive: boolean = false;
  _facilitySubscription: Subscription;
  isiOMode:boolean = true

  prepTypes: any = { 0: "PRE-PREP", 1: "POST-PREP" }
  pickerDate: string;


  constructor(public _glob: GlobalService, public translate: TranslateService, private customPipe: Custompipe, private formBuilder: FormBuilder, private _http: HttpService, private _renderer: Renderer2, private _route: Router, private _cdr: ChangeDetectorRef, private _authService:AuthService) {
    this.global = _glob
    this.Patient_Data = { ...this._glob.Patient_Info }
    this.Result_Data = this.deepCopy(this._glob.Fresh_Test_Data)
    this.isiOMode = !_authService.iOwMode
    this.whoTxt = `WHO ${this.Result_Data.testSettings.who + 2}`
    this.dateformat = this._glob.pcDateFormat + ' | ' + this._glob.pcTimeFormat;
    this.dateTime = this.Result_Data.dateTime instanceof Date ? customPipe.transform(this.Result_Data.dateTime, this.dateformat, translate.currentLang) : customPipe.transform(this.Result_Data.dateTime.toDate(), this.dateformat, translate.currentLang)
    this.patientNameForm = this.formBuilder.group({
      firstName: [""],
      lastName: [""],
      referringDoc: [""],
    });
    this.InternetSubscription = _glob.networkMonitor().subscribe((status: any) => {
      if (!status) {
        $('#editName').modal('hide');
        console.log();
        _renderer.addClass(this.name.nativeElement, "no-poionters")
      } else {
        _renderer.removeClass(this.name.nativeElement, "no-poionters")
      }
    })
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if (event.lang == "ja") {
        this.maxlength = 20
      } else {
        this.maxlength = 25
      }
    })
  }

  // checkForPrivacyMode(settings: any) {
  //   try {
  //     let privacySettings = { 
  //                             disableFirstLastName: settings.disableFirstLastName, 
  //                             disableBirthDateAge: settings.disableBirthDateAge, 
  //                             removePatientPhone:settings.removePatientPhone
  //                           };
  //     // Privacy mode enabled only if all settings are checked
  //     if (Object.values(privacySettings).every(setting => setting === true)) {
  //       this.privacyModeActive = true;
  //     } else {
  //       !this.privacyModeActive;
  //     }
  //   } catch (error) {
  //     !this.privacyModeActive;
  //     console.error('Privacy settings not found', !this.privacyModeActive, error)
  //   }
  // }

  ngAfterViewInit(): void {
    this.systemSettings = this._http.systemSettings;
    //If iOw mode is true remove the underlines that allows to edit
    if (this._authService.iOwMode) {
      this.updateTextList.forEach(element => {
        console.log(element,"KKKKKKKKKKKK");
        this._renderer.removeClass(element.nativeElement,"edit-info")
      });
    }
   
    this._cdr.detectChanges()
  }

  ngOnInit(): void {
    // this.checkForPrivacyMode(this.systemSettings);
    // this._facilitySubscription = this._http.facilityUpdates.subscribe((fUpdate) => {
    //   console.log("??????????????????",fUpdate);


    // })

    console.log("Came Hereeeeeeeeeeee", this.Result_Data.testSettings.patientAge);

    if (this.Result_Data.testSettings.patientAge != "BirthDate") {
      this.patientNameForm.addControl('sampleInformation', this.formBuilder.group({ 'patientAge': [this.Result_Data.sampleInformation.patientAge, [Validators.max(100), Validators.min(16)]] }))
    } else {
      this.patientNameForm.addControl('birthDate', this.formBuilder.control(this.formatBirthdate(this.Patient_Data.birthDate)))
    }

    this.patientNameForm.valueChanges.subscribe(form => {
      console.log(this.enableSubmit);
      this.enableSubmit = (JSON.stringify(form) != JSON.stringify(this.initialFormValue) && this.initialFormValue != undefined)

    })
  }

  setMaxLengths() {
    if (this.translate.currentLang == "ja") {
      this.maxlength = 20
    } else {
      this.maxlength = 25
    }
  }

  calculateAge(): string {
    console.log(this._glob.Patient_Info, this.Patient_Data);

    let dateandAge: string
    let bday: Date
    let age: any
    // if (this._glob.Patient_Info.birthDate != undefined && this._glob.Patient_Info.birthDate != '') {
    if (this.Result_Data.testSettings.patientAge == 'BirthDate') {
      if (this._glob.Patient_Info.birthDate != undefined && this._glob.Patient_Info.birthDate != '') {
        try {
          bday = this._glob.Patient_Info.birthDate.toDate();
          age = Math.floor((new Date().getTime() - new Date(bday).getTime()) / 31556952000);
          this.pickerDate = this.customPipe.transform(bday, 'yyyy-MM-dd', this.translate.currentLang, true)
          dateandAge = `${this.customPipe.transform(bday, this._glob.pcDateFormat, this.translate.currentLang, true)}\xa0\xa0|\xa0\xa0${age.toString()}`;
        } catch (error) {
          bday = this._glob.Patient_Info.birthDate;
          age = Math.floor((new Date().getTime() - new Date(bday).getTime()) / 31556952000);
          dateandAge = `${formatDate(bday, this._glob.pcDateFormat, this.translate.currentLang)}\xa0\xa0|\xa0\xa0${age.toString()}`;
          this.pickerDate = formatDate(bday, 'yyyy-MM-dd', this.translate.currentLang)

        }
        // var timeDiff = new Date().getTime() - new Date(bday).getTime();
        age = Math.floor((new Date().getTime() - new Date(bday).getTime()) / 31556952000);
        dateandAge = `${this.customPipe.transform(bday, this._glob.pcDateFormat, this.translate.currentLang, true)}\xa0\xa0|\xa0\xa0${age.toString()}`;
      } else {
        dateandAge = 'N/A'
      }
    }
    else {
      if (this.Result_Data.sampleInformation.patientAge) {
        age = this.Result_Data.sampleInformation.patientAge
        dateandAge = `${this.Result_Data.sampleInformation.patientAge}`;
      } else {
        dateandAge = 'N/A'
      }

    }
    console.log(dateandAge, "------------------");

    return dateandAge
  }

  onSubmit(frm: FormGroup) {
    const patient = ["firstName", "lastName", "birthDate"]
    const specificTest = ["sampleInformation", "referringDoc", "birthDate"]
    this.showSpinner = true
    let allPromises: any = []

    const removeInitialValues = (obj) => {
      console.log(this.initialFormValue, JSON.parse(JSON.stringify(frm.value)));

      for (const key in obj) {
        const element = obj[key];
        if (element != null && typeof element == "object") {
          removeInitialValues(element) && delete obj[key]
        } else {
          console.log(element, key);
          if (key != "patientAge") {
            if (element == this.initialFormValue[key])
              delete obj[key]
          } else {
            console.log(element, this.initialFormValue["sampleInformation"][key], "LLLLLLLLLLLLLLLLL");
            if (element == this.initialFormValue["sampleInformation"][key]) {
              return true
            } else {
              console.log("Came Here", obj);
              if (element == null)
                obj[key] = ""
              return false
            }
          }
        }
      }
      return obj
    }
    const objTobeSaved = removeInitialValues(frm.value)
    console.log(objTobeSaved, ":::::::::::::::::::::::::::::::::");

    const makeObj = (keys: any[], isAllTests?: boolean) => {
      let returningObj = {}
      for (const key in objTobeSaved) {
        const element = objTobeSaved[key];
        if (keys.includes(key)) {
          if (key == "birthDate") {
            if (new Date(element) instanceof Date && !isNaN(new Date(element).valueOf()))
              returningObj[key] = new Date(element)
            else {
              returningObj[key] = firebase.firestore.FieldValue.delete()
              returningObj["sampleInformation"] = { ["patientAge"]: firebase.firestore.FieldValue.delete() }
              this._glob.Patient_Info.birthDate = undefined
              this._glob.Fresh_Test_Data.sampleInformation.patientAge = undefined
              this.Result_Data.sampleInformation.patientAge = undefined
            }
          }
          else {
            console.log(element, returningObj[key], "PPPPPPPPPPPPPPPPPPPPPP");
            returningObj[key] = element ?? ""
          }
        }
      }
      return returningObj
    }
    const patientData: any = makeObj(patient)
    const specificTestData: any = makeObj(specificTest)
    const allTests = {
      ...objTobeSaved.firstName != undefined && { patientFirstName: objTobeSaved.firstName },
      ...objTobeSaved.lastName != undefined && { patientLastName: objTobeSaved.lastName }
    }
    console.log(allTests, specificTestData, frm.value, "===============================");

    if (Object.keys(allTests).length != 0) {
      allPromises.push(this._http.updatePatientNameInTests(this.Patient_Data.patientid, allTests))
      this._glob.Patient_Info = this.deepCopy({ ...this.Patient_Data, ...allTests })
    }
    if (Object.keys(patientData).length != 0) {
      if (patientData.birthDate) {
        if (new Date(patientData.birthDate) instanceof Date && !isNaN(new Date(patientData.birthDate).valueOf())) {
          this._glob.Patient_Info.birthDate = new Date(patientData.birthDate)
          this.Patient_Data.birthDate = new Date(patientData.birthDate)
          this._glob.Patient_Info = { ...this.Patient_Data }
          this.calculateAge()
        } else {
          this._glob.Patient_Info.birthDate = undefined
          this.Patient_Data.birthDate = undefined
          this.patientNameForm.removeControl("birthDate")
          this.patientNameForm.addControl('sampleInformation', this.formBuilder.group({ 'patientAge': [this.Result_Data.sampleInformation.patientAge, [Validators.max(100), Validators.min(16)]] }))
          this._glob.Patient_Info = { ...this.Patient_Data }
          this._cdr.detectChanges()
          console.log(patientData, this._glob.Patient_Info, this.Patient_Data);

          // this.calculateAge()
        }
      }
      allPromises.push(this._http.UpdatePatientData(this.Patient_Data.patientid, patientData))
    }
    if (Object.keys(specificTestData).length != 0) {
      allPromises.push(this._http.updateTestData(this.Patient_Data.patientid, this.Result_Data.id, specificTestData))
      // console.log(this.Result_Data,allTests,...JSON.parse(JSON.stringify(specificTestData)));
      //below line will merge patientAge to global object
      this._glob.Fresh_Test_Data = this.deepCopy({ ...this.Result_Data, ...allTests })
      this._glob.Fresh_Test_Data.sampleInformation = { ...this._glob.Fresh_Test_Data.sampleInformation, ...specificTestData.sampleInformation }
    }
    console.log(makeObj(patient), allTests, { ...this.Result_Data, ...allTests }, this._glob.Fresh_Test_Data);
    Promise.all(allPromises).then((data: any) => {
      this.showSpinner = false
      $('#editName').modal('hide');
      this._http.loadArchiveTestData(this._http.currentFacilityId);
    })
  }

  onBack() {
    this.patientNameForm.reset()
    this.Patient_Data = { ...this._glob.Patient_Info }
    this.Result_Data = this.deepCopy(this._glob.Fresh_Test_Data)
    console.log(this.Result_Data);

  }

  public get pf() {
    return this.patientNameForm.controls
  }


  public get sampleInfo() {
    return (this.patientNameForm.get('sampleInformation') as FormGroup).controls
  }


  onLoadpopup() {

    this.patientNameForm.patchValue({
      firstName: this.Patient_Data.firstName,
      lastName: this.Patient_Data.lastName,
      referringDoc: this.Result_Data.referringDoc ?? ""
    });
    console.log(this.Patient_Data, { ...this.Result_Data });
    if (this.Result_Data.testSettings.patientAge != "BirthDate") {
      // if (this.Patient_Data.birthDate == undefined) {
      if (this.patientNameForm.get('sampleInformation'))
        this.patientNameForm.get("sampleInformation.patientAge").setValue(this.Result_Data.sampleInformation.patientAge)
      else
        this.patientNameForm.addControl('sampleInformation', this.formBuilder.group({ 'patientAge': this.Result_Data.sampleInformation.patientAge }))
    } else {
      if (this.patientNameForm.get('birthDate'))
        this.patientNameForm.get("birthDate").setValue(this.formatBirthdate(this.Patient_Data.birthDate))
      else
        this.patientNameForm.addControl('birthDate', this.formBuilder.control(this.formatBirthdate(this.Patient_Data.birthDate)))
    }
    this.initialFormValue = this.patientNameForm.value

    $('#editName').modal('show')
    // if (this._route.url.includes('counter')){
    //   setTimeout(() => this.backBtn.nativeElement.focus());
    //   // this._cdr.detectChanges()
    // }

    // console.log(this._route.url.includes('counter'))
  }

  translateSampleType(type) {
    if (type == 1) {
      return this.translate.instant('test_process.normal_volume')
    } else if (type == 2) {
      return this.translate.instant('test.dilution');
    } else if (type == 3) {
      return this.translate.instant('test_process.10_micro')
    }
  }

  dateToIso(date?: Date | any, onlyDate?) {
    if (date == undefined || date == "")
      date = new Date()
    onlyDate == undefined ? onlyDate = -8 : null
    let tzoffset = (date).getTimezoneOffset() * 60000; //offset in milliseconds
    let maxdate = (new Date(date.getTime() - tzoffset)).toISOString().slice(0, onlyDate);

    return maxdate
  }

  getPickerFormate(date: Date) {
    return date.toISOString().slice(0, -14)
  }

  deepCopy(objToCopy: any): any {
    let objtoReturn: any = {}
    if (typeof objToCopy === "object") {
      for (const key in objToCopy) {
        const element = objToCopy[key];
        // console.log(Array.isArray(element), key);
        if (Array.isArray(element)) {
          let Arr = []
          element.forEach(arrelement => {
            Arr.push(arrelement)
          });
          objtoReturn[key] = Arr
        } else if (typeof element === "object" && !(element instanceof Date)) {
          objtoReturn[key] = this.deepCopy(element)
        } else {
          objtoReturn[key] = element
        }
      }
      return objtoReturn
    }
  }

  formatBirthdate(birthDate: any) {
    // const birthDate:any = new Date()
    console.warn('header', birthDate)
    if (birthDate == undefined) return birthDate
    try {
      return this.customPipe.transform(birthDate.toDate(), 'yyyy-MM-dd', this.translate.currentLang, true)
    } catch (e) {
      if (birthDate !== '') {
        return new Date(birthDate).toISOString().slice(0, -14)
      }
    }
    // return this.pickerDate
  }

  public get max(): string {
    return this.dateToIso(this.momentRef().subtract(16, 'years').toDate(), -14)
  }


  public get min(): string {
    return this.dateToIso(this.momentRef().subtract(100, 'years').toDate(), -14)
  }


  public set setInitialFormValue(patitentInfo: formData) {
    this.initialFormValue = patitentInfo;
  }


  public get fullPatientName(): string {
    if ((this.Patient_Data.firstName == undefined && this.Patient_Data.lastName == undefined) || (this.Patient_Data.firstName == '' && this.Patient_Data.lastName == '')) {
      return "N/A"
    } else {
      return `${this.Patient_Data[this.translate.currentLang != 'ja' ? "firstName" : "lastName"] ?? ''} ${this.Patient_Data[this.translate.currentLang != 'ja' ? "lastName" : "firstName"] ?? ''}`
    }
  }

  setLimit(event: KeyboardEvent, value: string, limit: number) {
    console.log(event);

    if (event.key == "Backspace")
      return true
    if (value.length >= limit || event.key == '-' || event.key == '+') {
      return false
    }
  }

  onPaste(event: ClipboardEvent, forControl) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData("text");
    if (forControl == "age") {
      if (pastedText.length > 3 || $("#age").val() != "") {
        event.preventDefault();
      } else {
        for (var i = 0; i < pastedText.length; i++) {
          if (
            !(pastedText.charCodeAt(i) >= 48 && pastedText.charCodeAt(i) <= 57)
          ) {
            event.preventDefault();
          }
        }
      }
    }
  }


  public get getageTxt(): string {
    return this.translate.instant("test.age")
  }
  isObject(element) {
    return typeof element == "object"
  }

}
