import { Component, OnInit, OnDestroy, ChangeDetectorRef, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { AuthService } from 'src/app/core/auth.service';
import { Subject, Subscription } from 'rxjs';
import { User } from 'src/app/globals/user';
import { GlobalService } from 'src/app/services/global.service';
import { CommunicatorService, ActiveClient, MyServiceEvent, TestState } from 'src/app/services/communication.service';
import { Router, NavigationEnd, ActivatedRoute, NavigationStart } from '@angular/router';
// import { RightSidebarComponent } from '../right-sidebar/right-sidebar.component';
import { version } from 'package.json';
import { HttpService } from 'src/app/services/http.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';
import { environment } from 'src/environments/environment';
declare var navigator;

import { debounceTime, first } from 'rxjs/operators';
import { MediaDeviceService } from 'src/app/services/media-device.service';

const document: any = window.document;
declare const $: any;
declare const jQuery: any;
declare const mozFullScreenElement: any;

interface sensor {
  capillaryState?: number,
  resData?: any,
  capSensor?: any,
  temperature?: number
}
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit, OnDestroy {

  @ViewChild("disconnectBody") disconnect: ElementRef
  @ViewChild('reorder') reorder: ElementRef
  photo: string;
  doctorName: string;
  isConnected: boolean;
  subscription: Subscription;
  logoUrl: string = "assets/images/SQA-iO-Logo-white.png";
  isLogoSmall: boolean = false;
  NumberofSamplesPoints = 100;

  appVersion: string;
  driver: string;
  userGuideUrl: any;
  connected = false;
  redirLink: string;
  isDriverInstalled: boolean;
  photodefined: boolean;
  languages: any;
  currentLanguage: any;
  viewSample: string;
  contact: string;
  guide: string;
  help: string;

  slowNetworkTooltip: string;
  stableNetworkTooltip: string;
  noNetworkTooltip: string;
  networkStatusTooltip: string;

  notifications: string;
  fullScreen: string;
  videos: string;
  isUSBgreen = false;
  isUSBProgress: boolean = false;
  userProfile: string
  languagesTooltip: string

  allNotifications = [];
  isNewNotifications: boolean = false;
  modalNotificationTitle: string;
  modalNotificationBody: string;
  _serviceSubscription: Subscription;
  NotificationsEmpty = true;
  seviceManualUrl: any;
  cyberSecurityDocUrl: string;
  debrisGuideUrl: string;
  InternetSubscription: Subscription;
  isInternet: boolean = true
  networkSpeed: any;
  offlineIcon: any;
  networkStatusIcon: string = 'assets/images/network_status/stable_connection.png';
  vuConnectionIcon: string = 'assets/images/VU_icon.png'
  contentLimitation: any;
  private _facilitySettingsSubscription: Subscription;
  VUConnection$: Subscription;
  selectedPortindex = 0;
  sensorCheck$: Subject<any> = new Subject<any>()
  sensorData: sensor = {}
  facility: any;
  showLanguageSelector: boolean = true;

  public isVisualizationConnected: boolean = false;
  public isVUDeviceConnected: boolean = false;
  private isInTestProcessScreen: boolean = false;
  openVisualizationFirstTime: boolean = false;
  showVisualization: boolean = false;
  isDeviceBusy: boolean = false;

  args = {
    hideControls: true,
    showFullScreenBtn: false,
    showZoomBtn: false,
    showRealTimeBtn: false,
    showStreamPlayFreezeBtn: false,
    showPreviewPlayFreezeBtn: false,
    showBackBtn: false,
    isFullScreen: false,
    isGrid: false,
    isFreezed: false,
    isZoom: false,
    showRealtimeText: false,
    height: false,
    isStreaming: false,
    mainvideodivHeight: false,
    mainVideoFooterHeight: false,
    rightSideHeight: false,
    showCameraSettings: false,
    showVideoBottomText: false,
  };
  isSelfTestDone: boolean = false;

  constructor(
    private elRef: ElementRef,
    private _renderer: Renderer2,
    private langService: LanguageService,
    public translate: TranslateService,
    private Comm: CommunicatorService,
    public authService: AuthService,
    public globalService: GlobalService,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpService,
    private cdr: ChangeDetectorRef,
    private _mediaDeviceService: MediaDeviceService
  ) {
    this.languages = langService.getAllLanguages();
    this.guide = "Training Videos";

    this.InternetSubscription = globalService.networkMonitor().subscribe((status: boolean) => {
      this.isInternet = status
      globalService.isHavingInternet = status
      if (!status) {
        this.networkStatusIcon = this.offlineIcon;
        this.networkStatusTooltip = this.noNetworkTooltip;
        _renderer.setStyle(this.disconnect.nativeElement, "pointer-events", "none")
      } else {
        _renderer.removeStyle(this.disconnect.nativeElement, "pointer-events")
        this.isDeviceBusy = false;
      }
    })
    this.checkIsvisuazationConnected();

    if (navigator.userAgent.indexOf("Firefox") > -1) {
      console.log('Firefox browser')
    } else {
      this.networkSpeed = navigator.connection;
      this.networkSpeed.onchange = (e) => {
        console.log(e.target);
        if (e.target.rtt >= 350 && e.target.rtt < 500) {
          this.networkStatusIcon = 'assets/images/network_status/slow_connection_a.png';
          this.networkStatusTooltip = this.translate.instant('general.internet_status');
        } else if (e.target.rtt >= 500) {
          this.networkStatusIcon = 'assets/images/network_status/slow_connection.png';
          this.networkStatusTooltip = this.translate.instant('general.internet_status');
        } else {
          this.networkStatusIcon = 'assets/images/network_status/stable_connection.png';
          this.networkStatusTooltip = this.translate.instant('general.internet_status');
        }
        this.cdr.detectChanges();
      }
    }

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.bindClickLinks();
      this.translate.get('general.view_sample').subscribe((res: string) => {
        this.viewSample = res;
      });
      this.translate.get('menu.contact').subscribe((res: string) => {
        this.contact = res;
      });
      this.translate.get('menu.user_guide').subscribe((res: string) => {
        this.guide = res;
      });
      this.translate.get('menu.full_screen').subscribe((res: string) => {
        this.fullScreen = res;
      });
      this.translate.get('settings.user_profile').subscribe((res: string) => {
        this.userProfile = res;
      });
      this.translate.get('general.languages').subscribe((res: string) => {
        this.languagesTooltip = res;
      });
      this.translate.get('general.no_internet').subscribe((res: string) => {
        this.noNetworkTooltip = res;
        this.networkStatusTooltip = this.noNetworkTooltip;
      });
      this.translate.get('menu.user_guides').subscribe((res: string) => {
        this.help = res;
      });
      this.translate.get('menu.notifications').subscribe((res: string) => {
        this.notifications = res;
      });
      this.translate.get('help.title').subscribe((res: string) => {
        this.videos = res;
      });
    });
    this.translate.get('general.view_sample').subscribe((res: string) => {
      this.viewSample = res;
    });
    this.translate.get('menu.contact').subscribe((res: string) => {
      this.contact = res;
    });
    this.translate.get('menu.user_guide').subscribe((res: string) => {
      this.guide = res;
    });
    this.translate.get('menu.full_screen').subscribe((res: string) => {
      this.fullScreen = res;
    });
    this.translate.get('settings.user_profile').subscribe((res: string) => {
      this.userProfile = res;
    });
    this.translate.get('general.internet_status').subscribe((res: string) => {
      this.networkStatusTooltip = res;
    });
    this.translate.get('general.no_internet').subscribe((res: string) => {
      this.noNetworkTooltip = res;
    });
    this.translate.get('general.languages').subscribe((res: string) => {
      this.languagesTooltip = res;
    });
    this.translate.get('menu.user_guides').subscribe((res: string) => {
      this.help = res;
    });
    this.translate.get('menu.notifications').subscribe((res: string) => {
      this.notifications = res;
    });
    this.translate.get('help.title').subscribe((res: string) => {
      this.videos = res;
    });

    if (this.http.realtimeNotifications) {

      this.http.realtimeNotifications.subscribe(async (res) => {

        res = res.filter(function (val) {
          if (val.active) { return val; }
        });

        res.forEach(async (val, key) => {
          res[key].updatedTimestamp = new Date(res[key].updatedTimestamp.toDate()).getTime();
        });

        res.sort((a, b) => b.updatedTimestamp - a.updatedTimestamp);

        if (res[0]) {
          this.NotificationsEmpty = false;
          // let lastNotification = parseInt(localStorage.getItem("lastNotification"));

          let lastNotification = await this.http.getLastNotificationTime();

          // console.log('--------------------------------------lastNotification---------------------------------');
          // console.log(lastNotification);

          if (lastNotification) {
            if (res[0].updatedTimestamp > lastNotification.lastNotification.toDate().getTime()) {
              this.isNewNotifications = true;
            }
          } else {
            this.isNewNotifications = true;
          }

        } else {
          // console.log('not last notification ', res);
          this.NotificationsEmpty = true;

        }

      });

    }
    this._facilitySettingsSubscription = this.http.facilityUpdates.subscribe((fUpdate) => {
      this.facility = fUpdate
      if (fUpdate.contentLimitation) {
        this.contentLimitation = fUpdate.contentLimitation
        this.bindClickLinks();
      }
    })

    this.authService.selfTestTrigger.pipe(first()).subscribe(res => {
      if (res === true) {
        console.log('Device test starting -->', res);
        if (this.isDeviceBusy) {
          console.log('Device not idle - aborting self-test.');
          return;
        }
        if (this.Comm.SocketArray.length !== 0 &&
          (this.Comm.SocketArray[0].TESTSTATE !== TestState.TEST_SELF_TEST &&
            this.Comm.SocketArray[0].TESTSTATE === TestState.IDLE)) {
          this.Comm.setCurrentClient(ActiveClient.HOME_PAGE);
          // this.Comm.StartSelftest(0);
          setTimeout(() => {
            if (this.authService.loginDeviceCommand === 'selftest') {
              console.log('SELF-TEST TRIGGERED');
              this.globalService.globalSelfTest$.next(true);
            } else if (this.authService.loginDeviceCommand === 'reconnect') {
              console.log('DEVICE RECONNECTION TRIGGERED');
              this.globalService.isReconnect$.next(true);
              this.Comm.reconnectDevices();
            }
          }, 200)
        } else {
          console.log('SKIPPING LOGIN SELF TEST')
        }
      } else {
        console.log('LOGIN SELF TEST OVERRIDE');
      }
    })

    let langId = this.langService.getSavedLang();
    this.currentLanguage = this.languages.find(x => x.id === langId);
    // console.log('header current language ', this.currentLanguage);

    // this.currentLanguage = this.languages[0];
    this.appVersion = version;
    // this.driver = 'ask Beni how to get the drive version';

    this.subscription = authService.user.subscribe((user: any) => {
      // console.log('current user ', user);
      if (user !== undefined && user !== null) {
        if (user.photoURL === null || user.photoURL === '' || user.photoURL === undefined) {
          this.photodefined = false;
        } else {
          this.photo = user.photoURL;
          this.photodefined = true;
        }
        if (user.doctorName !== null) {
          this.doctorName = user.doctorName;
        }
      }
    });
    // this.isConnected = true;

    if (this.Comm.IsDriverConnected) {
      this.isConnected = this.globalService.toggleconnect();
    } else {
      this.isConnected = false;
      this.connected = false;
    }
    if (this.isConnected && this.globalService.isDevicesnValid() && !this.isUSBProgress) {
      this.isUSBgreen = true;
    } else {
      this.isUSBgreen = false;

    }

    this.globalService.connected.subscribe((e) => {
      // console.log('connected event', e);
      this.isConnected = e;
      if (!e) {
        this.connected = false;
        $('#Deviceconnected').modal('hide');
        $(".modal-backdrop").remove();
        $('#Devicenotconnected').modal('hide');
        $('#Devicedisconnected').modal('hide');
      } else {
      }
      if (this.isConnected && this.globalService.isDevicesnValid() && !this.isUSBProgress) {
        this.isUSBgreen = true;
      } else {
        this.isUSBgreen = false;
      }
      this.cdr.detectChanges();
    });

    this.globalService.logoColor.subscribe((color) => {
      if (color === "white") {
        this.logoUrl = "assets/images/SQA-iO-Logo-white.png";
      } else {
        this.logoUrl = "assets/images/SQA-iO-Logo.png"
      }
    });


    this.globalService.isViewSample$.subscribe(res => {
      if (res) {
        this.isInTestProcessScreen = true;
      } else {
        this.isInTestProcessScreen = false;
      }
    })

  }

  bindClickLinks() {
    setTimeout(() => {
      const serviceLink = this.elRef.nativeElement.querySelectorAll('.link-to-faq');
      for (let i = 0; i < serviceLink.length; i++) {
        serviceLink[i].addEventListener('click', this.gotoFAQ.bind(this));
      }
    }, 100);

    this.globalService.getUserManualUrl(this.contentLimitation).then(url => {
      this.userGuideUrl = url;
    }).catch(err => {
    });
    this.globalService.getServiceManualUrl().then(url => {
      this.seviceManualUrl = url;
    }).catch(err => {
    });

    this.globalService.getHelpDocumentUrl('SQA_iO_cyber.pdf').then(url => {
      this.cyberSecurityDocUrl = url;
    }).catch(err => {
      console.error(err);
    });
    this.globalService.getHelpDocumentUrl('SQA-iO_debris_guide.pdf').then(url => {
      this.debrisGuideUrl = url;
    }).catch(err => {
      console.error(err);
    });

  }

  async handleInternetStatusIcons() {
    this.offlineIcon = await this.globalService.getBase64ImageFromURL('assets/images/network_status/internet_offline.png');
  }

  ngAfterViewInit() {
  }

  ngOnInit() {
    // $('#serial-no-error').modal('show');
    this.showLanguageSelector = !this.authService.iOwMode;
    this.handleInternetStatusIcons();
    this.onSelfTestStart()
    this.setStartupStyles();
    this.callFullScreen();
    this.setNavbar();
    this.setRightSidebar();
    console.log(`%c VU: ${this.isVisualizationConnected}`, "color: red; background-color: cyan");

    $(".navbar-right .dropdown-menu .body .menu").slimscroll({
      height: "254px",
      color: "rgba(0,0,0,0.5)",
      size: "4px",
      alwaysVisible: false,
      borderRadius: "0",
      railBorderRadius: "0"
    });

    if (this.router.url.includes('installation') || !this.Comm.IsDriverConnected()) {
      this.redirLink = 'installation/installation';
      this.isDriverInstalled = false;
    } else {
      this.redirLink = 'dashboard/main';
      this.isDriverInstalled = true;
    }

    this.router.events.subscribe((val) => {
      // console.log('router event url', this.router.url.toString());
      if (val instanceof NavigationEnd) {

        this.notificationListener();


        // console.log('navigation end url', val.url.toString());

        if (val.url.includes('installation') || !this.Comm.IsDriverConnected()) {
          this.redirLink = 'installation/installation';
          this.isDriverInstalled = false;
        } else {
          this.redirLink = 'dashboard/main';
          this.isDriverInstalled = true;
        }
        // console.log('in installation page', this.redirLink);
      }
    });

    let params = this.Comm.getParameters();
    // console.log('header params', params);
    if (params !== undefined) {
      this.driver = params.OBServiceVersion;
      this.connected = true;
    } else {
      this.connected = false;
    }
    this.globalService.Usb.subscribe((green) => {
      if (green && !this.isUSBProgress) {
        this.isUSBgreen = green;
      } else {
        this.isUSBgreen = false;
      }
      this.cdr.detectChanges();
    })

    this._serviceSubscription = this.Comm.onChange.subscribe({
      next: async (event: MyServiceEvent) => {
        // console.log(event.command,event);
        // console.log(`${event.command} | `, event.data, `${this.Comm.SocketArray[0].TESTSTATE} | ${this.Comm.getCurrentClient()}`)
        switch (event.command) {
          case 'DeviceBussy':
            // Set flag to true, if no test process or other specific client is active, to make sure self-test button will always respond
            if (this.Comm.getCurrentClient() === 0 || this.Comm.getCurrentClient() === 7 || this.Comm.getCurrentClient() === 9) {
              this.isDeviceBusy = true;
            }
            break;
          case 'initDevice':
            this.globalService.isReconnect$.next(true)
            break;
          case 'DeviceList':
            if (Array.isArray(event.value) && event.value.length > 0) {
              $('#Devicenotconnected').modal('hide');
              $('#Devicedisconnected').modal('hide');
              $('#Deviceconnected').modal('hide');
              this.isUSBProgress = true;
              this.globalService.isReconnect$.next(true)
            }
            break;
          case 'isConnect':
            // console.log("Driver connection change ", event.value);
            if (event.value === true) {
              this.isDriverInstalled = true;
            } else {
              this.isDriverInstalled = false;
            }
            break;
          case "ReadCap":

            if (this.Comm.getCurrentClient() == ActiveClient.TEST_PROCESS_CLIENT) {
              return;
            }
            if (event.data != undefined) {
              console.log("ooooooooooo");

              /*
              if (event.data["STATE"] == TestState.TEST_READ_CAP) {
                this.sensorData.capSensor = event.value;
                this.sensorData.capillaryState = event.data.isCap;
                // 1 - Capillary is inserted
                // 2 - Capillary is not inserted
                // 0 - Unknown, board with no capillary sensor
              }
              */

              if (event.data["STATE"] == TestState.TEST_READ_TEMP) {
                this.sensorData.temperature = event.value;
              }
            }
            break;

          case "capStatus":
            if (this.Comm.getCurrentClient() == ActiveClient.TEST_PROCESS_CLIENT) {
              return;
            }
            console.log("Capillary Status [analysis]", event.value)

            this.sensorCheck$.next(event.value)

            break;
          case "ReadDataRes":
            this.isDeviceBusy = false;
            break;
          case "usbadd":
            this.checkIsvisuazationConnectedFromDriverEvent('connectVU', event.value.vendorId, event.value.productId);
            let VUData: any;
            try {
              VUData = await this.getSerial();
            } catch (error) {
              console.error('VU Id error: ', error)
            }
            const updateFac = (data) => {
              this.http.UpdateFacilitySettings(data).then(res => {
                console.log(res);
              })
            }
            if ((VUData == undefined || VUData instanceof Error))
              break
            if (this.facility?.VUData == undefined) {
              updateFac({ VUData: VUData });
            } else {
              if (this.facility?.VUData.VUSerialNumber != VUData.VUSerialNumber) {
                updateFac({ VUData: VUData });
              }
            }
            break;
            case "usbremove":
              this.checkIsvisuazationConnectedFromDriverEvent('disconnectVU', event.value.vendorId, event.value.productId);           
              break;
          case "Finishoperations":
            this.isUSBProgress = false;
            this.isSelfTestDone = true;
            this.isDeviceBusy = false;;
            break
          case "ReadParameters":
            if (this.isSelfTestDone) {
              this.isSelfTestDone = false
            }
            break
        }
      }
    })


    //subscription to trigger the beep sound
    this.globalService.triggerAutoCalSound$.subscribe(eve => {
      if (eve != false) {
        this.beepClick()
        this.cdr.detectChanges()
      }
    })

    // this.globalService.triggerAutoCalSound$.next(true)
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.InternetSubscription.unsubscribe()
    this.VUConnection$?.unsubscribe();
  }

  connectDevice() {
    if (!this.isConnected || this.isUSBProgress === true) {
      $('#Devicedisconnected').modal('show');

    } else if (this.globalService.isDevicesnValid) {
      $('#Deviceconnected').modal('show');

    }

    //   if(this.globalService.isDevicesnValid()) {
    //   if (this.isConnected) {
    //     $('#Deviceconnected').modal('show');
    //   } else {
    //     $('#Devicenotconnected').modal('show');
    //   }
    //   //
    // } else {
    //   $('#serial-no-error').modal('show');
    // }

  }

  VUconnected() {
    $('#VUconnected').modal('show');
  }

  checkConnection() {
    var isDeviceConnected;
    if (this.Comm.SocketArray.length == 0) {
      isDeviceConnected = false;
      // console.log("IS device connected", this.Comm.SocketArray.length);

      $('#Devicenotconnected').modal('hide');

    } else {
      isDeviceConnected = true;
      $('#Deviceconnected').modal('show');
      // this.delay(50).then(() => {
      // 	this.StartSelfTest();                                        //STEP 2 Read NoiseLevel
      // })
    }
  }

  // StartSelfTest() {
  //   this.Comm.setCurrentClient(ActiveClient.CALIBRATION_CLIENT);
  //   this.Comm.StartSelftest(this.NumberofSamplesPoints);
  //   // console.log("number of samplepoints", this.NumberofSamplesPoints);
  // }

  setStartupStyles() {
    //set theme on startup
    if (localStorage.getItem("theme")) {
      $("body").removeClass("dark light");
      jQuery("body").addClass(localStorage.getItem("theme"));
    } else {
      jQuery("body").addClass("light");
    }

    // set dark sidebar menu on startup
    if (localStorage.getItem("menu_option")) {
      jQuery("body").addClass(localStorage.getItem("menu_option"));
    }
    else {
      jQuery("body").addClass("menu_dark");
      localStorage.setItem("menu_option", "menu_dark");
    }

    // set header color on startup
    if (localStorage.getItem("choose_skin")) {
      jQuery("body").addClass(localStorage.getItem("choose_skin"));
    } else {
      jQuery("body").addClass("theme-white");
      localStorage.setItem("choose_skin", "theme-white");
    }
    if (localStorage.getItem("choose_skin_active")) {
      $(".right-sidebar .demo-choose-skin li").each(function (index) {
        jQuery(this).removeClass("actived");
        if (
          jQuery(this).attr("data-theme") ==
          localStorage.getItem("choose_skin_active")
        ) {
          jQuery(this).addClass("actived");
        }
      });
      // } else {
      //   localStorage.setItem("choose_skin_active", "black");
      //   $(".right-sidebar .demo-choose-skin li").each(function (index) {
      //     jQuery(this).removeClass("actived");
      //     if (
      //       jQuery(this).attr("data-theme") ==
      //       localStorage.getItem("choose_skin_active")
      //     ) {
      //       jQuery(this).addClass("actived");
      //     }
      //   });
    }
    // set logo color on startup
    if (localStorage.getItem("choose_logoheader")) {
      jQuery("body").addClass(localStorage.getItem("choose_logoheader"));
    } else {
      jQuery("body").addClass("logo-black");
      localStorage.setItem("choose_logoheader", "logo-black");
    }
    if (localStorage.getItem("choose_logoheader_active")) {
      $(".right-sidebar .demo-choose-logoheader li").each(function (index) {
        jQuery(this).removeClass("actived");
        if (
          jQuery(this).attr("data-theme") ==
          localStorage.getItem("choose_logoheader_active")
        ) {
          jQuery(this).addClass("actived");
        }
      });
    }
    // set logo size
    if (localStorage.getItem("sidebar_collapsed")) {
      if (localStorage.getItem("sidebar_collapsed") === 'true') {
        // this.isLogoSmall = true;
        console.log("collapsed");

      } else {
        // this.isLogoSmall = false;
      }
      // this.isLogoSmall = localStorage.getItem("sidebar_collapsed");
    } else {
      // this.isLogoSmall = false;
    }


    //set theme default color
    $(".choose-theme li").removeClass("actived");
    $(".choose-theme li[data-theme|='white']").addClass("actived");

  }

  callFullScreen() {
    $(document).on("click", ".fullscreen-btn", function (e) {
      if (
        !document.fullscreenElement && // alternative standard method
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement &&
        !document.msFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.msRequestFullscreen) {
          document.documentElement.msRequestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
      }
    });
  }

  setNavbar() {
    let imgPath = localStorage.getItem("choose_logoheader");
    if (imgPath === "logo-black") {
      this.logoUrl = "assets/images/SQA-iO-Logo-white.png";
      // console.log("logo path", imgPath);
    } else {
      this.logoUrl = "assets/images/SQA-iO-Logo.png";
      // console.log("logo path", imgPath);
    }

    var $body = $("body");
    var $overlay = $(".overlay");

    //Open left sidebar panel
    $(".bars").on("click", function () {
      $body.toggleClass("overlay-open");
      if ($body.hasClass("overlay-open")) {
        $overlay.fadeIn();
      } else {
        $overlay.fadeOut();
      }
    });



    //Close collapse bar on click event
    $('.nav [data-close="true"]').on("click", function () {
      var isVisible = $(".navbar-toggle").is(":visible");
      var $navbarCollapse = $(".navbar-collapse");

      if (isVisible) {
        $navbarCollapse.slideUp(function () {
          $navbarCollapse.removeClass("in").removeAttr("style");
        });
      }
    });



    $(window).resize(() => {
      if ($("body").width() < 1465) {
        this.isLogoSmall = true
      } else {
        this.isLogoSmall = false
      }
    })
  }

  setRightSidebar() {
    var _this = this;
    var $sidebar = $("#rightsidebar");
    var $overlay = $(".overlay");

    //Close sidebar
    $(window).on("click", function (e) {
      var $target = $(e.target);
      if (e.target.nodeName.toLowerCase() === "i") {
        $target = $(e.target).parent();
      }

      if (
        !$target.hasClass("js-right-sidebar") &&
        _this.isOpen() &&
        $target.parents("#rightsidebar").length === 0
      ) {
        if (!$target.hasClass("bars")) $overlay.fadeOut();
        $sidebar.removeClass("open");
      }
    });
    $(".js-right-sidebar").on("click", function () {
      $sidebar.toggleClass("open");
      if (_this.isOpen()) {
        $overlay.fadeIn();
      } else {
        $overlay.fadeOut();
      }
    });
  }

  isOpen() {
    return $(".right-sidebar").hasClass("open");
  }

  logout() {
    // if (this.globalService.fromDataentry === true) {
    //   this.globalService.hasUnsavedChanges = false;
    //   this.globalService.driverUpdatePopup = true;
    //   this.globalService.logoutSetFlags();
    //   this.http.allFacilityTestsData$.next([]);
    //   this.authService.signOut();
    //   return;
    // }
    if (this.globalService.hasUnsavedChanges) {
      // $('#unsaved-data-modal').modal('show');
      this.globalService.headerLogoutClicked$.next('clicked');
    } else {
      this.globalService.hasUnsavedChanges = false;
      this.globalService.driverUpdatePopup = true;
      this.globalService.logoutSetFlags();
      this.http.allFacilityTestsData$.next([]);
      this.authService.signOut();
    }
  }

  close(checkBlocking: boolean) {
    if (checkBlocking) {
      this.globalService.hasUnsavedChanges = false;
      this.globalService.logoutSetFlags();
      this.authService.signOut();
    }
    $('#unsaved-data-modal').modal('hide');
  }

  collapseSideBar() {
    this.isLogoSmall = !this.isLogoSmall;
    localStorage.setItem("sidebar_collapsed", this.isLogoSmall.toString());
  }
  // closeFac(checkBlocking: boolean) {
  //   if (checkBlocking) {

  //     this.globalService.hasUnsavedChanges = false;
  //     this.globalService.navToFacilitySet = true;
  //     this.globalService.callFactilityUserSettings();
  //   }
  //   $('#unsaved-data-modal-fac-header').modal('hide');
  // }
  goToFacilityProfile() {
    this.router.navigate(['/sementest/settings'], { fragment: 'user_profile' });
  }

  showInfo() {
    let params = this.Comm.getParameters();
    // console.log('header params', params);
    if (params !== undefined && this.isConnected) {
      this.driver = params.OBServiceVersion;
      this.connected = true;
    } else {
      this.connected = false;
    }
    $('#About').modal('show');

  }

  selectLang(langId) {
    this.currentLanguage = this.langService.selectLang(langId);
  }



  notificationListener = () => {

    if (this.http.realtimeNotifications) {

      this.http.realtimeNotifications.subscribe({
        next: (res) => {

          // console.log('----------------------------fetchNotifications--------------------------------');
          // console.log( '----------------------------------------------------------------this.router.url-----------------------------------------------' );
          // console.log( this.router.url );

          var data = [];

          switch (this.router.url.toString()) {

            case '/dashboard/main' || '/':

              data = res.filter((value) => {
                if (value.active && value.display.homepage) {
                  return value;
                }
              });

              break;

            case '/sementest/test-process':

              data = res.filter((value) => {
                if (value.active && value.display.testpage) {
                  return value;
                }
              });

              break;

            case '/sementest/service':

              data = res.filter((value) => {
                if (value.active && value.display.servicepage) {
                  return value;
                }
              });

              break;
          }

        }

      });

    }

  }

  fetchNotifications = () => {


    this.lastNotification();

    if (this.http.realtimeNotifications) {

      this.http.realtimeNotifications.subscribe(res => {

        this.allNotifications = res.filter(function (val) {
          if (val.active) { return val; }
        });

        this.allNotifications.forEach(async (val, key) => {
          this.allNotifications[key].updatedTimestamp = new Date(this.allNotifications[key].updatedTimestamp.toDate()).getTime();
        });

        this.allNotifications.sort((a, b) => b.updatedTimestamp - a.updatedTimestamp);

        // if(this.allNotifications.length > 3){
        //   this.allNotifications.length = 3;
        // }

      });

    }


  }

  viewAllNotifications = () => {
    this.lastNotification();
    this.router.navigate(["/notifications/view-all-notifications"]);
  }

  lastNotification = () => {
    this.isNewNotifications = false;
    this.http.updateFacilityNotificationTime(new Date());
    // localStorage.setItem("lastNotification", (new Date()).getTime().toString());
  }


  showNotifications = (notification) => {

    this.modalNotificationTitle = notification.title;
    this.modalNotificationBody = notification.body;

  }
  gotoFAQ() {
    if (this.router.url == '/support/help?tabParam=faq') {
      $(function () {
        $('#myTab a[href="#faq"]').tab('show');
      })
    }
    $('.modal-backdrop').remove();
    this.router.navigate(['/support/help'], { queryParams: { tabParam: 'faq' } });
  }

  gotoHelpCenter() {
    if (this.authService.iOwMode === true) {
      this.router.navigate(['/support/help'], { queryParams: { tabParam: 'faq' } });
    } else {
      if (this.router.url == '/support/help?tabParam=videos') {
        $(function () {
          $('#myTab a[href="#video"]').tab('show');
        })
      }
      $('.modal-backdrop').remove();
      this.router.navigate(['/support/help'], { queryParams: { tabParam: 'videos' } });
    }
  }
  gotoContactus() {
    if (this.router.url == '/support/help?tabParam=contactus') {
      $(function () {
        $('#myTab a[href="#contact"]').tab('show');
      })
    }
    $('.modal-backdrop').remove();
    this.router.navigate(['/support/help'], { queryParams: { tabParam: 'contactus', requestParam: 'contactus' } });
  }
  openUserGuide() {
    window.open(this.userGuideUrl, '_blank');
  }

  openServiceManual() {
    window.open(this.seviceManualUrl, '_blank');
  }

  openCyberSecurityDoc() {
    window.open(this.cyberSecurityDocUrl, '_blank');
  }

  openDebrisGuideDoc() {
    window.open(this.debrisGuideUrl, '_blank');
  }

  onSelfTestStart() {

    this.globalService.globalSelfTest$.subscribe((isStart: boolean) => {

      console.log("Device self-test: ", isStart, this.isDeviceBusy);
      if (isStart && !this.isDeviceBusy) {       
          this.Comm.TestCapStatus(this.selectedPortindex)
      }
    })
    this.sensorCheck$.subscribe((res: any) => {
      console.log(res);
      if (res.capStatus) {
        setTimeout(() => {
          $("#capillaryInside").modal('show')
        }, 500);
      } else {
        $("#capillaryInside").modal('hide')
        $("#start-selfTest").modal('show')
        this.Comm.StartSelftest(4000);
      }
    },
    error => {
      console.error('Sensor Check error:', error);
    });

  }

  getSerial(): Promise<any> {
    if (!this.authService.isLoggedIn) return;
    let allowedVuDevices: any[] = this.globalService.defaults.CameraSettings.allowedDevices;
    return new Promise((res, rej) => {
      this.Comm.getusbDevicesByAPI().then((data: any) => {
        if (data !== 'Version error' && data.connected.length != 0) {
          this.http.checkVUserialNumber(data.connected, allowedVuDevices).then(result => {
            res({ VUSerialNumber: result.id, VendorSerialNumber: result.data().serialNumber })
          }).catch(err => {
            rej(err);
          });
        }
        else
          res(undefined)
      }).catch(err => {
        rej(err);
      })
    })
  }
  beepClick() {
    let audioCtx = new (window.AudioContext);
    var oscillator = audioCtx.createOscillator();
    var gainNode = audioCtx.createGain();

    oscillator.connect(gainNode);
    gainNode.connect(audioCtx.destination);

    gainNode.gain.value = 0.01;
    oscillator.frequency.value = 509;
    oscillator.type = 'sawtooth';

    oscillator.start();

    setTimeout(
      function () {
        oscillator.stop();
      },
      634
    );
  }


  checkIsvisuazationConnectedFromDriverEvent(command: string, _vendorID: number, _productID): void {
    let allowedVuDevices: any[] = this.globalService.defaults.CameraSettings.allowedDevices;
    let vendorIdList: number[] = [];
    let productIdList: number[] = [];
    allowedVuDevices.forEach(vuDetails => {
      vendorIdList.push(vuDetails['vendorId']);
      productIdList.push(vuDetails['productId'])
    });
    if (vendorIdList.includes(_vendorID) && productIdList.includes(_productID)) {
      this.isVUDeviceConnected = command === 'connectVU'? true: false;
    };
  }
  


  checkIsvisuazationConnected(): void {
    this._mediaDeviceService.detectDeviceFn();
    this.VUConnection$ = this._mediaDeviceService.isVisualizationaDeviceConnected$.subscribe((res: boolean) => {
      this.isVisualizationConnected = res;
      this.isVUDeviceConnected = res;
      console.log(`%c VU: ${this.isVisualizationConnected}`, "color: red; background-color: cyan");
      if (!this.isVisualizationConnected && $("#VUconnected").hasClass("show")) {
        $("#VUconnected").modal("hide");
      }
      this.cdr.detectChanges();
    });
  }




  get getIsOpenVisualization(): boolean {
    // return this.isVisualizationConnected && !this.isInTestProcessScreen;
    return this.isVUDeviceConnected && !this.isInTestProcessScreen;
  }


  openVisualization(): void {
    if (!this.getIsOpenVisualization) return;
    if (!this.openVisualizationFirstTime) this.openVisualizationFirstTime = true;
    this.showVisualization = true;
    this._mediaDeviceService.startStreaming();
  }

  async hideVisualization(): Promise<void> {
    this.showVisualization = false;
    var res = await this._mediaDeviceService.stopStreaming();
  }

}
