import { AppError } from './app-error';

export class EmailNotAvailableError extends AppError {
    authError = {
        code: 'auth/email-already-in-use',
        message: 'The Email is already used for another account.'
    };
    constructor(public originalError?: any) {
        super(originalError);
        this.originalError = this.authError;

    }
}
