import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  OnDestroy,
  OnChanges,
  Renderer2,
  ElementRef,
  ViewChild,
  Injectable,
} from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
} from "@angular/forms";
import { Observable, Subscription } from "rxjs";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";

import {
  TestSettings,
  // defaultTestSettings,
  additionalText,
  ReportType,
  SQACustomer,
} from "src/app/globals/globals";
import { HttpService } from "src/app/services/http.service";
import { GlobalService } from "src/app/services/global.service";
import { MorphCriteria } from "src/app/services/algorithm.service";
import { AuthService } from "src/app/core/auth.service";
import { MatSelect } from "@angular/material/select";
import { log } from "console";
import { HttpClient } from "@angular/common/http";
import { ReportsService } from "src/app/services/reports/reports.service";
import { DomSanitizer } from "@angular/platform-browser";

//for pef dowload
@Injectable({ providedIn: "root" })
export class DowloadService {
  constructor(private http: HttpClient) { }

  download(url: string): Observable<Blob> {
    return this.http.get(url, {
      responseType: "blob",
    });
  }
}

interface PatientInfoInputs {
  control: string;
  options: {
    translationPath: string;
    value: string;
  }[];
}

interface StandardPageReportStructure {
  text: string;
  control: string;
  input?: {
    text: string | { title: string; value: string }[];
    controlName: string;
    unit?: string;
    errorText?: string;
    controlEmailName?: string;
  };
}

interface ReportStructure {
  sectionId?: string;
  sectionText?: string;

  items: {
    text: string;
    control: string;
    input?: {
      text: string | { title: string; value: string }[];
      controlName: string;
      unit?: string;
      errorText?: string;
      controlEmailName?: string;
    };
  }[];
}

interface FileError {
  correctFormat: boolean;
  correctSize: boolean;
}

declare const $: any;

@Component({
  selector: "app-test-settings",
  templateUrl: "./test-settings.component.html",
  styleUrls: ["./test-settings.component.sass"],
})
export class TestSettingsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() facilityData: SQACustomer;
  @Input() defaultTestSettings: TestSettings;
  @Output() fireEvent = new EventEmitter();

  testSettingValues: TestSettings;
  form: FormGroup;
  formValueSubscription: Subscription;
  //file picker variablr
  facilityHtmlFile: File | undefined;
  fileName: string;
  fileBlob: string;
  fileList: string[] = [];

  defaulthtmlUrl = "";
  formInputChanged = false;
  fileError: FileError = { correctFormat: true, correctSize: true };
  labelInputActive = false;

  hideSMIOption: boolean = false;
  iOwMode: boolean = this._auth.iOwMode;
  hideConcStandard: boolean = false;

  //for linkTag
  interval;
  timerId;
  showPreview: boolean = false;
  previewContent: any;
  isInternetCheck: Subscription;
  isInternet: boolean = true;

  submitBtnLoading = false;
  currentLang: string;
  languageSubscription: Subscription;
  patientInfoInputs: PatientInfoInputs[] = [
    {
      control: "patientAge",
      options: [
        { translationPath: "settings.age", value: "Age" },
        { translationPath: "settings.birthdate", value: "BirthDate" },
      ],
    },
    {
      control: "heightUnit",
      options: [
        { translationPath: "settings.ft", value: "ft" },
        { translationPath: "settings.cm", value: "cm" },
      ],
    },
    {
      control: "weightUnit",
      options: [
        { translationPath: "settings.lb", value: "lb" },
        { translationPath: "settings.kg", value: "kg" },
      ],
    },
  ];
  WHO5_REPORT_STRUCTURE: ReportStructure[] = [
    {
      sectionId: "firstPage",
      sectionText: "settings.first_page",
      items: [
        {
          text: "settings.display_page_numbers",
          control: "reportSettings.firstPage.displayPageNumber",
        },
        {
          text: "settings.remove_sign",
          control: "reportSettings.firstPage.removeSignature",
        },
        {
          text: "settings.remove_tester",
          control: "reportSettings.firstPage.removeTester",
        },
        {
          text: "settings.include_header",
          control: "reportSettings.firstPage.reportHeader",
          input: {
            text: "settings.blank_header",
            controlName: "headerSpace", // Can't use formControl path in Html...
            unit: "settings.mm_unit",
            errorText: "settings.header_footer_error",
          },
        },
        {
          text: "settings.include_footer",
          control: "reportSettings.firstPage.reportFooter",
          input: {
            text: "settings.blank_footer",
            controlName: "footerSpace", // Can't use formControl path in Html...
            unit: "settings.mm_unit",
            errorText: "settings.header_footer_error",
          },
        },
        {
          text: "settings.report_email",
          control: "reportSettings.firstPage.isEditEmail",
          input: {
            text: [
              { title: "settings.remove", value: "remove" },
              { title: "settings.change", value: "change" },
            ],
            controlName: "reportSettings.firstPage.editEmail",
            controlEmailName: "reportSettings.firstPage.reportEmail",
            errorText: "auth.email_alert",
          },
        },
      ],
    },
  ];
  WHO6_REPORT_STRUCTURE: ReportStructure[] = [
    {
      sectionId: "firstPage",
      sectionText: "settings.first_page",
      items: [
        {
          text: "settings.display_page_numbers",
          control: "reportSettings.firstPage.displayPageNumber",
        },

        {
          text: "settings.remove_sign",
          control: "reportSettings.firstPage.removeSignature",
        },
        {
          text: "settings.remove_tester",
          control: "reportSettings.firstPage.removeTester",
        },
        {
          text: "settings.include_header",
          control: "reportSettings.firstPage.reportHeader",
          input: {
            text: "settings.blank_header",
            controlName: "headerSpace", // Can't use formControl path in Html...
            unit: "settings.mm_unit",
            errorText: "settings.header_footer_error",
          },
        },
        {
          text: "settings.include_footer",
          control: "reportSettings.firstPage.reportFooter",
          input: {
            text: "settings.blank_footer",
            controlName: "footerSpace", // Can't use formControl path in Html...
            unit: "settings.mm_unit",
            errorText: "settings.header_footer_error",
          },
        },
        {
          text: "settings.report_email",
          control: "reportSettings.firstPage.isEditEmail",
          input: {
            text: [
              { title: "settings.remove", value: "remove" },
              { title: "settings.change", value: "change" },
            ],
            controlName: "reportSettings.firstPage.editEmail",
            controlEmailName: "reportSettings.firstPage.reportEmail",
            errorText: "auth.email_alert",
          },
        },
      ],
    },
    {
      sectionId: "secondPage",
      sectionText: "settings.second_page",
      items: [
        {
          text: "settings.display_page_numbers",
          control: "reportSettings.secondPage.displayPageNumber",
        },
        {
          text: "settings.remove_sign",
          control: "reportSettings.secondPage.removeSignature",
        },
        {
          text: "settings.remove_tester",
          control: "reportSettings.secondPage.removeTester",
        },
        {
          text: "settings.include_header_second_page",
          control: "reportSettings.secondPage.reportHeader",
          input: {
            text: "settings.blank_header",
            controlName: "headerSpace", // Can't use formControl path in Html...
            unit: "settings.mm_unit",
            errorText: "settings.header_footer_error",
          },
        },
        {
          text: "settings.include_footer",
          control: "reportSettings.secondPage.reportFooter",
          input: {
            text: "settings.blank_footer",
            controlName: "footerSpace", // Can't use formControl path in Html...
            unit: "settings.mm_unit",
            errorText: "settings.header_footer_error",
          },
        },
      ],
    },
  ];
  TEST_REPORT: { header: string; pages: ReportStructure[] };

  STANDARD_REPORT: StandardPageReportStructure[] = [
    {
      text: "settings.display_page_numbers",
      control: "reportSettings.standardPage.displayPageNumber",
    },
    {
      text: "settings.include_header",
      control: "reportSettings.standardPage.reportHeader",
      input: {
        text: "settings.blank_header",
        controlName: "reportSettings.standardPage.headerSpace", // Can't use formControl path in Html...
        unit: "settings.mm_unit",
        errorText: "settings.standard_page_header_footer_error",
      },
    },
    {
      text: "settings.include_footer",
      control: "reportSettings.standardPage.reportFooter",
      input: {
        text: "settings.blank_footer",
        controlName: "reportSettings.standardPage.footerSpace", // Can't use formControl path in Html...
        unit: "settings.mm_unit",
        errorText: "settings.standard_page_header_footer_error",
      },
    },
    {
      text: "settings.report_email",
      control: "reportSettings.standardPage.isEditEmail",
      input: {
        text: [
          { title: "settings.remove", value: "remove" },
          { title: "settings.change", value: "change" },
        ],
        controlName: "reportSettings.standardPage.editEmail",
        controlEmailName: "reportSettings.standardPage.reportEmail",
        errorText: "auth.email_alert",
      },
    },
  ];

  //Flexiple Report and remove the test href value in future
  flexibleReport = [
    {
      step: "Step 1",
      translationPath: "settings.htmlReportTemplate",
      buttonName: "manual.instruction_btn",
    },
    {
      step: "Step 2",
      translationPath: "settings.upload_html_file",
      buttonName: "settings.choose_file",
      options: ["select", "option1", "option2", "option3"],
    },
    { step: "Step 3", translationPath: "settings.save_changes" },
    { step: "Step 4", translationPath: "settings.preview" },
  ];

  // prettier-ignore
  additionalTexts = [
    { text: 'settings.none', control: 'reportSettings.secondPage.additionalText', value: additionalText.none },
    { text: 'settings.semen_analysis_result', control: 'reportSettings.secondPage.additionalText', value: additionalText.semenAnalysis },
    { text: 'settings.SMI_parameter', control: 'reportSettings.secondPage.additionalText', value: additionalText.SMI }
  ]

  // Modal related variables...
  modalTitle: string;
  modalBody: string;
  modalButtons: {
    name: string;
    color: string;
    clickFn: any;
    loading?: boolean;
  }[];

  @ViewChild("facilityHtml") facilityHtmlInput: ElementRef;

  get disableSave() {
    if (!this.form.valid) return true;
    return !this.globalService.hasUnsavedChanges;
  }

  get isNotDefaultValue() {
    if (this.testSettingValues && this.form) {
      return this.checkForValueChanges(
        this.defaultTestSettings,
        this.form.getRawValue()
      );
    }
    return false;
  }

  constructor(
    private renderer: Renderer2,
    private httpService: HttpService,
    private globalService: GlobalService,
    private reportService: ReportsService,
    private sanitizer: DomSanitizer,
    private _translate: TranslateService,
    private _auth: AuthService,
    private httpClient: HttpClient,
    private cdr: ChangeDetectorRef,
    private downloads: DowloadService,
    private eRef: ElementRef,
  ) {
    this.currentLang = this._translate.currentLang;

    //check internet connection for save button disable options
    this.isInternetCheck = globalService.isInternet.subscribe(res => {
      this.isInternet = res;
    });
  }

  //Loading navigation
  bindClickLinks() {
    clearInterval(this.interval);
    clearInterval(this.timerId);

    setTimeout(() => {
      const previewLinks = this.eRef.nativeElement.querySelectorAll(".link-to-preview");
      console.log(previewLinks);
      for (let i = 0; i < previewLinks.length; i++) {
        previewLinks[i].addEventListener(
          "click",
          this.navigateToPreview.bind(this),
        );
        // Check condition if html file is seleceted or not
        if (this.form.get('reportSettings.selectedFecitlityHtmlFile').value === "select") {
          previewLinks[i].style.cursor = 'none'
          previewLinks[i].style.pointerEvents = 'none'
          previewLinks[i].style.textDecoration = 'none'
          previewLinks[i].style.setProperty('color', '#c6c6c6', 'important');
        } else {
          previewLinks[i].style.cursor = 'pointer'
          previewLinks[i].style.pointerEvents = 'auto'
          previewLinks[i].style.textDecoration = 'underline'
          previewLinks[i].style.setProperty('color', '#0022FF', 'important');
        }
      }

      const htmlReportLinks = this.eRef.nativeElement.querySelectorAll(".link-to-html-report");
      console.log(htmlReportLinks);
      for (let i = 0; i < htmlReportLinks.length; i++) {
        htmlReportLinks[i].addEventListener(
          "click",
          this.navigateToReport.bind(this),
        );
      }
    }, 100);
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Update formGroup values on testSettings value changes...
    if (changes["facilityData"] && changes["facilityData"].currentValue) {
      this.testSettingValues = this.facilityData.testSettings;

      //user email set to DefaultTestsetting report email
      this.defaultTestSettings.reportSettings.firstPage.reportEmail = this.facilityData.email;
      this.defaultTestSettings.reportSettings.standardPage.reportEmail = this.facilityData.email;

      if (this.testSettingValues.who === MorphCriteria.MORPH_WHO5) {
        this.TEST_REPORT = {
          header: "settings.who5_test_report",
          pages: this.WHO5_REPORT_STRUCTURE,
        };
      }
      if (this.testSettingValues.who === MorphCriteria.MORPH_WHO6) {
        this.TEST_REPORT = {
          header: "settings.who6_test_report",
          pages: this.WHO6_REPORT_STRUCTURE,
        };
      }

      this.createForm(this.testSettingValues.who);

      this.setFormValues(this.testSettingValues);
    }
  }

  ngOnInit(): void {
    this.languageSubscription = this._translate.onLangChange.subscribe(
      (e: LangChangeEvent) => {
        this.currentLang = e.lang;
        this.bindClickLinks();
      }
    );

    //get html array list for choose dropdown below
    this.getHtmlArrayList();
  }

  createForm(who: number) {

    // Update default settings reset for US customers
    // if (this.facilityData.systemCountry === 'United States of America (the)') {
    //   this.defaultTestSettings.debrisAssessment = true;
    //   this.defaultTestSettings.morphUpperLimit = 10;
    //   this.defaultTestSettings.reportSettings.reportType = 1;
    // } else {
    //   this.defaultTestSettings.debrisAssessment = false;
    //   this.defaultTestSettings.morphUpperLimit = 20;
    //   this.defaultTestSettings.reportSettings.reportType = 2;
    // }

    //Hide Settings for Concentration Standard for US customers
    if (this.facilityData.contentLimitation.testSettingsAccess?.concStandard) {
      this.hideConcStandard = this.facilityData.contentLimitation.testSettingsAccess.concStandard;
    }


    const controls = {
      concentrationStd: new FormControl("", Validators.required),
      debrisAssessment: new FormControl(
        this.defaultTestSettings.debrisAssessment,
        Validators.required
      ),
      testOptional1: new FormControl(""),
      testOptional2: new FormControl(""),
      patientAge: new FormControl("", Validators.required),
      heightUnit: new FormControl("", Validators.required),
      weightUnit: new FormControl("", Validators.required),
      morphUpperLimit: new FormControl(
        this.defaultTestSettings.morphUpperLimit ?? 20,
        [Validators.required, this.morphValidator]
      ),
      appearance: new FormControl(
        this.defaultTestSettings.appearance ?? 1,
        Validators.required
      ),
    };

    // Construct the report settings form group...
    const reportSettingsObj = {
      reportType: new FormControl(
        this.defaultTestSettings.reportSettings.reportType ??
        ReportType.GRAPH_REPORT,
        Validators.required
      ),
      // onePageDisplayPageNumber: new FormControl(
      //   defaultTestSettings.reportSettings.onePageDisplayPageNumber ?? true,
      //   Validators.required
      // ),

      //Report Settings for flexible Report...
      selectedFecitlityHtmlFile: new FormControl(
        this.defaultTestSettings.reportSettings.selectedFecitlityHtmlFile ??
        "select",
        Validators.required
      ),
    };

    //Report Settings for standard Report...
    reportSettingsObj["standardPage"] = new FormGroup({
      displayPageNumber: new FormControl(true, Validators.required),
      reportHeader: new FormControl(false, Validators.required),
      reportFooter: new FormControl(false, Validators.required),
      // prettier-ignore
      headerSpace: new FormControl('', this.outRangeValidator('standardPage', 50)),
      // prettier-ignore
      footerSpace: new FormControl('', this.outRangeValidator('standardPage', 50)),
      //for edit email
      reportEmail: new FormControl("", [Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
      editEmail: new FormControl("remove", Validators.required),
      isEditEmail: new FormControl(true, Validators.required),
    });

    // Report settings for WHO 5...
    if (who === MorphCriteria.MORPH_WHO5) {
      reportSettingsObj["firstPage"] = new FormGroup({
        displayPageNumber: new FormControl(false, Validators.required),
        removeSignature: new FormControl(false, Validators.required),
        removeTester: new FormControl(false, Validators.required),
        reportHeader: new FormControl(false, Validators.required),
        reportFooter: new FormControl(false, Validators.required),
        // prettier-ignore
        headerSpace: new FormControl('', this.outRangeValidator('firstPage', 80)),
        // prettier-ignore
        footerSpace: new FormControl('', this.outRangeValidator('firstPage', 80)),
        //for edit email
        reportEmail: new FormControl('', [Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
        editEmail: new FormControl("remove", Validators.required),
        isEditEmail: new FormControl(true, Validators.required),
      });
    }
    // Report settings for WHO 6...
    if (who === MorphCriteria.MORPH_WHO6) {
      reportSettingsObj["firstPage"] = new FormGroup({
        displayPageNumber: new FormControl(false, Validators.required),
        removeSignature: new FormControl(false, Validators.required),
        removeTester: new FormControl(false, Validators.required),
        reportHeader: new FormControl(false, Validators.required),
        reportFooter: new FormControl(false, Validators.required),
        // prettier-ignore
        headerSpace: new FormControl('', this.outRangeValidator('firstPage', 80)),
        // prettier-ignore
        footerSpace: new FormControl('', this.outRangeValidator('firstPage', 80)),
        //for edit email
        reportEmail: new FormControl('', [Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
        editEmail: new FormControl("remove", Validators.required),
        isEditEmail: new FormControl(true, Validators.required),
      });
      reportSettingsObj["secondPage"] = new FormGroup({
        displayPageNumber: new FormControl(false, Validators.required),
        removeSignature: new FormControl(false, Validators.required),
        removeTester: new FormControl(false, Validators.required),
        reportHeader: new FormControl(false, Validators.required),
        reportFooter: new FormControl(false, Validators.required),
        // prettier-ignore
        headerSpace: new FormControl('', this.outRangeValidator('secondPage', 80)),
        // prettier-ignore`
        footerSpace: new FormControl('', this.outRangeValidator("secondPage", 80)),
        additionalText: new FormControl(0, Validators.required),
      });
    }
    controls["reportSettings"] = new FormGroup(reportSettingsObj);

    if (this.facilityData.contentLimitation.hideParameters === true &&
      this.facilityData.resultsRestriction.includes("spermMotilityIndex")
    ) {
      this.hideSMIOption = true;
    }


    this.form = new FormGroup(controls);

    this.cdr.detectChanges();
    // Disable morphUpperLimit if it's who 5...
    if (who === MorphCriteria.MORPH_WHO5) {
      this.form.get("morphUpperLimit").disable();
    } else {
      this.form.get("morphUpperLimit").enable();
    }

    // Check for the forms value changed or not by comparing previous and current values...
    this.formValueSubscription = this.form.valueChanges.subscribe((result) => {

      this.globalService.hasUnsavedChanges = false;
      if (this.testSettingValues) {

        this.globalService.hasUnsavedChanges = this.checkForValueChanges(
          this.testSettingValues,
          result
        );
      }
    });

  }

  // <------------------------------- Form Validators ---------------------------------->

  // Custom validator for Reactive form to check header, footer values in range...
  outRangeValidator(page: string, boundRange: number): ValidatorFn {
    return (_control: AbstractControl): ValidationErrors | null => {
      if (
        this.form &&
        this.form.get(`reportSettings.${page}.headerSpace`) &&
        this.form.get(`reportSettings.${page}.footerSpace`)
      ) {
        // If either reportHeader or reportFooter is not selecetd than the other value
        // can go upto maxRange. So, using value as 0 when it's not selected...
        const headerValue =
          this.form.get(`reportSettings.${page}.reportHeader`).value === false
            ? 0
            : +this.form.get(`reportSettings.${page}.headerSpace`).value;
        const footerValue =
          this.form.get(`reportSettings.${page}.reportFooter`).value === false
            ? 0
            : +this.form.get(`reportSettings.${page}.footerSpace`).value;

        if (headerValue + footerValue > boundRange) {
          return { outOfRange: true };
        } else {
          // Remove the existing errors from the header, footer controls...
          this.form.get(`reportSettings.${page}.headerSpace`).setErrors(null);
          this.form.get(`reportSettings.${page}.footerSpace`).setErrors(null);
          return null;
        }
      }
      return null;
    };
  }

  morphValidator(control: AbstractControl): ValidationErrors | null {
    if (+control.value < 10 || +control.value > 30) {
      return { errorMorph: true };
    }
    return null;
  }

  // <---------------------------------------------------------------------------------->

  // Compare the current value with the initial value to check if any data has changed...
  // **IMPORTANT: Form values data structure can be different from initial value data structure**
  checkForValueChanges(initial: TestSettings, formValue: any) {
    let result = false;

    for (const key in formValue) {
      if (!result) {
        // For handling the report settings value changes...
        if (key === "reportSettings") {
          for (const reportItem in formValue["reportSettings"]) {

            // For the firstPage and secondPage items in the report settings...
            if (
              reportItem === "firstPage" ||
              reportItem === "secondPage" ||
              reportItem === "standardPage"
            ) {
              for (const item in formValue["reportSettings"][reportItem]) {
                // Convert header and footer space to number for comparition...
                const compareValue =
                  item === "footerSpace" || item === "headerSpace"
                    ? +formValue["reportSettings"][reportItem][item]
                    : formValue["reportSettings"][reportItem][item];

                if (initial["reportSettings"]) {
                  if (
                    initial["reportSettings"][reportItem][item] !== undefined &&
                    initial["reportSettings"][reportItem][item] !== compareValue
                  ) {
                    // If data is defined in database and local data became changed from the
                    // database data...
                    result = true;
                  } else if (
                    initial["reportSettings"][reportItem][item] === undefined &&
                    (compareValue || compareValue === 0)
                  ) {
                    // if data isn't defined in the database and local data became truish...
                    result = true;
                  }
                }
              }
            } else {
              // For the other items in report settings...
              if (initial.reportSettings[reportItem] !== undefined) {
                // If the database value isn't undefined than check for value changes...
                if (
                  initial.reportSettings[reportItem] !==
                  formValue["reportSettings"][reportItem]
                ) {
                  result = true;
                }
              } else {
                // If the database value is undefined than check if it's changed from the
                // default value or not...
                if (
                  this.defaultTestSettings.reportSettings.hasOwnProperty(reportItem)
                ) {
                  if (
                    this.defaultTestSettings.reportSettings[reportItem] !==
                    formValue["reportSettings"][reportItem]
                  )
                    result = true;
                }
              }
            }
          }
        } else {
          // Convert the input value to number...
          const compareValue =
            key === "morphUpperLimit" ? +formValue[key] : formValue[key];
          // Other values look for changes (If it exist's on the cloud)...
          if (initial[key] !== undefined) {
            /* If field exist on database */
            if (initial[key] !== compareValue) {
              result = true;
            }
          } else {
            // If key doesn't exist on the cloud than first check the default settings
            // If it exist in default setting and the values is changed comparing the
            // default value or if the key doesn't exist in default value and the value becomes
            // truish only than enable the save button...
            if (this.defaultTestSettings.hasOwnProperty(key)) {
              if (this.defaultTestSettings[key] !== compareValue) result = true;
            } else if (formValue[key]) result = true;
          }
        }
      }
    }
    return result;
  }

  setFormValues(value: TestSettings) {
    if (this.form) {
      const reportControls = (this.form.controls["reportSettings"] as FormGroup)
        .controls;
      for (const control in this.form.controls) {
        // Pre-populating report settings fields...
        if (control === "reportSettings") {
          for (const reportControlItem in reportControls) {

            // If the controls are firstPage and secondPage only(because they will have inner items)...
            if (
              reportControlItem === "firstPage" ||
              reportControlItem === "secondPage" ||
              reportControlItem === "standardPage"

            ) {
              for (const item in (
                reportControls[reportControlItem] as FormGroup
              ).controls) {
                // For disabling the header space input field and set the value(default/existing)...
                if (item === "reportHeader") {
                  let headerSpaceValue =
                    value.reportSettings[reportControlItem].headerSpace;

                  if (
                    value.reportSettings[reportControlItem]["reportHeader"] ===
                    false
                  ) {
                    this.form
                      .get(`${control}.${reportControlItem}.headerSpace`)
                      .disable();
                    headerSpaceValue =
                      this.defaultTestSettings.reportSettings[reportControlItem]
                        .headerSpace;
                  }

                  if (headerSpaceValue !== undefined) {
                    this.form
                      .get(`${control}.${reportControlItem}.headerSpace`)
                      .setValue(headerSpaceValue);
                  }
                }

                // For disabling the footer space input field and set the value(default/existing)...
                if (item === "reportFooter") {
                  let footerSpaceValue =
                    value.reportSettings[reportControlItem].footerSpace;

                  if (
                    value.reportSettings[reportControlItem]["reportFooter"] ===
                    false
                  ) {
                    this.form
                      .get(`${control}.${reportControlItem}.footerSpace`)
                      .disable();
                    footerSpaceValue =
                      this.defaultTestSettings.reportSettings[reportControlItem]
                        .footerSpace;
                  }

                  if (footerSpaceValue !== undefined) {
                    this.form
                      .get(`${control}.${reportControlItem}.footerSpace`)
                      .setValue(footerSpaceValue);
                  }
                }

                // Pre-populating the otherfields of the report settings...
                if (
                  value.reportSettings[reportControlItem][item] !== undefined &&
                  item !== "headerSpace" &&
                  item !== "footerSpace"
                ) {
                  this.form
                    .get(`${control}.${reportControlItem}.${item}`)
                    .setValue(value.reportSettings[reportControlItem][item]);
                }
              }
            } else {
              console.log(reportControlItem, value);
              // For the other items in report settings rather than firstPage and secondPage items...
              if (value.reportSettings[reportControlItem] !== undefined) {
                this.form
                  .get(`${control}.${reportControlItem}`)
                  .setValue(value.reportSettings[reportControlItem]);
              }
            }
          }
        } else {
          // Pre-populating the other fields...
          if (value[control] !== undefined) {
            this.form.get(control).setValue(value[control]);
          }
        }
      }
    }
    //update formGroup Values after then the link will be update
    this.bindClickLinks();
    console.log(this.form.value);
  }

  //edit email and header, footer update
  changeValueUpdate(event: Event, controlName: string) {
    // console.log(event, controlName);

    const value = (event.target as HTMLInputElement).value;
    // console.log(value);

    if (controlName === 'reportSettings.firstPage.reportEmail' || 'reportSettings.standardPage.reportEmail') {
      this.form.get(controlName).setValue(value);
    } else {
      this.form.get(controlName).setValue(parseInt(value));
    }
  }

  //active Disable for ratiobutton
  activedisable(control: string) {


    if (this.form.get(control).value === "remove") {
      return true;
    }
    if (this.form.get(control).value === "") {
      return true;
    }

    return false;
  }

  changeCheckMark(control: string, value: string | number | boolean | Event) {

    console.log("control", control, value);

    // Control Name can be control path also...
    const controlArr = control.split(".");
    const controlName = controlArr[controlArr.length - 1];

    if (typeof value === 'number' && value === 3) {
      this.bindClickLinks();
    }

    // Disable input field on false for headerSpace and footerSpace input...
    if (controlName === "reportHeader" || controlName === "reportFooter") {
      // For Report Header and Footer path will be 'reportSettings/firstPage/headerSpace'...
      const page = controlArr[1];
      const controlToUpdate =
        controlName === "reportHeader" ? "headerSpace" : "footerSpace";
      const controlPathToUpdate = `reportSettings.${page}.${controlToUpdate}`;

      setTimeout(() => {

        if (!this.form.get(control).value) {
          this.form.get(controlPathToUpdate).disable();
          // Reset back the header and footer space values to the default values also...
          if (
            this.defaultTestSettings.reportSettings[page][controlToUpdate] !==
            undefined
          ) {
            // First set the headerSpace or footerSpace input field to the default value...
            this.form
              .get(controlPathToUpdate)
              .setValue(
                this.defaultTestSettings.reportSettings[page][controlToUpdate]
              );

            // Than re-run the validation for the headerSpace and footerSpace input fields...
            this.form
              .get(`reportSettings.${page}.headerSpace`)
              .updateValueAndValidity();
            this.form
              .get(`reportSettings.${page}.footerSpace`)
              .updateValueAndValidity();
          }
        } else {
          this.form.get(controlPathToUpdate).enable();
        }
      }, 0);
    }

    // isEditEmail is value set false when the reportSettings.standardPage.reportHeader and  reportSettings.firstPage.reportHeader is true
    if ((control === "reportSettings.standardPage.reportHeader" || control === "reportSettings.firstPage.reportHeader") && value == true) {
      //update isEditEmail value false base on reportHeader
      this.form.get(`${controlArr[0]}.${controlArr[1]}.isEditEmail`).setValue(false);
      //set default email
      this.form.get(`${controlArr[0]}.${controlArr[1]}.reportEmail`).setValue(this.facilityData.email);
      this.form.get(`${controlArr[0]}.${controlArr[1]}.editEmail`).setValue(this.facilityData.testSettings[controlArr[0]][controlArr[1]]['editEmail']);
    }

    // Update the control value...
    this.form.get(control).setValue(value);

    //if the editEmail is unChecked or enable remove
    //the value should be defauld email
    if ((control.includes('isEditEmail') || control.includes('editEmail')) && (value === false || value === 'remove')) {
      //set default email
      this.form.get(`${controlArr[0]}.${controlArr[1]}.reportEmail`).setValue(this.facilityData.email);
    }

    if (control.includes('isEditEmail') && value === false) {
      //set previous editEmail value 
      this.form.get(`${controlArr[0]}.${controlArr[1]}.editEmail`).setValue(this.facilityData.testSettings[controlArr[0]][controlArr[1]]['editEmail']);
    }


  }

  activeCheckMark(controlName: string, value?: string | number | boolean) {

    //if reportSettings.secondPage.additionalText 
    if (controlName === 'reportSettings.secondPage.additionalText') {
      return this.form.get(controlName).value === value;
    }

    // Activate checkmark for checkboxes and inputFields...
    if (!value) {
      return this.form.get(controlName).value;
    }

    // Active checkmark for radio buttons...
    if (this.form.get(controlName).value === value) {
      return value;
    }

    return false;
  }

  showHeaderInputText(page: string, controlName: string) {
    return !(page === "secondPage" && controlName === "headerSpace");
  }

  onlyNumber(event: KeyboardEvent) {
    const key = event.key;
    if (
      key === "Backspace" ||
      key === "ArrowLeft" ||
      key === "ArrowRight" ||
      key === "Tab"
    )
      return true;
    if (key === "Space" || key === "Enter") return false;
    return /[0-9]/.test(key);
  }

  onResetToDefault() {
    this.modalTitle = this._translate.instant("settings.default_header");
    // prettier-ignore
    this.modalBody = `<i class="material-icons" style="color: red; font-size: 36px; height: 36px; margin-bottom: 15px">error_outline</i>
                      <h6 style="text-align: center"><strong>${this._translate.instant('settings.default_header_body')}</strong></h6>`;
    this.modalButtons = [
      {
        name: this._translate.instant("general.restore"),
        color: "btn-primary",
        clickFn: () => this.resetValueToDefault(),
        loading: false,
      },
      {
        name: this._translate.instant("general.cancel"),
        color: "btn-primary",
        clickFn: this.closeCommonModal,
        loading: false,
      },
    ];
    $("#testSettingsCommonModal").modal("show");
  }

  closeCommonModal() {
    $("#testSettingsCommonModal").modal("hide");
  }

  async resetValueToDefault() {
    if (this.modalButtons[0].loading) return;

    let isDatabaseValueChanged = false;
    const parameters = Object.keys(this.form.controls);
    const reportSettingsControls = (
      this.form.controls["reportSettings"] as FormGroup
    ).controls;

    // Compare the default settings and the setting stored in database is same or
    // not, if same than don't need to save in database again...
    for (const key of parameters) {
      if (!isDatabaseValueChanged) {
        // Since reportSettings will have nested items...
        if (key === "reportSettings") {
          for (const reportItem in reportSettingsControls) {
            // For firstPage and secondPage control in report settings...
            if (
              reportItem === "firstPage" ||
              reportItem === "secondPage" ||
              reportItem === "standardPage"
            ) {
              for (const item in (
                reportSettingsControls[reportItem] as FormGroup
              ).controls) {
                if (
                  this.testSettingValues.reportSettings[reportItem][item] !==
                  this.defaultTestSettings.reportSettings[reportItem][item]
                ) {
                  isDatabaseValueChanged = true;
                }
              }
            } else {
              // For the other controls in report settings...
              if (
                this.testSettingValues.reportSettings[reportItem] !==
                this.defaultTestSettings.reportSettings[reportItem]
              ) {
                isDatabaseValueChanged = true;
              }
            }
          }
        } else if (this.testSettingValues[key] !== this.defaultTestSettings[key]) {
          isDatabaseValueChanged = true;
        }
      }
    }

    // Since defaultTestSettings will have additional values, so filtering out only
    // the required values...
    const value = {};
    for (const key of parameters) {
      if (!value.hasOwnProperty(key)) {
        value[key] = this.defaultTestSettings[key];
      }
    }

    // Update the default values to the database and change the form values accordingly...
    if (isDatabaseValueChanged) {
      this.modalButtons[0].loading = true;
      const newTestSettings = { ...this.testSettingValues, ...value };
      const result: { id: string; data: { testSettings: TestSettings } } =
        await this.httpService.UpdateFacilitySettings({
          testSettings: newTestSettings,
        });
      this.globalService.hasUnsavedChanges = false;
      this.testSettingValues = result.data.testSettings;
      this.modalButtons[0].loading = false;
      this.fireEvent.emit("fetch data");
      this.fireEvent.emit("success:settings.save_success");
    }
    this.closeCommonModal();
    this.setFormValues(value as TestSettings);
  }

  resetValues() {
    // Important to create new form so values entered in the form controls
    // (values that don't exit in database) will also gets removed from the form...
    this.createForm(this.testSettingValues.who);
    this.setFormValues(this.testSettingValues);
  }

  async onSubmit() {
    if (this.submitBtnLoading) return;

    const value = this.form.getRawValue();


    // Convert morphUpperLimit, header and footer space to number...
    value.morphUpperLimit = +value.morphUpperLimit;

    // //standard page
    value.reportSettings.standardPage.headerSpace =
      +value.reportSettings.standardPage.headerSpace;
    value.reportSettings.standardPage.footerSpace =
      +value.reportSettings.standardPage.footerSpace;

    //first page
    value.reportSettings.firstPage.headerSpace =
      +value.reportSettings.firstPage.headerSpace;
    value.reportSettings.firstPage.footerSpace =
      +value.reportSettings.firstPage.footerSpace;

    if (this.testSettingValues.who === MorphCriteria.MORPH_WHO6) {
      //second page
      value.reportSettings.secondPage.headerSpace =
        +value.reportSettings.secondPage.headerSpace;
      value.reportSettings.secondPage.footerSpace =
        +value.reportSettings.secondPage.footerSpace;
    }

    const newTestSettings = { ...this.testSettingValues, ...value };

    this.submitBtnLoading = true;

    // If file is selected, than save the image first...
    if (this.facilityHtmlFile) {
      const htmlUrl = await this.httpService.uploadHTMLFile(
        this.facilityHtmlFile,
        this.fileName
      );
    }

    // Save the data in the database...

    try {
      const result: { id: string; data: { testSettings: TestSettings } } =
        await this.httpService.UpdateFacilitySettings({
          testSettings: newTestSettings,
        });
      this.globalService.hasUnsavedChanges = false;
      this.testSettingValues = result.data.testSettings;
      this.fireEvent.emit("fetch data");
      this.fireEvent.emit("success:settings.save_success");
    } catch (error) {
      // console.log(error);
      this.fireEvent.emit("error:settings.save_fail");
    }
    this.submitBtnLoading = false;

    ////get html array list for choose dropdown below
    this.getHtmlArrayList();
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
    clearInterval(this.timerId);
    this.languageSubscription?.unsubscribe();
    this.formValueSubscription?.unsubscribe();
    this.isInternetCheck.unsubscribe();
  }

  //remove email
  restInputField(control: string) {
    //make email adress feild is empty
    this.form.get(control).setValue("");
  }

  navigateToReport() {

    const whoCriteria = this.testSettingValues.who === MorphCriteria.MORPH_WHO5 ? 'who5' : 'who6';

    this.httpService
      .getFileUrl(`customreport/templates/${whoCriteria}/SQA-iO_flexible_report_template.html`)
      .then((res: any) => {
        // window.open(url, "_blank");
        this.downloads.download(res).subscribe((blob) => {
          const a = document.createElement("a");
          const objectUrl = URL.createObjectURL(blob);
          a.href = objectUrl;
          a.download = `${whoCriteria}SQA-iO_flexible_report_template.html`;
          a.click();
          URL.revokeObjectURL(objectUrl);
        });
      })
  }

  //downloadPdf
  async downloadPdf() {
    let fileName: string;

    if (this.testSettingValues.who === MorphCriteria.MORPH_WHO5) {
      fileName = 'Flex_Report_User_Guide_WHO5.pdf';

    } else if (this.testSettingValues.who === MorphCriteria.MORPH_WHO6) {
      fileName = 'Flex_Report_User_Guide_WHO6.pdf';
    }

    let url: string = undefined;

    try {
      url = await this.httpService.getFileUrl(
        `Manuals/${this._translate.currentLang}/${fileName}`
      );

    } catch (error) {
      try {
        url = await this.httpService.getFileUrl(`Manuals/${fileName}`);
      } catch (error) {
        console.log(error);
      }
    }

    if (url !== undefined) {
      this.downloads.download(url).subscribe((blob) => {
        const a = document.createElement("a");
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = fileName;
        a.click();
        URL.revokeObjectURL(objectUrl);
      });
    }

  }
  //click link tag
  navigateToPreview() {
    let fileName: string = this.form.get('reportSettings.selectedFecitlityHtmlFile').value;
    let facilityID = this.httpService.currentFacilityId;
    this.reportService.getFlexibleReport('preview', fileName, false, facilityID).then(() => {
      this.reportService.flexReportPreviewContent$.subscribe(content => {
        this.previewContent = this.sanitizer.bypassSecurityTrustResourceUrl(content + '#toolbar=0');
        this.showPreview = true;
      })
    });
  }

  //delete dropdown list
  deleteDropdownList(fileName: string) {
    var checkFileName = this.form.get('reportSettings.selectedFecitlityHtmlFile').value;

    //check deleteDropdownList === selectedFecitlityHtmlFile

    if (fileName === checkFileName) {
      this.form.get('reportSettings.selectedFecitlityHtmlFile').setValue("select");
      this.facilityHtmlFile = undefined;
      // this.httpService.UpdateFacilitySettings({
      //   testSettings: { reportSettings: { selectedFecitlityHtmlFile: "select" } }
      // })
    }

    this.httpService
      .deleteFileFromStorage(
        `customreport/${this._auth.currentUser.uid}/${fileName}`
      )
      .then((res: any) => {
        // console.log(res);
        this.getHtmlArrayList();
      });
    this.bindClickLinks();
    console.log(this.form.value);
  }

  //file picker
  resetFilePicker() {
    if (!this.facilityHtmlFile) return;

    this.facilityHtmlFile = undefined;
    this.form.get('reportSettings.selectedFecitlityHtmlFile').setValue("select");
    this.fileError = { correctFormat: true, correctSize: true };
    this.renderer.removeClass(
      this.facilityHtmlInput.nativeElement,
      "valid-image"
    );
    this.renderer.removeClass(
      this.facilityHtmlInput.nativeElement,
      "invalid-image"
    );
    this.bindClickLinks();
    // this.setHasUnsavedChanges();
  }

  onSelectFile(event: Event) {
    const htmlFile = (event.target as HTMLInputElement).files[0];
    this.fileName = htmlFile.name;

    //update selectedFecitlityHtmlFile
    this.form
      .get('reportSettings.selectedFecitlityHtmlFile')
      .setValue(htmlFile.name);

    if (htmlFile) {
      // Store the file...
      this.facilityHtmlFile = htmlFile;

      // Check for correct file format...
      if (this.facilityHtmlFile.type !== "text/html")
        this.fileError.correctFormat = false;
      // Check for correct image size...
      console.log(this.facilityHtmlFile.size);
      if (this.facilityHtmlFile.size / 1024 > 120)
        this.fileError.correctSize = false;

      this.setHasUnsavedChanges();
    }
  }

  // Keep track of the input field, image picker field and changable label(optional1, optional2) field...
  setHasUnsavedChanges() {
    if (
      this.formInputChanged ||
      this.facilityHtmlFile
      // this.optionalLabelChanged
    ) {
      this.globalService.hasUnsavedChanges = true;
    } else {
      this.globalService.hasUnsavedChanges = false;
    }
  }

  deleteFacilityLogo() {
    this.httpService
      .DeleteLogo()
      .then((_) => {
        this.facilityData.htmlURL = "";
        this.fireEvent.emit("success:settings.remove_succ_logo");
        this.closeModal();
      })
      .catch((error) => {
        console.log(error);
        this.fireEvent.emit("error:settings.remove_fail_img");
      });
  }

  onClickDeleteFile(fileName: string) {
    this.deleteDropdownList(fileName);
    this.facilityHtmlFile = undefined;

  }

  closeModal() {
    $("#facilityCommonModal").modal("hide");
  }

  getHtmlArrayList() {
    //get html array list for choose dropdown below
    this.httpService
      .getHTMLFilesList(this._auth.currentUser.uid)
      .then((res: any) => {
        this.fileList = res;
      });
  }

  selectValueChange(event: any) {
    //update selectedFecitlityHtmlFile value
    this.form.get("reportSettings.selectedFecitlityHtmlFile").setValue(event.value);
  }

  //keydown function use for block unwanted keys
  keydown(event): void {
    console.log(event.key);
    if (event.key === " ") {
      event.preventDefault();
      return;

    }
  }

  hidePreviewContent() {
    this.showPreview = false;
  }
}
