import {
  Component,
  OnInit,
  ViewChild,
  ViewChildren,
  QueryList,
  ElementRef,
  Renderer2,
  OnDestroy,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, Subject, Observable } from 'rxjs';
import { defaultTestSettings, defaultTestSettingsUS, SQACustomer, TestSettings } from 'src/app/globals/globals';
import { HttpService } from 'src/app/services/http.service';
import { GlobalService } from 'src/app/services/global.service';
import { AuthService } from 'src/app/core/auth.service';
import { FacilityProfileComponent } from './facility-profile/facility-profile.component';
import { RefValueComponent } from './ref-value/ref-value.component';
import { TestSettingsComponent } from './test-settings/test-settings.component';
import { SystemSettingsComponent } from './system-settings/system-settings.component';
import { UserProfileComponent } from './user-profile/user-profile.component';

declare const $: any;

export enum Permission {
  'ADMIN',
  'EDITOR',
  'USER',
}

// prettier-ignore
type Tabs = 'test_settings' | 'ref_values' | 'facility_settings' | 'user_profile' | 'user_management' | 'system_settings';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.sass'],
})
export class SettingsComponent implements OnInit, AfterViewInit, OnDestroy {
  facilityData: SQACustomer;
  defaultTestSettings: TestSettings;
  userPermission: Permission;
  fragmentValue: Tabs;
  currentTab: Tabs;
  tempTab: Tabs;
  navigateAwaySelection$ = new Subject<boolean>();
  userSubscription: Subscription;
  headerLogoutClickSubscription: Subscription;
  exitPopupBtnClickSubscription: Subscription;
  facilityDataSubscription: Subscription;

  // Current Tab related variables...
  currentTabDataInvalid: boolean;
  currentTabSave: () => Promise<void>;
  currentTabReset: () => void;

  // Modal related variables...
  modalTitle: string;
  modalBody: string;
  modalButtons: {
    name: string;
    color: string;
    clickFn: any;
    loading?: boolean;
  }[];
  closeModalFn: () => void;

  @ViewChild(TestSettingsComponent)
  testSettingsComponent: TestSettingsComponent;
  @ViewChild(RefValueComponent) refValueComponent: RefValueComponent;
  @ViewChild(FacilityProfileComponent)
  facilityProfileComponent: FacilityProfileComponent;
  @ViewChild(UserProfileComponent) userProfileComponent: UserProfileComponent;
  @ViewChild(SystemSettingsComponent)
  systemSettingsComponent: SystemSettingsComponent;

  @ViewChildren('tabContent', { read: ElementRef })
  tabContents: QueryList<ElementRef>;

  get translateTextStyle() {
    return (this.globalService.unTranslate as string[]).includes(
      this._translate.currentLang
    )
      ? 'lowercase'
      : 'uppercase';
  }

  constructor(
    private httpService: HttpService,
    private globalService: GlobalService,
    private authService: AuthService,
    private renderer: Renderer2,
    private _translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.facilityDataSubscription = httpService.facilityUpdates.subscribe(res => {
      console.log("On Data change value changes", res);
      this.facilityData = res
      this.defaultTestSettings = res.systemCountry === 'United States of America (the)' ? defaultTestSettingsUS : defaultTestSettings;
    })
  }

  ngOnInit(): void {
    this.fetchSettingsData();

    this.setUpGlobalPopup();

    this.route.fragment.subscribe((result: Tabs) => {
      this.fragmentValue = result;

      // Open default tab if no fragment is presented...
      if (!this.fragmentValue && this.tabContents) {
        this.userDefaultTab();
      } else if (this.tabContents) {
        this.changeTab(result);
      }
    });
  }

  ngAfterViewInit(): void {
    // Retrieve current user email...
    this.userSubscription = this.authService.user.subscribe((user) => {
      if (user) {
        if (user.isFacilityAdmin) this.userPermission = Permission.ADMIN;
        else if (user.isEditor) this.userPermission = Permission.EDITOR;
        else this.userPermission = Permission.USER;
      }

      this.cdr.detectChanges();

      // For the first time showing tabs manually, since view will not be rendered in
      // OnInit(element will be undefined)...
      if (!this.fragmentValue) {
        // User default tab...
        this.userDefaultTab();
      } else {
        // Else if fragment is passed...
        this.changeTab(this.fragmentValue);
      }
    });
  }

  canDeactivate(): Observable<boolean> | boolean {
    // Don't run any deactivate checks if no current user present(related to force logout)...
    if (Object.keys(this.authService.currentUser).length === 0) return true;

    // if (this.globalService.hasUnsavedChanges || this.globalService.alternateUnsavedForRefValues || this.globalService.alternateUnsavedForRefValues1) {
    if (this.globalService.hasUnsavedChanges) {
      // Setup the current tabs save, reset functions...
      this.setCurrentTabInfo(this.currentTab);

      // Show different popup base on if the form is valid or not...
      if (this.currentTabDataInvalid) {
        this.modalTitle = this._translate.instant(
          'general.incomplete_information'
        );
        // prettier-ignore
        this.modalBody = `<h6 style="margin-top: 20px"><strong>${this._translate.instant('general.incomplete_information_body1')}</strong></h6>
                          <h6><strong>${this._translate.instant('general.incomplete_information_body2')}</strong></h6>`;
        this.modalButtons = [
          {
            name: this._translate.instant(
              'general.incomplete_information_button'
            ),
            color: 'btn-primary',
            clickFn: () => {
              this.close(true, false);
              $('#SettingsCommonModal').modal('hide');
            },
            loading: false,
          },
          {
            name: this._translate.instant('general.back'),
            color: 'btn-primary',
            clickFn: () => {
              this.close(false, false);
              $('#SettingsCommonModal').modal('hide');
            },
            loading: false,
          },
        ];
        this.closeModalFn = () => {
          this.close(false, false);
          $('#SettingsCommonModal').modal('hide');
        };
        $('#SettingsCommonModal').modal('show');
      } else {
        this.globalService.exitPopupData$.next({
          showPopup: true,
          buttons: [
            {
              eventName: 'save',
              displayName: this._translate.instant('general.save'),
              disableBtn: this.currentTabDataInvalid,
            },
            {
              eventName: 'dontSave',
              displayName: this._translate.instant('general.dont_save'),
              disableBtn: false,
            },
            {
              eventName: 'close',
              displayName: this._translate.instant('general.cancel'),
              disableBtn: false,
            },
          ],
        });
      }

      return this.navigateAwaySelection$;
    }
    return true;
  }

  userDefaultTab() {
    // If no fragment is passed than based on the user permission will show a default tab...
    if (
      this.userPermission === Permission.ADMIN ||
      this.userPermission === Permission.EDITOR
    ) {
      this.changeTab('test_settings');
    }
    if (this.userPermission === Permission.USER) {
      this.changeTab('user_profile');
    }
  }

  fetchSettingsData() {
    //get settings data
    this.httpService
      .getSettings()
      .then((result: { id: string; data: SQACustomer }) => {

        const userEmail = result.data.email;

        //this is a temporary solution we have to fix this in future 
        //check standardPage.reportEmail
        if (result.data.testSettings.reportSettings.standardPage.reportEmail !== result.data.testSettings.reportSettings.firstPage.reportEmail
          || result.data.testSettings.reportSettings.standardPage.reportEmail === '') {
          result.data.testSettings.reportSettings.standardPage.reportEmail = userEmail;
        }
        console.log("on data change", result.data);

        // this.facilityData = result.data;
      });
  }

  setUpGlobalPopup() {
    const commonSaveFn = async () => {
      this.globalService.exitSaveLoading$.next(true);
      await this.currentTabSave();
      this.globalService.hasUnsavedChanges = false;
      // this.globalService.alternateUnsavedForRefValues1 = false;
      this.globalService.exitSaveLoading$.next(false);
      this.globalService.exitPopupData$.next({ showPopup: false });
    };

    // Listen for exit Popup btn click events...
    this.exitPopupBtnClickSubscription =
      this.globalService.exitPopupBtnClicked$.subscribe(async (result) => {
        switch (result) {
          case 'save-inner':
            await commonSaveFn();
            this.changeTab(this.tempTab);
            break;
          case 'save':
            await commonSaveFn();
            this.close(true, false);
            break;
          case 'save-logout':
            await commonSaveFn();
            this.close(true, true);
            break;
          case 'dontSave-inner':
            this.currentTabReset();
            this.globalService.exitPopupData$.next({ showPopup: false });
            this.changeTab(this.tempTab);
            break;
          case 'dontSave':
            this.close(true, false);
            break;
          case 'dontSave-logout':
            this.close(true, true);
            break;
          case 'close':
            this.close(false, false);
            break;
          case 'close-inner':
            this.router.navigate(['.'], {
              relativeTo: this.route,
              fragment: this.currentTab,
            });
            this.close(false, false);
            break;
          default:
            this.close(false, false);
        }
      });

    // Listen for header logout button clicked...
    this.headerLogoutClickSubscription =
      this.globalService.headerLogoutClicked$.subscribe((result) => {
        if (result) {
          if (result === 'clicked') {
            // Only logout btn in header emits 'clicked' string(sidebar emits 'sidebar')...
            this.setCurrentTabInfo(this.currentTab);

            // Show different popup base on if the form is valid or not...
            if (this.currentTabDataInvalid) {
              this.modalTitle = this._translate.instant(
                'general.incomplete_information'
              );
              // prettier-ignore
              this.modalBody = `<h6 style="margin-top: 20px"><strong>${this._translate.instant('general.incomplete_information_body1')}</strong></h6>
                          <h6><strong>${this._translate.instant('general.incomplete_information_body2')}</strong></h6>`;
              this.modalButtons = [
                {
                  name: this._translate.instant(
                    'general.incomplete_information_button'
                  ),
                  color: 'btn-primary',
                  clickFn: () => {
                    this.close(true, true);
                    $('#SettingsCommonModal').modal('hide');
                  },
                  loading: false,
                },
                {
                  name: this._translate.instant('general.back'),
                  color: 'btn-primary',
                  clickFn: () => {
                    this.close(false, false);
                    $('#SettingsCommonModal').modal('hide');
                  },
                  loading: false,
                },
              ];
              this.closeModalFn = () => {
                this.close(false, false);
                $('#SettingsCommonModal').modal('hide');
              };
              $('#SettingsCommonModal').modal('show');
            } else {
              this.globalService.exitPopupData$.next({
                showPopup: true,
                accessedFromHeader: true,
                buttons: [
                  {
                    eventName: 'save',
                    displayName: this._translate.instant('general.save'),
                    disableBtn: this.currentTabDataInvalid,
                  },
                  {
                    eventName: 'dontSave',
                    displayName: this._translate.instant('general.dont_save'),
                    disableBtn: false,
                  },
                  {
                    eventName: 'close',
                    displayName: this._translate.instant('general.cancel'),
                    disableBtn: false,
                  },
                ],
              });
            }
          }
        }
      });
  }

  showNotification(
    colorName: null | string,
    text: null | string,
    placementFrom: string,
    placementAlign: string,
    animateEnter: null | string,
    animateExit: null | string
  ) {
    if (colorName === null || colorName === '') {
      colorName = 'bg-black';
    }
    if (text === null || text === '') {
      text = 'Turning standard Bootstrap alerts';
    }
    if (animateEnter === null || animateEnter === '') {
      animateEnter = 'animated fadeInDown';
    }
    if (animateExit === null || animateExit === '') {
      animateExit = 'animated fadeOutUp';
    }
    var allowDismiss = true;
    $.notify(
      {
        message: text,
      },
      {
        type: colorName,
        allow_dismiss: allowDismiss,
        newest_on_top: true,
        timer: 1000,
        placement: {
          from: placementFrom,
          align: placementAlign,
        },
        z_index: 9999,
        animate: {
          enter: animateEnter,
          exit: animateExit,
        },
        template:
          '<div data-notify="container" class="bootstrap-notify-container alert alert-dismissible {0} ' +
          (allowDismiss ? 'p-r-35' : '') +
          '" role="alert">' +
          '<span data-notify="icon"></span> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          '</div>' +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          '</div>',
      }
    );
  }

  handleEvent(event: string) {
    if (event.startsWith('success')) {
      const message = this._translate.instant(event.split(':')[1]);
      this.showNotification(
        'bg-green',
        message,
        'bottom',
        'right',
        'animated fadeInRight',
        'animated fadeOutRight'
      );
      return;
    }
    if (event.startsWith('error')) {
      const message = this._translate.instant(event.split(':')[1]);
      this.showNotification(
        'bg-red',
        message,
        'bottom',
        'right',
        'animated fadeInRight',
        'animated fadeOutRight'
      );
      return;
    }
    if (event === 'fetch data') {
      this.fetchSettingsData();
      return;
    }
  }

  closeCommonModal() {
    $('#SettingsCommonModal').modal('hide');
  }

  // For setting current active tab's save, reset function ref in variables...
  setCurrentTabInfo(tab: Tabs) {
    switch (tab) {
      case 'test_settings':
        this.currentTabDataInvalid = this.testSettingsComponent.disableSave;
        this.currentTabSave = this.testSettingsComponent.onSubmit.bind(
          this.testSettingsComponent
        );
        this.currentTabReset = this.testSettingsComponent.resetValues.bind(
          this.testSettingsComponent
        );
        break;
      case 'system_settings':
        this.currentTabDataInvalid =
          this.systemSettingsComponent.disableSaveBtn;
        this.currentTabSave = this.systemSettingsComponent.onSubmit.bind(
          this.systemSettingsComponent
        );
        this.currentTabReset = this.systemSettingsComponent.resetValues.bind(
          this.systemSettingsComponent
        );
        break;
      case 'ref_values':
        this.currentTabDataInvalid = this.refValueComponent.disableSaveBtn;
        this.currentTabSave = this.refValueComponent.onSubmit.bind(
          this.refValueComponent
        );
        this.currentTabReset = this.refValueComponent.resetValues.bind(
          this.refValueComponent
        );
        break;
      case 'facility_settings':
        this.currentTabDataInvalid =
          this.facilityProfileComponent.disableSaveBtn;
        this.currentTabSave = this.facilityProfileComponent.onSubmit.bind(
          this.facilityProfileComponent
        );
        this.currentTabReset = this.facilityProfileComponent.resetValues.bind(
          this.facilityProfileComponent
        );
        break;
      case 'user_profile':
        this.currentTabDataInvalid = this.userProfileComponent.disableSaveBtn;
        this.currentTabSave = this.userProfileComponent.onSaveImage.bind(
          this.userProfileComponent
        );
        this.currentTabReset = this.userProfileComponent.resetValues.bind(
          this.userProfileComponent
        );
        break;
    }
  }

  leaveWithOutSaving() {
    this.globalService.hasUnsavedChanges = false;
    this.currentTabReset();
    this.changeTab(this.tempTab);

    this.closeCommonModal();
  }

  back() {
    // Stop from changing the route fragmentation...
    this.router.navigate(['.'], {
      relativeTo: this.route,
      fragment: this.currentTab,
    });

    this.closeCommonModal();
  }

  changeTab(tab: Tabs) {
    // If trying to click on the already activated tab...
    if (tab === this.currentTab) return;

    // Store the save, reset function for the active tab we are currently on...
    this.setCurrentTabInfo(this.currentTab);

    // If current tab not presented that means running for first time, so don't check for value
    // changes at all...
    // using a alternateUnsavedForRefValues variable because, unsaved changes is not working fine with ref values component

    // if (this.globalService.hasUnsavedChanges && this.currentTab || (this.globalService.alternateUnsavedForRefValues == true) || (this.globalService.alternateUnsavedForRefValues1 == true)) {
    if (this.globalService.hasUnsavedChanges && this.currentTab) {
      // Store the tab we want to go in temporary variable...
      this.tempTab = tab;

      // Show different popup base on if the form is valid or not...
      if (this.currentTabDataInvalid) {
        this.modalTitle = this._translate.instant(
          'general.incomplete_information'
        );
        // prettier-ignore
        this.modalBody = `<h6 style="margin-top: 20px"><strong>${this._translate.instant('general.incomplete_information_body1')}</strong></h6>
                          <h6><strong>${this._translate.instant('general.incomplete_information_body2')}</strong></h6>`;
        this.modalButtons = [
          {
            name: this._translate.instant(
              'general.incomplete_information_button'
            ),
            color: 'btn-primary',
            clickFn: this.leaveWithOutSaving.bind(this),
            loading: false,
          },
          {
            name: this._translate.instant('general.back'),
            color: 'btn-primary',
            clickFn: this.back.bind(this),
            loading: false,
          },
        ];
        this.closeModalFn = this.back.bind(this);
        $('#SettingsCommonModal').modal('show');
      } else {
        this.globalService.exitPopupData$.next({
          showPopup: true,
          buttons: [
            {
              eventName: 'save-inner',
              displayName: this._translate.instant('general.save'),
              disableBtn: this.currentTabDataInvalid,
            },
            {
              eventName: 'dontSave-inner',
              displayName: this._translate.instant('general.dont_save'),
              disableBtn: false,
            },
            {
              eventName: 'close-inner',
              displayName: this._translate.instant('general.cancel'),
              disableBtn: false,
            },
          ],
        });
      }
    } else {
      // First reset the current tab to reset the warnings from the input fields,
      // and than go to the next tab that we clicked...
      if (this.currentTabReset) this.currentTabReset();

      this.currentTab = tab;
      // Show the active tab's content...
      this.tabContents.forEach((item) => {
        this.renderer.removeClass(item.nativeElement, 'show');
        this.renderer.removeClass(item.nativeElement, 'active');
      });
      $(`#${tab}`).tab('show');
    }
  }

  close(checkBlocking: boolean, shouldLogout: boolean) {
    this.globalService.exitPopupData$.next({ showPopup: false });
    if (checkBlocking == true) {
      this.globalService.hasUnsavedChanges = false;
      if (shouldLogout) {
        this.globalService.logoutSetFlags();
        this.authService.signOut();
      }
    }

    this.navigateAwaySelection$.next(checkBlocking);
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
    this.headerLogoutClickSubscription.unsubscribe();
    this.exitPopupBtnClickSubscription.unsubscribe();
    this.facilityDataSubscription.unsubscribe()
    this.globalService.hasUnsavedChanges = false;
  }
}
