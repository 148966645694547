import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-no-sperm-seen-checkbox',
  templateUrl: './no-sperm-seen-checkbox.component.html',
  styleUrls: ['./no-sperm-seen-checkbox.component.sass']
})
export class NoSpermSeenCheckboxComponent implements OnInit, OnChanges {

  @ViewChild('noSpermCheckbox') noSpermCheckbox: ElementRef;

  @Input('isDisabled') isDisabled:boolean;
  @Input('isStreaming') isStreaming: boolean;
  @Input('isNoSpermSeenChecked') isNoSpermSeenChecked: boolean;

  @Output('noSpermCheckEvent') noSpermCheckEvent = new EventEmitter<Event>();

  constructor(
    private renderer: Renderer2,
    public _globalService: GlobalService,
    public translate: TranslateService,
  ) { } 
  
  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    // throw new Error('Method not implemented.');

    if(changes.isNoSpermSeenChecked){
      this.isNoSpermSeenChecked = changes.isNoSpermSeenChecked.currentValue;
    }
    
    if(this.noSpermCheckbox){
      this.renderer.setProperty(
        this.noSpermCheckbox.nativeElement,
        'checked',
        this.isNoSpermSeenChecked
      );
    }
  }


  // ngAfterViewInit(): void {
  //   if(this.noSpermCheckbox){
  //     this.renderer.setProperty(
  //       this.noSpermCheckbox.nativeElement,
  //       'checked',
  //       this.isNoSpermSeenChecked
  //     );
  //   }
  // }

  get translateTextStyle() {
    return (this._globalService.unTranslate as string[]).includes(
      this.translate.currentLang
    )
      ? 'lowercase'
      : 'uppercase';
  }

  noSpermCheck(event): void{
    this.noSpermCheckEvent.emit(event);
  }

}
