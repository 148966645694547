import { Custompipe } from './pipes/pipes.module';
import { NgModule, Pipe, PipeTransform, ErrorHandler } from '@angular/core';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { MatTooltipModule } from '@angular/material/tooltip';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header/header.component';
import { PageLoaderComponent } from './layout/page-loader/page-loader.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { RightSidebarComponent } from './layout/right-sidebar/right-sidebar.component';
import { LocationStrategy, HashLocationStrategy, DatePipe, DecimalPipe } from '@angular/common';
import { DynamicScriptLoaderService } from './services/dynamic-script-loader.service';
import { GlobalErrorHandler } from './services/error-handler.service';

import { CommunicatorService } from './services/communication.service';
import { HttpService } from './services/http.service';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';

import { AlgorithmService, Results, TestSetup, SAMPLETYPE, TESTTYPE, WBCConcStd, ChamberStd, MorphCriteria, LES, KeyParameters, Debris_Scan } from './services/algorithm.service'

import { CoreModule } from './core/core.module';
// import { ContactUsComponent } from './contact-us/contact-us.component';


import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { registerLocaleData } from '@angular/common'
import localeFr from '@angular/common/locales/fr'
import localeDe from '@angular/common/locales/de'
import localeEn from '@angular/common/locales/en'
import localeIt from '@angular/common/locales/it'
import localeVi from '@angular/common/locales/vi'
import localePt from '@angular/common/locales/pt'
import localeEs from '@angular/common/locales/es'
import localeJa from '@angular/common/locales/ja'
import localeEl from '@angular/common/locales/el'
import localeTr from '@angular/common/locales/tr'


import { PipesModule } from './pipes/pipes.module';
// import { SafeHtmlPipe } from './dashboard/dashboard.module';
import { BnNgIdleService } from 'bn-ng-idle';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { SharedModule } from './shared/shared.module';
import { TestProcessMaskDirective } from './directives/test-process-mask.directive';


registerLocaleData(localeFr, 'fr')
registerLocaleData(localeDe, 'de')
registerLocaleData(localeEn, 'en');
registerLocaleData(localeIt, 'it')
registerLocaleData(localeVi, 'vi')
registerLocaleData(localePt, 'pt');
registerLocaleData(localeEs, 'es');
registerLocaleData(localeJa, 'ja');
registerLocaleData(localeEl, 'el');
registerLocaleData(localeTr, 'tr');



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    PageLoaderComponent,
    SidebarComponent,
    RightSidebarComponent,
    // ContactUsComponent,
    // SafeHtmlPipe
  ],
  imports: [
    PipesModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgbModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    CoreModule,
    MatTooltipModule,
    HttpClientModule,
    NgIdleKeepaliveModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    SharedModule
  ],


  providers: [BnNgIdleService, CommunicatorService, HttpService, AlgorithmService,DatePipe,DecimalPipe,Custompipe,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    DynamicScriptLoaderService,

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http,'./assets/i18n/', '.json');
}
