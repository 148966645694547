import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { switchMap, first } from 'rxjs/operators';
import * as firebase from 'firebase/app';
import 'firebase/storage';
import { Customer, TestSettings, SQACustomer, defaultReportSettings, defaultVUSettings,  
         defaultTestSettings, ReportType, defaultSystemSettings, SystemSettings, autoLogOutData, 
         defaultTestSettingsUS} from '../globals/globals';
import { AppError } from '../_helpers/app-error';
import { NotFoundError } from '../_helpers/not-found-error';
import { WrongPasswordError } from '../_helpers/wrong-password-error';
import { EmailNotAvailableError } from '../_helpers/email-not-available-error';
import { FailedLoginError } from '../_helpers/failed-login-error';
import { InvalidEmailError } from '../_helpers/invalid-email-error';
import { environment } from 'src/environments/environment';
import { User } from '../globals/user';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CrossAuthError } from '../_helpers/cross-auth-error';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EncryptionService } from '../services/encryption.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  db = firebase.firestore();
  user: Observable<User>;
  // currentUser: User;
  private loggedInStatus = JSON.parse(localStorage.getItem('loggedIn') || 'false');
  // private currentUser: any = {};
  public currentUser: any = {};
  public currentUserIP: string;
  public currentHost: string;
  public currentPath: string;
  private userLoggedIn = new Subject<boolean>();
  private timeoutDuration = new Subject<autoLogOutData>();
  public multiFactorAuth = new Subject<boolean>();
  selfTestTrigger: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private userData;
  private apiEP;
  public registrationCode: string;
  public iOwMode: boolean = false;
  public facilitySystemCountry: string;
  public loginDeviceCommand: string = 'reconnect' || 'selftest';
  public driverVersion: string; 
  public cptcCheckResponse: any = null;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    private deviceService: DeviceDetectorService,
    private encryptionService: EncryptionService,
    private _http: HttpClient
  ) {

    
    const hostname = window.location.hostname.toString().toLowerCase();
    this.currentHost = hostname;
    this.currentPath = window.location.pathname
    if (this.currentHost.includes('sqa-io.com') || this.currentPath.includes('sqa-io.com')) {
      this.currentHost = 'sqa-io.com'
    } else if (this.currentHost.includes('sqa-iow.com') || this.currentPath.includes('sqa-iow.com')) {
      this.currentHost = 'sqa-iow.com';
      this.iOwMode = true;
    } 
    
    if (hostname.includes('localhost')) {
      this.apiEP = 'api';
      this.currentHost = 'localhost:' + this.currentHost;
    } else {
      this.apiEP = "https://us-central1-messqasystem.cloudfunctions.net";
    }

    console.log('ACTIVE DOMAIN: ', this.currentPath, this.iOwMode, this.currentHost);
    // console.log('localdb loginstatus ', this.loggedInStatus);

    this.setUserLoggedIn(this.loggedInStatus);
    this.registrationCode = '';


    //// Get auth data, then get firestore user document || null
    // this.afAuth.user.pipe(
    //   switchMap(user => {
    //     if (user) {
    //       Customer.currentFacilityID = user.uid;
    //       this.currentUser.uid = user.uid;
    //       // this.currentUser.email = user.email;
    //       // this.currentUser.photoURL = user.photoURL;

    //       // console.log("userID ", user.uid);
    //       return this.afs.doc<User>(`SQAUsers/${user.uid}`).valueChanges(); // add case of customer
    //     } else {
    //       return of(null);
    //     }
    //   })
    // )

    this.user = this.afAuth.authState.pipe(
      switchMap(user => {
        // console.log("userID ", user);

        if (user) {
          Customer.currentFacilityID = user.uid;
          this.currentUser.uid = user.uid;
          // this.currentUser.email = user.email;
          // this.currentUser.photoURL = user.photoURL;

          // console.log("userID ", user.uid);
          return this.afs.doc<User>(`SQAUsers/${user.uid}`).valueChanges(); // add case of customer
        } else {
          // console.log("userID null");
          return of(null);
        }
      })
    );

  }

  setUserData(val) {
    this.userData = val;
  }
  getUserData() {
    return this.userData;
  }

  setUserLoggedIn(userLoggedIn: boolean) {
    this.userLoggedIn.next(userLoggedIn);
  }
 
  getUserLoggedIn(): Observable<boolean> {
    // console.log('get user logged in');
    return this.userLoggedIn.asObservable();
  }
  
  setDuration(isLogOutActive:boolean = true, timeoutDuration: number) {
    this.timeoutDuration.next({ isLogOutActive, timeoutDuration });
  }

  getDuration(): Observable<autoLogOutData> {
    return this.timeoutDuration.asObservable();
  }

  setLoggedIn(value: boolean) {
    this.loggedInStatus = value;
    localStorage.setItem('loggedIn', value.toString());
  }

  get isLoggedIn() {
    return JSON.parse(localStorage.getItem('loggedIn') || this.loggedInStatus.toString());
  }

  get authenticated(): boolean {
    // console.log('auth state', this.afAuth.authState);
    return this.afAuth.authState !== null;
  }

  get currentUserObservable(): any {
    return this.afAuth.auth;
  }

  googleLogin() {
    const provider = new firebase.auth.GoogleAuthProvider();
    return this.oAuthLogin(provider);
  }

  private oAuthLogin(provider) {
    return this.afAuth.auth.signInWithPopup(provider)
      .then((credential) => {
        this.updateUserData(credential.user);
      }).catch(this.handleError);
  }

  private handleError(err: firebase.FirebaseError) {
    console.trace('handle error', err);
    if (err.code === 'auth/user-not-found') {
      throw (new NotFoundError(err));
    }

    if (err.code === 'auth/wrong-password') {
      throw (new WrongPasswordError(err));
    }

    if (err.code === 'auth/email-already-in-use') {
      throw (new EmailNotAvailableError(err));
    }

    if (err.code === 'auth/too-many-requests' || err.message.includes('TOO_MANY_ATTEMPTS_TRY_LATER')) {
      throw (new FailedLoginError(err));
    }

    if (err.code === 'auth/invalid-email') {
      throw (new InvalidEmailError(err));
    }

    if(err.code === 'auth-error') {
      throw (new AppError(err)); 
    }
    if(err.code === 'input/validation-error') {
      throw (new InvalidEmailError(err));
    }
    throw (new AppError(err));

  
  }

  async emailLoginPhase1(value:any) { 
    let url = this.apiEP + "/userLogin3";
    const data = { email: value.username, password: value.password };
    return this._http.post(url, JSON.stringify(data)).toPromise().then(async exist => {
      this.currentUserIP = exist['clientIP'];     
      return({message: 'returned from PHASE 1', exist})
    });
  }

  async emailLoginPhase2(value: any, _systemCountry: string): Promise<void> {
    const credential = await firebase.auth().signInWithEmailAndPassword(value.username, value.password);
    const usr = await this.checkUser(credential.user);
    
      if(usr instanceof CrossAuthError) {
          // console.log('no interface user!');
          // return new NotFoundError();
      } else {
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE);
        await usr.set({lastLoginToHost: this.currentHost}, { merge: true });
        await usr.set({lastSignInTime: new Date(credential.user.metadata.lastSignInTime)}, { merge: true });
        const userRef: AngularFirestoreDocument<any> = this.afs.doc(`SQAUsers/${credential.user.uid}`);
        userRef.get().pipe(
          first(),
        ).subscribe(userData => {
          const _id = userData.data().facilityID;
          this.handleReportSettings({testSettings: {reportSettings: defaultReportSettings}}, _id);
          this.handleDefaultSettings(_id, _systemCountry);
          this.addDeviceInfo(_id);
          this.addLocationInfo(_id);
        });
        this.setLoggedIn(true);
        this.setUserLoggedIn(true);
      }
  }

  async changeExpiredPassword(value: any): Promise<any> {
    return new Promise<any>(async (res, rej) => {
        try {
          const credential = await firebase.auth().signInWithEmailAndPassword(value.username, value.password);
          res(credential);
        } catch (err) {
          rej(err);
        }
      });
  }

  async emailLogin(value) {
    let url = this.apiEP + "/userLogin3";
    const data = { email: value.username, password: value.password };
    return this._http.post(url, JSON.stringify(data)).toPromise().then(async exist => {
      this.currentUserIP = exist['clientIP'];
      const isMFAactive: boolean = exist['facilityMFAactive'];
      const isLoginSelfTest: boolean = exist['facilityLoginSelfTest'];
      const facilitySystemCountry: string = exist['facilitySystemCountry'];

      const credential = await firebase.auth().signInWithEmailAndPassword(value.username, value.password);
      const usr = await this.checkUser(credential.user);
        if(usr instanceof CrossAuthError) {
            // console.log('no interface user!');
            // return new NotFoundError();
        } else {
          firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE);
          await usr.set({lastLoginToHost: this.currentHost}, { merge: true });
          await usr.set({lastSignInTime: new Date(credential.user.metadata.lastSignInTime)}, { merge: true });
          const userRef: AngularFirestoreDocument<any> = this.afs.doc(`SQAUsers/${credential.user.uid}`);
          userRef.get().pipe(
            first(),
          ).subscribe(userData => {
            const _id = userData.data().facilityID;
            this.handleReportSettings({testSettings: {reportSettings: defaultReportSettings}}, _id);
            this.handleDefaultSettings(_id, facilitySystemCountry);
            this.addDeviceInfo(_id);
            this.addLocationInfo(_id);
          });
          this.setLoggedIn(true);
          this.setUserLoggedIn(true);
          // return await this.retrieveUserData(usr);
        }
       return({ isMFAactive, isLoginSelfTest});
      }).catch(err => {
        console.log(err);
        if( err instanceof CrossAuthError) {
          throw new CrossAuthError();
        } else if(err.hasOwnProperty('error')) {
          this.handleError(err.error);
        } else this.handleError(err);
      });
    }
    
  async signUpWithEmail(pload: any, _k: string) {

    let headers = new HttpHeaders({
      'X-hdr-checksum': pload,
      'X-hdr-k-sig': _k
    })
    let value = this.userData;
    const url = this.apiEP + '/signUpWithAuthVerified';
    return new Promise<any>(async (resolve, reject) => {
        await this._http.post(url, JSON.stringify({email: value.email, password: value.password, doctorName: value.username }), { headers }).toPromise().then(async usr => {
          await firebase.auth().signInWithEmailAndPassword(value.email, value.password).then(async cred => {
            this.setLoggedIn(true);
            this.setUserLoggedIn(true);
            firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE);
            let credential = cred;
            const userData: User = {
              userID: credential.user.uid,
              lastSignInTime: new Date(credential.user.metadata.lastSignInTime),
              lastLoginToHost: this.currentHost,
              creationTime: new Date(credential.user.metadata.creationTime),
              lastPasswordChange: new Date(),
              passwordExpire30DaysNotified: false,
              passwordExpire7DaysNotified: false,
              isFacilityAdmin: true,
              isEditor: true,
              facilityID: credential.user.uid,
              facilityName: value.facility,
              email: credential.user.email,
              doctorName: value.username,
              doNotShowCounterAlert: false,
              doNotShowCounterSkipAlert: false,
              doNotShowLeavePageAlert: false,
              doNotShowManualAlert: false,
        }
        
        let userCountry: string = value.country;
        let newFac = await this.updateUserData2(userData, userCountry, value.facility);
        if (newFac !== null || newFac || undefined) {
          resolve(newFac);
        } else {
          reject(null);
        }
  
      }).catch(this.handleError);
    }).catch(err => { 
      this.handleError(err.error)
      throw err;
    });
    })
  }

  private addDeviceInfo(userID) {
    let dInfo:any = this.deviceService.getDeviceInfo();
    // console.log('device info ', dInfo);
    // console.log('screen info', window.screen);
    dInfo.screen_width = window.screen.width;
    dInfo.screen_height = window.screen.height;
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`SQACustomers/${userID}`);
    return userRef.set({deviceInfo: dInfo}, { merge: true });
  }

  private addLocationInfo(userID) {
    return new Promise<any>((res, rej) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(resp => {
          let location = {
            lat: resp.coords.latitude,
            lng:  resp.coords.longitude
          }
          const userRef: AngularFirestoreDocument<any> = this.afs.doc(`SQACustomers/${userID}`);
          userRef.set({geoLocation: location }, { merge: true }).then(userlocation => {
            res(location);
          })
          .catch(err => {
            console.log('loc saving err ', err);
            rej(err);
          });
        }, err => {
          console.log('loc getting err ', err);
          rej("N/A");
        });
      }

    });

  }

  private updateUserData(user, name?, myFacility?, myPhoto?) {
    // Sets user data to firestore on login
    // console.log('user ', user);

    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`SQACustomers/${user.uid}`);

    const data: User = {
      uid: user.uid,
      email: user.email,
      doctorName: user.doctorName,
      photoURL: user.photoURL,
      facilityName: myFacility
    };
    if (name !== undefined && name !== null && name !== '') { data.doctorName = name; }
    // else { data.displayName = user.displayName; }
    if (myPhoto !== undefined && myPhoto !== null) { data.photoURL = myPhoto; }
    // console.log('user data ', data);

    return userRef.set(data, { merge: true });

  }

  private async handleReportSettings(updatedData: any, facilityID: string) {
    const facilityRef = this.db.collection('SQACustomers').doc(facilityID);
    let facilityData = (await facilityRef.get()).data();
    let _reportSettings = facilityData.testSettings.reportSettings;
        if (_reportSettings !== undefined) {

      // Check if report email is defined for existing user (Graph & Standard), if not - set it to facility's email as default.
      if (!_reportSettings.firstPage.hasOwnProperty('reportEmail')) {
        _reportSettings.firstPage.reportEmail = facilityData.email;
        await facilityRef.set({testSettings: {reportSettings: _reportSettings}}, { merge: true });
      } 

      // Check if standard report settings are defined for the facility, if not - add from default settings
      if (!_reportSettings.hasOwnProperty('standardPage')) {
        let reportStandardPage = defaultReportSettings.standardPage;
        _reportSettings.standardPage = reportStandardPage;
        await facilityRef.set({testSettings: {reportSettings: _reportSettings}}, { merge: true });
      } 

      // check if default report type is defined for existing user
      if (_reportSettings.reportType === undefined) {
        let addReportType = {..._reportSettings, onePageDisplayPageNumber: false, reportType: ReportType.GRAPH_REPORT }
        return await facilityRef.set({testSettings: {reportSettings: addReportType}}, { merge: true });
      } else return;
    } else {
      return await facilityRef.set(updatedData, { merge: true });
    }
  }
  
  // private async multiFactorAuthCheck (facilityID: string) {
  //   const facilityRef = this.db.collection('SQACustomers').doc(facilityID);
  //   let facilityData = (await facilityRef.get()).data();
  //   let facilitySystemSettings = facilityData.testSettings.systemSettings;

  //   if (facilitySystemSettings) {
  //     if (facilitySystemSettings.multiFactorAuthActive !== undefined) {
  //       this.multiFactorAuth.next(facilitySystemSettings.multiFactorAuthActive)
  //     } else {
  //       this.multiFactorAuth.next(false);
  //     }
  //   }
  // }

  private async handleDefaultSettings(facilityID: string, _systemCountry: string, isAutoSelfTest?: boolean, isMFA?: boolean){
    const batch = this.db.batch();
    const defaultSettingsRef = this.db.collection('System').doc('defaultSettings');

    const facilityRef = this.db.collection('SQACustomers').doc(facilityID);
    let facilityData = (await facilityRef.get()).data();
    let changes: Array<any> = [];
    let facilityTestSettings = facilityData.testSettings;
    let settingsChanged: boolean = false;
    
    let defaultSettings = (await defaultSettingsRef.get()).data();

    let optionalItems = {
      facilityOptional1: '',
      facilityOptional2: '',
      testOptional1: '',
      testOptional2: '',
    }

    // Helper function to compare facility Settings to defaults settings and update any missing setting with its default value
    const mergeObjects = (_facilitySettings: any, _defaultSettings: any): any => {
      for (const key in _defaultSettings) {
        if (_defaultSettings.hasOwnProperty(key)) {
          if (
            typeof _defaultSettings[key] === 'object' &&
            !Array.isArray(_defaultSettings[key])
          ) {
            if (!_facilitySettings.hasOwnProperty(key)) {
              _facilitySettings[key] = {};
            }
            mergeObjects(_facilitySettings[key], _defaultSettings[key]);
          } else {
            if (!_facilitySettings.hasOwnProperty(key)) {
              _facilitySettings[key] = _defaultSettings[key];
              settingsChanged = true;
            }
          }
        }
      }
      return _facilitySettings;
    };

    if (facilityData.testSettings.systemSettings === undefined) {
      
      // First check of systemSettings can be loaded from database default, if not, load it from globals.ts
      let _systemSettings: SystemSettings = defaultSettings.systemSettings ?? defaultSystemSettings;
      let updatedTestSettings = {...facilityTestSettings,  systemSettings: _systemSettings };
      changes.push('systemSettings loaded');
      batch.set(facilityRef, { testSettings: updatedTestSettings }, { merge: true });
      // this.selfTestTrigger.next(!_systemSettings.disableSelfTest);
      this.setDuration(_systemSettings.autoLogOut,  _systemSettings.autoLogOutValue);
    } else {
      // this.selfTestTrigger.next(!facilityData.testSettings.systemSettings.disableSelfTest); 
      this.setDuration(facilityData.testSettings.systemSettings.autoLogOut, facilityData.testSettings.systemSettings.autoLogOutValue);
    };

    if (facilityData.testSettings.VUSettings === undefined) {
      let _VUSettings = defaultSettings.VUSettings ?? defaultVUSettings;
      let updatedTestSettings = {...facilityTestSettings,  VUSettings: _VUSettings };
      changes.push('VU Settings loaded');
      batch.set(facilityRef, { testSettings: updatedTestSettings }, { merge: true });
    }

    // if (facilityData.sessionTimeout === undefined) {
    //   batch.set(facilityRef, { sessionTimeout: defaultSettings.sessionTimeout }, { merge: true })
    //   changes.push('sessionTimeout loaded');
    // } else {
    //   this.setDuration(facilityData.sessionTimeout);
    // };
   
    if (facilityData.lowQualityCriteria === undefined) {
      changes.push('lowQCriteria loaded');
      batch.set(facilityRef, { lowQualityCriteria: defaultSettings.lowQualityCriteria }, { merge: true });
    };
    if (facilityData.contentLimitation === undefined) {
      changes.push('contentLimitation loaded');
      batch.set(facilityRef, { contentLimitation: defaultSettings.contentLimitation }, { merge: true });
    } else {
      let updatedContentLimitation = mergeObjects(facilityData.contentLimitation,defaultSettings.contentLimitation);
      if (settingsChanged) {
        changes.push('contentLimitation updated');
        console.log ('content lim.: updated')
        batch.set(facilityRef, { contentLimitation: updatedContentLimitation }, { merge: true });
        settingsChanged = false;
      } else {
        console.log ('content lim.: match')
      };
    };

    // Update facility test settings

    let updatedTestSettings = mergeObjects(facilityData.testSettings, 
      _systemCountry === 'United States of America (the)' ? defaultTestSettingsUS : defaultTestSettings);
    if (settingsChanged) {
      changes.push('testSettings updated');
      console.log ('test settings: updated')
      batch.set(facilityRef, { testSettings: updatedTestSettings }, { merge: true });
      settingsChanged = false;
    } else {
      console.log ('test settings.: match')
    };


    if (facilityData.resultsRestriction === undefined) {
      changes.push('resultsRestriction loaded');
      batch.set(facilityRef, { resultsRestriction: defaultSettings.resultsRestriction }, { merge: true })
    };
    
    if (facilityData.creditRestriction === undefined) {
      changes.push('creditRestriction loaded');
      batch.set(facilityRef, { creditRestriction: defaultSettings.creditRestriction }, { merge: true })
    };
    
    if (facilityData.CameraSettings === undefined) {
      changes.push('camSettings loaded');
      batch.set(facilityRef, { CameraSettings: defaultSettings.CameraSettings }, { merge: true });
    };

    if (facilityData.numberOfAllowImages === undefined || 
        facilityData.numberOfAllowVideos === undefined ||
        facilityData.saveVideoLength === undefined) {
      changes.push('numberOfAllowImages loaded');
      batch.set(
        facilityRef, 
        {
          numberOfAllowImages: defaultSettings.numberOfAllowImages,
          numberOfAllowVideos: defaultSettings.numberOfAllowVideos,
          saveVideoLength: defaultSettings.saveVideoLength
        },
        { merge: true }
      )
    };
    
    if (facilityData.saveTestSignal === undefined) {
      changes.push('saveTestSignal loaded');
      batch.set(facilityRef, { saveTestSignal: defaultSettings.saveTestSignal }, { merge: true});
    };

    if (facilityData.orderSuppliesDefault === undefined) {
      changes.push('orderSuppliesDefault loaded');
      batch.set(facilityRef, { orderSuppliesDefault: defaultSettings.orderSuppliesDefault }, { merge: true});
    };

    Object.keys(optionalItems).forEach(async (_item) => {
      if (facilityData.testSettings[_item] === undefined) {
        let updatedTestSettings = {...facilityTestSettings, [_item]: ''};
        changes.push('optionals loaded');
        batch.set(facilityRef, { testSettings: updatedTestSettings }, { merge: true });
      };
    });

    if (changes.length > 0) {
      batch.commit().then(result => {
        firebase.analytics().logEvent('login_Defaults_handler', { facID: facilityID, changes });
        console.log('settings fixed', result);
      }).catch(err => {
        console.log(err);
      });
    } else {
      console.log('SETTINGS MATCH');
      firebase.analytics().logEvent('login_Defaults_handler', { facID: facilityID, changes: 'no changes' });
    }


  }; 

  private async updateUserData2(user: any, country: string, facilityName) {
    return new Promise<any>(async (resolve, reject) =>{
      const accountSettingData: TestSettings = country === 'United States of America (the)' ? defaultTestSettingsUS : defaultTestSettings;
      const batch = this.db.batch();
      const facilityRef = this.db.collection('SQACustomers').doc(user.userID);
      const userRef = this.db.collection('SQAUsers').doc(user.userID);
      const defaultSettingsRef = this.db.collection('System').doc('defaultSettings');

      let defaultSettings = (await defaultSettingsRef.get()).data();
      let facility: any = {};

      const hostname = window.location.hostname.toString().toLowerCase();
      let customerTypeEntry = 'facility';
      if (hostname.includes('validation')) {
        customerTypeEntry = 'validation';
      } else if (hostname.includes('localhost') || hostname.includes('dr')) {
        customerTypeEntry = 'development';
      } else if (hostname.includes('clinical')) {
        customerTypeEntry = 'clinical trial';
      }
      facility.uid = user.userID;
      facility.email = user.email;
      facility.doctorName = user.doctorName;
      facility.facilityName = facilityName;
      facility.country = country;
      facility.customerType = customerTypeEntry;
      facility.testCredits = 0;
      facility.creationTime = user.creationTime;
      facility.iOwMode = this.iOwMode;
      facility.lastSignInTime = user.lastSignInTime;
      facility.lastLoginToHost = this.currentHost;
      facility.testCount = 0;
      facility.patientCount = 0;
      facility.testSettings = accountSettingData;
      facility.testSettings.reportSettings.firstPage.reportEmail = user.email;
      facility.testSettings.reportSettings.standardPage.reportEmail = user.email;

      // Default settings handled in databases
      facility.contentLimitation = defaultSettings.contentLimitation;
      facility.creditRestriction = defaultSettings.creditRestriction;
      facility.resultsRestriction = defaultSettings.resultsRestriction;
      facility.CameraSettings = defaultSettings.CameraSettings;
      facility.lowQualityCriteria = defaultSettings.lowQualityCriteria;
      facility.selfTestOnLogin = defaultSettings.selfTestOnLogin;
      facility.numberOfAllowImages = defaultSettings.numberOfAllowImages;
      facility.numberOfAllowVideos = defaultSettings.numberOfAllowVideos;
      facility.saveVideoLength = defaultSettings.saveVideoLength;
      facility.maxNoiseLevel = defaultSettings.maxNoiseLevel;
      facility.saveTestSignal = defaultSettings.saveTestSignal;
      facility.sessionTimeout = defaultSettings.sessionTimeout;

      this.selfTestTrigger.next(defaultSettings.selfTestOnLogin);
      batch.set(facilityRef, facility, { merge: true });
      batch.set(userRef, user);
      batch.commit().then(r => {
        resolve(facility);
      }).catch(err => {
        console.error(err);
        reject(null);
      });
      // resolve(userRef);

    }).catch(err => {
      this.handleError(err);
    })

  }

  private async retrieveUserData(userRef) {
    // await this.addDeviceInfo(user);
    // this.addLocationInfo(user);
    // console.log('retrieve u data ref ', userRef);
    let returnedUser = userRef.get();
    return await returnedUser;

  }

  private async checkUser(user) {
    return new Promise<any>((res, rej) => {
      const userRef: AngularFirestoreDocument<any> = this.afs.doc(`SQAUsers/${user.uid}`);
          userRef.get().toPromise().then(usr => {
            // console.log('returnedUser exists', usr.exists);
            // console.log('returnedUser', usr.data());
            if (usr.exists) {
              res(userRef);
            } else {
              rej(new CrossAuthError());
            }

      }).catch(err => {
        console.log('returnedFacility err', err);
        rej(new CrossAuthError());
      });
    });
  }

  private async getFacilityUser (user) {
    return new Promise<any>((res, rej) => {
      const userRef: AngularFirestoreDocument<any> = this.afs.doc(`SQACustomers/${user.uid}`);
          userRef.get().toPromise().then(usr => {
            // console.log('returnedFacility exists', usr.exists);
            // console.log('returnedFacility', usr.data());
            if (usr.exists) {
              res(userRef);
            } else {
              rej('not a facility');
            }

      }).catch(err => {
        console.log('returnedFacility err', err);
        rej('not a facility');
      });
    });
  }

  // Generate custom token for cases of authentication loss during session
  public async reAuthenticateWithCustomToken(userID: string) {
    console.log(userID);
    const url = `${this.apiEP}/createTokenForAuth`;
    return new Promise<boolean>((res, rej) => {
      this._http.post(url, JSON.stringify({userID}), { headers: {'Content-type': 'application/json'} }).pipe(
        first(),
      ).subscribe(_token => {
        firebase.auth().signInWithCustomToken(_token.toString()).then(_response => {
          console.log('Custom auth. success');
          res(true);      
        });
      },
       error => {
        rej(error);
       });
    });
  }

  public async getSiteData() {
    try {
      return await new Promise<boolean>((res, rej) => {
        this._http.get(`api_services/getSiteData`).subscribe(res_1 => {
          console.log(res_1)
        });
      });
    } catch (err) {
      console.error(err);
    }
  }

  signOut() {
    this.afAuth.auth.signOut().then(() => {
      // console.log('state sign out');
      this.setLoggedIn(false);
      this.setUserLoggedIn(false);
      this.userData = undefined;
      localStorage.setItem("sidebar_option", '');
      this.currentUser = {};
      this.router.navigate(['/']);
    });
  }

  async signOutNoNavigate() {
    await this.afAuth.auth.signOut();
  }

  async resetPassword(email, iOwMode: boolean) {
    // const userExists = await firebase.auth().fetchSignInMethodsForEmail(email);
    const userExists = await this.checkIfUserExist(email);
    if (email !== null && userExists === true) {
    // if (email !== null && userExists.length > 0) {
      const url = this.apiEP + '/sendResetPasswordEmail';
      // await firebase.auth().sendPasswordResetEmail(email).then((resp) => {
        await this._http.post(url, JSON.stringify({email, iOwMode}), {headers:{'Content-type':'application/json'}}).toPromise().then((resp) => {
        return true;
      }).catch(err => {
        this.handleError(err.error);
      });
    } else {
      throw new NotFoundError();
    }
  }



  async checkIfUserExist(email: string) {
    let data = {
      email,
    }
    const _k = this.encryptionService.createChecksum(environment.firebase.apiKey);
    let headers = new HttpHeaders({
      'X-hdr-checksum': _k
    });   
    let url = this.apiEP + "/isUserRegistered";

    try {
      const userData = await this._http.post(url, { data }, { headers }).toPromise();
      
      let decryptedPayload = JSON.parse(this.encryptionService.decrypt(userData['dataFC'], _k));

      if (decryptedPayload.code === 'auth/user-not-found') {
        return Promise.resolve(false);
      } else if (decryptedPayload.uid !== null && decryptedPayload.uid.length > 0) {
        return Promise.resolve(true)
      }
    } catch (error) {
      return Promise.reject(false);
    }

    // let url = this.apiEP + "/isUserExist?email=" + email;
    // return this._http.get(url).toPromise();
  }

  public async AuthenticateUser(providedEmail, providedPassword) {

    var user = firebase.auth().currentUser;
    var credential = firebase.auth.EmailAuthProvider.credential(
      providedEmail,
      providedPassword
    );

    // Prompt the user to re-provide their sign-in credentials

    await user.reauthenticateWithCredential(credential).then(() => {
      // User re-authenticated.
      // console.log("User re-authenticated");
      return true;
    }).catch(this.handleError);
  }

  public async reauthenticateUser(providedEmail, providedPassword)  {

    let user = firebase.auth().currentUser;
    let credential = firebase.auth.EmailAuthProvider.credential(
      providedEmail,
      providedPassword
    );

    return new Promise<Boolean>(async (res, rej) =>{
      try {
        await user.reauthenticateWithCredential(credential);
        res(true);
      } catch (error) {
        console.error(error);
        rej(false);
      }
    })
  }

  

}
