import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SettingsComponent } from '../sementest/settings/settings.component';
import { EditPatientComponent } from '../patient/edit-patient/edit-patient.component';
import { AddPatientComponent } from '../patient/add-patient/add-patient.component';
import { TestresultsComponent } from '../sementest/testresults/testresults.component';
import { ServiceComponent } from '../sementest/service/service.component';
import { QcControlsComponent } from '../sementest/qc-controls/qc-controls.component';
import { ManualResultsComponent } from '../sementest/manual-results/manual-results.component';
import { VideoanalysisComponent } from '../sementest/videoanalysis/videoanalysis.component';
import { TestProcessComponent } from '../sementest/test-process/test-process.component';
import { CounterComponent } from '../sementest/counter/counter.component';
// import { HasUnsavedData } from './core.interface';

@Injectable({
  providedIn: 'root'
})
export class HasUnsavedDataGuard implements CanDeactivate<SettingsComponent> {
  cComponent;
  constructor( private router: Router) {
    this.cComponent = SettingsComponent;
  }
  canDeactivate(component: SettingsComponent | EditPatientComponent | AddPatientComponent | TestresultsComponent | ServiceComponent | QcControlsComponent | ManualResultsComponent | VideoanalysisComponent|TestProcessComponent | CounterComponent,
    route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    console.log('has-unsaved-changes guard ', component.canDeactivate());
    console.log('current url ', route.url);

    // @TODO - ADD CONDITION TO OVERRIDE POP-UP IF SESSION HAS EXPIRED

    return component.canDeactivate();
    // if (component.canDeactivate()) {
    //   return true;
    // } else {
    //   let answer = confirm('You have some unsaved form data. Are you sure, you want to leave this page?');
    //   console.log(answer);
    //   // return answer;
    //   return answer;

    // }

  }

  

}
