import { AuthService } from 'src/app/core/auth.service';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ChangeDetectorRef,
} from "@angular/core";
import { Subscription } from "rxjs";
import { GlobalService } from "src/app/services/global.service";
import { HttpService } from "src/app/services/http.service";
import {
  ActiveClient,
  CommunicatorService,
  MyServiceEvent,
} from "src/app/services/communication.service";
import { TranslateService } from "@ngx-translate/core";
import { ResultsHeaderService } from "src/app/sementest/results-header.service";
import { Router } from "@angular/router";
declare const $: any;

@Component({
  selector: "app-header-buttons",
  templateUrl: "./header-buttons.component.html",
  styleUrls: ["./header-buttons.component.sass"],
})
export class HeaderButtonsComponent implements OnInit {
  @Output() printReport = new EventEmitter<string>();
  @Output() onretest = new EventEmitter<string>();
  InternetSubscription: Subscription;
  facilityUpdates: Subscription;
  isInternet: boolean = true;
  displayRetest: boolean = false;
  isStoringInProgress: boolean = false;
  _serviceSubscription: Subscription;
  isVisualization: boolean = false;
  isHideMorphology: boolean = true;
  iOwmode: boolean = false;
  Result_Data: any;
  constructor(
    private cdr: ChangeDetectorRef,
    public _header: ResultsHeaderService,
    private router: Router,
    private _http: HttpService,
    public _glob: GlobalService,
    public translate: TranslateService,
    private Comm: CommunicatorService,
    private authService: AuthService
  ) {
    this.InternetSubscription = _glob
      .networkMonitor()
      .subscribe((status: any) => {
        console.log("OOOOOOOOOOOOOOOOOOOOOOOO",status);
        
        this.isInternet = status;
        this._header.isRetest = false;
        this.cdr.detectChanges();
      });
    this.Result_Data = { ...this._glob.Fresh_Test_Data };
    this._header.testCount.subscribe((countVal: any) => {
      if (countVal <= 0) {
        this.displayRetest = false;
      } else {
        this.displayRetest = true;
      }
    });
    _glob.isAnyUploadingImagesVideos$.subscribe((status) => {
      this.isStoringInProgress = status;
    });

    this.facilityUpdates = _http.facilityUpdates.subscribe((fac) => {
      console.log(fac.contentLimitation?.visualizationAccess);
      this.isVisualization = fac.contentLimitation?.visualizationAccess;
      this.isHideMorphology =
        fac.contentLimitation?.hideMorphology != undefined
          ? fac.contentLimitation?.hideMorphology
          : true;
    });
    this.iOwmode = this.authService.iOwMode;
    this._serviceSubscription = this.Comm.onChange.subscribe({
      next: (event: MyServiceEvent) => {
        switch (event.command) {
          case "DeviceList":
            this._header.deviceconnect = this._header.updateconnectionstatus();

            break;

          case "Progress":
            //this.myvalue = event.value;
            //console.log("Progress and value" , this.ProgressValue , " value=",event.value);

            break;

          case "ReadDataRes":
            // console.log("Read Data [analysis]")
            break;

          case "ReadODReferenceCompleted":
            break;

          case "ReadODCompleted":
            break;

          case "MotilityTestCompleted":
            var buf = new Uint16Array(event.value, 0);
            // console.log("MotilityTestCompleted");
            break;

          case "NoiseTestCompleted":
            var buf = new Uint16Array(event.value, 0);
            // console.log("NoiseTestCompleted");
            break;

          case "SelfTestCompleted":
            // console.log("SelfTestCompleted");

            break;

          case "ReadParameters":
            // console.log("Parameters: [test process] ", event.value);
            if (event.value.SelfTest == 1) {
              // setTimeout(() => {
                this._header.deviceconnect =
                this._header.updateconnectionstatus();
                // console.log("Disconnect.......", _header.deviceconnect,(this._header.deviceconnect==0   || !this.displayRetest || !this.isInternet));
                cdr.detectChanges()
              // }, 500);
              
              
                
            }
            break;
        }
      },
    });
  }

  ngOnInit(): void {
    this.isInternet = this._glob.networkStatus;
  }

  report(isDownload) {
    this.printReport.emit(isDownload);
  }

  onreTest() {
    this._glob.reTest = true;
    let optionals: any = { fragment: this.testModeFragment };
    if (this.Result_Data.testingMode == 2) {
      optionals.queryParams = {
        prepType: this.Result_Data.prepType == 0 ? "pre_prep" : "post_prep",
        ...(this.Result_Data.prepType == 0 && {
          selectedTest: this.Result_Data.sampleInformation.sampleType,
        }),
      };
    }
    this.router.navigate(["sementest/test-process"], optionals);
    this._header.isRetestClicked = true;
    this.onretest.emit("true");
  }
  ngOnDestroy() {
    this._header.onCompDestroy();
    this._serviceSubscription.unsubscribe();
  }

  ngAfterViewInit() {
    console.log("RRRRR33RRRRR", this._header.creditcount);
  }

  get isPostPrep(): boolean {
    // const ART_PREP_Results =  this._glob.ART_PREP_Results;
    if (
      this._glob.Fresh_Test_Data.testingMode === 2 &&
      this._glob.Fresh_Test_Data.prepType === 1
    ) {
      return true;
    }
    return false;
  }

  ref() {
    this.Comm.RunReferenceTest(0);
  }
  enableorDisable() {
    this.router.navigate([`sementest/visualization/${this._header.testID}`]);
    if (this._glob.hasUnsavedChanges == true) {
      this._header.enteringVisualization == false;
      this._header.anyChanges.subscribe((change: boolean) => {
        this._header.enteringVisualization = change;
      });
    } else {
      this._header.enteringVisualization = true;
    }
  }

  public get testModeFragment(): string {
    const fragments = ["fresh_test", "washed_test", "art_prep_test"];

    return fragments[this._glob.Fresh_Test_Data.testingMode];
  }
}
