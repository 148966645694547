import { Component, OnDestroy, Input, ChangeDetectorRef, OnInit, OnChanges, AfterViewInit, SimpleChanges } from '@angular/core';
import { MatPaginator, PageEvent, MatPaginatorIntl } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, Subscription } from 'rxjs';
import { take, repeat } from 'rxjs/operators'
import { GlobalService } from 'src/app/services/global.service';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-custom-paginator',
  templateUrl: './custom-paginator.component.html',
  styleUrls: ['./custom-paginator.component.sass']
})
export class CustomPaginatorComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() _dataSource: MatTableDataSource<any[]>
  @Input() _searchDataSource: string;

  paginator: MatPaginator; // MatPaginator is not bound to ViewChild
  
  _tableLength: number = 0;
  _pageStartEntry: number = 0;
  _pageEndEntry: number = 0;
  _prevPageIndex: number = 0
  pageButtons: Array<any> = [];
  totalPageButtons: number = 0;
  maxPageButtons: number = 5;
  minArrayFragmentButton: number;
  maxArrayFragmentButton: number = this.maxPageButtons;
  pagePivot$: BehaviorSubject<number> = new BehaviorSubject<number>(1);
  pageSize$: Subscription;
  viewLastPage$: Subscription;
  viewLastPageStatus: boolean = false;
  selectedPage: number;
  sortDirection: string = '';
  sortedColumn: string = ''

  //@@@@@@@@@@@@@@@  Create custom paginator (not by mat-paginator element; _"Intl" parameter is required it initialize the instance)
  constructor(private cdr: ChangeDetectorRef, private _intl: MatPaginatorIntl, private httpService: HttpService, private globalService: GlobalService) {
    this.paginator = new MatPaginator(_intl, cdr);
  }

  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ Initialize paginator data, when table source data isloaded from archive page
  ngOnChanges(changes: SimpleChanges): void {
    this.viewLastPageStatus = this.httpService.viewLastArchivePage;
    if (this._dataSource) this._dataSource.paginator = this.paginator; // Bind the custom paginator to the Datatable
    this.paginator._changePageSize(this.globalService.lastPageSize);
    this.totalPageButtons = this.paginator.getNumberOfPages();

    // @@@@@@@@@@@@ Initialize table based on status of last page viewed condition
    if (this.viewLastPageStatus && this.globalService.PageNumLastVisit !== -1 && this.globalService.PageNumLastVisit > 1) {
      this.paginator.pageIndex = this.globalService.PageNumLastVisit - 1;
      this.pagePivot$.next(this.globalService.PageNumLastVisit);
      setTimeout(() => {
        this._tableLength = this.paginator.length;
        this._pageEndEntry = this._tableLength === 0 ? 0 : ((this.paginator.pageIndex + 1) * this.paginator.pageSize);
        this._pageStartEntry = this._tableLength === 0 ? 0 : this._pageEndEntry - (this.paginator.pageSize - 1);
        // this.totalPageButtons = this.paginator.getNumberOfPages();
        if ((this.globalService.PageNumLastVisit > this.maxPageButtons) && (this.globalService.PageNumLastVisit < this.totalPageButtons)) {
          this.pageButtons = this.genereatePageButtons(this.globalService.PageNumLastVisit);
        } else {
          this.initializePageButtons(this.maxPageButtons);
        }
      }, 100);
    } else {
      this.paginator.pageIndex = 0;
      setTimeout(() => {
        this._tableLength = this.paginator.length;
        this._pageStartEntry = this._tableLength === 0 ? 0 : this.paginator.pageIndex + 1;
        this._pageEndEntry = this.paginator.length > this.paginator.pageSize ? this.paginator.pageSize : this.paginator.length;
        this.totalPageButtons = this.paginator.getNumberOfPages();
        // this.initializePageButtons(this.paginator.pageSize / 2); 
        this.initializePageButtons(this.maxPageButtons);
      }, 100);
    }
  }


  // @@@@@@@@@@@@@@@@@@@@@@@@@ Setting up observables to handle pagination
  ngOnInit(): void {
    // @@@@@@@@@@@@@@@@@ Observable to update the current page button & display, and perform pagination
    this.pagePivot$.subscribe(res => {
      this.selectedPage = res;
      // @@@@@@@@@@@@@@ This block is triggering paginator built-in functions, which update the pageIndex explicitly
      if (res === 1) {
        this.paginator.firstPage();
      } else if (res === this.totalPageButtons) {
        this.paginator.lastPage();
        this.selectedPage = this.totalPageButtons;
      } else if (res === - 1) {
        this.paginator.nextPage();
        this.selectedPage = this.paginator.pageIndex + 1;
        this.onPageClick(this.selectedPage++); // Call the function manually to update button array structure
      } else if (res === - 2) {
        this.paginator.previousPage();
        this.selectedPage = this.paginator.pageIndex === 0 ? 1 : this.paginator.pageIndex + 1;
        this.onPageClick(this.selectedPage--);
      } else {
        //@@@@@@@@@@@@@@@@@@@@@@@ This block is handling customized paginator actions,
        //                        First the pageIndex is set and the pgination is emitted  
        const pageEvent = new PageEvent();
        this.paginator.pageIndex = res === 1 ? 0 : res - 1; // Set paginator to starting point if current page is 1, otherwise set it to -1 the page clicked
        pageEvent.pageIndex = res;
        this.paginator.page.emit(pageEvent);
      }

      // @@@@@@@@@@@@@ After pagination complete, update values for "Showing... of ... entries" section

      if ((this.paginator.pageIndex + 1) === this.totalPageButtons) {
        this._pageEndEntry = this.paginator.length;
        this._pageStartEntry = this.paginator.length % this.paginator.pageSize === 0 ?
          this.paginator.length - ((this.paginator.length % this.paginator.pageSize) + 9) :
          this.paginator.length - ((this.paginator.length % this.paginator.pageSize) - 1);
      } else {
        this._pageEndEntry = (this.paginator.pageIndex + 1) * this.paginator.pageSize;
        this._pageStartEntry = this._pageEndEntry - (this.paginator.pageSize - 1);
      }
      if (this.viewLastPageStatus) {
        this.globalService.PageNumLastVisit = this.selectedPage;
      }
      else {
        this.globalService.PageNumLastVisit = -1;
      }
      this.cdr.detectChanges();

    });

    // @@@@@@@@@@@@@@@@@ reload paginator after changing page size
    this.pageSize$ = this.paginator.page.pipe(take(1), repeat()).subscribe(evt => {
      if (evt.hasOwnProperty('pageSize')) {
        this.paginator._changePageSize(evt.pageSize)
        this._pageStartEntry = this.paginator.pageIndex + 1;
        this._pageEndEntry = this.paginator.pageSize > this.paginator.length ? this.paginator.length : this.paginator.pageSize;
        this.selectedPage = 1;
        this.totalPageButtons = this.paginator.getNumberOfPages();
        this.initializePageButtons(this.maxPageButtons);
      }
    });
  }

  ngAfterViewInit(): void {
    // Required to refresh test patient view after subscription is complete
    setTimeout(() => {
      if (this.globalService.PageNumLastVisit !== -1) {
        this.pageButtons = this.genereatePageButtons(this.globalService.PageNumLastVisit);
        this.pagePivot$.next(this.globalService.PageNumLastVisit);
      }
      this.totalPageButtons = this.paginator.getNumberOfPages()
    }, 350)
  }

  get hasNextPage(): boolean {
    return this.paginator.hasNextPage();
  }

  get hasPreviousPage(): boolean {
    return this.paginator.hasPreviousPage();
  }

  // Generate array for paginations direct navigation buttons
  initializePageButtons(range?: number): void {
    const ellipsis: string = '...';

    // this.pageButtons.length = 0;
    this.minArrayFragmentButton = null;
    this.maxArrayFragmentButton = 5;

    if (this.totalPageButtons < range) {
      this.pageButtons.length = 0;
      for (let i = 0; i < this.totalPageButtons; i++) {
        this.pageButtons.push(i + 1);
      }
    } else if (this.totalPageButtons > range) {
      this.pageButtons.length = 0;
      for (let i = 0; i < range; i++) {
        this.pageButtons.push(i + 1);
      }
      this.pageButtons.push(ellipsis);
      this.pageButtons.push(this.totalPageButtons);
      return;
    } else {
      if ((this.totalPageButtons === range)) {
        if (this.paginator.length % this.paginator.pageSize === 0) {
          this.pageButtons.length = 0;
          for (let i = 0; i < this.totalPageButtons; i++) {
            this.pageButtons.push(i + 1);
          }
          return;
        } else {
          let tempArray: Array<number | string> = [];
          this.pageButtons.length = 0;
          for (let i = this.totalPageButtons; i > (this.totalPageButtons - this.maxPageButtons); i--) {
            tempArray.push(i);
          }
          // tempArray.push(ellipsis);
          // tempArray.push(1);
          this.pageButtons = [...tempArray.reverse()];
          return;
        }
      }
    }
  }

  //@@@@@@@@@@@@@@@@@@@@ return new page buttons structure based on current selected page, used for "fragmented" buttons section
  genereatePageButtons(pageButtonClicked: number): Array<Number | String> {
    const ellipsis: string = '...';
    let arrayPrefix: Array<Number | String> = [1, ellipsis];
    let arraySuffix: Array<Number | String> = [ellipsis, this.totalPageButtons];
    let _buttonsArray: Array<Number | String> = [];

    this.maxArrayFragmentButton = pageButtonClicked + 1;
    this.minArrayFragmentButton = pageButtonClicked - 1;

    _buttonsArray = [
      ...arrayPrefix,
      this.minArrayFragmentButton,
      pageButtonClicked,
      this.maxArrayFragmentButton,
      ...arraySuffix
    ];
    return _buttonsArray
  }

  //@@@@@@@@@@@@@@@@ Update the pagination buttons structure 
  onPageClick(_pageButton) {
    if (_pageButton === this.selectedPage) return;

    if (_pageButton === 1 || (_pageButton < this.maxPageButtons)) {
      this.initializePageButtons(this.maxPageButtons);
    } else if (_pageButton === this.totalPageButtons || ((this.totalPageButtons - _pageButton + 2) <= this.maxPageButtons)) {
      this.initializePageButtons(this.totalPageButtons);
    } else {
      this.pageButtons = this.genereatePageButtons(_pageButton);
    }
    this.pagePivot$.next(_pageButton);
  }

  //@@@@@@@@@@@@@@@@@@@  Handling click on PREVIOUS/NEXT buttons, change table to relevant page and update "SHOWING..." labels values
  //@@@@@@@@@@@@@@@@@@@ (Seperate logic is required, to handle first pagination values)
  onPaginateClick(eventType: string) {
    if (eventType === 'next' && this.hasNextPage) {
      this.pagePivot$.next(-1); // Sign for single next page navigation
    } else if (eventType === 'prev' && this.hasPreviousPage) {
      this.pagePivot$.next(-2); // Sign for single previous page navigation
    }
  }

  ngOnDestroy(): void {
    this.pagePivot$?.unsubscribe();
    this.pageSize$?.unsubscribe();
    this.viewLastPage$?.unsubscribe();
  }


}
