import { Component, Input, OnInit } from '@angular/core';
import { bredCrumb } from 'src/app/globals/globals';

@Component({
  selector: 'app-bred-crum',
  templateUrl: './bred-crum.component.html',
  styleUrls: ['./bred-crum.component.sass']
})
export class BredCrumComponent implements OnInit {
  @Input() bredCrum: bredCrumb;

  constructor() { }

  ngOnInit(): void {

  }

  onclick(fn: Function) {
    if (fn)
      fn()
  }

}
