import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
} from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
  ValidationErrors,
} from "@angular/forms";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

import { AuthService } from "src/app/core/auth.service";
import { SQACustomer } from "src/app/globals/globals";
import { HttpService } from "src/app/services/http.service";
import { User } from "src/app/globals/user";
import { GlobalService } from "src/app/services/global.service";
import { passwordStrengthValidator } from "../../../_helpers/password-strength.validator";

interface FileError {
  correctFormat: boolean;
  correctSize: boolean;
  correctRes: boolean;
}

type FileType = "signature" | "profilePic";

declare const $: any;

@Component({
  selector: "app-user-profile",
  templateUrl: "./user-profile.component.html",
  styleUrls: ["./user-profile.component.sass"],
})
export class UserProfileComponent implements OnInit, OnDestroy {
  @Input() facilityData: SQACustomer;
  @Output() fireEvent = new EventEmitter();

  currentUser: User;
  signatureFile: File;
  profileFile: File;
  signatureBase64Url: SafeUrl;
  passwordForm: FormGroup;
  // prettier-ignore
  signatureError: FileError = { correctFormat: true, correctSize: true, correctRes: true };
  // prettier-ignore
  profilePicError: FileError = { correctFormat: true, correctSize: true, correctRes: true };
  defaultLogoUrl = "assets/images/settings/no_image_upload.png";
  userSubscription: Subscription;
  invalidPassword = false;
  tooManyRequests = false;
  submitBtnLoading = false;

  // Change password related variables...
  showCurrPswd = false;
  showNewPswd = false;
  showConfirmPswd = false;
  passwordChangeRetryCounter: number = 0;

  // Modal related variables...
  modalTitle: string;
  modalBody: string;
  modalButtons: {
    name: string;
    color: string;
    clickFn: any;
    loading?: boolean;
  }[];

  @ViewChild("signatureLabel") signatureLabel: ElementRef;
  @ViewChild("profileLabel") profileLabel: ElementRef;

  get profilePicUrl() {
    if (this.currentUser) {
      if (this.currentUser.photoURL && this.currentUser.photoURL !== "")
        return this.currentUser.photoURL;
      return this.defaultLogoUrl;
    }
    return this.defaultLogoUrl;
  }

  get signatureUrl() {
    if (this.signatureBase64Url) return this.signatureBase64Url;
    if (this.currentUser && this.currentUser.signatureURL)
      return this.currentUser.signatureURL;
    return "";
  }

  get disableSaveBtn() {
    return !(
      this.globalService.hasUnsavedChanges &&
      this.signatureError.correctFormat &&
      this.signatureError.correctSize &&
      this.signatureError.correctRes &&
      this.profilePicError.correctFormat &&
      this.profilePicError.correctSize &&
      this.profilePicError.correctRes
    );
  }

  get translateTextStyle() {
    return (this.globalService.unTranslate as string[]).includes(
      this._translate.currentLang
    )
      ? "lowercase"
      : "uppercase";
  }

  constructor(
    private authService: AuthService,
    private renderer: Renderer2,
    private httpService: HttpService,
    private _translate: TranslateService,
    private globalService: GlobalService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.userSubscription = this.authService.user.subscribe((user) => {
      if (user) {
        this.currentUser = user;
      }
    });

    this.passwordForm = new FormGroup({
      currentPassword: new FormControl("", [
        Validators.required,
        // passwordStrengthValidator,
      ]),
      newPassword: new FormControl("", [
        Validators.required,
        Validators.minLength(8),
        passwordStrengthValidator,
        this.runConfirmPswdValidator.bind(this),
      ]),
      confirmPassword: new FormControl("", [
        Validators.required,
        Validators.minLength(8),
        passwordStrengthValidator,
        this.matchPasswordValidator.bind(this),
      ]),
    });
  }

  // <-------------------------------- Form Custom Validators ---------------------------------->

  matchPasswordValidator(control: AbstractControl): ValidationErrors | null {
    if (control.parent) {
      const newPassword = control.parent.get("newPassword").value;
      if (
        control.value !== newPassword &&
        (control.value as string).length > 0
      ) {
        return { matchError: true };
      }
      return null;
    }
    return null;
  }

  runConfirmPswdValidator(control: AbstractControl): ValidationErrors | null {
    if (control.parent) {
      const confirmPasswordControl: AbstractControl =
        control.parent.get("confirmPassword");
      if (
        confirmPasswordControl.value &&
        confirmPasswordControl.value.length > 0
      ) {
        if (control.value !== confirmPasswordControl.value) {
          confirmPasswordControl.setErrors({ matchError: true });
        } else {
          confirmPasswordControl.setErrors({ matchError: null });
          // Reflect the remove error in the control...
          confirmPasswordControl.updateValueAndValidity();
        }
      }
      return null;
    }
    return null;
  }

  getValidationMessage(control: string) {

    //password pattern check and throw error
    const passwordPatternCheck = (control: string) => {
      const passwordString = this.passwordForm.get(control).value;
      const charCheckPattern = /^(?=.*[!@#$%^&*()_\-+=\[\]{}<>`:;,'".~?|]).+$/;
      const letterCheckPattern = /^(?=(.*[a-zA-Z])).+$/;
      const digitCheckPattern = /^(?=.*\d).+$/;

      if (
        !charCheckPattern.test(passwordString) &&
        (this.passwordForm.get(control).touched ||
          this.passwordForm.get(control).dirty)
      ) {
        return this._translate.instant("auth.password_char_alert"); // alert for charCheck
      } else if (
        !letterCheckPattern.test(passwordString) &&
        (this.passwordForm.get(control).touched ||
          this.passwordForm.get(control).dirty)
      ) {
        return this._translate.instant("auth.password_letters_alert"); // alert for letterCheck
      } else if (
        !digitCheckPattern.test(passwordString) &&
        (this.passwordForm.get(control).touched ||
          this.passwordForm.get(control).dirty)
      ) {
        return this._translate.instant("auth.password_digits_alert"); // alert for digitCheck
      } else {
        return '';
      }
    }

    switch (control) {
      case "currentPassword":
        //currentPassword required error
        if (
          (this.passwordForm.get(control).touched ||
            this.passwordForm.get(control).dirty) &&
          this.passwordForm.get(control).hasError("required")
        ) {
          return this._translate.instant("auth.password_required");
        }
        break;

      case "confirmPassword":
        //confirmPassword required error
        if (
          (this.passwordForm.get(control).touched ||
            this.passwordForm.get(control).dirty) &&
          this.passwordForm.get(control).hasError("required")
        ) {
          return this._translate.instant("auth.c_password_required");
        }

        //minlength error
        if (
          this.passwordForm.get(control).hasError("minlength") &&
          (this.passwordForm.get(control).touched ||
            this.passwordForm.get(control).dirty)
        ) {
          return this._translate.instant("auth.password_alert", { value: "8" });
        }

        //if confirmPassword not match the newPassword
        if ((this.passwordForm.get('newPassword').value !== this.passwordForm.get(control).value) &&
          (this.passwordForm.get(control).touched ||
            this.passwordForm.get(control).dirty)
        ) {
          return this._translate.instant('auth.pw_match');
        }

        //pattern error
        return passwordPatternCheck(control);

        break;

      case "newPassword":
        //currentPassword required error
        if (
          (this.passwordForm.get(control).touched ||
            this.passwordForm.get(control).dirty) &&
          this.passwordForm.get(control).hasError("required")
        ) {
          return this._translate.instant("auth.password_required");
        }

        //minlength error
        if (
          this.passwordForm.get(control).hasError("minlength") &&
          (this.passwordForm.get(control).touched ||
            this.passwordForm.get(control).dirty)
        ) {
          return this._translate.instant("auth.password_alert", { value: "8" });
        }

        //pattern error
        return passwordPatternCheck(control);

        break;

      default:
        return "";
    }



    // console.log("===getValidationMessage ===", control);

    // //currentPassword required error
    // if (control === 'currentPassword' && (this.passwordForm.get(control).touched ||
    //   this.passwordForm.get(control).dirty) && this.passwordForm.get(control).hasError('required')) {
    //   return this._translate.instant('auth.password_required');
    // }

    // if (control === 'currentPassword') {
    //   return null;
    // }

    // //confirmPassword required error
    // if (control === 'newPassword' && (this.passwordForm.get(control).touched ||
    //   this.passwordForm.get(control).dirty) && this.passwordForm.get(control).hasError('required')) {
    //   return this._translate.instant('auth.password_required');
    // }

    // //confirmPassword required error
    // if (control === 'confirmPassword' && (this.passwordForm.get(control).touched ||
    //   this.passwordForm.get(control).dirty) && this.passwordForm.get(control).hasError('required')) {
    //   return this._translate.instant('auth.password_required');
    // }

    // //if confirmPassword not match the newPassword
    // if (control === 'confirmPassword' &&
    //   this.passwordForm.get('newPassword').value !== this.passwordForm.get(control).value &&
    //   (this.passwordForm.get(control).touched ||
    //     this.passwordForm.get(control).dirty)
    // ) {
    //   return this._translate.instant('auth.pw_match');
    // }

    // if (
    //   this.passwordForm.get(control).hasError('minlength') &&
    //   (this.passwordForm.get(control).touched ||
    //     this.passwordForm.get(control).dirty)
    // ) {
    //   return this._translate.instant('auth.password_alert', { value: '8', });
    // }

    // const passwordString = this.passwordForm.get(control).value;
    // const charCheckPattern = /^(?=.*[!@#$%^&*()_\-+=\[\]{}<>?|]).+$/;
    // const letterCheckPattern = /^(?=(.*[a-zA-Z])).+$/;
    // const digitCheckPattern = /^(?=.*\d).+$/;

    // if ((!charCheckPattern.test(passwordString)) &&
    //   (this.passwordForm.get(control).touched ||
    //     this.passwordForm.get(control).dirty)) {
    //   return this._translate.instant('auth.password_char_alert');       // alert for charCheck
    // } else if ((!letterCheckPattern.test(passwordString)) &&
    //   (this.passwordForm.get(control).touched ||
    //     this.passwordForm.get(control).dirty)) {
    //   return this._translate.instant('auth.password_letters_alert');   // alert for letterCheck
    // } else if ((!digitCheckPattern.test(passwordString)) &&
    //   (this.passwordForm.get(control).touched ||
    //     this.passwordForm.get(control).dirty)) {
    //   return this._translate.instant('auth.password_digits_alert');    // alert for digitCheck
    // }
  }

  // <----------------------------------------------------------------------------------------->

  checkUnsavedChanges() {
    if (this.signatureFile || this.profileFile) {
      this.globalService.hasUnsavedChanges = true;
    } else {
      this.globalService.hasUnsavedChanges = false;
    }
  }

  getImageResolution(file: File): Promise<{ width: number; height: number }> {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    return new Promise((res) => {
      reader.onload = (event) => {
        const image = new Image();
        image.src = event.target.result as string;
        image.onload = () => {
          res({ width: image.naturalWidth, height: image.naturalHeight });
        };
      };
    });
  }

  getImageDataUrl(file: File): Promise<string> {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    return new Promise((res) => {
      reader.onload = (event) => {
        res(event.target.result as string);
      };
    });
  }

  async validateImageFile(file: File, type: FileType) {
    const result = { correctFormat: true, correctSize: true, correctRes: true };

    // Correct image format...
    if (
      file.type !== "image/png" &&
      file.type !== "image/jpg" &&
      file.type !== "image/jpeg"
    )
      result.correctFormat = false;

    // Correct image size(for signature 100kb, for profile pic 5mb)...
    if (type === "profilePic" && file.size / (1024 * 1024) > 5)
      result.correctSize = false;
    if (type === "signature" && file.size / 1024 > 200)
      result.correctSize = false;

    // Signature image's resolution...
    if (type === "signature" && file.type.split("/")[0] === "image") {
      // Check for resolution...
      const { width, height } = await this.getImageResolution(file);

      if (width > 400 || height > 200) result.correctRes = false;
    }

    if (type === "signature") this.signatureError = result;
    if (type === "profilePic") this.profilePicError = result;
  }

  resetImagePicker(type: FileType, element: Element) {
    if (type === "signature" && !this.signatureFile) return;
    if (type === "profilePic" && !this.profileFile) return;

    if (type === "signature") {
      this.signatureFile = undefined;
      this.signatureBase64Url = undefined;
      this.signatureError = {
        correctFormat: true,
        correctSize: true,
        correctRes: true,
      };
    }
    if (type === "profilePic") {
      this.profileFile = undefined;
      this.profilePicError = {
        correctFormat: true,
        correctSize: true,
        correctRes: true,
      };
    }

    this.renderer.removeClass(element, "valid-image");
    this.renderer.removeClass(element, "invalid-image");

    this.checkUnsavedChanges();
  }

  resetValues() {
    // Reset signature and profile image picker fields...
    this.resetImagePicker("signature", this.signatureLabel.nativeElement);
    this.resetImagePicker("profilePic", this.profileLabel.nativeElement);
  }

  async onSelectFile(event: Event, type: FileType, element: Element) {
    const file = (event.target as HTMLInputElement).files[0];

    if (file) {
      // Resetting the fields...
      if (type === "signature") this.resetImagePicker("signature", element);
      if (type === "profilePic") this.resetImagePicker("profilePic", element);

      // Running the check if file format is correct or not...
      if (type === "signature") await this.validateImageFile(file, type);
      if (type === "profilePic") await this.validateImageFile(file, type);

      // Adding green or red border in the bottom...
      let condition = false;
      if (type === "signature") {
        condition =
          this.signatureError.correctFormat &&
          this.signatureError.correctSize &&
          this.signatureError.correctRes;
      } else {
        condition =
          this.profilePicError.correctFormat &&
          this.profilePicError.correctSize;
      }
      if (condition) {
        this.renderer.addClass(element, "valid-image");
      } else {
        this.renderer.addClass(element, "invalid-image");
      }

      // Storing the file(At end beacuse it will reflect the save button)...
      if (type === "signature") {
        this.signatureFile = file;
        // Without sanitization angular won't load more than 4-5 mb image data as base64 url...
        if (file.type.split("/")[0] === "image") {
          const base64Url = await this.getImageDataUrl(this.signatureFile);
          this.signatureBase64Url =
            this.sanitizer.bypassSecurityTrustUrl(base64Url);
        }
      } else {
        this.profileFile = file;
      }
      this.checkUnsavedChanges();
    }
  }

  async deleteImage(type: FileType) {
    // Delete from the database...
    if (
      type === "signature" &&
      this.currentUser.signatureURL &&
      this.currentUser.signatureURL !== ""
    ) {
      await this.httpService.DeleteSignature();
      this.currentUser.signatureURL = "";
      this.fireEvent.emit("success:settings.remove_succ_prof");
    }
    if (
      type === "profilePic" &&
      this.currentUser.photoURL &&
      this.currentUser.photoURL !== ""
    ) {
      await this.httpService.DeleteProfilePicture();
      this.currentUser.photoURL = "";
      this.fireEvent.emit("success:settings.remove_succ_prof");
    }

    this.checkUnsavedChanges();
    this.closeModal();
  }

  onClickDeleteFile(type: FileType) {
    if (type === "signature" && !this.currentUser.signatureURL) return;
    if (type === "profilePic" && !this.currentUser.photoURL) return;

    // Modal buttons, common for both cases...
    this.modalButtons = [
      {
        name: this._translate.instant("general.confirm"),
        color: "btn-primary",
        clickFn: () => this.deleteImage(type),
        loading: false,
      },
      {
        name: this._translate.instant("general.cancel"),
        color: "btn-primary",
        clickFn: this.closeModal,
        loading: false,
      },
    ];

    // Modal title and body...
    if (
      type === "signature" &&
      this.currentUser.signatureURL &&
      this.currentUser.signatureURL !== ""
    ) {
      this.modalTitle = this._translate.instant("settings.remove_signature");
      // prettier-ignore
      this.modalBody = `<i class="material-icons" style="color: red; font-size: 36px; height: 36px; margin-bottom: 15px">error_outline</i>
                        <h6><strong>${this._translate.instant('settings.remove_img_warn_sign')}</strong></h6>`;
      $("#userProfileCommonModal").modal("show");
    }
    if (
      type === "profilePic" &&
      this.currentUser.photoURL &&
      this.currentUser.photoURL !== ""
    ) {
      this.modalTitle = this._translate.instant("settings.remove_prof_picture");
      // prettier-ignore
      this.modalBody = `<i class="material-icons" style="color: red; font-size: 36px; height: 36px; margin-bottom: 15px">error_outline</i>
                        <h6><strong>${this._translate.instant('settings.remove_img_warn')}</strong></h6>`;
      $("#userProfileCommonModal").modal("show");
    }
  }

  closeModal() {
    $("#userProfileCommonModal").modal("hide");
  }

  changePasswordInputType(type: "currPswd" | "newPswd" | "confirmPswd") {
    if (type === "currPswd") this.showCurrPswd = !this.showCurrPswd;
    if (type === "newPswd") this.showNewPswd = !this.showNewPswd;
    if (type === "confirmPswd") this.showConfirmPswd = !this.showConfirmPswd;
  }

  onClickChangePassword() {
    this.showCurrPswd = false;
    this.showNewPswd = false;
    this.showConfirmPswd = false;
    this.invalidPassword = false;
    this.tooManyRequests = false;
    this.passwordForm.reset();
    $("#resetPasswordModal").modal("show");
  }

  closePswdModal() {
    $("#resetPasswordModal").modal("hide");
  }

  async onSaveImage() {
    if (this.disableSaveBtn || this.submitBtnLoading) return;

    this.submitBtnLoading = true;
    let signatureUrl: null;
    let profileUrl: null;
    if (this.signatureFile) {
      signatureUrl = await this.httpService.UploadSignature(this.signatureFile);
    }
    if (this.profileFile) {
      profileUrl = await this.httpService.UploadProfilePicture(
        this.profileFile
      );
    }

    const dataToBeSaved: { signatureURL?: string; photoURL?: string } = {};
    if (signatureUrl) dataToBeSaved.signatureURL = signatureUrl;
    if (profileUrl) dataToBeSaved.photoURL = profileUrl;

    // Save the data...

    try {
      await this.httpService.UpdateFacilitySettings2(null, dataToBeSaved);
      this.resetImagePicker("signature", this.signatureLabel.nativeElement);
      this.resetImagePicker("profilePic", this.profileLabel.nativeElement);
      this.checkUnsavedChanges();
      this.fireEvent.emit("fetch data");
      this.fireEvent.emit("success:settings.save_success");
    } catch (error) {
      console.log(error);
      this.fireEvent.emit("success:settings.save_fail");
    }
    this.submitBtnLoading = false;
  }

  async onSubmitPassword() {
    this.passwordChangeRetryCounter === 0;
    if (this.passwordChangeRetryCounter === 3) {
      this.tooManyRequests = true;
      this.invalidPassword = false;
      return;
    }
    this.invalidPassword = false;
    this.tooManyRequests = false;
    if (!this.passwordForm.valid) return;

    const value: {
      currentPassword: string;
      newPassword: string;
      confirmPassword: string;
    } = this.passwordForm.value;
    if (value.newPassword !== value.confirmPassword) return;

    // Check if the current password is correct or not...
    try {
      await this.authService.AuthenticateUser(
        this.currentUser.email,
        value.currentPassword
      );
    } catch (error) {
      console.log(error);
      this.passwordChangeRetryCounter++;
      if (error.originalError.code === 'auth/too-many-requests') {
        this.tooManyRequests = true;
      } else {
        this.invalidPassword = true;
      }
      return;
    }

    // If current password correct than save the data...
    try {
      await this.httpService.changeUserPassword(
        this.currentUser.userID,
        value.newPassword
      );
      this.fireEvent.emit("success:settings.pw_change_success");
      this.closePswdModal();
    } catch (error) {
      console.log(error);
      this.fireEvent.emit("success:settings.pw_change_fail");
    }
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }
}
