import { Injectable } from '@angular/core';
import * as cryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class EncryptionService {

  constructor() { }

  encrypt(value : string, key: string): string {
    return cryptoJS.AES.encrypt(value, key.trim()).toString();
  }

  decrypt(textToDecrypt : string, key: string): string{
    return cryptoJS.AES.decrypt(textToDecrypt, key.trim()).toString(cryptoJS.enc.Utf8);
  }

  // creates checksum for HTTP requests integrity
  createChecksum(_source: any) {
    return cryptoJS.SHA256(JSON.stringify(_source)).toString(cryptoJS.enc.Hex);
  }

  // Helper function for JWT decoding
  computedSHA256(_token: string, _secret: string) {
    return cryptoJS.HmacSHA256(_token, _secret).toString(cryptoJS.enc.Base64);
  }

  decodeJWTasSHA256(_token: string, _secret: string) {
    const [header, payload, signature] = _token.split('.');

    const decodedHeader = atob(header.replace(/-/g, "+").replace(/_/g, "/"));
    const decodedPayload = atob(payload.replace(/-/g, "+").replace(/_/g, "/"));
    const unsignedToken = `${header}.${payload}`;
    const computedSignature: string = this.computedSHA256(unsignedToken, _secret);

    return {decodedHeader, decodedPayload, signature, computedSignature}
  }
  
}
