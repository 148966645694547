import { style } from '@angular/animations';
import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Patient, SystemSettings, Test } from 'src/app/globals/globals';
import pdfMake from "../../../assets/js/bundles/export-tables/pdfmake.min.js";
import pdfFonts from '../../../assets/js/bundles/export-tables/vfs_fonts.js';
import { arial_unicodeBoldURL, arial_unicodeURL } from 'src/assets/fonts/japneseSupport.js';
import { getArialUnicodeURL, getArialUnicodeBoldURL } from 'src/assets/fonts/importJapaneseFonts.js'
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from '../global.service.js';
import { formatDate } from '@angular/common';
import { AlgorithmService, Results, TestSetup, SAMPLETYPE, TESTTYPE, WBCConcStd, ChamberStd, MorphCriteria, LES, KeyParameters, Debris_Scan } from '../../services/algorithm.service'
import { Custompipe } from 'src/app/pipes/pipes.module.js';
import { Subject, Subscription } from 'rxjs';
import jsPDF from 'jspdf';
import { HttpService } from '../http.service.js';
import { AuthService } from 'src/app/core/auth.service.js';
pdfMake.vfs = pdfFonts.pdfMake.vfs;


enum IndirectElements {
  wbcConcentration,
  testType,
  sampleTested,
  criteria,
  liquefaction,
  debris,
  appearance,
  viscosity,
  onlyTime,
  sampleEvaluation,
  stepType

}

@Injectable({
  providedIn: 'root'
})
export class ReportsService {


  flexibleReportSubscription$: Subscription;
  flexReportPopup$: Subject<string | number> = new Subject<string | number>();
  flexReportPreviewContent$: Subject<any> = new Subject<any>();
  addGridToImages: boolean = false;
  Pdfobj = null;
  who = 0
  count: number = 0
  ART_PREP_LimitedParams: string[] = ["dateTime", "volume", "pH", "debris", "wbcConcentration", "motileSpermConc", "progMotileSpermConc", "progMotileSpermConcA", "progMotileSpermConcB", "velocity", "spermMotilityIndex", "motileSperm", "totalPmsc"]
  apiEP: string;
  constructor(private translate: TranslateService, private _glob: GlobalService, private http: HttpClient, private _http: HttpService, private _authService: AuthService) {
    this._glob.langNotToTranslate()
    const hostname = window.location.hostname.toString().toLowerCase();
    if (hostname.includes("localhost")) {
      this.apiEP = "api_services";
    } else {
      this.apiEP = "https://additional.mes-data.app";
    }
  }

  // public get getDate(): string {
  //   console.log("JJJJJJJJJJJJJJJJJJJJJ", this.count);


  // }
  async onePageReport(data1: Test | any, settings: any, isDownload: boolean, ARTTestData?: any[]): Promise<void> {
    this.count = 0
    this.who = data1.testSettings.who
    const Dates = ["birthDate", "collectedTime", "receivedTime", "dateTime", "patientAge"]
    const matchingTitles = {
      patientFirstName: "patients.first_name",
      patientLastName: "patients.last_name",
      patientId: "patients.patient_id",
      patientAge: "test.age",
      birthDate: "test.birth_day_age",
      sampleId: "test.sample_id",
      sampleType: "test.test_type",
      collectedTime: "test.colected_date",
      receivedTime: "test.received_date",
      dateTime: 'test.test_time',
      abstinence: "test.abstinence",
      optional1: "settings.opt_1",
      optional2: "settings.opt_2",
      tester: "test.tester_name",
      appearance: "test.appearance",
      viscosity: "test.viscosity",
      liquefaction: "test.liquefaction",
      who: "test.criteria",
      testType: "test.sample_tested",
      volume: "test.volume",
      pH: "test.ph",
      debris: "test.debris",
      wbcConcentration: "test.wbc",
      concentration: "settings.concentration",
      totalMotilePRNP: this.who == 4 ? "settings.motility" : "manual.tot_pr",
      progressivePR: this.who == 4 ? "test.progressive_no_sign" : "manual.progressive_pr",
      non_progressive_np: this.who == 4 ? "test.non_prog_no_sign" : "manual.non_prog_motility_np",
      immotile: this.who == 4 ? "counter.immotile" : "test.immotility_no_sign",
      rapidProgressiveA: "manual.rapid_prog",
      slowProgressiveB: "manual.slow_prog",
      normalForms: "manual.normal",
      motileSpermConc: "settings.motile_conc",
      progMotileSpermConc: "settings.prog_mot",
      progMotileSpermConcA: "test.rapid_msc",
      progMotileSpermConcB: "test.slow_msc",
      fsc: "test.fsc",
      velocity: "test.velocity",
      spermCount: "settings.sperm",
      motileSperm: "settings.mot_spr_conc",
      totalPmsc: "test.tota_progmotilesperm",
      totalFsc: "test.tota_fsc",
      totalMorph: "test.tota_morph",
      spermMotilityIndex: "test.SMI",
      phone: "general.phone",
      reportEmail: "general.email",
      website: "settings.website",
      ...data1.testingMode == 2 && {
        sampleEvaluation: "motility_estimation.sample_evaluation",
        stepType: "test.step_test_type"
      }

    }
    const handleIndirectElements = {
      who: IndirectElements.criteria,
      wbcConcentration: IndirectElements.wbcConcentration,
      sampleType: IndirectElements.sampleTested,
      testType: IndirectElements.testType,
      liquefaction: IndirectElements.liquefaction,
      debris: IndirectElements.debris,
      appearance: IndirectElements.appearance,
      viscosity: IndirectElements.viscosity,
      dateTime: IndirectElements.onlyTime,
      sampleEvaluation: IndirectElements.sampleEvaluation,
      stepType: IndirectElements.stepType

    }
    const res = await this._http.getCollectionData("System", "defaultSettings")
    this._glob.defaults = res.data
    this._glob.ART_PREP_Results = ARTTestData
    this._glob.Fresh_Test_Data = data1
    const isFullART = () => {
      return ARTTestData[0] != undefined && ARTTestData[1] != undefined
    }
    if (ARTTestData != undefined) {
      if (isFullART()) {
        this._glob.Fresh_Test_Data = this._glob.ART_PREP_Results[1]
        this._glob.Fresh_Test_Data.sampleInformation = this._glob.ART_PREP_Results[0].sampleInformation
        this._glob.Fresh_Test_Data.dateTime = this._glob.ART_PREP_Results[0].dateTime
      }
      if (ARTTestData[0] != undefined) {
        this._glob.ART_PREP_Results[0] = { ...this._glob.switchdisplayValues(ARTTestData[0]), ...ARTTestData[0].sampleInformation }
        if (isFullART() == false)
          this._glob.Fresh_Test_Data = this._glob.ART_PREP_Results[0]
      }

      if (ARTTestData[1] != undefined) {
        this._glob.ART_PREP_Results[1] = { ...this._glob.switchdisplayValues(ARTTestData[1]), ...ARTTestData[1].sampleInformation }
        if (isFullART() == false)
          this._glob.Fresh_Test_Data = this._glob.ART_PREP_Results[1]
        if (this._glob.ART_PREP_Results[0] != undefined) {
          this._glob.Fresh_Test_Data.visualizationImages = this._glob.ART_PREP_Results[0].visualizationImages
          data1.visualizationImages = this._glob.ART_PREP_Results[0].visualizationImages
        }
      }
    }

    // pdfFonts.pdfMake.vfs['Arial_unicode'] = arial_unicodeURL
    // pdfFonts.pdfMake.vfs['Arial_unicode_bold'] = arial_unicodeBoldURL
    getArialUnicodeURL().then(fontURL => {
      pdfFonts.pdfMake.vfs['Arial_unicode'] = fontURL
    });
    getArialUnicodeBoldURL().then(fontBoldURL => {
      pdfFonts.pdfMake.vfs['Arial_unicode_bold'] = fontBoldURL
    });
    pdfMake.fonts = {
      Roboto: {
        normal: 'Roboto-Regular.ttf',
        bold: 'Roboto-Medium.ttf',
        italics: 'Roboto-Italic.ttf',
        bolditalics: 'Roboto-MediumItalic.ttf'
      },
      japneseFont: {
        normal: 'Arial_unicode',
        bold: 'Arial_unicode_bold',
        italics: 'Arial_unicode',
        bolditalics: 'Arial_unicode'
      },
    }
    //setting fonts
    let img, upArrow, downArrow
    this._glob.facilityHeader = settings
    const reportData: Test | any = { ...this._glob.switchdisplayValues(data1), ...data1.sampleInformation, ...data1.motilityEstimationData != undefined && data1.motilityEstimationData }
    console.log(reportData, "PLPLPLPLPLPLPLPPL");

    const problemLanguages = ["el", "es", "tr", "vi", "it"]
    const switchFont = ["ja", "tr"].includes(this.translate.currentLang) ? "japneseFont" : "Roboto"

    const makeTable = (dataForTable: { data: any, widths: any[] }, upperMargin?: number, noBorder?: boolean) => {
      console.log(dataForTable.data, "KKKKKKKKKKKKKKKKKKKKKKK");

      return {
        margin: [0, upperMargin ?? 0, 10, 0],
        table: {
          widths: dataForTable.widths,
          body: dataForTable.data
        },
        layout: noBorder == true ? "noBorders" : {

          hLineWidth: function (i, node) {
            if (i != 0)
              return 0.1;
            else
              return 0;
          },
          vLineWidth: function (i, node) {
            return 0;
          },
        }
      }
    }

    const getDateObject = (date, field) => {
      if (field == "birthDate" || field == "patientAge") {
        // console.log(object.birthDate, "]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]");

        return this.calculateAge({ birthDate: data1.birthDate }, data1)
      }
      else {
        const trimmedKey = field.substring(0, field.length - 4)
        const isOnlyTime: boolean = (reportData[`${trimmedKey}Date`] != undefined && reportData[`${trimmedKey}Date`] == "")
        if (date) {
          console.warn(date, trimmedKey, field, date instanceof Date)
          try {
            const _date = date.toDate()
            if (_date.toLocaleTimeString('it-IT') != '00:00:00')
              return formatDate(_date, isOnlyTime ? `N/A | ${this._glob.pcTimeFormat}` : this._glob.pcDateFormat + ' | ' + this._glob.pcTimeFormat, this.translate.currentLang)
            else
              return isOnlyTime ? 'N/A' : `${formatDate(_date, this._glob.pcDateFormat, this.translate.currentLang)} | N/A`
          } catch (error) {
            if (date instanceof Date) {
              if (date.toLocaleTimeString('it-IT') != '00:00:00')
                return formatDate(date, isOnlyTime ? `N/A | ${this._glob.pcTimeFormat}` : this._glob.pcDateFormat + ' | ' + this._glob.pcTimeFormat, this.translate.currentLang)
              else
                return isOnlyTime ? 'N/A' : `${formatDate(date, this._glob.pcDateFormat, this.translate.currentLang)} | N/A`

            } else {
              return date;
            }
          }
        } else {
          return "N/A"
        }
      }
    }

    const generateRowData = (fields: any[], object: any, isMainTable: boolean) => {
      console.log(fields, object, "QQQQQQQQQQQQQQQQQQQQ");

      const getRefvalue = (paramName) => {
        const refSettings = object.testSettings.referenceSettings
        return refSettings[paramName] != undefined && refSettings[paramName][0] != null ? refSettings[paramName][0] + " " + refSettings[paramName][1] : " "
      }

      const getValue = (currentElement, artTestObj?: any) => {
        if (currentElement == 'sampleEvaluation' && this._glob.Fresh_Test_Data.testingMode == 2)
          artTestObj = { ...artTestObj.motilityEstimationData != undefined && artTestObj.motilityEstimationData }
        if (Object.keys(handleIndirectElements).includes(currentElement) || (artTestObj != undefined && currentElement == "dateTime")) {

          return this.handlingIndirectParameters(handleIndirectElements[currentElement], artTestObj != undefined ? artTestObj[currentElement == "stepType" ? 'sampleType' : currentElement] : object[currentElement == "stepType" ? 'sampleType' : currentElement])
        }
        else {
          if (["patientFirstName", "patientLastName"].includes(currentElement))
            return this._glob.handlePatientName(artTestObj != undefined ? artTestObj[currentElement] : object[currentElement])
          // else if(currentElement == "reportEmail")
          //   return "balasubrahman@reproductivefertilitycenter.com"
          else
            return this._glob.handleSampleandOtherInfo(artTestObj != undefined ? artTestObj[currentElement] : object[currentElement])

        }
      }

      const optionals = { optional1: "testOptional1", optional2: "testOptional2" }

      const handleOptionals = (curr) => {
        if (Object.keys(optionals).includes(curr))
          return object.testSettings[optionals[curr]] != undefined && object.testSettings[optionals[curr]] != "" ? object.testSettings[optionals[curr]] + ':' : this.translateNreturn(matchingTitles[curr])
        else {
          console.log(curr);
          if (curr == "abstinence") {
            return `${this.translateNreturn(matchingTitles[curr], true)} ${this.translateNreturn("units.days", false, false, true)}`
          } else if (curr == "dateTime") {
            return this.translateNreturn('test.test_date')
          }
          // else if (this._authService.iOwMode == true && curr == "liquefaction") {
          //   // if iOw mode and the value is liquefaction then it show return merged title translateNreturn function is not needed
          //   return matchingTitles[curr]
          // }
          return this.translateNreturn(matchingTitles[curr])

        }
      }

      const getStatus = (refKey: string, value) => {
        const refValue = getRefvalue(refKey).split(" ")
        if (!refValue || this._glob.handlingnegSigns(value) == "N/A")
          return null

        switch (refValue[0]) {
          case "<=":
            if (!(value <= refValue[1]))
              return upArrow;
            break;
          case ">=":
            if (!(value >= refValue[1]))
              return downArrow;
            break;
          case "<":
            if (!(value < refValue[1]))
              return upArrow;
            break;
          case ">":
            if (!(value > refValue[1]))
              return downArrow;
            break;
          case "=":
            if (value > refValue[1])
              return upArrow;
            else if (value < refValue[1])
              return downArrow;
            break;
          default:
            return null;
            break;
        }
      }


      let data = []
      if (!isMainTable) {
        data = fields.reduce((prev, curr, index) => {
          console.log(getValue(curr), Dates, curr);
          // noWrap: curr != "reportEmail"
          return [...prev, [{ text: handleOptionals(curr), style: "anotherStyle", margin: [0, 2, 0, Object.keys(optionals).includes(curr) ? -1 : 0], noWrap: curr != "reportEmail" }, { text: !Dates.includes(curr) ? getValue(curr) != undefined ? getValue(curr) : 'N/A' : getDateObject(object[curr], curr), style: "anotherStyle", margin: [0, 2, 0, Object.keys(optionals).includes(curr) ? -1 : 0], ...Object.keys(optionals).includes(curr) && { font: 'japneseFont' } }]]
        }, [])
      } else {
        const getPercentageOrMillValue = (curr, artPrepObj?: any) => {
          if (curr.unit == "units.percent" || curr.unit == "---" || curr.unit == "units.mic_sec")
            return this._glob.handlingnegSigns(artPrepObj ? artPrepObj[curr.value] : object[curr.value], "motility")
          else
            return this._glob.handlingnegSigns(artPrepObj ? artPrepObj[curr.value] : object[curr.value])
        }
        data = fields.reduce((prev, curr, index) => {
          if (data1.testingMode != 2) {
            console.log("||||||||||", matchingTitles[curr.value]);

            const translatedTxt = matchingTitles[curr.value] == "settings.motility" ? this.translateNreturn("settings.motility", true).substring(0, this.translateNreturn("settings.motility", true).length - 3) : this.translateNreturn(matchingTitles[curr.value], true, false, curr.isLower, curr.spaceCount)
            return [...prev, [{ text: translatedTxt, style: "resultsParam" }, { text: curr.notaParameter == true ? getValue(curr.value) ?? 'N/A' : getPercentageOrMillValue(curr), style: "paraheader" }, { text: curr.unit != "---" ? this.translateNreturn(curr.unit, true, true, true) : curr.unit, style: "paraheader" }, { text: getRefvalue(curr.ref), style: "paraheader" }, { ...getStatus(curr.ref, object[curr.value]) == null ? { text: " " } : { image: getStatus(curr.ref, object[curr.value]), fit: [30, 30] }, style: 'paraheader' }]]
          } else {
            if (this.displayAllParams) {
              return [...prev, [{ text: this.translateNreturn(matchingTitles[curr.value], true, false, curr.isLower, curr.spaceCount), style: "resultsParam" }, { ...this._glob.ART_PREP_Results[0] != undefined && { text: curr.notaParameter == true ? getValue(curr.value, this._glob.ART_PREP_Results[0]) ?? 'N/A' : getPercentageOrMillValue(curr, this._glob.ART_PREP_Results[0]), style: "paraheader" } }, { ...this._glob.ART_PREP_Results[1] != undefined && { text: curr.notaParameter == true ? getValue(curr.value, this._glob.ART_PREP_Results[1]) ?? 'N/A' : getPercentageOrMillValue(curr, this._glob.ART_PREP_Results[1]), style: "paraheader" } }, { text: curr.unit != "---" ? this.translateNreturn(curr.unit, true, true, true) : curr.unit, style: "paraheader" }].filter(el => Object.keys(el).length != 0)]
            } else {
              console.log(this._glob.ART_PREP_Results[1] != undefined);

              if (this.ART_PREP_LimitedParams.includes(curr.value))
                return [...prev, [{ text: this.translateNreturn(matchingTitles[curr.value], true, false, curr.isLower, curr.spaceCount), style: "resultsParam" }, { ...this._glob.ART_PREP_Results[0] != undefined && { text: curr.notaParameter == true ? getValue(curr.value, this._glob.ART_PREP_Results[0]) ?? 'N/A' : getPercentageOrMillValue(curr, this._glob.ART_PREP_Results[0]), style: "paraheader" } }, { ...this._glob.ART_PREP_Results[1] != undefined && { text: curr.notaParameter == true ? getValue(curr.value, this._glob.ART_PREP_Results[1]) ?? 'N/A' : getPercentageOrMillValue(curr, this._glob.ART_PREP_Results[1]), style: "paraheader" } }, { text: curr.unit != "---" ? this.translateNreturn(curr.unit, true, true, true) : curr.unit, style: "paraheader" }].filter(el => Object.keys(el).length != 0)]
              else
                return prev
            }
          }
        }, [])
      }
      console.log(data, "DDDDDDDDD-----");

      return data
    }

    const getImage = async (image) => {

      return this._glob.limitImageDims(image, 55).then((data1: any) => {
        console.log('image logo', data1.width)
        return {
          image: image,
          width: data1.width,
          height: data1.height,
        }

      })
    }

    const returnContent = async (imageTables) => {
      console.log("000000000000000000000000", imageTables);

      let mainTableHeaders = [{ text: this.translateNreturn("test.params", true), style: "tableHeader" }, { text: this.translateNreturn("test.result", true), style: "paraheader", bold: 'Helvetica-Bold' }, { text: this.translateNreturn("units.units", true), style: "paraheader", bold: 'Helvetica-Bold' }, { text: this.translateNreturn("settings.ref_value", true), style: "paraheader", bold: 'Helvetica-Bold' }, { text: this.translateNreturn("test.status", true), style: "paraheader", bold: 'Helvetica-Bold' }]
      if (data1.testingMode == 2) {
        if (this._glob.ART_PREP_Results[0] != undefined && this._glob.ART_PREP_Results[1] != undefined) {
          mainTableHeaders = [{ text: this.translateNreturn("test.params", true), style: "tableHeader" }, { text: this.translateNreturn("test_process.pre_prep", true), style: "paraheader", bold: 'Helvetica-Bold' }, { text: this.translateNreturn("test_process.post_prep", true), style: "paraheader", bold: 'Helvetica-Bold' }, { text: this.translateNreturn("units.units", true), style: "paraheader", bold: 'Helvetica-Bold' }]
        } else if (this._glob.ART_PREP_Results[0] != undefined || this._glob.ART_PREP_Results[1] != undefined) {
          if (this._glob.ART_PREP_Results[0] == undefined) {
            mainTableHeaders = [{ text: this.translateNreturn("test.params", true), style: "tableHeader" }, { text: this.translateNreturn("test_process.post_prep", true), style: "paraheader", bold: 'Helvetica-Bold' }, { text: this.translateNreturn("units.units", true), style: "paraheader", bold: 'Helvetica-Bold' }]
          } else {
            mainTableHeaders = [{ text: this.translateNreturn("test.params", true), style: "tableHeader" }, { text: this.translateNreturn("test_process.pre_prep", true), style: "paraheader", bold: 'Helvetica-Bold' }, { text: this.translateNreturn("units.units", true), style: "paraheader", bold: 'Helvetica-Bold' }]
          }
        }
      }
      console.log(mainTableHeaders, "::::::::::::::::::::::::::;");

      const getWho6andDebris = (section: number) => {
        if (section == 0) {
          return reportData.testSettings.debrisAssessment == true ? [{ value: "debris", unit: "---", notaParameter: true }] : []
        }
        if (reportData.testSettings.who != 4)
          return []
        if (section == 1) {
          return [{ value: "rapidProgressiveA", ref: "rapid_progressive_a", unit: "units.percent", spaceCount: 4 },
          { value: "slowProgressiveB", ref: "slow_progressive_b", unit: "units.percent", spaceCount: 4 }]
        } else if (section == 2) {
          return [
            { value: "progMotileSpermConcA", ref: "prog_motile_sperm_conc_a", unit: "units.M_ml", spaceCount: 4 },
            { value: "progMotileSpermConcB", ref: "prog_motile_sperm_conc_b", unit: "units.M_ml", spaceCount: 4 },
            { value: "fsc", ref: "fsc", unit: "units.M_ml" },
            { value: "velocity", ref: "velocity", unit: "units.mic_sec" },
          ]
        } else if (section == 3) {
          return [
            { value: "totalPmsc", ref: "totalPMSC", unit: "units.m_ejac" },
            { value: "totalFsc", ref: "totalFSC", unit: "units.m_ejac" },
            { value: "totalMorph", ref: "totalMorph", unit: "units.m_ejac" },
          ]
        }
      }

      return [
        //Header information
        // ...!settings.testSettings.reportSettings.standardPage.reportHeader ? [
        //   {
        //   alignment: 'justify',
        //   margin: [0, this.translate.currentLang == "ja" ? 10 : 15, -5, this.translate.currentLang == "ja" ? 0 : 10],
        //   opacity: 0.7,
        //   columns: [
        //     { width: 220, ...makeTable({ data: [...generateRowData(["phone", ...this._glob.facilityHeader.testSettings.reportSettings.standardPage.editEmail == "remove" ? [] : ["reportEmail"], "website"], { ...settings, ...settings.testSettings.reportSettings.standardPage }, false)], widths: this.leftHeaderTableWidths }, 0, true) },
        //     { width: 220, ...makeTable({ data: [[{ text: [{ text: `${this._glob.facilityHeader.facilityName}`, bold: true }, `\n${this._glob.getAddress()}`], style: "anotherStyle", colSpan: 2, lineHeight: 1.4, alignment: 'center', opacity: 0.3, }, { text: "" }]], widths: [70, 70] }, 0, true) },
        //     { width: '*', alignment: 'right', ...img }

        //   ]
        // }] : [],
        //main Heading
        { text: this._glob.unTranslate.includes(this.translate.currentLang) ? this.translate.instant('test.report_head') : this.translate.instant('test.report_head').toUpperCase().replace("SQA-IO", "SQA-iO").replace("SQA-iOW", "SQA-iOw"), style: "header", margin: [0, 0, 0, this.translate.currentLang == "ja" ? 0 : 10], opacity: 0.7 },
        // patient information
        {

          alignment: 'justify',
          columns: [
            // left table
            makeTable({
              data: [
                [{ text: this.translateNreturn("patients.patient_information", true), style: "tableHeader", colSpan: 2 }, { text: "" }],
                ...generateRowData([this.translate.currentLang == "ja" ? "patientLastName" : "patientFirstName", "patientId"], reportData, false),
              ],
              widths: ['*', 130]
            }),
            // right table
            makeTable(
              {
                data: [
                  [{ text: " ", style: "tableHeader", colSpan: 2 }, { text: "", }],
                  ...generateRowData([this.translate.currentLang == "ja" ? "patientFirstName" : "patientLastName"], reportData, false),
                  ...reportData.testSettings.patientAge == "Age" ? generateRowData(["patientAge"], reportData, false) : generateRowData(["birthDate"], reportData, false),
                ],
                widths: ['*', 130]
              }
            )
          ]
        },
        // Sample information 
        {
          alignment: 'justify',
          margin: [0, 10, 0, 0],
          columns: [
            // left table
            makeTable({
              data: [
                [{ text: this.translateNreturn("test.sample_info", true), style: "tableHeader", colSpan: 2 }, { text: "" }],
                ...generateRowData(["sampleId", "sampleType", "collectedTime", "receivedTime", "dateTime", "abstinence", ...this._glob.Fresh_Test_Data.sampleInformation.sampleType == 2 ? [] : ["optional1"], ...this._glob.Fresh_Test_Data.testingMode == 2 ? ["stepType"] : []].filter(el => {
                  if (this._glob.Fresh_Test_Data.sampleInformation.sampleType == 2 || this._glob.Fresh_Test_Data.sampleInformation.sampleType == 6)
                    return !['abstinence'].includes(el)
                  else
                    return true
                }), reportData, false),
              ],
              widths: ['*', 130]
            }

            ),
            // right table
            makeTable(
              {
                data: [
                  [{ text: " ", style: "tableHeader", colSpan: 2 }, { text: "", }],
                  ...generateRowData(["tester", "appearance", "viscosity", "liquefaction"].filter(el => {
                    if (this._glob.Fresh_Test_Data.sampleInformation.sampleType == 2 || this._glob.Fresh_Test_Data.sampleInformation.sampleType == 6)
                      return !["appearance", "viscosity", "liquefaction"].includes(el)
                    else
                      return true
                  }), reportData, false),
                  ...generateRowData(["who"], reportData.testSettings, false),
                  ...generateRowData(["testType", ...this._glob.Fresh_Test_Data.sampleInformation.sampleType == 2 ? ['optional1'] : [], "optional2"], reportData, false),
                ],
                widths: ['*', 130]
              }
            )
          ]
        },
        //Parameters Table

        makeTable(
          {
            data: [
              mainTableHeaders,
              ...generateRowData(
                [
                  ...this._glob.Fresh_Test_Data.testingMode == 2 ? [{ value: "dateTime", unit: "---", notaParameter: true }] : [],
                  { value: "volume", unit: "units.ml", notaParameter: true },
                  ...this._glob.Fresh_Test_Data.sampleInformation.sampleType != 2 ? [{ value: "pH", unit: "---", notaParameter: true, isLower: true }] : [],
                  ...(this._glob.Fresh_Test_Data.testingMode == 2 && this._glob.Fresh_Test_Data.prepType == 1) ? [] : [{ value: "wbcConcentration", unit: "units.M_ml", notaParameter: true }],
                  ...getWho6andDebris(0),
                  { value: "concentration", ref: "concentration", unit: "units.M_ml" },
                  { value: "totalMotilePRNP", ref: "total_motile_pr_np", unit: "units.percent" },
                  { value: "progressivePR", ref: "progressive_pr", unit: "units.percent", spaceCount: 2 },
                  ...getWho6andDebris(1),
                  { value: "non_progressive_np", ref: "non_progressive_np", unit: "units.percent", spaceCount: 2 },
                  { value: "immotile", ref: "immotile", unit: "units.percent" },
                  { value: "normalForms", ref: "normal_forms", unit: "units.percent" },
                  { value: "motileSpermConc", ref: "motile_sperm_conc", unit: "units.M_ml" },
                  { value: "progMotileSpermConc", ref: "prog_motile_sperm_conc", unit: "units.M_ml", spaceCount: 2 },
                  ...getWho6andDebris(2),
                  { value: "spermMotilityIndex", ref: "sperm_motility_index", unit: "---" },
                  { value: "spermCount", ref: "total_sperm_count", unit: "units.m_ejac" },
                  { value: "motileSperm", ref: "motile_sperm", unit: "units.m_ejac" },
                  ...getWho6andDebris(3),
                  ...this._glob.Fresh_Test_Data.testingMode == 2 ? [{ value: "sampleEvaluation", notaParameter: true, unit: "---" }] : []
                ].filter(el => {
                  if (this._authService.iOwMode == true) {
                    return !['spermMotilityIndex', 'velocity','volume',
                      'totalFsc', 'totalMorph', 'spermCount', 'motileSperm', 'totalPmsc'].includes(el.value)
                  } else {
                    if (settings.contentLimitation.hideParameters === true)
                      return !settings.resultsRestriction.includes(el.value)
                    else
                      return true
                  }


                }), { ...reportData, ...reportData.motilityEstimationData != undefined && reportData.motilityEstimationData }, true),
            ],
            ...data1.testingMode != 2 ? { widths: problemLanguages.includes(this.translate.currentLang) ? ['*', 80, 60, 50, 40] : ['*', this.translate.currentLang == "ja" ? 120 : 100, 70, 50, this.translate.currentLang == "ja" ? 55 : 40] } :
              { widths: ARTTestData[0] != undefined && ARTTestData[1] != undefined ? ['*', 80, 80, 80] : ['*', 80, 150] }
          },
          10
        ),
        // Mes parameters indication
        {
          margin: [0, 5, 0, 0],
          text: [
            { text: `${this.translateNreturn("test.mes_parameters_msg", true, false, true)} `, fontSize: 9.5, style: 'anotherStyle' }
          ]
        },

        // comments section
        {
          margin: [0, 10, 0, 0],
          text: [
            { text: `${this.translateNreturn("test.comments")} `, fontSize: 9.5, bold: 'Helvetica-Bold' }, { text: data1.comments ?? " ", lineHeight: 1.2, style: 'anotherStyle', fontSize: 9.5, font: 'japneseFont' }
          ]
        },
        ...imageTables != undefined ? imageTables : []



      ]
    }
    Promise.all([this._glob.getBase64ImageFromURL(this._glob.handleSampleandOtherInfo(this._glob.facilityHeader.logoURL) != "N/A" ? this._glob.facilityHeader.logoURL : "assets/images/home/home-logo.png"), this._glob.getBase64ImageFromURL("assets/images/test/upArrow.png"), this._glob.getBase64ImageFromURL("assets/images/test/downArrow.png")]).then(async res => {
      var attachImgurl = []
      var imageTables = []
      const getImagesTables = () => {
        if (data1.visualizationImages != undefined && data1.visualizationImages.length != 0) {
          return this._glob.idtoImageURL(data1.visualizationImages).then(async (data: any[]) => {
            attachImgurl = data.filter(el => el != undefined)
            return await this._glob.imagesPdfStructure(attachImgurl, getDateObject(data1.dateTime, "dateTime"))
          })
        } else {
          return []
        }
      }

      imageTables = await getImagesTables()
      console.log("Img.............", imageTables);
      img = await getImage(res[0])
      upArrow = res[1]
      downArrow = res[2]
      const getMIndication = () => {
        if (!this._glob.isManual) return ``
        if (this._glob.Fresh_Test_Data.countingMethod == 0) {
          if (this._glob.Fresh_Test_Data.countedMotilityFields !== undefined && this._glob.Fresh_Test_Data.countedMotilityFields.length > 0) {
            return `[MC] | `
          } else if (this._glob.Fresh_Test_Data.countedMorphologyFields !== undefined && this._glob.Fresh_Test_Data.countedMorphologyFields.length > 0) {
            return `[MM] | `
          }
        } else if (this._glob.Fresh_Test_Data.countingMethod == 1)
          return `[MA] | `
        else if (this._glob.Fresh_Test_Data.countingMethod == 4)
          return `[ME] | `
        else
          return ``
      }
      const getPrivacyMode = () => {
        const systemSettings: SystemSettings = this._glob.facilityHeader.testSettings.systemSettings
        if (systemSettings) {
          if (systemSettings.isPrivacyMode == true) {
            return `[PM] | `
          } else {
            return ``
          }
        } else {
          return ``
        }
      }
      const addr = this._glob.getAddress()
      let documentDef = {
        content: await returnContent(imageTables),
        header: (currentPage, pageCount, pageSize) => {
          //Address Section
          // const res=await getImage(img)
          // console.log("[[[[[[[[[[[[[[[[[[[[[[[[[[[[[", img, attachImgurl);
          if (currentPage == 1) {
            console.log('page 1', img, '.........', img.width, this.overTextHeaderTable)
            return !settings.testSettings.reportSettings.standardPage.reportHeader ?
              {
                alignment: 'right',
                margin: [this.overTextHeaderTable ? 10 : 20, this.translate.currentLang == "ja" ? 10 : 15, 20, this.translate.currentLang == "ja" ? 0 : 20],
                opacity: 0.5,
                columns: [
                  { width: this.overTextHeaderTable ? 'auto' : 195, ...makeTable({ data: [...generateRowData(["phone", ...(this._glob.facilityHeader.testSettings.reportSettings.standardPage.editEmail == "remove" && this._glob.facilityHeader.testSettings.reportSettings.standardPage.isEditEmail == true) ? [] : ["reportEmail"], "website"], { ...settings, ...settings.testSettings.reportSettings.standardPage }, false)], widths: this.leftHeaderTableWidths }, 0, true) },
                  { width: this.translate.currentLang == "vi" ? 180 : (this.overTextHeaderTable ? 165 : 200), ...makeTable({ data: [[{ text: [{ text: `${this._glob.facilityHeader.facilityName}`, bold: true }, { text: `\n${addr}` }], style: "anotherStyle", colSpan: 2, lineHeight: 1.4, alignment: 'center', opacity: 0.3, }, { text: "" }]], widths: [70, 70] }, 0, true) },
                  (img.width <= 120 && !this.overTextHeaderTable) ? { width: '*', alignment: 'right', ...this._glob.facilityHeader.testSettings.reportSettings.standardPage.displayPageNumber ? { columns: [{ ...img, alignment: 'right' }, { alignment: 'right', noWrap: true, text: `${this.translate.instant("test.page")}  ${currentPage}  ${this.translate.instant("test.of")}  ${pageCount} `, style: "pageNoText", ...this.translate.currentLang == 'el' && { fontSize: 8, } }] } : img } :
                    this._glob.facilityHeader.testSettings.reportSettings.standardPage.displayPageNumber ? [{ ...img }, { text: `${this.translate.instant("test.page")}  ${currentPage}  ${this.translate.instant("test.of")}  ${pageCount} `, style: "pageNoText", ...this.translate.currentLang == 'el' && { fontSize: 8, }, alignment: "right", margin: [0, 5, 0, 20] }] : img
                ]
              }
              : { text: this._glob.facilityHeader.testSettings.reportSettings.standardPage.displayPageNumber ? `${this.translate.instant("test.page")}  ${currentPage}  ${this.translate.instant("test.of")}  ${pageCount} ` : '', style: "headerText", margin: [10, 10, 30, 10], }
            // return {
            //   alignment: 'justify',
            //   margin: [20, this.translate.currentLang == "ja" ? 10 : 20, 20, this.translate.currentLang == "ja" ? 0 : 10],
            //   opacity: 0.7,
            //   columns: [
            //     { width: 220, ...makeTable({ data: [...generateRowData(["phone", ...this._glob.facilityHeader.testSettings.reportSettings.standardPage.editEmail == "remove" ? [] : ["reportEmail"], "website"], { ...settings, ...settings.testSettings.reportSettings.standardPage }, false)], widths: this.leftHeaderTableWidths }, 0, true) },
            //     { width: 220, ...makeTable({ data: [[{ text: [{ text: `${this._glob.facilityHeader.facilityName}`, bold: true }, `\n${this._glob.getAddress()}`], style: "anotherStyle", colSpan: 2, lineHeight: 1.4, alignment: 'center', opacity: 0.3, }, { text: "" }]], widths: [70, 70] }, 0, true) },
            //     { width: '*',alignment: 'right', ...this._glob.facilityHeader.testSettings.reportSettings.onePageDisplayPageNumber ? { columns: [{ alignment: 'left', ...img }, { alignment: 'left', text: `${this.translate.instant("test.page")}  ${currentPage}  ${this.translate.instant("test.of")}  ${pageCount} `, style: "headerText" }] } : img }

            //   ]
            // }
          } else {
            return { text: this._glob.facilityHeader.testSettings.reportSettings.standardPage.displayPageNumber ? `${this.translate.instant("test.page")}  ${currentPage}  ${this.translate.instant("test.of")}  ${pageCount} ` : '', style: "headerText", margin: [10, 10, 30, 10], }
          }
        },

        footer: (currentPage, pageCount, pageSize) => {
          const footerData = 'FAC ID#: ' + this._glob.facilityHeader.uid.substring(0, 6) + ' | SN# : ' + data1.rawData.data.serialNumber + ' | ' + getPrivacyMode() + getMIndication() + this.translate.instant('settings.conc_head') + ' ' + data1.testSettings.concentrationStd + ' | ' + formatDate(new Date(), this._glob.pcDateFormat + ' ' + this._glob.pcTimeFormat, this.translate.currentLang) + ' | AVG ' + data1.calRawData.data.average.toFixed(2) + ' | AW ' + data1.calRawData.data.aw + ' | CNT ' + data1.calRawData.data.count.toFixed(1) + ' | OD ' + data1.calRawData.data.od.toFixed(4)
          return { text: !settings.testSettings.reportSettings.standardPage.reportFooter ? footerData : '', style: "footerstyle" }
        },
        info: {
          title: 'Semen Analysis Test Report',
          author: 'MES',
        },
        pageMargins: [this.overTextHeaderTable ? 10 : 20, !settings.testSettings.reportSettings.standardPage.reportHeader ? (this.translate.currentLang == "ja" ? (27 * 2.83465) : ((addr.length > 90 ? 35 : 31) * 2.83465)) : (settings.testSettings.reportSettings.standardPage.headerSpace * 2.83465), this.overTextHeaderTable ? 10 : 20, !settings.testSettings.reportSettings.standardPage.reportFooter ? 10 : (settings.testSettings.reportSettings.standardPage.footerSpace * 2.83465)],
        styles: this.getAllStyles,
        defaultStyle: {
          font: switchFont,
        }
      }

      //Copied from old code
      try {
        var testDtdownload = formatDate((reportData.dateTime as any).toDate(), this._glob.pcDateFormat + ' ' + this._glob.pcTimeFormat, this.translate.currentLang)
      } catch (error) {
        testDtdownload = formatDate(reportData.dateTime, this._glob.pcDateFormat + ' ' + this._glob.pcTimeFormat, this.translate.currentLang)
      }
      if (isDownload) {
        if ((reportData.dateTime as any).seconds != undefined) {
          this.Pdfobj = pdfMake.createPdf(documentDef).download('Semen_Analysis_Test_Report_PID' + reportData.patientId + '_' + (testDtdownload.replace('/', '_')).replace(' ', '_') + '.pdf');
        } else {
          this.Pdfobj = pdfMake.createPdf(documentDef).download('Semen_Analysis_Test_Report_PID' + reportData.patientId + '_' + (testDtdownload.replace('/', '_')).replace(' ', '_') + '.pdf');
        }
      } else {
        this.Pdfobj = pdfMake.createPdf(documentDef, null, pdfMake.fonts).open();
      }
    })


  }


  public get leftHeaderTableWidths(): any[] {
    if (this.translate.currentLang == "en")
      return [40, '*']
    else if (this.translate.currentLang == "es")
      return [60, '*']
    else
      return [70, '*']
  }


  public get overTextHeaderTable(): boolean {
    console.log(this._glob.facilityHeader, this._glob.facilityHeader.website);

    const email = this._glob.facilityHeader.testSettings.reportSettings.standardPage.reportEmail
    if (email != undefined && email.length > 30)
      return true
    else if (this._glob.facilityHeader.website != undefined && this._glob.facilityHeader.website.length > 30)
      return true
    else
      return false


  }


  handlingIndirectParameters(param: IndirectElements, value: number | any) {
    switch (param) {
      case IndirectElements.criteria:
        return `WHO ${value + 2}th`
        break;
      case IndirectElements.testType:
        console.log("|||||||||||||||||||||||||||||||||||||||||", value);

        // let sampleTested = this.translate.instant("test_process.normal_volume")
        if (value == TESTTYPE.NORMAL)

          return this.translate.instant("test_process.normal_volume")
        else if (value == TESTTYPE.LOW_VOLUME)
          return this.translate.instant("test_process.10_micro")
        else if (value == TESTTYPE.DILUTED)
          return this.translate.instant("test.dilution")
        else
          return 'N/A'
        break;
      case IndirectElements.sampleTested:

        if (this._glob.Fresh_Test_Data.testingMode == 2) {
          return `${this.translate.instant("test_process.art_prep")}`
        } else {
          if (value == SAMPLETYPE.FRESH)
            return this.translate.instant("visualization.fresh")
          else if (value == SAMPLETYPE.WASHED)
            return this.translate.instant("visualization.washed")
          else if (value == SAMPLETYPE.SWIM_UP)
            return `${this.translate.instant("test.swimup")} / ${this.translate.instant("test.gradient")}`
        }

        break;
      case IndirectElements.wbcConcentration:
        if (this._glob.Fresh_Test_Data.prepType == 0 && value == "")
          return "N/A"
        if (value == WBCConcStd.WBC_CONC_NORMAL)
          return "< 1"
        else
          return ">=1"
        break;
      case IndirectElements.liquefaction:
        if (value == "Normal")
          return this.translate.instant('test_process.liquefaction_option_1');
        else if (value == "Abnormal")
          return this.translate.instant('test_process.liquefaction_option_2');
        else if (value == "Extreme")
          return this.translate.instant('test_process.liquefaction_option_3');
        break;
      case IndirectElements.appearance:
        if (value == "Normal")
          return this.translate.instant('test_process.normal');
        else if (value == "Abnormal")
          return this.translate.instant('test_process.abnormal');
        else if (value == undefined)
          return 'N/A'
        else if (this._glob.defaults.appearance[this.translate.currentLang].filter(el => el.value == value).length != 0)
          return this._glob.defaults.appearance[this.translate.currentLang].filter(el => el.value == value)[0].title
        else
          return value ?? "N/A"
        break;
      case IndirectElements.viscosity:
        if (value == "Normal")
          return this.translate.instant('test_process.normal');
        else if (value == "Abnormal")
          return this.translate.instant('test_process.abnormal');
        else if (value == "Decreased")
          return this.translate.instant('test_process.decreased');
        else "N/A"

      case IndirectElements.debris:
        if (value == Debris_Scan.LOW)
          return this.translate.instant('test_process.none_few_debris_report')
        else if (value == Debris_Scan.MODERATE)
          return this.translate.instant('test_process.moderate_debris_report')
        else if (value == Debris_Scan.HIGH)
          return this.translate.instant('test_process.many_debris_report')
        else if (value == Debris_Scan.GROSS)
          return this.translate.instant('test_process.gross_debris_report')
        break;
      case IndirectElements.onlyTime:
        console.log("OOOOOOOOOO", value, this._glob.ART_PREP_Results);

        try {
          return `${value.toDate().getHours()}:${value.toDate().getMinutes()}`
        } catch (error) {
          return `${value.getHours()}:${value.getMinutes()}`

        }
      case IndirectElements.sampleEvaluation:
        console.log("OIOIOOIOIOOIOIOIO", value);

        if (value != undefined) {
          if (value == "pass")
            return this.translateNreturn("general.pass", true)
          else if (value == "fail")
            return this.translateNreturn("general.fail", true)
          else
            return 'N/A'
        } else {
          return 'N/A'
        }
      case IndirectElements.stepType:
        let testType: string;
        if (value == SAMPLETYPE.FRESH)
          testType = this.translate.instant("visualization.fresh")
        else if (value == SAMPLETYPE.WASHED)
          testType = this.translate.instant("visualization.washed")
        else if (value == SAMPLETYPE.SWIM_UP)
          testType = `${this.translate.instant("test.swimup")} / ${this.translate.instant("test.gradient")}`
        if (this._glob.Fresh_Test_Data.testingMode == 2 && this._glob.ART_PREP_Results[0] != undefined && this._glob.ART_PREP_Results[1] != undefined)
          return testType.toUpperCase() + ' | ' + `${this.translate.instant("test.swimup").toUpperCase()} / ${this.translate.instant("test.gradient").toUpperCase()}`
        return testType.toUpperCase()
      default:
        break;
    }
  }

  translateNreturn(text: string, hasUnit?: boolean, isUnit?: boolean, isLower?: boolean, spaceCount?: number): string {
    let colon = ':'
    let beforeTitle = ""
    if (hasUnit)
      colon = ''
    if (text == ' ')
      return text
    if (spaceCount != undefined && this.who == 4) {
      for (let index = 0; index < spaceCount; index++) {
        beforeTitle += "\u200B\t"
      }
    }
    console.log(isUnit ? `${this.translate.instant(text).substring(1, this.translate.instant(text).length - 1)}${colon}` : `${isLower || this._glob.unTranslate.includes(this.translate.currentLang) ? beforeTitle + this.translate.instant(text) : beforeTitle + this.translate.instant(text).toUpperCase()}${colon}`);

    return isUnit ? `${this.translate.instant(text).substring(1, this.translate.instant(text).length - 1)}${colon}` : `${isLower || this._glob.unTranslate.includes(this.translate.currentLang) ? beforeTitle + this.translate.instant(text) : beforeTitle + this.translate.instant(text).toUpperCase()}${colon}`
  }

  getBase64ImageFromURL(url: string) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");

      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext("2d");
        ctx!.drawImage(img, 0, 0);

        var dataURL = canvas.toDataURL("image/png");

        resolve(dataURL);
      };

      img.onerror = error => {
        reject(error);
      };

      img.src = url;
    });
  }

  public get getAllStyles(): any {
    const fontSize = ["ja", "pr", "tr"].includes(this.translate.currentLang) ? [13, 8.5, 7.8, 6.7, 8.7, 7.5] : [14, 9.5, 8.8, 7.7, 9.7, 8.5]
    return {
      header: {
        fontSize: fontSize[0],
        bold: 'Helvetica-Bold',
        alignment: 'center',
        normal: 'Helvetica'
      },
      subhead: {
        fontSize: fontSize[1],
        margin: [0, 5, 0, 5],
        alignment: 'center',
        normal: 'Helvetica'
      },
      tabledata: {
        normal: 'Helvetica',
        fontSize: fontSize[2],
        color: 'black',
        alignment: 'center',
        margin: [0, 2, 0, 0],
      },
      footerstyle: {
        normal: 'Helvetica',
        fontSize: fontSize[3],
        color: 'grey',
        alignment: 'center',
        margin: [0, -8, 0, 0]
      },

      tableHeader: {
        normal: 'Helvetica',
        bold: 'Helvetica-Bold',
        fontSize: fontSize[2],
        color: 'black',
        uppercase: true
      },
      facilityHeader: {
        normal: 'Helvetica',
        bold: 'Helvetica-Bold',
        fontSize: fontSize[4],
        color: 'black',
        uppercase: true
      },

      anotherStyle: {
        alignment: 'left',
        // fontSize: this.smallFont ? 7.5 : 8.8,
        fontSize: fontSize[2],
        margin: [0, 2, 0, 0],
        normal: 'Helvetica',
      },
      resultsParam: {
        alignment: 'left',
        fontSize: fontSize[2],
        margin: [0, 2, 0, 0],
        normal: 'Helvetica',
      },

      footerTable: {
        alignment: 'left',
        fontSize: fontSize[2],
        normal: 'Helvetica',
      },

      paraheader: {
        alignment: 'center',
        normal: 'Helvetica',
        // bold: 'Helvetica-Bold',
        fontSize: fontSize[2],
        color: 'black',
        margin: [0, 1, 0, 0]
      },
      comments: {
        margin: [-20, 0, 0, 0],
      },
      sign: {
        margin: [-20, 0, 35, 0],
        alignment: 'left',
      },
      columnStyle: {
        margin: [0, 10, 0, 0]
      },
      pageNoText: {
        fontSize: fontSize[5],
        margin: [0, 15, 0, 10],
      },
      headerText: {
        fontSize: fontSize[5],
        alignment: "right",
        margin: [10, 15, 0, 10],
      },
      reportContinueText: {
        fontSize: 10,
        alignment: "left",
      },
      smi_Bullets: {
        margin: [6, 5, 0, 0],
        lineHeight: false ? 1.4 : 1.1,
        style: 'anotherStyle',
        fontSize: 10.5
      }
    }
  }
  calculateAge(Patient_Info, Fresh_Test_Data: Test) {
    let dateandAge = ""
    if (this._glob.facilityHeader.testSettings.systemSettings.isPrivacyMode == true)
      return dateandAge
    if (Fresh_Test_Data.testSettings.patientAge == "BirthDate") {
      if (Patient_Info.birthDate && Patient_Info.birthDate !== "" && Patient_Info.birthDate !== undefined) {
        var birthDate: any = 'N/A'
        try {
          var formattedBday: any = formatDate(Patient_Info.birthDate.toDate(), 'dd/MM/yyyy', 'en')
          birthDate = formatDate(Patient_Info.birthDate.toDate(), this._glob.pcDateFormat, this.translate.currentLang, 'UTC')
        } catch (error) {
          var formattedBday: any = formatDate(Patient_Info.birthDate, 'dd/MM/yyyy', 'en')
          birthDate = formatDate(Patient_Info.birthDate, this._glob.pcDateFormat, this.translate.currentLang, 'UTC')

        }
        var bday = new Date(formattedBday.substr(formattedBday.length - 4), formattedBday.substr(3, 2) - 1, formattedBday.substr(0, 2));
        var timeDiff = Math.abs(Date.now() - bday.getTime());
        let age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
        dateandAge = birthDate + " | " + age.toString();
      } else {
        dateandAge = 'N/A'
      }
    }
    else {
      if (Fresh_Test_Data.sampleInformation.patientAge)
        dateandAge = Fresh_Test_Data.sampleInformation.patientAge.toString()
      else
        dateandAge = 'N/A'
    }
    return dateandAge
  }

  public limitImageDims(imgUrl, setHeight) {
    return new Promise<any>((res, rej) => {
      this.getImageDims(imgUrl).then(dimensions => {
        // console.log('returned img dims', dimensions);
        let setWidth = Math.round(setHeight * dimensions.width / dimensions.height);
        // console.log(`new dimenstions: ${setWidth} x ${setHeight}`);
        res({ width: setWidth, height: setHeight });
      }).catch(err => {
        // console.log('img dims error', err);
        rej(err);
      });
    });


  }

  getImageDims(imgUrl) {

    let img = new Image();
    return new Promise<any>((res, rej) => {
      img.src = imgUrl;
      img.onload = () => {
        let imgWidth = img.width;
        let imgHeight = img.height;
        // console.log('height: ' + imgHeight);
        // console.log('width: ' + imgWidth);
        // return [imgHeight, imgWidth];
        res({ width: imgWidth, height: imgHeight });
      };
      img.onerror = (err) => {
        rej(err);
      }
    });
  }

  // ************************* Flexible report request ***********************

  async getFlexibleReport(testData: any, reportFileName: string, isDownload: boolean, facilityID?: string): Promise<void> {


    let progress: number = 0;
    let _images: any[] = [];
    let targetHTMLstring: string;
    const allowedFileTypes: Array<string> = ['html', 'htm', 'shtml', 'ehtml'];

    // Return an error if file type is not HTML
    if (!allowedFileTypes.includes(reportFileName.split('.')[1])) {
      this.flexReportPopup$.next('error');
      return;
    };
    this.flexReportPopup$.next('report');

    // Helper process to handles test images
    const createImagesSection = (originalHTML: Document, imagesArray: Array<any>): string => {

      const DOMImages = Array.from(originalHTML.querySelectorAll('.test-image'));
      for (const _domImage of DOMImages as HTMLImageElement[]) {
        let _index = Number(_domImage['dataset']?.image) - 1;
        if (imagesArray[_index]) {
          _domImage.src = imagesArray[_index].imageCanvas;
          _domImage.style.border = '2px solid black';
        }
      };

      const targetHtml = originalHTML.documentElement.outerHTML;
      return targetHtml;
    }

    if (testData !== 'preview') this._glob.makeChartfromValues(testData);
    for (const _image of testData.visualizationImages || []) {
      if (_image.isattached) {
        let imageUrl = await this._http.getFileUrl(`Visualization/Images/${testData.facilityId}/${_image.fileName}`);
        // let imageCanvas = await this._glob.getBase64ImageFromURLRotated(imageUrl);
        let imageCanvas = await this._glob.drawImage(imageUrl, null, null, testData.reportImageGrid);
        _images.push({ imageCanvas: imageCanvas['url'], _image })
      }
    };
    let reportPayload = {
      userID: this._http.currentUserId,
      facilityID: facilityID ? facilityID : testData.facilityId,
      patientID: testData.patientId,
      testID: testData === 'preview' ? 'preview' : testData.id,
      fileName: reportFileName,
      chartURL: this._glob.chartURL,
      locale: this.translate.currentLang,
      timeZoneData: {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        timeZoneLocal: Intl.DateTimeFormat().resolvedOptions().locale,
        localFormat: this._glob.pcDateFormat
      }
    }

    this.flexibleReportSubscription$ = this.http.post(`${this.apiEP}/getFileFromStorage`,
      reportPayload,
      {
        observe: 'events',
        reportProgress: true
      }).subscribe(event => {
        let blobUrl;
        if (event.type === HttpEventType.DownloadProgress) {
          progress = Math.round(100 * event.loaded / event.total)
          // console.log(`Report complete: ${progress}%`);
          this.flexReportPopup$.next(progress);
        } else if (event.type === HttpEventType.Response) {
          let doc = new jsPDF("portrait", "px", "a4");

          let processedDOM = event.body['data'].htmlContent;
          const parser = new DOMParser();
          const originalHTML = parser.parseFromString(processedDOM, 'text/html');
          try {
            targetHTMLstring = createImagesSection(originalHTML, _images);
          } catch (error) {
            this.flexReportPopup$.next('error');
          }
          doc.html(targetHTMLstring, {
            callback: (pdf) => {
              if (isDownload === true) {
                let reportDateFileName = formatDate((testData.dateTime as any).toDate(), this._glob.pcDateFormat + ' ' + this._glob.pcTimeFormat, this.translate.currentLang);
                pdf.save(`Semen_Analysis_Test_Report_PID_${testData.patientId}_${reportDateFileName}`);
                this.flexReportPopup$.next('close');
              } else if (isDownload === false) {
                if (testData === 'preview') {
                  this.flexReportPopup$.next('close');
                  const previewContent = pdf.output('datauristring');
                  this.flexReportPreviewContent$.next(previewContent);
                } else {
                  this.flexReportPopup$.next('close');
                  pdf.setProperties({ title: 'Semen Analysis Test Report' });
                  const _blob = pdf.output('blob');
                  blobUrl = URL.createObjectURL(_blob);
                  window.open(blobUrl, '_blank');
                }
              }
            }
          }).finally(() => {
            // jsPDF instance must be cleared on each service call
            doc = null;
            URL.revokeObjectURL(blobUrl);
            this.flexibleReportSubscription$.unsubscribe();
          })
        };
      },
        error => {
          console.error('Flexible report error:', error);
          this.flexReportPopup$.next('error');
          this.flexibleReportSubscription$.unsubscribe();
        }
      );

  }

  public get displayAllParams(): boolean {
    console.log("GGGGGGGGGGGGGGGGGGGGGG", this._glob.ART_PREP_Results, this._glob.displayAllParams);
    return this._glob.displayAllParams
    // if (this._glob.ART_PREP_Results[0] != undefined && this._glob.ART_PREP_Results[1] != undefined) {
    //   return this._glob.ART_PREP_Results[0].mconcentration != undefined || this._glob.ART_PREP_Results[1].mconcentration != undefined
    // } else if (this._glob.ART_PREP_Results[0] != undefined || this._glob.ART_PREP_Results[1] != undefined) {
    //   if (this._glob.ART_PREP_Results[0] != undefined) {
    //     return this._glob.ART_PREP_Results[0].mconcentration != undefined
    //   } else if (this._glob.ART_PREP_Results[1] != undefined) {
    //     return this._glob.ART_PREP_Results[1].mconcentration != undefined
    //   }
    // }

  }

}
