import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AuthService } from "src/app/core/auth.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { HttpService } from "src/app/services/http.service";
import { take } from "rxjs/operators";
import { EncryptionService } from "src/app/services/encryption.service";

@Component({
  selector: "app-otp-verification",
  templateUrl: "./otp-verification.component.html",
  styleUrls: ["./otp-verification.component.sass"],
})
export class OtpVerificationComponent implements OnInit {
  @Input() emailType: string = "";
  @Output() OTPsubmitEvent = new EventEmitter<boolean>();
  @Output() mailSendCounterEvent = new EventEmitter<number>();

  codeForm: FormGroup;
  email: string = "";
  vCode: string = "";
  iOwMode: boolean = false;
  wrongCode: boolean = false;
  isBtnDisabled: boolean = false;
  isSending: boolean = false;
  submitted: boolean = false;
  showButtonProgressBar: boolean = false
  counter: number = 0;
  userDetails: any;

  constructor(
    private authService: AuthService,
    private httpService: HttpService,
    private formBuilder: FormBuilder,
    private encryptionService: EncryptionService,
  ) {}

  ngOnInit(): void {
    this.userDetails = this.authService.getUserData();
    console.warn(this.userDetails);
    this.codeForm = this.formBuilder.group({
      codeInput: ["", [Validators.required, Validators.minLength(6)]],
    });
    switch (this.emailType) {
      case "MFAlogin":
        this.email = this.userDetails.username;
        this.sendMFAuthMail();
        break;
        case "signup":
        this.email = this.userDetails.email;
        this.resendEmail();
        break;
      case "userChange":
        break;
      default:
        break;
    }
  }

  get f() { return this.codeForm.controls; }

  // Send email with OTP for Multi Factor authentication
  sendMFAuthMail() {
    if (!this.isSending) {
      this.isSending = true;
      this.httpService
        .sendMFALoginVerificationMail(this.email, this.iOwMode)
        .pipe(take(1))
        .subscribe((response) => {
          let encryptedOTP = response["OTP"];
          let OTP = this.encryptionService.decrypt(
            encryptedOTP,
            this.authService.currentUserIP
          );
          this.vCode = OTP;
          if (this.counter > 0) {
            console.warn('Counter: ', this.counter)
            this.mailSendCounterEvent.emit(this.counter);
          }
          this.counter = this.counter + 1;
          this.isSending = false;
        });
    }
  }

  // Send email with OTP to complete new user registration
  resendEmail() {
    if (!this.isSending) {
      this.isSending = true;

      this.httpService
        .sendVerificationEmail(this.email, this.iOwMode, this.userDetails.username)
        .then((response) => {
          let OTP = response;
          this.vCode = OTP;
          if (this.counter > 0) {
            this.mailSendCounterEvent.emit(this.counter);
          }
          this.counter = this.counter + 1;
          this.isSending = false;
        })
        .catch((error) => {
          this.isSending = false;
          console.error("Verification mail send - failed: ", error);
        });
    }
  }

  onSubmit() {
    this.submitted = true;
    this.isBtnDisabled = true;
    this.showButtonProgressBar = true;
    let _codeInput = this.codeForm.value.codeInput;

    if (this.codeForm.invalid) {
      this.showButtonProgressBar = false;
      this.isBtnDisabled = false;
      return;
    }  

    if (_codeInput === this.vCode) {
      this.OTPsubmitEvent.emit(true);
    } else {
      this.wrongCode = true;
      this.submitted = false;
      this.isBtnDisabled = false;
      this.showButtonProgressBar = false;
      this.OTPsubmitEvent.emit(false);
    }
  }

  // Allow code input of digits only
  blockCharforNumberInput(evt) {
    if (this.wrongCode) !this.wrongCode;
    var charCode = evt.keyCode;
    if (!(charCode >= 48 && charCode <= 57)) {
      evt.preventDefault();
    }
  }

  // reset code state in case of input retry
  resetCodeState() {
    if (this.wrongCode === true) this.wrongCode = false;
  }
}
