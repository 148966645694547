import { HttpClient } from '@angular/common/http';
import { Component, OnInit, OnDestroy, ChangeDetectorRef, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { HttpService } from 'src/app/services/http.service';
import { ReportsService } from 'src/app/services/reports/reports.service';

declare const $: any;

@Component({
  selector: 'app-flexible-report-popup',
  templateUrl: './flexible-report-popup.component.html',
  styleUrls: ['./flexible-report-popup.component.sass'],
})
export class FlexibleReportPopupComponent implements OnInit, OnDestroy {

  showPopup: boolean = false;
  reportError: boolean = false;
  progressValue: number = 0;
  currentLanguage: string;
  flexReportPopupSubscription$: Subscription;
  

  constructor ( private reportsService: ReportsService, 
                private httpClient: HttpClient,
                private httpService: HttpService, 
                private trsnlateService: TranslateService,
                private cdr: ChangeDetectorRef, 
                private elementRef: ElementRef) { }

  ngOnInit(): void {
    this.currentLanguage = this.trsnlateService.currentLang;
    this.flexReportPopupSubscription$ = this.reportsService.flexReportPopup$.subscribe((res: string) => {
      if (res === 'report') {
        this.showPopup = true;
      } else if (res === 'close') {
        this.showPopup = false;
      } else if (res === 'error') {
        this.showPopup = true;
        this.reportError = true;
      } else if (typeof(res) === 'number') {
        this.progressValue = res;
        console.log(`Report complete: ${this.progressValue}%, ${res}`);
        // this.cdr.detectChanges();
      }
      this.cdr.detectChanges();

      // In case of error pop-up active, handle link to instructions download
      const instructionsLink = this.elementRef.nativeElement.querySelector(".instructions-link");

      if (instructionsLink) {
        instructionsLink.addEventListener('click', this.downloadInstructions.bind(this));
        instructionsLink.style.color = '#0022FF';
        instructionsLink.style.textDecoration = 'underline';
        instructionsLink.style.cursor = 'pointer';
      };

      if (this.showPopup === true) {
        $('#flex-report-modal').modal('show');
      } else {
        $('#flex-report-modal').modal('hide');
        var $body = $("body");
        $body.removeAttr('style');
      }
    });
  }
  
  downloadInstructions(): void {
    const fileName: string = 
      this.httpService.currentWHOSetting === 3 ? 'Flex_Report_User_Guide_WHO5.pdf' : 'Flex_Report_User_Guide_WHO6.pdf';
    this.httpService
    .getFileUrl(`Manuals/${this.currentLanguage}/${fileName}`)
    .then((res: any) => {
      this.httpClient.get(res, { responseType: 'blob'}).subscribe(_blob => {
        const a = document.createElement("a");
        const objectUrl = URL.createObjectURL(_blob);
        a.href = objectUrl;
        a.download = fileName;
        a.click();
        URL.revokeObjectURL(objectUrl);
      });
    })
  }
 
  onClose() {
    this.showPopup = false;
    this.reportError = false;
    this.progressValue = 0;
    $('#flex-report-modal').modal('hide');
    this.reportsService.flexReportPopup$.next('close');
  }

  ngOnDestroy(): void {

    this.flexReportPopupSubscription$.unsubscribe();
  }

}
