export const DeviceState = {
  START: 0,
  IN_PROCESS: 1,
  FINISHED: 2,
};

export const Customer = {
  currentFacilityID: null,
};

export const WHO_5_REF: References = {
  concentration: [">=", 15.0],
  total_motile_pr_np: [">=", 40],
  progressive_pr: [">=", 32],
  // prog_motile_sperm_conc: ['>=', 40],
  normal_forms: [">=", 4.0],
  total_sperm_count: [">=", 39.0],
  // total_motile_sperm: 000,
  // motile_sperm_conc: 000,
  // pmsc: 32.0,
  use_WHO_RefValue_def: true,
};

export const WHO_6_REF: References = {
  concentration: [">=", 16],
  // motility: 000,
  total_motile_pr_np: [">=", 42],
  progressive_pr: [">=", 30],
  // prog_motile_sperm_conc_a: ['>=', 20],
  // prog_motile_sperm_conc_b: ['>=', 20],
  non_progressive_np: ["<=", 1],
  immotile: ["<=", 20],
  normal_forms: [">=", 4],
  total_sperm_count: [">=", 39],
  velocity: [">=", 5],
  // total_motile_sperm: 000,
  // motile_sperm_conc: 000,
  // pmsc: 20.0,
  use_WHO_RefValue_def: true,
};

export const WHO_3_4_REF = {
  concentration: [">=", 20.0],
  // motility: 000,
  prog_motile_sperm_conc_a: [">=", 20],
  prog_motile_sperm_conc_b: [">=", 20],
  // normal_forms: ['>=', 40],
  total_sperm_count: [">=", 40.0],
  sperm_motility_index: [">=", 80],
  // total_motile_sperm: 000,
  // motile_sperm_conc: 000,
  // pmsc: 20.0,
};

export enum ReportType {
  ONE_PAGE_REPORT = 1,
  GRAPH_REPORT = 2,
  FLEXIBLE_REPORT = 3,
}

export enum Appearnce {
  specifySampleColor = 1,
  normalAbnormal,
}

export const defaultReportSettings = {
  reportType: ReportType.GRAPH_REPORT,
  standardPage: {
    displayPageNumber: true,
    editEmail: "remove",
    reportEmail: "",
    isEditEmail: false,
    reportHeader: false,
    reportFooter: false,
    headerSpace: 10,
    footerSpace: 10,
  },
  firstPage: {
    displayPageNumber: true,
    editEmail: "remove",
    reportEmail: "",
    isEditEmail: false,
    removeSignature: false,
    removeTester: false,
    reportHeader: false,
    reportFooter: false,
    headerSpace: 10,
    footerSpace: 10,
    removeComment: false,
  },
  secondPage: {
    displayPageNumber: true,
    removeSignature: false,
    removeTester: false,
    reportHeader: false,
    reportFooter: false,
    headerSpace: 10,
    footerSpace: 10,
    additionalText: 0,
  },
  selectedFecitlityHtmlFile: "select",
};

export const defaultReportSettingsUS = {
  reportType: ReportType.GRAPH_REPORT,
  standardPage: {
    displayPageNumber: true,
    editEmail: "remove",
    reportEmail: "",
    isEditEmail: false,
    reportHeader: false,
    reportFooter: false,
    headerSpace: 10,
    footerSpace: 10,
  },
  firstPage: {
    displayPageNumber: true,
    editEmail: "remove",
    reportEmail: "",
    isEditEmail: false,
    removeSignature: false,
    removeTester: false,
    reportHeader: false,
    reportFooter: false,
    headerSpace: 10,
    footerSpace: 10,
    removeComment: false,
  },
  secondPage: {
    displayPageNumber: true,
    removeSignature: false,
    removeTester: false,
    reportHeader: false,
    reportFooter: false,
    headerSpace: 10,
    footerSpace: 10,
    additionalText: 0,
  },
  selectedFecitlityHtmlFile: "select",
};

export const defaultSystemSettings: SystemSettings = {
  playSound: true,
  autoLogOut: true,
  autoLogOutValue: 60,
  disableSelfTest: false,
  multiFactorAuthActive: false,
  archivePageHistory: false,
  // addLIS: false,
  LISValue: "",
  autoOpenLQCounter: true,
  /*************privacy mode variables**********/
  // disableFirstLastName: false,
  // disableBirthDateAge: false,
  removePatientPhone: false,
  isPrivacyMode: false,
};

export const defaultSystemSettingsUS: SystemSettings = {
  playSound: true,
  autoLogOut: true,
  autoLogOutValue: 60,
  disableSelfTest: false,
  multiFactorAuthActive: false,
  archivePageHistory: false,
  // addLIS: false,
  LISValue: "",
  autoOpenLQCounter: true,
  /*************privacy mode variables**********/
  // disableFirstLastName: false,
  // disableBirthDateAge: false,
  removePatientPhone: true,
  isPrivacyMode: false,
};

export const defaultVUSettings: VUSettings = {
  openLQAuto: true,
  downloadJPEGImg: "png",
  downloadXXVid: "mp4_pc",
};

export const defaultTestSettings: TestSettings = {
  concentrationStd: 2,
  who: 4,
  patientAge: "Age",
  debrisAssessment: false,
  reportSettings: defaultReportSettings,
  referenceSettings: WHO_6_REF,
  playSound: true,
  weightUnit: "kg",
  heightUnit: "cm",
  testOptional1: "",
  testOptional2: "",
  morphUpperLimit: 20,
  facilityOptional1: "",
  facilityOptional2: "",
  numberOfAllowImages: 10,
  numberOfAllowVideos: 10,
  saveVideoLength: 10,
  systemSettings: defaultSystemSettings,
  appearance: Appearnce.normalAbnormal,
  VUSettings: defaultVUSettings,
};

export const defaultTestSettingsUS: TestSettings = {
  concentrationStd: 2,
  who: 4,
  patientAge: "BirthDate",
  debrisAssessment: true,
  reportSettings: defaultReportSettingsUS,
  referenceSettings: WHO_6_REF,
  playSound: true,
  weightUnit: "lb",
  heightUnit: "ft",
  testOptional1: "",
  testOptional2: "",
  morphUpperLimit: 10,
  facilityOptional1: "",
  facilityOptional2: "",
  numberOfAllowImages: 10,
  numberOfAllowVideos: 10,
  saveVideoLength: 10,
  systemSettings: defaultSystemSettingsUS,
  appearance: Appearnce.normalAbnormal,
  VUSettings: defaultVUSettings,
};

export interface References {
  concentration?: (string | number)[];
  motility?: (string | number)[];
  total_motile_pr_np?: (string | number)[];
  progressive_pr?: (string | number)[];
  prog_motile_sperm_conc?: (string | number)[];
  prog_motile_sperm_conc_a?: (string | number)[];
  prog_motile_sperm_conc_b?: (string | number)[];
  rapid_progressive_a?: (string | number)[];
  slow_progressive_b?: (string | number)[];
  immotile?: (string | number)[];
  non_progressive_np?: (string | number)[];
  normal_forms?: (string | number)[];
  total_sperm_count?: (string | number)[];
  sperm_motility_index?: (string | number)[];
  motile_sperm_conc?: (string | number)[];
  motile_sperm?: (string | number)[];
  fsc?: (string | number)[];
  velocity?: (string | number)[];
  totalFSC?: (string | number)[];
  totalPMSC?: (string | number)[];
  totalMorph?: (string | number)[];
  // prog_motile_sperm?: (string | number)[];
  use_WHO_RefValue_def?: boolean;
}

// export let CurrentUser = {
//   uid: '',
//   email: '',
//   photoURL: '',
//   doctorName: '',
//   facilityName: '',
//   phone: '',
//   website: '',
//   country: '',
//   city: '',
//   zipCode: '',
//   streetNo: '',
//   testCredits: 0,
//   designation: '',
//   optional1: '',
//   optional2: '',
//   testSettings: {},
// };

export interface SQACustomer {
  htmlURL?: string;
  photoURL?: string;
  logoURL?: string;
  signatureURL?: string;
  doctorName?: string;
  facilityName?: string;
  phone?: string;
  website?: string;
  country?: string;
  contentLimitation?: any;
  state?: string;
  city?: string;
  zipCode?: string;
  streetNo?: string;
  testCredits?: number;
  designation?: string;
  optional1?: string;
  optional2?: string;
  testSettings?: TestSettings;
  deviceSN?: string;
  patientCount?: number;
  testCount?: number;
  resultsRestriction?: string[];
  totalTestCount?: number;
  requestCount?: number;
  customerType?: string;
  lastMaintenance?: DeviceMaintenance;
  creationTime?: Date;
  lastSignInTime?: Date;
  totalCredits?: any;
  geoCity?: string;
  geoCountry?: string;
  lastNotification?: Date;
  lastQC?: LatexBeadsData;
  qcTestCount?: number;
  lastProficiency?: any;
  proficiencyTestCount?: number;
  email?: string;
  systemCountry?: string;
}

export interface SQAUser {
  photoURL?: string;
  signatureURL?: string;
  doctorName?: string;
  email?: string;
  facilityID?: string;
  phone?: string;
  designation?: string;
  lastSignInTime?: Date;
  creationTime?: Date;
  isFacilityAdmin?: boolean;
  isEditor?: boolean;
  userID?: string
  // isShowManualAlert?: boolean;
  doNotShowManualAlert?: boolean;
  doNotShowCounterAlert?: boolean;
  lastPasswordChange?: Date,
  passwordExpire30DaysNotified?: boolean,
  passwordExpire7DaysNotified?: boolean,
  doNotShowCounterSkipAlert?: boolean;
  doNotShowLiquefactionPopup? :boolean;
  motilityCountingMethod?: "tally" | "label";
  morphologyCountingMethod?: "tally" | "label";
  doNotShowMotilityEstimationPopup?: boolean;
  doNotShowValidationKitMessage?: boolean
}

export interface ReportSettings {
  displayPageNumber: boolean;
  editEmail?: string;
  reportEmail?: string;
  isEditEmail?: boolean;
  footerSpace: number;
  headerSpace: number;
  removeComment?: boolean;
  removeSignature?: boolean;
  removeTester?: boolean;
  reportFooter: boolean;
  reportHeader: boolean;
  additionalText?: number;
}

export interface SystemSettings {
  playSound?: boolean;
  autoLogOut?: boolean;
  disableSelfTest?: boolean;
  multiFactorAuthActive: boolean;
  archivePageHistory?: boolean;
  // addLIS?: boolean;
  autoLogOutValue?: number;
  LISValue?: string;
  autoOpenLQCounter: boolean;
  /********privacy mode********/
  // disableFirstLastName: boolean;
  // disableBirthDateAge: boolean;
  removePatientPhone?: boolean;
  isPrivacyMode?: boolean
}

export interface TestSettings {
  settingsid?: string;
  concentrationStd?: number;
  who?: number;
  testOptional1?: string;
  testOptional2?: string;
  facilityOptional1?: string;
  facilityOptional2?: string;
  patientAge?: string;
  debrisAssessment?: boolean;
  playSound?: boolean;
  signatureLine?: boolean;
  reportHeader?: boolean;
  headerSpace?: number;
  reportFooter?: boolean;
  footerSpace?: number;
  morphUpperLimit?: number;
  patientUnits?: string;
  weightUnit?: string;
  heightUnit?: string;
  referenceSettings?: References;
  displayPageNumbers?: boolean;
  numberOfAllowImages?: number;
  numberOfAllowVideos?: number;
  saveVideoLength?: number;
  reportSettings?: {
    reportType: ReportType;
    standardPage: ReportSettings;
    firstPage: ReportSettings;
    secondPage: ReportSettings;
    selectedFecitlityHtmlFile: string;
  };
  systemSettings?: SystemSettings;
  appearance?: Appearnce;
  VUSettings?: VUSettings;
}

export interface VUSettings {
  openLQAuto?: boolean;
  downloadJPEGImg?: string;
  downloadXXVid?: string;
}

export interface Patient {
  patientid?: string;
  firstName?: string;
  lastName?: string;
  birthDate?: Date;
  weight?: number;
  height?: number;
  email?: string;
  mobile_phone?: string;
  home_phone?: string;
  address?: string;
  addedDate?: Date;
  facilityId?: string;
  facilityName?: string;
  testCount?: number;
}

export interface Test {
  dateTime?: Date;
  tester?: string;
  designation?: string;
  referringDoc?: string;
  customerType?: string;
  testType?: string;
  aw?: number;
  amplitude?: number;
  average?: number;
  averageId?: number;
  conc?: number;
  count?: number;
  controlRef1?: number;
  ledCurrent1?: number;
  ledCurrent2?: number;
  msc?: number;
  maxSpWidth?: number;
  minSpWidth?: number;
  noiseLevel?: number;
  normalMorph?: number;
  od?: number;
  odRef?: number;
  ref1?: number;
  ref2?: number;
  smiThreshold?: number;
  serialNumber?: number;
  zeroLevel?: number;
  comments?: string;
  sampleInformation?: SampleInformation;
  testSettings?: TestSettings;
  concentration?: number;
  mconcentration?: number;
  totalMotilePRNP?: number;
  progressivePR?: number;
  normalForms?: number;
  motileSpermConc?: number;
  progMotileSpermConc?: number;
  spermMotilityIndex?: number;
  spermCount?: number;
  mspermCount?: number;
  motileSperm?: number;
  progMotileSperm?: number;
  motility?: number;
  rapidProgressiveA?: number;
  slowProgressiveB?: number;
  progMotileSpermConcA?: number;
  progMotileSpermConcB?: number;
  immotile?: number;
  non_progressive_np?: number;
  hostname?: string;
  facilityId?: string;
  facilityName?: string;
  patientId?: string;
  patientFirstName?: string;
  patientLastName?: string;
  userId?: string;
  userName?: string;
  mNoSpermSeen?: boolean;
  mTotal_Motile?: number;
  mProgrssive_Pr?: number;
  mNormalForms?: number;
  calRawData?: any;
  results?: any;
  rawData?: any;
  calibrationData?: any;
  softwareVersion?: string;
  testingMode?: SelectedTest;
  prepType?: number;
  id?: string;
  linkedTest?: {
    linkedTestID: string;
    linkedPatientID: string
  };
  signatureURL?: string
}

export interface SampleInformation {
  sampleId?: string;
  sampleType?: number;
  patientAge?: number | string;
  debris?: number;
  wbcConcentration?: number;
  pH?: number;
  volume?: number;
  appearance?: string;
  viscosity?: string;
  liquefaction?: string;
  abstinence?: number;
  collectedTime?: Date;
  receivedTime?: Date;
  optional1?: string;
  optional2?: string;
  testType?: number;
}

export interface Request {
  supportId?: string;
  facilityId?: string;
  facilityName?: string;
  userId?: string;
  userName?: string;
  requesterName?: string;
  requesterEmail?: string;
  requesterPhone?: string;
  requestType?: string;
  message?: string;
  dateTime?: Date;
  distributorStatus?: string;
  status?: string; // has to be set to 'new' on sending a support ticket through contact us
  order?: {
    product: Order;
    units: number;
  }[];
}

export interface autoLogOutData {
  isLogOutActive: boolean;
  timeoutDuration: number;
}

export enum Order {
  TEST_KIT = 0,
  DEVICE = 1,
  CLEANING_KIT = 2,
  CAPILLARY_BOX = 3,
  QC_LIQUEFACTION_KIT = 4,
  QC_BEADS = 5,
  SQA_VU = 6,
  QC_Strips_100 = 7,
  QC_Strips = 8,
  QC_Dilution = 9,
  QC_Visualization = 10,
  OTHER = 11,
}

export enum SelectedTest {
  FRESH = 0,
  WASHED,
  ART_PREP,
}

export enum PrepType {
  PREPREP = 0,
  POSTPREP,
}

export interface DeviceMaintenance {
  date?: Date;
  deviceSN?: string;
  facilityId?: string;
  facilityName?: string;
  supplyInventory?: boolean;
  cleanChamber?: boolean;
  dryChamber?: boolean;
  dustChamber?: boolean;
  passedSelfTestConfirm?: boolean;
  performedBy?: string;
  comments?: string;
}

export interface Device {
  calibration?: any;
  lastMaintenance?: Date;
  usingFacility?: {
    facilityName: string;
    facilityId: string;
    facilityAddress?: any;
  };
  testCount?: number;
  selfTestCount?: number;
}

export interface ServiceData {
  serviceDataDate?: Date;
  operatorId?: string;
  operatorName?: string;
  serviceDataParameters: any;
  serviceDataType?: string;
  platform?: string;
  userId?: string;
  userName?: string;
}

export interface ControlsBatch {
  batchID?: string;
  lots?: ControlsLot[];
}

export interface ControlsLot {
  batchID?: string;
  lotID?: string;
  level?: number;
  isNegativeControl?: boolean;
  expirationDate?: Date;
  target?: number;
  value?: number;
  dateTime?: Date;
  conc?: number;
  msc?: number;
  rawData?: any;
  results?: any;
  status?: boolean;
  correctiveAction?: any;
  facilityId?: string;
  facilityName?: string;
  userId?: string;
  userName?: string;
}

export interface LatexBeadsData {
  lastRun?: Date;
  batchID?: string;
  // newPending?: boolean;
  // levels?: {
  //     type: number;
  //     value: number;
  // } [];
}

export interface ProficiencyBatch {
  id?: string;
  submissionDeadline?: string;
  samples?: ProficiencySample[];
  scheme?: string;
}

export interface ProficiencySample {
  id?: string;
  sampleId?: string;
  distributionNo?: string;
  issueDate?: string;
  batchNumber?: string;
  testDateTime?: Date;
  refConc?: number;
  chamberStd?: number;
  wbcConcStd?: number;
  sampleType?: number;
  testType?: number;
  rawData?: any;
  calRawData?: any;
  results?: any;
  note?: string;
  scheme?: string;
  conc?: number;
  facilityId?: string;
  facilityName?: string;
  userId?: string;
  userName?: string;
  submissionDeadline?: string;
}

export interface PopupData {
  showPopup: boolean;
  message?: string;
  accessedFromHeader?: boolean;
  buttons?: {
    eventName: string;
    displayName: string;
    disableBtn: boolean;
  }[];
}

export const resultsKeyReference = {
  concentration: "TSC",
  totalMotilePRNP: "Motility",
  progressivePR: "PMot",
  normalForms: "Morphology",
  motileSpermConc: "MSC",
  progMotileSpermConc: "PMSC",
  spermMotilityIndex: "SMI",
  spermCount: "TotalTSC",
  motileSperm: "TotalMSC",
  progMotileSperm: "TotalPMSC",
  rapidProgressiveA: "PMota",
  slowProgressiveB: "PMotb",
  progMotileSpermConcA: "PMSCa",
  progMotileSpermConcB: "PMSCb",
  fsc: "FSC",
  velocity: "Velocity",
  totalPmsc: "TotalPMSC",
  totalMorph: "TotalMorphNorm",
  totalFsc: "TotalFSC",
  immotile: "Immot",
  smiThreshold: "smiThreshold",
  non_progressive_np: "NonProgMot",
};

export interface bredCrumb {
  text: string;
  icon?: string;
  route?: string;
  class: string;
  fn?: Function;
}

export enum CountingMethod {
  VU_Counting,
  Manual_Counting,
  Morphology,
  Automated,
  Motility_Estimation,
}

export enum additionalText {
  none,
  semenAnalysis,
  SMI,
}
export interface Array<T> {
  remove(o: T): Array<T>;
}

export interface LoggedError {
  dateTime: Date,
  _error: string
}