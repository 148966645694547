import { Injectable ,ErrorHandler } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpService } from './http.service';
import { GlobalService } from './global.service';

interface LoggedError {
  dateTime: Date,
  _error: string
}

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandler implements ErrorHandler {
  

  constructor(private http: HttpClient, private httpService: HttpService, private globalService: GlobalService) { }

  handleError(error: any): void {
    console.error(error);
    let newErrorItem = {
      dateTime: new Date(), 
      _error: error
    };
  this.globalService.globalErrors.push(newErrorItem)
    
    // this.logErrorToFacility(error);

  }

  private logErrorToFacility(error: any): void {
    let apiEP: string;
    let facilityID: string = this.httpService.currentFacilityId;
    const hostname = window.location.hostname.toString().toLowerCase();
    if (hostname.includes("localhost")) {
      apiEP = "api";
    } else {
      apiEP = "https://us-central1-messqasystem.cloudfunctions.net";
    }

    this.http.post(`${apiEP}/logErrorToFacility`, { error }).pipe(
      catchError(err => {
        console.error('Error logging failed: ',err);
        return throwError(err);
      })
    ).subscribe();

  }



}
