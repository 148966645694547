import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MediaDevice } from '../../interface/mediaDevice.interface';

@Component({
  selector: 'app-select-video-device',
  templateUrl: './select-video-device.component.html',
  styleUrls: ['./select-video-device.component.sass']
})
export class SelectVideoDeviceComponent implements OnInit {

  @Input() videoDevices: MediaDevice[] = []; 
  @Input() selectedDevice: MediaDevice| null;

  @Output() selectedDeviceEmit = new EventEmitter<MediaDevice>();

  selectRecordingDeviceFb:FormGroup = new FormGroup({
    selectRecordingDevice: new FormControl(null)
  });

  
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(): void{
    if(this.selectedDevice){
      this.selectRecordingDeviceFb.patchValue({'selectRecordingDevice': this.selectedDevice.deviceId});
    }
  }

  continue(){
    let selectedDeviceId = this.selectRecordingDeviceFb.get('selectRecordingDevice').value;
    
    let device = this.videoDevices.filter( device => device.deviceId == selectedDeviceId);

    this.selectedDeviceEmit.next(device[0]);

  }


}
