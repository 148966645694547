import { HttpService } from 'src/app/services/http.service';
import { Injectable, } from '@angular/core';
import RecordRTC from 'recordrtc';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { GlobalService } from './global.service';

// declare var MediaRecorder: any;

interface RecordedVideoOutput {
  blob: Blob;
  url: any;
  title: string;
}

@Injectable()
export class VideoRecordingService {

  constructor(private http: HttpService, private _globalService: GlobalService) {}

  public stream: MediaStream;
  private recorder;

  private interval_1;
  private startTime_1;

  private interval_2;
  private startTime_2;

  public stream1 = new Subject<any>();
  private _recorded = new Subject<RecordedVideoOutput>();
  private _recordedUrl = new Subject<string>();
  private _streamingTime = new Subject<string>();
  private _recordingTime = new Subject<string>();
  private _recordingFailed = new Subject<string>();
  // constraints = <MediaTrackConstraints>{};


  getRecordedUrl(): Observable<string> {
    return this._recordedUrl.asObservable();
  }
  
  getRecordedBlob(): Observable<RecordedVideoOutput> {
    return this._recorded.asObservable();
  }

  getStreamingTime(): Observable<string> {
    return this._streamingTime.asObservable();
  }

  getRecordedTime(): Observable<string> {
    return this._recordingTime.asObservable();
  }

  recordingFailed(): Observable<string> {
    return this._recordingFailed.asObservable();
  }

  public getStream(): Observable<MediaStream> {
    return this.stream1.asObservable();
  }



  async startStreaming( conf: any ): Promise<any> {

    if (this.recorder) {
      return;
    }

    await this._checkIsAnyVideoTracksAlreadyPlaying(conf);

    return new Promise((resolve, reject) => {
      
      navigator.mediaDevices.getUserMedia(conf)
      .then((stream: MediaStream) => this.stream = stream)
      .then(_ => this.processTracks({}))
      .then(_ => this.streamingTime())
      .then((stream: MediaStream) => {
        this._globalService.mstream = stream;
        this.stream = stream;
        this.stream1.next(stream);
        resolve(this.stream);
      })
      .catch(error => {
        this._recordingFailed.next();
        reject(error);
      });
    });
  }


  // If any video tracks already playing, need to stop it.
  private _checkIsAnyVideoTracksAlreadyPlaying(conf: any): Promise<void>{
    return new Promise(async (resolve, reject) => {
      const stream = await navigator.mediaDevices.getUserMedia(conf)
      const tracks = stream.getVideoTracks();
      tracks.map(val => {
        if(val.readyState == 'live'){
          val.stop();
        }
        return val;
      });
      return resolve();
    });
  }

  abortRecording() {
    this.stopMedia();
  }

  streamingTime(): Promise<MediaStream>{    

    return new Promise((resolve, reject) => {

      this._streamingTime.next('00:00');

      this.startTime_1 = moment();
      this.interval_1 = setInterval(
        () => {
          const currentTime = moment();
          const diffTime = moment.duration(currentTime.diff(this.startTime_1));
          const time = this.toString(diffTime.minutes()) + ':' + this.toString(diffTime.seconds());
          this._streamingTime.next(time);
        },500
      );

      resolve(this.stream);

    });
    
  }


  processTracks(cameraSettings: any): Promise<MediaStream>{
    
    if(!this.stream && this._globalService.mstream){
      this.stream = this._globalService.mstream;
    }
    
    return new Promise((resolve, reject) => {

      try {
        if(this.stream &&
          (
            cameraSettings
            && Object.keys(cameraSettings).length !== 0
            && Object.getPrototypeOf(cameraSettings) === Object.prototype
          )
        ){
          let track = this.stream.getVideoTracks()[0]; 
          let obj = {
            ...cameraSettings,
            whiteBalanceMode: 'manual'
          }
          track.applyConstraints({advanced:[obj]});
        }

        resolve(this.stream);

      } catch (error) {
        if (error.name === 'NotReadableError') {
          const tracks = this.stream.getVideoTracks();
          if (tracks) {
            tracks.forEach(track => track.stop());
          }
        }
        reject (error);
      }

    });


  }



  startRecording() {
    const _videoBPS = this._globalService.facilityHeader.CameraSettings.videoSettings.videoBitsPerSecond;
    this._recordingTime.next('00:00');

    this.recorder = new RecordRTC(this.stream, {
      type: 'video',
      mimeType: 'video/webm;codecs=vp9',
      videoBitsPerSecond: _videoBPS ?? 5000000
    });
    
    this.recorder.startRecording();
    
    this.startTime_2 = moment();
    this.interval_2 = setInterval(
      () => {
        const currentTime = moment();
        const diffTime = moment.duration(currentTime.diff(this.startTime_2));
        const time = this.toString(diffTime.minutes()) + ':' + this.toString(diffTime.seconds());
        this._recordingTime.next(time);
      },500
    );

  }



  private toString(value) {
    let val = value;
    if (!value) {
      val = '00';
    }
    if (value < 10) {
      val = '0' + value;
    }
    return val;
  }



  stopRecording() {
    if (this.recorder) {
      this.recorder.stopRecording(this.processVideo.bind(this));
      // console.log("---------------Video service stop Recording-----------");
      
      // this.recorder.stop();
      // console.log(this.recordedChunks);
      clearInterval(this.interval_2);
    }
  }



  stopStreaming(): Promise<boolean>{
    
    return new Promise((resolve, reject) => {
      
      if(this.stream){

        var promises = [];

        this.stream.getTracks().forEach((track: MediaStreamTrack) => promises.push(this.stopTrack(track)))
        
        Promise.all(promises).then((_) => {
          this._globalService.mstream = null;
          console.log(this.stream.getTracks());
          resolve(false)
        });
      }
    });
    
  }

  private stopTrack(track: MediaStreamTrack): Promise<boolean>{
    return new Promise((resolve, reject) => {
      try{
        track.stop();
        track.enabled = false;
        resolve(true);
      }catch(e){
        console.log("=================stopTrack error ===============", e);
      }
    });
  }



  private processVideo(audioVideoWebMURL) {
    const recordedBlob = this.recorder.getBlob();
    this.recorder.getDataURL(function (dataURL) { });
    const recordedName = encodeURIComponent('video_' + new Date().getTime() + '.mp4');
    // console.log("-------------this._recorded----------", this._recorded);
    
    // this._recorded.next({ blob: recordedBlob, title: recordedName });
    this._recorded.next({ blob: recordedBlob, url: audioVideoWebMURL, title: recordedName });
    this.stopMedia();
  }



  stopMedia() {

    // console.log(this.recorder);

    this.recorder = null;

    // if(this.recorder != null) this.recorder.stop();

    clearInterval(this.interval_1);
    this.startTime_1 = null;
    clearInterval(this.interval_2);
    this.startTime_2 = null;
    
  }

  freezeStreamingVideo(){
    this.stream.getTracks()[0].enabled = false;
  }

}



  // playStreamingVideo(conf){
  //   this.stream.getTracks()[0].enabled = true;
  //   this.startStreaming(conf);
  // }




// startRecording() {

  //   // this._recordingTime.next('00:00');

  //   // this.recorder = new RecordRTC(this.stream, {
  //   //   type: 'video',
  //   //   mimeType: 'video/mp4',
  //   //   videoBitsPerSecond: 15000000
  //   // });
    
  //   // this.recorder.startRecording();
    
    

  //   // console.log(stream);
  //   var options = { mimeType: "video/webm;codecs=H264" };
  //   this.recorder = new MediaRecorder(this.stream, options);

  //   // Event After Stop Recording
  //   var handleDataAvailable = (event) =>  {

  //     console.log(event);

  //     console.log(this._recorded);

  //     const blob: Blob = event.data;


  //     const recordedName = encodeURIComponent('video_' + new Date().getTime() + '.mp4');
  //     console.log("-------------this._recorded-----------", this._recorded);    
  //     this._recorded.next({ blob: blob, title: recordedName });
  //     this.stopMedia();

  //   }

  //   this.recorder.ondataavailable =  handleDataAvailable;
    
  //   this.recorder.start();

  //   this.startTime_2 = moment();
  //   this.interval_2 = setInterval(
  //     () => {
  //       const currentTime = moment();
  //       const diffTime = moment.duration(currentTime.diff(this.startTime_2));
  //       const time = this.toString(diffTime.minutes()) + ':' + this.toString(diffTime.seconds());
  //       this._recordingTime.next(time);
  //     },500
  //   );

  // }

