import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import {
  References,
  SQACustomer,
  TestSettings,
  WHO_5_REF as WHO5_DEFAULT,
  WHO_6_REF as WHO6_DEFAULT,
} from 'src/app/globals/globals';
import { MorphCriteria } from 'src/app/services/algorithm.service';
import { HttpService } from 'src/app/services/http.service';
import { GlobalService } from 'src/app/services/global.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/core/auth.service';

interface RefValues {
  titleTranslation: string;
  unitTranslation: string;
  groupName: string;
  hasDefault?: boolean;
  isPercentage?: boolean;
  label: String;
}

type RefFormInput = { operator: string; value: string };

@Component({
  selector: 'app-ref-value',
  templateUrl: './ref-value.component.html',
  styleUrls: ['./ref-value.component.sass'],
})
export class RefValueComponent implements OnInit, OnChanges, OnDestroy {
  @Input() facilityData: any;
  @Input() testSettingValues: TestSettings;
  @Output() fireEvent = new EventEmitter();
  // prettier-ignore
  WHO_5_REF: RefValues[] = [
    { titleTranslation: 'settings.concentration', unitTranslation: 'units.M_ml', groupName: 'concentration', hasDefault: true, label: 'concentration' },
    { titleTranslation: 'settings.tot_pr', unitTranslation: '', groupName: 'total_motile_pr_np', hasDefault: true, isPercentage: true, label: 'totalMotilePRNP' },
    { titleTranslation: 'settings.progressive_pr', unitTranslation: '', groupName: 'progressive_pr', hasDefault: true, isPercentage: true, label: 'progressivePR' },
    { titleTranslation: 'test.non_prog_motility_np', unitTranslation: '', groupName: 'non_progressive_np', isPercentage: true, label: 'non_progressive_np' },
    { titleTranslation: 'settings.immotile', unitTranslation: '', groupName: 'immotile', isPercentage: true, label: 'immotile' },
    { titleTranslation: 'settings.motile_conc', unitTranslation: 'units.M_ml', groupName: 'motile_sperm_conc', label: 'motileSpermConc' },
    { titleTranslation: 'settings.prog_mot', unitTranslation: 'units.M_ml', groupName: 'prog_motile_sperm_conc', label: 'progMotileSpermConc' },
    { titleTranslation: 'settings.normal', unitTranslation: '', groupName: 'normal_forms', hasDefault: true, isPercentage: true, label: 'normalForms' },
    { titleTranslation: 'settings.sperm_motility_index', unitTranslation: '', groupName: 'sperm_motility_index', label: 'spermMotilityIndex' },
    { titleTranslation: 'settings.sperm', unitTranslation: 'units.m_ejac', groupName: 'total_sperm_count', hasDefault: true, label: 'spermCount' },
    { titleTranslation: 'settings.mot_spr_conc', unitTranslation: 'units.m_ejac', groupName: 'motile_sperm', label: 'motileSperm' },
  ];
  // prettier-ignore
  WHO_6_REF: RefValues[] = [
    { titleTranslation: 'settings.concentration', unitTranslation: 'units.M_ml', groupName: 'concentration', hasDefault: true, label: 'concentration' },
    { titleTranslation: 'settings.motility', unitTranslation: '', groupName: 'total_motile_pr_np', hasDefault: true, isPercentage: true, label: 'totalMotilePRNP' },
    { titleTranslation: 'settings.progressive', unitTranslation: '', groupName: 'progressive_pr', hasDefault: true, isPercentage: true, label: 'progressivePR' },
    { titleTranslation: 'test.rapid_prog', unitTranslation: '', groupName: 'rapid_progressive_a', isPercentage: true, label: 'rapidProgressiveA' },
    { titleTranslation: 'test.slow_prog', unitTranslation: '', groupName: 'slow_progressive_b', isPercentage: true, label: 'rapidProgressiveA' },
    { titleTranslation: 'settings.non_progressive_pr', unitTranslation: '', groupName: 'non_progressive_np', hasDefault: true, isPercentage: true, label: 'non_progressive_np' },
    { titleTranslation: 'settings.immotile_who6', unitTranslation: '', groupName: 'immotile', hasDefault: true, isPercentage: true, label: 'immotile' },
    { titleTranslation: 'settings.normal', unitTranslation: '', groupName: 'normal_forms', hasDefault: true, isPercentage: true, label: 'normalForms' },
    { titleTranslation: 'settings.motile_conc', unitTranslation: 'units.M_ml', groupName: 'motile_sperm_conc', label: 'motileSpermConc' },
    { titleTranslation: 'settings.prog_mot', unitTranslation: 'units.M_ml', groupName: 'prog_motile_sperm_conc', label: 'progMotileSpermConc' },
    { titleTranslation: 'test.rapid_msc', unitTranslation: 'units.M_ml', groupName: 'prog_motile_sperm_conc_a', label: 'progMotileSpermConcA' },
    { titleTranslation: 'test.slow_msc', unitTranslation: 'units.M_ml', groupName: 'prog_motile_sperm_conc_b', label: 'progMotileSpermConcB' },
    { titleTranslation: 'test.fsc', unitTranslation: 'units.M_ml', groupName: 'fsc', label: 'fsc' },
    { titleTranslation: 'test.velocity', unitTranslation: 'units.mic_sec', groupName: 'velocity', hasDefault: true, label: 'velocity' },
    { titleTranslation: 'settings.sperm_motility_index', unitTranslation: '', groupName: 'sperm_motility_index', label: 'spermMotilityIndex' },
    { titleTranslation: 'settings.sperm', unitTranslation: 'units.m_ejac', groupName: 'total_sperm_count', hasDefault: true, label: 'spermCount' },
    { titleTranslation: 'settings.mot_spr_conc', unitTranslation: 'units.m_ejac', groupName: 'motile_sperm', label: 'motileSperm' },
    { titleTranslation: 'test.tota_progmotilesperm', unitTranslation: 'units.m_ejac', groupName: 'totalPMSC', label: 'totalPmsc' },
    { titleTranslation: 'test.tota_fsc', unitTranslation: 'units.m_ejac', groupName: 'totalFSC', label: 'totalFsc' },
    { titleTranslation: 'settings.morph_normal', unitTranslation: 'units.m_ejac', groupName: 'totalMorph', label: 'totalMorph' },
  ];
  operatorOptions = ['', '=', '<', '<=', '>', '>='];
  // prettier-ignore
  whoOptions = [
    { translationPath: 'settings.who5', value: MorphCriteria.MORPH_WHO5, controlName: 'who' },
    { translationPath: 'settings.who6', value: MorphCriteria.MORPH_WHO6, controlName: 'who' },
  ];
  showRefValues: RefValues[] = [];
  form = new FormGroup({});
  submitBtnLoading = false;
  currentLang: string;
  langCompat: string[] = ['es', 'it', 'tr', 'el','de','vi','pt'];
  languageSubscription: Subscription;
  disableSaveBtn = true;
  tab: string;

  constructor(
    private httpService: HttpService,
    private globalService: GlobalService,
    private cdr: ChangeDetectorRef,
    private _translate: TranslateService,
    private route: ActivatedRoute,
    private authService: AuthService,
  ) {
    this.currentLang = this._translate.currentLang;
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Update formGroup values on testSettings value changes...
    if (
      changes['testSettingValues'] &&
      changes['testSettingValues'].currentValue
    ) {
      this.setFormValues();
    }
  }

  ngOnInit(): void {
    this.languageSubscription = this._translate.onLangChange.subscribe(
      (e: LangChangeEvent) => {
        console.log("===ngOnInit:languageSubscription===",e.lang);
        
        this.currentLang = e.lang;
      }
    );
    this.route.fragment.subscribe((result) => {
      this.tab = result
    });
  }

  // Custom validator to check if both the value and operator is entered...
  checkInputError(control: AbstractControl): ValidationErrors | null {
    if (control.parent) {
      // Since can't get update groupValue, so putting this in callback queue by setTimeout...
      setTimeout(() => {
        const groupValue: { operator: string | null; value: string | null } = (
          control.parent as FormGroup
        ).value;
        // If both value of operator and value is '' or null...
        if (
          (groupValue.operator === '' || groupValue.operator === null) &&
          (groupValue.value === '' || groupValue.value === null)
        )
          return null;
        // If either of operator and value is '' or null...
        if (
          groupValue.operator === '' ||
          groupValue.operator === null ||
          groupValue.value === '' ||
          groupValue.value === null
        ) {
          control.parent.setErrors({ invalidValue: true });
        }
        return null;
      }, 0);
    }
    return null;
  }

  createForm(whoCriteria: MorphCriteria, populateType: 'default' | 'saved') {
    const formControls = {};
    // console.log('========Before========', this.showRefValues);

    //check iOwMode
    //Fiter the showRefValue if hideParameters === true;
    this.showRefValues = this.showRefValues.filter(el => {
      if (!this.authService.iOwMode) {

        if (this.facilityData.contentLimitation.hideParameters === true)
          return !this.facilityData.resultsRestriction.includes(el.label)
        else
          return true
      }else{
        return !(['spermMotilityIndex','velocity','fsc',
                          'totalFsc','totalMorph','spermCount','motileSperm','totalPmsc'] as any).includes(el.label)
      }

    });

    // console.log('=======After=========', this.showRefValues);

    // First create those controls with null values...
    for (const eachValue of this.showRefValues) {
      if (!formControls.hasOwnProperty(eachValue.groupName)) {
        formControls[eachValue.groupName] = new FormGroup({
          operator: new FormControl(null, this.checkInputError),
          value: new FormControl(null, this.checkInputError),
        });
      }
    }

    // After that populate those controls with either default values or user
    // saved values...
    let refValueDatas: References;
    if (populateType === 'default') {
      if (whoCriteria === MorphCriteria.MORPH_WHO5)
        refValueDatas = WHO5_DEFAULT;
      if (whoCriteria === MorphCriteria.MORPH_WHO6)
        refValueDatas = WHO6_DEFAULT;
    }
    if (populateType === 'saved') {
      refValueDatas = this.testSettingValues.referenceSettings as {
        [key: string]: (string | number)[];
      };
    }
    for (const key in refValueDatas) {
      if (formControls.hasOwnProperty(key)) {
        (formControls[key] as FormGroup)
          .get('operator')
          .setValue(refValueDatas[key][0]);
        (formControls[key] as FormGroup)
          .get('value')
          .setValue(refValueDatas[key][1]);
      }
    }

    // Adding whoCriteria and show default value controls in the form...
    if (whoCriteria === MorphCriteria.MORPH_WHO5) {
      formControls['who'] = new FormControl(3, Validators.required);
    } else {
      formControls['who'] = new FormControl(4, Validators.required);
    }
    formControls['use_WHO_RefValue_def'] = new FormControl(
      populateType === 'default',
      Validators.required
    );

    this.form = new FormGroup(formControls);

    this.cdr.detectChanges();

    if (populateType === 'default') this.form.disable();
    // Have to set enable to reflect it in DOM, if previously it was disabled...
    else this.form.enable();

    // Checking if the value has changed or not...
    this.form.valueChanges.subscribe(
      (result: { [key: string]: RefFormInput | number | boolean }) => {
        this.globalService.hasUnsavedChanges =
          this.checkForValueChanges(result);
        console.log(`=====globalService01 ${this.globalService.hasUnsavedChanges}`);

        this.checkSaveBtnDisable();
      }
    );
  }

  setFormValues() {
    if (this.testSettingValues.who === MorphCriteria.MORPH_WHO5) {
      this.showRefValues = [...this.WHO_5_REF];
      if (this.testSettingValues.referenceSettings.use_WHO_RefValue_def) {
        this.createForm(MorphCriteria.MORPH_WHO5, 'default');
      } else {
        this.createForm(MorphCriteria.MORPH_WHO5, 'saved');
      }
    }
    if (this.testSettingValues.who === MorphCriteria.MORPH_WHO6) {
      this.showRefValues = [...this.WHO_6_REF];
      if (this.testSettingValues.referenceSettings.use_WHO_RefValue_def) {
        this.createForm(MorphCriteria.MORPH_WHO6, 'default');
      } else {
        this.createForm(MorphCriteria.MORPH_WHO6, 'saved');
      }
    }
  }

  checkForValueChanges(values: {
    [key: string]: RefFormInput | number | boolean;
  }) {
    let result = false;
    if (this.testSettingValues) {
      for (const key in values) {
        if (!result) {
          // Since 'who' and 'use_WHO_RefValue_def' will not be object and will
          // stay in different places in testSettings...
          if (key === 'who' || key === 'use_WHO_RefValue_def') {
            if (key === 'who')
              result = this.testSettingValues.who !== values[key];
            if (key === 'use_WHO_RefValue_def') {
              result =
                this.testSettingValues.referenceSettings
                  .use_WHO_RefValue_def !== values[key];
            }
          } else {
            if (this.testSettingValues.referenceSettings.hasOwnProperty(key)) {
              // For the other values check both the operator and value...
              const compareOperator =
                (values[key] as RefFormInput).operator === ''
                  ? null
                  : (values[key] as RefFormInput).operator;
              const compareValue =
                (values[key] as RefFormInput).value === ''
                  ? null
                  : (values[key] as RefFormInput).value;
              result =
                compareOperator !==
                this.testSettingValues.referenceSettings[key][0] ||
                +compareValue !==
                +this.testSettingValues.referenceSettings[key][1];
            } else {
              // If key doesn't exist in the saved data and some value has changed to something
              // rather than null or empty string...
              result =
                ((values[key] as RefFormInput).operator !== '' &&
                  (values[key] as RefFormInput).operator !== null) ||
                ((values[key] as RefFormInput).value !== '' &&
                  (values[key] as RefFormInput).value !== null);
            }
          }
        }
      }
    }
    return result;
  }

  resetValues() {
    this.setFormValues();

    // Since we are creating new form so have to run the value update check manually...
    const formValue = this.form.getRawValue();
    this.globalService.hasUnsavedChanges = this.checkForValueChanges(formValue);
    console.log(`=====globalService02 ${this.globalService.hasUnsavedChanges}`);
    this.checkSaveBtnDisable();
  }

  showDarkBackground(isDefaultField: boolean | undefined) {
    if (this.showRefValues.length > 0 && this.form) {
      if (this.form.disabled) {
        if (isDefaultField === undefined || isDefaultField === false) {
          return true;
        }
        return false;
      }
    }
    return false;
  }

  roundNumberValue(groupName: string) {
    // Do nothing if the field is disabled...
    if (this.form.get(`${groupName}.value`).disabled) return;

    const value = this.form.get(`${groupName}.value`).value;

    // Don't run the check if value is null or empty...
    if (value !== null && value !== '') {
      const valueArr = (value as string).split('');
      let updatedValue: string;
      // If the value is like '.'...
      if (valueArr.length === 1 && valueArr[0] === '.') {
        updatedValue = '0.0';
      }
      // If the value is like '.8'...
      if (valueArr.length > 1 && valueArr[0] === '.') {
        valueArr.unshift('0');
        updatedValue = valueArr.join('');
      }
      // If the value is like '7.'...
      if (valueArr.length > 1 && valueArr[valueArr.length - 1] === '.') {
        valueArr.push('0');
        updatedValue = valueArr.join('');
      }

      if (updatedValue) {
        this.form.get(`${groupName}.value`).setValue(updatedValue);
      }
    }
  }

  // If condition true that means percentage value and shouldn't be above 100...
  decimalPointerValue(
    event: KeyboardEvent,
    value: string,
    condition?: boolean
  ) {
    // Cursor position to determine where the pressed key value would be entered in the
    // previous value's array...
    const _cursorStartPosition = (event.target as HTMLInputElement)
      .selectionStart;
    const _cursorEndPosition = (event.target as HTMLInputElement).selectionEnd;
    const valueArr = value.split('');

    // Backspace shouldn't work if it's used to remove '.' and after removing '.' length becomes 5...
    if (event.key === 'Backspace') {
      // With no text selection...
      if (_cursorStartPosition === _cursorEndPosition) {
        if (valueArr[_cursorStartPosition - 1] === '.') {
          valueArr.splice(_cursorStartPosition - 1, 1);
          if (condition)
            return (
              +valueArr.join('') <= 100
            ); /* After removing dot highest value 100 */
          return valueArr.length < 4; /* After removing dot max 3 itmes */
        } else {
          return true;
        }
      } else {
        // With text selection...
        const textSelected =
          _cursorEndPosition -
          _cursorStartPosition; /* Selected text through cursor */
        valueArr.splice(_cursorStartPosition, textSelected);
        // If the value have '.' than hightest length should be 3 digit for percentage values and 4 for
        // normal values, And without '.' below 100 for percentages and max 3 digit for normal values...
        if (valueArr.includes('.')) {
          if (condition) return valueArr.length < 4;
          return valueArr.length < 5;
        } else {
          if (condition) {
            return +valueArr.join('') <= 100;
          }
          return valueArr.length < 4;
        }
      }
    }

    if (event.key === 'ArrowRight' || event.key === 'ArrowLeft') return true;
    if (event.code === 'Space') return false;
    const key = event.key === '.' ? event.key : +event.key;
    if ((key !== '.' && key >= 0 && key <= 9) || key === '.') {
      // Since keypress or keydown event don't reflect the key pressed in the input value that's
      // why creating the reflected(latest) value by taking the previous value and current key pressed...
      const currentKey = event.key;
      if (_cursorStartPosition === _cursorEndPosition) {
        // If no text selected through the cursor...
        valueArr.splice(_cursorStartPosition, 0, currentKey);
      } else {
        // If text is selected through cursor...
        valueArr.splice(
          _cursorStartPosition,
          _cursorEndPosition - _cursorStartPosition,
          currentKey
        );
      }
      if (valueArr.includes('.')) {
        if (valueArr.length > 5) return false;
      } else {
        if (valueArr.length > 3) return false;
      }
      const dotIndex = valueArr.findIndex((item) => item === '.');
      if (dotIndex !== -1) {
        // Only 1 item after dot...
        if (valueArr.length - 1 - dotIndex > 1) return false;
        // Only 3 items before dot...
        if (dotIndex > 3) return false;
        // Can't be dot after a dot...
        if (valueArr[dotIndex + 1] === '.') return false;
      }
      // Check if value is above 100...
      if (condition) {
        if (+valueArr.join('') > 100) return false;
      }
      return true;
    }
    return false;
  }

  checkboxChange(checked: boolean) {
    if (checked) {
      this.form.get('use_WHO_RefValue_def').setValue(true);
      const morphCriteria = this.form.get('who').value;

      // Setting up ref values and default values accrording to who...
      let refValues =
        morphCriteria === MorphCriteria.MORPH_WHO5
          ? this.WHO_5_REF
          : this.WHO_6_REF;
      let defaultValues =
        morphCriteria === MorphCriteria.MORPH_WHO5
          ? WHO5_DEFAULT
          : WHO6_DEFAULT;

      // Update the form values as per the default values in the existing form...
      for (const item of refValues) {
        if (defaultValues.hasOwnProperty(item.groupName)) {
          this.form
            .get(`${item.groupName}.operator`)
            .setValue(defaultValues[item.groupName][0]);
          this.form
            .get(`${item.groupName}.value`)
            .setValue(defaultValues[item.groupName][1]);
        } else {
          this.form.get(`${item.groupName}.operator`).setValue(null);
          this.form.get(`${item.groupName}.value`).setValue(null);
        }
      }

      this.form.disable({ emitEvent: false });
    } else {
      this.form.get('use_WHO_RefValue_def').setValue(false);
      this.form.enable({ emitEvent: false });
    }
  }

  whoChange(criteria: MorphCriteria) {
    if (criteria === MorphCriteria.MORPH_WHO5) {
      this.showRefValues = [...this.WHO_5_REF];
      this.createForm(MorphCriteria.MORPH_WHO5, 'default');
    }
    if (criteria === MorphCriteria.MORPH_WHO6) {
      this.showRefValues = [...this.WHO_6_REF];
      this.createForm(MorphCriteria.MORPH_WHO6, 'default');
    }

    // Since new form gets created instead of using the old one, that's why valueChanges
    // will not trigger. For that checking for value changes manually...
    const value = this.form.getRawValue();
    this.globalService.hasUnsavedChanges = this.checkForValueChanges(value);
    this.checkSaveBtnDisable();
  }

  checkSaveBtnDisable() {
    // this.globalService.alternateUnsavedForRefValues = this.globalService.hasUnsavedChanges

    if (this.form && this.testSettingValues) {
      // For a few second the form becomes valid before becoming invalid(Probably for using
      // setTimeout in the control validator), which causes flickering in the button. To
      // overcome this putting check in callback queue...
      setTimeout(() => {
        if (this.form.disabled)
          this.disableSaveBtn = !this.globalService.hasUnsavedChanges;
        else if (!this.form.valid) this.disableSaveBtn = true;
        else this.disableSaveBtn = !this.globalService.hasUnsavedChanges;
      }, 0);
    } else this.disableSaveBtn = true;
  }

  async onSubmit() {
    if (this.submitBtnLoading) return;

    this.submitBtnLoading = true;
    const result = this.form.getRawValue();
    const whoCriteria = result.who;

    // Formatting the data from inner obj to inner array and convert the values
    // from string to number...
    for (const key in result) {
      if (key !== 'who' && key !== 'use_WHO_RefValue_def') {
        let sign = null;
        let value = null;
        if (result[key].operator !== '' && result[key].operator !== null)
          sign = result[key].operator;
        if (result[key].value !== '' && result[key].value !== null)
          value = +result[key].value;
        result[key] = [sign, value];
      }
    }
    // Since who value will not be in the ref settings...
    delete result.who;

    // Since refernceSettings in database will have both the who5 and who6 value
    // together, so adding the remining values...
    let mergeValues: RefValues[];
    if (whoCriteria === MorphCriteria.MORPH_WHO5) {
      mergeValues = this.WHO_6_REF;
    }
    if (whoCriteria === MorphCriteria.MORPH_WHO6) {
      mergeValues = this.WHO_5_REF;
    }
    for (const item of mergeValues) {
      if (!result.hasOwnProperty(item.groupName)) {
        result[item.groupName] = [null, null];
      }
    }

    // Saving the data to the database...
    const newTestSettings = {
      ...this.testSettingValues,
      who: whoCriteria,
      referenceSettings: result,
    };
    try {
      const result: { id: string; data: { testSettings: TestSettings } } =
        await this.httpService.UpdateFacilitySettings({
          testSettings: newTestSettings,
        });
      console.log(result);
      this.globalService.hasUnsavedChanges = false;
      // this.globalService.alternateUnsavedForRefValues = false;
      this.checkSaveBtnDisable();
      this.testSettingValues = result.data.testSettings;
      this.fireEvent.emit('fetch data');
      this.fireEvent.emit('success:settings.save_success');
    } catch (error) {
      console.log(error);
      this.fireEvent.emit('error:settings.save_fail');
    }
    this.submitBtnLoading = false;
  }

  ngOnDestroy() {
    this.languageSubscription.unsubscribe();
  }
}
