import { Component, Input, OnInit, Output, EventEmitter, OnDestroy, ChangeDetectorRef, AfterViewInit, OnChanges, ViewChild, Renderer2 } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTab, MatTabGroup } from '@angular/material/tabs';
import { GuardsCheckStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { CommunicatorService, MyServiceEvent } from 'src/app/services/communication.service';
import { GlobalService } from 'src/app/services/global.service';
import { HttpService } from 'src/app/services/http.service';
import { MediaDeviceService } from 'src/app/services/media-device.service';
import { VideoRecordingService } from 'src/app/services/video-recording.service';
import { MediaDevice } from '../../interface/mediaDevice.interface';

interface videoEl {
  title: string
  value?: Number
  min?: Number
  max?: Number
  default?: Number
  element: string
}
interface btns {
  text: string,
  class: string,
  fn: Function,
  disable?: Function
}
interface cameraSettings {
  min: Number,
  max: Number,
  default: Number
}
interface devices {
  label: string,
  kind: string,
  coefficient: Number,
  cameraSettings: cameraSettings[]
}
interface constraints {
  brightness: Number
  contrast: Number
  saturation: Number
  sharpness: Number
  colorTemperature: Number
}
@Component({
  selector: 'app-video-settings',
  templateUrl: './video-settings.component.html',
  styleUrls: ['./video-settings.component.sass']
})
export class VideoSettingsComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  @Input() bodyHeight
  @Input() fullHeight
  @Input() footerHeight

  @Input() isStreaming
  @Input() previewData

  @Output() argsSettingsHandler: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('tabGrp', { static: true }) tabGroup: MatTabGroup
  @ViewChild('tab1', { static: true }) vidoSettingsTab: MatTab
  selectedDevice: MediaDevice | null;
  videoDevices: MediaDevice[] = [];

  videoElements: videoEl[] = [
    { title: "visualization.brightness", element: "brightness" },
    { title: "visualization.contrast", element: "contrast" },
    { title: "visualization.saturation", element: "saturation" },
    { title: "visualization.sharpness", element: "sharpness" },
    { title: "visualization.colorTemperature", element: "colorTemperature" }
  ]
  defaults: videoEl[]
  buttons: btns[] = [
    {
      text: "general.save",
      class: "btn btn-primary waves-effect",
      fn: () => {
        this.getSettings()
      },
      disable: () => this.enableSliderSave
    },
    {
      text: "settings.default",
      class: "btn btn-primary waves-effect",
      fn: () => {
        this.videoElements = this.defaults //setting defaults
        this.onSliderChange(false)
        this.getSettings()
      },
      disable: () => this.enableSliderDefault
    },
    {
      text: "general.close",
      class: "btn btn-primary waves-effect",
      fn: () => {
        console.log("close working");
        if (!this.disableSave) {
          this._glob.exitPopupData$.next({
            showPopup: true,
            buttons: [
              {
                eventName: "save-vs",
                displayName: this.translate.instant("general.save"),
                disableBtn: false,
              },
              {
                eventName: "dontSave-vs",
                displayName: this.translate.instant("general.dont_save"),
                disableBtn: false

              },
              {
                eventName: "close",
                displayName: this.translate.instant("general.cancel"),
                disableBtn: false

              }]
          })
        } else {
          this.argsSettingsHandler.emit(false);
        }
      },
      disable: () => false
    }
  ]
  rightButtons: any[] = [
    {
      text: "visualization.refresh",
      class: "btn btn-primary waves-effect",
      isDisable: () => this.isDisableContinue,
      fn: () => {
        this.continue()
      }
    },
    {
      text: "general.close",
      class: "btn btn-primary waves-effect",
      isDisable: () => false,

      fn: () => {
        console.log("close works");
        if (!this.disableSave) {
          this._glob.exitPopupData$.next({
            showPopup: true,
            buttons: [
              {
                eventName: "save-vs",
                displayName: this.translate.instant("general.save"),
                disableBtn: false,
              },
              {
                eventName: "dontSave-vs",
                displayName: this.translate.instant("general.dont_save"),
                disableBtn: false

              },
              {
                eventName: "close",
                displayName: this.translate.instant("general.cancel"),
                disableBtn: false

              }]
          })
        } else {
          this.argsSettingsHandler.emit(false);
        }
        // this.argsSettingsHandler.emit(false);

      }
    },
  ]
  private _VideoDeviceSubsciption: Subscription;
  private _VideoDevicesSubsciption: Subscription;
  private _selectedDeviceSubsciption: Subscription;

  isDeviceConnected: boolean;

  selectRecordingDeviceFb: FormGroup = new FormGroup({
    selectRecordingDevice: new FormControl(null)
  });
  nextURL: string;
  disableSave: boolean = true;
  disableDefaults: boolean = true;
  connectedDevice: any = {};
  selectedIndex: Number = -1;
  isRealTime: boolean = true
  facilitySubscription: Subscription;
  videoStreamSubscription: Subscription;
  private _serviceSubscription: Subscription;
  _deviceListSubscription$: Subscription;
  VUSerial: any;
  _popupSubscription: Subscription;
  _headerLogoutClicked: Subscription;
  previousData: any[];

  navigateAwaySelection$: Subject<boolean> = new Subject<boolean>();
  //check internet variable
  isInternetCheck: Subscription;
  isInternet: boolean = true;


  constructor(
    private _http: HttpService,
    public translate: TranslateService,
    public _glob: GlobalService,
    private _vdRecordingService: VideoRecordingService,
    private _mediaDeviceService: MediaDeviceService,
    private _router: Router,
    private _renderer: Renderer2,
    private Comm: CommunicatorService,
  ) {
    //check internet connection for save button disable options
    this.isInternetCheck = _glob.isInternet.subscribe(res => {
      if (res && !this.disableSave) {
        this._glob.hasUnsavedChanges = true;
      }
      this.isInternet = res;
    });

    // this._mediaDeviceService.detectDeviceFn()
    this.comEvents();

    this._VideoDevicesSubsciption = this._mediaDeviceService.videoDevices$.subscribe(res => {
      // console.log(res);
      this.videoDevices = res;
    })

    this._router.events.subscribe(event => {
      if (event instanceof GuardsCheckStart) {
        this.nextURL = event.urlAfterRedirects
      }
    });

    this._selectedDeviceSubsciption = this._mediaDeviceService.selectedDevice$.subscribe(res => {
      this.selectedDevice = res;
      this.updateDropdownValue();
    })

    this._popupSubscription = this._glob.exitPopupBtnClicked$.subscribe(async (result) => {
      const resetGlobalUnsavedVariable = () => {
        _glob.hasUnsavedChanges = false

      }
      switch (result) {
        case 'save':
          await this.getSettings()
          this.argsSettingsHandler.emit(false);
          this._glob.exitPopupData$.next({
            showPopup: false
          })
          _glob.videoSettingNavigation.next([true, false])
          break;
        case 'save-vs':
          await this.getSettings()
          this.argsSettingsHandler.emit(false);
          this._glob.exitPopupData$.next({
            showPopup: false
          })
          resetGlobalUnsavedVariable()
          break;
        case 'dontSave':
          this.argsSettingsHandler.emit(false);
          this.onSliderChange(true)
          this._glob.exitPopupData$.next({
            showPopup: false
          })
          _glob.videoSettingNavigation.next([true, false])
          break;
        case 'dontSave-vs':
          this.argsSettingsHandler.emit(false);
          this.onSliderChange(true)
          this._glob.exitPopupData$.next({
            showPopup: false
          })
          resetGlobalUnsavedVariable()
          break;
        case 'close':
          this._glob.exitPopupData$.next({
            showPopup: false
          })
          _glob.videoSettingNavigation.next([false, false])
          break;

        case 'save-logout':
          await this.getSettings()
          this.argsSettingsHandler.emit(false);
          this._glob.exitPopupData$.next({
            showPopup: false
          })
          _glob.videoSettingNavigation.next([true, true])
          break;

        case 'dontSave-logout':
          this.argsSettingsHandler.emit(false);
          this.onSliderChange(true)
          this._glob.exitPopupData$.next({
            showPopup: false
          })
          _glob.videoSettingNavigation.next([true, true])
          break;

        case 'close-logout':
          this._glob.exitPopupData$.next({
            showPopup: false
          })
          _glob.videoSettingNavigation.next([false, false])
      }
    })

    //logout linsener
    this._headerLogoutClicked = this._glob.headerLogoutClicked$.subscribe((result) => {
      if (result === 'clicked') {
        this._glob.exitPopupData$.next({
          showPopup: true,
          accessedFromHeader: true,
          buttons: [
            {
              eventName: "save",
              displayName: this.translate.instant("general.save"),
              disableBtn: false,
            },
            {
              eventName: "dontSave",
              displayName: this.translate.instant("general.dont_save"),
              disableBtn: false

            },
            {
              eventName: "close",
              displayName: this.translate.instant("general.cancel"),
              disableBtn: false

            }],
        });
      }
    });

  }


  ngOnChanges(changes): void {
    this.isRealTime = this.previewData == null

    if (changes.fullHeight) {
      this.fullHeight = changes.fullHeight.currentValue;
    }
    if (changes.bodyHeight) {

      this.bodyHeight = changes.bodyHeight.currentValue;

    }
    // if(changes.footerHeight){
    //   this.bodyHeight = changes.footerHeight.currentValue;
    // }
  }


  public get disableTab(): boolean {
    const val = !this.isRealTime || !this.isDeviceConnected
    if (val) {
      this.tabGroup.selectedIndex = 1
      this.vidoSettingsTab.disabled = true
    } else {
      this.vidoSettingsTab.disabled = false
    }

    return val
  }


  ngOnInit(): void {
    this.videoDevices = this._mediaDeviceService.videoDevices;
    this.selectedDevice = this._mediaDeviceService.selectedDevice;

    this.updateDropdownValue();

    this.facilitySubscription = this._http.facilityUpdates.subscribe((res) => {
      const field = this._router.url.includes('morphology') ? `morphologySettings` : `motilitySettings`
      if (res.cameraSettings) {
        this.videoElements.forEach(setting => {
          if (res.cameraSettings[field]) {
            setting.value = res.cameraSettings[field][setting.element]

          }
        });
      }
      // console.log(this.videoElements);
      // this._http.getCollectionData("System", "defaultSettings").then(res => {

      this.fetchDefaults(res.CameraSettings.allowedDevices)
      // })
    })

  }


  ngAfterViewInit() {
    this.tabGroup.realignInkBar();
  }


  async fetchDefaults(allowedDevices: devices[]) {
    try {
      this.VUSerial = await this.getSerial();
      if (this.VUSerial === undefined) throw new Error('VU Serial Number not found!');
    } catch (error) {
      console.log(error);
    } finally {
      const device = allowedDevices.filter(device => device.label == this._glob.cameraLabel)[0]
      if (device == undefined)
        return
      let matchedDevice = device.cameraSettings
      this.connectedDevice = device
      const finalData = this.videoElements.map(constraint => {
        return { ...constraint, ...matchedDevice[constraint.element], value: constraint.value ?? matchedDevice[constraint.element][this.defaultKey] }
      });
      this.videoElements = finalData
      this.previousData = JSON.parse(JSON.stringify(finalData))
      this.defaults = this.videoElements.map(constraint => {
        return { ...constraint, ...matchedDevice[constraint.element], value: matchedDevice[constraint.element][this.defaultKey] }
      });
      this.disableDefaults = (JSON.stringify(this.videoElements) == JSON.stringify(this.defaults))

      // this.onSliderChange()
      // this.disableSave = true
    }

  }


  public get defaultKey(): string {
    return this._router.url.includes('morphology') ? 'morphologyDefault' : 'default'
  }


  translateNReturn(translateStr: string): string {
    return this.translate.instant(translateStr)
  }

  updateSetting(ele: videoEl, event): void {
    ele.value = event.value;
    // console.log(ele, event);
  }

  async getSettings(): Promise<any> {
    const userSettings = this.videoElements.reduce((pre, cur) => {
      return { ...pre, [cur.element]: cur.value }
    }, {})
    let fieldName = this._router.url.includes('morphology') ? "morphologySettings" : "motilitySettings"

    console.log(this.videoElements, this.previousData, JSON.stringify(this.videoElements) == JSON.stringify(this.previousData));

    if (JSON.stringify(this.videoElements) != JSON.stringify(this.previousData)) {
      await this._http.UpdateFacilitySettings({ cameraSettings: { [fieldName]: userSettings } }).then(res => {
        this.disableSave = true
        this.sethasUnsavedChanges = false
        this.disableDefaults = (JSON.stringify(this.videoElements) == JSON.stringify(this.defaults))
        this._glob.showNotification("bg-green", this.translateNReturn("settings.save_success"), "bottom", "right", "animated fadeInRight", "animated fadeOutRight")
      })
    } else {
      this.disableSave = true
      this.sethasUnsavedChanges = false
      this.disableDefaults = (JSON.stringify(this.videoElements) == JSON.stringify(this.defaults))
    }
  }

  async onSliderChange(onDontSave: boolean): Promise<void> {
    this.disableSave = false
    this.sethasUnsavedChanges = true
    this.disableDefaults = false
    const makeArray = (obj) => {
      return obj.reduce((pre, cur) => {
        return { ...pre, [cur.element]: cur.value }
      }, {})
    }
    const userSettings: constraints | any = onDontSave == true ? makeArray(this.previousData) : makeArray(this.videoElements)
    try{
      await this._vdRecordingService.processTracks(userSettings)
    }catch(err){
      console.log("========= onSliderChange Err ===========", err);
    }
  }


  public get enableSliderSave(): boolean {
    return this.disableSave
  }

  public get enableSliderDefault(): boolean {
    return this.disableDefaults
  }

  async continue(): Promise<void> {
    try {
      this.VUSerial = await this.getSerial();
      if (this.VUSerial === undefined) throw new Error('VU Serial Number not found!');
    } catch (err) {
      console.log(err);
    } finally {
      let selectedDeviceId = this.getDropdownValue();
      this.onDeviceSelectionChange(selectedDeviceId);
    }
  }


  get isDisableContinue(): boolean {
    return this.videoDevices?.length == 0 || !this.selectedDevice;
  }

  onDeviceSelectionChange(selectedDeviceId: string) {
    let device = this.videoDevices.filter(device => device.deviceId == selectedDeviceId);
    this.selectedDevice = device[0];
    this._mediaDeviceService.emitSelectedDevice(this.selectedDevice);
  }

  updateDropdownValue(): void {
    if (this.selectedDevice) {
      this.selectRecordingDeviceFb.patchValue({ 'selectRecordingDevice': this.selectedDevice.deviceId });
      this.isDeviceConnected = true
      this.vidoSettingsTab.disabled = false
    } else {
      this.isDeviceConnected = false
      this.tabGroup.selectedIndex = 1
      this.vidoSettingsTab.disabled = true
    }
  }

  getDropdownValue(): string {
    return this.selectRecordingDeviceFb.get('selectRecordingDevice').value;
  }

  comEvents() {
    this._serviceSubscription = this.Comm.onChange.subscribe({
      next: async (event: MyServiceEvent) => {
        switch (event.command) {
          case "usbadd":
            this.VUSerial = await this.getSerial();
            break;
          case "usbremove":
            this.VUSerial = await this.getSerial();
            break;
        }
      }
    })
  }

  getSerial(): Promise<string> {
    let allowedVuDevices: any[] = this._glob.defaults.CameraSettings.allowedDevices;
    return new Promise((res, rej) => {
      this.Comm.getusbDevicesByAPI().then((data: any) => {
        console.log(data);
        if (data !== 'Version error' && data.connected.length != 0) {
          this._http.checkVUserialNumber(data.connected, allowedVuDevices).then(result => {
            res(result.id)
          }).catch(err => {
            console.error('VU DATA ERROR:', err);
            rej(err);
          })
        }
        else
          rej(undefined)
      }).catch(err => {
        console.error('GET SERIAL ERROR!!', err)
        rej(err);
      })
    })
  }

  ngOnDestroy(): void {
    this._headerLogoutClicked.unsubscribe();
    // this._VideoDeviceSubsciption.unsubscribe();
    this._VideoDevicesSubsciption.unsubscribe();
    this._selectedDeviceSubsciption.unsubscribe();
    this.facilitySubscription.unsubscribe();
    this._serviceSubscription.unsubscribe();
    this.isInternetCheck.unsubscribe();
    this._popupSubscription.unsubscribe()
    if (this._deviceListSubscription$ && !this._deviceListSubscription$.closed)
      this._deviceListSubscription$.unsubscribe();
  }

  set sethasUnsavedChanges(status: boolean) {
    this._glob.hasUnsavedChanges = status;
  }

  get gethasUnsavedChanges(): boolean {
    return this._glob.hasUnsavedChanges;
  }
}
